import React, { Component } from 'react';
import { connect } from "react-redux";

import BukaToko from "../../../assert/files/illustration/buka_toko.png";
import BukaToko1 from "../../../assert/files/illustration/buka_toko_1.png";
import BukaToko2 from "../../../assert/files/illustration/buka_toko_2.png";
import BukaToko3 from "../../../assert/files/illustration/buka_toko_3.png";
import PendaftaranTokoDiajukan from "../../../assert/files/illustration/pendaftaran_toko_diajukan.svg";

import { CSSTransition } from 'react-transition-group';

import {
    NewHeader,
    Footer,
    LoadingLock,
    // MapComponent    
} from '../../../components';

import {
    toggleModalLogin
} from '../../../actions/registerActions';

import {
    setUserShopData
} from '../../../actions/userAction';

import {
    authGet, 
    get,
    convertSlug,
    AlertWarning,
    authPostFile,
    authPost,
    AlertError,
    AlertSuccess,
    Toast,
    ConfirmInfo,
    AlertLoading
} from '../../../helper';
import ReactLoading from 'react-loading';
import AsyncSelect from 'react-select/lib/Async';
import _ from 'lodash';
import Geocode from "react-geocode";
import { API_GMAPS_KEY } from '../../../config';
import CreateShopLoading from '../../../components/Loading/CreateShopLoading';
import NetworkDetector from '../../PUBLIC/NetworkDetector';
// import { LoadScript } from '@react-google-maps/api';
// const libraries = ['places'];
Geocode.setApiKey(API_GMAPS_KEY);
Geocode.enableDebug();
Geocode.setLanguage("id");
Geocode.setRegion("id");

const NewStep1 = props => (
    <div>
        <div className="center-div" data-id="1">
            <img src={BukaToko} className="mb30" alt="Buat Toko"/>
            <h1>BUAT TOKO</h1>
            <p className="desc-text">Selamat Datang, ayo buat toko Anda sekarang.</p>

            <div className="text-left">
                <div className="form-group-div">
                    <label>Nama Toko</label><span className="red">*</span><br/>
                    <div className="relative">
                        <input 
                            type="text" 
                            name="name_shop" 
                            className={`form-control `} 
                            placeholder="Tulis Nama Toko"
                            onChange={props.onChangeStep1Name}
                            value={props.name_shop}
                            required
                        />
                        <div className="red absolute fs11" style={{ bottom: "-15px" }}>{props.message_name}</div>
                    </div>
                </div>
                <div className="form-group-div">
                    <label>Domain Toko</label><span className="red">*</span><br/>
                    {
                        props.loadingDomain
                        ? <div style={{position: "absolute", right: 0, top: 0}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                        : props.domain.length > 2
                            ? props.valid_domain
                                ? <i className="fas fa-check-circle validation-icon"></i>
                                : <i className="fas fa-times-circle validation-icon red" style={{color: 'red'}}></i>
                            : null
                    }
                    
                    
                    <div className="row">
                        <div className="col-md-2">
                            <span className="inline-block grey mt7" style={{ fontSize: "13px" }}>agrinis.com/ </span>
                        </div>
                        <div className="col-md-10">
                            <div className="relative">
                                <input 
                                    type="text" 
                                    name="domain" 
                                    className={`form-control ${!props.valid_domain && props.domain.length > 0 ? 'red' : ''}`} 
                                    placeholder="Tulis Domain Toko"
                                    onChange={props.onChangeStep1Domain}
                                    value={props.domain}
                                    required
                                />
                                <div className="red absolute fs11" style={{ bottom: "-15px" }}>{props.message_domain}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group-div">
                    <label>No. Telepon</label><span className="red">*</span><br/>
                    <div className="relative">
                        <input 
                            type="text" 
                            name="no_telp" 
                            className={`form-control `} 
                            placeholder="Tulis Nomor Telepon"
                            onChange={props.onChangeStep1NoTelp}
                            value={props.no_telp}
                            required
                        />
                        <div className="red absolute fs11" style={{ bottom: "-15px" }}>{props.message_name}</div>
                    </div>
                </div>
                <div className="form-group-div">
                    <label>Kode Referal</label><span className="red">*</span><br/>
                    {
                        props.loadingKode
                        ? <div style={{position: "absolute", right: 0, top: 0}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                        : props.kode.length > 5
                            ? props.valid_kode
                                ? <i className="fas fa-check-circle validation-icon"></i>
                                : <i className="fas fa-times-circle validation-icon red" style={{color: 'red'}}></i>
                            : null
                    }
                    <div className="relative">
                        <input 
                            type="text" 
                            name="kode" 
                            className={`form-control ${!props.valid_kode && props.kode.length > 0 ? 'red' : ''}`} 
                            placeholder="Tulis kode referal yang Anda dapatkan dari Agrinis"
                            onChange={props.onChangeStep1Kode}
                            value={props.kode}
                            required
                        />
                        <div className="red absolute fs11" style={{ bottom: "-15px" }}>{props.message_kode}</div>
                    </div>
                </div>   
                <hr/>
                        <p style={{display: props.kodeInti ? "none" : ""}}>Apakah anda merupakan perusahaan inti? <a href="/#" onClick={(e) => {e.preventDefault();props.onOpenKodeInti(true)}}>Masukkan Kode Inti</a></p>
                        <div className="form-group-div" id="form_referalcode" style={{display: props.kodeInti ? "" : "none"}}>
                            <input type="text" className="form-control" placeholder="Tulis Kode Inti" value={props.input_kode_inti} onChange={props.onChangeInputeKodeInti}/>
                            <div className="notification-box sm mt20">
                                <div className="info">
                                    <i className="fas fa-info-circle"></i>
                                    <p>Kode inti juga bisa diinput melalui halaman profil toko anda.</p>
                                </div>
                            </div>
                            <a href="/#" onClick={(e) => {e.preventDefault();props.onOpenKodeInti(false)}}>Batal</a>
                        </div>                
            </div>         
            <hr />
            {
                props.loadingStep1
                ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                : <button className={`btn btn-solid primary mxw200 btn-nav-bukatoko ${(!props.valid_domain) || (!props.valid_kode) ?'disabled': ''}`} onClick={props.handleTab}>Buat Toko </button>
            }
            <br /><br />
            {
                props.already_request ? 
                    props.code_inactive ? 
                        <p>Kode referal anda telah dinonaktifkan, silahkan klik <a href="/#" onClick={props.onClickReaktivasiKode}>disini</a> untuk reaktivasi kode referal anda.</p>
                    :
                        <p>Anda sudah pernah melakukan pengajuan referal kode untuk pendaftaran toko.<br />Silahkan cek email Anda untuk mengetahui kode referal Anda.</p>
                :
                    <p>Tidak punya referal kode ? <a href="/#" data-toggle="modal" data-target="#modalRequestCode">Ajukan Pendaftaran Toko</a></p>
            }
        </div>
        <Footer/>
    </div>
)

const NewStep2 = props => (
    <div>
        <div className="center-div" data-id="2">
        <img src={BukaToko1} className="mb30" alt="Buat Toko"/>
        <h1>BUAT TOKO</h1>
        <p className="desc-text">Lengkapi Logo Toko</p>

        <div className="text-left">
            <div className="form-group-div">
                <label>Logo</label><br/>
                <div className="image-upload-container">
                    <div className="image-upload-div" >
                        <div className="image-upload" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            { props.file === null ? 
                            <i className="fas fa-camera"></i>
                            :
                            <img 
                            style={{ width: "100px", height: "100px", objectFit: "cover" }} 
                            src={props.file} 
                            alt="Agrinis"/>
                            }
                        </div>
                        <input
                            name="logo" 
                            style={{marginTop: "10px"}}
                            type="file"
                            onChange={props.handleSelectedFile} 
                            accept="image/png, image/jpeg,  image/jpg"
                        />
                    </div>
                    <div className="image-upload-div">
                    </div>
                    <div className="image-upload-notes">
                        <p className="mb10">Rasio gambar 1:1 (Persegi). Ukuran gambar minimum 300x300 pixel.</p>
                        <p className="mb10">File gambar harus berupa format JPG, JPEG, PNG. Gambar tidak boleh melebihi 32MB.</p>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
            <div className="form-group-div">
                <label>Deskripsi</label><br/>
                <textarea className="form-control" name="description" value={props.description} onChange={props.onChange} rows="5"/>
            </div>
        </div>
        <br/><br/><br/>
        <button className="btn btn-solid darkgrey mxw200 btn-nav-bukatoko" onClick={props.prevStep} >Sebelumnya</button>
        <button className="btn btn-solid primary mxw200 btn-nav-bukatoko" onClick={props.nextStep} id="next">{props.file === null ? "Lewati" : "Selanjutnya"}</button>
    </div>
    <Footer />
    </div>
)

const NewStep3 = props => (
    <div>
    <div className="center-div" data-id="3">
        <img src={BukaToko2} className="mb30" alt="Buat Toko"/>
        <h1>BUAT TOKO</h1>
        <p className="desc-text">Lengkapi Alamat Asal Pengiriman</p>
        <div className="text-left address-container">
            {
                props.addresses.length > 0 ? 
                props.addresses.map((address, index) => (
                    <div className="box-outline" key={index}>
                    <a href="/#" className="pull-right red" onClick={(e) => props.deleteAddress(e,index)}><i className="fas fa-times"></i></a>
                        <div>
                            <p>{address.city_name}</p>
                            <p>{`Kec. ${address.district_name}, ${address.zipPortal}`}</p>
                                
                            <p>{address.pickup}</p>
                        </div>
                    </div>
                ))
                : 
                <a href="/#" className="btn btn-outline btn-block primary" data-toggle="modal" data-target="#modalTambahAlamat"><i className="fas fa-plus"></i>Tambah Alamat</a>
            }
            
        </div>
        <br/><br/>
        <button className="btn btn-solid darkgrey mxw200 btn-nav-bukatoko" onClick={props.prevStep} >Sebelumnya</button>
        <button className={`btn btn-solid primary mxw200 btn-nav-bukatoko ${props.addresses.length > 0 ? null : "disabled"}`} onClick={props.nextStep} id="next">Selanjutnya</button>
    </div>
    <Footer />
    </div>
)

const NewStep4 = props => (
    <div>
    <div className="center-div" data-id="4">
        <img src={BukaToko3} className="mb30" alt="Buat Toko"/>
        <h1>BUAT TOKO</h1>
        <p className="desc-text">Lengkapi Jasa Pengiriman yang Tersedia</p>
        <div className="text-left">
            <div className="form-group-div">
                <label>Pilih Jasa Pengiriman</label><br/>
                {
                    props.shipments.map((item, i) => {
                        return (
                            <div className="box-outline" key={i}>
                                <table cellPadding="10">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "100px" }}><img src={item.image} alt="buat toko"/></td>
                                            <td>
                                                <div className="p10">
                                                    <div className="w25 pull-left">
                                                        <input
                                                            type="checkbox"
                                                            checked={props.checkedItem[item.detail_id]}
                                                            onChange={(e) => props.handleChange(e, item.detail_id)}
                                                        />
                                                    </div>
                                                        {item.name}
                                                </div>
                                            {/* {_.map(item.detail, (detail, i) => {
                                                return (
                                                    <div key={i} className="p10">
                                                    <div className="w25 pull-left">
                                                        <input
                                                            type="checkbox"
                                                            checked={props.checkedItem[detail.id]}
                                                            onChange={(e) => props.handleChange(e, detail.id)}
                                                        />
                                                    </div>
                                                        {detail.id === 1 ? `${item.name} (${detail.name})` : `${item.name} - ${detail.name}`}
                                                </div>
                                                )
                                            })} */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <br/><br/>
        <div>
            <button className={`btn btn-solid darkgrey mxw200 btn-nav-bukatoko`} onClick={props.prevStep} >Sebelumnya</button>
            <button onClick={props.onSubmit} id="next" className={`btn btn-solid primary mxw200 ${props.isCheked.length > 0 ? '' : 'disabled'}`}>Simpan</button>
        </div>
            
        </div>
    <Footer/>
    </div>
)

const KodeReferalPending = props => ( 
    <div>
        <div className="center-div pengajuan-pendaftaran-toko">
            <img src={PendaftaranTokoDiajukan} className="mb30" alt="Buat Toko"/>
            <h1>PENDAFTARAN TOKO DIAJUKAN</h1>
            <p className="desc-text">Pengajuan Anda untuk melakukan pendaftaran toko berhasil dikirim. <br />Kami akan mereview pengajuan Anda segera. Mohon ditunggu respon dari tim kami <br />melalui email atau nomor telepon yang telah Anda daftarkan.</p>            
            <a href="/" className={`btn btn-solid primary mxw200 btn-nav-bukatoko`}>Kembali ke halaman awal</a>
        </div>
    </div>
)

class Create extends Component {

    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            tab : 1,
            shipments: [],
            name_shop: '',
            no_telp: '',
            valid_shop_name: false,
            message_name: '',

            domain: '',
            valid_domain: false,
            loadingDomain: false,
            message_domain: '',
            kode: '',
            valid_kode: false,
            loadingKode: false,
            message_kode: '',            
            loadingStep1: false,

            file : null,
            logo: 0,
            description: '',

            addresses: [],
            defaultDistrict: [],
            input_address_name: '',
            district: '',
            input_zip_portal: '',
            input_address: '',
            g_adrress: null,
            g_position: {
                lat: -6.1526222,
                lng: 106.8943693
            },


            checkedItem: {},

            loadingCreateToko: false,
            openKodeInti: false,
            input_kode_inti: "",
            client : null,
            request: {
                nama: this.props.userData.name,
                notelp: '',
                email: this.props.userData.email,
                negara: ''
            },
            already_request: false,
            already_have_code: false,
            code_inactive: false,
            request_id: 0
        };
    }
    
    componentWillMount(){
        authGet(`my_shop`, this.props.userData.token)
        .then(res => {
            this.setState({loadingPage: false, already_request: res.data.already_request, already_have_code: res.data.already_have_code, code_inactive: res.data.code_inactive, request_id: res.data.request_id});
            
            if(res.data.data.domain){
                this.props.history.push(`/myshop`);
            }else{
                get(`get_shipment_with_detail`)
                .then(res => {

                    let tempChecked = {};

                    _.forEach(res.data, item => {
                        // _.forEach(item.detail, detail => {
                            // tempChecked[detail.id] = true;
                            tempChecked[item.detail_id] = true;
                        // })
                    });

                    this.setState({
                        shipments: res.data,
                        checkedItem: tempChecked
                    });

                    // get(`indonesia/jakarta`)
                    // .then(res => {
                    //     this.setState({defaultDistrict: res.data});
                    // })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
        // this.getCurrentLocation();
        this.timer = null;
    }

    getCurrentLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({
                    g_position: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }, () => {
                    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                        response => {
                            const g_adrress = response.results[0].formatted_address;

                            this.setState({
                                g_adrress: (g_adrress) ? g_adrress : ''
                            })
                        },
                        error => {
                            console.error(error);
                        }
                    );
                });
            },
            (error) => console.log(error.message),
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 1000 },
        );
    }

    /**
     * 
     * STEP 1
     */

    setTimer = val => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if(val.length > 3){
                this.checkDomain(val);
            }else{
                
                if(this.state.name_shop === "" || this.state.domain === ""){
                    this.setState({
                        loadingDomain: false,
                        message_domain: ""
                    })
                }else{
                    this.setState({
                        message_domain: "Minimal 4 karakter",
                        loadingDomain: false,
                        valid_domain: false
                    })
                }
            }
        }, 500);
    }

    onClickReaktivasiKode = async e => {
        e.preventDefault();
        const result = await ConfirmInfo.fire({
            text: 'Apakah anda yakin ingin mengaktifkan kode referal anda kembali?'
        })
        if (result.value) {
            AlertLoading.fire({})
            try {
                const { data } = await authGet(`reaktivasi_kode/${this.state.request_id}`, this.props.userData.token);
                this.setState({
                    already_request: true,
                    already_have_code: false,
                }, () => { 
                    AlertSuccess.fire({title: "Berhasil!", text: data.message});
                })
            } catch (error) {
                AlertError.fire({text: error.response.data.message});
            }                
        }
    }

    checkDomain(domain){
        authGet(`check_domain/${domain}`)
        .then(res => {
            this.setState({
                valid_domain: true,
                loadingDomain: false
            })
        })
        .catch(err => {
            this.setState({
                valid_domain: false,
                message_domain: err.response.data.message,
                loadingDomain: false
            })
        })
    }

    setTimerKode = val => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if(val.length > 3){
                this.checkKode(val);
            }else{
                
                if(this.state.kode === ""){
                    this.setState({
                        loadingKode: false,
                        message_kode: ""
                    })
                }
            }
        }, 500);
    }

    checkKode(kode){
        authPost({kode: kode}, `check_kode`, this.props.userData.token)
        .then(res => {
            this.setState({
                valid_kode: true,
                loadingKode: false
            })
        })
        .catch(err => {
            this.setState({
                valid_kode: false,
                message_kode: err.response.data.message,
                loadingKode: false
            })
        })
    }

    onChangeStep1Name = (e) => {
        this.setState({
            name_shop : e.target.value,
            domain : convertSlug(e.target.value),
            loadingDomain: true,
            valid_domain: false,
            message_domain: ''
        }, () => {
            this.setTimer(this.state.domain);
        });
    }

    onChangeStep1Domain = (e) => {
        this.setState({
            domain : convertSlug(e.target.value),
            loadingDomain: true,
            valid_domain: false,
            message_domain: ''
        }, () => {
            this.setTimer(this.state.domain);
        });
    }

    onChangeStep1Kode = (e) => {
        this.setState({
            kode : e.target.value,
            loadingKode: true,
            valid_kode: false,
            message_kode: ''
        }, () => {
            this.setTimerKode(this.state.kode);
        });
    }

    onChangeStep1NoTelp = (e) => {
        this.setState({
            no_telp: e.target.value,
        });
    }

    onChangeStep1Request = (e) => {
        this.setState({
            request: { 
                ...this.state.request,
                [e.target.name] : e.target.value
            }
        });
    }

    setTab(tab){
        this.setState({tab});
    }

    handleTabStep1 = () => {        
        let { domain, name_shop, no_telp, kode, valid_domain, valid_kode } = this.state;

        if (name_shop.length === 0) { 
            AlertError.fire({text: "Nama toko wajib diisi"});
            return false;
        } else if (no_telp.length === 0) { 
            AlertError.fire({text: "Nomor Telepon wajib diisi"});
            return false;
        } else if (kode.length === 0) { 
            AlertError.fire({text: "Kode Referal wajib diisi"});
            return false;
        } else if (!valid_domain) { 
            AlertError.fire({text: "Domain tidak valid"});
            return false;
        } else if (!valid_kode) { 
            AlertError.fire({text: "Kode Referal tidak valid"});
            return false;
        }
        if(this.validateDomain(domain)){
            if(this.state.openKodeInti && this.state.input_kode_inti !== ""){
                this.setState({loadingStep1: true});
                authGet(`shop/find_client/${this.state.input_kode_inti}`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        loadingStep1: false,
                        client: res.data.id
                    }, () => {
                        this.setTab(2);
                    });
                })
                .catch(err => {
                    this.setState({loadingStep1: false});
                    AlertError.fire({text: err.response.data.message});
                })
            }else{
                this.setTab(2);
            }
        }
    }

    validateDomain(domain){
        let re = /^[a-zA-Z0-9_-]*$/;
        return re.test(String(domain).toLowerCase());
    }

    onSubmitRequestForm = async (e) => {
        e.preventDefault();
        this.setState({
            loadingRequest: true,
        })
        let { nama, email, negara, notelp } = this.state.request;
        let request = {};

        if (nama.length === 0) { 
            AlertError.fire({text: "Nama wajib diisi"});
            return false;
        } else if (email.length === 0) { 
            AlertError.fire({text: "Email wajib diisi"});
            return false;
        } else if (negara.length === 0) { 
            AlertError.fire({text: "Negara wajib diisi"});
            return false;
        } else if (notelp.length === 0) { 
            AlertError.fire({text: "Nomor telepon wajib diisi"});
            return false;
        } else { 
            request = { nama, email, negara, notelp }
        }

        try {
            const { data } = await authPost(request, `request_code`, this.props.userData.token);
            this.setState({
                already_request: true
            }, () => { 
                window.$("#modalRequestCode").modal("hide")
                AlertSuccess.fire({title: "Berhasil!", text: data.message});
            })
        } catch (error) {
            this.setState({loadingRequest: false});
            AlertError.fire({text: error.response.data.message});
        }
    }

    /**
     * 
     * STEP 2
     */

    handleSelectedFile = (event) => {
        this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            logo: event.target.files[0]
        });
    }

    onChangeStep2 = (e) => {
        this.setState({[e.target.name] : e.target.value});
    }
    

    /**
     * 
     * STEP 3
     */

    onChangeStep3Ziportal = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        
        this.setState({input_zip_portal : validateValue});
    }

    validateAlamat(data) {
        if(data.nama_alamat === ""){
            AlertWarning.fire({text: "Label harus diisi"});
            return false;
        }
        else if(data.district === ""){
            AlertWarning.fire({text: "Kota harus diisi"});
            return false;
        }
        else if(data.zipPortal === ""){
            AlertWarning.fire({text: "Kode pos harus diisi"});
            return false;
        }
        else if(data.pickup === ""){
            AlertWarning.fire({text: "Alamat lengkap harus diisi"});
            return false;
        }

        return true;
    }

    setAddress = (e) => {
        e.preventDefault();
        let split = _.split(this.state.district.label, ' - ');

        let data = {
            nama_alamat : this.state.input_address_name,
            district: this.state.district,
            district_name: split[1],
            city_name: split[0],
            zipPortal: this.state.input_zip_portal,
            pickup: this.state.input_address,
            lat: this.state.g_position.lat,
            lng: this.state.g_position.lng,
        }

        if(this.validateAlamat(data)){
            this.setState({
                addresses: [
                    ...this.state.addresses,
                    data,
                ],
                input_address_name: '',
                district: '',
                input_zip_portal: '',
                input_address: ''
            }, () => window.$("#modalTambahAlamat").modal("hide"));

        }
    }

    deleteAddress(e, index){
        e.preventDefault();
        let {addresses} = this.state;

        addresses.splice(index, 1);

        let newaddres = addresses;

        this.setState({addresses: [...newaddres]});
    }

    loadOptions = (inputValue, callback) => {
        if(inputValue.length > 2){
            get(`indonesia/search/${inputValue}`)
            .then(res => {
                callback(res.data);
            })
        }else{
            callback([]);
        }
    }

    onChangeGMaps = (e) => {
        this.setState({ place: e.description }
            , () => {
                Geocode.fromAddress(e.description).then(
                    response => {
                        const coordinates = response.results[0].geometry.location;
                        const g_adrress = response.results[0].formatted_address;
                        this.setState({
                            g_position: {
                                lat: coordinates.lat,
                                lng: coordinates.lng
                            },
                            g_adrress: (g_adrress) ? g_adrress : '',
                        });
                    },
                    error => {
                        console.error("From Address: " + error);
                    }
                );
            }
        )
    }
    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const g_adrress = response.results[0].formatted_address;
                this.setState({
                    g_position: {
                        lat: newLat,
                        lng: newLng
                    },
                    g_adrress: (g_adrress) ? g_adrress : '',
                });
            },
            error => {
                console.error(error);
            }
        );
    };

    

    /**
     * 
     * STEP 4
     */

    chekedShipment = (e, id) => {
        this.setState({
            checkedItem: {
                ...this.state.checkedItem,
                [id] : !this.state.checkedItem[id]
            }
        })
    }

    isChekedShipment(data){
        return _.keys(_.pickBy(data))
    }


    createShop = () => {
        let data = {
            name_shop : this.state.name_shop,
            domain : this.state.domain,
            kode: this.state.kode,
            description: this.state.description,
            addresses: this.state.addresses,
            no_telp: this.state.no_telp,
            shipment_detail: this.isChekedShipment(this.state.checkedItem),
            client_id: this.state.client
        }

        this.setState({loadingCreateToko: true});
        /**  INSERT TOKO  */
        authPost(data, `create_shop`, this.props.userData.token)
        .then(res => {
            this.props.setUserShopData(res.data);

             /**  UPLOAD LOGO JIKA ADA  */
            if(this.state.logo){
                let formData = new FormData();
                formData.append('id', res.data.id);
                formData.append('logo', this.state.logo);
                authPostFile(formData, `shop_upload_logo`, this.props.userData.token)
                .then(response => {
                    Toast.fire({
                        type: "success",
                        title: "Berhasil membuat toko"
                    }).then(result => {
                        this.setState({loadingCreateToko: false});
                        this.props.history.push("/myshop");
                    })
                })
                .catch(err => {
                    AlertError.fire({text: "Ada kesalahan saat upload logo"})
                    .then(result => {
                        this.setState({loadingCreateToko: false});
                        this.props.history.push("/myshop");
                    })
                })
            }else{
                Toast.fire({
                    type: "success",
                    title: "Berhasil membuat toko"
                }).then(result => {
                    this.setState({loadingCreateToko: false});
                    this.props.history.push("/myshop");
                })
            }
        })
        .catch(err => {
            this.setState({loadingCreateToko: false});
            AlertError.fire({text: err.response.data.message});
        })
    }

    render() {
        let Page = null;
        if (this.state.already_request && !this.state.already_have_code) {
            Page = (
                <KodeReferalPending
                    onChangeStep1Name={this.onChangeStep1Name}
                    onChangeStep1Domain={this.onChangeStep1Domain}
                    handleTab={this.handleTabStep1}
                    onChangeStep1NoTelp={this.onChangeStep1NoTelp}
                    onOpenKodeInti={(val) => this.setState({openKodeInti: val})}
                    kodeInti={this.state.openKodeInti}
                    input_kode_inti={this.state.input_kode_inti}
                    onChangeInputeKodeInti={(e) => this.setState({input_kode_inti: e.target.value})}
                    onChangeStep1Kode={this.onChangeStep1Kode}
                    {...this.state}
                    />
            )
        } else if (((!this.state.already_request && !this.state.already_have_code) || (this.state.already_request && this.state.already_have_code)) && this.state.tab === 1) {
        Page = (
            <NewStep1
                onChangeStep1Name={this.onChangeStep1Name}
                onChangeStep1Domain={this.onChangeStep1Domain}
                handleTab={this.handleTabStep1}
                onChangeStep1NoTelp={this.onChangeStep1NoTelp}
                onOpenKodeInti={(val) => this.setState({openKodeInti: val})}
                kodeInti={this.state.openKodeInti}
                input_kode_inti={this.state.input_kode_inti}
                onChangeInputeKodeInti={(e) => this.setState({input_kode_inti: e.target.value})}
                onChangeStep1Kode={this.onChangeStep1Kode}
                onClickReaktivasiKode={this.onClickReaktivasiKode}
                {...this.state}
                />
        )
        } else if(this.state.tab === 2){
            Page = (
                <NewStep2
                    handleSelectedFile={this.handleSelectedFile}
                    onChange={this.onChangeStep2}
                    prevStep={() => this.setTab(1)}
                    nextStep={() => this.setTab(3)}
                    {...this.state}
                />
            )
        } else if(this.state.tab === 3){
            Page = (
                <NewStep3
                    onChange={this.onChangeStep2}
                    prevStep={() => this.setTab(2)}
                    nextStep={() => this.setTab(4)}
                    deleteAddress={(e,index) => this.deleteAddress(e, index)}
                    {...this.state}
                />
            )
        }
        else if(this.state.tab === 4){
            Page = (
                <NewStep4
                    prevStep={() => this.setTab(3)}
                    handleChange={this.chekedShipment}
                    isCheked={this.isChekedShipment(this.state.checkedItem)}
                    onSubmit={this.createShop}
                    {...this.state}
                />
            )
        }

        return (
            <div className="container-fluid" style={{ marginTop: "100px" }}>
                {
                    this.state.loadingCreateToko
                    ? <LoadingLock/>
                    : null
                }
                
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <CreateShopLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {Page}
                    {/* {this.renderStep()} */}
                </CSSTransition>

                <div className="modal fade modal-2" id="modalTambahAlamat" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>TAMBAH ALAMAT</h2>
                                    <div className="form-group-div form-warning">
                                        <label>Label</label><br />
                                        <input 
                                            name="input_address_name"
                                            type="text" 
                                            className="form-control" 
                                            onChange={this.onChangeStep2}
                                            value={this.state.input_address_name}
                                            placeholder="Contoh: Toko, Gudang, Outlet"/>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Alamat</label><br />
                                        <textarea
                                            name="input_address"
                                            className="form-control"
                                            placeholder="Tulis Alamat"
                                            onChange={this.onChangeStep2}
                                            value={this.state.input_address}></textarea>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Kota</label><br />
                                        <AsyncSelect
                                            defaultOptions={false}
                                            loadOptions={this.loadOptions}
                                            // onInputChange={this.handleChange}
                                            onChange={(newValue) => {this.setState({district : newValue});}}
                                            placeholder="Ketik untuk memilih Kota Pengiriman"
                                            value={this.state.district}
                                            noOptionsMessage={(val)=> null}
                                            />
                                    </div>
                                    <div className="form-group-div">
                                        <label>Kode Pos</label><br />
                                        <input 
                                            name="input_zip_portal"
                                            type="text" 
                                            className="form-control w200" 
                                            placeholder="Tulis Kode Pos"
                                            onChange={this.onChangeStep3Ziportal}
                                            value={this.state.input_zip_portal}/>
                                    </div>

                                    {/* <div className="form-group-div">
                                        <label>Pilih Koordinat Lokasi</label><br />
                                        <LoadScript
                                            id="script-loader"
                                            googleMapsApiKey={API_GMAPS_KEY}
                                            language="id"
                                            region="id"
                                            libraries={libraries}
                                        >
                                        <MapComponent
                                            onChangeGMaps={this.onChangeGMaps}
                                            position={this.state.g_position}
                                            address={this.state.g_adrress}
                                            api_key={API_GMAPS_KEY}
                                            onMarkerDragEnd={this.onMarkerDragEnd}
                                        />
                                        </LoadScript>
                                        </div> */}
                                </div>
                                <div className="modal-footer">
                                    <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</button>
                                    <button className="btn btn-solid primary btn-sm" onClick={this.setAddress}>Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalRequestCode" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>AJUKAN PENDAFTARAN TOKO</h2>
                                    <div className="form-group-div">
                                        <label>Nama Lengkap</label><br />
                                        <input 
                                            name="nama"
                                            type="text" 
                                            className="form-control" 
                                            onChange={this.onChangeStep1Request}
                                            value={this.state.request.nama}
                                            placeholder="Tulis nama lengkap"/>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Email</label><br />
                                        <input 
                                            name="email"
                                            type="text" 
                                            className="form-control" 
                                            onChange={this.onChangeStep1Request}
                                            value={this.state.request.email}
                                            placeholder="Tulis Email"/>
                                    </div> 
                                    <div className="form-group-div">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Negara</label>    
                                                <input 
                                                    name="negara"
                                                    type="text" 
                                                    className="form-control" 
                                                    onChange={this.onChangeStep1Request}
                                                    value={this.state.request.negara}
                                                    placeholder="Tulis Negara"/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>No. Telepon</label>    
                                                <input 
                                                    name="notelp"
                                                    type="text" 
                                                    className="form-control" 
                                                    onChange={this.onChangeStep1Request}
                                                    value={this.state.request.notelp}
                                                    placeholder="62"/>
                                            </div>                                            
                                        </div>
                                </div>
                                </div>
                                <div className="modal-footer">
                                    <button className={`btn btn-solid darkgrey btn-sm ${(this.state.loadingRequest) ? 'disabled' : ''}`} data-dismiss="modal">Batal</button>
                                    <button className={`btn btn-solid primary btn-sm ${(this.state.loadingRequest) ? 'disabled' : ''}`} onClick={this.onSubmitRequestForm} >Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>                
            </div>
        )
    }
}

const mapStateToprops = (state) => {
    return {
        userData : state.user.user,
    }
}

export default NetworkDetector(connect(mapStateToprops,{
    setUserShopData,
    toggleModalLogin
})(Create));
