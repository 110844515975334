import React from 'react'
import ProductGrid from './ProductGrid';
import ProductList from './ProductList';

const BarangPopuler = props => {
    let page = null;
    props.grid ? 
    page = <ProductGrid
            data={props.data}
            grid={props.grid}
            emtyDataAction={() => this.props.history.push('/myshop')}
            totalData={props.data.length}
            fetchMoreData={props.fetchMoreData}
            hasMoreData={props.hasMoreData()}
        />
        :
     page = <ProductList
            data={props.data}
            grid={props.grid}
            emtyDataAction={() => this.props.history.push('/myshop')}
            totalData={props.data.length}
            fetchMoreData={props.fetchMoreData}
            hasMoreData={props.hasMoreData()}
        />
    return (page);
}
export default BarangPopuler