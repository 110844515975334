import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
    Filter,
    FilterShop, 
    NewHeader,
    Product,
    EmptyResult
} from '../../components';

import {
    seacrhProduct,
    onLikeProduct,
    postMoreProducts
} from '../../actions/productActions';
import { toggleModalLogin } from "../../actions/registerActions"
import AuthService from '../../actions/API/AuthService'
import Searh_result from '../../assert/icon/search-result.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingInfiniteScroll from '../../components/Loading/LadingInfiniteScroll'
import { 
    AlertWarning,
    get,
    AlertError,
    Toast,
    authPost,
    post,
    authPostPaginate
} from '../../helper';
import {
    subScribeShop,
    unsubScribeShop
} from '../../actions/userAction';
import _ from 'lodash';
import SearchLoading from '../../components/Loading/SearchLoading';
import  ReactLoading  from 'react-loading';
import { Link } from 'react-router-dom';
import HubungiPenjual from '../../components/Global/HubungiPenjual';
import { withNamespaces } from 'react-i18next'
import i18n from '../../i18n';
import NetworkDetector from './NetworkDetector'

class Search extends Component {

    constructor(props){
        super(props)
        this.state = {
            loadingPage: true,
            min : "",
            max : "",
            lockFilter : true,
            sort_id : 1,
            sort_id_shop : 1,
            kondisi : {
                1 : true,
                2 : true
            },
            shipment : [],
            wilayah: [],
            shipmentChecked : {},
            dataPersist : {},
            diskonCheked : false,
            loadingButton: false,
            loadingSubscribeShop: false,
            shop_result: [],
            shop_pagination: {},
            tab_active: 1,
            subscribed_shop: [],
            provinsi: [],
            seller_selected: { image: "", addresses: { data: [{ city: "" }]}},
        }
        this.Auth = new AuthService();
    }

    componentDidMount() {
        this.setDataPost(this.props.match.params);
        get(`get_shipment_detail`)
        .then(res => {
            let shipmentChecked = {};
            _.each(res.data, d => {
                shipmentChecked = {...shipmentChecked, [d.id] : true};
            })
            get(`get_province`).then(res2 => {
                this.setState({provinsi: res2.data}, () => {
                    let arr = this.state.provinsi.map(e => {
                        return parseInt(e.value)
                    });
                    this.setState({wilayah: arr});
                });
                this.setState({shipmentChecked, shipment : res.data, loadingPage: false});
            });
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
        })
    }

    setDataPost = (data) => {
        this.setState({dataPersist : data}
        ,() => {
            authPost(data, 'search_shop', this.props.userData.token).then(res => {
                this.setState({
                    shop_result: res.data.data,
                    shop_pagination: res.data.meta.pagination
                })
                this.props.seacrhProduct(data, this.props.userData.token);
            });
        })
    }

    handleChangeTab = (e, tab_id) => {
        e.preventDefault();
        this.setState({
            tab_active: tab_id
        })
    }

    onChangeSortShop = (e) => {
        this.setState({loadingMoreData: true, sort_id_shop: parseInt(e.target.value)})
        let data = { 
            keyword: this.props.match.params.keyword,
            sort_id: parseInt(e.target.value),
            wilayah: this.state.wilayah
        }
        post(data, 'search_shop').then(res => {
            this.setState({
                shop_result: res.data.data,
                shop_pagination: res.data.meta.pagination,
                loadingMoreData: false
            });
        });
    }

    onClickLikeButton (product, likeActive) {
        let { token } = this.props.userData;
        if(this.props.userId){
            this.setState({loadingButton: true});
            if(!likeActive){
                /* INSERT LIKE TABLE */
                
                let data = {
                    product_id : product.id,
                    user_id: this.props.userId,
                    action: "INSERT" 
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.setState({loadingButton: false}, () => { 
                        Toast.fire({
                            text: i18n.t("Search:favorite.tambah"),
                            type: "success"
                        });                        
                    });
                });

            }else{
                /* REMOVE FROM LIKE TABLEE */

                let data = {
                    id : likeActive.id,
                    user_id: this.props.userId,
                    action: "DELETE" 
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.setState({loadingButton: false}, () => { 
                        Toast.fire({
                            text: i18n.t("Search:favorite.hapus"),
                            type: "success"
                        });                        
                    });
                });
            }
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    filterProduct = () =>  {
        let keyword = this.props.prevKeyword;
        let kondisi = _.keys(_.pickBy(this.state.kondisi));
        let shipment = _.keys(_.pickBy(this.state.shipmentChecked));

        if(keyword === ""){
            keyword = this.props.match.params.keyword;
        }

        if(parseInt(this.state.min) > parseInt(this.state.max)){
            AlertWarning.fire({text : i18n.t("Search:validation.minmax")});
            return false;
        }

        this.setState({lockFilter : false}
        ,() => {
            let data = {
                keyword,
                min : this.state.min,
                max : this.state.max,
                sort_id : this.state.sort_id,
                kondisi,
                shipment
            }

            if(this.state.diskonCheked){
                data = {...data, diskon : this.state.diskonCheked}
            }
            this.setDataPost(data);
        })
    }

    onChangefilterHarga = (value, name) =>{
        this.setState({[name] : value})
    }

    onChangeShipment = (item) => {
        this.setState({
            shipmentChecked : {
                ...this.state.shipmentChecked,
                [item.id] : !this.state.shipmentChecked[item.id]
            }
        })
    }

    resetFilterProduct = (e) => {
        e.preventDefault();
        let kondisi = {1: true, 2: true};
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = {...shipmentChecked, [d.id] : true};
        })

        this.setState({min : "", max: "", kondisi, shipmentChecked, diskonCheked : false},
        () => {
            let keyword = this.props.prevKeyword;

            if(keyword === ""){
                keyword = this.props.match.params.keyword;
            }

            let data = {
                keyword
            }
            this.setDataPost(data);
        })
    }

    clearFilterPage = () => {
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = {...shipmentChecked, [d.id] : true};
        });

        let data = {
            max : "",
            min : "",
            sort_id : 1,
            kondisi : {
                1 : true,
                2 : true
            },
            shipmentChecked,
            diskonCheked : false
        }
        this.setState(data, () => {
            this.setDataPost({keyword: this.props.prevKeyword});
        })
    }

    sortProduct = (e) => {
        this.setState({ sort_id : e.target.value }
        ,() => {
            // let keyword = this.props.prevKeyword;

            // if(keyword === ""){
            //     keyword = this.props.match.params.keyword;
            // }

            // let kondisi = _.keys(_.pickBy(this.state.kondisi));
            // let shipment = _.keys(_.pickBy(this.state.shipmentChecked));

            // let data = {
            //     keyword,
            //     min : this.state.min,
            //     max : this.state.max,
            //     sort_id : this.state.sort_id,
            //     kondisi,
            //     shipment
            // }

            // if(this.state.diskon){
            //     data = {...data, diskon : this.state.diskon}
            // }

            let { dataPersist } = this.state;
            let data = {...dataPersist, sort_id : this.state.sort_id}

            this.setDataPost(data);
        })
    }

    checkedKondisi = (e) => {
        let kon = parseInt(e.target.value)
        this.setState({
            kondisi : {
                ...this.state.kondisi,
                [kon] : !this.state.kondisi[kon]
            }
        })
    }

    checkedWilayah = (e) => { 
        let wil = parseInt(e.target.value)
        if(this.state.wilayah.includes(wil)) {
            this.setState({
                wilayah: _.without(this.state.wilayah, wil)
            })
        } else { 
            this.setState({
                wilayah: [...this.state.wilayah, wil]
            })
        }
    }

    filterShops = (e) => { 
        e.preventDefault();
        this.setState({loadingMoreData: true})
        let data = {
            keyword: this.props.match.params.keyword,
            sort_id: this.state.sort_id_shop, 
            filter: this.state.wilayah,
        }
        post(data, 'search_shop').then(res => {
            this.setState({
                shop_result: res.data.data,
                shop_pagination: res.data.meta.pagination,
                loadingMoreData: false
            });
        });
    }
    resetFilterShops = (e) => { 
        e.preventDefault();
        let arr = this.state.provinsi.map(e => {
            return parseInt(e.value)
        });
        this.setState({wilayah: arr});
    }

    fetchMoreData = () => {
        let { next } = this.props.pagination.links;
        let { dataPersist } = this.state;

        this.props.postMoreProducts(next, dataPersist);
    }
    unSubscribe = (e, id) => {
        e.preventDefault();
        let data = { shop_id: id }
        this.setState({loadingSubscribeShop: true, subscribed_shop: _.without(this.state.subscribed_shop, id)}, () => {
            this.props.unsubScribeShop(data, this.props.userData.token, this.props.lng)
            this.setState({loadingSubscribeShop: false});
        })
    }
    onSubscribe = (e, id) => {
        e.preventDefault();
        let data = { shop_id: id }
        this.setState({loadingSubscribeShop: true, subscribed_shop: [...this.state.subscribed_shop, id]}, () => {
            this.props.subScribeShop(data, this.props.userData.token, this.props.lng)
            this.setState({loadingSubscribeShop: false});
        })
    }
    onClickHubungiPenjual = (e, data) => {
        e.preventDefault()
        this.setState({
            seller_selected: data
        }, () => {
            window.$("#modalHubungiPenjual").modal("show");
        })
    }
    /**
     * @var LOAD_PAGINATION_SHOP
     */
    loadMoreData = () => { 
        let { next } = this.state.shop_pagination.links;
        this.setState({ loadingMoreData: true });
        let { dataPersist } = this.state;
        let data = { ...dataPersist, sort_id: this.state.sort_id }
        authPostPaginate(data, next, this.props.userData.token)
            .then(res => {
                this.setState({
                    shop_result: [...this.state.shop_result, ...res.data.data],
                    shop_pagination: res.data.meta.pagination,
                    loadingPage: false,
                    loadingMoreData: false,
                })
            })
    }
    hasMoreData = () => !(this.props.products.length === (this.props.pagination ? this.props.pagination.total : -1 ));
    hasMoreDataShop = () => !(this.state.shop_result.length === (this.state.shop_pagination ? this.state.shop_pagination.total : -1 ));

    render() {
        const totalProudcts = this.props.pagination ? this.props.pagination.total : 0;
        const countProudcts = this.props.pagination ? this.props.pagination.count : 0;
        const totalShops = this.state.shop_pagination ? this.state.shop_pagination.total : 0;
        const countShops = this.state.shop_pagination ? this.state.shop_pagination.count : 0;
        let PageToko2 = (
            <div className="container" style={{marginTop: "62px"}}>
                    <div className="left-side">
                        <FilterShop
                            province={this.state.provinsi}
                            wilayah={this.state.wilayah}
                            filterShops={this.filterShops}
                            resetFilterShops={this.resetFilterShops}
                            checkedWilayah={this.checkedWilayah}
                            />
                            
                    </div>

                    <div className="category-detail">
                    <div className="content-tab mb20">
                    <ul>
                        <li data-id="1"><a href="/#" onClick={(e) => this.handleChangeTab(e, 1)}><i className="fas fa-dolly-flatbed"></i> {i18n.t("Search:tab_barang")}</a></li>
                        <li data-id="2" className="active"><a href="/#" onClick={(e) => this.handleChangeTab(e, 2)}><i className="fas fa-store"></i> {i18n.t("Search:tab_toko")}</a></li>
                    </ul>
                    </div>
                    <div className="sorting-input">
                        <select className="form-control" name="sort" id="sort" value={this.state.sort_id_shop} onChange={this.onChangeSortShop}>
                            <option value="1">{i18n.t("Search:urutkan_toko.barang")}</option>
                            {/* <option value="2">Aktif Terbaru</option> */}
                            <option value="3">{i18n.t("Search:urutkan_toko.diskon")}</option>
                        </select>
                        <label>{i18n.t("Search:urutkan")}</label>
                    </div>
                        <p className="search-summary">{i18n.t("Search:menampilkan.menampilkan")} {totalShops} {i18n.t("Search:menampilkan.toko_untuk")} "<span>{this.props.prevKeyword === "" ? this.props.match.params.keyword : this.props.prevKeyword}</span>" ({countShops} {i18n.t("Search:menampilkan.dari")} {totalShops})</p>
                        <div className="clearfix"></div>
                        {
                            this.state.loadingMoreData
                            ? <div>
                                <LoadingInfiniteScroll />
                            </div>
                            : this.state.shop_result.length > 0 
                                ? <div><div className="row">
                                    {this.state.shop_result.map((data, i) => {
                                        return (
                                            <div className="col-md-6" key={i}>
                                                <div className="following-shop">
                                                    <div className="following-shop-header">
                                                        <img src={data.photo} alt="" className="shop-icon mr10" />
                                                        <div className="following-info">
                                                            <div>
                                                                <Link to={`/${data.domain}`} className="shop-name">
                                                                    {data.name}
                                                                </Link>
                                                                <p><i className="fas fa-map-marker-alt"></i> {data.city}</p>
                                                            </div>
                                                            <div>
                                                                <a href="/#" onClick={(e) => this.onClickHubungiPenjual(e, data)} className="btn btn-sm btn-solid grey mr5 mb5" data-id={data.id}><i className="far fa-envelope"></i> {i18n.t("Search:toko.hubungi_penjual")}</a>
                                                                {
                                                                this.state.subscribed_shop.includes(data.id) ? 
                                                                <a href="/#" onClick={(e) => this.unSubscribe(e, data.id)} className="btn btn-sm btn-solid grey mb5"><i className="fas fa-bookmark"></i> {i18n.t("Search:toko.langganan_hapus")}</a>
                                                                    :
                                                                <a href="/#" onClick={(e) => this.onSubscribe(e, data.id)} className="btn btn-sm btn-solid grey mb5"><i className="far fa-bookmark"></i> {i18n.t("Search:toko.langganan")}</a>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="following-shop-body">
                                                    {data.product.length <= 0 ? 
                                                            <div className="text-center">
                                                                <span className="regularitalic">{i18n.t("Search:toko.no_barang")}</span>
                                                            </div>    
                                                            :
                                                        <div><p>{i18n.t("Search:toko.perbarui")} {data.latest}</p>
                                                        <div className="row">
                                                            {
                                                                data.product.map((datax, ix) => <div className="col-md-4" key={ix}>
                                                                <Link to={`/product/${datax.id}`} className="block">
                                                                    <img src={datax.image} alt="" className="product-img" />
                                                                </Link>
                                                            </div>
                                                            )}
                                                        </div></div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })} 
                                    </div>
                                    <div className="text-center">
                                        {
                                            this.hasMoreDataShop() ? 
                                            this.state.loadingMoreData
                                                ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                                : <button className="btn btn-solid primary" onClick={this.loadMoreData}>{i18n.t("Search:toko.lainnya")}</button>
                                                :null
                                        }
                                    </div></div>
                                    :
                                    <EmptyResult
                                        image={Searh_result}
                                        message={i18n.t("Search:toko.not_found")}
                                        buttonText={0}
                                    />
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
        )

        let Page = (
            <div className="container" style={{marginTop: "62px"}}>
                    <div className="left-side">
                        <Filter 
                            onChangeHarga={this.onChangefilterHarga}
                            maxValue={this.state.max}
                            minValue={this.state.min}
                            filterProduct={this.filterProduct}
                            resetFilterProduct={this.resetFilterProduct}
                            kondisi={this.state.kondisi}
                            checkedKondisi={this.checkedKondisi}
                            shipments={this.state.shipment}
                            shipmentChecked={this.state.shipmentChecked}
                            onChangeShipment={this.onChangeShipment}
                            diskonChecked={this.state.diskonCheked}
                            onChangeDiskon={() => this.setState({diskonCheked : !this.state.diskonCheked})}
                            />
                            
                    </div>

                    <div className="category-detail">
                    <div className="content-tab mb20">
                        <ul>
                        <li data-id="1" className="active"><a href="/#" onClick={(e) => this.handleChangeTab(e, 1)}><i className="fas fa-dolly-flatbed"></i> {i18n.t("Search:tab_barang")}</a></li>
                        <li data-id="2"><a href="/#" onClick={(e) => this.handleChangeTab(e, 2)}><i className="fas fa-store"></i> {i18n.t("Search:tab_toko")}</a></li>
                        </ul>
                    </div>
                        <div className="sorting-input">
                            <select className="form-control" value={this.state.sort_id} onChange={this.sortProduct}>
                                <option value="1">{i18n.t("Search:urutkan_barang.sesuai")}</option>
                                <option value="2">{i18n.t("Search:urutkan_barang.termurah")}</option>
                                <option value="3">{i18n.t("Search:urutkan_barang.termahal")}</option>
                                <option value="4">{i18n.t("Search:urutkan_barang.terlaris")}</option>
                                <option value="5">{i18n.t("Search:urutkan_barang.populer")}</option>
                                <option value="6">{i18n.t("Search:urutkan_barang.terbaru")}</option>
                            </select>
                            <label>{i18n.t("Search:urutkan")}</label>
                        </div>
                        <p className="search-summary">{i18n.t("Search:menampilkan.menampilkan")} {totalProudcts} {i18n.t("Search:menampilkan.barang_untuk")} "<span>{this.props.prevKeyword === "" ? this.props.match.params.keyword : this.props.prevKeyword}</span>" ({countProudcts} {i18n.t("Search:menampilkan.dari")} {totalProudcts})</p>
                        <div className="clearfix"></div>
                        <div className="product-container" style={{overflow: "auto"}}>
                            {
                                this.props.loading 
                                ? <p>{i18n.t("Search:memuat")}</p>
                                : countProudcts > 0 
                                ? <InfiniteScroll
                                    dataLength={this.props.products.length}
                                    next={this.fetchMoreData}
                                    hasMore={this.hasMoreData()}
                                    loader={<LoadingInfiniteScroll/>}
                                    > 
                                    {
                                        this.props.products.map(product => (
                                            <Product 
                                                key={product.id}
                                                onClickLikeButton={(product, likeActive) => this.onClickLikeButton(product, likeActive)}
                                                likes={this.props.likes ? this.props.likes : []}
                                                loadingButton={this.state.loadingButton}
                                                {...product}
                                                />
                                        ))
                                    }
                                </InfiniteScroll>
                                : <EmptyResult 
                                image={Searh_result}
                                message={i18n.t("Search:no_barang")}
                                buttonText={0}
                                />
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
        )
        return (
            <div>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userId}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    clearFilterPage={this.clearFilterPage}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <SearchLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        { this.state.tab_active === 1 ? Page : PageToko2}
                </CSSTransition>
                <HubungiPenjual
                    seller={this.state.seller_selected}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products : state.products.items,
        pagination: state.products.pagination,
        prevKeyword : state.products.prevKeyword,
        loading : state.loading.loadingProduct,
        likes : state.user.user.product_favorite,
        userId : state.user.user.user,
        userData : state.user.user,
    }
}


export default NetworkDetector(withNamespaces('Search')(connect(mapStateToProps, {
    seacrhProduct, 
    toggleModalLogin, 
    onLikeProduct,
    postMoreProducts,
    subScribeShop,
    unsubScribeShop,
})(Search)))
