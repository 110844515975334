import React, { PureComponent } from 'react';
import { FormatUang } from './../../helper';
import _ from 'lodash';
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Brush,
    ResponsiveContainer,
    AreaChart,
    Area,
    LineChart,
    Line,
} from 'recharts';
import ReactLoading from 'react-loading';
import * as moment from "moment";
import 'moment/locale/id';
import i18n from '../../i18n';
moment.locale('id');
class TickBulanan extends PureComponent {

    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{moment(payload.value).format('MMM')}</text>
                {/* <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{payload.value}</text> */}
            </g>
        );
    }
}
class TickMingguan extends PureComponent {
    render() {
        const {
            x, y, payload,
        } = this.props;
        let start = moment(payload.value).format("DD MMM");
        let end = moment(payload.value).add(6, 'days').format("DD MMM");
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{start + ' - ' + end}</text>
            </g>
        );
    }
}
class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, payload,
        } = this.props;
        let date = moment(payload.value).format("DD MMM");
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{date}</text>
            </g>
        );
    }
}
const PendapatanPerBarang = props => {
    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'M';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'jt';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'rb';
        } else {
            return number.toString();
        }
    }
    const numberFormat = (value) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).split(',')[0];
    }
    return (
        <div className="statistic-body" id="statistic-1">
            <table width="100%" cellPadding="15">
                <tbody>
                    <tr>
                        <td>
                            <div className="stat-bg-1">
                                <label>{i18n.t("Statistic:pendapatan.total_pendapatan")}</label>
                                <span className="stat-bold-text">Rp &nbsp; <FormatUang value={_.sum(_.map(props.data, 'pendapatan'))} /></span>
                            </div>
                        </td>
                        <td style={{ width: '22%' }}>
                            <div className="stat-bg-2">
                                <label>{i18n.t("Statistic:pendapatan.total_terjual")}</label>
                                <span>{_.sum(_.map(props.data, 'Terjual'))}</span>
                            </div>
                        </td>
                        <td style={{ width: '22%' }}>
                            <div className="stat-bg-3">
                                <label>{i18n.t("Statistic:pendapatan.total_dilihat")}</label>
                                <span>{_.sum(_.map(props.data, 'Dilihat'))}</span>
                            </div>
                        </td>
                        <td style={{ width: '22%' }}>
                            <div className="stat-bg-4">
                                <label>{i18n.t("Statistic:pendapatan.total_difavoritkan")}</label>
                                <span>{_.sum(_.map(props.data, 'Difavoritkan'))}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="mb15">
                <label className="mr20">{i18n.t("Statistic:tampilkan.title")}</label>
                <select className="form-control w150 inline-block" value={props.show_active} onChange={props.onChangeShow} disabled={props.LoadingShowBy || props.data.length === 0 ? 'disabled' : ''}>
                    <option value="bulanan">{i18n.t("Statistic:tampilkan.bulanan")}</option>
                    <option value="mingguan">{i18n.t("Statistic:tampilkan.mingguan")}</option>
                    <option value="harian">{i18n.t("Statistic:tampilkan.harian")}</option>
                </select>
                {
                    props.LoadingShowBy ?
                        <div>
                            <ReactLoading type="spinningBubbles" color="#0C7F8F" width={20} height={20} />
                        </div> : ''
                }
            </div>
            <div className="row mb15">
                <div className="col-md-6">
                    <span className="title-graphic">{i18n.t("Statistic:pendapatan.grafik")}</span>
                    <div style={{ width: '100%', height: 435 }}>
                        <ResponsiveContainer>
                            <AreaChart
                                width={600}
                                height={300}
                                data={props.data}
                                margin={{
                                    top: 10, right: 30, left: 0, bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="label" height={100} tick={props.show_active === "bulanan" ? <TickBulanan /> : props.show_active === "mingguan" ? <TickMingguan/> : <CustomizedAxisTick />} minTickGap={3} tickSize={3} interval={0}/>
                                <YAxis type="number" tickFormatter={DataFormater} allowDecimals={false} label={{ value: 'Rupiah', angle: -90, position: 'insideLeft' }} />
                                <Tooltip labelFormatter={(value) => props.show_active === "bulanan" ? moment(value).format('MMMM YYYY') : props.show_active === "mingguan" ? moment(value).format("DD MMMM YYYY") + " - " + moment(value).add(6, 'days').format("DD MMMM YYYY") : moment(value).format("DD MMMM YYYY")} formatter={(value) => numberFormat(value)} />
                                <Area type="monotone" dataKey="pendapatan" stDataFormaterroke="#C2EFEF" fill="#C2EFEF" />
                                {
                                    props.data.length > 12 ?
                                        <Brush
                                            dataKey='name'
                                            height={20}
                                            stroke="#000000"
                                            y={415}
                                            startIndex={0}
                                            endIndex={11}>

                                            <AreaChart>
                                                <Area type="monotone" dataKey="pendapatan" stDataFormaterroke="#C2EFEF" fill="#C2EFEF" />
                                            </AreaChart>

                                        </Brush>
                                        : ''
                                }
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-md-6">
                    <span className="title-graphic">{i18n.t("Statistic:pendapatan.grafik_penjualan")}</span>
                    <div style={{ width: '100%', height: 430 }}>
                        <ResponsiveContainer>
                            <LineChart
                                width={600}
                                height={300}
                                data={props.data}
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="label" height={100} tick={props.show_active === "bulanan" ? <TickBulanan /> : props.show_active === "mingguan" ? <TickMingguan/> : <CustomizedAxisTick />} minTickGap={3} tickSize={3} interval={0} />
                                <YAxis allowDecimals={false} label={{ value: 'Unit', angle: -90, position: 'insideLeft' }} />
                                <Tooltip labelFormatter={(value) => props.show_active === "bulanan" ? moment(value).format('MMMM YYYY') : props.show_active === "mingguan" ? moment(value).format("DD MMMM YYYY") + " - " + moment(value).add(6, 'days').format("DD MMMM YYYY") : moment(value).format("DD MMMM YYYY")} formatter={(value) => value + " unit"} />
                                <Legend verticalAlign="top" align="right" height={36} />
                                <Line type="monotone" dataKey="Terjual" stroke="#36A2EB" strokeWidth={4} />
                                <Line type="monotone" dataKey="Dilihat" stroke="#FF6C86" strokeWidth={4} />
                                <Line type="monotone" dataKey="Difavoritkan" stroke="#FFCD56" strokeWidth={4} />
                                {
                                    props.data.length > 12 ?
                                        <Brush
                                            dataKey='name'
                                            height={20}
                                            stroke="#000000"
                                            y={410}
                                            startIndex={0}
                                            endIndex={11}>

                                            <LineChart>
                                                <Line type="step" dataKey="Terjual" stroke="#36A2EB" strokeWidth={4} />
                                                <Line type="step" dataKey="Dilihat" stroke="#FF6C86" strokeWidth={4} />
                                                <Line type="step" dataKey="Difavoritkan" stroke="#FFCD56" strokeWidth={4} />
                                            </LineChart>

                                        </Brush>
                                        : ''
                                }
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <a href="/#" style={{ display: props.show_table ? 'none' : '' }} data-id="open" onClick={props.onClickShowTable}>{i18n.t("Statistic:pendapatan.show_table")}</a>
            <a href="/#" style={{ display: !props.show_table ? 'none' : '' }} data-id="close" onClick={props.onClickShowTable}>{i18n.t("Statistic:pendapatan.hide_table")}</a>

            <div className="row mb15 mt15" style={{ display: !props.show_table ? 'none' : '' }}>
                <div className="col-md-6">
                    <div className="stat-table-container">
                        <span className="title-graphic">{i18n.t("Statistic:pendapatan.tabel_pendapatan.title")}</span>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{i18n.t("Statistic:pendapatan.tabel_pendapatan.periode")}</th>
                                    <th className="right">{i18n.t("Statistic:pendapatan.tabel_pendapatan.pendapatan")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.data.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                {
                                                    props.show_active === "bulanan" ? 
                                                        <td>{moment(data.label).format("MMMM YYYY")}</td>
                                                    : props.show_active === "mingguan" ? 
                                                        <td>{moment(data.label).format("DD MMMM YYYY")+" - "+moment(data.label).add(6, 'days').format("DD MMMM YYYY")}</td> :
                                                        <td>{moment(data.label).format("DD MMMM YYYY")}</td>
                                                }
                                                <td className="right">Rp &nbsp; <FormatUang value={data.pendapatan} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="stat-table-container">
                        <span className="title-graphic">{i18n.t("Statistic:pendapatan.tabel_penjualan.title")}</span>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{i18n.t("Statistic:pendapatan.tabel_penjualan.periode")}</th>
                                    <th width="125" className="center">{i18n.t("Statistic:pendapatan.tabel_penjualan.dilihat")}</th>
                                    <th width="125" className="center bg-2">{i18n.t("Statistic:pendapatan.tabel_penjualan.terjual")}</th>
                                    <th width="125" className="center">{i18n.t("Statistic:pendapatan.tabel_penjualan.difavoritkan")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.data.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                {
                                                    props.show_active === "bulanan" ? 
                                                        <td>{moment(data.label).format("MMMM YYYY")}</td>
                                                    : props.show_active === "mingguan" ? 
                                                        <td>{moment(data.label).format("DD MMMM YYYY")+" - "+moment(data.label).add(6, 'days').format("DD MMMM YYYY")}</td> :
                                                        <td>{moment(data.label).format("DD MMMM YYYY")}</td>
                                                }
                                                <td className="right">{data.Dilihat}</td>
                                                <td className="right">{data.Terjual}</td>
                                                <td className="right">{data.Difavoritkan}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PendapatanPerBarang;
