import React from 'react';
import ContentLoader from "react-content-loader";

const NotificationLoading = () => (
    <div className="container" style={{marginTop: "62px"}}>
                <div className="basic-container">
                <ContentLoader 
                height={30}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="75" height="10"/>
                <rect x="0" y="12" rx="0" ry="0" width="75" height="5"/>
            </ContentLoader>
                    <br />
                    <ContentLoader 
                height={10}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="20" height="4"/>
                <rect x="325" y="4" rx="0" ry="0" width="20" height="4"/>
                <rect x="350" y="0" rx="0" ry="0" width="50" height="10"/>
            </ContentLoader>

                    <div className="clearfix"></div>
                    <br />

                    <div className="fs13">
                    <ContentLoader 
                            height={190}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="450" height="30"/>
                            <rect x="0" y="33" rx="0" ry="0" width="450" height="30"/>
                            <rect x="0" y="66" rx="0" ry="0" width="450" height="30"/>
                            <rect x="0" y="99" rx="0" ry="0" width="450" height="30"/>
                            <rect x="0" y="132" rx="0" ry="0" width="450" height="30"/>
                            <rect x="0" y="165" rx="0" ry="0" width="450" height="30"/>
                        </ContentLoader>
                    </div>
                </div>
            </div>
)
export default NotificationLoading;