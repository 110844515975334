import {
    SET_NOTIFICATION,
    SET_NOTIFICATION_DATA
} from '../actions/types/notificationType'

const initialState = {
    message: "",
    data : []
}

export default function(state = initialState, action){
    switch(action.type){
        case SET_NOTIFICATION:
            return {
                ...state,
                message: action.payload
            }
        case SET_NOTIFICATION_DATA:
            return {
                ...state,
                data: action.payload
            }
        default : 
            return state
    }
}