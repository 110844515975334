import {
    SET_NOTIFICATION,
    SET_NOTIFICATION_DATA
} from './types/notificationType';


export const setNotification = data => dispatch => {
    dispatch({
        type: SET_NOTIFICATION,
        payload: data
    });
}


export const setNotificationData = data => dispatch => {
    dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: data
    });
}