/**
 * 
 * @Liberary
 */

import React, { Component } from 'react';
import { 
  BrowserRouter, 
  Switch, 
  Route, 
  Redirect 
} from "react-router-dom";
import { connect } from 'react-redux';
// import { API_URL } from './config'

/**
 * 
 * @PUBLIC
 */

import {
  Home,
  Page404,
  BlankPage,
  Category,
  Search,
  // DetailProduct,
  SingleShop,
  DetailProduct_temp,
} from './views/PUBLIC';


/**
 * 
 * @GUEST
 */

import {
  Register,
  ForgotPassword,
  ResetPassword,
  VerifyEmail
} from './views/GUEST';


/**
 * 
 * @AUTH MyShop
 */
import{
  Create,
  Products,
  ProfileShop,
  Wallet,
  Statistic,
} from './views/AUTH/Shop';

import { 
  Request,
  RequestResult,
  ListUser,
  DetailUser
} from './views/AUTH/Shop/Vip';


/**
 * 
 * @AUTH MyShop @Product
 */

import{
  CreateProduct,
  // ViewMyProduct,
  ViewMyProduct_v2,
  EditProduct
} from './views/AUTH/Shop/Product';


/**
 * 
 * @AUTH User
 */
import {
  Profile,
  Favourite,
  Subscribes,
  Notification,
  Message,
  MutasiHutang
} from './views/AUTH/User';


/**
 * 
 * @AUTH Transaction
 */
import {
  TransactionList,
  TransactionBuyerCreate,
  TransactionBuyerDetail,
  TransactionSellerDetail
} from './views/AUTH/Transaction';

import NetworkDetector from './views/PUBLIC/NetworkDetector'

/**
 * 
 * @socket
 */
import {
  privateChannel,
  // leaveChannel
} from './socket';

/**
 * 
 * @Action
 */
import {
  checkAndSetUserData,
  setCursorPosition
} from './actions/userAction';

import {
  setNotification
} from './actions/notificationAction';

import { 
  callNotification, 
} from './helper';

function AuthRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return rest.token
        ? <Component {...props} />
        : <BlankPage {...props}/>}
      }
    />
  )
}

function GuestRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !rest.token
        ? <Component {...props} />
        : <Redirect to="/"/>}
      }
    />
  )
}




class App extends Component {

  componentDidMount(){
    if(this.props.userData.token)
    {
      privateChannel(this.props.userData)
      .notification(data => {
          this.props.setNotification(data.message);
          callNotification();
          this.props.checkAndSetUserData(this.props.userData.token);
      })
    }
  }


  pageClick = (e) => {
    this.props.setCursorPosition({x: e.screenX, y: e.screenY});
    // console.log("x : ", e.screenX)
    // console.log("y : ", e.screenY)
  }

  render(){
    
    let { token } = this.props.userData;
    return (
      <div onMouseMove={this.pageClick}>
      <BrowserRouter>
        <Switch>
            {
              /**
               * @GUEST
               */
            }
            <Route exact path={`/register`} component={Register} />
            <Route exact path={`/email_verify`} component={VerifyEmail} />            
            <GuestRoute exact path={`/forgot_password`} component={ForgotPassword} token={token}/>
            <GuestRoute exact path={`/reset_password/:token`} component={ResetPassword} token={token}/>
            
            {
              /**
               * @AUTH @Shop
               */
            }
            <AuthRoute exact path={`/myshop/create`} component={Create} token={token}/>
            <AuthRoute exact path={`/myshop`} component={Products} token={token}/>
            <AuthRoute exact path={`/myshop/profile`} component={ProfileShop} token={token}/>
            <AuthRoute exact path={`/wallet`} component={Wallet} token={token}/>
            <AuthRoute exact path={`/myshop/statistic`} component={Statistic} token={token} />
            <AuthRoute exact path={`/myshop/vip/registrasi`} component={Request} token={token} />
            <AuthRoute exact path={`/myshop/vip/result/:id`} component={RequestResult} token={token} />
            <AuthRoute exact path={`/myshop/vip/list`} component={ListUser} token={token} />
            <AuthRoute exact path={`/myshop/vip/detail/:id`} component={DetailUser} token={token} />
            {
              /**
               * @AUTH Shop @Product
               */
            }
            <AuthRoute exact path={`/myshop/product/create`} component={CreateProduct} token={token}/>
            <AuthRoute exact path={`/myshop/product/view/:id`} component={ViewMyProduct_v2} token={token}/>
            <AuthRoute exact path={`/myshop/product/edit/:id`} component={EditProduct} token={token}/>

            {
              /**
               * @AUTH @User
               */
            }
            <AuthRoute exact path={`/user/profile`} component={Profile} token={token}/>
            <AuthRoute exact path={`/user/favourite`} component={Favourite} token={token}/>
            <AuthRoute exact path={`/user/subscribe`} component={Subscribes} token={token} />
            <AuthRoute exact path={`/user/notification`} component={Notification} token={token} />
            <AuthRoute exact path={`/user/message`} component={Message} token={token} />
            <AuthRoute exact path={`/user/hutang`} component={MutasiHutang} token={token} />

            {
              /**
               * @AUTH @Transaction
               */
            }
            <AuthRoute exact path={`/transaction`} component={TransactionList} token={token}/>
            <AuthRoute exact path={`/transaction/create`} component={TransactionBuyerCreate} token={token}/>
            <AuthRoute exact path={`/transaction/detail/:id`} component={TransactionBuyerDetail} token={token}/>
            <AuthRoute exact path={`/transaction/seller/:id`} component={TransactionSellerDetail} token={token}/>

            {
              /**
               * @PUBLIC
               */
            }
            <Route exact path={`/`} component={Home} />
            <Route exact path={`/category/:id/:sub_id?`} component={Category}/>
            {/* <Route exact path={`/product/:id`} component={DetailProduct}/> */}
            <Route exact path={`/product/:id`} component={DetailProduct_temp}/>
            <Route exact path={`/search/:keyword`} component={Search}/>
            <Route exact path={`/:shop`} component={SingleShop}/>
            <Route component={Page404} />
          
        </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userData : state.user.user
  }
}

export default NetworkDetector(connect(mapStateToProps, {
  checkAndSetUserData,
  setNotification,
  setCursorPosition
})(App));