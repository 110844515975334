import React from 'react'
import EmptyResult from "../Global/EmptyResult"
import { FormatUang } from '../../helper'
import imageEmpty from '../../assert/icon/empty-result.png'
import ReactLoading from 'react-loading';
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next'
// import logo_vip from '../../assert/icon/vip_logo.svg';

const MyProductList = (props) => {
    
    const renderLabel = (status) => {
        if(status === 1)
        {
            return <td><span className="label label-productactive">{i18n.t("MyProduct:aktif")}</span></td>
        }
        else if(status === 2)
        {
            return <td><span className="label label-productnonactive">{i18n.t("MyProduct:nonaktif")}</span></td>
        }
        else
        {
            return null
        }
    }

    if(props.loading){
        return <p>{i18n.t("MyProduct:loading")}</p>
    }else{
        if(props.totalData > 0){
            return (
                <table className="table table-product table-hover" style={{display : props.grid ? "none": "",cursor : "pointer"}}>
                    <thead>
                        <tr>
                            <th className="bulk-check w50"></th>
                            <th className="w75">{i18n.t("MyProduct:list.gambar")}</th>
                            <th>{i18n.t("MyProduct:list.nama")}</th>
                            { props.status === 3 ? <th>{i18n.t("MyProduct:list.status")}</th> : null }
                            <th>{i18n.t("MyProduct:list.harga")}</th>
                            <th>{i18n.t("MyProduct:list.lihat")}</th>
                            <th>{i18n.t("MyProduct:list.suka")}</th>
                            <th>{i18n.t("MyProduct:list.jual")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            props.data.map(product => (
                                <tr key={product.id} onClick={() => props.onClickProductList(product.id)}>
                                    <td className="bulk-check">
                                    {
                                        props.activeChecked
                                        ? <input 
                                            type="checkbox"
                                            checked={props.checkedProduct(product.id)} 
                                            onChange={() => props.onChangeProduct(product.id)}/>
                                        : null
                                    }
                                    </td>
                                    <td><img src={product.image} className="img-fluid" alt="Agrinis"/></td>
                                    <td><span className="product-name">{product.name}</span></td>
                                    { props.status === 3 ? renderLabel(product.status) : null }
                                    <td>
                                        {/* <img src={logo_vip} alt="LOGO VIP"/>  */}
                                    Rp <FormatUang value={product.price}/>
                                    </td>
                                    <td>{product.viewer}</td>
                                    <td>{product.like}</td>
                                    <td>{product.terjual}</td>
                                </tr>
                            )) 
                        }
                        {
                            props.hasMoreData
                            ? <tr>
                                <td colSpan="7" align="center">
                                    {
                                        props.loadingMoreLoading
                                        ? <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} />
                                        : <div className="text-center">
                                            <button className="btn btn-solid primary" onClick={props.fetchMoreData}>{i18n.t("MyProduct:list.lainnya")}</button>
                                        </div>
                                    }
                                    
                                </td>
                            </tr>
                            : null
                        }
                    </tbody>
                </table>
            )
        }else{
            return <EmptyResult 
            image={imageEmpty}
            message="Tidak ada barang"
            buttonAction={props.emtyDataAction}
            buttonText="Tambah Barang"
        />
        }
    }
    
}

export default withNamespaces("MyProduct")(MyProductList)
