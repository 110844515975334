export const MY_SHOP = "MY_SHOP";
export const USER_DATA = "USER_DATA";
export const AFTER_VERIFY_EMAIL = "AFTER_VERIFY_EMAIL";
export const SET_SHOP_DATA = "SET_SHOP_DATA";
export const UPDATE_LIKES_PRODUCT = "UPDATE_LIKES_PRODUCT";
export const SUBSCRIB_SHOP = "SUBSCRIB_SHOP";
export const UN_SUBSCRIB_SHOP = "UN_SUBSCRIB_SHOP";
export const SET_ADDRESS = "SET_ADDRESS";
export const SEND_EMAIL = "SEND_EMAIL";
export const CLOSE_NOTIF_EMAIL = "CLOSE_NOTIF_EMAIL";
export const SET_IMAGE_PROFILE = "SET_IMAGE_PROFILE";
export const SET_TOKEN = "SET_TOKEN";
export const SUBSCRIB_DATA = "SUBSCRIB_DATA";
export const SET_SALDO = "SET_SALDO";
export const SET_CURSOR_POSITION = "SET_CURSOR_POSITION";