import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import { CSSTransition } from 'react-transition-group';
import ReactLoading from 'react-loading';
import Geocode from "react-geocode";

import {
    fetchDistricts
} from '../../../actions/shopActions'
import {
    toggleModalLogin
} from '../../../actions/registerActions';

import {
    NewHeader,
    // MapComponent
} from "../../../components";

import {
    authGet,
    AlertError,
    get,
    authPost,
    Toast,
    AlertWarning,
    ConfirmWarning,
    AlertInfo,
    authPostFile
} from '../../../helper';
import i18n from '../../../i18n';
import { API_GMAPS_KEY } from '../../../config';
import ProfileLoading from '../../../components/Loading/ProfileLoading';
import NetworkDetector from '../../PUBLIC/NetworkDetector'
// import { LoadScript } from '@react-google-maps/api';
// const libraries = ['places'];

Geocode.setApiKey(API_GMAPS_KEY);
Geocode.enableDebug();
Geocode.setLanguage("id");
Geocode.setRegion("id");

const Loading = () => <div className="loading-btn mb20" style={{marginRight: "50px"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

class ProfileShop extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            main_url : {},
            addresses: [],
            shipments: [],
            shipment_elses: [],
            checkedShipment: {},
            mode_shipment: false,
            bank_accounts: [],
            master_banks: [],
            modal_toko: {
                input_name: "",
                input_domain: "",
                input_no_telp: "",
            },
            defaultDistrict: [],
            district: '',
            modal_edit_alamat: {},
            modal_create_alamat: {
                input_name_address: '',
                input_zip_portal: '',
                input_address: '',
                lat: '',
                lng: '',
            },
            g_adrress: null,
            g_position: {
                lat: parseFloat(0.0),
                lng: parseFloat(0.0)
            },
            modal_edit_bank: {},
            modal_create_bank: {
                input_bank_id: '',
                input_account_name: '',
                input_account_number: ''
            },
            loadingUpdateTokoProfile: false,
            loadingUpdateTokoAddress: false,
            loadingCreateTokoAddress: false,
            loadingAddTokoShipment: false,
            loadingAddTokoBank: false,
            loadingUpdateTokoBank: false,
            loadingUploadImage: false,
            kode_inti: null,
            kodeInti: false,
            input_kode_inti: ""
        }
    }

    componentDidMount(){
        this.timer = null;
        // this.props.fetchDistricts();
        authGet(`shop_profile`, this.props.userData.token)
        .then(res => {
            this.setState({
                loadingPage: false, 
                ...res.data,
                modal_toko : {
                    input_name : res.data.name,
                    input_domain : res.data.domain,
                    input_no_telp: res.data.telephone ? res.data.telephone : "",
                },
                checkedShipment: {
                    ...this.defaultCheckedShipment(res.data.shipment_elses),
                    ...this.defaultCheckedShipment(res.data.shipments)
                },
                mode_shipment : true,
                kodeInti: res.data.kode_inti ? true : false,
                input_kode_inti : res.data.kode_inti ? res.data.kode_inti : ""
            });
        })
        .catch(err => {
            this.setState({loadingPage: false});
            AlertError.fire({text: err.response.data.message});
        })

        this.getCurrentLocation();
    }

    /**
     * 
     * @Profile Toko
     */

    onChangeModalToko = (e) => {
        this.setState({
            modal_toko : {
                ...this.state.modal_toko,
                [e.target.name] : e.target.value
            }
        });
    }

    onClickModalToko = (e) => {
        e.preventDefault();
        let data = {
            name : this.state.modal_toko.input_name,
            domain : this.state.modal_toko.input_domain,
            telephone: this.state.modal_toko.input_no_telp
        }

        if(data.name === "" || data.domain === ""){
            AlertError.fire({text: i18n.t("ProfileShop:validation.data_kosong")});
            return false;
        }

        ConfirmWarning.fire({
            text: i18n.t("ProfileShop:confirm_ubah")
        }).then(result => {
            if(result.value){
                if(this.validateDomain(data.domain)){
                    this.setState({loadingUpdateTokoProfile: true});
                    authPost({kode: this.state.input_kode_inti},`shop/find_client`, this.props.userData.token)
                    .then(response => {
                        data.client_id = response.data.id ? response.data.id : null;
                        let kode_inti = response.data.kode_inti ? response.data.kode_inti : null;
                        authPost(data, `shop_profile/update`, this.props.userData.token)
                        .then(res => {
                            this.setState({
                                ...this.state,
                                ...res.data,
                                loadingUpdateTokoProfile: false,
                                kode_inti: kode_inti
                            });
                            Toast.fire({
                                type: "success",
                                title: i18n.t("ProfileShop:success_ubah")
                            })

                            window.$('#modalUbahProfilToko').modal('hide');
                        })
                        .catch(err => {
                            AlertError.fire({text: err.response.data.message});
                            this.setState({loadingUpdateTokoProfile: false});
                        })
                    })
                    .catch(err => {
                        AlertError.fire({text: err.response.data.message});
                        this.setState({loadingUpdateTokoProfile: false});
                    })
                }
                else{
                    AlertError.fire({text: i18n.t("ProfileShop:validation.domain")});
                }
            }
        })
    }

    validateDomain(domain){
        let re = /^[a-zA-Z0-9_-]*$/;
        return re.test(String(domain).toLowerCase());
    }

    cangePhotoProfile = (e) => {
        let formData = new FormData();

        formData.append('photo', e.target.files[0]);
        this.setState({loadingUploadImage: true});
        authPostFile(formData, `shop_profile/update_photo`, this.props.userData.token)
        .then(res => {
            this.setState({
                loadingUploadImage: false,
                has_image: res.data.has_image,
                image: res.data.image,
            });
            Toast.fire({
                type: "success",
                title: i18n.t("ProfileShop:success_ubah")
            });
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message}).then(() => {
                this.setState({loadingUploadImage: false})
            })
        })
    }

    removeProfileImage = (e) => {
        e.preventDefault();

        ConfirmWarning.fire({
            text: i18n.t("ProfileShop:confirm_hapus")
        }).then(result => {
            if(result.value){
                this.setState({loadingUploadImage: true});
                authGet(`shop_profile/delete_photo`, this.props.userData.token)
                .then(res => {
                    Toast.fire({
                        type: "success",
                        title: i18n.t("ProfileShop:success_ubah")
                    });
        
                    this.setState({
                        has_image: res.data.has_image,
                        image: res.data.image,
                        loadingUploadImage: false
                    });
                })
                .catch(err => {
                    AlertError.fire({text : err.response.data.message}).then(() => {
                        this.setState({loadingUploadImage: false})
                    })
                })
            }
        })
    }

    /**
     * 
     * @Alamat Toko
     */
    loadOptions = (inputValue, callback) => {
        if(inputValue.length > 2){
            get(`indonesia/search/${inputValue}`)
            .then(res => {
                this.setState({defaultDistrict: res.data}, () => {
                    callback(this.state.defaultDistrict);
                });
            })
        }else{
            callback([]);
        }
    }

    handleChangeDistrict = (newValue) => {
        this.setState({district : newValue});
    };


    loadOptionsEdit = (inputValue, callback) => {
        if(inputValue.length > 2){
            get(`indonesia/search/${inputValue}`)
            .then(res => {
                callback(res.data);
            })
        }else{
            callback([]);
        }
    }

    handleChangeDistrictEdit = (newValue) => {
        this.setState({
            modal_edit_alamat : {
                ...this.state.modal_edit_alamat,
                districts : newValue
            }
        });
    };

    setModalEditAlamat(address){
        this.setState({
            g_position: {
                lat: parseFloat(address.lat),
                lng: parseFloat(address.lng)
            },
            modal_edit_alamat : {
                id: address.id,
                input_nama_alamat : address.name ? address.name : "",
                districts: address.districts,
                input_zip_portal: address.zip_portal,
                input_address : address.address,
                lat: address.lat,
                lng: address.lng,
                edit : true
            }
        }, () => {
            this.getCurrentLocation();
        });
    }

    onChangeCreateAlamat = (e) => {
        this.setState({
            modal_create_alamat : {
                ...this.state.modal_create_alamat,
                [e.target.name] : e.target.value
            }
        })
    }

    onChangeEditAlamat = (e) => {
        this.setState({
            modal_edit_alamat : {
                ...this.state.modal_edit_alamat,
                [e.target.name] : e.target.value
            }
        })
    }

    validateModalAlamat(data){
        if(data.name === ""){
            AlertWarning.fire({text: i18n.t("ProfileShop:validation.label")});
            return false;
        }
        else if(data.district === ""){
            AlertWarning.fire({text: i18n.t("ProfileShop:validation.kota")});
            return false;
        }
        else if(data.zip_portal === ""){
            AlertWarning.fire({text: i18n.t("ProfileSHop:validation.kodepos")});
            return false;
        }
        else if(data.address === ""){
            AlertWarning.fire({text: i18n.t("ProfileSHop:validation.alamat")});
            return false;
        }

        return true;
    }

    updateModalAlamat = (e) => {
        e.preventDefault();
        let data = {
            id: this.state.modal_edit_alamat.id,
            name : this.state.modal_edit_alamat.input_nama_alamat,
            district: this.state.modal_edit_alamat.districts,
            zip_portal: this.state.modal_edit_alamat.input_zip_portal,
            address: this.state.modal_edit_alamat.input_address,
            lat: this.state.g_position.lat,
            lng: this.state.g_position.lng,
        }

        if(this.validateModalAlamat(data)){
            this.setState({loadingUpdateTokoAddress: true})
            authPost(data, `shop_profile/update_address`, this.props.userData.token)
            .then(res => {
                this.setState({
                    loadingUpdateTokoAddress: false,
                    addresses: res.data.data
                });
                Toast.fire({
                    type: "success",
                    title: i18n.t("ProfileShop:success_ubah")
                });
                window.$('#modalUbahAlamat').modal('hide');
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message});
                this.setState({loadingUpdateTokoAddress: false});
            })
        }
    }

    getCurrentLocation() {
        if (this.state.g_position.lat === 0.0 || this.state.g_position.lng === 0.0) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.setState({
                        g_position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }, () => {
                        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                            response => {
                                const g_adrress = response.results[0].formatted_address;

                                this.setState({
                                    g_adrress: (g_adrress) ? g_adrress : ''
                                })
                            },
                            error => {
                                console.error(error);
                            }
                        );
                    });
                },
                (error) => console.log(error.message),
                { enableHighAccuracy: true, timeout: 10000, maximumAge: 1000 },
            );
        } else {
            Geocode.fromLatLng(this.state.g_position.lat, this.state.g_position.lng).then(
                response => {
                    const g_adrress = response.results[0].formatted_address;
                    this.setState({
                        g_adrress: (g_adrress) ? g_adrress : ''
                    })
                },
                error => {
                    console.error(error);
                }
            );
        }
    }

    createModalAlamat = (e) => {
        e.preventDefault();
        let data = {
            name : this.state.modal_create_alamat.input_name_address,
            district: this.state.district,
            zip_portal: this.state.modal_create_alamat.input_zip_portal,
            address: this.state.modal_create_alamat.input_address,
            lat: this.state.g_position.lat,
            lng: this.state.g_position.lng,
        }

        if(this.validateModalAlamat(data)){
            this.setState({loadingCreateTokoAddress: true});
            authPost(data, `shop_profile/store_address`, this.props.userData.token)
            .then(res => {
                this.setState({
                    loadingCreateTokoAddress: false,
                    addresses: res.data.data,
                    modal_create_alamat: {
                        input_name_address: '',
                        input_zip_portal: '',
                        input_address: ''
                    },
                });
                Toast.fire({
                    type: "success",
                    title: i18n.t("ProfileShop:success_ubah")
                });
                window.$('#modalTambahAlamat').modal('hide');
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message});
                this.setState({loadingCreateTokoAddress: false});
            })
        }
    }

    removeAlamat = (e, address) => {
        e.preventDefault();
        let { addresses } = this.state;

        if(addresses.length < 2){
            AlertInfo.fire({text: i18n.t("ProfileShop:validation.alamat_required")});
            return false;
        }

        ConfirmWarning.fire({
            text: i18n.t("ProfileShop:confirm_hapus")
        }).then(result => {
            if(result.value){
                authPost(address, `shop_profile/remove_address`, this.props.userData.token)
                .then(res => {
                    this.setState({addresses: res.data.data,})
                    Toast.fire({
                        type: "success",
                        title: i18n.t("ProfileShop:success_hapus")
                    });
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }
    onChangeGMaps = (e) => {
        this.setState({ place: e.description }
            , () => {
                Geocode.fromAddress(e.description).then(
                    response => {
                        const coordinates = response.results[0].geometry.location;
                        const g_adrress = response.results[0].formatted_address;
                        this.setState({
                            g_position: {
                                lat: coordinates.lat,
                                lng: coordinates.lng
                            },
                            g_adrress: (g_adrress) ? g_adrress : '',
                        });
                    },
                    error => {
                        console.error("From Address: " + error);
                    }
                );
            }
        )
    }
    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const g_adrress = response.results[0].formatted_address;
                this.setState({
                    g_position: {
                        lat: newLat,
                        lng: newLng
                    },
                    g_adrress: (g_adrress) ? g_adrress : '',
                });
            },
            error => {
                console.error(error);
            }
        );
    };
    

    /**
     * 
     * @Shipment Toko
     */

    defaultCheckedShipment(data){
        let tempChecked = {};
        _.forEach(data, item => {
            tempChecked[item.id] = false;
        });
        return tempChecked;
    }

    removeShipment = (e, shipment) => {
        e.preventDefault();
        let { shipments } = this.state;

        if(shipments.length < 2){
            AlertInfo.fire({text: i18n.t("ProfileShop:validation.pengiriman")});
            return false;
        }

        ConfirmWarning.fire({
            text: i18n.t("ProfileShop:confirm_hapus")
        }).then(result => {
            if(result.value){
                authPost(shipment, `shop_profile/remove_shipment`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        shipments: res.data.shipments,
                        shipment_elses: res.data.shipment_elses,
                        checkedShipment: {
                            ...this.defaultCheckedShipment(res.data.shipment_elses),
                            ...this.defaultCheckedShipment(res.data.shipments)
                        },
                    })
                    Toast.fire({
                        type: "success",
                        title: i18n.t("ProfileSHop:success_hapus")
                    });
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }

    onChangeShipment(shipment_else){
        this.setState({
            checkedShipment: {
                ...this.state.checkedShipment,
                [shipment_else.id] : !this.state.checkedShipment[shipment_else.id]
            }
        })
    }

    onClickModalShipment = (e) => {
        e.preventDefault();
        let shipment = _.keys(_.pickBy(this.state.checkedShipment));

        if(shipment.length < 1){
            AlertInfo.fire({text: i18n.t("ProfileShop:validation.pengiriman_modal")});
            return false;
        }

        this.setState({loadingAddTokoShipment: true});
        authPost({data : shipment}, `shop_profile/add_shipment`, this.props.userData.token)
        .then(res => {
            this.setState({
                loadingAddTokoShipment: false,
                shipments: res.data.shipments,
                shipment_elses: res.data.shipment_elses,
                checkedShipment: {
                    ...this.defaultCheckedShipment(res.data.shipment_elses),
                    ...this.defaultCheckedShipment(res.data.shipments)
                },
            });
            Toast.fire({
                type: "success",
                title: i18n.t("ProfileShop:success_tambah")
            });
            window.$('#modalTambahJasaPengiriman').modal('hide');
        })
        .catch(err => {
            this.setState({loadingAddTokoShipment: false});
            AlertError.fire({text: err.response.data.message});
        })
    }

    
    /**
     * 
     * @Bank Toko
     */

    validateModalBank(data){
        if(data.bank_id === 0){
            AlertWarning.fire({text: i18n.t("ProfileShop:validation.bank")});
            return false;
        }
        else if(data.nama_rekening === ""){
            AlertWarning.fire({text: i18n.t("ProfileShop:validation.nama_rek")});
            return false;
        }
        else if(data.nomor_rekening === ""){
            AlertWarning.fire({text: i18n.t("ProfileShop:validation.no_rek")});
            return false;
        }

        return true;
    }

    removeBank = (e, bank_account) => {
        e.preventDefault();

        ConfirmWarning.fire({
            text: i18n.t("ProfileShop:confirm_hapus")
        }).then(result => {
            if(result.value){
                authPost(bank_account, `shop_profile/remove_bank`, this.props.userData.token)
                .then(res => {
                    this.setState({bank_accounts: res.data})
                    Toast.fire({
                        type: "success",
                        title: i18n.t("ProfileShop:success_hapus")
                    });
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }

    onChangeModalBank = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            modal_create_bank : {
                ...this.state.modal_create_bank,
                [e.target.name] : e.target.name === 'input_account_number' ? validateValue : e.target.value
            }
        })
    }

    onClickAddBank = (e) => {
        e.preventDefault();

        let data = {
            bank_id : this.state.modal_create_bank.input_bank_id,
            nama_rekening: this.state.modal_create_bank.input_account_name,
            nomor_rekening: this.state.modal_create_bank.input_account_number
        }

        if(this.validateModalBank(data)){
            this.setState({loadingAddTokoBank: true});
            authPost(data, `shop_profile/add_bank`, this.props.userData.token)
            .then(res => {
                this.setState({
                    loadingAddTokoBank: false,
                    modal_create_bank : {
                        input_bank_id: '',
                        input_account_name: '',
                        input_account_number: ''
                    },
                    bank_accounts: res.data
                });
                Toast.fire({
                    type: "success",
                    title: i18n.t("ProfileShop:success_tambah")
                });
                window.$('#modalTambahRekening').modal('hide');
            })
            .catch(err => {
                this.setState({loadingAddTokoBank: false});
                AlertError.fire({text: err.response.data.message});
            })
        }
    }

    onChangeModalBankEdit = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            modal_edit_bank : {
                ...this.state.modal_edit_bank,
                [e.target.name] : e.target.name === 'input_account_number' ? validateValue : e.target.value
            }
        })
    }

    setModalEditBank = (bank_account) => {
        this.setState({
            modal_edit_bank : {
                id: bank_account.id,
                input_bank_id: bank_account.bank_id,
                input_account_name: bank_account.account_name,
                input_account_number: bank_account.account_number,
                edit : true
            }
        })
    }

    onClickUpdateBank = (e) => {
        e.preventDefault();

        let data = {
            id: this.state.modal_edit_bank.id,
            master_bank_id : this.state.modal_edit_bank.input_bank_id,
            nama_rekening: this.state.modal_edit_bank.input_account_name,
            nomor_rekening: this.state.modal_edit_bank.input_account_number
        }

        if(this.validateModalBank(data)){
            this.setState({loadingUpdateTokoBank: true});
            authPost(data, `shop_profile/update_bank`, this.props.userData.token)
            .then(res => {
                this.setState({
                    loadingUpdateTokoBank: false,
                    bank_accounts: res.data
                });
                Toast.fire({
                    type: "success",
                    title: i18n.t("ProfileShop:success_ubah")
                });
                window.$('#modalUbahRekening').modal('hide');
            })
            .catch(err => {
                this.setState({loadingUpdateTokoBank: false});
                AlertError.fire({text: err.response.data.message});
            })
        }
    }

    onChangeInputeKodeInti = (e) => {
        this.setState({
            input_kode_inti: e.target.value
        })
    }

    render() {

        let Page = (
            <div className="container">
                <div className="basic-container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="fas fa-home mr0"></i></Link></li>
                            <li className="breadcrumb-item"><Link to="/myshop">{i18n.t("ProfileShop:toko")}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{i18n.t("ProfileShop:profil_toko")}</li>
                        </ol>
                    </nav>
                    <br />
                    <h1 className="page-title">{i18n.t("ProfileShop:profil_toko")}</h1>
                    <div className="pull-left mr20 text-center">
                        <div className="user-profile-picture">
                            <img src={this.state.image} alt="Profil Toko" className="profile-picture lg" />
                                {
                                    this.state.loadingUploadImage
                                    ? <div style={{position: "absolute", top: "35%", left: "35%"}}>
                                        <ReactLoading type="spin" color="#0C7F8F" width={30}/>
                                    </div>
                                    : null
                                }
                            <div className="action-profile-picture">
                                <input id="myInput" type="file" ref={(ref) => this.uploadPhoto = ref} style={{ display: 'none' }} onChange={this.cangePhotoProfile} accept=".png, .jpg, .jpeg"/>
                                <a href="/" className="mr10 fs15" onClick={(e) => {e.preventDefault(); this.uploadPhoto.click()}}><i className="fas fa-camera"></i></a>
                                {
                                    this.state.has_image
                                    ? <a href="/" className="red fs15" onClick={this.removeProfileImage}><i className="fas fa-trash-alt"></i></a>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="pull-left">
                        <span className="block fs25 semibold">{this.state.name}</span>
                        {
                            this.state.city === "-"
                            ? null
                            : <span className="block fs13"><i className="fas fa-map-marker-alt"></i> {this.state.city}</span>
                        }
                    </div>
                    <div className="clearfix"></div>
                    <br />

                    <div className="fs13">
                        <a href="/" className="pull-right mr10 mt10" data-toggle="modal" data-target="#modalUbahProfilToko"><i className="fas fa-pencil-alt mr5"></i>{i18n.t("ProfileShop:ubah")}</a>
                        <span className="profile-title">{i18n.t("ProfileShop:profil_toko").toUpperCase()}</span>
                        <table width="100%" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td className="grey w150">{i18n.t("ProfileShop:nama")}</td>
                                    <td><span>{this.state.name}</span></td>
                                </tr>
                                <tr>
                                    <td className="grey">{i18n.t("ProfileShop:domain")}</td>
                                    <td><span><Link to={`/${this.state.domain}`}>{`${this.state.main_url.host}/${this.state.domain}`}</Link></span></td>
                                </tr>
                                <tr>
                                    <td className="grey w150">{i18n.t("ProfileShop:telepon")}</td>
                                    <td><span>{this.state.telephone}</span></td>
                                </tr>
                                {
                                    this.state.kode_inti
                                    ? <tr>
                                            <td className="grey w150">Kode Inti</td>
                                            <td><span>{this.state.kode_inti}</span></td>
                                        </tr>
                                    : null
                                }
                                
                            </tbody>
                        </table>

                        <span className="profile-title">{i18n.t("ProfileShop:alamat_title")}</span>
                        {/* <a href="/" className="btn btn-outline primary mt15 mb20" data-toggle="modal" data-target="#modalTambahAlamat"><i className="fas fa-plus"></i> Tambah Alamat</a> */}
                        {
                        this.state.addresses.map(address => (
                        <div className="p10" key={address.id}>
                            <p className="bold fs15 mb0">{address.name} <a href="/#" data-toggle="modal" data-target="#modalUbahAlamat" onClick={() => this.setModalEditAlamat(address)}><i className="fas fa-pencil-alt fs13 mr0 ml10"></i></a></p>
                            <p>{address.address},<br />
                                {address.district}, {address.city} {address.zip_portal}
				            </p>
                        </div>
                        ))
                        }
                        {/* <table className="table table-form w500" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td>Alamat</td>
                                    <td></td>
                                </tr>
                                {
                                    this.state.addresses.map(address => (
                                        <tr key={address.id}>
                                            <td>
                                                <b>{address.name}</b>
                                                <p>{address.address}<br />
                                                {address.district}, {address.city} {address.zip_portal}
                                                </p>
                                            </td>
                                            <td valign="top" align="right">
                                                <a href="/" className="mr10" data-toggle="modal" data-target="#modalUbahAlamat" onClick={() => this.setModalEditAlamat(address)}><i className="fas fa-pencil-alt"></i></a>
                                                <a href="/" className="red" onClick={(e) => this.removeAlamat(e, address)}><i className="fas fa-trash-alt"></i></a>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table> */}

                        <span className="profile-title">{i18n.t("ProfileShop:pengiriman_title")}</span>
                        <div className="w400">
                            {
                                this.state.shipments.map(shipment => (
                                    <div className="box-outline" key={shipment.id}>
                                        <table cellPadding="10" width="100%">
                                            <tbody>
                                                <tr>
                                                    <td className="w100"><img src={shipment.image} alt="Self Pick Up" /></td>
                                                    <td>{shipment.name}</td>
                                                    <td align="right">
                                                        <a href="/" className="red" onClick={(e) => this.removeShipment(e, shipment)}><i className="fas fa-trash-alt"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))
                            }
                        </div>
                        <a href="/" className="btn btn-outline primary mt15 mb20" data-toggle="modal" data-target="#modalTambahJasaPengiriman"><i className="fas fa-plus"></i> {i18n.t("ProfileShop:tambah_pengiriman")}</a>

                        <span className="profile-title">{i18n.t("ProfileShop:rekening_title")}</span>
                        <table className="table table-form w500" cellPadding="5">
                            <tbody>
                                {
                                    this.state.bank_accounts.map(bank_account => (
                                        <tr key={bank_account.id}>
                                            <td valign="middle" width="100">
                                                <img src={bank_account.bank_image} alt={bank_account.bank_name} />
                                            </td>
                                            <td>
                                                <span className="grey">{bank_account.bank_name}</span><br />
                                                <p>{bank_account.account_number}<br />
                                                {bank_account.account_name}</p>
                                            </td>
                                            <td valign="top" align="right">
                                                <a href="/" className="mr10" data-toggle="modal" data-target="#modalUbahRekening" onClick={() => this.setModalEditBank(bank_account)}><i className="fas fa-pencil-alt"></i></a>
                                                <a href="/" className="red" onClick={(e) => this.removeBank(e, bank_account)}><i className="fas fa-trash-alt"></i></a>
                                            </td>
                                        </tr>
                                    ))
                                }
                                
                            </tbody>
                        </table>
                        <a href="/" className="btn btn-outline primary mt15 mb20" data-toggle="modal" data-target="#modalTambahRekening"><i className="fas fa-plus"></i> {i18n.t("ProfileShop:tambah_rekening")}</a>
                    </div>
                </div>
            </div>
        )

        return (
            <div className="mg-top">
                {/* <LoadScript
                    id="script-loader"
                    googleMapsApiKey={API_GMAPS_KEY}
                    language="id"
                    region="id"
                    libraries={libraries}
                >
                </LoadScript> */}
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <ProfileLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {Page}
                </CSSTransition>

                {
                    /**
                     * PROFILE TOKO
                     */

                    this.state.name
                    ? <div className="modal fade modal-2" id="modalUbahProfilToko" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <form>
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                        <h2>{i18n.t("ProfileShop:modal_profil.title")}</h2>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_profil.nama")}</label><br />
                                            <input 
                                                name="input_name"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_toko.input_name} 
                                                onChange={this.onChangeModalToko}/>
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_profil.domain")}</label><br />
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <span className="inline-block grey mt7">agrinis.com/</span>
                                                </div>
                                                <div className="col-md-9">
                                                    <input 
                                                        name="input_domain"
                                                        type="text" 
                                                        className={`form-control`} 
                                                        placeholder={i18n.t("ProfileShop:modal_profil.domain_placeholder")}
                                                        value={this.state.modal_toko.input_domain} 
                                                        onChange={this.onChangeModalToko}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_profil.notelp")}</label><br />
                                            <input 
                                                name="input_no_telp"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_toko.input_no_telp} 
                                                onChange={this.onChangeModalToko}/>
                                        </div>
                                        <hr/>
                                        {
                                            this.state.input_kode_inti === ""
                                            ? <p style={{display: this.state.kodeInti ? "none" : ""}}>
                                                Apakah anda merupakan perusahaan inti? 
                                                <a href="/#" onClick={(e) => {e.preventDefault();this.setState({kodeInti: !this.state.kodeInti})}}>Masukkan Kode Inti</a>
                                            </p>
                                            : null
                                        }
                                        
                                        <div className="form-group-div" id="form_referalcode" style={{display: this.state.kodeInti || this.state.input_kode_inti !== "" ? "" : "none"}}>
                                            <label>Kode Inti</label><br />
                                            <input type="text" className="form-control" placeholder="Tulis Kode Inti" value={this.state.input_kode_inti} onChange={this.onChangeInputeKodeInti}/>
                                            {
                                                this.state.input_kode_inti === ""
                                                ? <a href="/#" onClick={(e) => {e.preventDefault();this.setState({kodeInti: false})}}>Batal</a>
                                                : null
                                            }
                                            
                                        </div>
                                        {/* <div className="form-group-div">
                                            <label>Kode Inti</label><br />
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Tulis Kode Inti" 
                                                value={this.state.input_kode_inti} 
                                                onChange={this.onChangeInputeKodeInti}/>
                                        </div> */}
                                    </div>
                                    <div className="modal-footer">
                                        {
                                            this.state.loadingUpdateTokoProfile
                                            ? <Loading/>
                                            : <React.Fragment>
                                                <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("ProfileShop:batal")}</button>
                                                <button className="btn btn-solid primary btn-sm" onClick={this.onClickModalToko}>{i18n.t("ProfileShop:simpan")}</button>
                                            </React.Fragment>
                                        }
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : null
                }
                
                <div className="modal fade modal-2" id="modalTambahAlamat" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{i18n.t("ProfileShop:modal_alamat.title")}</h2>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_alamat.label")}</label><br />
                                        <input 
                                            name="input_name_address"
                                            type="text" 
                                            className="form-group-div form-warning" 
                                            value={this.state.modal_create_alamat.input_name_address}
                                            onChange={this.onChangeCreateAlamat}/>
                                    </div>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_alamat.alamat")}</label><br />
                                        <textarea
                                            name="input_address"
                                            className="form-control"
                                            placeholder={i18n.t("ProfileShop:modal_alamat.alamat_placeholder")}
                                            value={this.state.modal_create_alamat.input_address}
                                            onChange={this.onChangeCreateAlamat}
                                        ></textarea>
                                    </div>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_alamat.kota")}</label><br />
                                        <AsyncSelect
                                            defaultOptions={false}
                                            loadOptions={this.loadOptions}
                                            onChange={this.handleChangeDistrict}
                                            placeholder={i18n.t("ProfileShop:modal_alamat.kota_placeholder")}
                                            noOptionsMessage={(val)=> null}
                                            value={this.state.district}
                                            />
                                    </div>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_alamat.kode_pos")}</label><br />
                                        <input 
                                            name="input_zip_portal"
                                            type="text" 
                                            className="form-control w200" 
                                            placeholder={i18n.t("ProfileShop:modal_alamat.kode_pos_placeholder")}
                                            value={this.state.modal_create_alamat.input_zip_portal}
                                            onChange={this.onChangeCreateAlamat}/>
                                    </div>

                                    {/* <div className="form-group-div">
                                        <label>Pilih Koordinat Lokasi</label><br />
                                        <LoadScript
                                            id="script-loader"
                                            googleMapsApiKey={API_GMAPS_KEY}
                                            language="id"
                                            region="id"
                                            libraries={libraries}
                                        >
                                            <MapComponent
                                                onChangeGMaps={this.onChangeGMaps}
                                                position={this.state.g_position}
                                                address={this.state.g_adrress}
                                                api_key={API_GMAPS_KEY}
                                                onMarkerDragEnd={this.onMarkerDragEnd}
                                            />
                                        </LoadScript>
                                    </div> */}
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingCreateTokoAddress
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("ProfileShop:batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.createModalAlamat}>{i18n.t("ProfileShop:simpan")}</button>
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    /**
                     * EDIT ALAMAT
                     */
                    this.state.modal_edit_alamat.edit
                    ? <div className="modal fade modal-2" id="modalUbahAlamat" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <form>
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                        <h2>{i18n.t("ProfileShop:modal_alamat.title_ubah")}</h2>
                                        <div className="form-group-div form-warning">
                                            <label>{i18n.t("ProfileShop:modal_alamat.label")}</label><br />
                                            <input
                                                name="input_nama_alamat"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_edit_alamat.input_nama_alamat}
                                                onChange={this.onChangeEditAlamat}/>
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_alamat.alamat")}</label><br />
                                            <textarea 
                                                name="input_address"
                                                className="form-control" 
                                                placeholder={i18n.t("ProfileShop:modal_alamat.alamat_placeholder")}
                                                value={this.state.modal_edit_alamat.input_address}
                                                onChange={this.onChangeEditAlamat}
                                                ></textarea>
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_alamat.kota")}</label><br />
                                            <AsyncSelect
                                                defaultOptions={false}
                                                loadOptions={this.loadOptionsEdit}
                                                // onInputChange={this.handleChange}
                                                onChange={this.handleChangeDistrictEdit}
                                                placeholder={i18n.t("ProfileShop:modal_alamat.kota_placeholder")}
                                                noOptionsMessage={(val)=> null}
                                                value={this.state.modal_edit_alamat.districts}
                                                />
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_alamat.kode_pos")}</label><br />
                                            <input 
                                                name="input_zip_portal"
                                                type="text" 
                                                className="form-control w200" 
                                                placeholder={i18n.t("ProfileShop:modal_alamat.kode_pos_placeholder")}
                                                value={this.state.modal_edit_alamat.input_zip_portal}
                                                onChange={this.onChangeEditAlamat}
                                                />
                                        </div>
                                        {/* <div className="form-group-div">
                                            <label>Pilih Koordinat Lokasi</label><br />
                                                    <MapComponent
                                                        onChangeGMaps={this.onChangeGMaps}
                                                        position={this.state.g_position}
                                                        address={this.state.g_adrress}
                                                        api_key={API_GMAPS_KEY}
                                                        onMarkerDragEnd={this.onMarkerDragEnd}
                                                    />
                                        </div> */}
                                    </div>
                                    <div className="modal-footer">
                                    {
                                        this.state.loadingUpdateTokoAddress
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("ProfileShop:batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.updateModalAlamat}>{i18n.t("ProfileShop:simpan")}</button>
                                        </React.Fragment>
                                    }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : null
                }


                    {/**
                     * SHIPMENT
                     */}

                {
                    this.state.mode_shipment
                    ? <div className="modal fade modal-2" id="modalTambahJasaPengiriman" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <form>
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                        <h2>{i18n.t("ProfileShop:modal_pengiriman.title")}</h2>
                                        {
                                            this.state.shipment_elses.map(shipment_else => (
                                                <div className="box-outline" key={shipment_else.id}>
                                                    <table cellPadding="10">
                                                        <tbody>
                                                            <tr>
                                                                <td className="w100"><img src={shipment_else.image} alt={shipment_else.name} /></td>
                                                                <td>
                                                                    <input 
                                                                        type="checkbox" 
                                                                        checked={this.state.checkedShipment[shipment_else.id]} 
                                                                        onChange={() => this.onChangeShipment(shipment_else)}
                                                                        />
                                                                </td>
                                                                <td>{shipment_else.name}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>
                                    {
                                        this.state.shipment_elses.length > 0
                                        ? <div className="modal-footer">
                                            {
                                                this.state.loadingAddTokoShipment
                                                ? <Loading/>
                                                : <React.Fragment>
                                                    <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("ProfileShop:batal")}</button>
                                                    <button className="btn btn-solid primary btn-sm" onClick={this.onClickModalShipment}>{i18n.t("ProfileShop:simpan")}</button>
                                                </React.Fragment>
                                            }
                                            
                                        </div>
                                        : null
                                    }
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                    : null
                }

                {/**
                * ADD BANK
                */}
                <div className="modal fade modal-2" id="modalTambahRekening" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{i18n.t("ProfileShop:modal_bank.title")}</h2>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_bank.bank")}</label><br />
                                        <select 
                                            name="input_bank_id"
                                            className="form-control" 
                                            onChange={this.onChangeModalBank}
                                            >
                                            <option value={0}>Pilih Bank</option>
                                            {
                                                this.state.master_banks.map(master_bank => (
                                                    <option key={master_bank.id} value={master_bank.id}>{master_bank.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_bank.namarek")}</label><br />
                                        <input 
                                            name="input_account_name"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.modal_create_bank.input_account_name}
                                            onChange={this.onChangeModalBank}
                                            />
                                    </div>
                                    <div className="form-group-div">
                                        <label>{i18n.t("ProfileShop:modal_bank.norek")}</label><br />
                                        <input
                                            name="input_account_number"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.modal_create_bank.input_account_number}
                                            onChange={this.onChangeModalBank}
                                            />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingAddTokoBank
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("ProfileShop:batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.onClickAddBank}>{i18n.t("ProfileShop:simpan")}</button>
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                {/**
                * EDIT BANK
                */}
                {
                    this.state.modal_edit_bank.edit
                    ? <div className="modal fade modal-2" id="modalUbahRekening" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <form>
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                        <h2>{i18n.t("ProfileShop:modal_bank.title_ubah")}</h2>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_bank.bank")}</label><br />
                                            <select 
                                                name="input_bank_id"
                                                className="form-control"
                                                value={this.state.modal_edit_bank.input_bank_id}
                                                onChange={this.onChangeModalBankEdit}
                                                >
                                                {
                                                    this.state.master_banks.map(master_bank => (
                                                        <option key={master_bank.id} value={master_bank.id}>{master_bank.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_bank.namarek")}</label><br />
                                            <input 
                                                name="input_account_name"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_edit_bank.input_account_name}
                                                onChange={this.onChangeModalBankEdit}
                                                />
                                        </div>
                                        <div className="form-group-div">
                                            <label>{i18n.t("ProfileShop:modal_bank.norek")}</label><br />
                                            <input 
                                                name="input_account_number"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_edit_bank.input_account_number}
                                                onChange={this.onChangeModalBankEdit}
                                                />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                    {
                                        this.state.loadingUpdateTokoBank
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("ProfileShop:batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.onClickUpdateBank}>{i18n.t("ProfileShop:simpan")}</button>
                                        </React.Fragment>
                                    }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : null
                }

            </div>
        )
    }
}

const mapStateToProps = state => {
    let userData = state.user.user;
    return {
        userData,
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    fetchDistricts,
    toggleModalLogin
})(ProfileShop));