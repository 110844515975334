import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from "lodash";
// import { URL } from "../../../config";
import ContentLoader from "react-content-loader";
import ReactLoading from 'react-loading';
const Loading = () => <div className="loading-btn"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"35px"} width={"35px"} /></div>
const ImageLoading = () => (
    <div style={{width:"250px", float: "left"}}>
        <ContentLoader 
            height={200}
            width={500}
            speed={2}
            primaryColor="#ffffff"
            secondaryColor="#ecebeb"
        >
            <circle cx="80" cy="80" r="80" /> 
            <rect x="178" y="23" rx="4" ry="4" width="311" height="25" /> 
            <rect x="185" y="72" rx="4" ry="4" width="198" height="23" /> 
            <rect x="515" y="-242" rx="5" ry="5" width="400" height="400" /> 
            <rect x="183" y="114" rx="4" ry="4" width="213" height="23" />
        </ContentLoader>
    </div>
)

export class HeaderBanner extends Component {
    
    render() {
        // console.log(this.props);
        let { shop } = this.props;
        let cities = "";
        _.forEach(shop.addresses.data, (d, i) => {
            let koma = i === (shop.addresses.data.length - 1) ? "" : ", ";
            cities += `${d.city}${koma}`;
        })
        return (
            <div className="container-fluid">
                <div className="header-banner-3 header-store">
                    <div className="header-content">
                        <div className="header-logo">
                            {shop.photo === null ? <ImageLoading/> : <img src={shop.photo} alt="Agrinis"/>}
                            {/* <span className="fs11 block text-center">Terakhir aktif 1 jam yang lalu</span> */}
                        </div>
                        <div className="header-storename">
                            <h1>{shop.name}</h1>
                            <p className="mb5"><i className="fas fa-map-marker-alt"></i>&nbsp;
                                {cities}
                            </p>
                        </div>
                        <div className="pull-right mt20 mr30">
                            {
                                this.props.isMyShop
                                ? null
                                : this.props.userSubsricbe
                                    ? this.props.loadingButton ? <React.Fragment><Loading /><br /></React.Fragment> : <a href="/#" className="btn btn-solid white" onClick={(e) => this.props.unSubscribe(e, shop.id)}><i className="fas fa-bookmark"></i>Berhenti Berlangganan</a>
                                    : this.props.loadingButton ? <React.Fragment><Loading /><br /></React.Fragment> : <a href="/#" className="btn btn-solid white" onClick={(e) => this.props.onSubscribe(e, shop.id)}><i className="far fa-bookmark"></i> Berlangganan</a>
                            }
                            {/* <a href="/#" className="btn btn-solid white"><i className="far fa-envelope"></i> Hubungi Penjual</a> */}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shop: state.shop.item
    }
}

export default connect(mapStateToProps, {})(HeaderBanner);
