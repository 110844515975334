import React from 'react';
import imageError from '../../assert/files/illustration/productnotfound.svg'

const ProductNotFound = () => (
    <React.Fragment>
    {/* <Header/> */}
    <div className="container-fluid">
        <div className="container p30 text-center">
            <img src={imageError} alt="Product Not Found"/>
            <h2>Ups! barang tidak ditemukan</h2>
            <p className="fs15">Barang tidak ditemukan atau sudah tidak tersedia.</p>
	    </div>
    </div>
    </React.Fragment>
)

export default ProductNotFound;