import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {
    URL
} from './config';

window.Pusher = Pusher;

const options = token => {
    return {
        broadcaster: 'pusher',
        authEndpoint: `${URL}/broadcasting/auth`,
        key: window.globalConfig.socket_key,
        cluster: window.globalConfig.socket_cluster,
        forceTLS: true,
    
        auth: {
            headers: {
                Authorization: token ? `Bearer ${token}`: "",
                Accept: 'application/json',
            },
        },
    }
};


export const privateChannel = userData => {
    return  new Echo(options(userData.token)).private(`App.User.${userData.user}`);
}

export const leaveChannel = (userData) => {
    return new Echo(options(userData.token)).disconnect();
}

export const MessageChannel = (userData, messageID) => {
    return new Echo(options(userData.token)).private(`message_chat${messageID}`);
}