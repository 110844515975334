import React from 'react'
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import {
    FormatUang
} from '../../helper';

const Ppn = styled.span`
    font-style: italic;
    font-size: 10px;
    color: #84C225;
`;

const CartItem = (props) => {
    const { t } = props;
    return (
        <div>
            {
                props.status === 2
                ? <div className="notification-box sm">
                    <div className="danger">
                        <i className="fas fa-exclamation-circle"></i>
                        <p>{t("keranjang.item.tidak_tersedia")}</p>
                    </div>
                </div>
                : null
            }
        <div className={`cart-list ${props.status === 2 ? "fadeout" : ""}`}>
            
            <label className="form-check pull-left">
                <input type="checkbox" onChange={props.onCheckedItem} checked={props.checked} disabled={props.disabledChecked}/>
            </label>
            <a href="/#" onClick={props.onRemoveItem}><i className="fas fa-times"></i></a>
            {/* <a href="/#" onClick={props.onRemoveItem} style={{right: "20px"}}><i className="fas fa-edit"></i></a> */}
            <div className="product-img-2">
                {props.discount > 0 ? <div className="discount">{props.discount} %</div>: null }
                <img src={props.image_thumb} alt="keranjang"/>
            </div>
            <div className="product-desc">
                <p className="product-name">{props.name}</p>
                <div className="product-price-container">
                    <span className="product-price">
                        <FormatUang value={props.price} />
                    </span>
                    {
                        props.discount > 0
                        ? <span className="product-price-stripe">
                            <FormatUang value={props.real_price} />
                        </span> 
                        : null 
                    }
                </div>
                <p className="product-total-price">
                    Rp <FormatUang value={props.sub_total} />
                    <br/>
                    {
                        props.ppn
                        ? <Ppn>{t("keranjang.item.ppn")}</Ppn>
                        : null
                    }
                    
                </p>
                <div className="input-group qty-input">
                    {/* <span>x {props.quantity}</span> */}
                    <div className="input-group-prepend">
                        <button 
                            className={`btn ${!props.checked || props.quantity <=props.min_order ? 'disabled': ''}`} 
                            type="button" 
                            onClick={props.onDecrement}>
                                <i className="fas fa-minus no-margin"></i>
                        </button>
                    </div>
                
                    <input 
                        type="text" 
                        className={`form-control ${!props.checked ? 'disabled' : ''}`} 
                        value={props.quantity} 
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        />
                    
                    <div className="input-group-prepend">
                        <button 
                            className={`btn ${!props.checked ? 'disabled' : ''}`} 
                            type="button" 
                            onClick={props.onIncrement}>
                                <i className="fas fa-plus no-margin"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
        </div>
    )
}

export default withNamespaces()(CartItem)
