import { 
    ADD_TO_CART,
    REMOVE_ITEM,
    SUB_QUANTITY,
    ADD_QUANTITY,
    // ADD_SHIPPING,
    TOGGLE_CART_HIDDEN ,
    CHANGE_QUANTITY_PRODUCT,
    CHANGE_CHECKED_ITEM,
    CHANGE_CHECKED_SHOP,
    REMOVE_SHOP_ITEM,
    ADD_TO_CART_API,
    SYNC_CART,
    CLEAR_CART
} from "./types/cartType";
import axios from 'axios';
// import {headers} from '../headers';
import {API_URL} from '../config';


export const toggleCartHidden = () => ({
    type: TOGGLE_CART_HIDDEN
});


export const addToCart = (data) => {
    return{
        type: ADD_TO_CART,
        payload: data
    }
}

export const changeQuantity = (data) => {
    return{
        type: CHANGE_QUANTITY_PRODUCT,
        payload: data
    }
}

export const onCheckedItem = (data) => {
    return{
        type: CHANGE_CHECKED_ITEM,
        payload: data
    }
}

export const onCheckedShop = (data) => {
    return{
        type: CHANGE_CHECKED_SHOP,
        payload: data
    }
}

export const removeItem = (data) => {
    return{
        type: REMOVE_ITEM,
        payload: data
    }
}

export const removeShopItem = (id) => {
    return{
        type: REMOVE_SHOP_ITEM,
        payload: id
    }
}

export const subtractQuantity= (id) => {
    return{
        type: SUB_QUANTITY,
        id
    }
}

export const addQuantity= (id) => {
    return{
        type: ADD_QUANTITY,
        id
    }
}

export const clearCart= () => dispatch => {
    return Promise.resolve().then(() => {
        dispatch({
            type: CLEAR_CART
        })
    });
    
}

export const addToCartV2 = data => dispatch => {   
    dispatch({
        type: ADD_TO_CART_API,
        payload: data
    })
}

/**
 * 
 * 
 *   PERUBAHAN KE API
 */


export const addToCartWithApi = (data, token) => dispatch => {   
    
    return axios.post(`${API_URL}/cart`, {value : JSON.stringify(data)} , {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
    .then(res => {
        dispatch({
            type: ADD_TO_CART_API,
            payload: res.data.value ? JSON.parse(res.data.value) : []
        })
    })
}

export const getPrevCart = (token, url) => dispatch => {   
    
    return axios.get(`${API_URL}/cart`, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
    .then(res => {
        dispatch({
            type: SYNC_CART,
            // payload: res.data.value ? JSON.parse(res.data.value) : []
            payload: res.data
        })
    })
}