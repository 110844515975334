import React from 'react'

const TextField = (props) => {
    return (
        <div className="form-group-div">
            <label>
                {props.label} 
                {props.isRequire ?  <span className="red"> *</span> : null}
            </label>
            <input 
                type={props.type}
                name={props.name}
                className={`form-control ${props.className}`}
                placeholder={props.placeholder} 
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                disabled={props.isDisabled ? "disabled" : ""}
            />
        </div>
    )
}

export default TextField
