import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    authPost,
    AlertWarning,
    FormatUang,
    AlertError,
    Toast,
} from './../../helper';
import ReactLoading from 'react-loading';
const Loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

class HubungiPenjual extends Component {
    
    /**
     * Reference Props
     * - Reference_id: ID Produk / ID Invoice
     * - Reference_name: Nama Produk / No Transaksi
     * - Reference_image: Gambar utama Produk / Foto toko
     */

    constructor(props) {
        super(props);        
        this.state = {
            message_text: '',
            removed: false,
            loadingButton: false,
        }
    }

    componentDidMount() {
        //
    }
    onMessageTextAreaChange = (e) => {
        this.setState({
            message_text: e.target.value,
        });
    }

    onKirimClick = (e) => {
        e.preventDefault();
        let { token } = this.props.userData;
        var textarea_msg = this.refs.textarea_msg;
        if (this.state.message_text === "") { 
            AlertWarning.fire({ text: "Pesan tidak boleh kosong" });
            return false;
        }
        let data = {
            message: this.state.message_text,
            shop_id: this.props.seller.id,
            reference_type: (this.state.removed || this.props.reference === undefined ? "" : this.props.reference.reference_type),
            reference_id: (this.state.removed || this.props.reference === undefined ? "" : this.props.reference.reference_id)
        }
        this.setState({ loadingButton: true });
        authPost(data, 'messages/store_from_hubungi_penjual', token).then(() => {
            textarea_msg.value = "";
            this.setState({ loadingButton: false }, () => {
                Toast.fire({
                    type: "success",
                    title: "Berhasil dikirim"
                });
                window.$("#modalHubungiPenjual").modal('hide');
                window.open('/user/message', '_blank');
            });
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }

    removeReference = (e) => {
        e.preventDefault();
        this.setState({
            removed: true,
        });
    }

    render() {
        return (
            <div className="modal fade modal-2" id="modalHubungiPenjual" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form>
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>HUBUNGI PENJUAL</h2>
                                <div className="mb10">
                                    <img src={this.props.seller.image} alt="" className="shop-icon mr10" />
                                    <div className="pull-left">
                                        <a href={`/${this.props.seller.domain}`} className="shop-name">{this.props.seller.name}</a>
                                        <p><i className="fas fa-map-marker-alt"></i> {this.props.seller.addresses.data[0].city}</p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="form-group-div">
                                    <textarea ref="textarea_msg" className="form-control" rows="6" placeholder="Tulis Pesan" onChange={this.onMessageTextAreaChange}></textarea>
                                </div>
                                {
                                    this.props.reference ? this.props.reference.reference_type === 'product' ? 
                                    <div className="product-card" style={{display: this.state.removed ? "none" : ""}}>
                                            <a href={'/product/' + this.props.reference.reference_id} className="pull-right mr10" onClick={this.removeReference}><i className="fas fa-times"></i></a>
                                        <div className="product-thumb">
                                            <img src={this.props.reference.reference_image} alt={this.props.reference.reference_name} title={this.props.reference.reference_name}/>
                                        </div>
                                        <div className="product-info">
                                            <p className="semibold mt20">{this.props.reference.reference_name}</p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    :
                                    <div className="box-outline">
                                        <span className="pull-right fs17">Rp <FormatUang value={this.props.reference.reference_total} /></span>
                                        <a href={'/transaction/detail/'+this.props.reference.reference_id} className="fs17">#{this.props.reference.reference_name}</a>
                                    </div> 
                                    : ''
                                }
                            </div>
                            <div className="modal-footer">
                            {
                                this.state.loadingButton
                                    ? <Loading /> : 
                                    <React.Fragment>
                                        <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</button>
                                        <button className="btn btn-solid primary btn-sm" onClick={this.onKirimClick}>Kirim</button>
                                    </React.Fragment>
                            }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.user.user,
    }
}

export default connect(mapStateToProps)(HubungiPenjual);