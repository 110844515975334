import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
    NewHeader,
    LoadingPage,
} from '../../../components';
import {
    authGet,
    authPost,
    AlertError,
    FormatUang,
    ConfirmInfo,
    authPut,
    Toast,
    authPostPaginate,
    AlertLoading,
} from './../../../helper';
import {
    getMyShop,
} from "../../../actions/shopActions";
import {
    setNotification
} from '../../../actions/notificationAction';
import "react-datepicker/dist/react-datepicker.css";
import {
    toggleModalLogin
} from '../../../actions/registerActions';
import { fetchCategories } from '../../../actions/categoryActions';
import ReactLoading from 'react-loading'
import VipBadge from '../../../assert/icon/vip-badge-small.svg'
import EmptyResult from '../../../components/Global/EmptyResult';
import imageEmptyHutang from '../../../assert/icon/empty-hutang.svg';
import imageEmptyHutangLunas from '../../../assert/icon/empty-hutang-lunas.svg';
import NetworkDetector from '../../PUBLIC/NetworkDetector'
class MutasiHutang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingPage: true,
            loadData: true,
            loadFilterData: false,
            flag: 1,
            data: [],
            pagination: [],
            hutang: 0,
            toko_vip: [],
            filter_data: [],
            sorting_data: [],
            sort_id: 1,
            metode_pembayarans: [],
            hutangPraBayar: null,
            modalInfoBayar: null,
            activeBank: null,
            metode_pembayaran_id: null,
            metode_pembayaran_selected: null,
            filter_id: null,
            img_empty: [imageEmptyHutang, imageEmptyHutangLunas],
            text_empty: ["Anda tidak memiliki hutang ke toko mana pun.", "Anda tidak memiliki hutang yang sudah lunas."],
            title_empty: ["Anda tidak memiliki hutang", "Anda tidak memiliki hutang lunas"],
        }
    }
    componentDidMount() {
        let data = {
            flag: 1,
            sort_id: 1,
            filter_id: 1
        };
        this.retrieveData(data);
    }

    retrieveData(data) {
        authPost(data, `hutang/list`, this.props.userData.token)
        .then(res => {
            this.setState({
                data: res.data.hutang.data,
                pagination: res.data.hutang.meta.pagination,
                hutang: res.data.total_hutang,
                toko_vip: res.data.toko_vip,
                filter_data: res.data.filter,
                sorting_data: res.data.sorting,
                sort_id: res.data.sort_id,
                filter_id: res.data.filter_id,
                loadingPage: false,
                loadData: false,
                loadFilterData: false
            })
        })
    }

    getMetodePembayaran(data){
        if(this.state.metode_pembayarans.length === 0) {
            this.setState({metode_pembayaran_loading: true});
            authGet(`v2/get_metode_pembayaran_hutang`, this.props.userData.token)
            .then(res => {
                this.setState({
                    metode_pembayarans : res.data,
                    metode_pembayaran_loading: false,
                    hutangPraBayar: data
                })
            })
            .catch(err => {
                this.setState({metode_pembayaran_loading: false});
                AlertError.fire({ text: err.response.data.message });
            })
        }
    }

    handleChangeTab = (e, flag) => {
        e.preventDefault();

        this.setState({
            flag,
            loadData: true
        }, () => {
            let data = {flag}
            this.retrieveData(data);
        });
    }

    handleCheckedFilter(id){
        let data_checked = this.state.checked_filter_data;

        if(data_checked.find(c => c === id)) {
            this.setState({
                checked_filter_data: data_checked.filter(data => data !== id)
            })
        } else {
            this.setState({
                checked_filter_data: [...data_checked, id]
            })
        }
    }

    onClickKonfirmasi(e) {
        e.preventDefault();
        ConfirmInfo.fire({
            title: "Konfirmasi",
            text: "Apakah anda yakin akan melanjutkan transaksi?"
        }).then(result => {
            if(result.value){
                //doing something
            }
        });
    }

    onPilihPembayaranClick = (e) => {
        e.preventDefault();
        let data = {
            metode_pembayaran_id: this.state.metode_pembayaran_selected.id
        }

        authPut(data, `hutang/set_metode_pembayaran/${this.state.hutangPraBayar.id}`, this.props.userData.token)
        .then(res => {
            this.setState({
                data: this.state.data.map(d => d.id === res.data.id ? {...d, ...res.data} : {...d})
            })
            window.$('#modalPilihPembayaran').modal('hide');
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }

    ubahMetodeBayar = () => {
        window.$("#modalInfoPembayaran").modal('hide');
        window.$("#modalPilihPembayaran").modal('show');
        this.getMetodePembayaran(this.state.hutangPraBayar);
    }

    konfirmasiPembayaran = (e, data) => {
        e.preventDefault();

        ConfirmInfo.fire({
            text: "Apakah Anda yakin ingin melanjutkan transaksi ini?"
        }).then(result => {
            if(result.value) {
                AlertLoading.fire({});
                authPut({}, `hutang/konfirmasi_pembayaran/${data.id}`, this.props.userData.token)
                .then(res => {
                    let transform = this.state.data.map(d => d.id === res.data.id ? {...d, ...res.data} : {...d});
                    let { pagination } = this.state;

                    if(parseInt(res.data.status_id) === 4) {
                        transform = this.state.data.filter(d => d.id !== res.data.id);
                        pagination.total -= 1;
                    }

                    this.setState({
                        data: transform,
                        pagination,
                        total_hutang: res.data.total_hutang
                    })
                    Toast.fire({type: "success", title: "Berhasil mengirim"});
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        });
    }

    onClickfilterData = (e) => {
        e.preventDefault();
        this.setState({
            loadFilterData: true
        }, () => {
            let data = {
                sort_id: this.state.sort_id,
                filter_id: this.state.filter_id,
                flag: 1
            }
    
            this.retrieveData(data);
        })
    }

    onClickResetfilterData = (e) => {
        e.preventDefault();
        this.setState({
            loadFilterData: true
        }, () => {
            let data = {
                sort_id: 1,
                filter_id: 1,
                flag: 1
            }
    
            this.retrieveData(data);
        })
    }

    onChangeSortData = (e) => {
        this.setState({
            sort_id: parseInt(e.target.value),
            loadFilterData: true
        }, () => {
            
            let data = {
                sort_id: this.state.sort_id,
                filter_id: this.state.filter_id,
                flag: 1
            }
    
            this.retrieveData(data);
        })
    }

    loadMoreData = () => {
        let { next } = this.state.pagination.links;
        let data = {
            sort_id: this.state.sort_id,
            filter_id: this.state.filter_id,
            flag: this.state.flag,
        }
        this.setState({loadingMoreData: true});
        authPostPaginate(data, next, this.props.userData.token)
        .then(res => {
            this.setState({
                data: [...this.state.data, ...res.data.hutang.data],
                pagination: res.data.hutang.meta.pagination,
                hutang: res.data.total_hutang,
                toko_vip: res.data.toko_vip,
                filter_data: res.data.filter,
                sorting_data: res.data.sorting,
                sort_id: res.data.sort_id,
                filter_id: res.data.filter_id,
                loadingMoreData: false,
            })
        })
    }
    hasMoreData = () => !(this.state.data.length === (this.state.pagination ? this.state.pagination.total : -1 ));

    render() {
        let Page = (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10">
                        <div className="basic-container">
                        <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/"><i className="fas fa-home mr0"></i></a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Piutang</li>
                                </ol>
                            </nav>
                            <br />
                            <h1 className="page-title">Mutasi Hutang</h1>
                            <div className="clearfix"></div>
                        </div>
                        <div className="vip-container">
                            <div className="vip-main" style={{ padding: "0px" }}>
                                { this.state.loadData
                                ? <div className="text-center" style={{height: "400px"}}>
                                    <div className="loading-btn mb20" style={{height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <ReactLoading type="spinningBubbles" color="#0C7F8F"/>
                                    </div>
                                </div>
                                : 
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="vip-top">
                                            <div className="detail-vip-tab">
                                                <div className="pull-right">
                                                    <div className="sorting-input">
                                                        <select className="form-control" value={this.state.sort_id} onChange={this.onChangeSortData}>
                                                            {
                                                                this.state.sorting_data.map(d => (
                                                                    <option value={d.id} key={d.id}>{d.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <label>Urutkan</label>
                                                    </div>
                                                </div>   
                                                <ul>
                                                    <li className={this.state.flag === 1 ? "active" : ""}>
                                                        <a href="/#" onClick={(e) => this.handleChangeTab(e, 1)}>Daftar Hutang</a>
                                                    </li>
                                                    <li className={this.state.flag === 2 ? "active" : ""}>
                                                        <a href="/#" onClick={(e) => this.handleChangeTab(e, 2)}>Hutang Lunas</a>
                                                    </li>
                                                </ul>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-md-2">
                                    <label className="mr20" style={{ fontSize: "24px",lineHeight: "33px", float:"left", marginLeft:"30px" }}>Filter</label>
                                    <br />
                                    <br />
                                    <div className="filter-menu" style={{ marginTop:"10px",marginLeft: "30px" }}>
                                        <div className="mb20">
                                            <h3>Jatuh Tempo Piutang</h3>
                                            <table width="100%" cellPadding="3">
                                                <tbody>
                                                    {
                                                        this.state.filter_data.map(f => (
                                                            <tr key={f.id}>
                                                                <td>
                                                                    <input 
                                                                        type="radio" 
                                                                        checked={this.state.filter_id === f.id} 
                                                                        onChange={() => this.setState({filter_id: f.id})}
                                                                        />
                                                                </td>
                                                                <td>{f.label}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                                                                
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="mb20 text-center">
                                            <button className="btn btn-block btn-solid primary" onClick={this.onClickfilterData} disabled={this.state.flag !== 1}>Terapkan</button>
                                            <br/>
                                            <a href="/#" onClick={this.onClickResetfilterData}>Reset</a>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-10">
                                    <div className="pull-right">
                                    {
                                        this.state.flag === 1 ? 
                                            <label style={{ marginRight: "10px", fontSize: "18px",lineHeight: "25px", fontWeight: "600" }}>Total Hutang: <span style={{ color: "#0C7F8F" }}> Rp &nbsp;<FormatUang value={this.state.hutang} /></span></label>
                                        :
                                            <label style={{ marginRight: "10px", fontSize: "18px",lineHeight: "25px", fontWeight: "600" }}>Total Transaksi: <span style={{ color: "#0C7F8F" }}> Rp &nbsp;<FormatUang value={this.state.hutang} /></span></label>
                                    }
                                    </div>
                                    { 
                                    this.state.loadFilterData ? 
                                        <div className="text-center" style={{height: "400px"}}>
                                            <div className="loading-btn mb20" style={{height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <ReactLoading type="spinningBubbles" color="#0C7F8F"/>
                                            </div>
                                        </div>
                                        :
                                        this.state.data.length <= 0 ? 
                                            <EmptyResult image={this.state.img_empty[this.state.flag-1]} message={this.state.text_empty[this.state.flag-1]} title={this.state.title_empty[this.state.flag-1]} buttonText={0}/>
                                        :
                                            <table className="table table-form" style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}>
                                                <tbody>
                                                    {
                                                        this.state.data.map(data => (
                                                            <tr className="" key={data.id}>
                                                                <td className="p10" width="28%">
                                                                    <p className="semibold mb0 fs16">{data.label} <a href={data.link_ref}>{data.ref}</a></p><span>{data.tanggal}</span>
                                                                </td>
                                                                <td width="20%">
                                                                    <div className="pos-relative vip-shop-image">
                                                                        <img src={VipBadge} alt="" className="inner-image" style={{ left: "12%" }}/>
                                                                        <img className="shop-icon" src={data.shop_image_url} alt="Banner Header"/>
                                                                        <p className="semibold mb0 fs16" style={{ position: "absolute", left: "60px", top: "10px" }}> {data.shop_name}</p>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    data.status_id === 3
                                                                    ? <td align="right" className="p10" width="25%" colSpan="2">
                                                                            <p className="semibold mb0 fs16"> Rp &nbsp;<FormatUang value={data.nominal} /></p>
                                                                            <span className="regularitalic green">{data.status_label}</span>
                                                                        </td>
                                                                    : <>
                                                                        <td align="right" className="p10" width="25%">
                                                                            <p className="semibold mb0 fs16"> Rp &nbsp;<FormatUang value={data.nominal} /></p>
                                                                            {
                                                                                data.info_bayar && !data.approve_at
                                                                                ? <span 
                                                                                    className="regular primary clickable" 
                                                                                    data-toggle="modal" 
                                                                                    data-target="#modalInfoPembayaran"
                                                                                    onClick={() => this.setState({
                                                                                        modalInfoBayar: data.info_bayar,
                                                                                        hutangPraBayar: data
                                                                                    })}>
                                                                                        Informasi Pembayaran
                                                                                    </span> 
                                                                                : <span className={`badge badge-pill ${data.status_class}`} style={{ marginTop:"4px", fontWeight: "100" }}>
                                                                                    <i className="fas fa-calendar-alt"></i> &nbsp;{data.jatuh_tempo}
                                                                                </span>
                                                                            }
                                                                        </td>
                                                                        {
                                                                            !data.info_bayar
                                                                            ? <td align="right" className="p10"  width="7%">
                                                                                <button 
                                                                                    className="btn btn-md btn-solid primary" 
                                                                                    onClick={() => this.getMetodePembayaran(data)} 
                                                                                    data-toggle="modal" 
                                                                                    data-target="#modalPilihPembayaran">
                                                                                        Bayar
                                                                                    </button>
                                                                            </td>
                                                                            : data.approve_at
                                                                                ? null
                                                                                : <td align="right" className="p10"  width="7%">
                                                                                    <button className="btn btn-md btn-solid primary" onClick={(e) => this.konfirmasiPembayaran(e, data)}>Konfirmasi Pembayaran</button>
                                                                                </td>
                                                                        }
                                                                        
                                                                    </>
                                                                }
                                                                                                            
                                                            </tr>
                                                        ))
                                                    }                                          
                                                </tbody>
                                            </table>
                                    }
                                    {
                                        this.hasMoreData()
                                        ? <div className="text-center">
                                            {
                                                this.state.loadingMoreData
                                                ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                                : <button className="btn btn-solid primary" onClick={this.loadMoreData}>Data Lainnya</button>
                                            }
                                        </div>
                                        : null
                                    }                                       
                                </div>                          
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-2" style={{ borderLeft: "1px solid rgba(0,0,0,0.1)" }}>
                    <div className="basic-container">
                        <h1 className="page-title primary" style={{ marginRight: "10px", fontSize: "18px",lineHeight: "25px", fontWeight: "600", marginBottom: "20px" }}>Daftar Toko VIP :</h1>
                        <div className="clearfix"></div>
                        {
                            this.state.toko_vip.map(toko => (
                                <div className="mb10 ml10" key={toko.id}>
                                    <div className="pos-relative vip-shop-image">
                                        <img src={VipBadge} alt="" className="inner-image" />
                                        <img className="shop-icon" src={toko.image_url} alt="Banner Header"/>
                                    </div>
                                    <div className="pull-left ml5"><a href={toko.domain} className="shop-name">{toko.name}</a>
                                        <p><i className="fas fa-map-marker-alt"></i> {toko.place}</p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            ))
                        }
                                                                                                                  
                    </div>
                </div>
            </div>
        </div>
        )
        return(
            <div className="mg-top">
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    <LoadingPage />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <div className="modal fade modal-2" id="modalPilihPembayaran" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {
                                        this.state.metode_pembayaran_loading
                                        ? <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <ReactLoading type="spinningBubbles" color="#0C7F8F" />
                                        </div>
                                        : <React.Fragment>
                                            <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                            <h2>PILIH METODE PEMBAYARAN</h2>
                                                {
                                                    this.state.metode_pembayarans.map(metode_pembayaran => (
                                                        <div className="mb30 ml10" key={metode_pembayaran.id}>
                                                            <label className="form-check">
                                                                {metode_pembayaran.label}
                                                            </label>
                                                            {
                                                                metode_pembayaran.list.map(list => {
                                                                    return (
                                                                        <div className="mb20 pl50" key={list.id}>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    checked={this.state.activeBank === list.id}
                                                                                    onChange={() => this.setState({
                                                                                        activeBank: list.id,
                                                                                        metode_pembayaran_id: metode_pembayaran.id,
                                                                                        metode_pembayaran_selected: list
                                                                                    })}
                                                                                    type="radio" 
                                                                                    className="form-check-input"/>
                                                                                <img src={list.image} alt="bank" />
                                                                                <label htmlFor="">&nbsp; {list.label}</label>
                                                                            </div>
                                                                        </div>

                                                                )})
                                                            }
                                                        </div>
                                                    ))
                                                }
                                        </React.Fragment>
                                    }
                                    </div>
                                    {
                                        this.state.metode_pembayaran_loading
                                        ? null
                                        : <div className="modal-footer">
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onPilihPembayaranClick}>Simpan</a>
                                        </div>
                                    }
                                    
                                </div>
                        </div>
                    </div>                
                <div className="modal fade modal-2" id="modalInfoPembayaran" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>INFORMASI PEMBAYARAN</h2>
                                {
                                    this.state.modalInfoBayar
                                    ? <div className="trans-payment-info">
                                        <div className="p30 bg-grey">
                                            <div className="text-center">
                                                <span className="semibold fs20 block mb30">Informasi Pembayaran</span>
                                                <span className="block mb20">Total Tagihan</span>
                                                <span className="semibold fs30">Rp &nbsp;<FormatUang value={this.state.modalInfoBayar.total_tagihan} /></span>
                                            </div>
                                            
                                            <hr />
                                            <table width="100%" cellPadding="3">
                                                <tbody>
                                                    <tr>
                                                        <td width="20%">Metode Pembayaran</td>
                                                        <td width="">
                                                            <span className="pull-right">
                                                                {this.state.modalInfoBayar.label} &nbsp;
                                                                <img src={this.state.modalInfoBayar.image_url} alt="Logo Bank"/>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    {
                                                        this.state.modalInfoBayar.info
                                                        ? <>
                                                            <tr>
                                                                <td>Nomor Rekening</td>
                                                                <td width="30%"><span className="pull-right">{this.state.modalInfoBayar.info.nomor_rekening}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nama Rekening</td>
                                                                <td><span className="pull-right">{this.state.modalInfoBayar.info.nama_rekening}</span></td>
                                                            </tr>
                                                        </>
                                                        : null
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                            
                                        </div>
                                    </div>
                                    : null
                                }
                                
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-lg btn-primary" onClick={this.ubahMetodeBayar}>Ubah Metode Pembayaran</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        userData: state.user.user,
        shop: state.shop.item,
        notifMessage: state.notification.message,
        categories: state.categories.items,
    }
}
export default NetworkDetector(connect(mapStateToProps, {
    getMyShop,
    setNotification,
    toggleModalLogin,
    fetchCategories
})(MutasiHutang));
