import { 
    FETCH_PRODUCTS, 
    FETCH_MORE_PRODUCTS,
    FETCH_PRODUCT_DETAIL, 
    ON_CHANGE_PRODUCT, 
    ON_DROP_PICTURE ,
    ON_CHANGE_PRODUCT_HARGA_GROSIR,
    ON_CLICK_PRODUCT_HARGA_GROSIR,
    ON_REMOVE_HARGA_GROSIR,
    ON_CHANGE_STATUS_HARGA,
    ON_CHECK_ALAMAT,
    ON_CHECK_PENGIRIMAN,
    GET_SHOP_BY_PRODUK_ID,
    SELECT_PRODUCT_MAGNIFY,
    SEARCH_PRODUCT,
    GET_PRODUCT_SHIPMENT,
    CHANGE_DISKON,
    ON_SEACRH,
    SAVE_KEYWORD,
    FETCH_PRODUCT_RATING,
    ON_INCREMENT,
    ON_DECREMENT,
    ON_CHANGE_QUANTITY,
    RESET_CREATE_PRODUCT,
    CHANGE_TEMP_PRICE,
    UPDATE_LIKES_PRODUCT_DETAIL,
    // ON_CHANGE_CATEGORY_FILTER
} from "../actions/types";
import { 
    FETCH_SHOP_ADDRESS,
    FETCH_SHOP_SHIPMENT,
    FETCH_SHOP_PRODUCT
} from '../actions/types/shopType';

const initialState = {
    items: [],
    pagination: {},
    pictures:[],
    createProduct : {
        nama: '',
        keterangan: '',
        category : "0",
        sub_category: "0",
        kondisi : "1",
        deskripsi: '',
        berat:"",
        hargaGrosirs: [],
        hargaGrosir: {
            min: '',
            max: '',
            harga: ''
        },
        hargaSatuan: '',
        grosir: false,
        alamat : {},
        shipment: {},
        pictures: [],
        minPembelian : 1
    },
    item: {
        image_selected : 0,
        image : "empty.png",
        images: [],
        real_price : 0,
        price : 0,
        shop: {
            name : ''
        }
    },
    range : [],
    seller : {
        addresses : {
            data : []
        }
    },
    productShipment: [],
    keyword : '',
    prevKeyword : '',
    product_rating : {
        avg : 0,
        b5: {total : 0, persentase : 0},
        b4: {total : 0, persentase : 0},
        b3: {total : 0, persentase : 0},
        b2: {total : 0, persentase : 0},
        b1: {total : 0, persentase : 0},
        rating : {
            data : []
        }
    }

}

export default function(state = initialState, action){
    switch(action.type){
        case FETCH_PRODUCTS:
            return {
                ...state,
                items: action.payload,
                pagination : action.meta
            }
        case FETCH_MORE_PRODUCTS:
            return {
                ...state,
                items: state.items.concat(action.payload),
                pagination : {
                    ...action.meta,
                    count : state.pagination.count + action.meta.count
                }
            }
        case SEARCH_PRODUCT:
            return {
                ...state,
                items: action.payload,
                pagination : action.meta
            }
        case FETCH_PRODUCT_DETAIL:
            return {
                ...state,
                item: action.payload
            }
        case RESET_CREATE_PRODUCT:
            return {
                ...state,
                createProduct: resetCreateProduct(),
                pictures : []
            }
        case ON_CHANGE_PRODUCT:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    [action.payload.name] : action.payload.value
                }
            }
        case ON_CHANGE_PRODUCT_HARGA_GROSIR:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    hargaGrosir : {
                        ...state.createProduct.hargaGrosir,
                        [action.payload.name] : action.payload.value
                    }
                }
            }
        case ON_CLICK_PRODUCT_HARGA_GROSIR:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    hargaGrosirs: [...state.createProduct.hargaGrosirs, action.payload],
                    hargaGrosir: { min: '', max: '', harga: '' }
                }
            }
        case ON_REMOVE_HARGA_GROSIR:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    hargaGrosirs: action.payload,
                }
            }
        case ON_CHANGE_STATUS_HARGA:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    grosir: action.payload,
                }
            }
        case ON_CHECK_ALAMAT:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    alamat: {
                        ...state.createProduct.alamat,
                        [action.payload.id] : action.payload.value
                    }
                }
            }
        case ON_CHECK_PENGIRIMAN:
            return {
                ...state,
                createProduct: { 
                    ...state.createProduct,
                    shipment: {
                        ...state.createProduct.shipment,
                        [action.payload.id] : action.payload.value
                    }
                }
            }
        case ON_DROP_PICTURE:
            return {
                ...state,
                pictures : action.payload
                
            }
        case FETCH_SHOP_ADDRESS:
            return {
                ...state,
                createProduct: {
                    ...state.createProduct,
                    alamat: getAlamatId(action.payload)
                }
            }
        case FETCH_SHOP_SHIPMENT:
            return {
                ...state,
                createProduct: {
                    ...state.createProduct,
                    shipment : getShipmentDetailId(action.payload)
                }
            }
        case GET_SHOP_BY_PRODUK_ID:
            return {
                ...state,
                seller : action.payload
                
            }
        case SELECT_PRODUCT_MAGNIFY:
            return {
                ...state,
                item : {
                    ...state.item,
                    image_selected : action.payload.id,
                    image : action.payload.image
                }
            }
        case FETCH_SHOP_PRODUCT:
            return {
                ...state,
                items : action.payload,
                pagination : action.meta,
            }
        case GET_PRODUCT_SHIPMENT:
            return {
                ...state,
                productShipment : action.payload
            }
        case CHANGE_DISKON:
            return {
                ...state,
                item : {
                    ...state.item,
                    discount_price : action.payload.discount_price,
                    discount : action.payload.price
                }
            }
        case ON_SEACRH:
            return {
                ...state,
                keyword : action.payload
            }
        case SAVE_KEYWORD:
            return {
                ...state,
                prevKeyword : action.payload
            }
        case FETCH_PRODUCT_RATING:
            return {
                ...state,
                product_rating : action.payload
            }
        case ON_INCREMENT:
            return {
                ...state,
                item : {
                    ...state.item,
                    qty : state.item.qty + 1,
                    disableDec : false
                }
            }
        case ON_DECREMENT:
            return {
                ...state,
                item : {
                    ...state.item,
                    qty : state.item.qty - 1,
                    disableDec : (state.item.qty - state.item.min_order) > 1 ? false : true 
                }
            }
        case ON_CHANGE_QUANTITY:
            return {
                ...state,
                item : {
                    ...state.item,
                    qty : parseInt(action.payload),
                    disableDec : (parseInt(action.payload) - state.item.min_order) > 1 ? false : true 
                }
            }
        case CHANGE_TEMP_PRICE:
            return {
                ...state,
                item : {
                    ...state.item,
                    temp_price : action.payload
                }
            }
        case UPDATE_LIKES_PRODUCT_DETAIL:
            return {
                ...state,
                item : {
                    ...state.item,
                    jml_like : parseInt(state.item.jml_like) + action.payload
                }
            }
        default:
            return state;
    }
}

const resetCreateProduct = () => {
    return {
        nama: '',
        keterangan: '',
        category : "0",
        sub_category: "0",
        kondisi : "1",
        deskripsi: '',
        berat:"",
        hargaGrosirs: [],
        hargaGrosir: {
            min: '',
            max: '',
            harga: ''
        },
        hargaSatuan: '',
        grosir: false,
        alamat : {},
        shipment: {},
        pictures: [],
        minPembelian : 1
    }
}

const getAlamatId = data => {
    let result = {};
    data.map(item => {
        result = {[item.id] : true, ...result }
        return {};
    })

    return result
}

const getShipmentDetailId = data => {
    let result = {};
    data.map(item => {
        item.detail.map(detail => {
            result = {[detail.id] : true, ...result}
            return {};
        })
        return {};
    })

    return result
}