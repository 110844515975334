import React from 'react'
import { Link } from 'react-router-dom'
import NorificationIcon from '../../assert/icon/notification.png'
import CartIcon from '../../assert/icon/cart.png'
import ShopIcon from '../../assert/icon/shop.png'
import GlobeIcon from '../../assert/icon/globe.svg'
import { FormatUang } from '../../helper';
import HeaderNotification from './HeaderNotification';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
// import User1 from '../../assert/files/profile/user1.jpg'

const NewMenu = props => {
    const { t } = props;
    if(!props.isLogin){
        return (
            <ul className="navbar-nav">
                {/* <li className="nav-item">
                    <a href="/#" className="nav-link menu-cart" onClick={props.toggleCartHidden}>
                        {
                            props.countOrder > 0 ? <span>{props.countOrder}</span> : null
                        }
                        <img src={CartIcon} alt="Menu Agrinis"/>
                    </a>
                </li> */}
                <li className="nav-item dropdown">
                    <a href="/#" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={GlobeIcon} alt="Ganti Bahasa"/>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a href="/#" className={i18n.language === "id" ? "dropdown-item active" : "dropdown-item"} onClick={(e) => props.handleChangeLanguage(e, "id")}> Bahasa Indonesia</a>
                    <a href="/#" className={i18n.language === "en" ? "dropdown-item active" : "dropdown-item"} onClick={(e) => props.handleChangeLanguage(e, "en")}> English</a>
                    </div>
                </li>
                <div className="vl" style={{ borderLeft: "1px solid #DDDDDD", "height": "38.5px", marginLeft: "10px", marginRight: "10px" }}></div>
                <li className="nav-item">
                    <button className="btn btn-solid primary" onClick={props.toggleModalLogin}>{t('Header:masuk')}</button>
                </li>
                <li className="nav-item">
                    <Link to="/register" className="btn">{t('Header:daftar')}</Link>
                </li>
            </ul>
        )
    }else{
        return (
            <ul className="navbar-nav">
                <li className="nav-item dropdown">
                    <a href="/#" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={GlobeIcon} alt="Ganti Bahasa"/>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a href="/#" className={i18n.language === "id" ? "dropdown-item active" : "dropdown-item"} onClick={(e) => props.handleChangeLanguage(e, "id")}> Bahasa Indonesia</a>
                    <a href="/#" className={i18n.language === "en" ? "dropdown-item active" : "dropdown-item"} onClick={(e) => props.handleChangeLanguage(e, "en")}> English</a>
                    </div>
                </li>
                {/* <li className="nav-item">
                    <a href="/#" onClick={props.handleChangeLanguage} className="nav-link">
                        <img src={GlobeIcon} alt="Ganti Bahasa" width="22" height="22"/>
                    </a>
                </li> */}
                <div className="vl" style={{ borderLeft: "1px solid #DDDDDD", "height": "38.5px", marginLeft: "10px", marginRight: "10px" }}></div>
                <li className="nav-item active">
                    <div id="menu-notification" className="nav-link menu-notif" title="Notifikasi Saya" onClick={e => e.preventDefault()}>
                        {
                            props.unread_notif > 0
                            ? <span>{props.unread_notif}</span>
                            : null
                        }
                        <img src={NorificationIcon} alt="Notifikasi Saya"/>
                        <HeaderNotification 
                            data={props.notifData}
                            unread={props.unread_notif}
                            readNotification={props.readNotification}
                            />
                    </div>
                </li>
                <li className="nav-item">
                    <a href="/#" className="nav-link menu-cart" onClick={props.toggleCartHidden}>
                        {
                            props.countOrder > 0 ? <span>{props.countOrder}</span> : null
                        }
                        <img src={CartIcon} alt="Keranjang Saya"/>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/#" onClick={props.hasShop} className="nav-link">
                        <img src={ShopIcon} alt="Toko Saya"/>
                    </a>
                </li>
                <li className="nav-item dropdown">
                    <a href="/#" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/* jika ada foto user */}
                        {/* <img className="profile-picture" src={User1}/> */} 
                        {/* jika tidak ada foto user, ambil inisial Nama */}
                        {
                            props.userImage === 0
                            ? <div className="profile-user">{props.userName}</div>
                            : <img className="profile-picture" src={props.userImage} alt="userimage"/>
                        }
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <Link to="/user/profile" className="dropdown-item"><i className="fas fa-user"></i> {t('Header:profil')}</Link>
                        <Link to="/user/message" className="dropdown-item"><i className="fas fa-envelope"></i> {t('Header:pesan')}</Link>
                        {/* <a href="/#" className="dropdown-item" onClick={props.hasShop}><i className="fas fa-store"></i> Toko</a> */}
                        {
                            <React.Fragment><div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" to="/wallet">
                                        <i className="fas fa-wallet"></i> {t('Header:dompet')} <div className="block primary fs17 mt10">Rp <span className="pull-right"><FormatUang value={props.saldo ? props.saldo : 0} /></span></div></Link>
                                <div className="dropdown-divider"></div>
                            </React.Fragment>
                        }
                        
                        <Link to="/transaction" className="dropdown-item"><i className="fas fa-exchange-alt"></i> {t('Header:transaksi')}</Link>
                        { props.is_pembeli_spesial ? <Link to="/user/hutang" className="dropdown-item"><i className="fas fa-receipt"></i> Mutasi Hutang</Link> : null }
                        <Link to="/user/favourite" className="dropdown-item"><i className="fas fa-heart"></i> {t('Header:barang_favorit')}</Link>
                        <Link to="/user/subscribe" className="dropdown-item"><i className="fas fa-bookmark"></i> {t('Header:toko_langganan')}</Link>
                        {/* <Link to="/" className="dropdown-item"><i className="fas fa-cog"></i> Pengaturan</Link> */}
                        <div className="dropdown-divider"></div>
                        <a href="/#" className="dropdown-item red" onClick={props.logout}><i className="fas fa-sign-out-alt"></i> {t('Header:keluar')}</a>
                    </div>
                </li>
            </ul>
        )
    }
}

export default withNamespaces('Header')(NewMenu);