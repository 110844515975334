import React, { 
    useState, 
    useEffect 
} from 'react';
import { connect } from "react-redux";

import {
    toggleModalLogin
} from '../../../actions/registerActions';

import EmptyResult from '../../Global/EmptyResult';
import imageEmpty from '../../../assert/icon/empty-discussion.png';
import LoadingInfiniteScroll from '../../Loading/LadingInfiniteScroll';
import * as moment from "moment";
import 'moment/locale/id';
import { 
    authGet, 
    authPost, 
    AlertError, 
    AlertWarning,
    ConfirmWarning,
    Toast
} from '../../../helper';
import ReactLoading from 'react-loading';
import i18n from '../../../i18n';

moment.locale('id');

const Loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

function SingleProductDiscussionV2(props){

    const [loading, setLoading] = useState(true);
    const [loadingSent, setLoadingSent] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [datas, setDatas] = useState([]);
    const [comment, setComment] = useState("");
    const [laporkan, setLaporkan] = useState({});

    useEffect(() => {
        authGet(`get_discussions/${props.dataProduct.id}`, props.userData.token)
        .then(res => {
            setLoading(false);
            setDatas(res.data);
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }, []);

    function onChangeComment(e) {
        setComment(e.target.value);
    }

    function onClickBtnComment(e) {
        e.preventDefault();

        if(props.userData.token) {
            let data = {
                comment,
                product_id: props.dataProduct.id
            };
    
            setLoadingSent(true);
            authPost(data, `add_discussion`, props.userData.token)
            .then(res => {
                setDatas(res.data);
                setLoadingSent(false);
                setComment("");
            })
            .catch(err => {
                setLoadingSent(false);
                AlertWarning.fire({text: err.response.data.message});
            })
        } else {
            props.toggleModalLogin(true);
        }
    }

    function onChangeSubComment(e, data){
        let new_data = datas.map(d => d.id === data.id ? {...d, sub_comment: e.target.value} : d);
        setDatas(new_data);
    }

    function onClickBtnSubComment(e, data){
        e.preventDefault();
        
        let data_sub = {
            id: data.id,
            comment: data.sub_comment
        }

        authPost(data_sub, `add_sub_discussion`, props.userData.token)
        .then(res => {
            setDatas(res.data);
        })
        .catch(err => {
            setLoadingSent(false);
            AlertWarning.fire({text: err.response.data.message});
        })
    }

    function onClickBtnRemove(e, data) {
        e.preventDefault();

        ConfirmWarning.fire({text: "Anda yakin ingin menghapus pertanyaan ini?"})
        .then(result => {
            if(result.value){
                authPost({id: data.id, product_id: props.dataProduct.id}, `delete_discussion`, props.userData.token)
                .then(res => {
                    setDatas(res.data);
                    Toast.fire({type: "success", title: "Berhasil dihapus"});
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }

    function onClickBtnRemoveSub(e, detail)
    {
        e.preventDefault();

        ConfirmWarning.fire({text: "Anda yakin ingin menghapus diskusi ini?"})
        .then(result => {
            if(result.value){
                authPost({id: detail.id, product_id: props.dataProduct.id}, `delete_sub_discussion`, props.userData.token)
                .then(res => {
                    setDatas(res.data);
                    Toast.fire({type: "success", title: "Berhasil dihapus"});
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }

    function onSetBtnLaporkanDiskusi(e, data, type) {
        e.preventDefault();
        setLaporkan({
            ...data,
            type
        })
    }

    function onClickBtnLaporkanDiskusi(e) {
        e.preventDefault();
        setLoadingModal(true);
        authPost(laporkan, `laporan_discussion`, props.userData.token)
        .then(res => {
            setLoadingModal(false);
            Toast.fire({
                type: "success",
                title: "Laporan berhasil dikirim"
            });
            window.$("#modalLaporkanDiskusi").modal("hide");
        })
        .catch(err => {
            setLoadingModal(false);
            AlertError.fire({text: err.response.data.message})
            .then(() => {
                window.$("#modalLaporkanDiskusi").modal("hide");
            })
        })
    }
    
    
    if(loading){
        return <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px"
        }}>
            <LoadingInfiniteScroll/>
        </div>
    }

    return (
        <div className="tab-div" id="tab-4">
                {
                    props.dataProduct.shop_id === (props.userData.shop ? props.userData.shop.id : 0)
                    ? null
                    : <div 
                        className="discussion-input" 
                        style={{
                            border: "1px #C2DFE3 solid",
                            background: "#F6FCFD",
                            padding: "20px",
                        }}>
                        <label>{i18n.t("SingleProductDiscussionV2:pertanyaan")}</label>
                        <textarea 
                            className="form-control" 
                            rows="4" 
                            placeholder={i18n.t("SingleProductDiscussionV2:tulis_pertanyaan")}
                            name="comment"
                            value={comment} 
                            onChange={onChangeComment}
                            >
                            </textarea>

                        <div className="pull-right">
                            {
                                loadingSent
                                ? <Loading/>
                                : <a 
                                    href="/" 
                                    className="btn btn-solid primary"
                                    onClick={onClickBtnComment}
                                    >{i18n.t("SingleProductDiscussionV2:kirim")}</a>
                            }
                            
                        </div>
                        <div className="clearfix"></div>
                    </div>
                }
                
            {
                datas.length > 0
                ? <React.Fragment>
                    <label className="semibold">{i18n.t("SingleProductDiscussionV2:diskusi")}</label>
                    <div className="discussion-container">
                        {
                            datas.map(data => (
                                <div className="discussion-list" key={data.id}>
                                    {
                                        data.me
                                        ? <div className="pull-right more-menu">
                                            <a href="/#" className="dropdown-toggle no-toggle" role="button" data-toggle="dropdown"><i className="fas fa-ellipsis-h fs16 grey"></i></a>
                                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-sm">
                                                {
                                                    data.my_comment
                                                    ? <a className="dropdown-item" href="/#" onClick={(e) => onClickBtnRemove(e, data)}>{i18n.t("SingleProductDiscussionV2:hapus")}</a>
                                                    : <a className="dropdown-item" data-toggle="modal" data-target="#modalLaporkanDiskusi" href="/#" onClick={(e) => onSetBtnLaporkanDiskusi(e, data, "comment")}>{i18n.t("SingleProductDiscussionV2:laporkan")}</a>
                                                }
                                                
                                            </div>
                                        </div>
                                        : null
                                    }
                                    

                                    <div className="discussion">
                                        <div className="user-avatar pull-left">
                                            {
                                                data.image
                                                ? <img src={data.image} alt="diskusi" />
                                                : <span>{data.init_name}</span>
                                            }
                                            
                                        </div>
                                        <p className="discussion-from pull-left" data-toogle="tooltip">
                                            <span>{data.name}</span>&nbsp;&nbsp;.&nbsp;&nbsp;{data.created_at}
                                        </p>
                                        <div className="clearfix"></div>
                                        <p className="discussion-text">{data.comment}</p>
                                    </div>

                                    <div className="discussion-response">

                                        {
                                            data.details.map(detail => (
                                                <div key={detail.id}>
                                                    {
                                                        data.me
                                                        ? <div className="pull-right more-menu">
                                                            <a href="/#" className="dropdown-toggle no-toggle" role="button" data-toggle="dropdown"><i className="fas fa-ellipsis-h fs16 grey"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-sm">
                                                                {
                                                                    detail.my_comment
                                                                    ? <a className="dropdown-item" href="/#" onClick={(e) => onClickBtnRemoveSub(e, detail)}>{i18n.t("SingleProductDiscussionV2:hapus")}</a>
                                                                    : <a className="dropdown-item" data-toggle="modal" data-target="#modalLaporkanDiskusi" href="/#" onClick={(e) => onSetBtnLaporkanDiskusi(e, data, "sub-comment")}>{i18n.t("SingleProductDiscussionV2:laporkan")}</a>
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                    
                                                    <div className="response-list">
                                                        <div className="user-avatar pull-left">
                                                            {
                                                                detail.image
                                                                ? <img src={detail.image} alt="diskusi" />
                                                                : <span>{detail.init_name}</span>
                                                            }
                                                        </div>
                                                        <p className="response-from pull-left" data-toogle="tooltip">
                                                            <span>{detail.name}</span>&nbsp;&nbsp;.&nbsp;&nbsp;{detail.created_at}
                                                        </p>
                                                        <div className="clearfix"></div>
                                                        <p className="response-text">{detail.comment}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            data.me
                                            ? <div className="response-list">
                                                <div className="user-avatar pull-left">
                                                    {
                                                        data.me.image
                                                        ? <img src={data.me.image} alt="diskusi" />
                                                        : <span>{data.me.init_name}</span>
                                                    }
                                                </div>
                                                <p className="response-from pull-left" data-toogle="tooltip">
                                                    <span>{data.me.name}</span>
                                                </p>
                                                <div className="clearfix"></div>
                                                <div className="form-group clearfix">
                                                    <input 
                                                        type="text" 
                                                        className="form-control mb5" 
                                                        placeholder="Tulis komentar"
                                                        onChange={(e) => onChangeSubComment(e, data)}
                                                        value={data.sub_comment}
                                                        />
                                                    <a 
                                                        href="/#" 
                                                        className="btn btn-solid btn-sm primary pull-right"
                                                        onClick={(e) => onClickBtnSubComment(e, data)}
                                                        >{i18n.t("SingleProductDiscussionV2:kirim")}</a>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        

                                        
                                    </div>

                                </div>
                            ))
                        }
                        
                    </div>
                </React.Fragment>
                : <EmptyResult
                    image={imageEmpty}
                    message={i18n.t("SingleProductDiscussionV2:kosong")}
                    buttonText={0}
                    />
            }
            

            <div className="modal fade modal-2" id="modalLaporkanDiskusi" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                            <h2>{i18n.t("SingleProductDiscussionV2:laporkan_modal.title")}</h2>
                            <p>{i18n.t("SingleProductDiscussionV2:laporkan_modal.desc")}</p>
                        </div>
                        <div className="modal-footer">
                            {
                                loadingModal
                                ? <Loading/>
                                : <React.Fragment>
                                    <a href="/#" className="btn btn-solid darkgrey btn-sm" data-dismiss="modal">{i18n.t("SingleProductDiscussionV2:laporkan_modal.batal")}</a>
                                    <a href="/#" className="btn btn-solid red btn-sm" onClick={onClickBtnLaporkanDiskusi}>{i18n.t("SingleProductDiscussionV2:laporkan_modal.laporkan")}</a>
                                </React.Fragment>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return{
        userData : state.user.user
    }
}

export default connect(mapStateToProps, { 
    toggleModalLogin 
})(SingleProductDiscussionV2);