import React, { Component } from 'react';
import { connect } from "react-redux";
import { toggleModalLogin } from "../../actions/registerActions";
import AuthService from '../../actions/API/AuthService';
// import "../../assert/css/auth.css";
import Login from "../../components/Login/Login";
import axios from "axios";
import { API_URL } from '../../config';
import ReactLoading from 'react-loading';
import Logo from '../../logo.png';
import {
    getUserData
} from '../../actions/userAction'

import {
    AlertError
} from '../../helper'
import NetworkDetector from '../PUBLIC/NetworkDetector'


const Loading = (props) => (
    <div className={props.className} style={props.style}>
        <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} />
    </div>
)

class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            stateLogin: {email: '', password: ''},
            stateRegister: {name: '', email: '', username: '', password: ''},
            view_password : "password",
            icon_password : "view-password",
            loadingEmail : false,
            loadingUsername : false,
            loadingButton : false,
            validEmail : false,
            validUsername : false,
            validPassword : false,
            message: "",
            messageUsername: "",
            messagePassword: "",
            isValidForm : false
        };
        this.toggle = this.toggle.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmitRegister = this.handleSubmitRegister.bind(this);

        this.Auth = new AuthService();
    }

    toggle(e){
        e.preventDefault();
        this.props.toggleModalLogin(true);
    }


    onChange(event){
        let field = event.target.name;
        let stateRegister = this.state.stateRegister;
        stateRegister[field] = event.target.value;
        if (field === "email"){
            this.setState({
                stateRegister: {
                    ...this.state.stateRegister,
                    email: event.target.value
                },
                message: "",
                validEmail: false,
            }, () => {
                this.setTimer(field, this.state.stateRegister.email);
            });
        } else if (field === "username") {
            this.setState({
                stateRegister: {
                    ...this.state.stateRegister,
                    username: event.target.value
                },
                messageUsername: "",
                validUsername: false
            }, () => {
                this.setTimer(field, this.state.stateRegister.username);
            });
        } else {
            this.setState({ stateRegister: stateRegister }, () => {
                // this.validateForm();
            });
        }
    }

    // onChange = (event) => {
    //     let field = event.target.name;
    //     // let stateRegister = this.state.stateRegister;
    //     // stateRegister[field] = event.target.value;
    //     this.setState({
    //         stateRegister: {
    //             ...this.state.stateRegister,
    //             email: event.target.value
    //         },
    //         message: "",
    //         validEmail: false,
    //     }, () => {
    //         this.setTimer(field, this.state.stateRegister.email);
    //     });
    // }


    // onChangeUsername = (event) => {
    //     let field = event.target.name;
    //     let stateRegister = this.state.stateRegister;
    //     stateRegister[field] = event.target.value;
    //     this.setState({
    //         stateRegister: stateRegister,
    //         messageUsername: "",
    //         validUsername: false
    //     }, () => {
    //         this.setTimer(field, this.state.stateRegister.username);
    //     });
    // }

    setTimer = (name, val) => {
        clearTimeout(this.timer);
        if (name === "email") {
            this.setState({ loadingEmail: true });
            this.timer = setTimeout(() => {
                if (this.validateEmail(val)) {
                    this.checkEmail(val);
                } else {
                    if (val === "") {
                        this.setState({
                            loadingEmail: false,
                            message: ""
                        })
                    } else {
                        this.setState({
                            message: "Format email tidak sesuai",
                            loadingEmail: false,
                            validEmail: false
                        })
                    }
                }
            }, 500);
        } else if (name === "username")  {
            this.setState({ loadingUsername: true });
            this.timer = setTimeout(() => {
                if (val.length > 5) {
                    this.checkUsername(val);
                } else {
                    if (val === "") {
                        this.setState({
                            loadingUsername: false,
                            messageUsername: ""
                        })
                    }else{
                        this.setState({
                            validUsername: false,
                            messageUsername: "Minimal 6 karakter"
                        });
                    }
                }
            }, 500);
        }
    }

    checkEmail(email) {
        if (email !== "") {
                axios.post(`${API_URL}/check_email`, { email })
                    .then(res => {
                        this.setState({ loadingEmail: false });
                        if (res.data === 0) {
                            this.setState({ validEmail: true });
                        } else {
                            this.setState({ validEmail: false });
                            this.setState({ message: "Email telah terdaftar" });
                        }
                    });
        }
    }

    checkUsername(username) {
        if (username !== "") {
            
            axios.post(`${API_URL}/check_username`, { username })
                .then(res => {
                    this.setState({ loadingUsername: false });
                    if (res.data === 0) {
                        this.setState({ validUsername: true });
                    } else {
                        this.setState({
                            validUsername: false,
                            messageUsername: "Username telah terdaftar"
                        });
                    }
                })
        }
    }
    // onBlurEmail = () => {
    //     if (this.state.stateRegister.email !== "") {
    //         if (this.validateEmail(this.state.stateRegister.email)) {
    //             this.setState({ loadingEmail: true });
    //             axios.post(`${API_URL}/check_email`, { email: this.state.stateRegister.email })
    //                 .then(res => {
    //                     this.setState({ loadingEmail: false });
    //                     if (res.data === 0) {
    //                         this.setState({ validEmail: true });
    //                     } else {
    //                         this.setState({ validEmail: false });
    //                         this.setState({ message: "Email telah terdaftar" });
    //                     }
    //                 });
    //         } else {
    //             this.setState({ validEmail: false });
    //             this.setState({ message: "Format email tidak sesuai" });
    //         }
    //     }
    // }

    // onBlurUsername = () => {
    //     if (this.state.stateRegister.username !== "") {
    //         this.setState({ loadingUsername: true });
    //         axios.post(`${API_URL}/check_username`, { username: this.state.stateRegister.username })
    //         .then(res => {
    //             this.setState({ loadingUsername: false });
    //             if (res.data === 0) {
    //                 if(this.state.stateRegister.username.length > 5){
    //                     this.setState({ validUsername: true });
    //                 }else{
    //                     this.setState({
    //                         validUsername: false,
    //                         messageUsername: "Minimal 6 Karakter"
    //                     });
    //                 }
    //             } else {
    //                 this.setState({ 
    //                     validUsername: false,
    //                     messageUsername: "Username telah terdaftar"
    //                 });
    //             }
    //         })
    //     }
        
    // }

    onBlurPassword = () => {
        if(this.state.stateRegister.password.length < 8){
            this.setState({
                messagePassword : "Minimal 8 karakter",
                validPassword : false
            })
        }else{
            this.setState({validPassword : true, messagePassword: ""});
        }
    }

    handleSubmitRegister(event){
        event.preventDefault();
        let {name, email, username, password } = this.state.stateRegister;
        this.setState({loadingButton : true});
        this.Auth.register({name, email, username, password, confirm_password: password})
            .then(res => {
                // this.Auth.setToken(res.data.token)
                this.props.getUserData(res.data)
                this.setState({loadingButton: false})
                // this.props.history.replace('/');
                // window.location.href = '/';
            })
            .then(() => {
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000)
            })
            .catch(err => {
                this.setState({ loadingButton: false })
                AlertError.fire({text : err.response.data.message});
                // alert("Terjadi kesalahan");
                // console.log(err.response);
            })
    }

    componentWillMount(){
        this.props.toggleModalLogin(false);
        this.timer = null;
        if(this.props.userData.token){
            this.props.history.push(`/`);
        }
    }

    validateForm(){
        let {name, email, username, password} = this.state.stateRegister;
        let { validEmail, validUsername, validPassword } = this.state;
        if (
                name !== '' && 
                email !== '' && 
                username !== '' && 
                password !== '' && 
                validEmail &&
                validUsername &&
                validPassword
            ){
            return true
        }else{
            return false
        }
    }

    validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    viewPassword = () => {
        this.setState({view_password: this.state.view_password === "password" ? "text" : "password"});
        this.setState({icon_password: this.state.view_password === "password" ? "hide-password" : "view-password"});
    }

    onKeyDownUsername(e){
        if (e.key === " ") {
            e.preventDefault();
        }
    }

    render(){
        return (
            <div className="container-fluid w100p">
            <div className="clearfix h100p w100p">
                <div className="register-side-left" style={{position: "fixed"}}>
                    <a href="/" className="mb100 block"><i className="fas fa-arrow-left mr20"></i> Kembali ke Halaman Utama</a>
                    <h1>Selamat Datang,</h1>
                    <p align="justify">Agrinis merupakan platform jual beli online yang memfasilitasi kebutuhan sapronak untuk industri broiler yang didukung dengan transaksi yang mudah dan cepat.</p>
                    <p align="justify">Agrinis hadir dalam beberapa kategori yaitu OVK, pakan, DOC, dan peralatan peternakan.</p>
					<p>Anda sudah bergabung di Agrinis?</p>
                    <a href="/#" onClick={this.toggle} className="btn btn-outline white btn-login" id="login">Login</a>
                </div>
                <div className="register-side-right" style={{float: "right"}}>
                <div className="text-center logo">
                    <img src={Logo} alt="Logo"/>
                </div>
                <h2>REGISTRASI</h2>
                <form className="auth-form">
                    <div className="form-group">
                        <input 
                            type="text"
                            name="name"
                            value={this.state.stateRegister.name}
                            onChange={this.onChange}
                            required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Nama</label>
                    </div>
                    <div className="form-group">
                            <input 
                                type="text" 
                                name="email"
                                value={this.state.stateRegister.email}
                                onChange={this.onChange}
                                // onBlur={this.onBlurEmail}
                                required
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Email</label>
                            <div className="red absolute fs11">{this.state.message}</div>
                            {this.state.loadingEmail ? <Loading className="loading-input" />  : null}
                            {this.state.validEmail ? <i className="fas fa-check-circle validation-icon"></i> : null}
                            {this.state.message !== "" ? <i className="fas fa-times-circle red validation-icon"></i> : null}
                    </div>
                    <div className="form-group">
                            <input 
                                type="text" 
                                name="username"
                                value={this.state.stateRegister.username}
                                    onChange={this.onChange}
                                onKeyDown={this.onKeyDownUsername}
                                // onBlur={this.onBlurUsername}
                                required/>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Username</label>
                            {this.state.loadingUsername ? <Loading className="loading-input" /> : null}
                            <div className="red absolute fs11">{this.state.messageUsername}</div>
                            {this.state.validUsername ? <i className="fas fa-check-circle validation-icon"></i> : null}
                            {this.state.messageUsername !== "" ? <i className="fas fa-times-circle red validation-icon"></i> : null}
                    </div>
                    {/* {this.state.messageUsername === "" ? <p className="addon-text">Rekomendasi: <span className="text-link">putrilestari123</span>, <span className="text-link">putrilestari234</span>, <span className="text-link">putriles2245</span></p> : null} */}
                    <div className="form-group">
                        <input 
                            type={this.state.view_password} 
                            name="password"
                            value={this.state.stateRegister.password}
                            onChange={this.onChange}
                            onBlur={this.onBlurPassword}
                            required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Password</label>
                        <i className={`far icon_password ${this.state.icon_password}`} onClick={this.viewPassword}></i>
                        <div className="red absolute fs11">{this.state.messagePassword}</div>
                    </div>
                    <div className="text-center">
                        {
                            this.state.loadingButton ?
                            <Loading className="loading-btn mb50"/>
                            :
                            <button type="submit" className={`btn btn-solid primary btn-register ${this.validateForm() ? null : "disabled"}`} onClick={this.handleSubmitRegister}>Daftar</button>
                        }
                        <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                    </div>
                </form>
                </div>
            </div>
            <Login {...this.props} />
        </div>
        )
    }
    
}

const mapStateToProps = (state) => {
    let userData = state.user.user;

    return {
        toggle: state.register.toggle,
        userData
    }
}

export default NetworkDetector(connect(mapStateToProps, { toggleModalLogin, getUserData })(Register));