import React from 'react'

const ListCategory = (props) => 
(
    <div className="menu">
        <span onClick={props.changeCategory}>{props.name}<i className="fas fa-chevron-down"></i></span>
        <ul 
            className="navbar-nav" 
            style={{display : `${props.id === (props.parentActive ? props.parentActive : 0) ? "" : 'none'}`}}>
            {
                props.subCategory.data.map(sub => (
                    <li 
                        className={props.subActive === sub.id ? "active" : ""} 
                        key={sub.id}
                        onClick={() => props.onClick(sub.id, sub.name, props)}
                        >
                        {sub.name}
                    </li>
                ))
            }
            {
                props.subCategory.data.length > 0
                ? <li
                    className={props.subActive === "other" ? "active" : ""}
                    onClick={() => props.onClick("other", "Lain-lain", props)}
                >Lain-lain</li>
                : null
            }
            
        </ul>
    </div>
)

export default ListCategory
