import React from 'react'; 
import Currency from 'react-currency-formatter';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
    API_URL
} from './config';
import slug from 'slug';
import testLoading from './assert/files/loading/test-loading.gif';

export const FormatUang = props =>
(
    <Currency
        quantity={props.value}
        currency=""
        locale="id"
        pattern="##,###!"
    />
)


export const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

export const AlertInfo = Swal.mixin({
    title: 'Opss!',
    type: 'info',
    confirmButtonText: 'Ok'
})

export const AlertError = Swal.mixin({
    title: 'Opss!',
    type: 'error',
    confirmButtonText: 'Ok'
})

export const AlertSuccess = Swal.mixin({
    title: 'Yeyy!',
    type: 'success',
    confirmButtonText: 'Ok'
})

export const AlertWarning = Swal.mixin({
    title: 'Opss!',
    type: 'warning',
    confirmButtonText: 'Ok'
})

export const ConfirmWarning = Swal.mixin({
    title: 'Anda yakin?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Batal',
})

export const RedConfirmWarning = Swal.mixin({
    title: 'Anda yakin?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Batal',
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
})


export const ConfirmInfo = Swal.mixin({
    title: 'Konfirmasi',
    type: 'info',
    showCancelButton: true,
    confirmButtonText: 'Lanjut',
    cancelButtonText: 'Kembali',
})

export const AlertLoading = Swal.mixin({
    title: "Mohon Tunggu...",
    imageUrl: testLoading,
    showConfirmButton: false,
    allowOutsideClick: false
})


export const get = (url) => {
    return axios.get(`${API_URL}/${url}`)
}

export const post = (data, url) => {
    return axios.post(`${API_URL}/${url}`, data)
}

export const authGet = (url, token) => {
    return axios.get(`${API_URL}/${url}`, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}

export const getPaginate = (url) => {
    return axios.get(url)
}

export const postPaginate = (url, data) => {
    return axios.post(url, data)
}

export const authGetPaginate = (url, token) => {
    return axios.get(url, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}

export const authPost = (data, url, token) => {
    return axios.post(`${API_URL}/${url}`,data , {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}

export const authPostFile = (data, url, token) => {
    return axios.post(`${API_URL}/${url}`,data , {
        headers : {
            'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer ' + token,
        }
    })
}

export const authPostPaginate = (data, url, token) => {
    return axios.post(url ,data , {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

export const authPut = (data, url, token) => {
    return axios.put(`${API_URL}/${url}`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}

export const authDelete = (url, token) => {
    return axios.delete(`${API_URL}/${url}`, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}


export const convertSlug = (keyword) => {
    return slug(keyword, {
        replacement: '-',      // replace spaces with replacement
        symbols: true,         // replace unicode symbols or not
        remove: /[.]/g,          // (optional) regex to remove characters
        lower: true,           // result in lower case
        charmap: slug.charmap, // replace special characters
        multicharmap: slug.multicharmap // replace multi-characters
    })
}


export const callNotification = () => {
    window.$("#notif_success").fadeIn();
    setTimeout(() => {
        window.$("#notif_success").fadeOut('slow');
    }, 3000);
}