import React from 'react'
import { Link } from "react-router-dom"
import _ from 'lodash'
import classnames from 'classnames'
// import Award from '../../../assert/icon/award.png'
import { URL } from '../../../config'
import SingleProductReview from '../_SingleProduct/SingleProductReview'
// import SingleProductDiscussion from '../_SingleProduct/SingleProductDiscussion'
import EmptyResult from '../../Global/EmptyResult';
import imageEmpty from '../../../assert/icon/empty-review.png';
import { FormatUang } from '../../../helper';
import NumberFormat from 'react-number-format'
import ReactLoading from 'react-loading';
import SingleProductDiscussionV2 from '../_SingleProduct/SingleProductDiscussionV2';
import styled from 'styled-components';
import i18n from '../../../i18n';
import logo_vip from '../../../assert/icon/vip_logo.svg';
const Ppn = styled.span`
    font-style: italic;
    font-weight: 300;
    color: #84C225;
`;

const Loading = () => <div className="loading-btn"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"20px"} width={"20px"} /></div>
const TabDeskripsi = props => (
    <div className="tab-div">
        {
            props.spesifikasi.length > 0 ? <>
                <h5><b>Spesifikasi Produk</b></h5>
                <table className="p3">
                    <tbody>
                        {
                            props.spesifikasi.map((item, i) => ( 
                                <tr key={i}>
                                    <td valign="top" className="grey" width="50%">{item.name}</td>
                                    <td width="100%">{item.value}</td>
                                </tr>
                            ))
                        }                                                                                              
                    </tbody>
                </table>
                <hr style={{borderTop: "1px solid #BBBBBB"}}/>
                </>
            : null
        }        
        <div className="clearfix"></div>
        <h5><b>Deskripsi Produk</b></h5><br />
        <p className="product-info" style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: props.description}}/>
    </div>
)

export const TabPengiriman = (props) => {
    return (
    <div className="tab-div">
        <label className="fs14 pull-left mr20">Berat Barang</label>
        <label className="semibold">{props.berat} kg</label>
        <div className="clearfix"></div>
        <label className="fs14">Ekspedisi</label>

        <table className="p5">
            <tbody>
                <tr>
                    <td style={{width : '30%'}}></td>
                    <td className="grey">Jasa Pengiriman</td>
                    {/* <td className="grey">Waktu Pengantaran</td> */}
                </tr>
                {
                    props.data.map(item => (
                        <tr key={item.id}>
                            <td valign="top"><img src={`${URL}/images/shipment/${item.photo}`} alt="shipment"/></td>
                            <td>
                                <ul className="none-style">
                                    {
                                        item.detail.map(detail => <li key={detail.id}>{`${item.name} - ${detail.name}`}</li>)
                                    }
                                </ul>
                            </td>
                            {/* <td>
                                <ul className="none-style">
                                    <li>2-3 Hari</li>
                                </ul>
                            </td> */}
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
)}

export const NewTabPengiriman = (props) => {
    return (
    <div className="tab-div">
        <label className="fs14 pull-left mr20">{i18n.t("DetailProductComponent:NewTabPengiriman.berat")}</label>
        <label className="semibold">
            <NumberFormat
                value={props.berat}
                displayType={'text'}
                thousandSeparator="."
                decimalSeparator=","
                /> kg
            {/* {props.berat} kg */}
        </label>
        <div className="clearfix"></div>
        <label className="fs14">{i18n.t("DetailProductComponent:NewTabPengiriman.ekspedisi")}</label>

        <table className="p5">
            <tbody>
                <tr>
                    <td style={{width : '30%'}}></td>
                    <td className="grey">{i18n.t("DetailProductComponent:NewTabPengiriman.pengiriman")}</td>
                </tr>
                {
                    props.data.map(item => (
                        <tr key={item.id}>
                            <td valign="top"><img src={item.image} alt="shipment"/></td>
                            <td>
                                <ul className="none-style">
                                    <li>{item.name}</li>
                                </ul>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
)}


export const TabDetailProduct = props => {
    const renderTab = (active) => {
        switch(active){
            case 2 : return <NewTabPengiriman data={props.getShipment} berat={props.berat}/>
            case 3 : return props.jml_ulasan > 0 
                    ? <SingleProductReview dataProduct={props} />
                    : <EmptyResult image={imageEmpty} message={i18n.t("DetailProductComponent:TabDetailProduct.no_ulasan")} buttonText={0}/>
            case 4 : return <SingleProductDiscussionV2 dataProduct={props}/>
            default : return <TabDeskripsi {...props} />
        }
    }
    return (
        <div className="desc-product">
            <ul className="product-tab">
                <li className={classnames({"active" : props.active === 1})} onClick={() => props.setTab(1)}>{i18n.t("DetailProductComponent:TabDetailProduct.info_barang")}</li>
                <li className={classnames({"active" : props.active === 2})} onClick={() => props.setTab(2)}>{i18n.t("DetailProductComponent:TabDetailProduct.pengiriman")}</li>
                <li className={classnames({"active" : props.active === 3})} onClick={() => props.setTab(3)}>{i18n.t("DetailProductComponent:TabDetailProduct.ulasan")}</li>
                <li className={classnames({"active" : props.active === 4})} onClick={() => props.setTab(4)}>{i18n.t("DetailProductComponent:TabDetailProduct.diskusi")}</li>
            </ul>
            {renderTab(props.active)}
        </div>
    )
}

export const ImgThumb = props => (
    // <div className={image.id == this.state.selectImageID ? "thumb active" : "thumb"} onClick={() => this.selectImage(image.id)}>
    <div className={props.className} onClick={props.onClick}>
        <img src ={props.src} alt="agrinis"/>
    </div>
)


export const Breadcrumb = props => (
    <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/category/${props.category_id}`}>{props.category_name}</Link></li>
            {
                props.category_detail_name
                ? <li className="breadcrumb-item active" aria-current="page">{props.category_detail_name}</li>
                : null
            }
        </ol>
    </nav>
)

export const Seller = props => (
    <div className="store-side">
        <div className="store-product">
            {
                props.image
                ? <img src={props.image} className="img-store w75" alt="agrinis"/>
                : null
            }
            {
                props.is_vip
                ? <p className="store-name"><img src={logo_vip} alt="LOGO VIP" style={{height: "14px", "width": "14px"}} />&nbsp;{props.name}</p>
                : <p className="store-name">{props.name}</p>
            }
            {_.map(props.addresses.data, function(data, i){
                return (
                    <p className="store-location" key={i}>
                        <i className="fas fa-map-marker-alt"></i>&nbsp;{data.city}
                    </p>
                )
            })}
            {/* <div className="store-award">
                <img src={Award} alt="agrinis"/>
                <img src={Award} alt="agrinis"/>
            </div> */}
            <div className="store-btn">
                {
                    !props.isMyShop
                    ? props.userSubsricbe 
                        ? props.loadingButton ? <React.Fragment><Loading /><br /></React.Fragment> : <a href="/#" className="btn btn-solid grey-2" onClick={props.unsubScribeShop} style={{ whiteSpace: "normal" }}><i className="fa fa-bookmark primary"></i> {i18n.t("DetailProductComponent:Seller.langganan.remove")}</a>
                        : props.loadingButton ? <React.Fragment><Loading /><br /></React.Fragment> : <a href="/#" className="btn btn-solid grey-2" onClick={props.subScribeShop}><i className="far fa-bookmark"></i> {i18n.t("DetailProductComponent:Seller.langganan.add")}</a>
                    : null
                }
                {
                    !props.isMyShop
                    ? <a href="/#" className="btn btn-solid grey-2" onClick={props.hubungiPenjual}><i className="far fa-envelope"></i> {i18n.t("DetailProductComponent:Seller.hubungi")}</a>
                    : null
                }
                <Link className="btn btn-solid grey-2" to={{pathname : `/${props.domain}`}}><i className="fas fa-store"></i> {i18n.t("DetailProductComponent:Seller.kunjungi")}</Link>
            </div>
            {/* <p className="store-lastonline">Terakhir aktif 1 jam yang lalu</p> */}

        </div>
    </div>
)

export const ProductDetail = props => 
{
    let rating = [];
    for (let i = 0; i < 5; i++) {
        if(i < props.rating){
            rating.push(<i className="fas fa-star starred" key={i}></i>);
        }else{
            rating.push(<i className="fas fa-star" key={i}></i>);
        }
    }
    return (
        <div className="detail-product">
            <h1>{props.name}</h1>
            <p>{props.subtitle}</p>
            <div className="rating pull-left mr10">
                {rating}
            </div>
            <span className="rating-text pull-left">{props.jml_ulasan} {i18n.t("DetailProductComponent:ProductDetail.ulasan")}</span>
            <div className="clearfix"></div>
            { props.discount > 0 
            ?
            <div>
                <div className="discount">{props.discount} %</div> 
                <span className="discount">
                    Rp&nbsp;
                    <FormatUang value={props.real_price}/>
                </span>
            </div>
            : null }
            <div className="clearfix"></div>
            <div className="price">
                <span className="main-price">
                {
                    props.is_vip
                    ? <img src={logo_vip} alt="LOGO VIP" style={{height: "25px", "marginBottom": "4px"}} /> 
                    : null
                }
                Rp&nbsp;
                <FormatUang value={props.price}/>
                </span>
            </div>
            <div className="clearfix"></div>          

            <table>
                <tbody>
                    {_.map(props.prices, price =>{
                                let pembelian = price.max > 0 ? `${price.min} - ${price.max}` : `${price.min} ${i18n.t("DetailProductComponent:ProductDetail.lebih")}`;
                                if(price.id !== 0){
                                    return (
                                        <tr key={price.id}>
                                            <td>{i18n.t("DetailProductComponent:ProductDetail.pembelian")}{` ${pembelian}`}</td>
                                            <td>
                                                Rp. &nbsp;
                                                <FormatUang value={parseInt(price.price)}/>
                                            </td>
                                            <td className="pl10">
                                                {
                                                    price.real_price !== price.price
                                                    ? <span className="discount fs12">Rp <FormatUang value={parseInt(price.real_price)}/></span>
                                                    : null
                                                }
                                            </td>
                                            <td>
                                                {
                                                    price.discount > 0
                                                    ? <div className="discount" style={{padding: "0 10px", fontSize: "12px"}}>{price.discount}%</div>
                                                    : null
                                                }
                                            </td>                                            
                                        </tr>
                                    )
                                }
                        }
                    )}
                </tbody>
            </table>
            {
                props.ppn
                ? <Ppn>{i18n.t("DetailProductComponent:ProductDetail.ppn")}</Ppn>
                : null
            }
            <br/>
            <br/>
            {
                props.is_blacklist ? 
                    <div class="notification-box sm">
                        <div class="danger">
                            <i class="fas fa-info-circle"></i>
                            <p>Anda diblacklist oleh toko ini</p>
                        </div>
                   </div> : null
            }         
            <label>Jumlah</label>
            <div className="input-group qty-input" style={{zIndex: 1, width: '175px'}}>
                <div className="input-group-prepend">
                    <button 
                        className={`btn ${props.disableDecrement ? "disabled" : null}`} 
                        type="button" 
                        onClick={props.onDecrement}>
                        <i className="fas fa-minus no-margin"></i>
                    </button>
                </div>
                <input 
                    type="text" 
                    className="form-control" 
                    name="quantity"
                    value={props.qty} 
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
                <div className="input-group-prepend">
                    <button 
                        className="btn" 
                        type="button" 
                        onClick={props.onIncrement}>
                        
                        <i className="fas fa-plus no-margin"></i>
                    </button>
                </div>
            </div>
            <br/>
            <button className={`btn btn-solid primary ${props.is_blacklist ? 'disabled' : ''}`} onClick={props.onOrder}>{i18n.t("DetailProductComponent:ProductDetail.add_keranjang")}</button>  
        </div>
    )
}

export const MyProductDetail = props => (
    <div className="detail-product">
        <h1>{props.name}</h1>
        <p>{props.subtitle}</p>
        {
            props.status === 1
            ? <span className="label label-productactive">Aktif</span>
            : <span className="label label-productnonactive">Nonaktif</span>
        }
        <br/><br/>
        <label className="semibold">Harga</label><br />
        { 
            props.discount > 0 
            ? <div>
                <div className="discount">{props.discount} %</div> 
                <span className="discount">
                    Rp&nbsp;
                    <FormatUang value={props.real_price}/>
                </span>
                <div className="clearfix"></div>
            </div>
            : null 
        }
        <div className="price">
            <span className="main-price">
            Rp &nbsp;
            <FormatUang value={props.price}/>
            </span>
        </div>
            {
                props.is_vip
                ? <div className="price-vip">
                    <span className="main-price">
                    <img src={logo_vip} alt="LOGO VIP" style={{height: "25px", "marginBottom": "4px"}} /> Rp&nbsp;
                    <FormatUang value={props.price_vip}/>
                    { props.discount_vip > 0 
                    ?
                    <div className="discount-vip">
                        <div className="discount">{props.discount_vip} %</div> 
                        <span className="discount">
                            Rp&nbsp;
                            <FormatUang value={props.real_price_vip}/>
                        </span>
                    </div>
                    : null }                  
                    </span>              
                </div> 
                : null
            }
                     

        <table style={{ width: "123%" }}>
            <tbody>
                {_.map(props.prices, price =>{
                            let pembelian = price.max > 0 ? `${price.min} - ${price.max}` : `${price.min} atau lebih`;
                            if(price.id !== 0){
                                return (
                                    <tr key={price.id}>
                                        <td>{`Pembelian ${pembelian}`}</td>
                                        <td>
                                            Rp. &nbsp;
                                            <FormatUang value={parseInt(price.price)}/>
                                        </td>
                                        <td className="pl10">
                                            {
                                                price.real_price !== price.price
                                                ? <span className="discount fs12">Rp <FormatUang value={parseInt(price.real_price)}/></span>
                                                : null
                                            }
                                        </td>
                                        <td>
                                            {
                                                price.discount > 0
                                                ? <div className="discount" style={{padding: "0 10px", fontSize: "12px"}}>{price.discount}%</div>
                                                : null
                                            }
                                        </td>
                                        {/* HARGA VIP */}
                                        {
                                            props.is_vip
                                            ? <>
                                            <td>
                                                <img src={logo_vip} alt="LOGO VIP" /> Rp. &nbsp;
                                                    <FormatUang value={parseInt(price.price_vip)}/>
                                                </td>                                            
                                                <td className="pl10">
                                                    {
                                                        price.real_price_vip !== price.price_vip
                                                        ? <span className="discount fs12">Rp <FormatUang value={parseInt(price.real_price_vip)}/></span>
                                                        : null
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        price.discount_vip > 0
                                                        ? <div className="discount" style={{padding: "0 10px", fontSize: "12px"}}>{price.discount_vip}%</div>
                                                        : null
                                                    }
                                                </td>
                                            </>
                                            : null
                                        }
                                                                                  
                                    </tr>
                                )
                            }
                        }
                    )}
            </tbody>
        </table>
        {
            props.ppn
            ? <Ppn>{i18n.t("DetailProductComponent:MyProductDetail.ppn")}</Ppn>
            : null
        }
    </div>
)