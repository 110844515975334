import React from 'react'
import EmptyResult from "../Global/EmptyResult"
import imageEmpty from '../../assert/icon/empty-result.png'
import ReactLoading from 'react-loading';
import i18n from '../../i18n';
const ProductList = props => {
    const renderStar = (starred) => {

        let arr_star = [];
        for (let i = 0; i < 5; i++) {
            if (i < starred) {
                arr_star.push(<i className="fas fa-star starred" key={i}></i>)
            } else {
                arr_star.push(<i className="fas fa-star" key={i}></i>)
            }
        }

        return arr_star;
    }

    if (props.loading) {
        return (<p>Loading ...</p>)
    } else {
        if (props.totalData === 0) {
            let msg = i18n.t("Statistic:barang_populer_list.tidak_ada_barang");
            let btnText = 0;

            if (props.status === 2) {
                msg = i18n.t("Statistic:barang_populer_list.tidak_ada_barang_nonaktif");
                btnText = 0;
            }

            return <EmptyResult
                image={imageEmpty}
                message={msg}
                buttonAction={props.emtyDataAction}
                buttonText={btnText}
            />
        } else {
            return (
                <table className="table table-product" style={{ display: props.grid ? "none" : "" }}>
                    <thead>
                        <tr>
                            <th className="w75">{i18n.t("Statistic:barang_populer_list.gambar")}</th>
                            <th>{i18n.t("Statistic:barang_populer_list.nama")}</th>
                            <th>{i18n.t("Statistic:barang_populer_list.rating")}</th>
                            <th>{i18n.t("Statistic:barang_populer_list.dilihat")}</th>
                            <th>{i18n.t("Statistic:barang_populer_list.disukai")}</th>
                            <th>{i18n.t("Statistic:barang_populer_list.terjual")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map(product => (
                                <tr key={product.id}>
                                    <td><img src={product.image} className="img-fluid" alt="Agrinis" /></td>
                                    <td><span className="product-name">{product.name}</span></td>
                                    <td><div className="rating">{renderStar(product.rating)}</div></td>
                                    <td>{product.viewer}</td>
                                    <td>{product.like}</td>
                                    <td>{product.terjual}</td>
                                </tr>
                            ))
                        }
                        {
                            props.hasMoreData
                                ? <tr>
                                    <td colSpan="7" align="center">
                                        {
                                            props.loadingMoreLoading
                                                ? <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} />
                                                : <div className="text-center">
                                                    <button className="btn btn-solid primary" onClick={props.fetchMoreData}>{i18n.t("Statistic:barang_populer_list.lainnya")}</button>
                                                </div>
                                        }

                                    </td>
                                </tr>
                                : null
                        }
                    </tbody>
                </table>
            )
        }
    }
}

export default ProductList
