import _ from 'lodash';

export const addItemToCart = (cartItems, cartItemToAdd) => {

    const existingCartItem = cartItems.find(
        cartItem => cartItem.id === cartItemToAdd.id
    );
    
    if (existingCartItem) {
        return cartItems.map(cartItem =>
        cartItem.id === cartItemToAdd.id
            ? { 
                ...cartItem, 
                quantity: cartItem.quantity + cartItemToAdd.quantity, 
                sub_total: cartItem.sub_total + cartItemToAdd.sub_total, 
                sub_diskon: cartItem.sub_diskon + cartItemToAdd.sub_diskon
            }
            : cartItem
        );
    }

    return [...cartItems, { ...cartItemToAdd}];
};

export const addItemToCartV2 = (cartItems, cartItemToAdd) => {

    const existingCartItem = cartItems.find(
        cartItem => cartItem.id === cartItemToAdd.id
    );
    
    if (existingCartItem) {
        return cartItems.map(cartItem => {
            let qtyToAdd = cartItem.quantity + cartItemToAdd.quantity;
            let new_discount_price = cartItem.discount_price;
            let new_real_price = cartItem.real_price;
            let new_price = new_real_price - new_discount_price;

            let obj_price = findPrice(cartItem, qtyToAdd);
            
            if(obj_price){
                new_discount_price = obj_price.discount;
                new_real_price = parseInt(obj_price.price);
                new_price = new_real_price - new_discount_price;
            }

            return cartItem.id === cartItemToAdd.id
                ? { 
                    ...cartItem, 
                    quantity: qtyToAdd,
                    price: new_price,
                    real_price: new_real_price,
                    discount_price: new_discount_price,
                    sub_total: qtyToAdd * new_price,
                    sub_diskon: qtyToAdd * new_discount_price
                }
                : {...cartItem}
        }
        );
    }

    let qtyToAdd = cartItemToAdd.quantity;
    let new_discount_price = cartItemToAdd.discount_price;
    let new_real_price = cartItemToAdd.real_price;
    let new_price = new_real_price - new_discount_price;

    let obj_price = findPrice(cartItemToAdd, cartItemToAdd.quantity);
    if(obj_price){
        new_discount_price = obj_price.discount;
        new_real_price = parseInt(obj_price.price);
        new_price = new_real_price - new_discount_price;
    }


    return [
        ...cartItems, 
        { 
            ...cartItemToAdd,
            price: new_price,
            real_price: new_real_price,
            discount_price: new_discount_price,
            sub_total: qtyToAdd * new_price,
            sub_diskon: qtyToAdd * new_discount_price
        }
    ];
};

export const addItemToCartV3 = (cartItems, cartItemToAdd) => {

    const existingCartItem = cartItems.find(cartItem => cartItem.id === cartItemToAdd.id);
    
    if (existingCartItem) {
        return cartItems.map(cartItem => {

            if(cartItem.id === cartItemToAdd.id) {

                let qtyToAdd = cartItem.quantity + cartItemToAdd.quantity;
                let obj_price = findPrice(cartItem, qtyToAdd);
                
                let price = obj_price.price;
                let real_price = obj_price.real_price;
                let discount = obj_price.discount;
                let discount_price = real_price - price;

                return { 
                    ...cartItem, 
                    quantity: qtyToAdd,
                    price,
                    real_price,
                    discount_price,
                    discount,
                    sub_total: qtyToAdd * price,
                    sub_diskon: qtyToAdd * discount_price
                };
            } else {
                return {...cartItem};
            }

        }
        );
    }

    let qtyToAdd = cartItemToAdd.quantity;

    let obj_price = findPrice(cartItemToAdd, cartItemToAdd.quantity);

    let price = obj_price.price;
    let real_price = obj_price.real_price;
    let discount = obj_price.discount;
    let discount_price = real_price - price;

    return [
        ...cartItems, 
        { 
            ...cartItemToAdd,
            price,
            real_price,
            discount_price,
            discount,
            sub_total: qtyToAdd * price,
            sub_diskon: qtyToAdd * discount_price
        }
    ];
};

export const onChangeAddItemToCartV3 = (cartItems, cartItemToAdd) => {

    const existingCartItem = cartItems.find(cartItem => cartItem.id === cartItemToAdd.id);
    
    if (existingCartItem) {
        return cartItems.map(cartItem => {

            if(cartItem.id === cartItemToAdd.id) {

                let qtyToAdd = cartItemToAdd.quantity;
                let obj_price = findPrice(cartItem, qtyToAdd);
                
                let price = obj_price.price;
                let real_price = obj_price.real_price;
                let discount = obj_price.discount;
                let discount_price = real_price - price;

                return { 
                    ...cartItem, 
                    quantity: qtyToAdd,
                    price,
                    real_price,
                    discount_price,
                    discount,
                    sub_total: qtyToAdd * price,
                    sub_diskon: qtyToAdd * discount_price
                };
            } else {
                return {...cartItem};
            }

        }
        );
    }

    let qtyToAdd = cartItemToAdd.quantity;

    let obj_price = findPrice(cartItemToAdd, cartItemToAdd.quantity);

    let price = obj_price.price;
    let real_price = obj_price.real_price;
    let discount = obj_price.discount;
    let discount_price = real_price - price;

    return [
        ...cartItems, 
        { 
            ...cartItemToAdd,
            price,
            real_price,
            discount_price,
            discount,
            sub_total: qtyToAdd * price,
            sub_diskon: qtyToAdd * discount_price
        }
    ];
};

export const onChangeAddItemToCartV2 = (cartItems, cartItemToAdd) => {
    
    return cartItems.map(cartItem => {
        let qtyToAdd = cartItemToAdd.quantity;
        let new_discount_price = cartItem.discount_price;
        let new_real_price = cartItem.real_price;
        let new_price = new_real_price - new_discount_price;

        let obj_price = findPrice(cartItem, qtyToAdd);
        
        if(obj_price){
            new_discount_price = obj_price.discount;
            new_real_price = parseInt(obj_price.price);
            new_price = new_real_price - new_discount_price;
        }

        return cartItem.id === cartItemToAdd.id
            ? { 
                ...cartItem, 
                quantity: qtyToAdd,
                price: new_price,
                real_price: new_real_price,
                discount_price: new_discount_price,
                sub_total: qtyToAdd * new_price,
                sub_diskon: qtyToAdd * new_discount_price
            }
            : {...cartItem}
    }
    );
};

export function findPrice(cartItem, qtyToAdd){

    let result = cartItem.prices.find(price => qtyToAdd >= price.min && qtyToAdd <= (price.max === 0 ? (qtyToAdd + 1) : price.max));

    if(result) {
        return result;
    }

    return cartItem.prices.find(price => price.id === 0);
}


export const syncItemCart = (cartItems, cartItemToAdd) => {

    let data = cartItemToAdd.map(c => {
        const exiting = cartItems.find(cartItem => cartItem.id === c.id);

        if(exiting) {
            let index = cartItems.indexOf(exiting);
            cartItems.splice(index, 1);
            return {
                ...exiting,
                quantity : exiting.quantity + c.quantity,
                sub_total: exiting.sub_total + c.sub_total, 
                sub_diskon: exiting.sub_diskon + c.sub_diskon
            }
        }

        return c
    })

    return [...cartItems, ...data];
};

export const updateItemToCart = (cartItems, cartItemToAdd) => {
    
    return cartItems.map(cartItem =>
        cartItem.id === cartItemToAdd.id
            ? { 
                ...cartItem, 
                quantity: cartItemToAdd.quantity,
                sub_total: cartItem.checked ? cartItemToAdd.sub_total : 0,
                disableDec: cartItemToAdd.disableDec,
                sub_diskon : cartItemToAdd.sub_diskon
            }
            : cartItem
        )
};

export const updateCheckedToCart = (cartItems, cartItemToAdd) => {
    return cartItems.map(cartItem =>
        cartItem.id === cartItemToAdd.id
            ? { 
                ...cartItem, 
                checked : !cartItem.checked
            }
            : cartItem
        )
};

export const updateCheckedShopToCart = (cartItems, shop) => {
    return cartItems.map(cartItem =>
        cartItem.shop_id === parseInt(shop.shop_id)
            ? { 
                ...cartItem, 
                checked : shop.checked ? true : false,
                disabledChecked : false
            }
            : { 
                ...cartItem,
                checked : shop.checked ? false : true,
                disabledChecked : true
            }
        )
};


export const removeItemsCart = (cartItems, cartItemToRemove) => {
    return _.differenceBy(cartItems, cartItemToRemove, 'id')
};

export const removeItemCart = (cartItems, cartItemToRemove) => {
    return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id);
};

export const removeShopItemCart = (cartItems, shop_id) => {
    
    return cartItems.filter(cartItem => cartItem.shop_id !== parseInt(shop_id));
};