import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
    NewHeader,
    LoadingPage
} from '../../../../components';
import NumberFormat from 'react-number-format'
import {
    FormatUang, authPost, AlertError, ConfirmInfo, authPostPaginate
} from './../../../../helper';
import {
    getMyShop,
} from "../../../../actions/shopActions";
import {
    setNotification
} from '../../../../actions/notificationAction';
import "react-datepicker/dist/react-datepicker.css";
import {
    toggleModalLogin
} from '../../../../actions/registerActions';
import { LoadingMyShopHeader } from '../../../../components/Loading/Loading';
import Banner from "../../../../components/Shop/_Component/Banner";
import { fetchCategories } from '../../../../actions/categoryActions';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import EmptyResult from '../../../../components/Global/EmptyResult';
import imageEmptyAktif from '../../../../assert/icon/empty_aktif.svg';
import imageEmptyBlacklist from '../../../../assert/icon/empty_blacklist.svg';
import imageEmptyNonaktif from '../../../../assert/icon/empty-nonaktif.svg';
import NetworkDetector from '../../../PUBLIC/NetworkDetector';
// const LoadingInfiniteScroll = () => 
// (
//     <div className="loading-statistic">
//         <ReactLoading 
//             type="spinningBubbles" 
//             color="#0C7F8F" 
//             height={"100px"} 
//             width={"50px"} />
//     </div>
// )

class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingPage: true,
            loadData: false,
            data: [],
            data_filtered: new Array( 26 ).fill( 1 ).map( ( _, i ) => String.fromCharCode( 65 + i ) ),
            badge_color: ["success", "danger", "warning", "secondary"],
            tab_active: 1,
            tab_text: "Aktif",
            todo_list: [],
            search_result: [],
            keyword: "",
            filter_data: [],
            sorting_data: [],
            sort_id: null,
            filter_id: null,
            huruf_selected: null,
            pagination: {},
            loadingMoreData: false,
            empty_text: ["Undang pembeli untuk menjadi pembeli spesial Anda agar dapat menikmati\nharga spesial dan fitur piutang dari toko Anda.", "Tidak ada pembeli spesial yang Anda nonaktifkan.", "Anda dapat memblacklist pembeli spesial yang bermasalah."],
            empty_img: [imageEmptyAktif, imageEmptyNonaktif, imageEmptyBlacklist],
            empty_title: ["Anda belum mempunyai pembeli spesial", "Tidak ada pembeli spesial non aktif", "Tidak ada pembeli yang Anda blacklist"],
            loadingMenu: false,
            loadingAddUser: false,
        }
    }
    componentDidMount() {
        this.props.getMyShop(this.props.userData.token);
        this.defaultRequest();
    }

    defaultRequest() {
        let data = {
            filter_id: 1,
            sort_id: 1,
            tab_active: 1,
        }
        this.setState({huruf_selected: null});

        this.retrieveData(data);
    }

    retrieveData(data) {
        this.setState({loadData: true});
        authPost(data, `vip/list`, this.props.userData.token)
        .then(res => {         
            this.setState({
                // data_filtered: this.filterData(res.data.data),
                data: res.data.data,
                filter_data: res.data.filter_data,
                sorting_data: res.data.sorting_data,
                sort_id: res.data.sort_id,
                filter_id: res.data.filter_id,
                pagination: res.data.meta.pagination,
                loadingPage: false,
                loadData: false
            })
        })
    }

    filterData(data) {
        let hasNumber = /\d/;
        let datax = data.reduce((r, e) => {
            let group = e.name[0];
            if (!hasNumber.test(group)) {
                if(!r[group]) r[group] = {group, children: [e]}
                else r[group].children.push(e);
            } else { 
                if(!r["#"]) r["#"] = {group: "#", children: [e]}
                else r["#"].children.push(e);
            }
            return r;
          }, {})
          let result = Object.values(datax)   
          return result;
    }

    filterHuruf(val) {
        this.setState({
            huruf_selected: val
        }, () => {
            let data = {
                filter_id: this.state.filter_id,
                sort_id: this.state.sort_id,
                tab_active: this.state.tab_active,
                huruf: this.state.huruf_selected
            }

            this.retrieveData(data);
        })
    }

    goToTop() { 
        window.history.pushState("", document.title, window.location.pathname + window.location.search); //Hapus #Huruf
        window.scrollTo({ top: 0, behavior: 'smooth' }) //Scroll ke atas
    }

    onChangeTab(id) { 
        this.setState({
            tab_active: id,
            tab_text: (id === 1 ? "Aktif" : id === 2 ? "Nonaktif" : "Blacklist"),
        }, () => { 
            this.retrieveData({
                tab_active: this.state.tab_active,
                sort_id: this.state.sort_id,
                filter_id: this.state.filter_id
            });
        })
    }

    findUser = (e) => {

        if(e.target.value.length > 0) {
            this.setState({
                keyword: e.target.value
            }, () => {
                let data = {
                    keyword : this.state.keyword,
                    todo_list: this.state.todo_list.map(t => t.id)
                }
        
                authPost(data, `vip/find_user`, this.props.userData.token)
                .then(res => {
                    this.setState({search_result: res.data})
                })
            })
        } else {
            this.setState({
                search_result: [],
                keyword: e.target.value
            })
        }
    }

    addTodoList(data) {
        let exists = this.state.todo_list.find(r => r.id === data.id)

        
        this.setState({
            todo_list: !exists ? [...this.state.todo_list, {...data, tempo_piutang: 30}] : this.state.todo_list,
            keyword: "",
            search_result: []
        })
    }


    handleChangeTempoPiutang(data, tempo) {
        this.setState({
            todo_list: this.state.todo_list.map(d => d.id === data.id ? {...d, tempo_piutang: tempo} : {...d})
        })
    }

    removeItemTodo(e, data) {
        e.preventDefault();
        this.setState({
            todo_list: this.state.todo_list.filter(d => d.id !== data.id)
        })
    }

    addUserVip = (e) => {
        e.preventDefault();

        let data = {
            users: this.state.todo_list,
            tab_active: 1,
            sort_id: this.state.sort_id,
            filter_id: this.state.filter_id,
            huruf: this.state.huruf_selected
        }
        this.setState({loadingAddUser: true});
        authPost(data, `vip/add_member_vip`, this.props.userData.token)
        .then(res => {
            this.setState({
                // data_filtered: this.filterData(res.data.data),
                data: res.data.data,
                pagination: res.data.meta.pagination,
                todo_list: [],
                loadingAddUser: false
            }, () => {
                window.$("#modalTambahAnggotaVIP").modal('hide');
            })
        })
        .catch(err => {
            this.setState({loadingAddUser: false});
            AlertError.fire({text: err.response.data.message});
        })
    }

    // filterHuruf(huruf)
    // {  
    //     window.location.hash = '#'+huruf;
    // }

    sortUserVip = (e) => {
        this.setState({
            sort_id: e.target.value
        }, () => {
            let data = {
                filter_id: this.state.filter_id,
                sort_id: this.state.sort_id,
                tab_active: this.state.tab_active,
                huruf: this.state.huruf_selected
            }

            this.retrieveData(data);
        })
    }

    onClickFilterData = (e) => {
        e.preventDefault();
        let data = {
            filter_id: this.state.filter_id,
            sort_id: this.state.sort_id,
            tab_active: this.state.tab_active,
            huruf: this.state.huruf_selected
        }
        this.retrieveData(data);
    }

    deleteUserVip(e, data) {
        e.preventDefault();
        ConfirmInfo.fire({
            text: 'Anda yakin menghapus data ini?'
        }).then(result => {
            if(result.value) {
                authPost({id: data.id}, `vip/remove_member_vip`, this.props.userData.token)
                .then(res => {
                    this.defaultRequest();
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        });
    }

    loadMoreData = (e) => {
        e.preventDefault();

        let { next } = this.state.pagination.links;

        let data = {
            filter_id: this.state.filter_id,
            sort_id: this.state.sort_id,
            tab_active: this.state.tab_active,
            huruf: this.state.huruf_selected
        };

        this.setState({loadingMoreData: true});
        
        authPostPaginate(data, next, this.props.userData.token)
        .then(res => {
            this.setState({
                data : [
                    ...this.state.data,
                    ...res.data.data
                ],
                pagination : res.data.meta.pagination,
                loadingMoreData: false
            })
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
            this.setState({loadingMoreData: false});
        })
    }

    hasMoreData = () => !(this.state.data.length === (this.state.pagination ? this.state.pagination.total : -1 ));


    render() {
        let Page = (
        <div>
            <div className="container-fluid">
	            <div className="header-banner-3 header-store">
                    {
                        !this.props.loadingHeader
                        ? <Banner {...this.props.shop}/>
                        : <LoadingMyShopHeader/>
                    }
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="vip-container">
                    <div className="vip-main">
                        <div className="row">
                            <div className="col-md-12">
                                    <div className="vip-top">
                                        <div className="toko-tab">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="mr20" style={{ fontSize: "24px",lineHeight: "33px" }}>Pembeli Spesial</label>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <div className="pull-right">
                                                        <a href="/#" className="btn btn-outline primary" data-toggle="modal" data-target="#modalTambahAnggotaVIP" data-backdrop="static" data-keyboard="false"><i className="fas fa-plus"></i> Tambah Pembeli Spesial</a>
                                                    </div>
                                                </div>
                                            </div>
                                        <ul>
                                            <li className={this.state.tab_active === 1 ? "active" : ""}><a href="/#" onClick={(e) => {e.preventDefault();this.onChangeTab(1)}}>Aktif</a></li>
                                            <li className={this.state.tab_active === 2 ? "active" : ""}><a href="/#" onClick={(e) => {e.preventDefault();this.onChangeTab(2)}}>Nonaktif</a></li>
                                            <li className={this.state.tab_active === 3 ? "active" : ""}><a href="/#" onClick={(e) => {e.preventDefault();this.onChangeTab(3)}}>Blacklist</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.filter_data.length > 0
                                ? <div className="col-md-2">
                                    <div style={{ position: "sticky", top: "10%" }}>
                                    <label className="mr20" style={{ fontSize: "24px",lineHeight: "33px", float:"left", marginLeft:"15px" }}>Filter</label>
                                    <br />
                                    <br />
                                        <div className="filter-menu" style={{ marginTop:"10px",marginLeft: "15px" }}>
                                            <div className="mb20">
                                                <h3>Jatuh Tempo Piutang</h3>
                                                <table width="100%" cellPadding="3">
                                                    <tbody>
                                                        {
                                                            this.state.filter_data.map(d => (
                                                                <tr key={d.id}>
                                                                    <td>
                                                                        <input 
                                                                            type="radio" 
                                                                            checked={this.state.filter_id === d.id } 
                                                                            onChange={() => this.setState({filter_id: d.id})}
                                                                            />
                                                                    </td>
                                                                    <td>{d.label}</td>
                                                                </tr>
                                                            ))
                                                        }                                            
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="mb20 text-center">
                                                <button className="btn btn-block btn-solid primary" onClick={this.onClickFilterData}>Terapkan</button>
                                                <br/>
                                                <a href="/#" onClick={(e) => {e.preventDefault(); this.defaultRequest()}}>Reset</a>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            
                            <div className="col-md-1">
                                <div className="list-nav-parent">
                                    <div className="list-nav">
                                        <div className="huruf" onClick={() => this.filterHuruf(null)}><i className="fas fa-home"></i></div>
                                        {
                                            this.state.data_filtered.map((d, i) => (
                                                <div className="huruf" key={i} onClick={() => this.filterHuruf(d)}>{d}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={this.state.filter_data.length > 0 ? 'col-md-9' : 'col-md-11'}>
                                <div className="vip-bottom">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <label className="mr20" style={{ fontSize: "24px",lineHeight: "33px" }}>Pembeli Spesial { this.state.tab_text }</label>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-12">
                                            <div className="sorting-input">
                                                <select className="form-control" onChange={this.sortUserVip} value={this.state.sort_id}>
                                                    {
                                                        this.state.sorting_data.map(d => (
                                                            <option value={d.id} key={d.id}>{d.label}</option>
                                                        ))
                                                    }
                                                </select>
                                                <label>Urutkan</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {
                                                this.state.loadData
                                                ? <div className="text-center" style={{height: "400px"}}>
                                                    <div className="loading-btn mb20" style={{height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                        <ReactLoading type="spinningBubbles" color="#0C7F8F"/>
                                                    </div>
                                                </div>
                                                :  <div className="user-container">
                                                <div className="row">
                                                    {
                                                        this.state.huruf_selected
                                                        ? <div className="col-lg-12 col-md-12 col-12" tabIndex="0">
                                                            <h4 className="vip-title"><span>{this.state.huruf_selected}</span></h4>
                                                        </div>
                                                        : null
                                                    }
                                                        
                                                        {
                                                            this.state.data.length > 0
                                                            ? this.state.data.map((child, j) => (
                                                                <div className="col-lg-4 col-md-6 col-6" key={j}>                               
                                                                    <div className="vip-user-box">
                                                                        <div className="pull-right more-menu">
                                                                            <a href="/#" className="dropdown-toggle no-toggle" role="button" data-toggle="dropdown"><i className="fas fa-ellipsis-h fs16 primary"></i></a>
                                                                            <div className="dropdown-menu dropdown-menu-left dropdown-menu-sm">
                                                                                <Link
                                                                                    className="dropdown-item" 
                                                                                    to={`/myshop/vip/detail/${child.id}`}><i className="fas fa-eye"></i> &nbsp;Detail</Link>
                                                                                    {
                                                                                        child.can_delete
                                                                                        ? <a 
                                                                                            className="dropdown-item red" 
                                                                                            href="/#"
                                                                                            onClick={(e) => this.deleteUserVip(e, child)}
                                                                                            ><i className="fas fa-trash-alt"></i> &nbsp;Hapus</a>
                                                                                        : null
                                                                                    }
                                                                                
                                                                            </div>
                                                                        </div>        
                                                                        <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <h3>{child.name}</h3>
                                                                                    {
                                                                                        child.is_phone ? 
                                                                                        <>
                                                                                            <i className="fas fa-phone"></i> &nbsp;
                                                                                                <NumberFormat
                                                                                                    value={child.contact} 
                                                                                                    displayType={'text'}
                                                                                                    format="+## ### #### #### #"
                                                                                                    />
                                                                                        </>
                                                                                        : 
                                                                                        <span><i className="fas fa-envelope"></i> {child.contact} </span>
                                                                                    }
                                                                                    <br /><br />
                                                                                    {
                                                                                        child.hutang
                                                                                        ? <>
                                                                                        <p style={{ color: "#555555" }}>Piutang Jatuh Tempo</p>
                                                                                        <div className="block primary fs17 mt10">
                                                                                            Rp &nbsp;<FormatUang value={child.hutang.nominal} /> &nbsp;
                                                                                            <span className={`badge badge-pill ${child.status_class}`} style={{ marginTop:"4px", fontWeight: "100" }}>
                                                                                                <i className="fas fa-calendar-alt"></i> &nbsp;{child.hutang.jatuh_tempo}
                                                                                            </span>
                                                                                        </div>
                                                                                        </>
                                                                                        : null
                                                                                    }
                                                                                    
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="vip-user-img-parent">
                                                                                        <img src={child.image} alt="Pembeli Spesial" className={this.state.tab_active === 3 ? "vip-user-img-blacklist" : "vip-user-img"} />
                                                                                        {
                                                                                            this.state.tab_active === 3 ? 
                                                                                                <span className={child.hutang ? "badge badge-pill badge-dark blacklist-badge-child" : "badge badge-pill badge-dark blacklist-badge"}>Blacklist</span>
                                                                                            : this.state.tab_active === 2 ? 
                                                                                                <span className={child.hutang ? "badge badge-pill badge-warning blacklist-badge-child" : "badge badge-pill badge-warning blacklist-badge"}>Nonaktif</span>
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                        </div>                             
                                                                    </div>
                                                                </div> 
                                                            ))
                                                            : <div className="col-lg-12 col-md-12 col-12 empty-list-vip">
                                                                <EmptyResult image={this.state.empty_img[this.state.tab_active-1]} message={this.state.empty_text[this.state.tab_active-1]} title={this.state.empty_title[this.state.tab_active-1]} buttonText={0}/>
                                                            </div>
                                                        }
                                                        {/* </React.Fragment>
                                                    ))
                                                } */}
                                                
                                                </div>
                                                <hr/>
                                                    {
                                                            this.hasMoreData()
                                                            ? <div className="text-center">
                                                                {
                                                                    this.state.loadingMoreData
                                                                    ? <div className="loading-btn mb20">
                                                                        <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} />
                                                                    </div>
                                                                    : <a href="/#" className="btn btn-outline primary" onClick={this.loadMoreData}>Data lainnya</a>
                                                                }
                                                            </div>
                                                            : null
                                                        }
                                                </div>
                                            }
                                        
                                        </div>                                                                                                                           
                                    </div>                                                                                                                                                                                                                                        
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>         
        )
        return(
            <div className="mg-top">
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    <LoadingPage />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <div className="modal fade modal-2" id="modalTambahAnggotaVIP" tabIndex="-1" role="dialog" aria-hidden="true" >
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>TAMBAH PEMBELI SPESIAL</h2>
                                    <div className="row">
                                        <div className="col-md-12 pl5">
                                            <div className="form-group-div">
                                                <label htmlFor="">Cari Pembeli</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Pembeli" 
                                                    onChange={this.findUser}
                                                    value={this.state.keyword}
                                                    />
                                                {
                                                    this.state.search_result.length > 0
                                                    ? <div className="search-result-vip">
                                                        <ul className="shop-result">
                                                            {
                                                                this.state.search_result.map(data => (
                                                                    <li data-url="/ambasador" className="result" key={data.id} onClick={() => this.addTodoList(data)}>
                                                                        <img src={data.image} className="shop-icon" alt="Ambasador" />
                                                                            <div>
                                                                                <span className="block medium">
                                                                                    <b></b>{data.name}</span><span className="grey">
                                                                                    <i className="fas fa-xs fa-phone"></i> {data.contact}
                                                                                </span>
                                                                            </div>
                                                                    </li>
                                                                ))
                                                            }                                                                                                                                                                     
                                                        </ul>
                                                    </div>

                                                    : null
                                                }
                                                
                                            </div>
                                            <div className="notification-box sm mt20">
                                                <div className="info">
                                                    <i className="fas fa-info-circle"></i>
                                                    <p>Cari akun yang ingin dijadikan sebagai Pembeli Spesial.</p>
                                                </div>
                                            </div>     
                                            <div className="notification-box sm mt20">
                                                <div className="info">
                                                    <i className="fas fa-info-circle"></i>
                                                    <p>Tempo piutang adalah waktu jatuh tempo saat pembeli spesial membayar dengan piutang.</p>
                                                </div>
                                            </div>                                                                                   
                                        </div>
                                    </div>

                                    <div className="choose-product-container">
                                        {
                                            this.state.todo_list.map(data => (
                                                <div className="choose-product" key={data.id}>
                                                    <img src={data.image} alt="asdnaskjdaskjd" width={60} height={60}/>
                                                    <div className="pull-left pt10">
                                                        <span className="block">{data.name}</span>
                                                        <span className="grey"><i className="fas fa-xs fa-phone"></i> {data.contact}</span>
                                                        <br />
                                                        <div className="form-group-div">
                                                            <label>Tempo Piutang*</label>
                                                            <br />
                                                            <div className="form-check" style={{ marginLeft: "20px" }}>
                                                                <input 
                                                                    className="form-check-input" 
                                                                    type="radio"
                                                                    checked={data.tempo_piutang === 30}
                                                                    onChange={() => this.handleChangeTempoPiutang(data, 30)}
                                                                    />
                                                                <label className="form-check-label" htmlFor="gridRadios1">
                                                                    30 Hari
                                                                </label>
                                                            </div>
                                                            <div className="form-check" style={{ marginLeft: "20px" }}>
                                                                <input 
                                                                    className="form-check-input" 
                                                                    type="radio"
                                                                    checked={data.tempo_piutang === 60}
                                                                    onChange={() => this.handleChangeTempoPiutang(data, 60)}
                                                                    />
                                                                <label className="form-check-label" htmlFor="gridRadios2">
                                                                    60 Hari
                                                                </label>
                                                            </div>
                                                            {/* <div className="form-group-div">
                                                                <label>Atur Sendiri</label><br />
                                                                <div className="input-group input-group-sm mb-2">
                                                                    <input type="text" className="form-control form-control-sm" id="inlineFormInputGroup" placeholder="Jumlah hari" />
                                                                    <div className="input-group-prepend">
                                                                        <div className="input-group-text">Hari</div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <a href="/#" className="pull-right" onClick={(e) => this.removeItemTodo(e, data)}>
                                                        <i className="fas fa-times fs16 red"></i>
                                                    </a>
                                                    <div className="clearfix"></div>
                                                </div>
                                            ))
                                        }                                                                                                                                                          
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingAddUser
                                        ? <div className="loading-btn mb20" style={{marginRight: "50px"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                        : <>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.addUserVip}>Simpan</button>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        userData: state.user.user,
        shop: state.shop.item,
        notifMessage: state.notification.message,
        categories: state.categories.items,
    }
}
export default NetworkDetector(connect(mapStateToProps, {
    getMyShop,
    setNotification,
    toggleModalLogin,
    fetchCategories
})(ListUser));
