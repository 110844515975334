import React from 'react';
import ContentLoader from "react-content-loader"

const HomeLoading = () => (
    <div className="container-fluid mg-top">
        <div className="container">
            <div className="header-banner">
                <div className="row">
                    <div className="col-md-6">
                    <ContentLoader                     
                        height={230}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                    </ContentLoader>
                    </div>
                    <div className="col-md-6">
                        <ContentLoader 
                            style={{marginBottom: "10px"}}
                            height={110}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                        </ContentLoader>
                        <ContentLoader 
                            height={110}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                        </ContentLoader>
                    </div>
                </div>
            </div>
        </div>                    

        <div className="container p10">
            {
                /**
                 * Kategory text
                 */
            }
                <h2 className="sub-title">
                    <ContentLoader 
                        height={7}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="50" height="100%" />
                    </ContentLoader>
                </h2>
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <ContentLoader 
                        height={190}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="col-lg-4 col-md-6">
                    <ContentLoader 
                        height={190}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="col-lg-4 col-md-6">
                    <ContentLoader 
                        height={190}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
            </div>
            <h2 className="sub-title" style={{marginTop: "20px"}}>
                    <ContentLoader 
                        height={7}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="50" height="100%" />
                    </ContentLoader>
                </h2>
            <div className="product-container" style={{overflow: "auto", marginBottom: 0}}>
                <div className="product-div" style={{marginBottom: 0, width: "20%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "20%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "20%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "20%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "20%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
            </div>
        </div>
        <div className="clearfix"></div>
    </div>
)

export default HomeLoading;