import { 
    FETCH_PRODUCTS, 
    FETCH_MORE_PRODUCTS,
    FETCH_PRODUCT_DETAIL, 
    ON_CHANGE_PRODUCT, 
    ON_DROP_PICTURE ,
    ON_CHANGE_PRODUCT_HARGA_GROSIR,
    ON_CLICK_PRODUCT_HARGA_GROSIR,
    ON_REMOVE_HARGA_GROSIR,
    ON_CHANGE_STATUS_HARGA,
    ON_CHECK_ALAMAT,
    ON_CHECK_PENGIRIMAN,
    GET_SHOP_BY_PRODUK_ID,
    SELECT_PRODUCT_MAGNIFY,
    SEARCH_PRODUCT,
    GET_PRODUCT_SHIPMENT,
    CHANGE_DISKON,
    ON_SEACRH,
    SAVE_KEYWORD,
    FETCH_PRODUCT_RATING,
    ON_INCREMENT,
    ON_DECREMENT,
    ON_CHANGE_QUANTITY,
    RESET_CREATE_PRODUCT,
    CHANGE_TEMP_PRICE,
    // UPDATE_LIKES_PRODUCT_DETAIL
} from "./types";

import {
    UPDATE_LIKES_PRODUCT
} from './types/userType';
import { GET_STATUS_PRODUCT, ERROR_PRODUCT } from "./types/loadingType";
import axios from "axios";
import { API_URL } from "../../src/config";
import {headers} from '../headers';


/**
 *  REQUEST API
 */


export const fetchProducts = (token) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    axios.get(`${API_URL}/product`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res =>{
            dispatch({
                type: FETCH_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        }
    )
        .catch(error => { // The first request fails
            dispatch({
                type: ERROR_PRODUCT,
                payload: true
            });
        });
}

export const fetchMoreProducts = (link, token) => dispatch => {
    
    axios.get(link, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res =>{
            dispatch({
                type: FETCH_MORE_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
        })
}

export const fetchMoreProductsAuth = (link, token) => dispatch => {

    axios.get(link, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res => {
            dispatch({
                type: FETCH_MORE_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
        })
}

export const postMoreProducts = (link, data) => dispatch => {
    
    axios.post(link, data)
        .then(res =>{
            dispatch({
                type: FETCH_MORE_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
        })
}

export const postMoreProductsAuth = (link, data, token) => dispatch => {
    
    return axios.post(link, data, {
        headers : {
            Authorization : 'Bearer ' + token
        }
    })
        .then(res =>{
            dispatch({
                type: FETCH_MORE_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
        })
}

export const postProductsWithCategory = (data) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    axios.post(`${API_URL}/product_with_category`, data)
        .then(res => {
            dispatch({
                type: FETCH_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        }
    )
}


export const fetchProductByDomainShop = (data) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    axios.post(`${API_URL}/product_by_domain_shop`, data)
        .then(res => {
            dispatch({
                type: FETCH_PRODUCTS,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        }
    )
}

export const fetchProductsWithCategory = (id, subid = null) => dispatch => {
    
    let url = subid != null ? `${API_URL}/product_with_category/${id}/${subid}` : `${API_URL}/product_with_category/${id}` ;
    axios.get(url)
        .then(res => {
            dispatch({
                type: FETCH_PRODUCTS,
                payload: res.data.data
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        }
    )
}

export const fetchProductDetail = params => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    return axios.get(`${API_URL}/product/show/${params.id}`)
        .then(res => {
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
            if(res.data.data.id){
                dispatch({
                    type: FETCH_PRODUCT_DETAIL,
                    payload: res.data.data
                })
            }
            else{
                window.location.href = '/';
            }
        });
}

export const fetchMyProductDetail = (params, token) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    return axios.get(`${API_URL}/show_my_product/${params.id}`, {
            headers : {
                Authorization : "Bearer " + token
            }
        })
        .then(res => {
            if(res.data.data.price){
                res.data.data.temp_price = res.data.data.price;
            }

            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
            dispatch({
                type: FETCH_PRODUCT_DETAIL,
                payload: res.data.data
            });
            
        });
}



export const getShopByProductId = id => dispatch => {
    axios.get(`${API_URL}/product/get_shop/${id}`)
        .then(res => 
            dispatch({
                type: GET_SHOP_BY_PRODUK_ID,
                payload: res.data.data
            })
        );
}


export const storeProduct = data => dispatch => {
    axios.post(`${API_URL}/product`, data, headers)
        .then(res => {
            // console.log(res)
        });
}

export const savePrevKeyword = keyword => dispatch => {
    dispatch({
        type: SAVE_KEYWORD,
        payload: keyword
    })
}

export const seacrhProduct = (data, token = null) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    axios.post(`${API_URL}/search`, data, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res => {
            dispatch({
                type: SEARCH_PRODUCT,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        });
}

export const postFavoriteProduct = (data, token) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    return axios.post(`${API_URL}/favourite`, data,{
            headers : {
                Authorization : 'Bearer ' + token
            }
        })
        .then(res => {
            dispatch({
                type: SEARCH_PRODUCT,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        });
}

export const searchFavouriteProduct = (data, token) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    });
    axios.post(`${API_URL}/favourite/search`, data, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res => {
            dispatch({
                type: SEARCH_PRODUCT,
                payload: res.data.data,
                meta: res.data.meta.pagination
            });
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            });
        });
}


export const productShipment = product_id => dispatch => {
    axios.get(`${API_URL}/product_shipment/${product_id}`)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_SHIPMENT,
                payload: res.data.data
            })
        });
}

export const onLikeProduct = (data, token) => dispatch => {
    return axios.post(`${API_URL}/like_product`, data, {
            headers : {
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(res => {
            // let update = data.action === "INSERT" ? 1 : -1 ;
            dispatch({
                type: UPDATE_LIKES_PRODUCT,
                payload: res.data
            })
            // dispatch({
            //     type: UPDATE_LIKES_PRODUCT_DETAIL,
            //     payload: update
            // })
        })
}


export const pusher = () => {
    return axios.get(`${API_URL}/pusher`);
}

export const fetchProductRating = data => dispatch => {
    axios.post(`${API_URL}/product_rating`, data)
        .then(res => {
            dispatch({
                type: FETCH_PRODUCT_RATING,
                payload: res.data
            })
        })
        .catch(err => alert("Terjadi Kesalahan!"));
}


export const updateDiscount = (data, token) => dispatch =>{
    return axios.put(`${API_URL}/product/update_discount`, data, {
        headers : {
            Authorization : 'Bearer ' + token
        }
    })
    .then(res => {
        dispatch({
            type: FETCH_PRODUCT_DETAIL,
            payload: res.data.data
        })
    })
}

export const updateStatusProduct = (data, token) => dispatch => {
    return axios.post(`${API_URL}/product/put_update_status`, data, {
        headers : {
            Authorization : 'Bearer ' + token
        }
    })
    .then(res => {
        dispatch({
            type: FETCH_PRODUCT_DETAIL,
            payload: res.data.data
        })
    })
}

/**
 * 
 * PRODUCT DETAIL
 */


export const selectImageMagnify = data => dispatch => {
    dispatch({
        type: SELECT_PRODUCT_MAGNIFY,
        payload: data
    })
}

export const changeDiskon = data => dispatch => {
    dispatch({
        type: CHANGE_DISKON,
        payload: data
    })
}


export const onIncrement = () => dispatch => {
    dispatch({
        type: ON_INCREMENT,
    })
}

export const onDecrement = () => dispatch => {
    dispatch({
        type: ON_DECREMENT
    })
}

export const onChangeQuantity = (data) => dispatch => {
    dispatch({
        type: ON_CHANGE_QUANTITY,
        payload : data === "" ? 0 : data
    })
}

/**
 * 
 * PRODUCT SEARCH
 */


export const handleChangeSearch = data => dispatch => {
    dispatch({
        type: ON_SEACRH,
        payload: data
    })
}


/**
 * 
 * CREATE PRODUCT
 */


export const handleChangeProduct = data => dispatch => {
        dispatch({
            type: ON_CHANGE_PRODUCT,
            payload: data
        })
}

export const handleChangeProductHargaGrosir = data => dispatch => {
        dispatch({
            type: ON_CHANGE_PRODUCT_HARGA_GROSIR,
            payload: data
        })
}

export const handleClickHargaGrosir = data => dispatch => {
        dispatch({
            type: ON_CLICK_PRODUCT_HARGA_GROSIR,
            payload: data
        })
}

export const onRemoveListHarga = data => dispatch => {
        dispatch({
            type: ON_REMOVE_HARGA_GROSIR,
            payload: data
        })
}

export const onChangeStatusHarga = data => dispatch => {
        dispatch({
            type: ON_CHANGE_STATUS_HARGA,
            payload: data
        })
}

export const onCheckAlamat = data => dispatch => {
        dispatch({
            type: ON_CHECK_ALAMAT,
            payload: data
        })
}

export const onCheckPengiriman = data => dispatch => {
        dispatch({
            type: ON_CHECK_PENGIRIMAN,
            payload: data
        })
}

export const onDropPicture = data => dispatch => {
        dispatch({
            type: ON_DROP_PICTURE,
            payload: data
        })
}

export const resetCreateProduct = () => dispatch => {
    dispatch({
        type: RESET_CREATE_PRODUCT
    })
}


export const changeTempPrice = (value) => dispatch => {
    dispatch({
        type: CHANGE_TEMP_PRICE,
        payload : value
    })
}
