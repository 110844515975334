import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Header from '../../../../components/Transaction/Header';
import { CSSTransition } from 'react-transition-group';

import ListProduct from '../../../../components/Transaction/ListProduct';
import { 
    postShopShipment,
    // getShop
} from '../../../../actions/shopActions';

import {
    addToCartV2
} from '../../../../actions/cartActions';

import { 
    changeQuantity,
    createInvoice,
    clearDataTransaction
} from '../../../../actions/transactionAction';

import {
    setShippingAddress
} from '../../../../actions/userAction';

import {
    TambahAlamat,
    // ShippingAddress,
    LoadingLock
} from '../../../../components';

import {
    // addItemToCart,
    // updateItemToCart,
    removeItemsCart,
    addItemToCartV2,
    onChangeAddItemToCartV2
} from '../../../../reducers/cart.util'

import {
    transactionItems,
    getTotalBelanja,
    getTotalBerat
} from '../../../../selector/transactionSelector'

import {
    FormatUang,
    AlertWarning,
    ConfirmInfo,
    ConfirmWarning,
    AlertError,
    Toast,
    authPost,
} from '../../../../helper';
import NumberFormat from 'react-number-format';
import HubungiPenjual from '../../../../components/Global/HubungiPenjual';
import NetworkDetector from '../../../PUBLIC/NetworkDetector'

import ReactLoading from 'react-loading';
import TransactionCreateLoading from '../../../../components/Loading/TransactionCreateLoading';
// import ShippingAddressTransction from './../../../../components/Transaction/ShippingAddressTransction';

class TransactionBuyerCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            activeBank : 1,
            shipment_id : '',
            redirectUrl : '/',
            loadingButton: false,
            shipments: [],
            shipment: null,
            shipment_temp: {},
            banks: [],
            defaultDistrict: [],
            products: [],
            total: 0,
            total_berat: 0,
            costs: [],
            loadingShipment: false,
            loadingValueShipment: false,
            shop_id: null,
            catatan: '',
            seller: {},
            current_address: this.props.address[0],
            current_address_edit: {},
            alamat: [],
            alamat_selected: this.props.address.length > 0 ? this.props.address.length > 1 ? parseInt(this.props.address[0].id) : parseInt(this.props.address.id) : '',
            alamat_selected2: 0,
            is_edit_address: false,
        }
    }

    componentDidMount() {
        if(this.props.products.length > 0){
            let dataCheckout = this.props.cartItems.filter(cartItem => cartItem.checked && cartItem.status === 1);
            authPost({data: JSON.stringify(dataCheckout)}, `cart/sync`, this.props.userData.token)
            .then(response => {
                let { shop_id } = dataCheckout[0];

                authPost({shop_id}, `invoice/create`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        loadingPage: false,
                        shop_id,
                        ...res.data,
                        products : response.data,
                        total: getTotalBelanja(response.data),
                        total_berat: getTotalBerat(response.data) * 1000,
                        seller: {
                            id: res.data.id,
                            domain: res.data.domain,
                            name: res.data.name,
                            image: res.data.image,
                            addresses: {
                                data: res.data.addresses
                            }
                        }
                     });
                })
                .catch(err => {
                    this.setState({loadingPage: false}, () => {
                        AlertError.fire(err.response.data.message);
                    });
                })
            })
            .catch(error => {
                this.setState({loadingPage: false}, () => {
                    AlertError.fire(error.response.data.message);
                });
            })
            
        }
        else{
            AlertError.fire({text: "Barang tidak ditemukan"})
            .then(() => {
                this.props.history.push(this.props.url_back);
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.address !== prevProps.address) {
            this.setCurrentAddress();
        }

    }

    setCurrentAddress() {
        this.setState({
            current_address: this.props.address[0],
        });
    }
    
    incrementQty(product){
        let { products } = this.state;
        this.setState({
            products : addItemToCartV2(products, {...product, quantity: 1}),
            shipment: null,
            shipment_id: '',
            shipments: []
        }, () => {
            this.setState({
                total: getTotalBelanja(this.state.products),
                total_berat: getTotalBerat(this.state.products) * 1000
            });
        })
        
        /*
        let cartItemToAdd = {
            ...product, 
            quantity : 1,
            sub_total : product.price,
            sub_diskon : product.discount_price
        };
        let newValue = addItemToCart(this.props.products, cartItemToAdd);
        this.props.changeQuantity(newValue);
        */
    }

    handleChange = (e) => {
        this.setState({catatan: e.target.value});
    }

    onchange(e, product){
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        if(validateValue === ''){
            validateValue = 0
        }

        let { products } = this.state;
        this.setState({
            products : onChangeAddItemToCartV2(products, {...product, quantity: parseInt(validateValue)}),
            shipment: null,
            shipment_id: '',
            shipments: []
        }, () => {
            this.setState({
                total: getTotalBelanja(this.state.products),
                total_berat: getTotalBerat(this.state.products) * 1000
            });
        })

        /*
        let cartItemToAdd = {
            ...product, 
            quantity : parseInt(validateValue),
            sub_total : (parseInt(validateValue) * product.price),
            sub_diskon : (parseInt(validateValue) * product.discount_price)
        };

        let newValue = updateItemToCart(this.props.products, cartItemToAdd);
        this.props.changeQuantity(newValue);
        */
    }


    decrementQty(product){
        let { products } = this.state;
        this.setState({
            products : addItemToCartV2(products, {...product, quantity: -1}),
            shipment: null,
            shipment_id: '',
            shipments: []
        }, () => {
            this.setState({
                total: getTotalBelanja(this.state.products),
                total_berat: getTotalBerat(this.state.products) * 1000
            });
        })
        /*
        let cartItemToAdd = {
            ...product, 
            quantity : -1,
            sub_total : (-1 * product.price),
            sub_diskon : (-1 * product.discount_price)
        };

        let newValue = addItemToCart(this.props.products, cartItemToAdd);
        this.props.changeQuantity(newValue);
        */
    }

    onBlur(product){

        let validateValue = product.quantity;

        if(validateValue <= product.min_order){
            validateValue = product.min_order
        }

        let { products } = this.state;
        this.setState({
            products : onChangeAddItemToCartV2(products, {...product, quantity: parseInt(validateValue)}),
        }, () => {
            this.setState({
                total: getTotalBelanja(this.state.products),
                total_berat: getTotalBerat(this.state.products) * 1000
            });
        })

        /*
        let cartItemToAdd = {
            ...product, 
            quantity : parseInt(validateValue),
            sub_total : (parseInt(validateValue) * product.price),
            sub_diskon : (parseInt(validateValue) * product.discount_price)
        };

        let newValue = updateItemToCart(this.props.products, cartItemToAdd);
        this.props.changeQuantity(newValue);
        */
    }

    handleOngkir() {
        let { token } = this.props.userData;
        this.state.products.map(product => {
            let exist = product.shipment.find(item => item.id === this.state.shipment_id);
            if (exist) {
                let data = {
                    product_id: product.id,
                    user_district_id: this.props.userData.current_address.data.district.value,
                    weight: (product.berat*1000),
                    courier: this.state.shipment_id
                }
                authPost(data, 'get_ongkir', token)
                .then(res => {
                    // console.log(res.data);
                })
            }
            return true;
        })
    }

    changeShipment(shipment){

        this.setState({shipment_id : shipment.id},
        () => {
            // this.handleOngkir();
            let newValue = this.state.products.map(product => {
                let exist = product.shipment.find(item => item.id === this.state.shipment_id);
                
                if(exist){
                    return {...product, fadeOut: false};
                }
                return {...product, fadeOut: true};
            });

            this.setState({
                products : newValue,
                total : getTotalBelanja(newValue),
                total_berat: getTotalBerat(newValue) * 1000
            })
        })
    }

    changeShipmentV2(shipment_temp, i){
        const old_temp = this.state.shipment_temp;
        if (this.state.total_berat > 30000 && this.state.shipments[i].third_party === 1) { 
            AlertError.fire({"text": "Maksimal total berat adalah 30kg."});
        }
        this.setState({
            shipment_temp, 
            shipment_id: i
        }, () => {
            let newValue = this.state.products.map(product => {
                let exist = product.shipment.find(item => item.id === this.state.shipment_temp.id);
                if(exist){
                    return {...product, fadeOut: false};
                }
                return {...product, fadeOut: true};
            });

            this.setState({
                products : newValue,
                total : getTotalBelanja(newValue),
                total_berat: getTotalBerat(newValue) * 1000
            }, () => { 
                if (this.state.total_berat > 0) { 
                    if(shipment_temp.third_party && old_temp.id !== shipment_temp.id){
                        this.getDataShipment("loadingValueShipment");
                    }
                }else{
                    let newshipment = this.state.shipments.map(shipment => {
                        return {...shipment, cost: {...shipment.cost, value: 0}}
                    })
                    this.setState({
                        shipments: newshipment
                    });
                }
            })
        })
    }

    selectShipment = (e) => {
        e.preventDefault();
        this.setState({
            shipment : this.state.shipments[this.state.shipment_id]
        }, () => {
            if(this.state.shipment)
            window.$("#modalPilihJasaPengiriman").modal("hide");
        })
    }

    loadShipment = (e) => {
        e.preventDefault();
        if (!this.state.current_address) {
            AlertWarning.fire({text: "Alamat pengiriman harus diisi terlebih dahulu!"});
            return false;
        }

        window.$("#modalPilihJasaPengiriman").modal();
        if(this.state.shipments.length === 0){
            this.getDataShipment('loadingShipment');
        }
    }

    getDataShipment(loading){
        this.setState({
            [loading]: true
        },() => {
            let data = {
                origin: this.state.third_party.city_id,
                destination: this.state.current_address.third_party.city_id,
                weight: this.state.total_berat,
                shop_id: this.state.shop_id
            }
            if (this.state.total_berat > 0) { 
                authPost(data, `shipment/get_cost`, this.props.userData.token)
                .then(res => {                    
                    this.setState({
                        [loading] : false,
                        shipments: res.data
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                    window.$("#modalPilihJasaPengiriman").modal("hide");
                })
            } else {
                this.setState({
                    [loading]: false,
                })
            }
        })
    }

    notifBarang(products){
        if(products.length > 0){
            return products.find(product => product.fadeOut === true);
        }

        return false;
    }


    onRemoveDetail(e, productToRemove){
        e.preventDefault();
        ConfirmWarning.fire({
            text : "Apakah anda yakin akan menghapus barang ini?"
        }).then(result => {
            if(result.value){
                let newValue = this.state.products.filter(product => product.id !== productToRemove.id);

                this.setState({
                    products : newValue,
                    total : getTotalBelanja(newValue),
                    total_berat: getTotalBerat(newValue) * 1000,
                    shipment: null,
                    shipment_id: '',
                    shipments: []
                })
                // this.props.changeQuantity(newValue);
            }
        })
    }

    validationTransaction() {

        if(!this.props.address === 0)
        {
            AlertWarning.fire({text : "Lengkapi Alamat Terlebih dahulu"});
            return false;
        }
        else if(!this.state.shipment)
        {
            AlertWarning.fire({text : "Lengkapi Pengiriman"});
            return false;
        }
        // else if(this.props.total === 0)
        // {
        //     alert("Barang tidak ditemukan");
        //     return false;
        // }
        return true;
    }

    loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

    createTransaction = (e) => {
        e.preventDefault();
        
        let detail = this.state.products.filter(product => !product.fadeOut);

        if(detail.length <= 0){
            AlertWarning.fire({text : "Barang tidak di temukan!"});
            return false;
        }

        if (this.state.total > this.state.limit_checkout) { 
            AlertWarning.fire({text : "Transaksi melebihi batas yang ditentukan!"});
            return false;
        }

        if(this.validationTransaction()){
            ConfirmInfo.fire({
                text : "Apakah anda yakin data sudah benar?"
            }).then(result => {
                if(result.value){
                    this.setState({loadingButton : true})
                    let data = {
                        shipping_address : JSON.stringify({...this.state.current_address, district_id : this.state.current_address.district.value}),
                        district_id : this.state.current_address.district.value,
                        bank_id : this.state.activeBank,
                        shipment_id : this.state.shipment.id,
                        detail,
                        total : this.state.total,
                        shop_id : this.state.id,
                        shipment: this.state.shipment,
                        catatan: this.state.catatan,
                        total_berat: this.state.total_berat
                    };
    
                    let { token } = this.props.userData;

                    createInvoice(data , token)
                    .then(res => {
                        // this.props.addToCartWithApi(removeItemsCart(this.props.cartItems, detail), token)
                        let newCartItems = removeItemsCart(this.props.cartItems, detail);
                        let products = newCartItems.map(product => product.id);
                        authPost({value: JSON.stringify(newCartItems), products}, `cart`, this.props.userData.token)
                        .then(res => {
                            this.props.addToCartV2(newCartItems);
                        })
                        this.setState({redirectUrl : `/transaction/detail/${res.data.id}`},
                        () => {
                            Toast.fire({
                                text : "Pesanan berhasil dibuat",
                                type : "success"
                            }).then(result => {
                                this.props.clearDataTransaction()
                                this.props.history.push(this.state.redirectUrl)
                                this.setState({loadingButton : false})
                            })
                        })
                    })
                    .catch(err => {
                        AlertError.fire({text : err.response.data.message});
                        this.setState({loadingButton : false})
                    })
                }
            })
        }
    }

    onChangePilihAlamat = (e, id) => {
        this.setState({
            alamat_selected: parseInt(id),
            alamat_selected2: parseInt(e.currentTarget.dataset.id),
        });
    }
    
    onClickSimpanAlamat = (e) => {
        e.preventDefault();
        this.setState({
            current_address: this.props.address[this.state.alamat_selected2],
            shipment: null,
            shipment_id: '',
            shipments: [],
        }, () => {
            window.$("#modalPilihAlamat").modal('hide');
        });
    }

    onClickEditAlamat = (e) => {
        e.preventDefault();
            this.setState({
                current_address_edit: this.props.address[e.currentTarget.dataset.id],
                is_edit_address: true,
                shipment: null,
                shipment_id: '',
                shipments: [],
            }, () => {
                setTimeout(() => {
                    window.$("#modalTambahAlamat").modal('show');
                },500)
                window.$("#modalPilihAlamat").modal('hide');
                    
            });
    }
    onClickHapusAlamat = (e, id) => {
        e.preventDefault();
        if (parseInt(id) === this.state.alamat_selected) 
        {
            AlertError.fire({ text: "Alamat yang ingin dihapus masih aktif, silahkan pilih alamat lain!" });
            return false;
        }
        let data = {
            id: parseInt(id)
        };
        ConfirmWarning.fire({
            text: "Apakah anda yakin akan menghapus data ini?"
        }).then(result => {
            if (result.value) {
                authPost(data, `shipping_address/delete`, this.props.userData.token)
                    .then(res => {
                        this.setState({
                            current_address: this.props.address[0],
                            shipment: null,
                            shipment_id: '',
                            shipments: [],
                        }, () => {
                            Toast.fire({
                                type: "success",
                                title: "Data berhasil di hapus"
                            });
                            this.props.setShippingAddress(res.data.data);
                        });
                    })
                    .catch(err => {
                        AlertError.fire({ text: 'Ada yang salah!' });
                    })
            }
        })
    }
    onClickTambahAlamat = (e) => {
        e.preventDefault();
        this.setState({
            is_edit_address: false,
            shipment: null,
            shipment_id: '',
            shipments: [],
        }, () => {
            setTimeout(() => {
                window.$("#modalTambahAlamat").modal('show');
            },500)
            window.$("#modalPilihAlamat").modal('hide');
            
        });
    }
    
    render() {
        let Page = (
            <div className="container">
                    <div className="form-transaction">
                        <Header {...this.props}/>
                        <h1 className="form-title">Pesan Barang</h1>
                        <form>
                            <div className="form-subtitle">
                                {/* {
                                    this.props.address.penerima
                                    ? <a href="/#" className="pull-right"><i className="fas fa-pencil-alt"></i> Ubah Alamat</a>
                                    : null
                                } */}
                                <h2>Alamat Pengiriman</h2>
                            </div>
                            <div className="mb30">
                            {this.props.address.length > 0
                            ?
                            !this.state.current_address
                            ?
                                <div className="mb30">
                                    <a href="/#" className="btn btn-outline primary pull-right" data-toggle="modal" data-target="#modalPilihAlamat">Ubah Alamat</a>
                                    <div>
                                        <p className="bold mb0">{this.props.address[0].label}</p>
                                        <p className="grey mb0">{this.props.address[0].penerima}</p>
                                        <p className="grey mb0"><NumberFormat
                                            value={this.props.address[0].phone}
                                            displayType={'text'}
                                            format="#### #### #### #"
                                        /></p>
                                        <p className="grey mb0">
                                            {this.props.address[0].alamat_lengkap} {this.props.address[0].kode_pos}
                                            {this.props.no_br ? <span>&nbsp;</span> : <br />}
                                            {this.props.address[0].kec}, {this.props.address[0].kab}
                                        </p>
                                        <hr />
                                        <p className="grey mb0">Keterangan Lokasi: {this.props.address[0].instruksi_tambahan}</p>
                                    </div>
                                </div>
                                :
                                <div className="mb30">
                                    <a href="/#" className="btn btn-outline primary pull-right" data-toggle="modal" data-target="#modalPilihAlamat">Ubah Alamat</a>
                                    <div>
                                        <p className="bold mb0">{this.state.current_address.label}</p>
                                        <p className="grey mb0">{this.state.current_address.penerima}</p>
                                        <p className="grey mb0"><NumberFormat
                                            value={this.state.current_address.phone}
                                            displayType={'text'}
                                            format="#### #### #### #"
                                        /></p>
                                        <p className="grey mb0">
                                            {this.state.current_address.alamat_lengkap} {this.state.current_address.kode_pos}
                                            {this.props.no_br ? <span>&nbsp;</span> : <br />}
                                            {this.state.current_address.kec}, {this.state.current_address.kab}
                                        </p>
                                        <hr />
                                        <p className="grey mb0">Keterangan Lokasi: {this.state.current_address.instruksi_tambahan}</p>
                                    </div>
                                </div>
                                : <div className="text-center">
                                    <p>Belum ada alamat pengiriman</p>
                                    <a href="/#" onClick={this.onClickTambahAlamat}><i className="fas fa-plus"></i> Tambah Alamat</a>
                                 </div>
                            }
                            </div>

                            {/* <div className="form-subtitle">
                                <h2>Metode Pembayaran</h2>
                            </div>
                            <div className="mb30">
                                <label className="form-check"><input type="radio" name="opt1" defaultChecked="1" className="mr20" /> Transfer Bank</label>
                                {
                                    this.state.banks.map(bank => {
                                        let active = this.state.activeBank === bank.id ? 'active' : '';
                                        return (
                                        <div className={`box-option ${active}`} key={bank.id} onClick={() => this.setState({activeBank : bank.id})}>
                                            <img src={bank.image} alt="bank"/>
                                        </div>
                                        )
                                    })
                                }
                            </div> */}

                            <div className="form-subtitle">
                                <h2>Daftar Belanja</h2>
                            </div>
                            <div className="mb30">
                                <div className="pull-right">
                                <a href="/#" className="btn btn-outline primary mr5" data-toggle="modal" data-target="#modalHubungiPenjual"><i className="far fa-envelope"></i> Hubungi Penjual</a>
                                    <Link to={`/${this.state.domain}`} className="btn btn-outline primary"><i className="fas fa-store"></i> Kunjungi Toko</Link>
                                </div>
                                <img src={this.state.image} className="h30 mr15" alt="transaksi"/>
                                {this.state.name}
                                <hr />
                                {
                                    this.state.products.map( product => 
                                    <ListProduct 
                                        key={product.id} 
                                        allowEditQty={false}
                                        addQty={() => this.incrementQty(product)}
                                        minQty={() => this.decrementQty(product)}
                                        onchange={(e) => this.onchange(e, product)}
                                        disableDec={product.quantity <= product.min_order}
                                        onBlur={() => this.onBlur(product)}
                                        onRemoveDetail={(e) => this.onRemoveDetail(e, product)}
                                        fadeOut={product.fadeOut}
                                        removeButton={true}
                                        jumlah={product.quantity}
                                        {...product} 
                                        /> 
                                    )
                                }
                                
                                {
                                    this.notifBarang(this.state.products)
                                    ? <div className="notification-box">
                                        <div className="danger">
                                            <i className="fas fa-exclamation-circle"></i>
                                            <p>Barang yang tidak didukung dengan jasa pengiriman <b>{this.state.shipment_temp ? this.state.shipment_temp.name.split('-')[0] : 'test'}</b> tidak masuk dalam pesanan ini.</p>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                            <div className="form-subtitle">
                                <h2>Catatan Untuk Penjual</h2>
                            </div>
                            <div className="mb30">
                                <textarea 
                                    className="form-control" 
                                    placeholder="Tulis catatan untuk penjual"
                                    onChange={this.handleChange}
                                    value={this.state.catatan}
                                    ></textarea>
                            </div>
                            <div className="form-subtitle">
                                <h2>Metode Pengiriman</h2>
                            </div>
                            <div className="mb30">
                                {
                                    this.state.shipment
                                    ? <table cellPadding="10" width="100%" className="mb20">
                                        <tbody>
                                            <tr>
                                                <td width="200">
                                                    <div className="w75 pull-left">
                                                        <img src={this.state.shipment.image} alt="shipment"/>
                                                    </div>
                                                    <span>{this.state.shipment.name}</span>
                                                </td>
                                                {
                                                    this.state.shipment.third_party
                                                    ? <React.Fragment>
                                                        <td valign="top">{this.state.shipment.cost.etd} hari</td>
                                                        <td align="right" valign="top">Rp <FormatUang value={this.state.shipment.cost.value} /></td>
                                                    </React.Fragment>
                                                    : <td align="right" colSpan="2" valign="top" className="text-info">ditentukan penjual</td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    : null
                                }
                                {/* {
                                    this.state.shipments.map(shipment => (
                                        <div key={shipment.id}>
                                            <label className="form-check">
                                                <input 
                                                    type="radio" 
                                                    name="opt2" 
                                                    value={this.state.shipment_id} 
                                                    className="pull-left mr20" 
                                                    checked={parseInt(this.state.shipment_id) === shipment.id}
                                                    onChange={() => this.changeShipment(shipment)}/>
                                                <div className="w75 pull-left">
                                                    <img src={shipment.image} alt="transaksi"/>
                                                </div>
                                                <span>{shipment.name}</span>
                                            </label><br />
                                        </div>
                                    ))
                                } */}
                                <a 
                                    href="/#" 
                                    className="btn btn-outline primary" 
                                    data-toggle="modal"
                                    onClick={this.loadShipment}
                                    >
                                        Pilih Jasa Pengiriman
                                </a>
                            </div>
                            <div className="footer-transaction-list">
                                <div className="total-transaction">
                                    <span>TOTAL</span>
                                    <span className="pull-right fs19">
                                        Rp <FormatUang value={this.state.total + (this.state.shipment ? this.state.shipment.cost.value : 0)} />
                                    </span>
                                </div>
                                { 
                                    this.state.loadingButton
                                    ? this.loading()
                                    : <a href="/#" className={`btn btn-block btn-solid primary ${this.state.loadingButton ? "disabled" : ''}`} onClick={this.createTransaction}>Lanjutkan Pesanan</a>
                                }
                            </div>
                        </form>
                    </div>
                <HubungiPenjual
                    seller={this.state.seller}
                />
                </div>
        );        
        return (
            <div className="mg-top">
                {
                    this.state.loadingButton
                    ? <LoadingLock/>
                    : null
                }
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <TransactionCreateLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <TambahAlamat
                    address={this.props.address}
                    current_address={this.state.current_address_edit}
                    is_edit_address={this.state.is_edit_address}
                />
                <div className="modal fade modal-2" id="modalPilihAlamat" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>PILIH ALAMAT</h2>
                                <a href="/#" className="btn btn-outline primary" id="add_address" onClick={this.onClickTambahAlamat}><i className="fas fa-plus"></i>Tambah Alamat</a>
                                <div className="scroll-y mt5" id="form_choose_address">
                                    {
                                        this.props.address.map((data, i) => {
                                            let active = this.state.alamat_selected === data.id ? 'active' : '';
                                            return (
                                                // <div className={`box-outline-option ${active}`}  key={i} data-id={data.id} onClick={() => this.setState({alamat_selected: data.id})}>
                                                <div className={`box-outline-option ${active}`}  key={i} data-id={i} onClick={(e) => this.onChangePilihAlamat(e, data.id)}>
                                                <div className="pull-right">
                                                    <a href="/#" className="edit_address" data-id={i} onClick={this.onClickEditAlamat}><i className="fas fa-pencil-alt mr5"></i></a>&nbsp;
                                                    <a href="/#" onClick={(e) => {e.preventDefault();this.onClickHapusAlamat(e, data.id)}}><i className="red fas fa-trash-alt"></i></a>
                                                </div>
                                                <p className="bold mb0">{data.label}</p>
                                                <p className="grey mb0">{data.penerima}</p>
                                                <p className="grey mb0"><NumberFormat
                                                    value={data.phone}
                                                    displayType={'text'}
                                                /></p>
                                                <p className="grey mb0">{data.alamat_lengkap} {data.kode_pos}
                                                    <br />
                                                    {data.kec}, {data.kab}</p>
                                                <hr />
                                                <p className="grey mb0">Keterangan Lokasi: {data.instruksi_tambahan}</p>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onClickSimpanAlamat}>Simpan</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalPilihJasaPengiriman" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">

                        {this.state.loadingShipment ?
                            <div className="modal-content">
                                <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ReactLoading type="spinningBubbles" color="#0C7F8F" />
                                </div>
                            </div>
                            :
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>PILIH JASA PENGIRIMAN</h2>

                                    <table cellPadding="8" width="100%">
                                        <tbody>
                                            {this.state.shipments.map((shipment, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <label className="form-check">
                                                            <input type="radio" name="opt2" className="pull-left mr20" checked={this.state.shipment_id === i} onChange={() => this.changeShipmentV2(shipment, i)} disabled={shipment.disabled ? "disabled" : ""}/>
                                                            <div className="w75 pull-left">
                                                                <img src={shipment.image} alt="shipment" />
                                                            </div>
                                                            <span>{shipment.name}</span>
                                                        </label>
                                                        {/* {
                                                                shipment.disabled ? <><br /><span className="text-warning">Berat melebihi batas maksimum 30 kg.</span></> : null
                                                        } */}
                                                        {
                                                            shipment.disabled ? 
                                                                <div className="notification-box sm" style={{ maxWidth: "374px" }}>
                                                                    <div className="warning">
                                                                        <i className="fas fa-info-circle"></i>
                                                                        <p>Berat melebihi batas maksimum 30 kg.</p>
                                                                    </div>
                                                                </div>  
                                                            : null
                                                        }
                                                    </td>
                                                    {shipment.third_party ?
                                                        <React.Fragment>
                                                            <td valign="top">{shipment.cost.etd} hari</td>
                                                            {this.state.loadingValueShipment ?
                                                                <td valign="top">
                                                                    <ReactLoading type="spinningBubbles" color="#0C7F8F" width={20} height={20} />
                                                                </td>
                                                                :
                                                                <td align="right" valign="top">Rp
                                    <FormatUang value={shipment.cost.value} />
                                                                </td>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <td align="right" colSpan="2" valign="top" className="text-info">ditentukan penjual</td>
                                                    }
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    {this.notifBarang(this.state.products) ?
                                        <div className="notification-box">
                                            <div className="danger">
                                                <i className="fas fa-exclamation-circle"></i>
                                                <p>Ada barang yang tidak didukung oleh jasa pengriman ini</p>
                                            </div>
                                        </div>
                                        : null}

                                </div>
                                <div className="modal-footer">
                                    <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                    <a href="/#" className={`btn btn-solid primary btn-sm ${this.state.loadingValueShipment ? 'disabled' : ''}`} onClick={this.selectShipment}>Simpan</a>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let userData = state.user.user;

    return {
        products : transactionItems(state),
        address : userData.current_address,
        url_back : state.transaction.goBack,
        userData,
        cartItems : state.carts.cartItems,
        
    }
}


export default NetworkDetector(connect(mapStateToProps, {
    postShopShipment,
    changeQuantity,
    clearDataTransaction,
    addToCartV2,
    setShippingAddress
})(TransactionBuyerCreate));