import React, { Component } from 'react'
import {connect} from 'react-redux'
import { withNamespaces } from 'react-i18next';
import ModalImage from "react-modal-image";
import { fetchProductRating } from '../../../actions/productActions'
//import { URL } from '../../../config'
import i18n from '../../../i18n';
class SingleProductReview extends Component {

    componentDidMount(){
        this.props.fetchProductRating({product_id : this.props.dataProduct.id});
    }

    renderStar(starred){

        let arr_star = []; 
        for(let i=0; i < 5; i++){
            if(i < starred){
                arr_star.push(<i className="fas fa-star starred" key={i}></i>)
            }else{
                arr_star.push(<i className="fas fa-star" key={i}></i>)
            }
        }

        return arr_star;
    }

    filterReview(e, rating, image){
        e.preventDefault();
        this.props.fetchProductRating({
            product_id : this.props.dataProduct.id,
            rating,
            image
        });
    }

  render() {
    let { t,result } = this.props;
    return (
      <div className="tab-div">
        <div className="rating-product">
            <div className="rating-avg">
                <p>{result.avg}</p>
                <div className="rating-big">
                    {this.renderStar(result.avg)}
                </div>
            </div>
            <div className="rating-detail">
                <table cellPadding="5">
                    <tbody>
                        <tr>
                            <td><span>5</span></td>
                            <td><i className="fas fa-star starred"></i></td>
                            <td><div className="rating-progress-container"><div className="rating-progress" style={{width: `${result.b5.persentase}%`}}></div></div></td>
                            <td><span className="grey">{result.b5.total}</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><i className="fas fa-star starred"></i></td>
                            <td><div className="rating-progress-container"><div className="rating-progress" style={{width: `${result.b4.persentase}%`}}></div></div></td>
                            <td><span className="grey">{result.b4.total}</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><i className="fas fa-star starred"></i></td>
                            <td><div className="rating-progress-container"><div className="rating-progress" style={{width: `${result.b3.persentase}%`}}></div></div></td>
                            <td><span className="grey">{result.b3.total}</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><i className="fas fa-star starred"></i></td>
                            <td><div className="rating-progress-container"><div className="rating-progress" style={{width: `${result.b2.persentase}%`}}></div></div></td>
                            <td><span className="grey">{result.b2.total}</span></td>
                        </tr>
                        <tr>
                            <td><span>1</span></td>
                            <td><i className="fas fa-star starred"></i></td>
                            <td><div className="rating-progress-container"><div className="rating-progress" style={{width: `${result.b1.persentase}%`}}></div></div></td>
                            <td><span className="grey">{result.b1.total}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="clearfix"></div>
            </div>

            <div className="rating-filter">
            <label>{t("SingleProductReview:filter")}</label>
            <a href="/#" className="btn btn-rating-filter active" onClick={(e) => this.filterReview(e, 0, 0)}>{t("SingleProductReview:semua")}</a>
            <a href="/#" className="btn btn-rating-filter" onClick={(e) => this.filterReview(e, 0, 1)}>{t("SingleProductReview:dengan_foto")}</a>
            <a href="/#" className="btn btn-rating-filter" onClick={(e) => this.filterReview(e, 1, 0)}><i className="fas fa-star starred"></i> 1</a>
            <a href="/#" className="btn btn-rating-filter" onClick={(e) => this.filterReview(e, 2, 0)}><i className="fas fa-star starred"></i> 2</a>
            <a href="/#" className="btn btn-rating-filter" onClick={(e) => this.filterReview(e, 3, 0)}><i className="fas fa-star starred"></i> 3</a>
            <a href="/#" className="btn btn-rating-filter" onClick={(e) => this.filterReview(e, 4, 0)}><i className="fas fa-star starred"></i> 4</a>
            <a href="/#" className="btn btn-rating-filter" onClick={(e) => this.filterReview(e, 5, 0)}><i className="fas fa-star starred"></i> 5</a>
            </div>
            {
                result.rating.data.map(data => 
                    <RatingReview 
                        key={data.id}
                        renderStar={this.renderStar(data.rating)}
                        {...data}/>
                )
            }
            
    </div>
    )
  }
}

const RatingReview = props =>{
    return (
    <div className="review-container">
        <div className="review-list">
            <div className="rating">
                {props.renderStar}
            </div>
            <p className="review-from">{i18n.t("SingleProductReview:RatingReview.oleh")} <span>{props.user_name}</span>&nbsp;&nbsp;.&nbsp;&nbsp;{props.tanggal}</p>
            <p className="review-text">{props.review}</p>
            <div className="review-image">
            {
                props.rating_img.map(item =>(
                    <div key={item.id} className="review-img">
                        <ModalImage
                            className=""
                            small={item.image_thumb}
                            medium={item.image_url}
                            large={item.image_url}
                            alt="Foto Ulasan"
                            showRotate={true}
                            hideDownload={true}
                        />                    
                    </div>
                        ))
            }
            
            </div>
            {
                props.rating_reply ? 
                <div className="review-reply">
                    <p className="review-from">{i18n.t("SingleProductReview:RatingReview.oleh")} <span>{props.seller_name}</span> <span className="label label-seller">{i18n.t("SingleProductReview:RatingReview.penjual")}</span> &nbsp;&nbsp;.&nbsp;&nbsp;{props.rating_reply.created_at}<br /></p>
                    <p className="review-text">{props.rating_reply.review}</p>
                </div>
                : null
            }
            {/* <div className="review-like">Apakah ulasan ini membantu? <a href="/#"><i className="fas fa-thumbs-up"></i></a> 25</div> */}
        </div>
    </div>
)
}


const mapStateToProps = state => {
    return {
        result : state.products.product_rating
    }
}

export default withNamespaces("SingleProductReview")(connect(mapStateToProps, { fetchProductRating })(SingleProductReview))
