import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ImageUploader from 'react-images-upload';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { connect } from 'react-redux';
import _ from 'lodash';

import { 
    // SuccessNotif,
    NewHeader
} from "../../../../components";

// import Header from '../../../../components/Transaction/Header'
import StepTransaction from '../../../../components/Transaction/StepTransaction'
import ListProductTransaction from '../../../../components/Transaction/ListProductTransaction'
import Notification from '../../../../components/Global/Notification'

import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import {
    Images
} from '../../../../components/Transaction/Images';
import {
    toggleModalLogin
} from '../../../../actions/registerActions';
import {
    getDetailInvoice,
    updateInvoicePayment,
    updateInvoiceReceived,
    setUlasan,
    cancelInvoice,
    uploadFotoUlasan
} from '../../../../actions/transactionAction';
import {
    setNotification
} from '../../../../actions/notificationAction';
import {
    FormatUang,
    AlertError,
    AlertWarning,
    ConfirmInfo,
    ConfirmWarning,
    Toast,
    authGet,
    AlertLoading
} from '../../../../helper'

import ReactLoading from 'react-loading';
import { privateChannel } from '../../../../socket';
import HubungiPenjual from '../../../../components/Global/HubungiPenjual';  
import { 
    authPost,
    authPostFile
} from './../../../../helper';
import {LodingThumnailDetailProduct} from '../../../../components/Loading/Loading';
import ModalImage from "react-modal-image";
import * as moment from "moment";
// import axios from 'axios';
import 'moment/locale/id';
import TransactionDetailLoading from '../../../../components/Loading/TransactionDetailLoading';
import Swal from 'sweetalert2';
import NetworkDetector from '../../../PUBLIC/NetworkDetector';

moment.locale('id');
const Loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

const CardPembayaran = props => {
    
    return(
    // <div className="trans-payment-info" style={{display: Object.keys(props.metode_pembayaran).length === 0 || props.status_id > 2 ? 'none' : ''}}>
    <div className="trans-payment-info" style={{display: props.metode_pembayaran && props.status_id === 2 ? '' : 'none'}}>
        {
            props.status_id === 2
            ? <p className="text-center">Segera lakukan pembayaran sebelum {moment(props.expired).format("DD MMMM YYYY HH:mm")}</p>
            : null
        }
        {
        <div className="p30 bg-grey">
            <div className="text-center">
                <span className="semibold fs20 block mb30">Informasi Pembayaran</span>
                <span className="block mb20">Total Tagihan</span>
                <span className="semibold fs30">
                    Rp <FormatUang value={props.grand_total}/></span>
            </div>
            {
                props.metode_pembayaran.kategori_pembayaran_id === 1
                ? <div className="notification-box sm mt20">
                    <div className="info">
                        <i className="fas fa-info-circle"></i>
                        <p>Kode unik digunakan untuk mempermudah proses verifikasi pembayaran dan akan ditambahkan ke dompet anda</p>
                    </div>
                </div>
                : null
            }
            
            <hr />
            <table width="100%" cellPadding="3">
                <tbody>
                    <tr>
                        <td>Total Belanja</td>
                        <td width="30%">Rp <span className="pull-right">
                            <FormatUang value={props.total - props.ppn} />
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td>PPN</td>
                        <td width="30%">Rp <span className="pull-right">
                            <FormatUang value={props.ppn} />
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Biaya Pengiriman</td>
                        <td>Rp <span className="pull-right">
                            <FormatUang value={props.biaya_pengiriman} />
                        </span></td>
                    </tr>
                    {
                        props.metode_pembayaran.kategori_pembayaran_id === 1
                        ? <tr>
                            <td>Kode Unik</td>
                            <td>Rp <span className="pull-right">{parseInt(props.kode_unik)}</span></td>
                        </tr>
                        : null
                    }
                    
                    <tr>
                        <td className="fs15 semibold">Total Tagihan</td>
                        <td className="fs15 semibold">Rp <span className="pull-right">
                            <FormatUang value={props.grand_total} />
                        </span></td>
                    </tr>
                </tbody>
            </table>
            {
                props.metode_pembayaran.info
                ? <>
                    <hr />
                    <table width="100%" cellPadding="3">
                        <tbody>
                            <tr>
                                <td width="30%">Bank</td>
                                <td align="right">
                                    <img src={props.metode_pembayaran.image} alt="transaksi"/>
                                </td>
                            </tr>
                            <tr>
                                <td>Nomor Rekening</td>
                                <td align="right">
                                    <NumberFormat 
                                        value={props.metode_pembayaran.info.nomor_rekening}
                                        displayType={'text'} 
                                        format="#### #### #### # ####" />
                                    
                                </td>
                            </tr>
                            <tr>
                                <td>Nama Rekening</td>
                                <td align="right">{props.metode_pembayaran.info.nama_rekening}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
                : null
            }
            
        </div>
        }
        {
            props.status_id === 2
            ? props.loadingButton
                ? <Loading/>
                : <button className="btn btn-block btn-solid primary p10" onClick={props.confirmPayment}>Konfirmasi Pembayaran</button>
            : null
        }
    </div>
)}
/*
const InfoPembayaran = props => 
(
    <div>
        <div className="form-subtitle">
            <h2>Pembayaran</h2>
        </div>
        <div className="mb30">
            <div className="trans-payment-info">
                <p className="text-center">Pembayaran telah diterima pada {props.dibayar_at}</p>
                <div className="text-center">
                    <span className="semibold fs30 block mb20">Rp <FormatUang value={props.total_trim}/>.<span className={`${parseInt(props.kode_unik) > 0 ? "primary" : "" }`}>{props.kode_unik}</span></span>
                    <span className="grey block mb10">Metode Pembayaran</span>
                    <span className="block">Transfer Bank <img src={props.bank.image} alt="transaksi"/></span>
                </div>
            </div>
        </div>
    </div>
)
*/
const Complain = props => {
    let complain = props.complain;
    let latest_status = complain.status.slice(-1)[0];
    let can_batal = [1,2,6,7,12,13,16,19];
    let can_ubah = [1,6,12,16,19];
    
    return (
        <div>
            <div className="form-subtitle">
				<h2>Komplain & Retur</h2>
			</div>

			<div className="mb30">
				<table width="100%" className="mb10">
					<tbody>
						<tr>
							<td valign="top" width="200"><label className="darkgrey">Status Komplain</label></td>
							<td valign="top">
                            <ul className="status-step">
                            {complain.status.map((data, i) => (
                                <li key={i}>
                                    <div className={data.id === 22 || data.id === 23 ? "round-icon red" : "round-icon"}><i className={data.id === 22 || data.id === 23 ? "fas fa-times" : "fas fa-check"}></i></div>
                                    <div className="status-text">{data.title}</div>
                                    <div className="status-date">{moment(data.created_at).format("DD MMM YYYY HH:mm")} {data.shipment ? data.courier_answer ? <a href="/#" data-toggle="modal" data-target="#modalInfoKurir">Apa Kata Kurir?</a> :
                                        <a href="/#" data-toggle="modal" data-target="#modalInfoPengembalian" data-id={i} onClick={props.OnClickComplainShipment}>Info {data.shipment.link_title}</a> : ''}
                                    </div>
                                </li>
                            ))}
                            </ul>
							</td>
							<td valign="top" align="right">
								{/* <a href="/#" data-toggle="modal" data-target="#modalHubungiPenjual" className="btn btn-outline primary"><i className="far fa-envelope"></i> Hubungi Penjual</a> */}
							</td>
						</tr>
					</tbody>
				</table>
				<div className="notification-box sm">
					<div className="info">
                    <i className="fas fa-info-circle"></i>
                    <p>{latest_status.text_buyer}</p>
					</div>
				</div>
				{
                    latest_status.id === 2 || latest_status.id === 7 ? 
                    <div>
                        <a href="/#" className="btn btn-block btn-solid primary p10 mb10" data-toggle="modal" data-target="#modalPengembalianBarang">Barang Telah Dikembalikan</a>
                        <hr />
                        <table width="100%" className="mb10" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td valign="top" width="200"><label className="darkgrey">Alamat Pengembalian</label></td>
                                    <td valign="top"><p className="mb0">{props.shop.name}<br /><span className="grey">{props.shop.telephone}</span><br /><span className="grey">{props.shop.addresses.data[0].address}</span><br /><span className="grey">{props.shop.addresses.data[0].district}, {props.shop.addresses.data[0].city} {props.shop.addresses.data[0].zip_portal}</span></p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    :
                    ''
                }
				{
                    latest_status.id === 23 ?
                    <div>
                        <hr />
                        <table width="100%" className="mb10" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td valign="top" width="200"><label className="darkgrey">Alasan Penjual</label></td>
                                        <td valign="top"><p className="mb0">{complain.ditolak_reason}</p></td>
                                </tr>
                                <tr>
                                    <td valign="top" width="200"><label className="darkgrey">Catatan dari Agrinis</label></td>
                                    <td valign="top"><p className="mb0">{complain.ditolak_reason_agrinis}</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    :
                    ''
                }
				<hr />
				{
                    can_ubah.indexOf(latest_status.id) === -1 ? '' : 
				    <a href="/#" className="pull-right" onClick={props.onClickUbahComplain}><i className="fas fa-pencil-alt"></i> Ubah</a>
                }
				<table width="100%" className="mb10" cellPadding="3">
					<tbody>
						<tr>
							<td valign="top" width="200"><label className="darkgrey">Penerimaan Barang</label></td>
							<td valign="top">
								<span>{complain.status_barang}</span>
							</td>
						</tr>
						<tr>
							<td valign="top"><label className="darkgrey">Permasalahan Barang</label></td>
							<td valign="top">
								<span>{complain.problem}</span>
							</td>
						</tr>
						<tr>
							<td valign="top"><label className="darkgrey">Alasan Komplain</label></td>
							<td valign="top">{complain.reason}</td>
						</tr>
                        {
                            complain.status_barang === 'Belum diterima' ? '' : 
                            <tr><td valign="top"><label className="darkgrey">Foto Bukti Komplain</label></td>
                                <td valign="top">
                                    {complain.images.map((data, i) => (
                                            <React.Fragment key={i}>
                                                {
                                                !data.image_thumb ? 
                                                <div className="complain-img"><img src={data.image} alt="" /></div> :
                                                <ModalImage
                                                    className="complain-imgg"
                                                    small={data.image_thumb}
                                                    medium={data.image}
                                                    large={data.image}
                                                    alt="Foto Bukti Komplain"
                                                    showRotate={true}
                                                    hideDownload={true}
                                                />
                                                }
                                            </React.Fragment>
                                    ))}
                                </td>
                            </tr>
                        }
						<tr>
							<td valign="top"><label className="darkgrey">Solusi yang diinginkan</label></td>
							<td valign="top">
								<span>{complain.solution}</span>
							</td>
						</tr>
					</tbody>
				</table>
                {
                    can_batal.indexOf(latest_status.id) === -1 ? '' : 
                    <div>
                    <hr />
                    {
                        props.loadingButton ? <Loading/> :
                        <a href="/#" className="pull-right btn btn-solid yellow" onClick={props.onClickBatalComplain}>Batalkan Komplain</a>
                    }
                    </div>
                }
                <div className="clearfix"></div>
			</div>
        </div>
    )
}
const ComplainSolution = props => {
    return (
        props.complain_solutions.map((data, i) => (
            data.status_barang === props.complain_form.status_barang ?
                <label className="form-check" key={i}><input type="radio" 
                name="opt3" 
                value={data.id} 
                onChange={props.onChangeComplainSolution} 
                checked={props.complain_form.solution === data.id}
                /> {data.name}</label>
                :
                ''
        ))
    )
}
const InfoPembayaran2 = props => (
    // <div className="trans-payment-info" style={{ display: Object.keys(props.bank).length === 0 ? 'none' : '' }}>
    <div className="trans-payment-info" style={{display: props.metode_pembayaran ? '' : 'none'}}>
        {
            props.status_id === 4
                ? <p className="text-center">Pembayaran telah diterima pada {props.dibayar_at}</p>
                : null
        }
        {
            <div className="p30 bg-grey">
                <div className="text-center">
                    <span className="semibold fs20 block mb30">Informasi Pembayaran</span>
                    <span className="block mb20">Total Tagihan</span>
                    <span className="semibold fs30">
                        Rp <FormatUang value={props.grand_total} /></span>
                </div>
                {
                    props.metode_pembayaran.kategori_pembayaran_id === 1
                    ? <div className="notification-box sm mt20">
                        <div className="info">
                            <p>Kode unik digunakan untuk mempermudah proses verifikasi pembayaran</p>
                            {/* dan akan ditambahkan ke dompet anda */}
                        </div>
                    </div>
                    : null
                }
                
                <hr />
                <table width="100%" cellPadding="3">
                    <tbody>
                        <tr>
                            <td>Total Belanja</td>
                            <td width="30%">Rp <span className="pull-right">
                                <FormatUang value={props.total - props.ppn} />
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Total PPN</td>
                            <td width="30%">Rp <span className="pull-right">
                                <FormatUang value={props.ppn} />
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Biaya Pengiriman</td>
                            <td>Rp <span className="pull-right">
                                <FormatUang value={props.biaya_pengiriman} />
                            </span></td>
                        </tr>
                        {
                            props.metode_pembayaran.kategori_pembayaran_id === 1
                            ? <tr>
                                <td>Kode Unik</td>
                                <td>Rp <span className="pull-right">{parseInt(props.kode_unik)}</span></td>
                            </tr>
                            : null
                        }
                        
                        <tr>
                            <td className="fs15 semibold">Total Tagihan</td>
                            <td className="fs15 semibold">Rp <span className="pull-right">
                                <FormatUang value={props.grand_total} />
                            </span></td>
                        </tr>
                    </tbody>
                </table>
                {
                    props.metode_pembayaran.info
                    ? <>
                    <hr />
                    <table width="100%" cellPadding="3">
                        <tbody>
                            <tr>
                                <td width="30%">Bank</td>
                                <td align="right">
                                    <img src={props.metode_pembayaran.image} alt="transaksi" />
                                </td>
                            </tr>
                            <tr>
                                <td>Nomor Rekening</td>
                                <td align="right">
                                    <NumberFormat
                                        value={props.metode_pembayaran.info.nomor_rekening}
                                        displayType={'text'}
                                        format="#### #### #### # ####" />

                                </td>
                            </tr>
                            <tr>
                                <td>Nama Rekening</td>
                                <td align="right">{props.metode_pembayaran.info.nama_rekening}</td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                    : null
                }
                
            </div>
        }
    </div>
)

class TransactionBuyerDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            total : 0,
            detail : [],
            alamat : {
                phone: ''
            },
            shop_alamat: {},
            shipment : {},
            shop : {},
            prosess : [],
            modalRating : {
                1 : false,
                2 : false,
                3 : false,
                4 : false,
                5 : false
            },
            lockRating : false,
            ulasanText : '',
            fileUlasan : [],
            loadingButton : false,
            reference: {
                reference_id: '',
                reference_type: ''
            },
            activeBank: null,
            bank: {},
            banks: [],
            catatan: null,
            showCardPembayaran: false,
            userData: this.props.userData,
            barangSelected: {
                harga: 0
            },
            ulasanSelected_id: 0,
            ulasanSelected: {
                image: []
            },
            barangSelectedIndex: 0,
            biaya_pengiriman: 0,
            kode_unik: 0,
            grand_total: 0,
            complain: null,
            complain_problems: [],
            complain_solutions: [],
            complain_form: {
                status_barang: 1,
                problem: 1,
                reason: '',
                fileComplain: [],
                solution: 0,
                agreement: false,
            },
            complain_shipment: '',
            complain_resi: '',
            complain_shipment_selected: {},
            fileObj : [],
            fileArray : [],
            uploadUlasanFiles: [],
            uploadUlasanImages: [],
            metode_pembayarans: [],
            metode_pembayaran: null,
            metode_pembayaran_loading: false,
            metode_pembayaran_id: null,
            metode_pembayaran_selected: null,
            show_limit: true,
            show_limit_loading: false,
            loading_pdf: false,
            ppn: 0
        }
    }

    componentWillMount(){
        privateChannel(this.props.userData)
        .notification(data => {
            if(data.type === "pembelian" || data.type === "complain"){
                this.getDataInvoice();
            }
        })
    }


    componentDidMount(){        
        this.getDataInvoice();
    }

    getDataInvoice(){
        getDetailInvoice(this.props.match.params.id, this.props.userData.token)
        .then(res => { 
            if(res.data.data.alamat){
                this.setState({
                    ...res.data.data, 
                    detail: res.data.data.detail.length > 3 ? _.slice(res.data.data.detail, 0, 3) : res.data.data.detail,
                    alamat : JSON.parse(res.data.data.alamat), 
                    shop_alamat: res.data.data.shop.addresses.data[0],
                    activeBank: res.data.data.bank.id,
                    show_limit: res.data.data.detail.length > 3 ? true : false,
                    loadingPage: false,
                });
            }
            else{
                this.setState({
                    loadingPage: false
                }, () => {
                    AlertError.fire({text: "Transaksi tidak ditemukan"})
                    .then(() => {
                        this.props.history.push("/transaction");
                    })
                })
            }
        })
        .catch(err => {
            // alert(err.response)
            if(err.response){
                this.setState({
                    loadingPage: false
                },() => {
                    AlertError.fire({text : err.response.data.message})
                    .then(() => {
                        this.props.history.push("/transaction");
                    })
                })
            }
        })
    }

    confirmPayment = (e) => {
        e.preventDefault();

        ConfirmInfo.fire({
            text : "Apakah anda yakin akan melanjutkan transaksi?"
        }).then(result => {
            if(result.value){

                let data = {
                    invoice_id : this.state.id,
                }

                if(this.state.metode_pembayaran.pin) {
                    Swal.fire({
                        title: "Masukkan Password",
                        text: 'Masukkan password untuk dapat melanjutkan transaksi.',
                        input: "password"
                    }).then(result2 => {
                        if(result2.value){
                            authPost({password : result2.value}, `user/valid`, this.props.userData.token)
                            .then(response => {
                                if(response.data.valid) {
                                    this.checkout(data);
                                }
                            })
                            .catch(err => {
                                AlertWarning.fire({text: err.response.data.message});
                            })
                        }
                    })
                } else {
                    this.checkout(data);
                }
            
            }
        })
    }

    checkout (data) {
        this.setState({loadingButton : true});

        updateInvoicePayment(data, this.props.userData.token)
        .then(res => {
            // window.location.href = `/transaction/detail/${res.data.id}`;
            this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat), loadingButton : false}
            , () => {
                Toast.fire({
                    type : "success",
                    title : "Data berhasil disimpan!"
                })
            });
        })
        .catch(err => {
            // alert(err.response.data)
            this.setState({loadingButton : false});
            if(err.response){
                AlertError.fire({text : err.response.data.message});
            }
        })
    }

    renderStatus = (status) => {
        // switch (status) {
        //     case 2 : 
        //     case 3 :
        //         return <CardPembayaran 
        //             {...this.state}
        //             confirmPayment={this.confirmPayment}
        //             />
        //     case 4 :
        //     case 5 :
        //     case 6 :
        //         // return <InfoPembayaran {...this.state}/>
        //     case 9 : 
        //         return <CardPembayaran
        //             {...this.state}
        //             confirmPayment={this.confirmPayment}
        //         />
        //     default:
        //         return null;
        // }
    }

    handleRating = (param) => {
        let data = {};

        for(let i=1;i <= 5; i++){
            data = {[i] : i <= param ? true : false , ...data}
        }

        if(!this.state.lockRating){
            this.setState({
                modalRating : data 
            })
        }
    }

    setRating(param){
        let data = {};

        for(let i=1;i <= 5; i++){
            data = {[i] : i <= param ? true : false , ...data}
        }

        this.setState({
            modalRating : data,
            lockRating : true
        })
    }

    resetRating = () => {
        if(!this.state.lockRating){
            this.setState({
                modalRating : {
                    1 : false,
                    2 : false,
                    3 : false,
                    4 : false,
                    5 : false
                }
            })
        }
    }

    ulasNanti = (e) => {
        e.preventDefault();
        this.setState({loadingButton: true})
        updateInvoiceReceived({invoice_id : this.state.id}, this.props.userData.token)
        .then(res => {
            // window.location.href = `/transaction/detail/${res.data.id}`;
            this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat), loadingButton : false}
            ,() => {
                window.$("#modalPesananDiterima").modal("hide");
                Toast.fire({
                    type : "success",
                    title : "Data berhasil disimpan!"
                })
            });
        })
        .catch(err => {
            this.setState({loadingButton: false})
            AlertError.fire({text : err.response.data.message});
        })
    }

    batalkanPesanan = (e) => {
        e.preventDefault();
        ConfirmWarning.fire({
            text : "Apakah anda yakin ingin membatalkan Pesanan ini?"
        }).then(result => {
            if(result.value){
                AlertLoading.fire();
                cancelInvoice({invoice_id : this.state.id}, this.props.userData.token)
                .then(res => {
                    this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat)}
                    , () => {
                        Toast.fire({
                            type : "success",
                            title : "Data berhasil disimpan!"
                        })
                    });
                })
                .catch(err => {
                    AlertError.fire({text : err.response.data.message});
                })
            }
        })
    }

    setUlasan = (e) => {
        e.preventDefault();
        if(!this.state.lockRating){
            AlertWarning.fire({text : "Rating Belum Terisi"});
        }
        else if(this.state.ulasanText === ''){
            AlertWarning.fire({text : "Text Harus Diisi"});
        }
        else if (_.size(this.state.fileUlasan) > 3) {
            AlertWarning.fire({ text: "Foto yang diunggah maksimal 3 foto" });
        }
        else{
            let data = {
                rating : _.size(_.pickBy(this.state.modalRating, val => val === true)),
                text : this.state.ulasanText,
                invoice_detail_id: this.state.barangSelected.id,
                invoice_id: this.state.id
            }
            this.setState({loadingButton : true})
            setUlasan(data, this.props.userData.token)
            .then(res => {
                
                this.setState({
                    ...res.data.data, 
                    alamat : JSON.parse(res.data.data.alamat), 
                    // loadingButton: false,
                    detail: res.data.data.detail,                
                },() => {
                            let photo = this.state.uploadUlasanFiles;
                            let fd = new FormData();
                            if(_.size(photo) > 0){
                                _.forEach(photo, (item, key) => {
                                    fd.append(`photo[${key}]`, item)
                                })
                                fd.append('id', res.data.data.detail[this.state.barangSelectedIndex].ulasan.id)
                                fd.append('invoice_id', this.state.id)
                                uploadFotoUlasan(fd, this.props.userData.token)
                                    .then(resx => {
                                        this.setState({
                                            ...resx.data.data,
                                            alamat: JSON.parse(resx.data.data.alamat), 
                                            detail: resx.data.data.detail,
                                            barangSelectedIndex: 0,
                                            barangSelected: {},
                                            uploadUlasanFiles: [],
                                            uploadUlasanImages: [],
                                            ulasanText: '',
                                            modalRating: {
                                                1: false,
                                                2: false,
                                                3: false,
                                                4: false,
                                                5: false
                                            },
                                            lockRating: false,
                                        }, () => {
                                            Toast.fire({
                                                type : "success",
                                                title : "Data Berhasil disimpan!"
                                            })
                                                window.$('#modalBeriUlasan').modal('hide');
                                                window.$('#modalUbahUlasan').modal('hide');
                                        });
                                    }).catch(err => {
                                            this.setState({
                                                barangSelectedIndex: 0,
                                                barangSelected: {},
                                                uploadUlasanFiles: [],
                                                uploadUlasanImages: [],
                                                ulasanText: '',
                                                modalRating: {
                                                    1: false,
                                                    2: false,
                                                    3: false,
                                                    4: false,
                                                    5: false
                                                },
                                                lockRating: false,
                                                loadingButton: false
                                            }, () => {
                                                AlertError.fire({ text: err.response.data.message })
                                                .then(result => {
                                                    window.$('#modalBeriUlasan').modal('hide');
                                                    window.$('#modalUbahUlasan').modal('hide');
                                                })
                                            });
                                        })
                            }else{
                                window.$('#modalBeriUlasan').modal('hide');
                                window.$('#modalUbahUlasan').modal('hide');
                            }
                });
            })
            .then(() => {
                this.setState({loadingButton: false});
            })
            .catch(err => {
                this.setState({
                    barangSelectedIndex: 0,
                    barangSelected: {},
                    uploadUlasanFiles: [],
                    uploadUlasanImages: [],
                    ulasanText: '',
                    modalRating: {
                        1: false,
                        2: false,
                        3: false,
                        4: false,
                        5: false
                    },
                    lockRating: false,
                    loadingButton: false
                }, () => {
                    AlertError.fire({ text: err.response.data.message })
                    .then(result => {
                        window.$('#modalBeriUlasan').modal('hide');
                        window.$('#modalUbahUlasan').modal('hide');
                    });
                });
            })
        }
    }

    handleSelectedFile = (event) => {
        this.setState({fileUlasan : event.target.files});
    }

    onPilihPembayaranClick = (e) => {
        e.preventDefault();
        let { token } = this.state.userData;
        let data = {
            bank_id: this.state.activeBank,
            invoice_id: this.state.id,
            metode_pembayaran: this.state.metode_pembayaran_selected
        }
        this.setState({loadingButton: true})
        authPost(data, 'invoice/update_metode_pembayaran', token).then(res => {

            this.setState({
                ...res.data.data,
                loadingButton: false,
                alamat: JSON.parse(res.data.data.alamat), 
            });
            window.$('#modalPilihPembayaran').modal('hide');
            // window.location.reload()
            
        })
    }

    renderStar(starred) {

        let arr_star = [];
        for (let i = 0; i < 5; i++) {
            if (i < starred) {
                arr_star.push(<i className="fas fa-star starred" key={i}></i>)
            } else {
                arr_star.push(<i className="fas fa-star" key={i}></i>)
            }
        }

        return arr_star;
    }
    
    numberFormat = (value) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).split(',')[0];
    }

    onClickKonfirmasiPesanan = (e) => {
        e.preventDefault();
        ConfirmWarning.fire({
            text: `Apakah pesanan sudah diterima? Pembayaran ${this.numberFormat(this.state.total+this.state.biaya_pengiriman)} akan diteruskan ke penjual.`
        }).then(result => {
            if (result.value) {
                this.setState({ loadingButton: true })
                updateInvoiceReceived({ invoice_id: this.state.id }, this.props.userData.token)
                    .then(res => {
                        this.setState(
                            { ...res.data.data, 
                                alamat: JSON.parse(res.data.data.alamat), 
                                loadingButton: false ,
                            }
                            , () => {
                                Toast.fire({
                                    type: "success",
                                    title: "Data berhasil disimpan!"
                                })
                            });
                    })
                    .catch(err => {
                        this.setState({ loadingButton: false })
                        AlertError.fire({ text: err.response.data.message });
                    })
            }
        })
    }

    onClickBeriUlasan = (e) => {
        e.preventDefault();
        this.setState({
            barangSelected: this.state.detail[e.currentTarget.dataset.id],
            barangSelectedIndex: e.currentTarget.dataset.id,
        }, () => {
            // console.log(this.state.barangSelected);
            
            window.$('#modalBeriUlasan').modal('show');
        });
    }

    onClickUbahUlasan = (e) => {
        e.preventDefault();
        this.setState({
            ulasanSelected_id: e.currentTarget.dataset.id,
            barangSelected: this.state.detail[e.currentTarget.dataset.id],
            barangSelectedIndex: e.currentTarget.dataset.id,
            ulasanSelected: this.state.detail[e.currentTarget.dataset.id].ulasan,
            ulasanText: this.state.detail[e.currentTarget.dataset.id].ulasan.review,
        }, () => {
            this.setRating(this.state.ulasanSelected.rating);
            window.$('#modalUbahUlasan').modal('show');
        });
    }
    onClickPerpanjangGaransi = (e) => 
    {
        e.preventDefault();
        ConfirmInfo.fire({
            text: 'Apakah anda yakin ingin perpanjang garansi?'
        }).then(result => {
            if (result.value) {
                let data = {
                    invoice_id: this.state.id
                }
                this.setState({ loadingButton: true })
                authPost(data, 'invoice/update_garansi', this.props.userData.token).then(res => {
                    this.setState({ ...res.data.data, alamat: JSON.parse(res.data.data.alamat),loadingButton: false }
                    , () => {
                        Toast.fire({
                            type: "success",
                            title: "Data berhasil disimpan!"
                        })
                    });
                })
                .catch(err => {
                        AlertError.fire({ text: err.response.data.message });
                })
            }
        })
    }

    onChangeComplainProblem = (e) => {
        this.setState({
            complain_form: {
                ...this.state.complain_form,
                problem: e.target.value
            }
        });
    }

    onChangeComplainSolution = (e) => {
        this.setState({
            complain_form: {
                ...this.state.complain_form,
                solution: parseInt(e.currentTarget.value)
            }
        });
    }

    OnChangeComplainAgreement = () => {
        this.setState({
            complain_form: {
                ...this.state.complain_form,
                agreement: !this.state.complain_form.agreement
            }
        });
        
    }

    onChangeComplainImage = (e) => {
        // this.fileObj.push(e.target.files)
        // let files = Array.from(e.target.files);
        // this.setState({
        //     fileArray: []
        // });
        // files.forEach((file) => {
        //     let reader = new FileReader();
        //     reader.onloadend = () => {
        //         this.setState({
        //             complain_form: {
        //                 ...this.state.complain_form,
        //                 fileComplain: file
        //             },
        //             fileArray: [...this.state.fileArray, reader.result]
        //         }, () => {
        //             console.log(this.state.complain_form)
        //         });
        //     }
        //     reader.readAsDataURL(file);
        // });
        // this.setState({
        //     fileObj: e.target.files,
        // }, () => {
        //     console.log(this.state.fileObj);
            
        // });
        // for (let i = 0; i < this.fileObj[0].length; i++) {
        //     this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        // }
        // this.setState({ file: this.fileArray })
        // this.setState({ 
        //     complain_form: {
        //         ...this.state.complain_form,
        //         fileComplain: e.target.files
        //     }
        // });
    }

    onChangeComplainReason = (e) => {
        this.setState({
            complain_form: {
                ...this.state.complain_form,
                reason: e.target.value,
            }
        });
    }

    onSubmitComplain = (e) => {
        e.preventDefault();

        if(this.state.complain_form.reason === ""){
            AlertWarning.fire({text : "Alasan komplain wajib di isi"});
            return false;
        }
        if (this.state.complain_form.solution === 0) {
            AlertWarning.fire({ text: "Solusi komplain wajib di isi" });
            return false;
        }
        
        this.setState({ loadingButton: true });
        if ((this.state.complain_form.status_barang === 1 && this.state.complain_form.agreement) || this.state.complain_form.status_barang === 2) { 
            let photo = this.state.complain_form.fileComplain;
            let fd = new FormData();
            if (_.size(photo) > 0 && _.size(photo) <= 3) {
                    _.forEach(photo, (item, key) => {
                        fd.append(`photo[${key}]`, item)
                    })
                    fd.append('invoice_id', this.state.id);
                    fd.append('status_barang', this.state.complain_form.status_barang);
                    fd.append('complain_problem', this.state.complain_form.problem);
                    fd.append('complain_solution', this.state.complain_form.solution);
                    fd.append('reason', this.state.complain_form.reason);
                    // fd.append('user_type', 'buyer');
                    authPost(fd, 'complain/store', this.props.userData.token).then(res => {
                        this.setState({ ...res.data.data, alamat: JSON.parse(res.data.data.alamat), loadingButton: false }
                            , () => {
                                Toast.fire({
                                    type: "success",
                                    title: "Data berhasil disimpan!"
                                })
                                window.$('#modalKomplain').modal('hide');
                            });
                        
                    })
                    .catch(err => {
                        this.setState({ loadingButton: false });
                        AlertError.fire({ text: err.response.data.message });
                    })
                } else if (_.size(photo) > 3) { 
                    AlertError.fire({ text: "Foto Maksimal 3" })
                        .then(result => {
                            this.setState({ loadingButton: false });
                            // window.$('#modalKomplain').modal('hide');
                        });
                } else if (this.state.complain_form.status_barang === 2) {
                    fd.append('invoice_id', this.state.id);
                    fd.append('status_barang', this.state.complain_form.status_barang);
                    fd.append('complain_problem', 6);
                    fd.append('complain_solution', this.state.complain_form.solution);
                    fd.append('reason', this.state.complain_form.reason);
                    // fd.append('user_type', 'buyer');
                    authPost(fd, 'complain/store', this.props.userData.token).then(res => {
                        this.setState({ ...res.data.data, alamat: JSON.parse(res.data.data.alamat), loadingButton: false }
                            , () => {
                                Toast.fire({
                                    type: "success",
                                    title: "Data berhasil disimpan!"
                                })
                                window.$('#modalKomplain').modal('hide');
                            });
                        
                    })
                    .catch(err => {
                        this.setState({ loadingButton: false });
                        AlertError.fire({ text: err.response.data.message });
                    })
                } else {
                    AlertError.fire({ text: "Wajib Menyertakan foto bukti komplain maksimal 3 foto  " })
                        .then(result => {
                            this.setState({ loadingButton: false });
                            // window.$('#modalKomplain').modal('hide');
                        });
                }
        } else {
            AlertError.fire({ text: "Anda harus menyetujui untuk menanggung ongkos kirim jika harus mengembalikan barang!" })
                .then(result => {
                    this.setState({ loadingButton: false });
                    // window.$('#modalKomplain').modal('hide');
                });
        }
        
    }

    onChangePengembalianBarangModal = (e) => {
        this.setState({
            [e.currentTarget.name]: e.currentTarget.value,
        });
    }

    ComplainNextStep = (e) => {
        e.preventDefault();
        let data = {
            invoice_id: this.state.id,
            user_type: 'buyer',
        }
        let { token } = this.state.userData;
        authPost(data, 'complain/next_step', token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat),
            }, () => {
                    Toast.fire({
                        type: "success",
                        title: "Data berhasil disimpan!"
                    })
            });
            // window.$('#modalPilihPembayaran').modal('hide');
            // window.location.reload()
        })
    }
    ComplainNextStepShipment = (e) => {
        e.preventDefault();
        this.setState({ loadingButton: true });
        let data = {
            invoice_id: this.state.id,
            shipment: this.state.complain_shipment,
            resi: this.state.complain_resi,
            user_type: 'buyer',
        }
        let { token } = this.state.userData;
        authPost(data, 'complain/next_step_shipment', token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat),
                loadingButton: false,
            }, () => {
                    Toast.fire({
                        type: "success",
                        title: "Data berhasil disimpan!"
                    })
                window.$('#modalPengembalianBarang').modal('hide');

            });
            // window.location.reload()
        })
    }

    OnClickComplainShipment = (e) => {
        e.preventDefault();
        this.setState({
            complain_shipment_selected: this.state.complain.status[e.currentTarget.dataset.id].shipment
        });
        window.$('#modalInfoPengembalian').modal('show');
    }

    onClickBatalComplain = (e) => {
        e.preventDefault();
        ConfirmInfo.fire({
            text: "Apakah anda yakin ingin membatalkan komplain?"
        }).then(result => {
            if (result.value) {
                AlertLoading.fire({})
                let data = {
                    complain_id: this.state.complain.id,
                    user_type: 'buyer',
                }
                authPost(data, 'complain/store_batal', this.props.userData.token).then(res => {
                    this.setState({
                        ...res.data.data,
                        alamat: JSON.parse(res.data.data.alamat),
                    }, () => {
                        Toast.fire({
                            type: "success",
                            title: "Data berhasil disimpan!"
                        })
                    });
                })
            }
        });
    }

    onClickUbahComplain = (e) => {
        e.preventDefault();
        this.setState({
            complain_form: {
                status_barang: this.state.complain.status_barang_id,
                problem: this.state.complain.problem_id,
                reason: this.state.complain.reason,
                fileComplain: [],
                solution: this.state.complain.solution_id,
                agreement: true,
            }
        }, () => {
            window.$('#modalUbahKomplain').modal('show');
        });
    }

    onDropComplain = (picture) => {
        if (picture.length > 3 || this.state.complain_form.fileComplain >= 3) { 
            AlertError.fire({ text: "Foto komplain maksimal 3 foto" });
        }
            this.setState({
                complain_form: {
                    ...this.state.complain_form,
                    fileComplain: picture
                }});
    }

    onDropComplainUpdate = (picture) => {
        if (this.state.complain.images.length === 3) {
            AlertError.fire({ text: "Foto komplain maksimal 3 foto" });
            return;
        }else{
            this.setState({loadingUploadImage: true});
            let data = new FormData();
            data.append('file', picture[picture.length - 1]);
            data.append('invoice_id', this.state.id);

            authPostFile(data, `complain/add_complain_image`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        ...res.data.data,
                        alamat: JSON.parse(res.data.data.alamat),
                        loadingUploadImage: false
                    }, () => {
                        Toast.fire({
                            type: "success",
                            text: "Gambar berhasil di upload"
                        })
                    });
                })
                .catch(err => {
                    this.setState({ loadingUploadImage: false });
                    AlertError.fire({ text: err.response.data.message });
                })
        }
    }

    onDropUlasan = (picture) => {
        if (picture.length > 3 || this.state.fileUlasan >= 3) {
            AlertError.fire({ text: "Foto komplain maksimal 3 foto" });
        }
        this.setState({
            fileUlasan: picture
        });
    }

    onDropUlasanUpdate = (e) => {
        if (this.state.fileUlasan.length === 3) {
            AlertError.fire({ text: "Foto komplain maksimal 3 foto" });
            return false;
        } else {
            this.setState({ loadingUploadImage: true });
            let data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('product_ulasan_id', this.state.ulasanSelected.id);
            data.append('invoice_id', this.state.id);

            authPostFile(data, `invoice/add_ulasan_poto`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        ...res.data.data,
                        alamat: JSON.parse(res.data.data.alamat),
                        ulasanSelected: res.data.data.detail[this.state.ulasanSelected_id].ulasan,
                        ulasanText: res.data.data.detail[this.state.ulasanSelected_id].ulasan.review,
                        loadingUploadImage: false
                    }, () => {
                        Toast.fire({
                            type: "success",
                            text: "Gambar berhasil di upload"
                        })
                    });
                })
                .catch(err => {
                    this.setState({ loadingUploadImage: false });
                    AlertError.fire({ text: err.response.data.message });
                })
        }
    }

    onClickSimpanUbahComplain = (e) => {
        e.preventDefault();
        if (this.state.complain.status_barang_id === 1 && this.state.complain_form.status_barang === 2) { 
            authGet('complain/delete_all_complain_image/' + this.state.complain.id, this.props.userData.token);
        }
        if (this.state.complain_form.status_barang === 1 && _.size(this.state.complain.images) === 0 && _.size(this.state.complain.images) > 3) {
            AlertWarning.fire({ text: "Foto komplain wajib diisi" });
            return false;
        }
        if (this.state.complain_form.reason === "") {
            AlertWarning.fire({ text: "Alasan komplain wajib di isi" });
            return false;
        }
        if (this.state.complain_form.solution === 0) {
            AlertWarning.fire({ text: "Solusi komplain wajib di isi" });
            return false;
        }
        this.setState({loadingButton: true});
        let data = {
            invoice_id: this.state.id,
            status_barang: this.state.complain_form.status_barang,
            complain_problem: this.state.complain_form.status_barang === 2 ? 6 : this.state.complain_form.problem,
            complain_solution: this.state.complain_form.solution,
            reason: this.state.complain_form.reason
        }

            authPost(data, 'complain/ubah_complain', this.props.userData.token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat),
            }, () => {
                Toast.fire({
                    type: "success",
                    title: "Data berhasil disimpan!"
                })
                this.setState({loadingButton: false})
                window.$('#modalUbahKomplain').modal('hide');
            });
        })
        .catch(err => {
            this.setState({loadingButton: false});
            AlertError.fire({ text: err.response.data.message });
        })
    }

    removeImage(e, image) {
        e.preventDefault();
        ConfirmWarning.fire({
            text: "Apakah anda yakin ingin menghapus gambar ini?"
        }).then(result => {
        if (result.value) {
            let data = {
                invoice_id: this.state.id,
                complain_img_id: image.id,
            }
            authPost(data, 'complain/delete_complain_image', this.props.userData.token).then(res => {
                this.setState({
                    ...res.data.data,
                    alamat: JSON.parse(res.data.data.alamat),
                }, () => {
                        Toast.fire({
                            type: "success",
                            title: "Foto komplain berhasil dihapus!"
                        })
                    });
                })
                .catch(err => {
                    this.setState({loadingButton: false});
                    AlertError.fire({ text: err.response.data.message });
                })
            }
        }) 
    }
    removeImageUlasan(e, image) {
        e.preventDefault();
        let data = {
            invoice_id: this.state.id,
            ulasan_img_id: image.id,
        }

        ConfirmWarning.fire({
            text: "Apakah anda yakin ingin menghapus gambar ini?"
        }).then(result => {
            if(result.value){
                authPost(data, 'invoice/delete_ulasan_poto', this.props.userData.token)
                .then(res => {
                    this.setState({
                        ...res.data.data,
                        alamat: JSON.parse(res.data.data.alamat),
                        ulasanSelected: res.data.data.detail[this.state.ulasanSelected_id].ulasan,
                        ulasanText: res.data.data.detail[this.state.ulasanSelected_id].ulasan.review,
                    }, () => {
                        Toast.fire({
                            type: "success",
                            title: "Foto ulasan berhasil dihapus!"
                        })
                    });
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
        

    }
    loading = () => <div className="loading-btn mb20" style={{ width: "100px" }}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

    cangePhotoUlasan = (e) => {
        
        let file = e.target.files[0];
        
        this.setState({
            uploadUlasanImages: [
                ...this.state.uploadUlasanImages,
                window.URL.createObjectURL(file)
            ],
            uploadUlasanFiles: [
                ...this.state.uploadUlasanFiles,
                file
            ]
        });
        

    }

    removeUlasansImages (index) {
        let uploadUlasanImages = this.state.uploadUlasanImages;
        let uploadUlasanFiles = this.state.uploadUlasanFiles;
        
        uploadUlasanImages.splice(index, 1);
        uploadUlasanFiles.splice(index, 1);
        
        this.setState({
            uploadUlasanImages,
            uploadUlasanFiles
        })
    }

    getMetodePembayaran = () => {
        if(this.state.metode_pembayarans.length === 0) {
            this.setState({metode_pembayaran_loading: true});
            authGet(`v2/invoice_get_metode_pembayaran/${this.state.id}`, this.props.userData.token)
            .then(res => {
                this.setState({
                    metode_pembayarans : res.data,
                    metode_pembayaran_loading: false
                })
            })
            .catch(err => {
                this.setState({metode_pembayaran_loading: false});
                AlertError.fire({ text: err.response.data.message });
            })
        }
    }

    onClickGeneratePDF = (e) => {
        e.preventDefault();
        this.setState({loading_pdf: true})
        fetch(this.state.link_invoice, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer '+this.props.userData.token
            }), 
        })
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = this.state.no_transaksi+".pdf";
                this.setState({loading_pdf: false}, () => {
                    a.click();
                })
            });
        })
        .catch(err => {
            this.setState({loading_pdf: false});
            AlertError.fire({ text: err.response.data.message });
        })
    }

    onClickLihatLainnya = (e) => { 
        e.preventDefault();
        this.setState({show_limit_loading: true});
        authGet('invoice/detail/'+this.state.id, this.props.userData.token)
        .then(res => {
            this.setState({
                detail: res.data,
                show_limit: false,
                show_limit_loading: false,
            });
        })
        .catch(err => {
            this.setState({show_limit_loading: false});
            AlertError.fire({ text: err.response.data.message });
        })
    }

    onChangeStatusBarang = (e, status_barang) => {
        this.setState({
            complain_form: {
                ...this.state.complain_form,
                status_barang: parseInt(status_barang),
                agreement: parseInt(e.currentTarget.dataset.id) === 2 ? true : false,
                solution: 0
            }
        });
    }

    render() {
        let reference = {
            reference_id: this.state.id,
            reference_name: this.state.no_transaksi,
            reference_image: '',
            reference_total: this.state.grand_total,
            reference_type: 'invoice'
        }
        let shop = this.state.shop; 
        let message = '';
        if (this.state.complain) {
            if (this.state.complain.is_complain_finished) {
                if (this.state.status_id === 5) { 
                    if (this.state.complain.dibatal_at || (this.state.complain.ditolak_at && this.state.complain.status_tolak_internal === 1)) {
                        message = "Komplain diakhiri. Silahkan konfirmasi pesanan diterima.";
                    }else{
                        message = "Komplain selesai. Silahkan konfirmasi pesanan diterima.";
                    }
                } else {
                    if (this.state.complain.dibatal_at || (this.state.complain.ditolak_at && this.state.complain.status_tolak_internal === 1)) {
                        message = "Komplain diakhiri. "+this.state.status;
                    } else {
                        message = "Komplain selesai. "+this.state.status;
                    }
                }
            }else{
                message = "Anda sedang melakukan komplain & retur.";
            }
        } else {
            message = this.state.status;
        }

        let show_pesanan_diterima = null;
        if(this.state.complain){
            show_pesanan_diterima = this.state.complain.show_pesanan_diterima;
        }

        let Page = (
            <div className="container">
                {/* <SuccessNotif message={this.props.notifMessage}/> */}
                    <div className="form-transaction">
                        {/* <Header url_back="/transaction"/> */}
                        <h1 className="form-title">Detail Pesanan</h1>
                        <form encType="multipart/form-data">
                            <div className="form-subtitle no-border">
                                <span className="grey mr10 inline-block">Tanggal</span>
                                <span className="fs17">{this.state.tanggal}</span>
                                <div className="pull-right">
                                    <span className="grey mr10 inline-block">No. Transaksi</span>
                                    <span className="fs17">{this.state.no_transaksi}</span>
                                </div>
                            </div>
                            {
                                this.state.status_id > 1 && this.state.status_id !== 7 && this.state.status_id !== 8 ? 
                                <React.Fragment>
                                <div className="mb30">
                                    <div className="pull-right">
                                        {/* <a href="/#" className="btn btn-outline primary mr5" data-toggle="modal" data-target="#modalHubungiPenjual"><i className="fas fa-file-pdf"></i> Daftar Pesanan</a> */}
                                        {this.state.loading_pdf ? <Loading /> : <a className="btn btn-outline primary" href="/#" onClick={this.onClickGeneratePDF}><i className="fas fa-file-pdf"></i> Faktur</a>}
                                    </div>
                                </div>
                                <br />
                                </React.Fragment> : null
                            }
                            <div className="mb30">
                                <StepTransaction prosess={this.state.prosess}/>
                            </div>
                            
                            <Notification type={this.state.status_class} message={message} />
                            {/* {
                                this.state.status_id === 5 ? 
                                this.state.loadingButton ? <Loading /> : 
                                <a href="/#" className="btn btn-block btn-solid primary p10 mb30" onClick={this.onClickKonfirmasiPesanan}>Konfirmasi Pesanan Diterima</a>
                                : null
                            } */}
                            {
                                this.state.ditolak_reason ? 
                                    <table width="100%" className="mb10" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" width="200"><label className="darkgrey">Alasan Penjual</label></td>
                                            <td valign="top"><p className="mb0">{this.state.ditolak_reason}</p></td>
                                        </tr>
                                    </tbody>
                                </table> : null
                            }
                            {
                                this.state.status_id === 5 && !this.state.complain
                                    ? this.state.loadingButton 
                                        ? <Loading /> 
                                        : <a href="/#" className="btn btn-block btn-solid primary p10 mb30" onClick={this.onClickKonfirmasiPesanan}>Konfirmasi Pesanan Diterima</a>
                                    : null
                            }
                            {
                                this.state.status_id === 5 && show_pesanan_diterima
                                    ? this.state.loadingButton
                                        ? <Loading />
                                        : <a href="/#" className="btn btn-block btn-solid primary p10 mb30" onClick={this.onClickKonfirmasiPesanan}>Konfirmasi Pesanan Diterima</a>
                                    : null
                            }
                            {/* Perpanjang Garansi */}
                            {
                                this.state.garansi_button ? 
                                    <div className="text-center mb30">{this.state.loadingButton ? 'Perpanjang Garansi' : <a href="/#" onClick={this.onClickPerpanjangGaransi}>Perpanjang Garansi</a>} jika pesanan belum diterima sampai tanggal {moment(this.state.expired_diterima).format("DD MMMM YYYY")}.<br />Garansi akan diperpanjang selama 3 hari.</div>
                                : this.state.garansi_until !== null ? <div className="text-center mb30">Garansi telah diperpanjang sampai tanggal {moment(this.state.garansi_until).format("DD MMMM YYYY")}.</div>
                                    : null
                            }
                            {
                                this.state.complain ? 
                                <Complain
                                    {...this.state}
                                    OnClickComplainShipment={this.OnClickComplainShipment}
                                    onClickBatalComplain={this.onClickBatalComplain}
                                    loadingButton={this.state.loadingButton}
                                    onClickUbahComplain={this.onClickUbahComplain}
                                    
                                /> : null
                            }
                            {/* {
                                this.state.status_id === 5 && !this.state.complain
                                ? this.state.garansi_until === null 
                                    ? 
                                : this.state.complain 
                                    ? null 
                                        : 
                                :
                                null
                            } */}
                            {
                                this.state.complain 
                                    ? '' 
                                    : this.state.status_id === 5
                                        ? <div className="text-center mb30">{this.state.loadingButton ? 'Komplain & Retur' : <a href="/#" className="red" data-toggle="modal" data-target="#modalKomplain">Komplain &amp; Retur</a>}</div>
                                        : ''
                            }
                            {
                            !this.state.complain ? 
                                this.state.status_id === 6 ? 
                                    <div>
                                        <div className="form-subtitle">
                                            <h2>Ulasan Anda</h2>
                                        </div>

                                        <div className="mb30 transaction-list">
                                            <table width="100%">
                                                <tbody>
                                                    {this.state.detail.map((barang, i) => <tr key={i}>
                                                        <td valign="top" className="product-img-2">
                                                            <img src={barang.image} alt="barang" />
                                                        </td>
                                                        <td className="product-desc">
                                                            {
                                                                barang.ulasan ?
                                                                    <div>
                                                                        <a href="/#" className="pull-right btn btn-outline primary" data-id={i} onClick={this.onClickUbahUlasan}>Ubah Ulasan</a>
                                                                        <p className="fs15 semibold mb0">{barang.name}</p>
                                                                        <div className="rating fs15 pull-left">
                                                                            {this.renderStar(barang.ulasan.rating)}
                                                                        </div>
                                                                        <div className="pull-left pt5 ml20"><span className="grey mb20">{moment(barang.ulasan.created_at).format("DD MMM YYYY HH:mm")}</span></div>
                                                                        <div className="clearfix"></div>
                                                                        <p>{barang.ulasan.review}</p>
                                                                        {
                                                                            barang.ulasan.image ?
                                                                            <div className="review-image">
                                                                                {
                                                                                barang.ulasan.image.map((gambar, i) => (
                                                                                    <React.Fragment key={i}>
                                                                                        {!gambar.image_thumb ?
                                                                                            <div className="review-img" key={i}>
                                                                                                <img src={gambar.image_url} alt="" />
                                                                                            </div>
                                                                                            :
                                                                                            <ModalImage
                                                                                                className="review-imgg"
                                                                                                small={gambar.image_thumb}
                                                                                                medium={gambar.image_url}
                                                                                                large={gambar.image_url}
                                                                                                alt="Foto Ulasan"
                                                                                                showRotate={true}
                                                                                                hideDownload={true}
                                                                                            />}
                                                                                    </React.Fragment>
                                                                                ))
                                                                                }
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            Object.keys(barang.ulasan.reply).length === 0 ? '' :
                                                                                <><br /><div className="review-reply" style={{marginTop: barang.ulasan.image.length > 0 ? "12%" : "0%"}}>
                                                                                    <p className="review-from">Oleh <span>{this.state.shop.name}</span> <span className="label label-seller">Penjual</span> &nbsp;&nbsp;.&nbsp;&nbsp;{barang.ulasan.reply.created_at}</p>
                                                                                    <p className="review-text">{barang.ulasan.reply.review}</p>
                                                                                </div></>


                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <a href="/#" className="pull-right btn btn-outline primary" data-id={i} onClick={this.onClickBeriUlasan}>Beri Ulasan</a>
                                                                        <p className="fs15 semibold mb0">{barang.name}</p>
                                                                    </div>

                                                            }
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            {
                                                this.state.show_limit ?
                                                this.state.show_limit_loading ? <Loading /> : <div className="text-center"><a href="/#" onClick={this.onClickLihatLainnya}>Lihat ulasan lainnya</a></div>
                                                    : null
                                            }
                                        </div>
                                    </div> 
                                    : ''
                                    : 
                                (this.state.complain.solution_id === 1 || this.state.complain.solution_id === 4) && !this.state.complain.dibatal_at && !this.state.complain.ditolak_at ? '' :
                                    this.state.status_id === 6 ? 
                                    <div>
                                            <div className="form-subtitle">
                                                <h2>Ulasan Anda</h2>
                                            </div>

                                            <div className="mb30 transaction-list">
                                                <table width="100%">
                                                <tbody>
                                                    {this.state.detail.map((barang, i) => <tr key={i}>
                                                                    <td valign="top" className="product-img-2">
                                                                        <img src={barang.image} alt="barang"/>
                                                                    </td>
                                                                    <td className="product-desc">
                                                                        {
                                                                            barang.ulasan ? 
                                                                            <div>
                                                                                <a href="/#" className="pull-right btn btn-outline primary" data-id={i} onClick={this.onClickUbahUlasan}>Ubah Ulasan</a>
                                                                                <p className="fs15 semibold mb0">{barang.name}</p>
                                                                                <div className="rating fs15 pull-left">
                                                                                {this.renderStar(barang.ulasan.rating)}
                                                                                </div>
                                                                                <div className="pull-left pt5 ml20"><span className="grey mb20">{moment(barang.ulasan.created_at).format("DD MMM YYYY HH:mm")}</span></div>
                                                                                <div className="clearfix"></div>
                                                                                <p>{barang.ulasan.review}</p>
                                                                                {
                                                                                    barang.ulasan.image ?
                                                                                    <div className="review-image">
                                                                                        {
                                                                                        barang.ulasan.image.map((gambar, i) => (
                                                                                            <React.Fragment key={i}>
                                                                                                {!gambar.image_thumb ?
                                                                                                    <div className="review-img" key={i}>
                                                                                                        <img src={gambar.image_url} alt="" />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <ModalImage
                                                                                                        className="review-imgg"
                                                                                                        small={gambar.image_thumb}
                                                                                                        medium={gambar.image_url}
                                                                                                        large={gambar.image_url}
                                                                                                        alt="Foto Ulasan"
                                                                                                        showRotate={true}
                                                                                                        hideDownload={true}
                                                                                                    />}
                                                                                            </React.Fragment>
                                                                                        ))
                                                                                        }
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    Object.keys(barang.ulasan.reply).length === 0 ? '' :
                                                                                    <div className="review-reply">
                                                                                        <p className="review-from">Oleh <span>{this.state.shop.name}</span> <span className="label label-seller">Penjual</span> &nbsp;&nbsp;.&nbsp;&nbsp;{barang.ulasan.reply.created_at}</p>
                                                                                        <p className="review-text">{barang.ulasan.reply.review}</p>
                                                                                    </div>
                                                                                    
                                                                                    
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <a href="/#" className="pull-right btn btn-outline primary" data-id={i} onClick={this.onClickBeriUlasan}>Beri Ulasan</a>
                                                                                <p className="fs15 semibold mb0">{barang.name}</p>
                                                                            </div>

                                                                        }
                                                                    </td>
                                                                </tr>
                                                    )} 
                                            </tbody>
                                        </table>
                                        {
                                            this.state.show_limit ? 
                                            this.state.show_limit_loading ? <Loading /> : <div className="text-center"><a href="/#" onClick={this.onClickLihatLainnya}>Lihat ulasan lainnya</a></div>
                                            : null
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {
                                this.state.metode_pembayaran
                                ? <CardPembayaran
                                    {...this.state}
                                    confirmPayment={this.confirmPayment} />
                                : null
                            }
                            {/* { this.renderStatus(this.state.status_id) } */}

                            {
                                this.state.status_id > 1 && this.state.status_id !== 7 && this.state.status_id !== 8
                                ? <React.Fragment>
                                    <div className="form-subtitle">
                                        <h2>Metode Pembayaran</h2>
                                    </div>
                                    <div className="mb30">
                                        {
                                            !this.state.metode_pembayaran
                                            ? <a href="/#" className="btn btn-outline primary" data-toggle="modal" onClick={this.getMetodePembayaran} data-target="#modalPilihPembayaran">Pilih Metode Pembayaran</a>
                                            : <React.Fragment>
                                                {
                                                    this.state.status_id > 2
                                                    ? <a href="/#" className="btn btn-outline primary pull-right" data-toggle="modal" data-target="#modalInfoPembayaran">Lihat Informasi Pembayaran</a>
                                                    : <a href="/#" className="btn btn-outline primary pull-right" onClick={this.getMetodePembayaran} data-toggle="modal" data-target="#modalPilihPembayaran">Ubah Metode Pembayaran</a>
                                                }
                                                <img src={this.state.metode_pembayaran.image} alt="bank"/>
                                                <span>&nbsp;&nbsp;{this.state.metode_pembayaran.label}</span>
                                            </React.Fragment>
                                        }
                                    {/* {
                                        this.state.status_id === 2 ? 
                                        <div>
                                        <a href="/#" className="btn btn-outline primary pull-right" data-toggle="modal" data-target="#modalPilihPembayaran">Ubah Metode Pembayaran</a>
                                        <img src={this.state.bank.image} alt="bank"/>
                                        <span>Transfer {this.state.bank.nama}</span>
                                        </div>
                                        :
                                        this.state.status_id === 9 ? 
                                        <a href="/#" className="btn btn-outline primary" data-toggle="modal" data-target="#modalPilihPembayaran">Pilih Metode Pembayaran</a>
                                        :
                                        <div>
                                            <a href="/#" className="btn btn-outline primary pull-right" data-toggle="modal" data-target="#modalInfoPembayaran">Lihat Informasi Pembayaran</a>
                                            <img src={this.state.bank.image} alt="bank"/>
                                            <span>Transfer {this.state.bank.nama}</span>
                                        </div>
                                    } */}
                                    </div>
                                </React.Fragment>
                                : null
                            }
                            
                            <div className="form-subtitle">
                                <h2>Alamat Pengiriman</h2>
                            </div>
                            <div className="mb30">
                                <div>
                                    <p className="bold mb0">{this.state.alamat.label}</p>
                                    <p className="grey mb0">{this.state.alamat.penerima}</p>
                                    <p className="grey mb0">
                                        <NumberFormat
                                            value={this.state.alamat.phone} 
                                            displayType={'text'}
                                        format="#### #### #### #"
                                            />
                                        {/* {this.state.alamat.phone} */}
                                    </p>
                                    <p className="grey mb0">{this.state.alamat.alamat_lengkap} {this.state.alamat.kode_pos}<br />{this.state.alamat.kec} {this.state.alamat.kab}</p>
                                </div>
                            </div>

                            <div className="form-subtitle">
                                <h2>Daftar Pesanan</h2>
                            </div>

                            <div className="mb30">
                                <div className="pull-right">
                                <a href="/#" className="btn btn-outline primary mr5" data-toggle="modal" data-target="#modalHubungiPenjual"><i className="far fa-envelope"></i> Hubungi Penjual</a>
                                    <Link to={`/${this.state.shop.domain}`} className="btn btn-outline primary"><i className="fas fa-store"></i> Kunjungi Toko</Link>
                                </div>
                                <img src={this.state.shop.image} className="h30 mr15" alt="transaksi"/>
                                <span>{this.state.shop.name}</span>
                                <hr />
                                {
                                    this.state.detail.map( barang => 
                                    <ListProductTransaction 
                                        key={barang.id} 
                                        {...barang}
                                        allowEditQty={false}
                                        quantity={barang.jumlah}
                                        berat={(barang.berat / 1000)}
                                        price={barang.harga}
                                        sub_total={barang.harga * barang.jumlah}
                                        addQty={() => this.addQty(barang)}
                                        /> 
                                    )
                                }
                            {
                                this.state.show_limit ?
                                    this.state.show_limit_loading ? <Loading /> : <div className="text-center"><a href="/#" onClick={this.onClickLihatLainnya}>Lihat barang lainnya</a></div>
                                    : null
                            }
                            </div>
                            {
                                this.state.catatan
                                    ? <React.Fragment>
                                        <div className="form-subtitle">
                                            <h2>Catatan Untuk Penjual</h2>
                                        </div>
                                        <div className="mb30">
                                            <p>{this.state.catatan}</p>
                                        </div>
                                    </React.Fragment>
                                    : null
                            }
                            <div className="form-subtitle">
                                <h2>Metode Pengiriman</h2>
                            </div>
                            <div className="mb30">
                                {/* <div className="w75 pull-left">
                                    <img src={this.state.shipment.image} alt="transaksi"/>
                                </div>
                                <span>{this.state.shipment.name}</span> */}
                                <table cellPadding="10" width="100%" className="mb20">
                                    <tbody>
                                        <tr>
                                            <td width="300">
                                                <div className="w75 pull-left">
                                                    <img src={this.state.shipment.image} alt="shipment"/>
                                                </div>
                                                <span>{this.state.shipment.name}</span>
                                            </td>
                                            {
                                                this.state.shipment.third_party
                                                ? <React.Fragment>
                                                    <td valign="top">{this.state.shipment.ets} hari</td>
                                                    <td align="right" valign="top">Rp <FormatUang value={this.state.biaya_pengiriman} /></td>
                                                </React.Fragment>
                                                : this.state.status_id > 1
                                                    ? <td align="right" colSpan="2" valign="top">Rp <FormatUang value={this.state.biaya_pengiriman} /></td>
                                                    : <td align="right" colSpan="2" valign="top" className="text-info">ditentukan penjual</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                {
                                    this.state.status_id > 4 && this.state.status_id <= 6 ? 
                                    <div className="notification-box">
                                        <div className="info">
                                            <i className="fas fa-info-circle"></i>
                                            <p>{this.state.shipment.third_party ? 'Nomor Resi: ' : 'Catatan Pengiriman: '}<b>{this.state.catatan_pengiriman}</b></p>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                            <div className="footer-transaction-list">
                                <div className="total-transaction">
                                    <span>TOTAL</span>
                                    <span className="pull-right fs19">
                                        Rp <FormatUang value={this.state.total + this.state.biaya_pengiriman} />
                                    </span>
                                </div>
                            </div>

                            <br /><br /><br />
                                {
                                    this.state.status_id === 1 || this.state.status_id === 2
                                    ? <div className="text-center">
                                            <a href="/#" className="red" onClick={this.batalkanPesanan}>Batalkan Pesanan</a>
                                            <br /><br />
                                        </div>
                                    : null
                                }
                            
                        </form>
                    </div>
                </div>
        );
        
        return (
            <div className="mg-top" style={{marginTop: "80px"}}>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {/* <LoadingPage/> */}
                        <TransactionDetailLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                {reference.reference_id !== undefined ? <HubungiPenjual seller={shop} reference={reference} /> : ''}
                <div className="modal fade modal-2" id="modalPilihPembayaran" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {
                                        this.state.metode_pembayaran_loading
                                        ? <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <ReactLoading type="spinningBubbles" color="#0C7F8F" />
                                        </div>
                                        : <React.Fragment>
                                            <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                            <h2>PILIH METODE PEMBAYARAN</h2>
                                                {
                                                    this.state.metode_pembayarans.map(metode_pembayaran => (
                                                        <div className="mb30 ml10" key={metode_pembayaran.id}>
                                                            <label className="form-check">
                                                                {/* <input type="radio" name="opt1" defaultChecked="1" className="mr20" /> */}
                                                                {metode_pembayaran.label}
                                                            </label>
                                                            {
                                                                metode_pembayaran.list.map(list => {
                                                                    // let active = this.state.activeBank === list.id ? 'active' : '';
                                                                    return (
                                                                        <div className="mb20 pl50" key={list.id}>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    checked={this.state.activeBank === list.id}
                                                                                    onChange={() => this.setState({
                                                                                        activeBank: list.id,
                                                                                        metode_pembayaran_id: metode_pembayaran.id,
                                                                                        metode_pembayaran_selected: list
                                                                                    })}
                                                                                    type="radio" 
                                                                                    className="form-check-input"/>
                                                                                <img src={list.image} alt="bank" />
                                                                                <label htmlFor="">&nbsp; {list.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    // <div 
                                                                    //     className={`box-option ${active}`} 
                                                                    //     key={list.id} 
                                                                    //     onClick={() => this.setState({ activeBank: list.id })}
                                                                    //     >
                                                                    //         <img src={list.image} alt="bank" />
                                                                    // </div>
                                                                )})
                                                            }
                                                        </div>
                                                    ))
                                                }
                                        </React.Fragment>
                                    }

                                        {/* <div className="mb30">
                                            <label className="form-check"><input type="radio" name="opt1" defaultChecked="1" className="mr20" /> Transfer Bank</label>
                                            {
                                                this.state.banks.map(bank => {
                                                    let active = this.state.activeBank === bank.id ? 'active' : '';
                                                    return (
                                                        <div className={`box-option ${active}`} key={bank.id} onClick={() => this.setState({ activeBank: bank.id })}>
                                                            <img src={bank.image} alt="bank" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> */}
                                    </div>
                                    {
                                        this.state.loadingButton
                                        ? <div className="modal-footer">
                                                <div style={{marginRight: "40px"}}>
                                                    <Loading/>
                                                </div>
                                            </div>
                                        :   <div className="modal-footer">
                                                <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                                <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onPilihPembayaranClick}>Simpan</a>
                                            </div>
                                    }                                    
                                    {/* {
                                        this.state.metode_pembayaran_loading
                                        ? null
                                        : <div className="modal-footer">
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onPilihPembayaranClick}>Simpan</a>
                                        </div>
                                    } */}
                                    
                                </div>
                        </div>
                    </div>
                {/* <div className="modal fade modal-2" id="modalPesananDiterima" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    Apakah pesanan sudah diterima? Pembayaran {this.state.total} akan diteruskan ke penjual.
                                </div>
                                {
                                    this.state.loadingButton
                                    ? <div className="modal-footer">
                                            <div style={{marginRight: "40px"}}>
                                                <Loading/>
                                            </div>
                                        </div>
                                    : <div className="modal-footer">
                                        <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Tidak</a>
                                        <button type="submit" className="btn btn-solid primary btn-sm" onClick={this.ulasNanti}>Ya</button>
                                    </div>
                                }
                            </div>
                    </div>
                </div> */}
                <div className="modal fade modal-2" id="modalBeriUlasan" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>ULAS BARANG</h2>
                                    <div className="form-group-div">
                                        <div className="transaction-list">
                                            <table width="100%">
                                                <tbody>
                                                <tr>
                                                    <td className="product-img-2 ulasan-img">
                                                        <img src={this.state.barangSelected ? this.state.barangSelected.image : ''} alt="select"/>
                                                    </td>
                                                    <td className="product-desc">
                                                        <p className="product-name">{this.state.barangSelected ? this.state.barangSelected.name : ''}</p>
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{width: '110px'}} className="grey pr10">Jumlah</td>
                                                                    <td>{this.state.barangSelected ? this.state.barangSelected.jumlah : ''}</td>
                                                                    <td style={{width: '125px'}}></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="grey pr10">Harga Satuan</td>
                                                                    <td>Rp {this.state.barangSelected ? <FormatUang value={this.state.barangSelected.harga} /> : ''}</td>
                                                                    <td className="product-total-price">Rp {this.state.barangSelected ? <FormatUang value={this.state.barangSelected.harga*this.state.barangSelected.jumlah} /> : ''}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Beri Ulasan</label>
                                    <div className="rating rating-input">
                                        <i
                                            className={classNames("fas", "fa-star", { "starred": this.state.modalRating[1] })}
                                            onMouseEnter={() => this.handleRating(1)}
                                            onMouseLeave={this.resetRating}
                                            onClick={() => this.setRating(1)}></i>
                                        <i
                                            className={classNames("fas", "fa-star", { "starred": this.state.modalRating[2] })}
                                            onMouseEnter={() => this.handleRating(2)}
                                            onMouseLeave={this.resetRating}
                                            onClick={() => this.setRating(2)}></i>
                                        <i
                                            className={classNames("fas", "fa-star", { "starred": this.state.modalRating[3] })}
                                            onMouseEnter={() => this.handleRating(3)}
                                            onMouseLeave={this.resetRating}
                                            onClick={() => this.setRating(3)}></i>
                                        <i
                                            className={classNames("fas", "fa-star", { "starred": this.state.modalRating[4] })}
                                            onMouseEnter={() => this.handleRating(4)}
                                            onMouseLeave={this.resetRating}
                                            onClick={() => this.setRating(4)}></i>
                                        <i
                                            className={classNames("fas", "fa-star", { "starred": this.state.modalRating[5] })}
                                            onMouseEnter={() => this.handleRating(5)}
                                            onMouseLeave={this.resetRating}
                                            onClick={() => this.setRating(5)}></i>
                                    </div>
                                        <br />
                                        <textarea
                                            className="form-control"
                                            placeholder="Tulis Ulasan"
                                            value={this.state.ulasanText}
                                            onChange={(e) => this.setState({ ulasanText: e.target.value })}></textarea>
                                </div>
                                <div className="form-group-div">
                                            {/* <label>Foto bukti komplain</label>
                                            {
                                                this.state.loadingUploadImage
                                                    ? <Loading />
                                                    : <ImageUploader
                                                        name="photo"
                                                        label="Rasio gambar 1:1 (Persegi). Maksimal gambar yang diunggah adalah 3 gambar."
                                                        withPreview={true}
                                                        withLabel={true}
                                                        withIcon={true}
                                                        buttonText='Tambah Gambar'
                                                        onChange={this.onDropUlasan}
                                                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                                        maxFileSize={3145728}
                                                    />
                                            } */}
                                        <div className="image-upload-container">
                                            <ReactCSSTransitionGroup
                                                transitionName="example"
                                                transitionEnterTimeout={500}
                                                transitionLeaveTimeout={300}>
                                            {
                                                this.state.uploadUlasanImages.map((image, i) => (
                                                    
                                                    <div className="image-upload-div lg" key={i}>
                                                        <div 
                                                            className="image-upload"
                                                            style={{
                                                                backgroundImage: `url(${image})`,
                                                                backgroundSize: "cover",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundPosition: "center center"
                                                            }}
                                                            >
                                                        <a href="/#" className="pull-right" onClick={(e) => {e.preventDefault();this.removeUlasansImages(i)}}><i className="fas fa-times fs16 red"></i></a>
                                                            {/* <img src={image} alt="ts" style={{width: "100%", height: "100%"}}/> */}
                                                        </div>
                                                    </div>
                                                    
                                                ))
                                            }
                                            {
                                                this.state.uploadUlasanImages.length < 3
                                                ? <React.Fragment>
                                                    <div className="image-upload-div lg">
                                                        <div className="image-upload" 
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => this.uploadPhoto.click()}>
                                                            <i className="fas fa-camera"></i>
                                                        </div>
                                                    </div>
                                                        <input 
                                                            type="file" 
                                                            ref={(ref) => this.uploadPhoto = ref} 
                                                            style={{ display: 'none' }} 
                                                            onChange={this.cangePhotoUlasan} accept=".png, .jpg, .jpeg"/>
                                                </React.Fragment>
                                                : null
                                            }
                                            </ReactCSSTransitionGroup>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.loadingButton
                                    ? <div className="modal-footer">
                                            <div style={{marginRight: "40px"}}>
                                                <Loading/>
                                            </div>
                                        </div>
                                    : <div className="modal-footer">
                                        <a href="/#" className="btn btn-solid darkgrey btn-sm" data-dismiss="modal">Batal</a>
                                        <button type="submit" className="btn btn-solid primary btn-sm" onClick={this.setUlasan}>Simpan</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-2" id="modalUbahUlasan" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                        <h2>UBAH ULASAN BARANG</h2>
                                        <div className="form-group-div">
                                            <div className="transaction-list">
                                                <table width="100%">
                                                    <tbody>
                                                    <tr>
                                                        <td className="product-img-2 ulasan-img">
                                                            <img src={this.state.barangSelected ? this.state.barangSelected.image : ''} alt="select"/>
                                                        </td>
                                                        <td className="product-desc">
                                                            <p className="product-name">{this.state.barangSelected ? this.state.barangSelected.name : ''}</p>
                                                            <table width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: '110px'}} className="grey pr10">Jumlah</td>
                                                                        <td>{this.state.barangSelected ? this.state.barangSelected.jumlah : ''}</td>
                                                                        <td style={{width: '125px'}}></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="grey pr10">Harga Satuan</td>
                                                                        <td>Rp {this.state.barangSelected ? <FormatUang value={this.state.barangSelected.harga} /> : ''}</td>
                                                                        <td className="product-total-price">Rp {this.state.barangSelected ? <FormatUang value={this.state.barangSelected.harga*this.state.barangSelected.jumlah} /> : ''}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="form-group-div">
                                            <label>Beri Ulasan</label>
                                        <div className="rating rating-input">
                                            <i
                                                className={classNames("fas", "fa-star", { "starred": this.state.modalRating[1] })}
                                                onMouseEnter={() => this.handleRating(1)}
                                                onMouseLeave={this.resetRating}
                                                onClick={() => this.setRating(1)}></i>
                                            <i
                                                className={classNames("fas", "fa-star", { "starred": this.state.modalRating[2] })}
                                                onMouseEnter={() => this.handleRating(2)}
                                                onMouseLeave={this.resetRating}
                                                onClick={() => this.setRating(2)}></i>
                                            <i
                                                className={classNames("fas", "fa-star", { "starred": this.state.modalRating[3] })}
                                                onMouseEnter={() => this.handleRating(3)}
                                                onMouseLeave={this.resetRating}
                                                onClick={() => this.setRating(3)}></i>
                                            <i
                                                className={classNames("fas", "fa-star", { "starred": this.state.modalRating[4] })}
                                                onMouseEnter={() => this.handleRating(4)}
                                                onMouseLeave={this.resetRating}
                                                onClick={() => this.setRating(4)}></i>
                                            <i
                                                className={classNames("fas", "fa-star", { "starred": this.state.modalRating[5] })}
                                                onMouseEnter={() => this.handleRating(5)}
                                                onMouseLeave={this.resetRating}
                                                onClick={() => this.setRating(5)}></i>
                                        </div>
                                            <br />
                                            <textarea
                                                className="form-control"
                                                placeholder="Tulis Ulasan"
                                                value={this.state.ulasanText}
                                                onChange={(e) => this.setState({ ulasanText: e.target.value })}></textarea>
                                    </div>
                                    {/* {this.state.ulasanSelected.image 
                                        ? this.state.ulasanSelected.image.map(image => (
                                        <Images
                                            key={image.id}
                                            removeImage={(e) => this.removeImageUlasan(e, image)}
                                            {...image}
                                        />
                                    ))
                                    :null
                                    }
                                    {
                                        this.state.ulasanSelected.image ? this.state.ulasanSelected.image.length >= 3 ? null :
                                            <div className="form-group-div">
                                                <label>Gambar Ulasan</label>
                                                {
                                                    this.state.loadingUploadImage
                                                        ? <Loading />
                                                        : <ImageUploader
                                                            name="photo"
                                                            label="Rasio gambar 1:1 (Persegi). Maksimal gambar yang diunggah adalah 3 gambar. "
                                                            // withPreview={true}
                                                            withLabel={true}
                                                            withIcon={true}
                                                            buttonText='Tambah Gambar'
                                                            onChange={this.onDropUlasanUpdate}
                                                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                                            maxFileSize={3145728}
                                                            singleImage
                                                        />
                                                }
                                            </div>
                                            : null
                                    } */}
                                <div className="image-upload-container">
                                    <ReactCSSTransitionGroup
                                        transitionName="example"
                                        transitionEnterTimeout={500}
                                        transitionLeaveTimeout={300}>
                                    {
                                        this.state.ulasanSelected.image.map((image, i) => (
                                            
                                            <div className="image-upload-div lg" key={i}>
                                                <div 
                                                    className="image-upload"
                                                    style={{
                                                        backgroundImage: `url(${image.image_thumb})`,
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center center"
                                                    }}
                                                    >
                                                <a href="/#" className="pull-right" onClick={(e) => this.removeImageUlasan(e, image)}>
                                                    <i className="fas fa-times fs16 red"></i>
                                                </a>
                                                    {/* <img src={image.image_thumb} alt="ts" style={{width: "100%", height: "100%"}}/> */}
                                                </div>
                                            </div>
                                            
                                        ))
                                    }
                                    {
                                        this.state.ulasanSelected.image.length < 3
                                        ? <React.Fragment>
                                            <div className="image-upload-div lg">
                                                {
                                                    this.state.loadingUploadImage
                                                    ? <LodingThumnailDetailProduct/>
                                                    : <div className="image-upload" 
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => this.uploadUbahPhoto.click()}>
                                                            <i className="fas fa-camera"></i>
                                                        </div>
                                                }
                                            </div>
                                                <input 
                                                    type="file" 
                                                    ref={(ref) => this.uploadUbahPhoto = ref} 
                                                    style={{ display: 'none' }} 
                                                    onChange={this.onDropUlasanUpdate} accept=".png, .jpg, .jpeg"/>
                                        </React.Fragment>
                                        : null
                                    }
                                    
                                {/*
                                    this.state.ulasanSelected.image ?
                                        this.state.ulasanSelected.image.map(data => (
                                            
                                            
                                            <div className="user-profile-picture" key>
                                                <img src={`${URL}/storage/images/ulasan/${data.image}`} alt="Profil" className="profile-picture lg" />
                                                {
                                                    this.state.loadingUploadImage
                                                        ? <div style={{ position: "absolute", top: "35%", left: "35%" }}>
                                                            <ReactLoading type="spin" color="#0C7F8F" width={30} />
                                                        </div>
                                                        : null
                                                }
                                                <div className="action-profile-picture">
                                                    <input id="myInput" type="file" ref={(ref) => this.uploadPhoto = ref} style={{ display: 'none' }} onChange={this.cangePhotoProfile} accept=".png, .jpg, .jpeg" />
                                                    <a href="/#" className="mr10 fs15" onClick={(e) => { e.preventDefault(); this.uploadPhoto.click() }}><i className="fas fa-camera"></i></a>
                                                    {
                                                        this.state.hasImage
                                                            ? <a href="/#" className="red fs15" onClick={this.removeProfileImage}><i className="fas fa-trash-alt"></i></a>
                                                            : null
                                                    }

                                                </div>
                                            </div>

                                        ))
                                    :
                                    <div className="form-group-div">
                                        <label>Tambah Gambar</label><br />
                                        <input
                                            type="file"
                                            multiple
                                            onChange={this.handleSelectedFile}
                                            accept="image/png, image/jpeg,  image/jpg" />
                                    </div>*/
                                }
                                </ReactCSSTransitionGroup>
                                </div>
                                </div>
                                {
                                    this.state.loadingButton
                                    ? <div className="modal-footer">
                                            <div style={{marginRight: "40px"}}>
                                                <Loading/>
                                            </div>
                                        </div>
                                    : <div className="modal-footer">
                                        <a href="/#" className="btn btn-solid darkgrey btn-sm" data-dismiss="modal">Batal</a>
                                        <button type="submit" className="btn btn-solid primary btn-sm" onClick={this.setUlasan}>Simpan</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                <div className="modal fade modal-2" id="modalInfoPembayaran" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>INFORMASI PEMBAYARAN</h2>
                                    {
                                        this.state.metode_pembayaran
                                        ? <InfoPembayaran2
                                                {...this.state}
                                            />
                                        : null
                                    }
                                
                                </div>
                            </div>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalKomplain" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>KOMPLAIN & RETUR</h2>

                                    <div className="form-group-div">
                                        <label>Apakah barang sudah diterima?</label><br />
                                        <label className="form-check"><input type="radio" name="opt2" onChange={() => this.setState({complain_form: {...this.state.complain_form, status_barang: 1, agreement: false}})} checked={this.state.complain_form.status_barang === 1 ? 'checked' : ''} /> Ya, pesanan sudah diterima</label>
                                        <label className="form-check"><input type="radio" name="opt2" onChange={() => this.setState({complain_form: {...this.state.complain_form, status_barang: 2, agreement: true}})} checked={this.state.complain_form.status_barang === 2 ? 'checked' : ''} /> Belum menerima pesanan</label>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Apa permasalahan barang?</label>
                                        {
                                            this.state.complain_form.status_barang === 1 ? 
                                                <select className="form-control" onChange={this.onChangeComplainProblem} required>
                                                    {
                                                        this.state.complain_problems.map(data => {
                                                            return (
                                                                data.id !== 6 ? 
                                                                <option key={data.id} value={data.id}>{data.name}</option> 
                                                                : ''
                                                            )
                                                        })
                                                    }
                                                </select>
                                            :
                                            <select className="form-control" disabled defaultValue="6">
                                                <option value="6">Barang pesanan belum sampai</option>
                                            </select>
                                        }
                                    </div>
                                    <div className="form-group-div">
                                        <label>Alasan</label>
                                        <textarea className="form-control" placeholder="Tulis Alasan" onChange={this.onChangeComplainReason} required></textarea>
                                    </div>
                                    {
                                        this.state.complain_form.status_barang === 2 ? '' : 
                                        <div className="form-group-div">
                                            <label>Foto bukti komplain</label>
                                                <ImageUploader
                                                    // className={hiddeButton}
                                                    name="photo"
                                                    // className = "image-upload"
                                                    label="Rasio gambar 1:1 (Persegi). Ukuran gambar minimum 300x300 pixel. "
                                                    withPreview={true}
                                                    withLabel={true}
                                                    withIcon={true}
                                                    buttonText='Pilih Gambar'
                                                    onChange={this.onDropComplain}
                                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                                    maxFileSize={3145728}
                                                />
                                        </div>
                                    }
                                    <div className="form-group-div">
                                        <label>Pilih solusi yang anda inginkan</label>
                                        <ComplainSolution
                                        complain_solutions={this.state.complain_solutions}
                                        status_barang={this.state.complain_form.status_barang}
                                        onChangeComplainSolution={this.onChangeComplainSolution}
                                        {...this.state}
                                        />
                                    </div>
                                    {
                                        this.state.complain_form.status_barang === 2 ? '' : 
                                        <div className="form-group-div form-warning">
                                            <label className="form-check"><input type="checkbox" onChange={this.OnChangeComplainAgreement} checked={this.state.complain_form.agreement} /> Saya bersedia untuk menanggung ongkos kirim jika harus mengembalikan barang</label>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer">
                                {
                                    this.state.loadingButton 
                                        ? <Loading />  :
                                        <React.Fragment>
                                        <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                        <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onSubmitComplain}>Komplain</a>
                                        </React.Fragment> 
                                }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    !this.state.complain ? null : 
                        <div className="modal fade modal-2" id="modalUbahKomplain" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <form>
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                            <h2>UBAH KOMPLAIN & RETUR</h2>

                                            <div className="form-group-div">
                                                <label>Apakah barang sudah diterima?</label><br />
                                                <label className="form-check"><input type="radio" name="opt2" onChange={(e) => this.onChangeStatusBarang(e, 1)} checked={this.state.complain_form.status_barang === 1} /> Ya, pesanan sudah diterima</label>
                                                <label className="form-check"><input type="radio" name="opt2" onChange={(e) => this.onChangeStatusBarang(e, 2)} checked={this.state.complain_form.status_barang === 2} /> Belum menerima pesanan</label>
                                            </div>
                                            <div className="form-group-div">
                                                <label>Apa permasalahan barang?</label>
                                                {
                                                    this.state.complain_form.status_barang === 1 ?
                                                        <select className="form-control" onChange={this.onChangeComplainProblem} required value={this.state.complain_form.problem}>
                                                            {
                                                                this.state.complain_problems.map(data => {
                                                                    return (
                                                                        data.id !== 6 ?
                                                                            <option key={data.id} value={data.id}>{data.name}</option>
                                                                            : null
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        :
                                                        <select className="form-control" disabled defaultValue="6">
                                                            <option value="6">Barang pesanan belum sampai</option>
                                                        </select>
                                                }
                                            </div>
                                            <div className="form-group-div">
                                                <label>Alasan</label>
                                                <textarea className="form-control" placeholder="Tulis Alasan" onChange={this.onChangeComplainReason} required value={this.state.complain_form.reason}></textarea>
                                            </div>
                                            {this.state.complain_form.status_barang === 2 ? '' : this.state.complain.images.map(image => (
                                                    <Images
                                                        key={image.id}
                                                        removeImage={(e) => this.removeImage(e, image)}
                                                        clickImageActive={(e) => this.clickImageActive(e, image)}
                                                        {...image}
                                                    />
                                                ))
                                            }
                                            {
                                                this.state.complain_form.status_barang === 2 || this.state.complain.images.length >= 3? '' :
                                                    <div className="form-group-div">
                                                        <label>Foto bukti komplain</label>
                                                        {
                                                            this.state.loadingUploadImage
                                                            ? <Loading /> 
                                                            : <ImageUploader
                                                                    name="photo"
                                                                    label="Rasio gambar 1:1 (Persegi). Ukuran gambar minimum 300x300 pixel. "
                                                                    // withPreview={true}
                                                                    withLabel={true}
                                                                    withIcon={true}
                                                                    buttonText='Tambah Gambar'
                                                                    onChange={this.onDropComplainUpdate}
                                                                    imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                                                    maxFileSize={3145728}
                                                                    singleImage
                                                                />
                                                        }
                                                        {/* <input
                                                        type="file"
                                                        className="form-control"
                                                        multiple
                                                        onChange={this.onChangeComplainImage} 
                                                        accept="image/png, image/jpeg,  image/jpg" /> */}
                                                    </div>
                                            }
                                            <div className="form-group-div">
                                                <label>Pilih solusi yang anda inginkan</label>
                                                <ComplainSolution
                                                    complain_solutions={this.state.complain_solutions}
                                                    status_barang={this.state.complain.status_barang_id}
                                                    onChangeComplainSolution={this.onChangeComplainSolution}
                                                    {...this.state}
                                                />
                                            </div>
                                            <div className="form-group-div form-warning">
                                                <label>Saya telah bersedia untuk menanggung ongkos kirim jika harus mengembalikan barang</label>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            {
                                            this.state.loadingButton
                                                ? <Loading /> :
                                                <React.Fragment>
                                                    <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                                    <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onClickSimpanUbahComplain}>Ubah Komplain</a>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                }
                
                <div className="modal fade modal-2" id="modalPengembalianBarang" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>PENGEMBALIAN BARANG</h2>

                                    <div className="form-group-div">
                                        <label>Metode Pengiriman <span className="red">*</span></label>
                                        <input type="text" className="form-control" placeholder="Contoh: JNE, J&T, Tiki, Pengiriman sendiri" name="complain_shipment" onChange={this.onChangePengembalianBarangModal} value={this.state.complain_shipment}/>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Nomor Resi</label>
                                        <input type="text" className="form-control" placeholder="Tulis nomor resi" name="complain_resi" onChange={this.onChangePengembalianBarangModal} value={this.state.complain_resi}/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {  
                                        this.state.loadingButton ?  <Loading /> 
                                        : 
                                        <>
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.ComplainNextStepShipment}>Simpan</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal fade modal-2" id="modalInfoPengembalian" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>Info {this.state.complain_shipment_selected.link_title}</h2>

                                <table width="100%" cellPadding="3">
                                    <tbody>
                                        <tr>
                                            <td valign="top" width="150"><label className="grey">Tanggal Pengiriman</label></td>
                                            <td valign="top"><p className="mb0">{this.state.complain_shipment_selected.created_at}</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Metode Pengiriman</label></td>
                                            <td valign="top"><p className="mb0">{this.state.complain_shipment_selected.shipment}</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Nomor Resi</label></td>
                                            <td valign="top"><p className="mb0">{this.state.complain_shipment_selected.no_resi}</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Alamat</label></td>
                                            {
                                                this.state.complain_shipment_selected.link_title === "Pengembalian" ? 
                                                <td valign="top"><p className="mb0">{this.state.shop.name}<br /><span className="grey">{this.state.shop.telephone}</span><br /><span className="grey">{this.state.shop_alamat.address}</span><br /><span className="grey">{this.state.shop_alamat.district}, {this.state.shop_alamat.city} {this.state.shop_alamat.zip_portal}</span></p></td>
                                                :
                                                <td valign="top">
                                                <p className="bold mb0">{this.state.alamat.label}</p>
                                                <p className="grey mb0">{this.state.alamat.penerima}</p>
                                                <p className="grey mb0">
                                                    <NumberFormat
                                                        value={this.state.alamat.phone}
                                                        displayType={'text'}
                                                        format="#### #### #### #"
                                                    />
                                                    {/* {this.state.alamat.phone} */}
                                                </p>
                                                <p className="grey mb0">{this.state.alamat.alamat_lengkap} {this.state.alamat.kode_pos}<br />{this.state.alamat.kec} {this.state.alamat.kab}</p>
                                                </td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal-2" id="modalInfoPengiriman" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>INFO PENGIRIMAN</h2>

                                <table width="100%" cellPadding="3">
                                    <tbody>
                                        <tr>
                                            <td valign="top" width="150"><label className="grey">Tanggal Pengiriman</label></td>
                                            <td valign="top"><p className="mb0">13 Okt {(new Date().getFullYear())} 14:20:10</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Metode Pengiriman</label></td>
                                            <td valign="top"><p className="mb0">JNE Reg</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Nomor Resi</label></td>
                                            <td valign="top"><p className="mb0">3453463489</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Alamat</label></td>
                                            <td valign="top"><p className="mb0">{this.state.shop.name}<br /><span className="grey">{this.state.shop.telephone}</span><br /><span className="grey">{this.state.shop_alamat.address}</span><br /><span className="grey">{this.state.shop_alamat.district}, {this.state.shop_alamat.city} {this.state.shop_alamat.zip_portal}</span></p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal-2" id="modalInfoKurir" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>INFORMASI STATUS PENGIRIMAN</h2>
                                <label className="grey">Apa kata kurir?</label>
                                <p>{this.state.complain ? this.state.complain.status_courier_answer : ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData : state.user.user,
        notifMessage: state.notification.message
    }
}

export default NetworkDetector(connect(mapStateToProps , {
    setNotification,
    toggleModalLogin
})(TransactionBuyerDetail));