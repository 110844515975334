import React from 'react'
import ContentLoader from "react-content-loader"

export default function TransactionListLoading() {
    return (
        <div className="container-fluid">
            <div className="transaction-container">
                <div className="filter-left">
                <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="0" ry="0" width="500" height="30"/>
                        <rect x="10" y="40" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="110" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="180" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="250" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="320" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="390" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="460" rx="0" ry="0" width="500" height="50"/>
                        <rect x="10" y="530" rx="0" ry="0" width="500" height="50"/>
                </ContentLoader>        
                </div>
                <div className="transaction-main basic-container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            </ol>
                        </nav>
                        <br />
                        <ContentLoader 
                        height={20}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="51" height="10"/>
                </ContentLoader>   
                        <div className="transaction-tab">
                            <ul>
                            <ContentLoader 
                            height={10}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="25" height="10"/>
                                <rect x="26" y="0" rx="0" ry="0" width="25" height="10"/>
                            </ContentLoader>  
                            </ul>
                        </div>
                        <div className="mt10">
                        <ContentLoader 
                            height={130}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="12" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="24" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="36" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="48" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="60" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="72" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="84" rx="0" ry="0" width="400" height="13"/>
                        </ContentLoader> 
                        </div>
            </div>
        </div>
        </div>
    )
}

