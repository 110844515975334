import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toggleModalLogin } from "../../actions/registerActions";
import { 
    setNotification 
} from "../../actions/notificationAction";
import { getUserData } from "../../actions/userAction";
import { getPrevCart } from "../../actions/cartActions";
import AuthService from '../../actions/API/AuthService';
import { Modal, ModalBody } from 'reactstrap';
import Logo from '../../logo.png';
import ReactLoading from 'react-loading';
import {
    AlertError, 
    AlertWarning, 
    post,
    callNotification,
    AlertInfo,
    Toast,
} from '../../helper';

import OtpInput from 'react-otp-input';


export class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '', 
            password: '',
            loading : false,
            active: 1,
            phone: "",
            otp: "",
            temp_otp: "",
            set_email: "",
            set_password: "",
            set_password_confirm: "",
            view_password: "password",
            icon_password: "view-password",
        }

        this.onChange = this.onChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);

        this.Auth = new AuthService();
    }

    loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

    onChange(event){
        return this.setState({[event.target.name]: event.target.value});
    }

    toggle(){
        this.props.toggleModalLogin(!this.props.toggle);
    }

    validateLogin(data){
        if(data.email === ""){
            AlertWarning.fire({text: "Email / Username belum di isi"});
            return false;
        }
        else if(data.password === ""){
            AlertWarning.fire({text: "Password belum di isi"});
            return false;
        }

        return true;
    }

    changePhoneNumber = (e) => {
        let phone = e.target.value.replace(/[^\d]+/g,'');
        this.setState({phone})
    }

    verifyNumberPhone = (e) => {
        e.preventDefault();
        
        if(this.state.phone === ""){
            AlertWarning.fire({text: "No. Handphone tidak boleh kosong"});
            return false;
        }

        this.setState({loading: true})
        post({phone: this.state.phone}, `register/verify_number_phone`)
        .then(res => {
            if(res.data.alreadyUser){
                this.setState({active: 2});
                AlertInfo.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Login",
                    cancelButtonText: "Kembali"
                })
                .then(result => {
                    this.setState({loading: false});
                    if(result.value){
                        this.setState({
                            active: 1
                        })
                    }
                })
            }
            else {
                this.setState({
                    active: 3,
                    phone: res.data.phone,
                    loading: false,
                    temp_otp: res.data.otp
                }, () => {
                    Toast.fire({
                        type: "success",
                        title: res.data.message
                    });
                })
            }
        })
        .catch(err => {
            this.setState({loading: false})
            AlertError.fire({text: err.response.data.message})
        })
    }

    verifyOTP = (e) => {
        e.preventDefault();
        let data = {
            phone: this.state.phone,
            otp: this.state.otp
        };
        this.sendOTP(data);
    }

    sendOTP(data){
        this.setState({loading: true})
        post(data, `register/verify_otp`)
        .then(res => {
            this.setState({
                phone: res.data,
                active: 4,
                loading: false
            })
        })
        .catch(err => {
            this.setState({loading: false})
            AlertError.fire({text: err.response.data.message});
        })
    }

    onChangeOTP(otp){
        this.setState({otp}, () => {
            if(this.state.otp.length >= 6){
                if(parseInt(this.state.otp) === parseInt(this.state.temp_otp)){
                    let data = {
                        phone: this.state.phone,
                        otp: this.state.otp
                    };
                    this.sendOTP(data);
                }
            }
        })
    }

    handleSubmitLoginV2 = (e) => {
        e.preventDefault();
        let data = {
            email : this.state.email,
            password: this.state.password
        }
        if(this.validateLogin(data)){
            this.loginPost(data)
        }
    }

    loginPost = (data) => {
        this.setState({loading : true});
        post(data, `login`)
        .then(res => {
            this.props.setNotification("Login berhasil");
            callNotification();
            this.props.getUserData(res.data);
            this.props.getPrevCart(res.data.token)
            // .then(() => {
                // this.props.toggleModalLogin(false);
                // this.setState({
                //     loading: false,
                //     email: '',
                //     password: ''
                // });

            // })
            
        })
        .then(() => {
            setTimeout(() => {
                if(this.props.match.url === "/register"){
                    window.location.href = '/'
                }else{
                    window.location.href = this.props.match.url
                }
            }, 1000)
        })
        .catch(err => {
            this.setState({
                loading : false,
                password : ''
            }, () => {
                AlertError.fire({text : err.response.data.message});
            });
        })
    }


    handleSubmitLogin(event){
        event.preventDefault();
        this.setState({loading : true});
        this.Auth.login(this.state.email, this.state.password)
        .then(res => {
            this.Auth.setToken(res.data.success.token)
            this.props.getUserData(res.data.success)
            this.props.getPrevCart(res.data.success.token, this.props.url)
            .then(() => {
                // this.props.toggleModalLogin(false);
                // Toast.fire({
                //     type : "success",
                //     title : "Login Berhasil!"
                // });
                // this.setState({
                //     email : '',
                //     password : '',
                //     loading : false
                // }, () => {
                    this.props.toggleModalLogin(false);
                    window.location.href = this.props.url
                // })
            })
            .catch(err => {
                AlertError.fire({text : err.response.data.message});
            })
            // window.location.href = '/';
            
        })
        .catch((err) => {
            this.setState({
                loading : false,
                password : ''
            }, () => {
                AlertError.fire({text : err.response.data.message});
            });
            // alert(err.response.data.message);
        })
    }

    setPasswordFarm = (e) => {
        e.preventDefault();
        let data = {
            phone : this.state.phone,
            email : this.state.set_email,
            password : this.state.set_password,
            password_confirm : this.state.set_password_confirm
        }

        this.setState({loading: true})
        post(data, `register/set_password_farm`)
        .then(res => {
            this.loginPost({
                email : res.data.phone,
                password: data.password
            })
        })
        .catch(err =>{
            this.setState({loading: false})
            AlertError.fire({text: err.response.data.message});
        })
    }
    viewPassword = () => {
        this.setState({ view_password: this.state.view_password === "password" ? "text" : "password" });
        this.setState({ icon_password: this.state.view_password === "password" ? "hide-password" : "view-password" });
    }

    render() {
        return (
            <Modal isOpen={this.props.toggle} toggle={this.toggle} className={this.props.className}>
                <ModalBody>
                    <div className="text-center logo">
                        <img src={Logo} alt="Logo"/>
                    </div>
                    <h2>LOGIN</h2>
                    <form className="auth-form" style={{display: this.state.active === 1 ? "" : "none"}}>
                        <div className="form-group">
                            <input 
                                type="text" 
                                name="email" 
                                value={this.state.email} 
                                onChange={this.onChange}
                                />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Email / Username / No. Handphone</label>
                        </div>
                        <div className="form-group">
                            <input 
                                type={this.state.view_password} 
                                name="password" 
                                value={this.state.password} 
                                onChange={this.onChange} 
                                />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Password</label>
                            <i className={`far icon_password ${this.state.icon_password}`} onClick={this.viewPassword}></i>
                        </div>
                        <p className="addon-text"><Link to="/forgot_password">Lupa Password?</Link></p>
                        <div className="text-center">
                        {
                            this.state.loading
                            ? 
                            this.loading()
                            :<React.Fragment>
                            <button 
                                type="submit" 
                                className="btn btn-solid btn-block primary btn-login" 
                                onClick={this.handleSubmitLoginV2}>Masuk</button>
                            <a 
                                href="/#" 
                                className="btn btn-outline btn-block primary mb10"
                                onClick={(e)=>{e.preventDefault();this.setState({active: 2})}}
                                style={{ display: 'none' }}
                                >Pengguna Agrinis Farm dan belum pernah Login</a>
                                <p>Belum bergabung? <Link to="/register">Registrasi</Link></p>
                                <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                            </React.Fragment> 
                        }
                    </div>
                    </form>
                    <form className="auth-form" style={{display: this.state.active === 2 ? "" : "none"}}>
                    <label className="mb20">Masukkan nomor handphone anda untuk masuk ke Agrinis Shop</label>
                    <div className="form-group">
                        <input 
                            type="text"
                            value={this.state.phone}
                            onChange={this.changePhoneNumber}
                            />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>No. Handphone</label>
                    </div>
                    <div className="text-center">
                        {
                            this.state.loading
                            ? this.loading()
                            : <button 
                                type="submit" 
                                className="btn btn-solid btn-block primary btn-login"
                                onClick={this.verifyNumberPhone}
                                >Kirim Kode OTP</button>
                        }
                        
                        <p>Sudah punya akun? <a href="/#" onClick={(e)=>{e.preventDefault();this.setState({active: 1})}}>Login</a></p>
                        <p>Belum punya akun? <Link to="/register">Registrasi</Link></p>
                        <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                    </div>
                </form>
                <form className="auth-form" style={{display: this.state.active === 3 ? "" : "none"}}>
                    <label className="mb20">Kami telah mengirim kode OTP ke nomor handphone anda.</label>
                    {/* <div className="form-group text-center"> */}
                    <OtpInput
                        inputStyle={{width: "80%"}}
                        containerStyle="form-group text-center"
                        onChange={otp => this.onChangeOTP(otp)}
                        value={this.state.otp}
                        numInputs={6}
                        separator={<span> </span>}
                    />
                    {/* </div> */}
                    <div className="text-center">
                    {
                        this.state.loading
                        ? this.loading()
                        : <React.Fragment>
                            <button 
                            type="submit" 
                            className="btn btn-solid btn-block primary btn-login"
                            onClick={this.verifyOTP}>Masuk</button>
                            <a 
                                href="/#"
                                onClick={this.verifyNumberPhone}
                                >Kirim Ulang Kode OTP</a>
                        </React.Fragment>
                    }
                        
                        <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                    </div>
                </form>
                <form className="auth-form" style={{display: this.state.active === 4 ? "" : "none"}}>
                    <label className="mb20">Lengkapi data anda agar selanjutnya dapat login dengan mudah.</label>
                    <div className="form-group">
                        <input
                            type="text" 
                            name="set_email" 
                            value={this.state.set_email} 
                            onChange={this.onChange}
                            />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                    </div>
                    <div className="form-group">
                        <input 
                            type="password" 
                            name="set_password"
                            value={this.state.set_password}
                            onChange={this.onChange}
                            />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Password</label>
                    </div>
                    <div className="form-group">
                        <input 
                            type="password" 
                            name="set_password_confirm"
                            value={this.state.set_password_confirm}
                            onChange={this.onChange}
                            />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Konfirmasi Password</label>
                    </div>
                    <div className="text-center">
                    {
                        this.state.loading
                        ? this.loading()
                        : <button 
                            type="submit" 
                            className="btn btn-solid btn-block primary btn-login"
                            onClick={this.setPasswordFarm}
                            >Simpan Password</button>
                    }
                        
                        <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                    </div>
                </form>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        toggle: state.register.toggle
    }
}

export default connect(mapStateToProps, {
    toggleModalLogin, 
    getUserData, 
    getPrevCart,
    setNotification
})(Login);
