import React from 'react';
import HargaGrosir from '../_Component/HargaGrosir';
import "./CreateProductStyle.css";
import NumberFormat from 'react-number-format';

import {
    FormatUang
} from '../../../helper';

import {
    InfoPPN,
    LabelPPN,
    LabelPPNRight
} from '../_Component/PpnComponent'



const InfoHarga = props => (
    <div>
        <div className="form-subtitle">
            <h2>INFORMASI HARGA</h2>
        </div>
        <div className="row">
            <div className="col-md-5 mb30">
                <div className="form-group-div">
                    <label>Harga Satuan <span className="red">*</span></label>
                    {/* <label className="form-check">
                        <input type="radio" name="opt2" checked=""/> Hubungi Penjual
                    </label> */}
                    {/* <label className="form-check" id="show_form_price">
                        <input type="radio" name="opt2" defaultChecked/> 
                        Tentukan Harga
                    </label> */}
                    <div className="form_price">
                        <div className="form-group-div">
                            <NumberFormat
                                name="hargaSatuan"
                                type="text"
                                className="form-control mb5 right"
                                placeholder="Harga Satuan (Rp)"
                                value={props.data.harga} 
                                displayType={'input'}
                                thousandSeparator="."
                                decimalSeparator=","
                                onValueChange={(values) => props.onChangeHarga(values.value)}
                                />
                            {/* <input 
                                name="hargaSatuan" 
                                type="number" 
                                className="form-control mb5" 
                                placeholder="Harga Satuan (Rp)" 
                                value={createProduct.hargaSatuan} 
                                onChange={this.handleChange}/> */}


                            <div style={{display: "flex", marginBottom: "20px", marginTop: "10px"}}>
                                <div className="w100p">
                                    <input type="checkbox" checked={props.data.ppn} onChange={props.onChangePpn}/>
                                    <LabelPPN>Dikenakan PPN 10%</LabelPPN>
                                </div>
                                {
                                    props.data.ppn
                                    ? <div className="w100p" style={{textAlign: "right", display: "flex"}}>
                                        <LabelPPNRight style={{fontSize: "12px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.data.harga === "" ? 0 : parseInt(props.data.harga) + ((parseInt(props.data.harga)*10)/ 100)}/></LabelPPNRight>
                                        <div>
                                            <InfoPPN 
                                                className="fas fa-info-circle c-info"
                                                onMouseEnter={() => window.$(".label-info-ppn").css({opacity: 1, transition: 'opacity 0.5s ease-in'})}
                                                onMouseLeave={() => window.$(".label-info-ppn").css({opacity: 0})}
                                                ></InfoPPN>
                                            <div 
                                                className="alert alert-info label-info-ppn" 
                                                style={{
                                                    position: "absolute", 
                                                    textAlign: "left", 
                                                    width: "50%",
                                                    opacity: 0
                                                }}>
                                                Harga ini adalah harga yang tertera pada daftar barang dan detail barang
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                } 
                            </div>
                            <div className="notification-box sm" style={{ maxWidth: "374px" }}>
                                <div className="info">
                                    <i className="fas fa-info-circle"></i>
                                    <p>Harga spesial adalah harga yang ditampilkan untuk Pembeli Spesial.</p>
                                </div>
                            </div>                             
                            {
                                props.grosir
                                ? <a 
                                    href="/#" 
                                    className="btn btn-outline-danger btn-sm" 
                                    onClick={(e)=> {e.preventDefault(); props.setGrosir(false)}}
                                    >
                                        <i className="fas fa-times"></i>Batalkan harga grosir
                                    </a>
                                : <span 
                                    className="text-link"
                                    onClick={(e)=> {e.preventDefault(); props.setGrosir(true)}}>
                                        <i className="fas fa-plus fs12"></i> Tambah Harga Grosir
                                    </span>
                            }
                            
                            
                        </div>
                    </div>
                </div>              
            </div>

            {
                props.is_vip
                ? <div className="col-md-5 mb30">
                        <div className="form-group-div">
                            <label>Harga Spesial <span className="red">*</span></label>
                            {/* <label className="form-check">
                                <input type="radio" name="opt2" checked=""/> Hubungi Penjual
                            </label> */}
                            {/* <label className="form-check" id="show_form_price">
                                <input type="radio" name="opt2" defaultChecked/> 
                                Tentukan Harga
                            </label> */}
                            <div className="form_price">
                                <div className="form-group-div">
                                    <NumberFormat
                                        name="hargaSatuan"
                                        type="text"
                                        className="form-control mb5 right"
                                        placeholder="Harga Satuan untuk VIP (Rp)"
                                        value={props.data.harga_vip} 
                                        displayType={'input'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        onValueChange={(values) => props.onChangeHargaVIP(values.value)}
                                        />
                                <div style={{display: "flex", marginBottom: "20px", marginTop: "10px"}}>
                                        {
                                            props.data.ppn
                                            ? <div className="w100p" style={{textAlign: "right", display: "flex"}}>
                                                <LabelPPNRight style={{fontSize: "12px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.data.harga_vip === "" ? 0 : parseInt(props.data.harga_vip) + ((parseInt(props.data.harga_vip)*10)/ 100)}/></LabelPPNRight>
                                                <div>
                                                    <InfoPPN 
                                                        className="fas fa-info-circle c-info"
                                                        onMouseEnter={() => window.$(".label-info-ppn").css({opacity: 1, transition: 'opacity 0.5s ease-in'})}
                                                        onMouseLeave={() => window.$(".label-info-ppn").css({opacity: 0})}
                                                        ></InfoPPN>
                                                    <div 
                                                        className="alert alert-info label-info-ppn" 
                                                        style={{
                                                            position: "absolute", 
                                                            textAlign: "left", 
                                                            width: "50%",
                                                            opacity: 0
                                                        }}>
                                                        Harga ini adalah harga yang tertera pada daftar barang dan detail barang
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        
                                    </div> 
                                </div>                       
                            </div>
                        </div>
                    </div>
                : null
            }
            
            
            <div className="col-md-2">
                <div className="form-group-div">
                    <label>Minimal Pembelian <span className="red">*</span></label>
                    {/* minimal pembelian default 1 */}
                    <input 
                        name="min_order" 
                        type="text" 
                        className="form-control mb5 w60p right"
                        value={props.data.min_order}
                        onChange={props.onChangeMinOrder}
                        disabled={props.data.disabledMinOrder}/>
                </div>
            </div>
            <div className="col-md-12">
            <div className="form_grosir_price" style={{display: props.grosir? "block" : "none"}}>
                            <label>Harga Grosir</label>
                            <table className="table table-form">
                                <thead>
                                    <tr>
                                        <td className="w150">
                                            <input 
                                                name="min" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Jumlah Min" 
                                                onChange={props.handleChangeNumber}
                                                value={props.data.min}
                                                disabled={props.data.disabled || props.data.hargaGrosirs.length > 0}/>
                                        </td>
                                        <td align="center" className="w25"> - </td>
                                        <td className="w150">
                                            <input 
                                                name="max" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Jumlah Max" 
                                                onChange={props.handleChangeNumber}
                                                value={props.data.max}
                                                ref={props.refMaxGrosir}
                                                disabled={props.data.disabled}/>
                                        </td>
                                        <td align="center" className="w25"> = </td>
                                        <td className="pr10">
                                        <NumberFormat
                                            name="harga_grosir"
                                            type="text"
                                            className="form-control right"
                                            placeholder="Harga Biasa(Rp)"
                                            value={props.data.harga_grosir} 
                                            displayType={'input'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            disabled={props.data.disabled}
                                            onValueChange={(values) => props.onChangeHargaGrosir(values.value)}
                                            />
                                        </td>
                                        {
                                            props.is_vip
                                            ? <td className="pr10">
                                                <NumberFormat
                                                    name="harga_grosir"
                                                    type="text"
                                                    className="form-control right"
                                                    placeholder="Harga VIP (Rp)"
                                                    value={props.data.harga_grosir_vip} 
                                                    displayType={'input'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    disabled={props.data.disabled}
                                                    onValueChange={(values) => props.onChangeHargaGrosirVIP(values.value)}
                                                    />
                                                </td>
                                            : null
                                        }
                                                                                
                                        <td className="w40">
                                            <button type="button" className="btn btn-solid primary btn-plus" onClick={props.addHargaGrosir} disabled={props.data.disabled}>
                                                <i className="fas fa-plus no-margin"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6">
                                            <div className="notification-box sm">
                                                <div className="info">
                                                    <i className="fas fa-info-circle"></i>
                                                    <p>Kosongkan jumlah maksimum untuk jumlah tak terbatas</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.data.hargaGrosirs.map((item, i)=>(
                                            <HargaGrosir 
                                                key={i}
                                                indexItem={i}
                                                countItem={props.data.hargaGrosirs.length}
                                                onClick={() => props.onRemoveHarga(item, i)}
                                                ppn={props.data.ppn}
                                                is_vip={props.is_vip}
                                                {...item}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
            </div>
        </div>
    </div>
)


export default InfoHarga;
