import React from 'react';
import NumberFormat from 'react-number-format';
import { withNamespaces } from 'react-i18next';
const ShippingAddress = props => {
    const { t } = props;
    return (
    props.data.length > 0
    ? 
    <div>
    {props.data.map((data,i) => (
        <div className="box-outline" key={i}>
            <div className="pull-right mr10 mt10">
                <a href="/" className="mr5" data-toggle="modal" data-target="#modalTambahAlamat" data-id={i} onClick={props.onClickEditAlamat}><i className="fas fa-pencil-alt"></i></a>
                <a href="/" data-id={data.id} onClick={props.onClickHapusAlamat}><i className="red fas fa-trash-alt"></i></a>
            </div>
            <span className="block bold">{data.label}</span>
            <span className="block grey">{data.penerima}</span>
            <span className="block grey"><NumberFormat
                value={data.phone}
                displayType={'text'}
                format="#### #### #### #"
            /></span>
            <span className="block grey">
                {data.alamat_lengkap} {data.kode_pos}
                {props.no_br ? <span>&nbsp;</span> : <br />}
                {data.kec}, {data.kab}
            </span>
            <hr />
                <p className="grey mb0">{t("ShippingAddress:keterangan_lokasi")}: {data.instruksi_tambahan}</p>
        </div>
    ))}
            <a href="/#" className="btn btn-outline primary" data-toggle="modal" data-target="#modalTambahAlamat" onClick={props.onClickTambahAlamat}><i className="fas fa-plus"></i> {t("ShippingAddress:tambah")}</a>
    </div>
    :
    <div>
            <p>{t("ShippingAddress:kosong")}</p>
            <a href="/#" className="btn btn-outline primary" data-toggle="modal" data-target="#modalTambahAlamat" onClick={props.onClickTambahAlamat}><i className="fas fa-plus"></i> {t("ShippingAddress:tambah")}</a>
        </div>
    )
}

export default withNamespaces("ShippingAddress")(ShippingAddress);