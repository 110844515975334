import React from 'react'
import _ from 'lodash'

const SelectForm = (props) => {
    const { 
        items, 
        label,
        name, 
        className, 
        onChange,
        value
    } = props
    return (
        <div className="col-md-6">
            <select name={name} className={`form-control ${className}`} onChange={onChange} value={value} ref={props.ref}>
                {/* {
                    props.categoryValue > 0
                    ? null
                    : <option key="default" value={0}>{label}</option>
                } */}
                <option key="default" value={0}>{label}</option>
                {
                    _.size(items) > 0 
                    ? _.map(items, (item, i) => {
                        return(
                            <option 
                                key={i}
                                value={item.id}>
                                {item.name}
                            </option>
                        )
                    })
                    : null
                }
                {
                    props.other
                    ? <option key="other" value="other">Lain-lain</option>
                    : null
                }
            </select>
        </div>
    )
}

export default SelectForm
