import React from 'react'
// import {URL} from '../../config';
import { FormatUang } from '../../helper';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';


const Ppn = styled.span`
    font-style: italic;
    font-size: 10px;
    color: #84C225;
`;

const ListProduct = (props) => (
    <div className={`transaction-list ${props.fadeOut ? 'fadeout' : ''}`}>
        <table width="100%">
            <tbody>
            <tr>
                <td className="product-img-2">
                    <img src={props.image_thumb} alt="prda"/>
                </td>
                <td className="product-desc">
                    {
                        props.removeButton
                        ? <a href="/#" className="pull-right red" onClick={props.onRemoveDetail}><i className="fas fa-trash-alt"></i></a>
                        : null
                    }
                    <p className="product-name">{props.name}</p>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td style={{width: "110px"}} className="grey pr10">Jumlah</td>
                                <td>
                                    {
                                        props.allowEditQty
                                        ?
                                        <div className="input-group qty-input">
                                            <div className="input-group-prepend">
                                                <button className="btn" type="button" onClick={props.minQty} disabled={props.disableDec}>
                                                    <i className="fas fa-minus no-margin"></i>
                                                </button>
                                            </div>

                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                value={props.quantity} 
                                                onChange={props.onchange} 
                                                onBlur={props.onBlur}
                                                />

                                            <div className="input-group-prepend">
                                                <button className="btn" type="button" onClick={props.addQty}>
                                                    <i className="fas fa-plus no-margin"></i>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        props.jumlah
                                    }
                                </td>
                                <td style={{width: "125px"}}></td>
                            </tr>
                            <tr>
                                <td className="grey pr10">Berat</td>
                                <td>
                                    <NumberFormat
                                        displayType="text"
                                        value={parseFloat((props.berat * props.quantity).toFixed(3))}
                                        decimalSeparator=","
                                        /> Kg
                                    {/* {(props.berat * props.quantity).toFixed(3) } Kg */}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="grey pr10">Harga Satuan</td>
                                <td>Rp <FormatUang value={parseInt(props.price)}/>
                                </td>
                                <td className="product-total-price">
                                    Rp <FormatUang value={props.sub_total}/>
                                    <br/>
                                    {
                                        props.ppn
                                        ? <Ppn>Termasuk PPN</Ppn>
                                        : null
                                    }
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
)

export default ListProduct