import React from 'react';
import ContentLoader from "react-content-loader";

const ProfileShopLoading = () => (
            <div className="container">
                <div className="basic-container">
                <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="100" height="5"/>
            </ContentLoader>
                    <br />
                    <ContentLoader 
                        height={10}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="50" height="5"/>
                    </ContentLoader>
                    <ContentLoader 
                        height={25}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="29" y="5" rx="4" ry="4" width="117" height="6" /> 
                        <rect x="29" y="15" rx="3" ry="3" width="85" height="6" /> 
                        <circle cx="11" cy="15" r="10" />
                    </ContentLoader>
                    <div className="clearfix"></div>
                    <br />

                    <div className="fs13">
                    <ContentLoader 
                            height={20}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="450" height="10"/>
                        </ContentLoader>
                        <ContentLoader 
                            height={65}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="350" height="50"/>
                            {/* <rect x="0" y="15" rx="0" ry="0" width="350" height="10"/> */}
                            {/* <rect x="0" y="30" rx="0" ry="0" width="350" height="10"/> */}
                            {/* <rect x="0" y="45" rx="0" ry="0" width="350" height="10"/> */}
                        </ContentLoader>
                        <ContentLoader 
                            height={20}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="450" height="10"/>
                        </ContentLoader>
                        <ContentLoader 
                            height={65}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="350" height="50"/>
                        </ContentLoader>
                    </div>
                </div>
            </div>
)
export default ProfileShopLoading;