import React from 'react';
import image from '../../assert/files/loading/cart.gif';


const LoadingLock = () => (
    <div className="pageloading-container">
        <div className="pageloading">
            <img src={image} alt="loading"/>
            <span>Sedang memuat, mohon tunggu.</span>
        </div>
    </div>
)

export default LoadingLock;