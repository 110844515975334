import React, {PureComponent} from 'react'
import _ from 'lodash';
import ReactLoading from 'react-loading';
import {
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Brush,
    ResponsiveContainer,
} from 'recharts';
import * as moment from "moment";
import 'moment/locale/id';
import i18n from '../../i18n';
moment.locale('id');
class TickBulanan extends PureComponent {

    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{moment(payload.value).format('MMM')}</text>
                {/* <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{payload.value}</text> */}
            </g>
        );
    }
}
class TickMingguan extends PureComponent {
    render() {
        const {
            x, y, payload,
        } = this.props;
        let start = moment(payload.value).format("DD MMM");
        let end = moment(payload.value).add(6, 'days').format("DD MMM");
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{start + ' - ' + end}</text>
            </g>
        );
    }
}
class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, payload,
        } = this.props;
        let date = moment(payload.value).format("DD MMM");
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-49)">{date}</text>
            </g>
        );
    }
}
const Transaksi = props => {
    return (
        <div className="statistic-body" id="statistic-2">
            <table width="100%" cellPadding="15">
                <tbody>
                    <tr>
                        <td style={{ width: '25%' }}>
                            <div className="stat-bg-1">
                                <label>{i18n.t("Statistic:transaksi.diterima")}</label>
                                <span>{_.sum(_.map(props.data, 'Diterima'))}</span>
                            </div>
                        </td>
                        <td style={{ width: '25%' }}>
                            <div className="stat-bg-3">
                                <label>{i18n.t("Statistic:transaksi.ditolak")}</label>
                                <span>{_.sum(_.map(props.data, 'Ditolak'))}</span>
                            </div>
                        </td>
                        <td style={{ width: '25%' }}>
                            <div className="stat-bg-4">
                                <label>{i18n.t("Statistic:transaksi.dibatalkan")}</label>
                                <span>{_.sum(_.map(props.data, 'Dibatalkan'))}</span>
                            </div>
                        </td>
                        <td style={{ width: '25%' }}>
                            <div className="stat-bg-5">
                                <label>{i18n.t("Statistic:transaksi.dikomplain")}</label>
                                <span>{_.sum(_.map(props.data, 'Dikomplain'))}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="mb15">
                <label className="mr20">{i18n.t("Statistic:tampilkan.title")}</label>
                <select className="form-control w150 inline-block" value={props.show_active} onChange={props.onChangeShow} disabled={props.LoadingShowBy || props.data.length === 0 ? 'disabled' : ''}>
                    <option value="bulanan">{i18n.t("Statistic:tampilkan.bulanan")}</option>
                    <option value="mingguan">{i18n.t("Statistic:tampilkan.mingguan")}</option>
                    <option value="harian">{i18n.t("Statistic:tampilkan.harian")}</option>
                </select>
                {
                    props.LoadingShowBy ?
                        <div>
                            <ReactLoading type="spinningBubbles" color="#0C7F8F" width={20} height={20} />
                        </div> : ''
                }
            </div>
            <div className="row mb15">
                <div className="col-md-12">
                    <span className="title-graphic">{i18n.t("Statistic:transaksi.grafik")}</span>
                    <div style={{ width: '100%', height: 600 }}>
                        <ResponsiveContainer>
                            <BarChart
                                width={600}
                                height={500}
                                data={props.data}
                                margin={{ top: 5, right: 30, left: 20, bottom: 50 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="label" label={{ value: i18n.t("Statistic:transaksi.periode"), angle: 0, position: 'bottom' }} height={100} tick={props.show_active === "bulanan" ? <TickBulanan /> : props.show_active === "mingguan" ? <TickMingguan/> : <CustomizedAxisTick />} minTickGap={3} tickSize={3} interval={0} />
                                <YAxis type="number" allowDecimals={false} domain={[0, 'dataMax + 1']} label={{ value: i18n.t("Statistic:transaksi.jumlah_transaksi"), angle: -90, position: 'insideLeft' }} />
                                <Tooltip labelFormatter={(value) => props.show_active === "bulanan" ? moment(value).format('MMMM YYYY') : props.show_active === "mingguan" ? moment(value).format("DD MMMM YYYY") + " - " + moment(value).add(6, 'days').format("DD MMMM YYYY") : moment(value).format("DD MMMM YYYY")} formatter={(value) => value + " transaksi"} />
                                <Legend verticalAlign="top" align="right" height={36} />
                                <Bar dataKey="Diterima" fill="#6ac8c8" />
                                <Bar dataKey="Ditolak" fill="#fe6a6a" />
                                <Bar dataKey="Dibatalkan" fill="#ffbc4d" />
                                <Bar dataKey="Dikomplain" fill="#bd9fff" />
                                {
                                    props.data.length > 12 ?
                                        <Brush
                                            dataKey='name'
                                            height={20}
                                            stroke="#000000"
                                            y={560}
                                            startIndex={0}
                                            endIndex={12}>

                                            <BarChart>
                                                <Bar dataKey="Diterima" fill="#6ac8c8" />
                                                <Bar dataKey="Ditolak" fill="#fe6a6a" />
                                                <Bar dataKey="Dibatalkan" fill="#ffbc4d" />
                                                <Bar dataKey="Dikomplain" fill="#bd9fff" />
                                            </BarChart>

                                        </Brush>
                                        : ''
                                }
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        {/* <Chart options={props.chart_transaksi.chartOptionsBrush} series={props.chart_transaksi.series} type="area" height="130" /> */}
                    </div>

                </div>
            </div>
            <a href="/#" style={{ display: props.show_table ? 'none' : '' }} data-id="open" onClick={props.onClickShowTable}>{i18n.t("Statistic:transaksi.show_table")}</a>
            <a href="/#" style={{ display: !props.show_table ? 'none' : '' }} data-id="close" onClick={props.onClickShowTable}>{i18n.t("Statistic:transaksi.hide_table")}</a>

            <div className="row mb15 mt15" style={{ display: !props.show_table ? 'none' : '' }}>
                <div className="col-md-12">
                    <div className="stat-table-container">
                        <span className="title-graphic">{i18n.t("Statistic:transaksi.tabel.title")}</span>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{i18n.t("Statistic:transaksi.tabel.periode")}</th>
                                    <th width="200" className="center bg-1">{i18n.t("Statistic:transaksi.tabel.diterima")}</th>
                                    <th width="200" className="center">{i18n.t("Statistic:transaksi.tabel.ditolak")}</th>
                                    <th width="200" className="center">{i18n.t("Statistic:transaksi.tabel.dibatalkan")}</th>
                                    <th width="200" className="center">{i18n.t("Statistic:transaksi.tabel.dikomplain")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.data.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                {
                                                    props.show_active === "bulanan" ?
                                                        <td>{moment(data.label).format("MMMM YYYY")}</td>
                                                        : props.show_active === "mingguan" ?
                                                            <td>{moment(data.label).format("DD MMMM YYYY") + " - " + moment(data.label).add(6, 'days').format("DD MMMM YYYY")}</td> :
                                                            <td>{moment(data.label).format("DD MMMM YYYY")}</td>
                                                }
                                                <td className="right bg-1">{data.Diterima}</td>
                                                <td className="right">{data.Ditolak}</td>
                                                <td className="right">{data.Dibatalkan}</td>
                                                <td className="right">{data.Dikomplain}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Transaksi;