import React, { Component } from 'react';
import Swal from 'sweetalert2'

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      alreadyOK: false,
      alreadyPopUp: false,
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch('//google.com', {
              mode: 'no-cors',
              })
            .then(() => {
              this.setState({ isDisconnected: false }, () => {
                return clearInterval(webPing)
              });
            }).catch(() => this.setState({ isDisconnected: true }) )
          }, 5000);
        return;
      }

      return this.setState({ isDisconnected: true });
    }

    callNotif(){
        this.setState({
          alreadyPopUp: true
        })
        Swal.mixin({
            confirmButtonText: 'Ok',
            imageUrl: process.env.PUBLIC_URL + '/assets/images/icon/offline.svg',
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'No Internet Connection'
        }).fire({
            'title': 'Ups! koneksi internet buruk',
            'text': 'Coba lagi ya, cek koneksi internet kamu.'
        }).then(() => {
          this.setState({
            alreadyOK: true,
          })
        })
    }

    render() {
      const { isDisconnected, alreadyOK, alreadyPopUp } = this.state;
      return (
        <div>
          <img src={process.env.PUBLIC_URL + '/assets/images/icon/offline.svg'} style={{ display: 'none' }} alt="No Internet Connection"/>
          { (isDisconnected && !alreadyOK && !alreadyPopUp) && (this.callNotif()) }
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}