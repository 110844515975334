import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import {
    fetchHeaderShop
} from "../../actions/shopActions";

import {
    Footer,
    Filter,
    NewHeader,
    ListCategory,
    Product,
    EmptyResult,
    LoadingInfiniteScroll,
    LodingSideCategory
} from '../../components';

import HeaderBanner from "../../components/Shop/_SingleShop/HeaderBanner";
import { toggleModalLogin } from "../../actions/registerActions";
import { fetchCategoryWithSub } from "../../actions/categoryActions";
import Searh_result from '../../assert/icon/empty-result.png';
import { 
    postProductsWithCategory,
    onLikeProduct, 
    fetchProductByDomainShop,
    postMoreProducts 
} from "../../actions/productActions";
import InfiniteScroll from 'react-infinite-scroll-component';
import { 
    subScribeShop,
    unsubScribeShop
} from '../../actions/userAction';

import {
    get,
    AlertWarning,
    authPost,
    Toast,
    authGet,
    authPostPaginate
} from '../../helper';

import _ from 'lodash';
import CategoryLoading from '../../components/Loading/CategoryLoading';
import NetworkDetector from './NetworkDetector';


export class SingleShop extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            category_name : '',
            sub_category_name : '',
            category : 0,
            sub_category : 0,
            sort_id : 5,
            max: '',
            min : '',
            kondisi : {
                1 : true,
                2 : true
            },
            shipment : [],
            shipmentChecked : {},
            dataPersist : {},
            diskonCheked : false,
            products: [],
            products_pagination: {},
            loadingProduct: false,
            loadingButton: false,
        }
    }

    componentWillMount(){
        this.props.fetchCategoryWithSub();
        let params = this.props.match.params;
        authGet(`get_shop_by_domain/${params.shop}`, this.props.userData.token)
        .then(res => {
            if(res.data.redirect){
                this.props.history.push("/");
            }else{
                this.props.fetchHeaderShop(res.data.data);
                this.setDataPost({domain_shop: params.shop});
                get(`get_shipment_detail`)
                .then(res => {
                    let shipmentChecked = {};
                    _.each(res.data, d => {
                        shipmentChecked = {...shipmentChecked, [d.id] : true};
                    })

                    this.setState({shipmentChecked, shipment : res.data, loadingPage: false});
                })
            }
        })
    }

    setDataPost = (data) => {
        this.setState({dataPersist : data, loadingProduct: true}
        ,() => {
            // this.props.postProductsWithCategory(data)
            authPost(data, 'product_with_category', this.props.userData.token).then(res => {
                this.setState({
                    products: res.data.data,
                    products_pagination: res.data.meta.pagination,
                    loadingProduct: false
                });
            })
        })
    }

    onClickLikeButton (product, likeActive) {
        if(this.props.userData.token){
            this.setState({loadingButton: true});
            if(!likeActive){
                /* INSERT LIKE TABLE */
                
                let data = {
                    product_id : product.id,
                    user_id: this.props.userData.user,
                    action: "INSERT" 
                }

                this.props.onLikeProduct(data, this.props.userData.token).then(() => {
                    this.setState({loadingButton: false}, () => { 
                        Toast.fire({
                            text: "Barang berhasil ditambahkan ke daftar favorite",
                            type: "success"
                        });                        
                    });
                });

            }else{
                /* REMOVE FROM LIKE TABLEE */

                let data = {
                    id : likeActive.id,
                    user_id: this.props.userData.user,
                    action: "DELETE" 
                }

                this.props.onLikeProduct(data, this.props.userData.token).then(() => {
                    this.setState({loadingButton: false}, () => { 
                        Toast.fire({
                            text: "Barang berhasil dihapus dari daftar favorite",
                            type: "success"
                        });                        
                    });
                });
            }
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    handleCategory(id, name, category){
        this.setState({
            sub_category : id,
            sub_category_name : name,
            category : category.id,
            category_name: category.name
        },
        () => {
            this.setDataPost({
                ...this.state.dataPersist,
                id : this.state.category,
                sub_id : this.state.sub_category
            })
        })
    }

    onSubscribe = (e, shop_id) => {
        e.preventDefault();
        if(this.props.userData.user){
            this.setState({loadingButton: true})

            let data = {shop_id}

            this.props.subScribeShop(data, this.props.userData.token, this.props.lng).then(() => {
                this.setState({loadingButton: false})
            });

        }else{
            this.props.toggleModalLogin(true);
        }
    }

    unSubscribe = (e, shop_id) => {
        e.preventDefault();
        this.setState({loadingButton: true})
        if(this.props.userData.user){

            let data = {shop_id}

            this.props.unsubScribeShop(data, this.props.userData.token, this.props.lng).then(() => {
                this.setState({loadingButton: false})
            });

        }else{
            this.props.toggleModalLogin(true);
        }
    }

    userSubsricbe(data_user, shop_id){
        if(data_user.subscribes){
            return data_user.subscribes.find(subscribe => subscribe.shop_id === shop_id)
        }

        return false;
    }

    thisMyshop(data_user, domain){
        if(data_user.shop){
            return data_user.shop.domain === domain
        }

        return false;
    }

    onChangefilterHarga = (value, name) =>{
        this.setState({[name] : value})
    }

    checkedKondisi = (e) => {
        let kon = parseInt(e.target.value)
        this.setState({
            kondisi : {
                ...this.state.kondisi,
                [kon] : !this.state.kondisi[kon]
            }
        })
    }

    onChangeShipment = (item) => {
        this.setState({
            shipmentChecked : {
                ...this.state.shipmentChecked,
                [item.id] : !this.state.shipmentChecked[item.id]
            }
        })
    }

    filterProduct = () => {
        let {
            dataPersist,
            min,
            max,
            kondisi,
            shipmentChecked,
            diskonCheked,
        } = this.state;

        if(parseInt(min) > parseInt(max)){
            AlertWarning.fire({text : "Nilai min tidak boleh lebih besar dari max"});
            return false;
        }

        let data = {
            ...dataPersist,
            min,
            max,
            kondisi : _.keys(_.pickBy(kondisi)),
            shipment :  _.keys(_.pickBy(shipmentChecked)),
        }

        if(diskonCheked){
            data = {...data, diskon : diskonCheked}
        }

        this.setDataPost(data);
    }

    resetFilterProduct = (e) => {
        e.preventDefault();
        let kondisi = {1: true, 2: true};
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = {...shipmentChecked, [d.id] : true};
        })

        this.setState({
            min : "", 
            max: "", 
            kondisi, 
            shipmentChecked, 
            diskonCheked : false,
            category : 0,
            sub_category : 0,
        },() => {
            let { params } = this.props.match;
            this.setDataPost({domain_shop: params.shop});
        })
    }

    sortProduct = (e) => {
        this.setState({sort_id : e.target.value}
        ,() => {
            let { dataPersist } = this.state;

            let data = {
                ...dataPersist,
                sort_id : this.state.sort_id
            };

            this.setDataPost(data);
        })
    }

    fetchMoreData = () => {
        let { next } = this.state.products_pagination.links;
        let { dataPersist } = this.state;

        // this.props.postMoreProducts(next, dataPersist);
        authPostPaginate(dataPersist, next, this.props.userData.token).then(res => {
            this.setState({
                products: [...this.state.products, ...res.data.data],
                products_pagination: res.data.meta.pagination,
            })
        })
        // setTimeout(() => {
        // }, 1000);
    }

    hasMoreData = () => !(this.state.products.length === (this.state.products_pagination ? this.state.products_pagination.total : -1 ));
    
    
    render() {
    let domain = this.props.match.params.shop;

    let Page = (
        <div>
            <HeaderBanner 
                {...this.porps}
                loadingButton={this.state.loadingButton}
                onSubscribe={this.onSubscribe}
                unSubscribe={this.unSubscribe}
                isMyShop={this.thisMyshop(this.props.userData, domain)}
                userSubsricbe={this.userSubsricbe(this.props.userData, this.props.shop.id)}
                />
            <div className="container">
                <div className="left-side">
                    <div className="category-menu">
                        <h2>KATEGORI</h2>
                        {
                            this.props.loadingCategory
                            ? <LodingSideCategory/>
                            : this.props.categories.map(category => (
                                <ListCategory
                                    parentActive={this.state.category}
                                    changeCategory={() => this.handleCategory(null, null, category)}
                                    key={category.id} 
                                    active={this.state.sub_category}
                                    subActive={this.state.sub_category}
                                    onClick={(id, name, category) => this.handleCategory(id, name, category)}
                                    {...category} />
                            ))
                        }
                    </div>
                        <Filter 
                            maxValue={this.state.max}
                            minValue={this.state.min}
                            kondisi={this.state.kondisi}
                            shipments={this.state.shipment}
                            shipmentChecked={this.state.shipmentChecked}
                            diskonChecked={this.state.diskonCheked}
                            onChangeHarga={this.onChangefilterHarga}
                            checkedKondisi={this.checkedKondisi}
                            onChangeShipment={this.onChangeShipment}
                            onChangeDiskon={() => this.setState({diskonCheked : !this.state.diskonCheked})}
                            filterProduct={this.filterProduct}
                            resetFilterProduct={this.resetFilterProduct}
                            />
                </div>
                <div className="category-detail">
                    <div className="sorting-input">
                        <select className="form-control" value={this.state.sort_id} onChange={this.sortProduct}>
                            <option value="2">Termurah</option>
                            <option value="3">Termahal</option>
                            <option value="4">Terlaris</option>
                            <option value="5">Populer</option>
                            <option value="6">Terbaru</option>
                        </select>
                        <label>Urutkan</label>
                    </div>
                    <nav aria-label="breadcrumb">
                        {
                            this.state.category > 0
                            ? <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/category/${this.state.category}`}>{this.state.category_name}</Link></li>
                                {
                                    this.state.sub_category_name
                                    ? <li className="breadcrumb-item active" aria-current="page">{this.state.sub_category_name}</li>
                                    : null
                                }
                            </ol>
                            : <p>&nbsp;</p>
                        }
                    </nav>
                    <div className="clearfix"></div>
                    <h3>{this.state.sub_category_name}</h3>
                    <div className="product-container">
                        {
                            this.state.loadingProduct
                            ? <div style={{position:"relative", top: '200px'}}><LoadingInfiniteScroll/></div>
                            : this.state.products.length > 0
                            ? <InfiniteScroll
                                dataLength={this.state.products.length}
                                next={this.fetchMoreData}
                                hasMore={this.hasMoreData()}
                                loader={<LoadingInfiniteScroll/>}
                                >
                                    {
                                        this.state.products.map(product => 
                                            <Product 
                                                key={product.id}
                                                onClickLikeButton={(product, likeActive) => this.onClickLikeButton(product, likeActive)}
                                                likes={this.props.likes ? this.props.likes : []}
                                                loadingButton={this.state.loadingButton}
                                                {...product} 
                                            />
                                        )
                                    }
                            </InfiniteScroll>
                            : <EmptyResult 
                                image={Searh_result}
                                message="Tidak ada barang"
                                buttonText={0}
                                />
                        }
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
            {this.hasMoreData() ? null : <Footer/>}
            </div>
    );
    
    return (
        <div style={{marginTop: "62px"}}>
            <NewHeader 
                {...this.props}
                stateLogin = {this.props.userData.user}
                toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
            <CSSTransition
                in={this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
                    <CategoryLoading />
            </CSSTransition>
            <CSSTransition
                in={!this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
                    {Page}
            </CSSTransition>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories : state.categories.category_and_sub,
        loadingCategory : state.loading.loadingCategoryWithSub,
        products : state.products.items,
        pagination: state.products.pagination,
        loadingProduct: state.loading.loadingProduct,
        likes : state.user.user.product_favorite,
        userData : state.user.user,
        shop: state.shop.item
    }
}


export default NetworkDetector(connect(mapStateToProps, {
    fetchHeaderShop,
    toggleModalLogin,
    fetchCategoryWithSub,
    fetchProductByDomainShop,
    postProductsWithCategory,
    onLikeProduct,
    postMoreProducts,
    subScribeShop,
    unsubScribeShop
})(SingleShop));
