import { createSelector } from 'reselect';

const selectCart = state => state.carts;

const groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const selectCartHidden = createSelector(
    [selectCart],
    cart => cart.hidden
);


export const selectCartItems = createSelector(
    [selectCart],
    cart => cart.cartItems
);


export const selectGroupingItems = createSelector(
    [selectCartItems],
    cartItems => groupBy(cartItems, 'shop_id')
);


export const selectShopCart = createSelector(
    [selectCartItems],
    cartItems => {
        let obj;
        cartItems.map(cartItem => 
            obj = { [cartItem.shop_id] : cartItem.shop_name, ...obj }
        )
        return obj;
    }
);

export const selectCartTotal = createSelector(
    [selectCartItems],
    cartItems => cartItems.reduce(
        (accumalatedQuantity, cartItem) =>
            accumalatedQuantity + (cartItem.checked && cartItem.status === 1 ? cartItem.sub_total : 0 ),
        0
    )
);

export const selectCartTotalDiscount = createSelector(
    [selectCartItems],
    cartItems => cartItems.reduce(
        (accumalatedQuantity, cartItem) =>
            accumalatedQuantity + (cartItem.checked && cartItem.status === 1 ? cartItem.sub_diskon : 0),
        0
    )
);

