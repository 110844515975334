import { 
    ADD_TO_CART,
    REMOVE_ITEM,
    REMOVE_ITEMS,
    // SUB_QUANTITY,
    // ADD_QUANTITY,
    // ADD_SHIPPING ,
    TOGGLE_CART_HIDDEN,
    CHANGE_QUANTITY_PRODUCT,
    CHANGE_CHECKED_ITEM,
    CHANGE_CHECKED_SHOP,
    REMOVE_SHOP_ITEM,
    ADD_TO_CART_API,
    SYNC_CART,
    CLEAR_CART
} from "../actions/types/cartType";
// import { FETCH_PRODUCTS } from '../actions/types';
import { 
    addItemToCart, 
    removeItemCart, 
    removeItemsCart, 
    updateItemToCart,
    updateCheckedShopToCart,
    updateCheckedToCart,
    removeShopItemCart,
    syncItemCart
} from './cart.util'
// import _ from 'lodash';

const initialState = {
    items: [
        // {id: 1, store_name: "Japfa", store_id: 1, url_photo: "store1.png", product_name: "Layer Pre Starter G PRE Fine Crumble"}
    ],
    addedItems: [],
    cartItems: [],
    total: 0,
    total_discount : 0,
    hidden: true,
    groupingItem : [],
    shop: {},
    checkedShop : {}
}

export default function(state = initialState, action){
    switch (action.type) {
        case TOGGLE_CART_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden
            };
        case ADD_TO_CART:
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload),
            }
        case ADD_TO_CART_API:
            return {
                ...state,
                cartItems: action.payload
            }
        case SYNC_CART:
            return {
                ...state,
                cartItems: syncItemCart(state.cartItems ,action.payload)
            }
        case CHANGE_QUANTITY_PRODUCT:
            return {
                ...state,
                cartItems: updateItemToCart(state.cartItems, action.payload),
            }
        case CHANGE_CHECKED_ITEM:
            return {
                ...state,
                cartItems: updateCheckedToCart(state.cartItems, action.payload),
            }
        case CHANGE_CHECKED_SHOP:
                return {
                    ...state,
                    cartItems: updateCheckedShopToCart(state.cartItems, action.payload),
                }
        case REMOVE_ITEM:
                return {
                    ...state,
                    cartItems: removeItemCart(state.cartItems, action.payload),
                }
        case REMOVE_ITEMS:
                return {
                    ...state,
                    cartItems: removeItemsCart(state.cartItems, action.payload),
                }
        case REMOVE_SHOP_ITEM:
                return {
                    ...state,
                    cartItems: removeShopItemCart(state.cartItems, action.payload),
                }
        // case FETCH_PRODUCTS:
        //     return {
        //         ...state,
        //         items: action.payload
        //     }
        case CLEAR_CART:
            return {
                ...state,
                cartItems: []
            }
        default:
            return state;
    }
}


