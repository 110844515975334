import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
    NewHeader,
    Product,
    Filter,
    EmptyResult,
} from '../../../components';
import {
    onLikeProduct,
    searchFavouriteProduct,
    postMoreProductsAuth,
    postFavoriteProduct
} from '../../../actions/productActions';
import AuthService from '../../../actions/API/AuthService'
import {
    //authGet,
    get,
    AlertError,
    AlertWarning,
    Toast
} from '../../../helper';

import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingInfiniteScroll from '../../../components/Loading/LadingInfiniteScroll'
import Favourite_result from '../../../assert/icon/empty-favorite.png'
import FavouriteLoading from '../../../components/Loading/FavouriteLoading';
import { withNamespaces } from 'react-i18next'
import i18n from '../../../i18n';
import NetworkDetector from '../../PUBLIC/NetworkDetector'

import _ from 'lodash';
class Favourite extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            loadingPage: true,
            product: [],
            min: "",
            max: "",
            lockFilter: true,
            sort_id: 1,
            kondisi: {
                1: true,
                2: true
            },
            shipment: [],
            shipmentChecked: {},
            dataPersist: {},
            diskonCheked: false,
            meta: {},
            products: [],
            loadingButton: false,

        }
        this.Auth = new AuthService();
    }

    componentDidMount(){
        // authGet("favourite", this.props.userData.token).then(res => {
        //     this.setState({
        //         products: res.data.data,
        //         meta: res.data.meta.pagination
        //     });
        // });
        this.props.postFavoriteProduct({}, this.props.userData.token)
        .then(() => {
            get(`get_shipment_detail`)
                .then(res => {
                    let shipmentChecked = {};
                    _.each(res.data, d => {
                        shipmentChecked = { ...shipmentChecked, [d.id]: true };
                    })
    
                    this.setState({ shipmentChecked, shipment: res.data, loadingPage: false });
                })
                .catch(err => {
                    AlertError.fire({ text: err.response.data.message });
                })
        })

    }

    setDataPost = (data) => {
        let { token } = this.props.userData;
        this.setState({ dataPersist: data }
            , () => {
                this.props.searchFavouriteProduct(data, token);
            })
    }

    onChangefilterHarga = (value, name) => {
        this.setState({ [name]: value })
    }

    onClickLikeButton(product, likeActive) {
        let { token } = this.props.userData;
        if (this.props.userId) {
            this.setState({ loadingButton: true });
            if (!likeActive) {
                /* INSERT LIKE TABLE */

                let data = {
                    product_id: product.id,
                    user_id: this.props.userId,
                    action: "INSERT"
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.props.postFavoriteProduct({}, this.props.userData.token)
                    this.setState({ loadingButton: false }, () => { 
                        Toast.fire({
                            text: i18n.t("Favourite:tambah"),
                            type: "success"
                        });                        
                    })
                });

            } else {
                /* REMOVE FROM LIKE TABLEE */

                let data = {
                    id: likeActive.id,
                    user_id: this.props.userId,
                    action: "DELETE"
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.props.postFavoriteProduct({}, this.props.userData.token)
                    this.setState({ loadingButton: false }, () => { 
                        Toast.fire({
                            text: i18n.t("Favourite:hapus"),
                            type: "success"
                        });                        
                    })
                });
            }
        } else {
            this.props.toggleModalLogin(true);
        }
    }

    onChangefilterHarga = (value, name) => {
        this.setState({ [name]: value })
    }

    onChangeShipment = (item) => {
        this.setState({
            shipmentChecked: {
                ...this.state.shipmentChecked,
                [item.id]: !this.state.shipmentChecked[item.id]
            }
        })
    }

    resetFilterProduct = (e) => {
        e.preventDefault();
        let kondisi = { 1: true, 2: true };
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = { ...shipmentChecked, [d.id]: true };
        })

        this.setState({ min: "", max: "", kondisi, shipmentChecked, diskonCheked: false },
            () => {
                let keyword = this.props.prevKeyword;

                if (keyword === "") {
                    keyword = this.props.match.params.keyword;
                }

                let data = {
                    keyword
                }
                this.setDataPost(data);
            })
    }

    clearFilterPage = () => {
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = { ...shipmentChecked, [d.id]: true };
        });

        let data = {
            max: "",
            min: "",
            sort_id: 1,
            kondisi: {
                1: true,
                2: true
            },
            shipmentChecked,
            diskonCheked: false
        }
        this.setState(data, () => {
            this.setDataPost({ keyword: this.props.prevKeyword });
        })
    }

    sortProduct = (e) => {
        this.setState({ sort_id: e.target.value }
            , () => {
                // let keyword = this.props.prevKeyword;

                // if(keyword === ""){
                //     keyword = this.props.match.params.keyword;
                // }

                // let kondisi = _.keys(_.pickBy(this.state.kondisi));
                // let shipment = _.keys(_.pickBy(this.state.shipmentChecked));

                // let data = {
                //     keyword,
                //     min : this.state.min,
                //     max : this.state.max,
                //     sort_id : this.state.sort_id,
                //     kondisi,
                //     shipment
                // }

                // if(this.state.diskon){
                //     data = {...data, diskon : this.state.diskon}
                // }

                let { dataPersist } = this.state;
                let data = { ...dataPersist, sort_id: this.state.sort_id }

                this.setDataPost(data);
            })
    }

    checkedKondisi = (e) => {
        let kon = parseInt(e.target.value)
        this.setState({
            kondisi: {
                ...this.state.kondisi,
                [kon]: !this.state.kondisi[kon]
            }
        })
    }

    filterProduct = () => {
        let keyword = this.props.prevKeyword;
        let kondisi = _.keys(_.pickBy(this.state.kondisi));
        let shipment = _.keys(_.pickBy(this.state.shipmentChecked));

        if (keyword === "") {
            keyword = this.props.match.params.keyword;
        }

        if (parseInt(this.state.min) > parseInt(this.state.max)) {
            AlertWarning.fire({ text: i18n.t("Favourite:validation.minmax") });
            return false;
        }

        this.setState({ lockFilter: false }
            , () => {
                let data = {
                    keyword,
                    min: this.state.min,
                    max: this.state.max,
                    sort_id: this.state.sort_id,
                    kondisi,
                    shipment
                }

                if (this.state.diskonCheked) {
                    data = { ...data, diskon: this.state.diskonCheked }
                }
                this.setDataPost(data);
            })
    }

    fetchMoreData = () => {
        let { next } = this.props.pagination.links;
        let { dataPersist } = this.state;

        this.props.postMoreProductsAuth(next, dataPersist, this.props.userData.token);
    }

    hasMoreData = () => !(this.props.products.length === (this.props.pagination ? this.props.pagination.total : -1));

    render() {
        //const totalProudcts = this.props.pagination ? this.props.pagination.total : 0;
        const countProudcts = this.props.pagination ? this.props.pagination.count : 0;
        let Page = (
            <div className="container" style={{ marginTop: "62px" }}>
                <div className="left-side">
                    <Filter
                        onChangeHarga={this.onChangefilterHarga}
                        maxValue={this.state.max}
                        minValue={this.state.min}
                        filterProduct={this.filterProduct}
                        resetFilterProduct={this.resetFilterProduct}
                        kondisi={this.state.kondisi}
                        checkedKondisi={this.checkedKondisi}
                        shipments={this.state.shipment}
                        shipmentChecked={this.state.shipmentChecked}
                        onChangeShipment={this.onChangeShipment}
                        diskonChecked={this.state.diskonCheked}
                        onChangeDiskon={() => this.setState({ diskonCheked: !this.state.diskonCheked })} />
                </div>
                <div className="category-detail">
                    <div className="sorting-input">
                        <select className="form-control" value={this.state.sort_id} onChange={this.sortProduct}>
                            <option value="1">{i18n.t("Favourite:urutkan_barang.sesuai")}</option>
                            <option value="2">{i18n.t("Favourite:urutkan_barang.termurah")}</option>
                            <option value="3">{i18n.t("Favourite:urutkan_barang.termahal")}</option>
                            <option value="4">{i18n.t("Favourite:urutkan_barang.terlaris")}</option>
                            <option value="5">{i18n.t("Favourite:urutkan_barang.populer")}</option>
                            <option value="6">{i18n.t("Favourite:urutkan_barang.terbaru")}</option>
                        </select>
                        <label>{i18n.t("Favourite:urutkan")}</label>
                    </div>
                    <h3>{i18n.t("Favourite:favorit_title")}</h3>
                    <p className="search-summary">{i18n.t("Favourite:menampilkan.menampilkan")} {countProudcts} {i18n.t("Favourite:menampilkan.barang_favorit")}</p>
                    <div className="clearfix"></div>

                    <div className="product-container" style={{ overflow: "auto" }}>

                        {
                            this.props.loadingProduct
                                ? <div><LoadingInfiniteScroll/></div>
                                : countProudcts > 0
                                    ? <InfiniteScroll
                                        dataLength={this.props.products.length}
                                        next={this.fetchMoreData}
                                        hasMore={this.hasMoreData()}
                                        loader={<LoadingInfiniteScroll />}
                                    >
                                        {this.props.products.map(data => {
                                            return (
                                                <Product
                                                    key={data.id}
                                                    onClickLikeButton={(product, likeActive) => this.onClickLikeButton(product, likeActive)}
                                                    likes={this.props.likes ? this.props.likes : []}
                                                    loadingButton={this.state.loadingButton}
                                                    {...data}
                                                />
                                            )
                                        })}
                                    </InfiniteScroll>
                                    : <EmptyResult
                                        image={Favourite_result}
                                        message={i18n.t("Favourite:no_barang")}
                                        buttonText={0}
                                    />
                        }
                        {/* {this.state.products.map(data => {
                            return (
                                <Product
                                    key={data.id}
                                    onClickLikeButton={(product, likeActive) => this.onClickLikeButton(product, likeActive)}
                                    likes={this.props.likes ? this.props.likes : []}
                                    {...data}
                                />
                            )
                        })} */}
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        )
        return (
            <div>
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userId}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    clearFilterPage={this.clearFilterPage}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    <FavouriteLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products.items,
        userData: state.user.user,
        userId: state.user.user.user,
        pagination: state.products.pagination,
        likes: state.user.user.product_favorite,
        loadingProduct: state.loading.loadingProduct,
    }
}

export default NetworkDetector(withNamespaces("Favourite")(connect(mapStateToProps, {
    searchFavouriteProduct,
    onLikeProduct,
    postMoreProductsAuth,
    postFavoriteProduct
})(Favourite)))