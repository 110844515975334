import React from 'react'

const InfoAlamat = (props) =>
(
    <div>
        <div className="form-subtitle">
            <h2>INFORMASI ALAMAT ASAL PENGIRIMAN</h2>
        </div>
        <div className="mb30">
            <div className="form-group-div">
                <div className="row">
                    <div className="col-md-7">
                        <label>Barang Tersedia di Alamat Pengiriman <span className="red">*</span></label>
                    </div>
                    {/* <div className="col-md-4">
                        <label>Tersedia Pick Up</label>
                    </div> */}
                </div>
                {
                    props.addresses.map((item) => (
                        <div className="row" key={item.id}>
                            <div className="col-md-7">
                                <div className="box-outline mb0">
                                    <table cellPadding="10">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input 
                                                        name="alamat" 
                                                        type="checkbox" 
                                                        value={item.id} 
                                                        onChange={(e) => props.onChange(e, item)} 
                                                        checked={props.data[item.id]}/>
                                                </td>
                                                <td>
                                                    <p><b>{item.name}</b></p>
                                                    <p>{item.district}, {item.city}, {item.zip_portal}</p>
                                                    <p className="mb0">{item.address}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="toggle-input">
                                    <div className="toggle-option active">Ya</div>
                                    <div className="toggle-option">Tidak</div>
                                </div>
                            </div> */}
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
)


export default InfoAlamat;
