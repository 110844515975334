export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_MORE_PRODUCTS = 'FETCH_MORE_PRODUCTS';
export const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';
export const ON_CHANGE_PRODUCT = 'ON_CHANGE_PRODUCT';
export const ON_DROP_PICTURE = "ON_DROP_PICTURE";
export const ON_CHANGE_PRODUCT_HARGA_GROSIR = "ON_CHANGE_PRODUCT_HARGA_GROSIR";
export const ON_CLICK_PRODUCT_HARGA_GROSIR = "ON_CLICK_PRODUCT_HARGA_GROSIR";
export const ON_REMOVE_HARGA_GROSIR = "ON_REMOVE_HARGA_GROSIR";
export const ON_CHANGE_STATUS_HARGA = "ON_CHANGE_STATUS_HARGA";
export const ON_CHECK_ALAMAT = "ON_CHECK_ALAMAT";
export const ON_CHECK_PENGIRIMAN = "ON_CHECK_PENGIRIMAN";
export const GET_SHOP_BY_PRODUK_ID = "GET_SHOP_BY_PRODUK_ID";
export const SELECT_PRODUCT_MAGNIFY = "SELECT_PRODUCT_MAGNIFY";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const GET_PRODUCT_SHIPMENT = "GET_PRODUCT_SHIPMENT";
export const CHANGE_DISKON = "CHANGE_DISKON";
export const ON_SEACRH = "ON_SEACRH";
export const SAVE_KEYWORD = "SAVE_KEYWORD";
export const FETCH_PRODUCT_RATING = "FETCH_PRODUCT_RATING";
export const ON_INCREMENT = "ON_INCREMENT";
export const ON_DECREMENT = "ON_DECREMENT";
export const ON_CHANGE_QUANTITY = "ON_CHANGE_QUANTITY";
export const RESET_CREATE_PRODUCT = "RESET_CREATE_PRODUCT";
export const CHANGE_TEMP_PRICE = "CHANGE_TEMP_PRICE";
export const UPDATE_LIKES_PRODUCT_DETAIL = "UPDATE_LIKES_PRODUCT_DETAIL";