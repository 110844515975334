import React from 'react'
import NumberFormat from 'react-number-format'
import { withNamespaces } from 'react-i18next';
import _ from 'lodash'

const Filter = props => {
    const { t } = props;
    return (
        <>
        <h2>{t("Filter:filter")}</h2>
        <div className="filter-menu">
            <div className="mb20">
        
                <label>{t("Filter:rentang_harga")}</label>
                <div className="price-range">
                    <div className="input">
                        <NumberFormat
                            name="min"
                            type="text"
                            className="form-control"
                            placeholder="Min"
                            value={props.minValue}
                            displayType={'input'}
                            thousandSeparator="."
                            decimalSeparator=","
                            onValueChange={(values) => props.onChangeHarga(values.value, "min")}
                            />
                    </div>
                    <div className="separator">-</div>
                    <div className="input">
                        <NumberFormat
                            type="text"
                            className="form-control"
                            placeholder="Max"
                            value={props.maxValue}
                            displayType={'input'}
                            thousandSeparator="."
                            decimalSeparator=","
                            onValueChange={(values) => props.onChangeHarga(values.value, "max")}
                            />
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>

            <div className="mb20">
                <h3>{t("Filter:kondisi.title")}</h3>
                <label className="pull-left mr20">
                    <input 
                        name="baru"
                        type="checkbox" 
                        value="1" 
                        checked={props.kondisi[1]}
                        onChange={props.checkedKondisi}/> {t("Filter:kondisi.baru")}
                </label>
                <label className="">
                    <input 
                        name="bekas"
                        type="checkbox" 
                        value="2"
                        checked={props.kondisi[2]}
                        onChange={props.checkedKondisi}/> {t("Filter:kondisi.bekas")}
                </label>
            </div>

            <div className="mb20">
                <h3>{t("Filter:jasa_pengiriman")}</h3>
                <table width="100%" cellPadding="3">
                    <tbody>
                    {
                        props.shipments.map(shipment => (
                            <tr key={shipment.id}>
                                <td>
                                    <input 
                                        type="checkbox"
                                        checked={props.shipmentChecked[shipment.id]}
                                        onChange={() => props.onChangeShipment(shipment)}
                                        />
                                </td>
                                <td className="w50 pl10">
                                    <img className="w40 mr5" src={shipment.image} alt="shipment"/>
                                </td>
                                <td>{shipment.name}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <div className="mb20">
                <h3>{t("Filter:diskon.title")}</h3>
                <label className="form-check">
                    <input 
                        type="checkbox"
                        value="1"
                        checked={props.diskonChecked}
                        onChange={props.onChangeDiskon}/> {t("Filter:diskon.checkbox")}
                </label>
            </div>            
            {
                props.spesifikasis ? 
                _.orderBy(props.spesifikasis, 'attrs_count', 'desc').map(spesifikasi => (
                        <div className="mb20" key={spesifikasi.id}>
                        <h3>{spesifikasi.name}</h3>
                        <table width="100%" cellPadding="3">
                            <tbody>
                            {
                                _.orderBy(spesifikasi.attrs, 'count', 'desc').map(attr => (
                                    <tr key={attr.id}>
                                        <td className="w25">
                                            <input 
                                                type="checkbox"
                                                checked={props.isSpesifikasiChecked(attr.id)}
                                                onChange={() => props.onChangeSpesifikasi(attr.id)}
                                                />
                                        </td>
                                        <td>{attr.name} ({attr.count})</td>
                                    </tr>
                                ))
                            }
                            {spesifikasi.show_more ? <tr><td colSpan="2"><a href="/#" onClick={(e) => props.loadSpesifikasiLainnya(e,spesifikasi.id)}>Lainnya</a></td></tr> : null}
                            </tbody>
                        </table>
                    </div>
                ))
                : null
            }
        </div>
        <div className="mb20 text-center">
            <button className="btn btn-block btn-solid primary" onClick={props.filterProduct}>{t("Filter:terapkan")}</button>
            <br/>
            <a href="/#" onClick={props.resetFilterProduct}>{t("Filter:reset")}</a>
        </div>
    </>
    )
}

export default withNamespaces("Filter")(Filter)
