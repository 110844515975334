import { TOGGLE_MODAL_LOGIN } from "../actions/types/registerType";

const initialState = {
    toggle: false
}

export default function(state = initialState, action){
    switch (action.type) {
        case TOGGLE_MODAL_LOGIN:    
            return {
                ...state,
                toggle: action.payload
            }
        default:
            return state;
    }
}