import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 

import { connect } from 'react-redux';
import classNames from 'classnames';

import { 
    getInvoices,
    getMoreInvoices
} from '../../../actions/transactionAction';

import {
    FormatUang,
    AlertError,
} from '../../../helper';

import {
    NewHeader,
    EmptyResult,
} from '../../../components';
import {
    toggleModalLogin
} from '../../../actions/registerActions';

import imageEmpty from '../../../assert/icon/empty-transaction.png';
import { CSSTransition } from 'react-transition-group';
import ReactLoading from 'react-loading';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import id from "date-fns/locale/id";

import NumberFormat from 'react-number-format';
import * as moment from "moment";
import TransactionListLoading from '../../../components/Loading/TransactionListLoading';
import NetworkDetector from '../../PUBLIC/NetworkDetector';
registerLocale("id", id);
const Listing = props =>
(
    <tr>
        <td>{props.tanggal}</td>
        <td>{props.no_transaksi}</td>
        <td>Rp <FormatUang value={props.total}/></td>
        <td>{props.name}</td>
        <td>
            <ul className="transaction-step sm">
                {
                    props.prosess.map(proses => (
                        <li className={proses.active ? `active ${proses.cancel ? "cancel" : ""}` : ""} key={proses.id}>
                            <div className="round-icon">
                                <img src={proses.image} alt="Dibayar" />
                            </div>
                        </li>
                    ))
                }
            </ul>
        </td>
        <td><Link to={props.link} className="btn btn-outline primary">Lihat Detail</Link></td>
    </tr>
)

class TransactionList extends Component {

    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            loadData: false,
            tab : 1,
            datas: [],
            pagination: {},
            loadingMoreData: false,
            start_periode: '',
            end_periode: '',
            invoice_status_id: 0,
            keyword: '',
            min_harga: '',
            max_harga: ''
        }
    }

    componentWillMount(){
        let { token } = this.props.userData;

        getInvoices({
            flag: 1,
            invoice_status_id: 0
        }, token)
        .then(res => {
            this.setState({
                datas : res.data.data, 
                loadingPage: false,
                pagination : res.data.meta.pagination
            });
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
        })
    }

    setTab(e, tab){
        e.preventDefault();
        let { token } = this.props.userData;
        this.setState({tab, loadData: true},
        () => {
            this.setState({
                keyword: "",
                invoice_status_id: 0,
                min_harga: "",
                max_harga: "",
                start_periode: "",
                end_periode: ""
            }, () => {
                getInvoices({
                    flag: this.state.tab,
                    invoice_status_id: this.state.invoice_status_id
                }, token)
                .then(res => {
                    this.setState({
                        datas : res.data.data,
                        loadData: false,
                        pagination : res.data.meta.pagination
                    });
                })
                .catch(err => {
                    AlertError.fire({text : err.response.data.message});
                    this.setState({loadData: false});
                })
            })
        });
    }

    filterListingData = (e) => {
        e.preventDefault();
        let data = {
            flag : this.state.tab,
            invoice_status_id: this.state.invoice_status_id,
            keyword: this.state.keyword,
            start_periode: this.state.start_periode !== '' ? moment(this.state.start_periode).format("YYYY-MM-D") : '',
            end_periode: this.state.end_periode !== '' ? moment(this.state.end_periode).format("YYYY-MM-D") : '',
            min_harga: this.state.min_harga,
            max_harga: this.state.max_harga,
        }

        this.setState({loadData: true});
        getInvoices(data, this.props.userData.token)
        .then(res => {
            this.setState({
                datas : res.data.data,
                loadData: false,
                pagination : res.data.meta.pagination
            });
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
            this.setState({loadData: false});
        })
    }

    resetListingData = (e) => {
        e.preventDefault();
        this.setState({
            keyword: '',
            start_periode: '',
            end_periode: '',
            invoice_status_id: 0,
            min_harga: '',
            max_harga: '',
            loadData: true
        }, () => {
            getInvoices({
                flag: this.state.tab,
                invoice_status_id: 0
            }, this.props.userData.token)
            .then(res => {
                this.setState({
                    datas : res.data.data,
                    loadData: false,
                    pagination : res.data.meta.pagination
                });
            })
            .catch(err => {
                AlertError.fire({text : err.response.data.message});
                this.setState({loadData: false});
            })
        })
    }

    loadMoreData = () => {
        let { next } = this.state.pagination.links;
        this.setState({loadingMoreData: true});
        getMoreInvoices({
            flag : this.state.tab,
            invoice_status_id: this.state.invoice_status_id,
            keyword: this.state.keyword,
            start_periode: this.state.start_periode !== '' ? moment(this.state.start_periode).format("YYYY-MM-D") : '',
            end_periode: this.state.end_periode !== '' ? moment(this.state.end_periode).format("YYYY-MM-D") : '',
            min_harga: this.state.min_harga,
            max_harga: this.state.max_harga
        }, next, this.props.userData.token)
        .then(res => {
            this.setState({
                datas : [
                    ...this.state.datas,
                    ...res.data.data,
                ],
                pagination : res.data.meta.pagination,
                loadingMoreData: false
            })
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
            this.setState({loadingMoreData: false});
        })
    }

    hasMoreData = () => !(this.state.datas.length === (this.state.pagination ? this.state.pagination.total : -1 ));


    render() {


        return (
            <div className="mg-top">
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <TransactionListLoading />
                </CSSTransition>

                <CSSTransition
                in={!this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
            <div className="container-fluid">
            <div className="transaction-container">
                <div className="filter-left">
                    <h2>CARI TRANSAKSI</h2>
                    <div className="mb20">
                        <h3>Cari</h3>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Kata kunci" 
                            value={this.state.keyword}
                            onChange={(e) => this.setState({keyword: e.target.value})}
                            />
                    </div>
                    <div className="mb20">
                        <h3>Tanggal</h3>
                        <div className="date-range">
                            <div className="pull-left w45p">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-calendar-alt"></i></span>
                                        <DatePicker
                                            selected={this.state.start_periode}
                                            customInput={<input type="text" className="form-control datepicker" />}
                                            onChange={date => this.setState({start_periode : date})}
                                            placeholderText="Periode awal"
                                            showYearDropdown
                                            showMonthDropdown
                                            dateFormat="d MMMM yyyy"
                                            dropdownMode="select"
                                            locale={id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <span className="pull-left text-center w10p">-</span>
                            <div className="pull-left w45p">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-calendar-alt"></i></span>
                                        <DatePicker
                                            selected={this.state.end_periode}
                                            customInput={<input type="text" className="form-control datepicker" />}
                                            onChange={date => this.setState({end_periode : date})}
                                            placeholderText="Periode akhir"
                                            showYearDropdown
                                            showMonthDropdown
                                            dateFormat="d MMMM yyyy"
                                            dropdownMode="select"
                                            locale={id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="mb20">
                        <h3>Status</h3>
                        <select className="form-control" onChange={(e) => this.setState({invoice_status_id: e.target.value})} value={this.state.invoice_status_id}>
                            <option value={0}>Semua</option>
                            <option value={1}>Dipesan</option>
                            <option value={3}>Dibayar</option>
                            <option value={4}>Diproses</option>
                            <option value={5}>Dikirim</option>
                            <option value={6}>Diterima</option>
                            <option value={7}>Dibatalkan</option>
                            <option value={8}>Dikomplain</option>
                            <option value={9}>Selesai</option>
                        </select>
                    </div>
                    <div className="mb20">
                        <h3>Rentang Harga</h3>
                        <div className="price-range">
                            <div className="input">
                                <NumberFormat
                                    type="text"
                                    className="form-control"
                                    placeholder="Min"
                                    value={this.state.min_harga}
                                    displayType={'input'}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    onValueChange={(values) => this.setState({min_harga: values.value})}
                                    />
                            </div>
                            <div className="separator">-</div>
                            <div className="input">
                                <NumberFormat
                                    type="text"
                                    className="form-control"
                                    placeholder="Max"
                                    value={this.state.max_harga}
                                    displayType={'input'}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    onValueChange={(values) => this.setState({max_harga: values.value})}
                                    />
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <a href="/#" className="btn btn-solid btn-block primary" onClick={this.filterListingData}>Cari</a>
                    <a href="/#" className="block text-center mt10" onClick={this.resetListingData}>Reset</a>
                </div>
                    <div className="transaction-main basic-container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"><i className="fas fa-home mr0"></i></Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Transaksi</li>
                            </ol>
                        </nav>
                        <br />
                        <h1 className="page-title">Transaksi</h1>
                        <div className="transaction-tab">
                            <ul>
                                <li className={classNames({'active' : this.state.tab === 1})}><a href="/#" onClick={(e) => this.setTab(e, 1)}>Pembelian</a></li>
                                {
                                    this.props.userData.shop
                                    ? <li className={classNames({'active' : this.state.tab === 2})}><a href="/#" onClick={(e) => this.setTab(e, 2)}>Penjualan</a></li>
                                    : null
                                }
                            </ul>
                        </div>
                        <div className="mt10">
                        {
                            this.state.datas.length > 0
                            ? this.state.loadData
                                ? <p>Loading..</p>
                                :<table className="table table-product">
                                <thead>
                                    <tr>
                                        <th className="w75">Tanggal</th>
                                        <th>Nomor Transaksi</th>
                                        <th>Total Belanja</th>
                                        {
                                            this.state.tab === 1
                                            ? <th>Nama Toko</th>
                                            : <th>Nama Pembeli</th>
                                        }
                                        <th className="w350">Status</th>
                                        <th className="w100"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.datas.map(data => (
                                            <Listing 
                                                key={data.id}
                                                tab={this.state.tab}
                                                {...data}
                                                />
                                        ))
                                    }
                                    {
                                        this.state.loadData
                                        ? null
                                        : this.hasMoreData()
                                        ? <tr>
                                            <td colSpan="5">
                                                <div className="text-center">
                                                    {
                                                        this.state.loadingMoreData
                                                        ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                                        : <button className="btn btn-solid primary" onClick={this.loadMoreData}>Transaksi Lainnya</button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        :null
                                    }
                                    
                                </tbody>
                                </table>
                            : <EmptyResult
                                image={imageEmpty}
                                message="Tidak ada transaksi"
                                buttonText={0}
                                />
                        }
                        </div>
                    </div>
                </div>
                </div>
            </CSSTransition>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData : state.user.user
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    toggleModalLogin
})(TransactionList));