import React, { Component } from 'react';
import Logo from '../../logo.png';
import {
    get,
    AlertError,
    post,
    Toast
} from '../../helper';
import ReactLoading from 'react-loading';
import NetworkDetector from '../PUBLIC/NetworkDetector';

const Loading = (props) => (
    <div className={props.className} style={props.style}>
        <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} />
    </div>
)


class ResetPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            loadingPage : true,
            password: "",
            confirm_password: "",
            msgPassword: "",
            validPassword : false,
            msgPasswordConfirm: "",
            validPasswordConfirm : false,
            loadingButton : false
        }
    }

    componentDidMount(){
        get(`find_token/${this.props.match.params.token}`)
        .then(res => {
            this.setState({...res.data, loadingPage : false});
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message})
            .then(() => {
                this.props.history.push('/');
            })
        })
    }

    handleChangePassword = (e) => {
        this.setState({
            password : e.target.value,
            msgPassword : "",
            validPassword: false,
        });
    }

    handleChangePasswordConfirm = (e) => {
        this.setState({
            confirm_password : e.target.value,
            msgPasswordConfirm : "",
            validPasswordConfirm: false,
        });
    }

    onBlurPassword = (e) => {
        if(e.target.value.length < 6){
            if(e.target.value !== ""){
                this.setState({
                    msgPassword : "Password minimal 6 karakter"
                })
            }
        }
        else{
            this.setState({
                validPassword : true,
                msgPassword : ""
            })
        }
    }

    onBlurPasswordConfirm = (e) => {
        if(e.target.value !== this.state.password){
            if(e.target.value !== ""){
                this.setState({
                    msgPasswordConfirm : "Password konfirmasi tidak cocok"
                })
            }
        }
        else{
            this.setState({
                validPasswordConfirm : true,
                msgPasswordConfirm : ""
            })
        }
    }

    isValid(){
        return this.state.validPassword && this.state.validPasswordConfirm
    }

    resetPassword = (e) => {
        e.preventDefault();
        let data = {
            email : this.state.email,
            token : this.props.match.params.token,
            password : this.state.password
        }

        this.setState({loadingButton : true})
        post(data, `reset_password`)
        .then(res => {
            Toast.fire({
                type : "success",
                title: "Data berhasil di rubah"
            }).then(result => {
                this.setState({loadingButton : false})
                this.props.history.push('/');
            })
        })
        .catch(err => {
            this.setState({loadingButton : false});
            AlertError.fire({text : err.response.data.message});
        })
    }


    render() {

        if(this.state.loadingPage){
            return <p>Memuat...</p>
        }
        else{

        return (
            <div className="container-fluid w100p">
                <div className="clearfix h100p w100p">
                    <div className="register-side-left">
                        <h1>Reset Password</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <div className="register-side-right">
                        <div className="text-center logo">
                            <img src={Logo} alt="logo"/>
                        </div>
                        <h2>RESET PASSWORD</h2>
                        <form className="auth-form">
                            <div className="form-group">
                                <input 
                                    type="password" 
                                    name="password"
                                    onChange={this.handleChangePassword}
                                    value={this.state.password}
                                    onBlur={this.onBlurPassword}
                                    />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Password Baru</label>
                                <div className="red">{this.state.msgPassword}</div>
                            </div>
                            <div className="form-group">
                                <input 
                                    type="password" 
                                    name="confirm_password"
                                    onChange={this.handleChangePasswordConfirm}
                                    value={this.state.confirm_password}
                                    onBlur={this.onBlurPasswordConfirm}
                                    />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Konfirmasi Password</label>
                                <div className="red">{this.state.msgPasswordConfirm}</div>
                            </div>

                            <div className="text-center">
                                {
                                    this.state.loadingButton
                                    ? <Loading className="loading-btn mb50"/>
                                    : <button 
                                        type="submit" 
                                        className={`btn btn-solid primary btn-register ${this.isValid() ? "" : "disabled"}`}
                                        onClick={this.resetPassword}
                                        >Reset Password</button>
                                }
                                
                                <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                            </div>
                        </form>
                    </div>
                </div>
		</div>
        )
    }
}
}


export default NetworkDetector(ResetPassword);