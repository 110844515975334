import axios from 'axios'
import { 
    FETCH_CATEGORIES, 
    FETCH_CATEGORY, 
    FETCH_CATEGORY_HEADER, 
    FETCH_CATEGORY_WITH_SUB,
    FETCH_SUB_CATEGORY,
    ON_CHANGE_CATEGORY_FILTER,
    ON_CHANGE_SUB_CATEGORY_FILTER,
    SET_CATEGORY_HEADER,
    SET_SUB_CATEGORY_HEADER
} from "./types/categoryTipe";
import { GET_STATUS_CATEGORY, GET_STATUS_SIDE_CATEGORY } from "./types/loadingType";
import { API_URL } from "../../src/config";

export const fetchCategories = () => dispatch => {
    dispatch({
        type: GET_STATUS_CATEGORY,
        payload: true
    });
    return axios.get(`${API_URL}/category`)
        .then(res =>{
            dispatch({
                type: FETCH_CATEGORIES,
                payload: res.data.data
            });
            dispatch({
                type: GET_STATUS_CATEGORY,
                payload: false
            });
        }
        
        
    )
}


export const fetchAllSubCategories = () => dispatch => {

    return axios.get(`${API_URL}/sub_category`)
        .then(res =>{
            dispatch({
                type: FETCH_SUB_CATEGORY,
                payload: res.data
            });
        }
    )
}

export const fetchCategory = (params) => dispatch => {
    dispatch({
        type: GET_STATUS_CATEGORY,
        payload: true
    });
    return axios.post(`${API_URL}/get_category_by_id`, params)
        .then(res => {
            dispatch({
                type: FETCH_CATEGORY,
                payload: res.data.data
            })
            dispatch({
                type: GET_STATUS_CATEGORY,
                payload: false
            });
        })
}

export const fetchCategoryHeader = (category_id, sub_id = null) => dispatch => {
    dispatch({
        type: GET_STATUS_CATEGORY,
        payload: true
    });
    let url = sub_id != null ? `${API_URL}/category/${category_id}/${sub_id}` : `${ API_URL }/category/${ category_id }` ;
    axios.get(url)
        .then(res =>{
            dispatch({
                type: FETCH_CATEGORY_HEADER,
                payload: res.data.data
            });
            dispatch({
                type: GET_STATUS_CATEGORY,
                payload: false
            });
        })

}

export const fetchCategoryWithSub = () => dispatch => {
    dispatch({
        type: GET_STATUS_SIDE_CATEGORY,
        payload: true
    });
    axios.get(`${API_URL}/category_with_sub`)
        .then(res =>{
            dispatch({
                type: FETCH_CATEGORY_WITH_SUB,
                payload: res.data.data
            });
            dispatch({
                type: GET_STATUS_SIDE_CATEGORY,
                payload: false
            });
        })

}

export const getSubCategory = (id) => dispatch => {
    
    axios.get(`${API_URL}/get_sub_category/${id}`)
        .then(res =>{
            dispatch({
                type: FETCH_SUB_CATEGORY,
                payload: res.data
            });
        })

}

export const fetchSubCategory = (category, sub) => dispatch => {
    
    axios.get(`${API_URL}/category_with_sub/${category}/${sub}`)
        .then(res => {
            dispatch({
                type: FETCH_CATEGORY_WITH_SUB,
                payload: res.data.data
            });
        })

}


export const onChangeCategory = data => dispatch => {
    dispatch({
        type: ON_CHANGE_CATEGORY_FILTER,
        payload: data
    })
}

export const onChangeSubCategory = data => dispatch => {
    dispatch({
        type: ON_CHANGE_SUB_CATEGORY_FILTER,
        payload: data
    })
}

export const setCategoryWithSub = data => dispatch => {
    dispatch({
        type: SET_CATEGORY_HEADER,
        payload: data
    })
}

export const setSubCategory = data => dispatch => {
    dispatch({
        type: SET_SUB_CATEGORY_HEADER,
        payload: data
    })
}