import React from 'react'
import ContentLoader from "react-content-loader";

export default function WalletLoading() {
    return (
        <div className="container-fluid">
        <div className="basic-container">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    
                </ol>
            </nav>
            <br />
            <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
            <rect x="0" y="0" rx="0" ry="0" width="76" height="10"/>
            </ContentLoader>   
            <div className="w20p pull-left">
            <ContentLoader 
            height={220}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="380" height="200"/>
            </ContentLoader>
            </div>
            <div className="w75p pull-left">
            <ContentLoader 
            height={20}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="51" height="10"/>
            </ContentLoader>    
                <div className="content-tab">
                    <ul id="dompet_tab">
                    <ContentLoader 
                            height={15}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                            </ContentLoader>  
                    </ul>
                    <div className="content-tab-detail">
                    <ContentLoader 
                            height={130}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="12" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="24" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="36" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="48" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="60" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="72" rx="0" ry="0" width="400" height="10"/>
                            <rect x="0" y="84" rx="0" ry="0" width="400" height="13"/>
                        </ContentLoader> 
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    </div>
    )
}
