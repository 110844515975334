import React, { Component } from 'react';
import { connect } from "react-redux";

import BukaToko from "../../../../assert/files/illustration/toko_vip.svg";
import KTP from "../../../../assert/files/illustration/ktp.svg";
import SWAFOTO_KTP from "../../../../assert/files/illustration/swafoto_ktp.svg";
import NPWP from "../../../../assert/files/illustration/npwp.svg";
import SWAFOTO_NPWP from "../../../../assert/files/illustration/swafoto_npwp.svg";

import { CSSTransition } from 'react-transition-group';

import {
    NewHeader,
    Footer,
    LoadingLock,
    // MapComponent    
} from '../../../../components';

import {
    setUserShopData
} from '../../../../actions/userAction';
import {
    toggleModalLogin
} from '../../../../actions/registerActions';
import { 
    getMyShop
} from "../../../../actions/shopActions";
import NumberFormat from 'react-number-format';
import {
    authGet, 
    authPost,
    AlertError,
} from '../../../../helper';
import ReactLoading from 'react-loading';
import Geocode from "react-geocode";
import { API_GMAPS_KEY } from '../../../../config';
import CreateShopLoading from '../../../../components/Loading/CreateShopLoading';
import NetworkDetector from '../../../PUBLIC/NetworkDetector';
// import { LoadScript } from '@react-google-maps/api';
// const libraries = ['places'];
Geocode.setApiKey(API_GMAPS_KEY);
Geocode.enableDebug();
Geocode.setLanguage("id");
Geocode.setRegion("id");
const Loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

const NewStep1 = props => { 
    let inputKtp = null;
    let inputSwafotoktp = null;

    const uploadKTPClick = e => {
        e.preventDefault();
        inputKtp.click();
        return false;
      };
    const uploadSwafotoKTPClick = e => {
        e.preventDefault();
        inputSwafotoktp.click();
        return false;
      };
       
    return (
    <div>
        <div className="center-div" data-id="1">
            <img src={BukaToko} className="mb30" alt="Buat Toko"/>
            <h1>TOKO VIP</h1>
            <p className="desc-text">Jadikan toko Anda menjadi Toko VIP dan nikmati fitur-fitur terbaik kami<br /> khusus untuk toko VIP.</p>

            <div className="text-left">
                <div className="form-group-div">
                    <label>NIK Penanggung Jawab Toko<span className="red"> *</span></label><br/>
                    <div className="relative">
                        <input 
                            type="text" 
                            name="nik" 
                            className={`form-control `} 
                            placeholder="Tulis NIK Penanggung Jawab Toko"
                            onChange={props.onChangeStep1NIK}
                            value={props.nik}
                        />
                        <div className="red absolute fs11" style={{ bottom: "-15px" }}>{props.message_name}</div>
                    </div>
                </div>               
            </div>
            <div className="text-left">
                <div className="form-group-div">
                    <label>Foto KTP<span className="red"> *</span></label><br/>
                    <div className="row">
                        <div className="col-md-4">
                                            <div className="image-upload-div lg">
                                                
                                                    <div className="image-upload" 
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                            width: "156px",
                                                            height: "99px"
                                                        }}
                                                        onClick={uploadKTPClick}>
                                                            {
                                                                props.fileKtp ? 
                                                                <img src={props.fileKtp} alt="aksdaskl" style={{ width: "156px", height: "99px", objectFit: "cover" }} />
                                                                : <i className="fas fa-camera"></i>
                                                            }
                                                        </div>
                                                
                                            </div>
                                            <input 
                                                    type="file" 
                                                    style={{ display: 'none' }} 
                                                    ref={ktp => {
                                                        // assigns a reference so we can trigger it later
                                                        inputKtp = ktp;
                                                      }}
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={props.onChangeUploadKTP}
                                                    />
                        </div>
                        <div className="col-md-3 pull-right" style={{ marginLeft: "10px" }}>
                        <img src={KTP} className="mb30" alt="Buat Toko"/>
                        </div>
                        <div className="col-md-4 pull-right" style={{ marginLeft: "20px" }}>
                        <div className="image-upload-notes">
                            <p className="mb10">Contoh foto KTP.</p>
                        </div>
                        </div>
                    </div>
                </div>               
            </div>
            <div className="text-left">
                <div className="form-group-div">
                    <label>Swafoto KTP<span className="red"> *</span></label><br/>
                    <div className="row">
                    <div className="col-md-4">
                                            <div className="image-upload-div lg">
                                                
                                                    <div className="image-upload" 
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                            width: "156px",
                                                            height: "99px"
                                                        }}
                                                        onClick={uploadSwafotoKTPClick}>
                                                            {
                                                                props.fileSwafotoKtp ? 
                                                                <img src={props.fileSwafotoKtp} alt="aksdaskl" style={{ width: "156px", height: "99px", objectFit: "cover" }} />
                                                                : <i className="fas fa-camera"></i>
                                                            }
                                                        </div>
                                                
                                            </div>
                                            <input 
                                                    type="file" 
                                                    ref={swafotoktp => {
                                                        // assigns a reference so we can trigger it later
                                                        inputSwafotoktp = swafotoktp;
                                                      }}
                                                    style={{ display: 'none' }} 
                                                     accept=".png, .jpg, .jpeg"
                                                     onChange={props.onChangeUploadSwafotoKTP}/>
                        </div>
                        <div className="col-md-3 pull-right" style={{ marginLeft: "10px" }}>
                        <img src={SWAFOTO_KTP} className="mb30" alt="Buat Toko"/>
                        </div>
                        <div className="col-md-4 pull-right" style={{ marginLeft: "20px" }}>
                        <div className="image-upload-notes">
                            <p className="mb10">Contoh Swafoto KTP.</p>
                        </div>
                        </div>
                    </div>
                </div>               
            </div>
            {
                    props.loadingButton
                    ? <Loading /> :
                    <>
                        <button className="btn btn-solid darkgrey mxw200 btn-nav-bukatoko" onClick={(e) => { e.preventDefault(); window.location.href = "/myshop" }} style={{ marginRight: "10px" }}>Batal</button>
                        <button className="btn btn-solid primary mxw200 btn-nav-bukatoko" onClick={props.nextStep} id="next">Lanjutkan</button>
                    </>
            }            
        </div>
        <Footer/>
    </div>
    )
}
const NewStep2 = props => { 
    let inputNpwp = null;
    let inputSwafotoNpwp = null;

    const uploadNpwpClick = e => {
        e.preventDefault();
        inputNpwp.click();
        return false;
      };
    const uploadSwafotoNpwpClick = e => {
        e.preventDefault();
        inputSwafotoNpwp.click();
        return false;
      };
       
    return (
    <div>
        <div className="center-div" data-id="1">
            <img src={BukaToko} className="mb30" alt="Buat Toko"/>
            <h1>TOKO VIP</h1>
            <p className="desc-text">Jadikan toko Anda menjadi Toko VIP dan nikmati fitur-fitur terbaik kami<br /> khusus untuk toko VIP.</p>

            <div className="text-left">
                <div className="form-group-div">
                    <label>NPWP Penanggung Jawab Toko<span className="red"> *</span></label><br/>
                    <div className="relative">
                        {/* <input 
                            type="text" 
                            name="nik" 
                            className={`form-control `} 
                            placeholder="Tulis NPWP Penanggung Jawab Toko"
                            onChange={props.onChangeStep2NPWP}
                            value={props.npwp}
                        /> */}
                        <NumberFormat
                            type="text"
                            className="form-control"
                            placeholder="99.999.999.9-999.999"
                            value={props.npwp} 
                            displayType={'input'}
                            onValueChange={(values) => props.onChangeStep2NPWP(values.value)}
                            format="##.###.###.#-###.###"
                            />                        
                        <div className="red absolute fs11" style={{ bottom: "-15px" }}>{props.message_name}</div>
                    </div>
                </div>               
            </div>
            <div className="text-left">
                <div className="form-group-div">
                    <label>Foto NPWP<span className="red"> *</span></label><br/>
                    <div className="row">
                    <div className="col-md-4">
                                            <div className="image-upload-div lg">
                                                
                                            <div className="image-upload" 
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                            width: "156px",
                                                            height: "99px"
                                                        }}
                                                        onClick={uploadNpwpClick}>
                                                            {
                                                                props.fileNpwp ? 
                                                                <img src={props.fileNpwp} alt="aksdaskl" style={{ width: "156px", height: "99px", objectFit: "cover" }} />
                                                                : <i className="fas fa-camera"></i>
                                                            }
                                                        </div>
                                                
                                            </div>
                                            <input 
                                                    type="file" 
                                                    ref={npwp => {
                                                        // assigns a reference so we can trigger it later
                                                        inputNpwp = npwp;
                                                      }}
                                                    style={{ display: 'none' }} 
                                                     accept=".png, .jpg, .jpeg"
                                                     onChange={props.onChangeUploadNPWP}/>
                        </div>
                        <div className="col-md-3 pull-right" style={{ marginLeft: "10px" }}>
                        <img src={NPWP} className="mb30" alt="Buat Toko"/>
                        </div>
                        <div className="col-md-4 pull-right" style={{ marginLeft: "20px" }}>
                        <div className="image-upload-notes">
                            <p className="mb10">Contoh foto NPWP.</p>
                        </div>
                        </div>
                    </div>
                </div>               
            </div>
            <div className="text-left">
                <div className="form-group-div">
                    <label>Swafoto NPWP<span className="red"> *</span></label><br/>
                    <div className="row">
                    <div className="col-md-4">
                    <div className="image-upload-div lg">
                                                
                                                <div className="image-upload" 
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                cursor: "pointer",
                                                                width: "156px",
                                                                height: "99px"
                                                            }}
                                                            onClick={uploadSwafotoNpwpClick}>
                                                                {
                                                                    props.fileSwafotoNpwp ? 
                                                                    <img src={props.fileSwafotoNpwp} alt="aksdaskl" style={{ width: "156px", height: "99px", objectFit: "cover" }} />
                                                                    : <i className="fas fa-camera"></i>
                                                                }
                                                            </div>
                                                    
                                                </div>
                                                <input 
                                                        type="file" 
                                                        ref={npwp => {
                                                            // assigns a reference so we can trigger it later
                                                            inputSwafotoNpwp = npwp;
                                                          }}
                                                        style={{ display: 'none' }} 
                                                         accept=".png, .jpg, .jpeg"
                                                         onChange={props.onChangeUploadSwafotoNPWP}/>
                        </div>
                        <div className="col-md-3 pull-right" style={{ marginLeft: "10px" }}>
                        <img src={SWAFOTO_NPWP} className="mb30" alt="Buat Toko"/>
                        </div>
                        <div className="col-md-4 pull-right" style={{ marginLeft: "20px" }}>
                        <div className="image-upload-notes">
                            <p className="mb10">Contoh Swafoto NPWP.</p>
                        </div>
                        </div>
                    </div>
                </div>               
            </div>
        {
                props.loadingButton
                ? <Loading /> :
                <>
                    <button className="btn btn-solid darkgrey mxw200 btn-nav-bukatoko" onClick={props.prevStep} style={{ marginRight: "10px" }}>Sebelumnya</button>
                    <button className="btn btn-solid primary mxw200 btn-nav-bukatoko" onClick={props.nextStep} id="next">Lanjutkan</button>
                </>
        }
        </div>
        <Footer/>
    </div>
    )
}

const FinalStep = () => (
    <div className="container p30 text-center" style={{ marginTop: "200px" }}>
            {/* <img src={Logo} alt="Agrinis" /><br /> */}
            <img src={BukaToko} alt="Verifikasi Email" />
            <br /><br />
            <h3 style={{ color:"#0C7F8F" }}>DAFTAR VIP DIAJUKAN</h3><br />
            <p className="fs15">Terima kasih telah mengajukan pendaftaran Toko VIP.<br />Pendaftaran sedang diproses, verifikasi akan dilakukan dalam 2 hari kerja.</p>
            <br /><br />
            <a href="/#" onClick={(e) => { e.preventDefault(); window.location.href = "/myshop" }} className="btn btn-solid primary">Kembali ke toko</a>
        </div>
        )

class Request extends Component {

    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            tab : 1,
            nik: '',
            fileKtp: null,
            logoKtp: 0,
            fileSwafotoKtp: null,
            logoSwafotoKtp: 0,
            npwp: '',
            fileNpwp: null,
            logoNpwp: 0,
            fileSwafotoNpwp: null,
            logoSwafotoNpwp: 0,
            loadingButton: false,
        };
    }
    
    componentWillMount(){
        this.props.getMyShop(this.props.userData.token);
        if (this.props.shop.is_vip) { 
            window.location.href = "/myshop/vip/list"
        } else {
            authGet('vip/check', this.props.userData.token).then(res => {
                if (parseInt(res.data.message) === 1) { 
                    this.setTab(3);
                } else if (parseInt(res.data.message) === 2) { 
                    window.location.href = "/myshop/vip/list"
                }
                this.setState({loadingPage: false})
            })
        }
    }

    /**
     * 
     * STEP 1
     */

    onChangeStep1NIK = (e) => {
        this.setState({
            nik : e.target.value,
        });
    }

    onChangeUploadKTP = (e) => { 
        this.setState({
            fileKtp: URL.createObjectURL(e.target.files ? e.target.files[0] : null),
            logoKtp: e.target.files ? e.target.files[0] : null
        });
    }
    onChangeUploadSwafotoKTP = (e) => { 
        this.setState({
            fileSwafotoKtp: URL.createObjectURL(e.target.files ? e.target.files[0] : null),
            logoSwafotoKtp: e.target.files ? e.target.files[0] : null
        });
    }

    setTab(tab){
        this.setState({tab});
    }

    handleTabStep1 = () => { 
        if (!this.state.nik) { 
            AlertError.fire("Error", "NIK Penanggung Jawab wajib diisi")
        }
        else if (this.state.nik.length < 16) { 
            AlertError.fire("Error", "NIK Penanggung Jawab wajib tidak valid")
        }
        else if (!this.state.fileKtp || this.state.logoKtp === 0) { 
            AlertError.fire("Error", "Foto KTP wajib diunggah")
        }
        else if (!this.state.fileSwafotoKtp || this.state.logoSwafotoKtp === 0) { 
            AlertError.fire("Error", "Swafoto Foto KTP wajib diunggah")
        } else { 
            this.setTab(2);
        }
    }

    /**
     * 
     * STEP 2
     */
    onChangeStep2NPWP = (value) => {
        this.setState({
            npwp : value,
        });
    }
    onChangeUploadNPWP = (e) => { 
        this.setState({
            fileNpwp: URL.createObjectURL(e.target.files ? e.target.files[0] : null),
            logoNpwp: e.target.files ? e.target.files[0] : null
        });
    }
    onChangeUploadSwafotoNPWP = (e) => { 
        this.setState({
            fileSwafotoNpwp: URL.createObjectURL(e.target.files ? e.target.files[0] : null),
            logoSwafotoNpwp: e.target.files ? e.target.files[0] : null
        });
    }

    handleTabStep2 = () => { 
        if (this.state.npwp === '') { 
            AlertError.fire("Error", "NPWP Penanggung Jawab wajib diisi")
        }
        else if (!this.state.fileNpwp || this.state.logoNpwp === 0) { 
            AlertError.fire("Error", "Foto NPWP wajib diunggah")
        }
        else if (!this.state.fileSwafotoNpwp || this.state.logoSwafotoNpwp === 0) { 
            AlertError.fire("Error", "Swafoto Foto NPWP wajib diunggah")
        } else { 
            this.store();
        }
    }

    store() { 
        this.setState({ loadingButton: true });
        let fd = new FormData();
        fd.append('nik', this.state.nik);
        fd.append('npwp', this.state.npwp);
        fd.append('foto_ktp', this.state.logoKtp);
        fd.append('swap_foto_ktp', this.state.logoSwafotoKtp);
        fd.append('foto_npwp', this.state.logoNpwp);
        fd.append('swap_foto_npwp', this.state.logoSwafotoNpwp);
        authPost(fd, 'vip/register', this.props.userData.token).then(res => {
            this.setTab(3);
            this.setState({ loadingButton: false });
        })
        .catch(err => {
            this.setState({ loadingButton: false });
            AlertError.fire({ text: err.response.data.message });
        })
    }

    render() {
        let Page = (
            <NewStep1
                onChangeUploadKTP={this.onChangeUploadKTP}
                onChangeUploadSwafotoKTP={this.onChangeUploadSwafotoKTP}
                onChangeStep1NIK={this.onChangeStep1NIK}
                nextStep={this.handleTabStep1}
                {...this.state}
                />
        )
        if(this.state.tab === 2){
            Page = (
                <NewStep2
                    onChangeUploadNPWP={this.onChangeUploadNPWP}
                    onChangeUploadSwafotoNPWP={this.onChangeUploadSwafotoNPWP}
                    onChangeStep2NPWP={this.onChangeStep2NPWP}
                    nextStep={this.handleTabStep2}
                    prevStep={() => this.setTab(1)}
                    {...this.state}
                />
            )
        }
        if(this.state.tab === 3){
            Page = (
                <FinalStep
                    handleSelectedFile={this.handleSelectedFile}
                    onChange={this.onChangeStep2}
                    prevStep={() => this.setTab(1)}
                    {...this.state}
                />
            )
        }

        return (
            <div className="container-fluid" style={{ marginTop: "100px" }}>
                {
                    this.state.loadingCreateToko
                    ? <LoadingLock/>
                    : null
                }
                
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <CreateShopLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {Page}
                    {/* {this.renderStep()} */}
                </CSSTransition>
            </div>
        )
    }
}

const mapStateToprops = (state) => {
    return {
        shop : state.shop.item,
        userData : state.user.user,
    }
}

export default NetworkDetector(connect(mapStateToprops,{
    setUserShopData,
    toggleModalLogin,
    getMyShop
})(Request));
