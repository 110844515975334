import React, { Component } from 'react';
import CartIcon from '../../assert/icon/cart.png';
import { connect } from "react-redux";
import _ from 'lodash';
import CartItem from './CartItem';
import { 
    selectGroupingItems, 
    selectCartHidden, 
    selectCartTotal,
    selectCartTotalDiscount,
    selectShopCart 
} from '../../selector/cartSelector'
import { 
    changeQuantity, 
    onCheckedItem, 
    onCheckedShop,
    removeItem,
    removeShopItem,
    addToCartWithApi,
    addToCartV2
} from '../../actions/cartActions';
import cartImage from '../../assert/icon/empty-cart.png';
import { removeShopItemCart, removeItemCart, addItemToCartV3, onChangeAddItemToCartV3 } from '../../reducers/cart.util';
import { toggleModalLogin } from "../../actions/registerActions";
import { 
    setDataCheckout ,
    setHistoryBrowser
} from '../../actions/transactionAction';

import {
    FormatUang,
    AlertWarning,
    AlertInfo,
    AlertError,
    ConfirmWarning,
    authPost,
    // post
} from '../../helper';
import ReactLoading from 'react-loading';
import { withNamespaces } from 'react-i18next';

class Cart extends Component {
    constructor(props){
        super(props);
        this.state = {
            quantity : 0,
            disableDec : true,
            loadingData: false
        }
    }

    onChange(e, item){
        let quantity = parseInt(e.target.value.replace(/[^\d]+/g,''));
        let sub_total = quantity * item.price
        let sub_diskon =  quantity * item.discount_price
        // this.setState({ quantity: validateValue });
        if(quantity < 1 || quantity === ''){
            this.props.changeQuantity({...item, quantity, sub_total, sub_diskon, disableDec : true})
        }else{
            this.props.changeQuantity({...item, quantity, sub_total, sub_diskon, disableDec : false})
        }
    }


    onDecrement(item){
        // const value = this.state.quantity === '' ? 0 : eval(this.state.quantity);
        let { quantity } = item;
        quantity = parseInt(quantity) - 1;
        let sub_total = quantity * item.price
        let sub_diskon =  quantity * item.discount_price
        if(quantity < 1 || quantity === ''){
            this.props.changeQuantity({...item, quantity, sub_total, sub_diskon, disableDec : true})
        }else{
            this.props.changeQuantity({...item, quantity, sub_total, sub_diskon, disableDec : false})
        }
    }

    onIncrement(item){
        let { quantity } = item;
        quantity = parseInt(quantity) + 1;
        let sub_total = quantity * item.price
        let sub_diskon = quantity * item.discount_price
        this.props.changeQuantity({...item, quantity, sub_total, sub_diskon, disableDec : false})
    }

    onChangeV2(e, item){
        let quantity = parseInt(e.target.value.replace(/[^\d]+/g,''));
        this.setValueOnChange(this.props.cartItems, {...item, quantity: !quantity ? 0 : quantity});
    }

    onBlurV2(e, item){
        let quantity = parseInt(e.target.value.replace(/[^\d]+/g,''));
        let value = quantity < item.min_order ? item.min_order : quantity;
        this.setValueOnChange(this.props.cartItems, {...item, quantity: value});
    }


    setValueOnChange(cartItems, item) {
        this.props.addToCartV2(onChangeAddItemToCartV3(cartItems, item));
    }

    onIncrementV2(item){
        this.props.addToCartV2(addItemToCartV3(this.props.cartItems, {...item, quantity: 1}));
    }

    onDecrementV2(item) {
        this.props.addToCartV2(addItemToCartV3(this.props.cartItems, {...item, quantity: -1}));
    }

    onRemoveItem(item, e){
        e.preventDefault();
        
        if(this.props.userData.token){
            ConfirmWarning.fire({
                text : "Apakah anda yakin ingin menghapus nya?"
            }).then(result => {
                if(result.value){
                    let data_cart = removeItemCart(this.props.cartItems, item);
                    let products = data_cart.map(product => {
                        return {
                            product_id : product.id,
                            quantity : product.quantity
                        }
                    } );
                    
                    authPost({ value: JSON.stringify(data_cart), products }, `cart`, this.props.userData.token)
                    .then(res => {
                        this.props.addToCartV2(res.data);
                    })
                    // this.props.addToCartWithApi(, this.props.userData.token)
                }
            })
        }else{
            AlertError.fire({text : "Bermasalah pada akun anda\nMohon login ulang untuk memproses nya"});
        }
        // this.props.removeItem(item);
    }

    onRemoveShopItem(id){
        if(this.props.userData.token){
            ConfirmWarning.fire({
                text : "Apakah anda yakin ingin menghapus nya?"
            }).then(result => {
                if(result.value){
                    let data_cart = removeShopItemCart(this.props.cartItems, id);
                    
                    let products = data_cart.map(product => {
                        return {
                            product_id : product.id,
                            quantity : product.quantity
                        }
                    } );
                    authPost({ value: JSON.stringify(data_cart), products }, `cart`, this.props.userData.token)
                    .then(res => {
                        this.props.addToCartV2(res.data);
                    })
                    // this.props.addToCartWithApi(removeShopItemCart(this.props.cartItems, id), this.props.userData.token)
                }
            })
        }else{
            AlertError.fire({text : "Bermasalah pada akun anda\nMohon login ulang untuk memproses nya"});
        }
        // this.props.removeShopItem(id);
    }

    onCheckedItem(item){
        this.props.onCheckedItem(item);
    }

    onCheckedShop(e, shop_id){
        this.props.onCheckedShop({checked : e.target.checked, shop_id})
    }
/**
 * 
 * UNTUK CHECKBOX 
 * 
    existShopCheked(id){
        let shopItems = this.props.groupingItem[id];
        return shopItems.reduce((acc , item) => acc + (item.checked ? 1 : 0), 0) === shopItems.length
    }
*/

    existShopCheked(id){
        let shopItems = this.props.groupingItem[id];
        return shopItems.reduce((acc , item) => acc + (item.checked ? 1 : 0), 0) > 0
    }

    checkOut = () => {
        let user = this.props.userData;
        if(user.token){
            if(user.email_verify !== null){
                if(this.props.total <= 0) {
                    AlertWarning.fire({text : "Tidak ada Barang yang dapat diproses"});
                    return false;
                }

                if(this.props.cartItems.length > 0){
                    /** 
                     *  SYARAT TERPENUHI
                     */
                    let products = this.props.cartItems.map(product => {
                        return {
                            product_id : product.id,
                            quantity : product.quantity
                        }
                    });
                    this.setState({loadingData: true})
                    authPost({value: JSON.stringify(this.props.cartItems), products}, `cart`, this.props.userData.token)
                    .then(res => {
                        this.props.addToCartV2(res.data);
                        this.setState({loadingData: false});
                    })
                    .then(() => {
                        let dataCheckout = this.props.cartItems.filter(cartItem => cartItem.checked);

                        if(dataCheckout.length > 0){
                            dataCheckout.map(data => ({...data , fadeOut: false}))
                            this.props.setHistoryBrowser(this.props.match.url);
                            this.props.setDataCheckout(dataCheckout);
                            setTimeout(() => {
                                this.props.history.push(`/transaction/create`);
                            },500)
                        }else{
                            // alert('Pilih item pada keranjang');
                            AlertWarning.fire({text : "Pilih item pada keranjang"});
                        }
                    })
                    .catch(err => {
                        this.setState({loadingData: false}, () => {
                            AlertError.fire({text: err.response.data.message});
                        })
                    })
                    
                }else{
                    // alert("Minimal beli 1 barang!");
                    AlertWarning.fire({text : "Minimal beli 1 barang!"});
                }
            }else{
                // alert("Belum Verifikasi Email");
                AlertInfo.fire({text : 'Kamu Harus Verifikasi Email terlebih dahulu'});
            }
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    render() {
        // let hide = this.props.hidden ? "none" : '';
        let styleHidden = {display: 'none'}
        const { t } = this.props;

        return (
            // <div className="cart-container" style={{display: hide}}>
            <div className="cart-container" style={styleHidden}>
                <div className="cart-header"><img src={CartIcon} alt="agrinis"/> {t("keranjang.title")}</div>
                {
                    this.state.loadingData
                    ? <div className="cart-body" style={{height:"100%"}}>
                        <div style={{
                            height:"100%", 
                            display:"flex", 
                            justifyContent:"center", 
                            alignItems: "center",
                            flexDirection: "column"
                            }}>
                            <ReactLoading type="spinningBubbles" color="#0C7F8F" />
                            <p className="mt20">{t("keranjang.sinkron")}</p>
                        </div>
                        
                    </div>
                    : <div className="cart-body">
                    {
                        _.size(this.props.groupingItem) > 0
                        ?
                        _.map(this.props.groupingItem, (items, key) => (
                        <div key={key}>
                            <div className="cart-list-header">
                                <a href="/#" className="pull-right" onClick={(e) => {e.preventDefault();this.onRemoveShopItem(key)}}><i className="fas fa-trash"></i></a>
                                <label className="form-check" style={{display: "inline-block"}}>
                                    {/* <input type="radio" name="shop" value={key} checked={this.existShopCheked(key)} className="mr5" onChange={(e) => this.onCheckedShop(e, key)}/> */}
                                    <input type="radio" name="shop" value={key} className="mr5" checked={this.existShopCheked(key)} onChange={(e) => this.onCheckedShop(e, key)}/>
                                    {this.props.shop[key]}
                                </label>
                            </div>
                            {
                                items.map(item => (
                                    <CartItem 
                                        key={item.id} 
                                        quantity={item.quantity} 
                                        onChange={(e) => this.onChangeV2(e, item)}
                                        onIncrement={() => this.onIncrementV2(item)}
                                        onDecrement={() => this.onDecrementV2(item)}
                                        onCheckedItem={() => this.onCheckedItem(item)}
                                        checked={item.checked}
                                        onRemoveItem={(e) => this.onRemoveItem(item, e)}
                                        onBlur={(e) => this.onBlurV2(e, item)}
                                        {...item}
                                    />
                                ))
                            }
                            <hr/>
                        </div>
                        ))
                        :   
                        <div className="empty-cart">
                            <img src={cartImage} alt="empty cart" />
                            <p className="text-center">{t("keranjang.kosong")}</p>
                        </div>
                    }
    
                    </div>
                }
                
                <div className="cart-footer">
                    {
                        this.props.total_discount > 0
                        ? <div className="footer-1">
                            {t("keranjang.hemat")} <span className="pull-right">
                                Rp <FormatUang value={this.props.total_discount}/>
                            </span>
                        </div>
                        : <div className="footer-1">&nbsp;</div>
                    }
                    <button className="footer-2 btn btn-block btn-solid primary" onClick={this.checkOut}>
                        <span className="pull-left">{t("keranjang.proses")}</span>
                        <span className="pull-right">
                            Rp <FormatUang value={this.props.total}/>
                        </span>
                    </button>
                </div>
            </div>
        )
        
    }
}

const mapStateToProps = (state)=>{
    return {
        cartItems : state.carts.cartItems,
        hidden : selectCartHidden(state),
        groupingItem : selectGroupingItems(state),
        shop : selectShopCart(state),
        total : selectCartTotal(state),
        total_discount : selectCartTotalDiscount(state),
        checkedShop : state.carts.checkedShop,
        userData : state.user.user
    }
    
};

export default withNamespaces()(connect(mapStateToProps, {
    changeQuantity, 
    onCheckedItem, 
    onCheckedShop,
    removeItem,
    removeShopItem,
    addToCartWithApi,
    toggleModalLogin,
    setDataCheckout,
    setHistoryBrowser,
    addToCartV2
})(Cart));
