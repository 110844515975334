import React from 'react';
import Logo from '../../logo.png';
import {
    post,
    AlertError,
    Toast
} from '../../helper';
import ReactLoading from 'react-loading';
import NetworkDetector from '../PUBLIC/NetworkDetector'

const Loading = (props) => (
    <div className={props.className} style={props.style}>
        <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} />
    </div>
)

class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email : '',
            validEmail: false,
            msg : "",
            loadingButton: false
        }
    }

    sendResetPassword = (e) => {
        e.preventDefault();
        this.setState({loadingButton : true});
        post({email : this.state.email}, `create_reset_pw`)
        .then(res => {
            Toast.fire({
                type : "success",
                text : res.data.message
            }).then(result => {
                this.setState({loadingButton : false});
                window.location.href = "/";
            })
        })
        .catch(err => {
            this.setState({loadingButton : false});
            AlertError.fire({text : err.response.data.message});
        })
    }

    onBlurEmail = (e) => {
        if(this.validateEmail(e.target.value)){
            this.setState({
                validEmail: true,
            });
        }else{
            if(e.target.value !== ""){
                this.setState({msg: "Email tidak valid!"})
            }
        }
    }


    validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render(){
        return(
            <div className="container-fluid w100p">
                <div className="clearfix h100p w100p">
                    <div className="register-side-left">
                        <h1>Lupa Password?</h1>
                        <p>Silahkan masukkan email yang terdaftar.<br />Kami akan segera mengirim email untuk mengatur ulang kata sandi anda.</p>
                    </div>
                    <div className="register-side-right">
                        <div className="text-center logo">
                            <img src={Logo} alt="logo"/>
                        </div>
                        <h2>RESET PASSWORD</h2>
                        <form className="auth-form">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    value={this.state.email}
                                    onChange={(e) => this.setState({email: e.target.value, msg : "", validEmail: false})}
                                    onBlur={this.onBlurEmail}
                                    />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Email</label>
                                <div className="red">{this.state.msg}</div>
                            </div>

                            <div className="text-center">
                                {
                                    this.state.loadingButton
                                    ? <Loading className="loading-btn mb50"/>
                                    : <button 
                                        type="submit" 
                                        className={`btn btn-solid primary btn-register ${!this.state.validEmail ? "disabled" : ""}`}
                                        onClick={this.sendResetPassword}
                                        >
                                        Kirim
                                    </button>
                                }
                                
                                <p className="footer-text">&copy; {(new Date().getFullYear())} Agrinis</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default NetworkDetector(ForgotPassword)