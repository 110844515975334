import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import InfoBarang from "../../../../components/Product/_CreateProuct/InfoBarang";
import InfoHarga from '../../../../components/Product/_CreateProuct/InfoHarga';
import InfoAlamat from '../../../../components/Product/_CreateProuct/InfoAlamat';

import {
    NewHeader,
    Footer,
    LoadingLock
} from '../../../../components';

import axios from 'axios';
import { API_URL } from '../../../../config'
import {
    fetchCategories,
    fetchAllSubCategories
} from "../../../../actions/categoryActions";
import {
    fecthShopAddress,
    fecthShopShipment
} from "../../../../actions/shopActions";
import {
    storeProduct,
    resetCreateProduct
} from "../../../../actions/productActions";
import {
    toggleModalLogin
} from "../../../../actions/registerActions";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    AlertWarning,
    ConfirmInfo,
    Toast,
    AlertError,
    authGet,
    authPost,
} from '../../../../helper'
import _ from 'lodash';
import CreateProductLoading from '../../../../components/Loading/CreateProductLoading';
import NetworkDetector from "../../../PUBLIC/NetworkDetector";
import Fuse from "fuse.js";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 50,
  maxPatternLength: 12,
  minMatchCharLength: 2,
  keys: ["label"]
};

class CreateProduct extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            progress : 0,

            pictures: [],

            infoBarang:{
                nama: '',
                subtitle: '',
                category_id: '0',
                detail_category_id: '0',
                kondisi : 1,
                berat: '',
                deskripsi: "",
                spesifikasi: []
            },

            inforHarga : {
                harga: '',
                harga_vip: '',
                min_order: 1,
                harga_grosir: '',
                harga_grosir_vip: '',
                min: 2,
                max: '',
                disabled: false,
                disabledMinOrder: false,
                hargaGrosirs: [],
                ppn: false
            }, 
            grosir: false,
            spesifikasi: false,

            categories :[],
            detail_categories: [],
            current_detail_categories: [],

            addresses: [],
            checkedAddress: {},

            shipments: [],
            checkedShipment: {},

            master_spesifikasi: [],
            master_spesifikasi_detail: [],
            master_spesifikasi_selected: {
                value: null,
                label: null,
                is_new: false,
                is_new2: false //untuk nampilin input field
            },
            master_spesifikasi_detail_selected: {
                value: null,
                label: null,
                is_new: false,
                master_spesifikasi: {}
            },

            produsens: [],
            productInternal: [],
            productInternalAsync: [],
            productInternalAsync_temp: [],
            productInternalSelected: 0,
            productInternalSelectedAsync: "",
            dataProductInternalSelected: {
                images: [],
                produsen:{
                    id: 0
                }
            },
            loadingProductInternal: false,
            rd_gambar: undefined,
            disabledProdusen: false,
            isAssociated: false,
            is_vip: false,
            loadingDataSpesifikasi: false,
            loadingSpesifikasi: false
        }
    }

    componentWillMount(){
        const { token } = this.props.userData;
        authGet(`product/create`, token)
        .then(res => {
            let tempChecked = {};
            _.forEach(res.data.addresses, item => {
                tempChecked[item.id] = true;
            });


            let tempCheckedShipment = {};
            _.forEach(res.data.shipments, item => {
                tempCheckedShipment[item.id] = true;
            });

            this.setState({
                loadingPage : false,
                ...res.data,
                checkedAddress: tempChecked,
                checkedShipment: tempCheckedShipment,
                current_detail_categories: res.data.detail_categories
            })
        })
        // this.props.fetchCategories()
        // .then(() => {
        //     this.props.fetchAllSubCategories()
        //     .then(() => {
        //         this.props.fecthShopAddress(token);
        //         this.props.fecthShopShipment(token);
        //         this.setState({loadingPage : false})
        //     })
        //     .catch(err => {
        //         AlertError.fire({text : "Terjadi Kesalahan"});
        //     })
            
        // })
        // .catch(err => {
        //     AlertError.fire({text : "Terjadi Kesalahan"});
        // })
    }

    /**
     * 
     * @UPLOAD
     */

    onDrop = (picture) => {
        this.setState({pictures: picture});
    }
    
    /**
      * 
      * @BARANG
      */
    onChangeBarang = (e) => {    
        let name = e.target.name;
        this.setState({
            infoBarang: {
                ...this.state.infoBarang,
                [e.target.name] : e.target.value
            }
        }, () => {
            if (name === "detail_category_id") { 
                this.getMasterSpesifikasi(this.state.infoBarang.detail_category_id);
            }    
        })
    }

    onChangeDescription = (value) => {
        this.setState({
            infoBarang: {
                ...this.state.infoBarang,
                deskripsi: value
            }
        })
    }

    onChangeBarangNumber = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            infoBarang: {
                ...this.state.infoBarang,
                [e.target.name] : validateValue
            }
        })
    }

    onChangeBarangCategory = (e) => {
        this.setState({disabledProdusen: false,productInternalSelectedAsync: "", isAssociated: false});
        if(e.target.value > 0){
            let { detail_categories } = this.state;
            let newDetail = detail_categories.filter(detail_category => detail_category.category_id === parseInt(e.target.value));
            this.setState({
                infoBarang: {
                    ...this.state.infoBarang,
                    category_id: e.target.value,
                    detail_category_id: newDetail[0] ? newDetail[0].id : 0,
                    spesifikasi: []
                },
                current_detail_categories : newDetail
            }, () => {
                if (this.state.infoBarang.detail_category_id !== 0) { 
                    this.getMasterSpesifikasi(this.state.infoBarang.detail_category_id)
                }
            })
        }
        else {
            this.setState({
                infoBarang: {
                    ...this.state.infoBarang,
                    category_id: '',
                    detail_category_id: '',
                    spesifikasi: []
                },
                current_detail_categories: this.state.detail_categories
            })
        }
    }

    changeProductInternalAsync = (newValue) => {
        let master_spesifikasi = this.state.master_spesifikasi.find(master_spek => master_spek.id === parseInt(newValue.value));
        
        if(master_spesifikasi){
            this.setState({
                productInternalSelectedAsync: newValue,
                productInternalSelected: parseInt(master_spesifikasi.id)
            })

        }else{
            this.setState({
                productInternalSelectedAsync: newValue,
                productInternalSelected: parseInt(newValue.value),
                dataProductInternalSelected: {
                    images: [],
                    produsen : {
                        id : 0
                    }
                },
                infoBarang : {
                    ...this.state.infoBarang,
                    nama: "",
                    deskripsi: ""
                },
                rd_gambar: undefined
            })
        }
    }

    getMasterSpesifikasi = (detail_category_id) => {
        if (detail_category_id === "other" || detail_category_id === "0") { 
            this.setState({
                spesifikasi: false,
                master_spesifikasi: [],
                master_spesifikasi_detail: [],
                master_spesifikasi_selected: {
                    value: null,
                    label: null,
                    is_new: false,
                    is_new2: false //untuk nampilin input field
                },
                master_spesifikasi_detail_selected: {
                    value: null,
                    label: null,
                    is_new: false,
                    master_spesifikasi: {}
                },
            })
        } else { 
            const { token } = this.props.userData;
            this.setState({
                loadingSpesifikasi: true,
                loadingDataSpesifikasi: true
            }, () => { 
                authGet(`product/spesifikasi/get/${detail_category_id}`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi: res.data.data.map(d => { return {value: d.id, label: d.name} }),
                        spesifikasi: true,
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
            })
        }
    }

    setSpesifikasi(value){
        this.setState({
            spesifikasi: value,
        })
    }

    onChangeMasterSpesifikasi = (newValue) => {
        const { token } = this.props.userData;
        let data = { 
            'value' : newValue.value,
            'label' : newValue.label,
            'is_new' : newValue.__isNew__ ? true : false,
            'is_new2': newValue.__isNew__ ? true : false,
        }
        this.setState({
            master_spesifikasi_selected: data,
            master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
            loadingDataSpesifikasi: true,
            loadingSpesifikasi: true
        }, () => {
            if (!data.is_new) { 
                authGet(`product/spesifikasi/get_detail/${data.value}`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi_detail: res.data.data.map(d => { return {value: d.id, label: d.value} }),
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
            } else {
                let dataPost = {
                    'label': data.label,
                    'detail_category_id': this.state.infoBarang.detail_category_id
                }
                authPost(dataPost, `product/spesifikasi/save_new`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi_detail: [],
                        master_spesifikasi_selected: res.data.selected,
                        master_spesifikasi: res.data.data.map(d => { return {value: d.id, label: d.name} }),
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    }, () => { 
                        AlertError.fire({text : err.response.data.message });
                    });
                })                
            }
        })
    }
    onChangeMasterSpesifikasiDetailAsync = (newValue) => {
        if (!this.state.master_spesifikasi_selected.value) { 
            AlertError.fire({text : "Nama Spesifikasi wajib diisi"});
            return false;
        }
        
        let data = { 
            'value' : newValue.value,
            'label' : newValue.label,
            'is_new' : newValue.__isNew__ ? true : false,
            'master_spesifikasi' : this.state.master_spesifikasi_selected
        }        
        this.setState({
            master_spesifikasi_detail_selected: data,
        });
    }

    onChangeMasterSpesifikasiDetail = (e) => {
        if (!this.state.master_spesifikasi_selected.value) { 
            AlertError.fire({text : "Nama Spesifikasi wajib diisi"});
            return false;
        }
        let data = { 
            'value' : e.target.value,
            'label' : e.target.value,
            'is_new' : true,
            'master_spesifikasi' : this.state.master_spesifikasi_selected
        }        
        this.setState({
            master_spesifikasi_detail_selected: data,
        });
    }

    onClickAddSpesifikasi = () => { 
        /**
         * Pengecekan untuk mengantisipasi adanya data yang sama. 
         */
        if (!this.state.master_spesifikasi_detail_selected.value || !this.state.master_spesifikasi_selected.value) { 
            AlertError.fire({text : "Spesifikasi wajib diisi"});
            return false;
        }
        if (this.state.infoBarang.spesifikasi.length > 0) {
            // let exists_master_spek = this.state.infoBarang.spesifikasi.filter(item => item.master_spesifikasi.value === this.state.master_spesifikasi_detail_selected.master_spesifikasi.value).length;
            let exists_master_spek_detail = this.state.infoBarang.spesifikasi.filter(item => item.value.toString().toLowerCase() === this.state.master_spesifikasi_detail_selected.value.toString().toLowerCase() && item.master_spesifikasi.value.toString().toLowerCase() === this.state.master_spesifikasi_detail_selected.master_spesifikasi.value.toString().toLowerCase()).length;
            if (exists_master_spek_detail > 0) { 
                AlertError.fire({text : "Tidak dapat memilih data spesifikasi yang sama."});
                this.setState({
                    master_spesifikasi_selected: { value: null, label: null, is_new: false},
                    master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
                })            
                return false;
            }
        }
        this.setState({
            infoBarang: {
                ...this.state.infoBarang,
                spesifikasi: [...this.state.infoBarang.spesifikasi, this.state.master_spesifikasi_detail_selected]
            }
        }, () => {
            this.setState({
                master_spesifikasi_selected: { value: null, label: null, is_new: false},
                master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
                master_spesifikasi_detail: [],
            })
        })
    }
    onClickRemoveSpesifikasi = (item, index) => { 
        let { spesifikasi } = this.state.infoBarang;
        this.setState({
            infoBarang: {
                ...this.state.infoBarang,
                spesifikasi: spesifikasi.filter((h, i) => i !== index),
            }
        })
    }
    
    /**
      * 
      * @HARGA
      */
    setGrosir(value){
        this.setState({
            grosir: value,
            inforHarga: {
                ...this.state.inforHarga,
                disabledMinOrder: value
            }
        })
    }

    onChangeHarga = (value) => {
        this.setState({
            inforHarga : {
                ...this.state.inforHarga,
                harga: value
            }
        })
    }

    onChangeHargaVIP = (value) => {
        this.setState({
            inforHarga : {
                ...this.state.inforHarga,
                harga_vip: value
            }
        })
    }

    onChangeHargaGrosir = (value) => {
        this.setState({
            inforHarga : {
                ...this.state.inforHarga,
                harga_grosir: value
            }
        })
    }

    onChangeHargaGrosirVIP = (value) => {
        this.setState({
            inforHarga : {
                ...this.state.inforHarga,
                harga_grosir_vip: value
            }
        })
    }

    handleChangeHarga = (e) => {
        this.setState({
            inforHarga : {
                ...this.state.inforHarga,
                [e.target.name] : e.target.value
            }
        })
    }

    onChangeHargaNumber = (e) => {
        this.setState({
            inforHarga: {
                ...this.state.inforHarga,
                [e.target.name] : e.target.value.replace(/[^\d]+/g,'')
            }
        })
    }

    onChangeMinOrder = (e) => {
        let validValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            inforHarga: {
                ...this.state.inforHarga,
                min_order : validValue,
                min : validValue === "" ? 1 : parseInt(validValue) + 1
            }
        })
    }

    validateHargaGrosir(data){
        if(data.min === ""){
            AlertWarning.fire({text: "Jumlah minimal tidak boleh kosong!"});
            return false;
        }
        else if(data.harga === ""){
            AlertWarning.fire({text: "Harga tidak boleh kosong!"});
            return false;
        }
        else if(data.min > data.max){
            if(data.max !== ""){
                AlertWarning.fire({text: "Jumlah minimal tidak boleh lebih besar atau sama dengan jumlah maksimal"});
                return false;
            }
        }
        else if(data.min < data.min_order){
            AlertWarning.fire({text: "Jumlah minimal tidak boleh lebih kecil atau sama dengan minimal pembelian"});
            return false;
        }

        return true;
    }

    addHargaGrosir = () => {
        let { hargaGrosirs } = this.state.inforHarga;
        
        let data = {
            min : this.state.inforHarga.min !== "" ? parseInt(this.state.inforHarga.min) : "",
            max : this.state.inforHarga.max !== "" ? parseInt(this.state.inforHarga.max) : "",
            harga : this.state.inforHarga.harga_grosir !== "" ? parseInt(this.state.inforHarga.harga_grosir): "",
            harga_vip : this.state.inforHarga.harga_grosir_vip !== "" ? parseInt(this.state.inforHarga.harga_grosir_vip): "",
            min_order : this.state.inforHarga.min_order !== "" ? parseInt(this.state.inforHarga.min_order) : "",
        }

        if(this.validateHargaGrosir(data)){
            if(hargaGrosirs[hargaGrosirs.length -1]){
                if(data.min <= hargaGrosirs[hargaGrosirs.length -1].max){
                    AlertWarning.fire({text: "Jumlah minimal tidak boleh lebih kecil atau sama dengan jumlah maksimal yang ada di daftar harga"});
                    return false;
                }
            }
            this.setState({
                inforHarga : {
                    ...this.state.inforHarga,
                    hargaGrosirs : [
                        ...this.state.inforHarga.hargaGrosirs,
                        {
                            ...data,
                            max: data.max === "" ? `Lebih dari ${data.min}` : data.max,
                            unlimited: data.max === "" ? true : false
                        }
                    ],
                    min: data.max === "" ? "" : parseInt(data.max) + 1,
                    max: '',
                    harga_grosir: '',
                    harga_grosir_vip: '',
                    disabled: data.max === "" ? true : false,
                }
            }, () => {
                this.inputMaxGrosir.focus();
            })
            
            
        }
        
    }

    removeHargaGrosir = (item ,index) => {
        let { hargaGrosirs } = this.state.inforHarga;

        this.setState({
            inforHarga: {
                ...this.state.inforHarga,
                hargaGrosirs: hargaGrosirs.filter((h, i) => i !== index),
                min: item.min,
                max: '',
                harga: '',
                disabled: item.max === "" ? true : false
            }
        })

    }

    /**
     * 
     * @ALAMAT
     */
    onChangeAddress = (e, item) => {
        this.setState({
            checkedAddress: {
                ...this.state.checkedAddress,
                [item.id] : e.target.checked
            }
        })
    }

    /**
     * 
     * @SHIPMENT
     */
    onChangeShipment = (e, item) => {
        this.setState({
            checkedShipment: {
                ...this.state.checkedShipment,
                [item.id] : e.target.checked
            }
        })
    }


    /**
     * 
     * @SAVE
     */

    createProduct = () => {

        let data_product = {
            nama : this.state.infoBarang.nama,
            subtitle: this.state.infoBarang.subtitle,
            category_id : this.state.infoBarang.category_id,
            detail_category_id : this.state.infoBarang.detail_category_id,
            kondisi: this.state.infoBarang.kondisi,
            berat: this.state.infoBarang.berat,
            deskripsi: this.state.infoBarang.deskripsi,
            hargaSatuan: this.state.inforHarga.harga,
            harga_vip: this.state.inforHarga.harga_vip,
            min_order: this.state.inforHarga.min_order,
            grosir: this.state.grosir,
            hargaGrosirs: this.state.inforHarga.hargaGrosirs,
            alamat:  _.keys(_.pickBy(this.state.checkedAddress)),
            shipment : _.keys(_.pickBy(this.state.checkedShipment)),
            internal_product_id: this.state.productInternalSelected > 0 ? this.state.productInternalSelected : null,
            produsen_id: this.state.dataProductInternalSelected.produsen.id > 0 ? this.state.dataProductInternalSelected.produsen.id : null,
            ppn: this.state.inforHarga.ppn,
            is_vip: this.state.is_vip,
            spesifikasi: this.state.infoBarang.spesifikasi
        }

        
        if(this.validateForCreateProduct(data_product, this.state.pictures)){
            
            ConfirmInfo.fire({
                text : "Apakah anda yakin data sudah benar?"
            }).then(result => {
                if(result.value){
                    const { token } = this.props.userData;
                    const product = data_product;
            
                        /**
                         * 
                         * INSERT DATA PRODUCT
                         */
            
                        this.setState({ progress : 1 });
                        authPost(product ,`product`, token)
                        .then(response => {
                            
                            if(!this.state.rd_gambar){
                                this.setState({ progress : 10 });
                                let data = new FormData();
                                _.forEach(this.state.pictures, (item, key) => {
                                    data.append(`pictures[${key}]`, item);
                                })
                                data.append('product_id', response.data.id);

                                /**
                                 * 
                                 * UPLOAD POHOTO
                                 */
                                axios.post(`${API_URL}/product_image_upload`, data, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization' : 'Bearer ' + token, 
                                    },
                                    onUploadProgress: (progressEvent) => {
                                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                                        if (totalLength !== null) {
                                            this.setState({ progress : Math.round( (progressEvent.loaded * 100) / totalLength )});
                                        }
                                    }
                                })
                                .then(res => {
                                    this.setState({ progress: 100}
                                    ,() => {
                                        Toast.fire({
                                            type : "success",
                                            title : "Barang berhasil ditambah"
                                        }).then(result => {
                                            // this.props.resetCreateProduct();
                                            this.props.history.push(`/myshop/product/view/${response.data.id}`);
                                        })
                                    })
                                })
                                .catch(error => {
                                    this.setState({ progress: 0}
                                    ,() => {
                                        AlertError.fire({text : "Terjadi kesalahan saat upload"});
                                    })
                                })
                            } else {
                                let dataPicture = {
                                    product_id: response.data.id,
                                    pictures: this.state.dataProductInternalSelected.images,
                                    penyakits: this.state.dataProductInternalSelected.penyakit_id,
                                }
                                authPost(dataPicture, `product_image_upload_from_url`, this.props.userData.token)
                                .then(res => {
                                    this.setState({ progress: 100});
                                    Toast.fire({
                                        type : "success",
                                        title : "Barang berhasil ditambah"
                                    }).then(result => {
                                        // this.props.resetCreateProduct();
                                        this.props.history.push(`/myshop/product/view/${response.data.id}`);
                                    })
                                })
                                .catch(error => {
                                    this.setState({ progress: 0}
                                    ,() => {
                                        AlertError.fire({text : "Terjadi kesalahan saat upload"});
                                    })
                                })
                            }


                        })
                        .catch(err => {
                            this.setState({ progress: 0}
                            ,() => {
                                AlertError.fire({text : err.response.data.message});
                            })
                        })
                    }
                    /**
                     * 
                     * END OF CREATE PRODUCT
                     */
                
            })
        }
    }

    validateForCreateProduct(data, pictures){
        if(data.nama === ""){
            AlertWarning.fire({text : "nama tidak boleh kosong"});
            return false
        }
        else if(parseInt(data.category_id) === 0 || parseInt(data.detail_category_id) === 0){
            AlertWarning.fire({text : "Silahkan pilih kategori product"});
            return false
        }
        else if(data.berat === ""){
            AlertWarning.fire({text : "Berat tidak boleh kosong"});
            return false
        }
        // else if(parseInt(data.berat) > 30000){
        //     AlertWarning.fire({text : "Berat tidak boleh lebih dari 30kg"});
        //     return false
        // }        
        else if(parseInt(data.hargaSatuan) === 0 || data.hargaSatuan === ""){
            AlertWarning.fire({text : "Harga tidak boleh kosong"});
            return false
        }
        else if(data.alamat.length <= 0){
            AlertWarning.fire({text : "Pilih salah satu alamat"});
            return false
        }
        else if(data.shipment.length <= 0){
            AlertWarning.fire({text : "Pilih salah satu Pengiriman"});
            return false
        }
        else if(data.grosir && data.hargaGrosirs.length === 0){
            AlertWarning.fire({text : "Harga grosir belum lengkap"});
            return false
        }
        else if(data.is_vip && data.harga_vip === '') {
            AlertWarning.fire({text : "Harga VIP wajib di isi"});
            return false
        }
        else if(pictures.length === 0){
            if(parseInt(this.state.infoBarang.category_id) === window.globalConfig.ovk_id){
                if(this.state.productInternalSelected === 0){
                    AlertWarning.fire({text : "Produk OVK harus memilih produk yang sudah di sediakan"});
                    return false
                }
                if(!this.state.rd_gambar){
                    AlertWarning.fire({text : "minimal upload satu photo"});
                    return false
                }
                return true
            }else{
                AlertWarning.fire({text : "minimal upload satu photo"});
                return false
            }
        }
        else{
            return true
        }
    }

    changeProductInternal = (e) => {
        
        let product_internal = this.state.productInternal.find(product => product.id === parseInt(e.target.value));

        if(product_internal){
            this.setState({
                productInternalSelected: parseInt(product_internal.id),
                dataProductInternalSelected: {
                    ...product_internal,
                    produsen : product_internal.produsen ? product_internal.produsen : { id : 0 }
                },
                disabledProdusen: product_internal.produsen ? true : false,
                infoBarang : {
                    ...this.state.infoBarang,
                    nama: product_internal.nama,
                    deskripsi: product_internal.deskripsi ? product_internal.deskripsi : ""
                },
                rd_gambar: undefined
            })

        }else{
            this.setState({
                productInternalSelected: parseInt(e.target.value),
                dataProductInternalSelected: {
                    images: [],
                    produsen : {
                        id : 0
                    }
                },
                infoBarang : {
                    ...this.state.infoBarang,
                    nama: "",
                    deskripsi: ""
                },
                rd_gambar: undefined
            })
        }
    }

    // handleInputChange = (test) => {
        
    // }

    handleNamaBarangChange = (e) => {
        // if (e.target.value === "") { 
        //     this.setState({productInternalSelectedAsync: ""})
        // }
        let produk_internal = [];
        let produk_internal_temp = this.state.productInternalAsync_temp;
        let filtered = produk_internal_temp.filter(result => result.label.toLowerCase().includes(e.target.value.toLowerCase()));
        if ((filtered <= 0 || filtered.length === this.state.productInternalAsync_temp.length) || !e.target.value) { 
            produk_internal = this.state.productInternalAsync_temp;
        } else { 
            produk_internal = filtered;
        }
        this.setState({
            infoBarang: {
                ...this.state.infoBarang,
                [e.target.name] : e.target.value,
            },
            productInternalAsync: produk_internal
        })
    }

    changeProductInternalAsync = (newValue) => {
        let product_internal = this.state.productInternal.find(product => product.id === parseInt(newValue.value));
        
        if(product_internal){
            this.setState({
                productInternalSelectedAsync: newValue,
                productInternalSelected: parseInt(product_internal.id),
                dataProductInternalSelected: {
                    ...product_internal,
                    produsen : product_internal.produsen ? product_internal.produsen : { id : 0 }
                },
                disabledProdusen: product_internal.produsen ? true : false,
                infoBarang : {
                    ...this.state.infoBarang,
                    // nama: product_internal.nama,
                    deskripsi: product_internal.deskripsi ? product_internal.deskripsi : ""
                },
                rd_gambar: undefined
            })

        }else{
            this.setState({
                productInternalSelectedAsync: newValue,
                productInternalSelected: parseInt(newValue.value),
                dataProductInternalSelected: {
                    images: [],
                    produsen : {
                        id : 0
                    }
                },
                infoBarang : {
                    ...this.state.infoBarang,
                    nama: "",
                    deskripsi: ""
                },
                rd_gambar: undefined
            })
        }
    }

    onChangeProdusen = (e) => {
        this.setState({
            dataProductInternalSelected : {
                ...this.state.dataProductInternalSelected,
                produsen : {
                    id : e.target.value
                }
            }
        })
    }

    onGetInternalImage = () => {

    }

    loadOptionsProductInternal = (inputValue, callback) => {
        let data = this.state.productInternalAsync.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
        callback(data);
    }

    loadOptionsMasterSpesifikasi = (inputValue, callback) => {
        const fuse = new Fuse(this.state.master_spesifikasi, fuseOptions);
        const datax = inputValue ? fuse.search(inputValue) : this.state.master_spesifikasi;
        let dataa = [];
        datax.map(item => dataa.push(item.item))
        callback(dataa);
        // let data = this.state.master_spesifikasi.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    loadOptionsMasterSpesifikasiDetail = (inputValue, callback) => {
        const fuse = new Fuse(this.state.master_spesifikasi_detail, fuseOptions);
        const datax = inputValue ? fuse.search(inputValue) : this.state.master_spesifikasi_detail;
        let dataa = [];
        datax.map(item => dataa.push(item.item))
        callback(dataa);
        // let data = this.state.master_spesifikasi_detail.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    onChangeAssociatedProduct = (e) => {
        this.setState({
            isAssociated: e.target.checked,
            productInternalSelectedAsync: "",
            dataProductInternalSelected: {
                images: [],
                produsen:{
                    id: 0
                }
            },
            infoBarang:{
                ...this.state.infoBarang,
                subtitle: '',
                kondisi : 1,
                berat: '',
                deskripsi: ""
            },
        }, () => {
            if (this.state.isAssociated) {
                this.setState({loadingProductInternal: true});
                authGet(`product/internal/list_product`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        productInternal: res.data,
                        productInternalAsync: res.data.map(d => { return {value: d.id, label: d.nama} }),
                        productInternalAsync_temp: res.data.map(d => { return {value: d.id, label: d.nama} }),
                        loadingProductInternal: false
                    })
                })
                .catch(err => {
                    AlertError.fire({text: "Terjadi kesalahan pada server"});
                })
            }
        });
    }

    render() {

        let Page = (
            <div>
                <div className="container p15" style={{marginTop: "62px"}}>
                    <div className="form-product">
                        <InfoBarang
                            onDrop={this.onDrop}
                            data={this.state.infoBarang}
                            onChangeBarang={this.onChangeBarang}
                            onChangeBarangCategory={this.onChangeBarangCategory}
                            categories={this.state.categories}
                            detail_categories={this.state.current_detail_categories}
                            onChangeBarangNumber={this.onChangeBarangNumber}
                            onChangeDescription={this.onChangeDescription}
                            produsens={this.state.produsens}
                            productInternal={this.state.productInternalAsync}
                            loadingProductInternal={this.state.loadingProductInternal}
                            productInternalSelectedAsync={this.state.productInternalSelectedAsync}
                            handleInputChange={this.handleInputChange}
                            productInternalSelected={this.state.productInternalSelected}
                            changeProductInternal={this.changeProductInternalAsync}
                            dataProductInternalSelected={this.state.dataProductInternalSelected}
                            onChangeProdusen={this.onChangeProdusen}
                            rd_gambar={this.state.rd_gambar}
                            onChangeRdGambar={(val) => this.setState({rd_gambar: val})}
                            disabledProdusen={this.state.disabledProdusen}
                            loadOptionsProductInternal={this.loadOptionsProductInternal}
                            handleNamaBarangChange={this.handleNamaBarangChange}
                            onChangeAssociatedProduct={this.onChangeAssociatedProduct}
                            isAssociated={this.state.isAssociated}
                            setSpesifikasi={(value) => this.setSpesifikasi(value)}
                            spesifikasi={this.state.spesifikasi}
                            dataSpesifikasi={this.state.infoBarang.spesifikasi}
                            master_spesifikasi={this.state.master_spesifikasi}
                            master_spesifikasi_detail={this.state.master_spesifikasi_detail}
                            master_spesifikasi_selected={this.state.master_spesifikasi_selected}
                            loadingDataSpesifikasi={this.state.loadingDataSpesifikasi}
                            loadingSpesifikasi={this.state.loadingSpesifikasi}
                            master_spesifikasi_detail_selected={this.state.master_spesifikasi_detail_selected}
                            onChangeMasterSpesifikasi={this.onChangeMasterSpesifikasi}
                            onChangeMasterSpesifikasiDetail={this.onChangeMasterSpesifikasiDetail}
                            onChangeMasterSpesifikasiDetailAsync={this.onChangeMasterSpesifikasiDetailAsync}
                            onClickAddSpesifikasi={this.onClickAddSpesifikasi}
                            onClickRemoveSpesifikasi={this.onClickRemoveSpesifikasi}
                            loadOptionsMasterSpesifikasi={this.loadOptionsMasterSpesifikasi}
                            loadOptionsMasterSpesifikasiDetail={this.loadOptionsMasterSpesifikasiDetail}
                        />
                        <InfoHarga
                            data={this.state.inforHarga}
                            onChangeHarga={this.onChangeHarga}
                            onChangeHargaVIP={this.onChangeHargaVIP}
                            handleChangeNumber={this.onChangeHargaNumber}
                            onChangeHargaGrosir={this.onChangeHargaGrosir}
                            onChangeHargaGrosirVIP={this.onChangeHargaGrosirVIP}
                            onChangeMinOrder={this.onChangeMinOrder}
                            addHargaGrosir={this.addHargaGrosir}
                            setGrosir={(value) => this.setGrosir(value)}
                            onRemoveHarga={this.removeHargaGrosir}
                            onChangePpn={() => this.setState({inforHarga: {...this.state.inforHarga, ppn: !this.state.inforHarga.ppn}})}
                            grosir={this.state.grosir}
                            refMaxGrosir={(input) => { this.inputMaxGrosir = input; }}
                            is_vip={this.state.is_vip}
                        />

                        <InfoAlamat
                            addresses={this.state.addresses}
                            data={this.state.checkedAddress}
                            onChange={this.onChangeAddress}
                            />
                        <div className="form-subtitle">
                            <h2>INFORMASI JASA PENGIRIMAN</h2>
                        </div>
                        <div className="row mb30">
                            <div className="col-md-7">
                                <div className="form-group-div">
                                    <label>Pilih Jasa Pengiriman <span className="red">*</span></label>
                                    {
                                        this.state.shipments.map(shipment => (
                                            <div className="box-outline mb0" key={shipment.id}>
                                                <table cellPadding="10">
                                                    <tbody>
                                                        <tr>
                                                            <td className="w100" rowSpan="2"><img src={shipment.image} alt="shipment"/></td>
                                                            <td>

                                                                <div className="p10">
                                                                    <div className="w25 pull-left">
                                                                        <input
                                                                            value={shipment.id}
                                                                            type="checkbox"
                                                                            checked={this.state.checkedShipment[shipment.id]}
                                                                            onChange={(e) => this.onChangeShipment(e, shipment)}
                                                                        />
                                                                    </div>
                                                                    {shipment.name}
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row form-footer">
                            <div className="col-md-7">
                                <p className="grey">Pastikan barang yang Anda tambahkan sudah sesuai dengan syarat dan ketentuan Agrinis. Barang yang melanggar syarat dan ketentuan akan dinon-aktifkan.</p>
                            </div>
                            <div className="col-md-5 text-right pt10">
                                    {
                                        this.state.progress > 0
                                        ? <div style={{width: "40px", display: "inline-block"}}>
                                            <CircularProgressbar 
                                                value={this.state.progress}
                                                text={`${this.state.progress}%`}
                                                styles={buildStyles({
                                                    pathColor : '#0C7F8F',
                                                    backgroundColor : '#0C7F8F'
                                                })}
                                                />
                                            </div>
                                        : <div>
                                            <button className="btn btn-solid darkgrey mr5" onClick={() => this.props.history.replace('/myshop')}>Batal</button>
                                            <button type="submit" className="btn btn-solid primary" onClick={this.createProduct}>Simpan</button>
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );

        return (
            <div>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                {
                    this.state.progress > 0
                    ? <LoadingLock/>
                    : null
                }
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {/* <LoadingPage/> */}
                        <CreateProductLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                {/* <div className="modal fade modal-2" id="modalGunakanGambar" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="javascript:" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>PILIH GAMBAR</h2>
                                {
                                    this.state.dataProductInternalSelected.images.map((image, i) => (
                                        <div className="img-product-thumbnail-check" key={i}>
                                            <input type="checkbox"/>
                                            <img src={image.image_url} alt="" />
                                        </div>
                                    ))
                                }
                                
                            </div>
                            <div className="modal-footer">
                                <a href="javascript:" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                <a href="javascript:" className="btn btn-solid primary btn-sm" onClick={this.onGetInternalImage}>Simpan</a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        photo : state.products.pictures,
        dataProduct : state.products.createProduct,
        userData : state.user.user
    }
}

export default NetworkDetector(connect(mapStateToProps, { 
    fetchCategories, 
    fetchAllSubCategories, 
    fecthShopAddress,
    fecthShopShipment,
    storeProduct,
    toggleModalLogin,
    resetCreateProduct
})(CreateProduct))