import React from 'react'
import ContentLoader from 'react-content-loader'
function TransactionCreateLoading() {
    return (
        <div className="container" style={{ marginTop: "62px" }}>
            <div className="form-transaction">
            <ContentLoader 
                height={85}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="20"/>
            <rect x="0" y="50" rx="0" ry="0" width="100" height="20"/>
            </ContentLoader>   
            <div className="form-subtitle">
                <ContentLoader 
                    height={20}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                </ContentLoader>  
			</div>
            <ContentLoader 
                height={140}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="125"/>
            </ContentLoader>  
            <div className="form-subtitle">
                <ContentLoader 
                    height={20}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                </ContentLoader>  
			</div>
            <ContentLoader 
                height={100}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="90"/>
            </ContentLoader>  

            </div>
        </div>
    )
}

export default TransactionCreateLoading

