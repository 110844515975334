import { createStore, applyMiddleware, compose } from "redux";
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers';
// import crosstabSync from 'redux-persist-crosstab';

const initialState = {};

const config = {
    channel: 'my_broadcast_channel',
    broadcastChannelOption: { type: 'localstorage' },
    whitelist: ["headerCategory", "categories"],
}

const middleware = [
    thunk,
    createStateSyncMiddleware(config)
];

const devTools = process.env.NODE_ENV === "production"
? compose(applyMiddleware(...middleware))
: compose(applyMiddleware(...middleware)
    ,(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    );

export const store = createStore(
    rootReducer, 
    initialState, 
    devTools
);

export const persistor = persistStore(store);

// crosstabSync(persistor)

export default { store, persistStore };
