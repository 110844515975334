export const URL = window.globalConfig.url;
export const APP_URL = window.globalConfig.assetUrl;
// const apiUrl = process.env.NODE_ENV === 'production' ? 'https://example.com' : process.env.REACT_APP_DEV_API_URL;
// export const URL = 'https://beta.agrinis.com/api';
// export const URL = 'https://api.agrinis.com/public/marketplace/public';
// export const APP_URL = 'https://api.agrinis.com/public/marketplace';
// export const URL = 'https://api.agrinis.com/public/beta/public';
// export const APP_URL = 'https://api.agrinis.com/public/beta';
// export const URL = 'http://192.168.0.6:8000';
// export const URL = 'http://192.168.0.204:8000';
// export const APP_URL = 'http://192.168.0.204:8000';
export const API_URL = `${APP_URL}/api`;
export const API_GMAPS_KEY = window.globalConfig.gmapsApiKey;
export const API_RAJA_ONGKIR = window.globalConfig.rajaOngkirApi;