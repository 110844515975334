import React from 'react'
import { Link } from 'react-router-dom'
import EmptyResult from "../Global/EmptyResult"
import imageEmpty from '../../assert/icon/empty-result.png'
import { FormatUang } from '../../helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingInfiniteScroll from '../Loading/LadingInfiniteScroll'
import i18n from '../../i18n';
const ProductGrid = props => {
    const renderStar = (starred) => {

        let arr_star = [];
        for (let i = 0; i < 5; i++) {
            if (i < starred) {
                arr_star.push(<i className="fas fa-star starred" key={i}></i>)
            } else {
                arr_star.push(<i className="fas fa-star" key={i}></i>)
            }
        }

        return arr_star;
    }

    if (props.loading) {
        return (<p>{i18n.t("Statistic:barang_populer_grid.loading")}</p>)
    } else {
        if (props.totalData === 0) {
            let msg = i18n.t("Statistic:barang_populer_grid.tidak_ada_barang")
            let btnText = 0;

            if (props.status === 2) {
                msg = i18n.t("Statistic:barang_populer_grid.tidak_ada_barang_nonaktif")
                btnText = 0;
            }

            return <EmptyResult
                image={imageEmpty}
                message={msg}
                buttonAction={props.emtyDataAction}
                buttonText={btnText}
            />
        } else {
            return (
                <div className="product-container" style={{ display: props.grid ? "" : "none" }}>
                    <InfiniteScroll
                        dataLength={props.data.length}
                        next={props.fetchMoreData}
                        hasMore={props.hasMoreData}
                        loader={<LoadingInfiniteScroll />}
                    >
                        {props.data.map(product => (
                            <div key={product.id} className={`product-div home`}>
                                <Link to={`/product/${product.id}`} className="product-link" title="Lihat Produk">
                                    <div className="product-img" style={{ height: '196px' }}>
                                        <img src={product.image} alt="Agrinis" />
                                    </div>
                                    <h4 style={{overflowWrap: "break-word"}}>{product.name}</h4>
                                </Link>

                                <div className="product-desc">
                                    <p>{product.subtitle}</p>
                                    <div className="rating">
                                        {renderStar(product.rating)}
                                    </div>
                                    <div className="price">
                                        <span className="main-price">
                                            Rp &nbsp;
                                        <FormatUang value={product.price} />
                                        </span>
                                    </div>
                                    <div className="status">
                                        <span className="inline-block mr5 grey"><i className="far fa-heart" title="Favorit"></i> {product.like}</span>
                                        <span className="inline-block grey"><i className="far fa-eye" title="View"></i> {product.viewer}</span>
                                        <span className="inline-block pull-right">{i18n.t("Statistic:barang_populer_grid.terjual")} {product.terjual}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                    <div className="clearfix"></div>
                </div>
            )
        }
    }
}

export default ProductGrid
