import decode from 'jwt-decode';
import { API_URL } from "../../config";
// import _ from "lodash";
import axios from 'axios'

export default class AuthService {
    constructor(domain) {
        this.domain = domain || API_URL
        this.fetch = this.fetch.bind(this)
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(email, password) {
        return axios.post(`${this.domain}/login`, {email, password})
    }
    // login(email, password) {
    //     // Get a token
    //     return this.fetch(`${this.domain}/login`, {
    //         method: 'POST',
    //         body: JSON.stringify({email,password})
    //     }).then(res => {
    //         this.setToken(res.token)
    //         // this.setUser(res.user)
    //         // this.setShop(_.size(res.shop) > 0 ? JSON.stringify(res.shop) : 0)
    //         return Promise.resolve(res);
    //     })
    // }

    register(data){
        return axios.post(`${this.domain}/register`, data);
    }

    // register(name, email, username, password, confirm_password){
    //     return this.fetch(`${this.domain}/register`, {
    //         method: 'POST',
    //         body: JSON.stringify({name,email,username, password, confirm_password})
    //     }).then(res => {
    //         this.setToken(res.token)
    //         // this.setUser(res.user)
    //         // this.setShop(_.size(res.shop) > 0 ? JSON.stringify(res.shop) : 0)
    //         return Promise.resolve(res);
    //     })
    // }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    setUser(user) {
        // Saves user token to localStorage
        localStorage.setItem('user', user)
    }

    setShop(shop) {
        // Saves user token to localStorage
        localStorage.setItem('shop', shop)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    getUser() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('user')
    }

    getShop() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('shop')
    }

    logout() {
        // Clear user token and profile data from localStorage
        return Promise.resolve().then(() => {
            localStorage.removeItem('id_token');
            localStorage.removeItem('user');
            localStorage.removeItem('shop');
        })
        // localStorage.removeItem('disctrict');
    }

    getProfile() {
        return decode(this.getToken());
    }

    removeDistrict() {
        localStorage.removeItem('disctrict');
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}