import { 
    SET_DATA_PRODUCT,
    SET_DATA_BANK,
    CHANGE_QUANTITY,
    SET_HISTORY,
    SET_DATA_SHOP,
    SET_SHOP_SHIPMENT,
    CLEAR
}from '../actions/types/transactionType'

const initialState = {
    items: [],
    banks : [],
    goBack: '/',
    shop: {},
    shipments: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case SET_DATA_PRODUCT:
        case CHANGE_QUANTITY:
            return {
                ...state,
                items: action.payload
            }
        case SET_DATA_SHOP:
            return {
                ...state,
                shop: action.payload
            }
        case SET_SHOP_SHIPMENT:
            return {
                ...state,
                shipments: action.payload
            }
        case SET_DATA_BANK:
            return {
                ...state,
                banks : action.payload
            }
        case SET_HISTORY:
            return {
                ...state,
                goBack : action.payload
            }
        case CLEAR:
            return {
                ...state,
                items : [],
                shop : {},
                shipments : []
            }
        default:
            return state;
    }
}