import React from 'react'
import ContentLoader from 'react-content-loader'

function StatisticLoading() {
    return (
    <div>
        <div className="container-fluid">
        <ContentLoader 
            height={40}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%"/>
        </ContentLoader>
        </div>
        <div className="container-fluid">
                <div className="statistic-container">
                    <div className="statistic-left">
                    <ContentLoader 
                        height={180}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="150"/>
                    </ContentLoader>
                    <ContentLoader 
                        height={175}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="150"/>
                    </ContentLoader>       
                    <ContentLoader 
                        height={250}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="50"/>
                        <rect x="0" y="60" rx="0" ry="0" width="100%" height="50"/>
                        <rect x="0" y="120" rx="0" ry="0" width="100%" height="50"/>
                        <rect x="0" y="180" rx="0" ry="0" width="100%" height="50"/>
                    </ContentLoader>                
                    </div>
                    <div className="statistic-main">
                        <div className="statistic-filter">
                        <ContentLoader 
                            height={15}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%"/>
                        </ContentLoader> 
                        </div>
                        <div className="statistic-body" id="statistic-1">
                        <ContentLoader 
                        height={35}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="135" height="30"/>
                        <rect x="140" y="0" rx="0" ry="0" width="85" height="30"/>
                        <rect x="230" y="0" rx="0" ry="0" width="85" height="30"/>
                        <rect x="325" y="0" rx="0" ry="0" width="85" height="30"/>
                    </ContentLoader> 
                    <ContentLoader 
                            height={15}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="125" height="100%"/>
                        </ContentLoader> 
                        <ContentLoader 
                        height={100}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="3" rx="0" ry="0" width="190" height="100"/>
                        <rect x="200" y="3" rx="0" ry="0" width="210" height="100"/>
                    </ContentLoader> 
                        </div>
                    </div>
                </div>
        </div>
    </div>
    )
}

export default StatisticLoading
