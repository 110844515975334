import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';

import {
    NewHeader,
    Footer,
} from "../../../../components";

import ReactImageMagnify from 'react-image-magnify';
import {
    ImgThumb,
    MyProductDetail,
    TabDetailProduct
} from '../../../../components/Product/_Component/DetailProductComponent';
import {
    Toast,
    ConfirmInfo,
    FormatUang,
    AlertError,
    AlertInfo,
    authGet,
    authPost,
    authPut,
    ConfirmWarning
} from "../../../../helper";
import NumberFormat from 'react-number-format';

import ReactLoading from 'react-loading';

import {
    toggleModalLogin
} from "../../../../actions/registerActions";
import ViewMyProductLoading from '../../../../components/Loading/ViewMyProductLoading';
import styled from 'styled-components';
import logo_vip from '../../../../assert/icon/vip_logo.svg';
import NetworkDetector from '../../../PUBLIC/NetworkDetector';

const Loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
const Ppn = styled.span`
    font-style: italic;
    font-size: 10px;
    color: #84C225;
    float: right;
`;

class ViewMyProduct_v2 extends Component {
    constructor(props){
        super(props)
		this.state = {
            loadingPage: true,
            active : 1,
            discount_price : 0,
            id: "",
            images: [],
            image_url: '',
            shipment: [],
            valueDiscount: 0,
            valueDiscountPpn: 0,
            valueDiscount_vip: 0,
            valueDiscountPpn_vip: 0,
            loadingButton: false,
            loadingHapusDiskon: false,
            prices: [],
            prices_temp: [],
            is_vip: false,
            spesifikasi: []
        }
		
    }

    componentWillMount(){
        let params = this.props.match.params;
        let outputSpesifikasi = [];
        authGet(`shop_profile/detail_product/${params.id}`, this.props.userData.token)
        .then(res => {
            if (res.data.spesifikasi.length > 0) { 
                outputSpesifikasi = this.formatSpesifikasiProduct(res.data.spesifikasi)
            }
            this.setState({
                ...res.data,
                prices_temp: res.data.prices.filter(price => price.id !== 0),
                loadingPage:false,
                valueDiscount: res.data.price_before_ppn,
                valueDiscountPpn: res.data.real_price,
                valueDiscount_vip: res.data.price_before_ppn_vip,
                valueDiscountPpn_vip: res.data.real_price_vip,
                spesifikasi: outputSpesifikasi,
            });
        })
        .catch(err => {
            
            AlertError.fire({text: err.response.data.message})
            .then(result => {
                this.props.history.push("/myshop");
            })
        })
    }

    formatSpesifikasiProduct(data) { 
        let outputObj = _.reduce(data, function(accumulator, {value, name}) { 
            if (!accumulator[name]) accumulator[name] = value
            else accumulator[name] += ", " + value
            return accumulator
        }, {});
        let output = _.map(outputObj, function(value, name) { 
            return {name, value}
        });
        return output
    }

    setTab(active){
        // let {id} = this.props.match.params;
        switch(active){
            case 2:
                this.setState({active})
                // this.props.productShipment(id)
                break;
            case 3:
                this.setState({active})
                break;
            case 4:
                this.setState({active})
                break;
            default :
                this.setState({active})
                break;

        }
    }

    handleButtonActive(status){
        let item = this.props.match.params.id;

        let data = {
            status, 
            id: item
        }

        ConfirmInfo.fire({
            text : "Apakah anda yakin akan mengubah data ini?"
        }).then(result => {
            if(result.value){
                authPost(data, `product/put_update_status`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        ...res.data
                    })

                    Toast.fire({
                        type: "success",
                        title: "Status barang berhasil diubah"
                    });
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message})
                })
            }
        })
        
    }

    handleDelete = (e) => {
        e.preventDefault();
        let item = this.props.match.params.id;
        
        ConfirmInfo.fire({
            text : "Apakah anda yakin akan menghapus data ini?"
        }).then(result => {
            if(result.value){
                authPost({products : [item]}, `product/delete_product`,this.props.userData.token)
                .then(res => {
                    if(res.data.cant_delete){
                        AlertInfo.fire({text: res.data.message});
                    }
                    if(res.data === "ok"){
                        Toast.fire({
                            type: "success",
                            title: "Barang berhasil dihapus"
                        })
                        this.props.history.push(`/myshop`);
                        
                    }
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message})
                })
            }
        })
        
    }

    saveDiskon = (e) => {
        e.preventDefault();
        
        let data = {
            id: this.state.id,
            value_discount : this.state.valueDiscount,
            value_discount_vip : this.state.valueDiscount_vip,
            harga_grosir: this.state.prices_temp.map(p => {return {...p, price: p.price_before_ppn, price_vip: p.price_before_ppn_vip}}),
        };

        // console.log()
        this.setState({loadingButton: true});
        authPut(data ,`product/update_discount`, this.props.userData.token)
        .then(res => {
            this.setState({
                ...res.data,
                valueDiscount: res.data.price_before_ppn,
                prices_temp: res.data.prices.filter(price => price.id !== 0),
                loadingButton: false
            })
            Toast.fire({
                type: "success",
                title: "Diskon berhasil diubah"
            });
            window.$("#modalDiskon").modal("hide");
        })
        .catch(err => {
            this.setState({loadingButton: false});
            AlertError.fire({text: err.response.data.message});
        })
    }

    handleChangeDiskon (value) {
        this.setState({
            valueDiscount : value,
        }, () => {
            let diskon = this.state.valueDiscount === "" ? 0 : parseInt(this.state.valueDiscount);
            this.setState({valueDiscountPpn: diskon + (diskon*10/100) })
        });
        // this.props.changeTempPrice(value);
    }
    handleChangeDiskonVip (value) {
        this.setState({
            valueDiscount_vip : value,
        }, () => {
            let diskon = this.state.valueDiscount_vip === "" ? 0 : parseInt(this.state.valueDiscount_vip);
            this.setState({valueDiscountPpn_vip: diskon + (diskon*10/100) })
        });
        // this.props.changeTempPrice(value);
    }

    selectImage(image) {
        this.setState({
            image_url:image.image,
            image_selected: image.id
        })
    }

    onClickRemoveDiskon = (e) => {
        e.preventDefault();
        ConfirmWarning.fire({
            text: "Apakah anda yakin akan mengubah nya?"
        })
        .then(result => {
            if(result.value) {
                
                let data = {
                    id: this.state.id,
                    value_discount : this.state.real_price_before_ppn,
                    harga_grosir: this.state.prices_temp.map(p => { return {...p, price: p.real_price}})
                };

                this.setState({loadingHapusDiskon: true});
                authPost(data ,`product/remove_discount`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        ...res.data,
                        valueDiscount: res.data.price_before_ppn,
                        prices_temp: res.data.prices.filter(price => price.id !== 0),
                        loadingHapusDiskon: false
                    })
                    Toast.fire({
                        type: "success",
                        title: "Diskon berhasil diubah"
                    });
                })
                .catch(err => {
                    this.setState({loadingHapusDiskon: false});
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }

    onChangeDiscountGrosir = (price, value) => {
        this.setState({
            prices_temp: this.state.prices_temp.map(p => p.id === price.id ? {...p, price_before_ppn: value} : {...p})
        })
    }

    onChangeDiscountGrosirVip = (price, value) => {
        this.setState({
            prices_temp: this.state.prices_temp.map(p => p.id === price.id ? {...p, price_before_ppn_vip: value} : {...p})
        })
    }


    render() {
        // let product = this.props.product_detail;
        
        let Page = (
        <div>
        <div className="container mg-top">
            <div className="detail-product-container">
            {/* <Breadcrumb 
                category_id={this.state.category_id}
                category_name={this.state.category}
                category_detail_name={this.state.sub_category}
            /> */}
	    <br/>
	    <div className="product-side">
            <div className="image-product">
            {
                this.state.kondisi === 2
                ? <div className="second">Bekas</div>
                : null
            }
            <div className="image-thumbnail">
                {
                    this.state.images.map(item => (
                        <ImgThumb 
                            key={item.id} 
                            src={`${item.image_thumb}`}
                            onClick={() => this.selectImage(item)}
                            className={item.id === parseInt(this.state.image_selected) ? "thumb active" : "thumb"}
                        />
                    ))
                }
                </div>
                <div className="image-slide" style={{zIndex: 9999}}>
                    <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Product',
                            isFluidWidth: true,
                            src: this.state.image_url
                        },
                        largeImage: {
                            src: this.state.image_url,
                            width: 800,
                            height: 800,
                        },
                        style: {
                            zIndex: 99
                        },
                        // className: 'image-zoom',
                        enlargedImageClassName: 'image-zoom'
                    }} />
                
                    <div className="text-center">
                        <span className="mr10"><i className="far fa-heart"></i> {this.state.jml_like} Orang Menyukai</span> | 
                        <span className="ml10"><i className="far fa-eye"></i> {this.state.viewer} Orang Melihat</span>
                    </div>
                </div>
            </div>
            <MyProductDetail
                    {...this.state}
                    />
            <div className="clearfix"></div>
            <TabDetailProduct 
                active={this.state.active}
                setTab={(active) => this.setTab(active)}
                getShipment={this.state.shipment}
                {...this.state}
                />
		    </div>
            <div className="action-side">
                    <Link to={`/myshop/product/edit/${this.state.id}`} className="btn btn-outline primary mb10 w150 text-left"><i className="fas fa-pencil-alt"></i> Ubah</Link>
                    <a href="/#" className="btn btn-outline primary mb10 w150 text-left" data-toggle="modal" data-target="#modalDiskon"><i className="fas fa-tag"></i> Beri Diskon</a>
                    {/* <!-- jika harga grosir --> */}
                    {/* <!-- <a href="javascript:" className="btn btn-outline primary mb10 w150 text-left" data-toggle="modal" data-target="#modalDiskonGrosir"><i className="fas fa-tag"></i> Beri Diskon</a> --> */}
                    {/* <!-- jika harga grosir --> */}
                    {
                        this.state.status === 1
                        ? <a 
                            href="/#" 
                            className="btn btn-outline yellow mb10 w150 text-left" 
                            onClick={(e) => {e.preventDefault();this.handleButtonActive(2)}}><i className="fas fa-eye-slash"></i> Nonaktifkan</a>
                        : <a 
                            href="/#" 
                            className="btn btn-outline primary mb10 w150 text-left" 
                            onClick={(e) => {e.preventDefault();this.handleButtonActive(1)}}><i className="fas fa-eye"></i> Aktifkan</a>
                    }
                    <a href="/#" className="btn btn-outline red mb10 w150 text-left" onClick={this.handleDelete}><i className="fas fa-trash-alt"></i> Hapus</a>
                </div>
		    <div className="clearfix"></div>
            </div>
        </div>
        <Footer/>
        </div>
        )

        return (
        <div>
        <NewHeader 
			{...this.props}
			stateLogin = {this.props.userData.user}
			toggleModalLogin={() => this.props.toggleModalLogin(true)}
			/>
        <CSSTransition
            in={this.state.loadingPage}
            timeout={300}
            classNames="mypage"
            unmountOnExit>
                <ViewMyProductLoading />
        </CSSTransition>
        <CSSTransition
            in={!this.state.loadingPage}
            timeout={300}
            classNames="mypage"
            unmountOnExit>
            {Page}
        </CSSTransition>
        <ModalHarga 
            onChangeDiscount={(value) => this.handleChangeDiskon(value)}
            onChangeDiscountVip={(value) => this.handleChangeDiskonVip(value)}
            valueDiscount={this.state.valueDiscount}
            saveDiskon={this.saveDiskon}
            onClickRemoveDiskon={this.onClickRemoveDiskon}
            onChangeDiscountGrosir={this.onChangeDiscountGrosir}
            onChangeDiscountGrosirVip={this.onChangeDiscountGrosirVip}
            {...this.state}
            />
        </div>
        )
    }
}

const ModalHarga = props => (
    <div className="modal fade modal-2" id="modalDiskon" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <form>
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                        <h2>DISKON BARANG</h2>
                        <div className="form-group-div">
                            <div className="product-card">
                                <div className="product-thumb">
                                    <img src={props.image_thumb} alt="My Product"/>
                                </div>
                                <div className="product-info">
                                    <p className="semibold">{props.name}</p>
                                    <div className="price">
                                        <span className="main-price" style={{fontSize: "12px"}}>Rp <FormatUang value={parseInt(props.real_price)}/></span>
                                        {
                                            props.is_vip
                                            ? <><img src={logo_vip} alt="LOGO VIP" /> <span className="main-price" style={{fontSize: "12px"}}>Rp <FormatUang value={parseInt(props.real_price_vip)}/></span></>
                                            : null
                                        }
                                        
                                        <table>
                                            <tbody style={{fontSize: "12px"}}>
                                            {
                                                props.prices.map(price =>{
                                                    let pembelian = price.max > 0 ? `${price.min} - ${price.max}` : `${price.min} atau lebih`;
                                                    if(price.id !== 0){
                                                        return (
                                                            <tr key={price.id}>
                                                                <td style={{color: "#999999"}}>{`Pembelian ${pembelian}`}</td>
                                                                <td>
                                                                Rp. &nbsp;
                                                                <FormatUang value={parseInt(price.real_price)}/>
                                                                </td>
                                                                {
                                                                    props.is_vip
                                                                    ? <td >
                                                                        <img src={logo_vip} alt="LOGO VIP"/> Rp. &nbsp;
                                                                        <FormatUang value={parseInt(price.real_price_vip)}/>
                                                                    </td>
                                                                    : null
                                                                }
                                                            </tr>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                            )}
                                            {
                                                props.ppn
                                                ? <Ppn>Termasuk PPN</Ppn>
                                                : null
                                            }
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="form-group-div">
                            <label>Tentukan harga barang setelah diskon</label><br/>
                            <div className="row">
                                <div className="col-md-6 pr0">
                                    <label>Harga diskon</label><br/>
                                    <span className="mr5 mt5 pull-left">Rp</span>
                                    <NumberFormat
                                        type="text"
                                        className={`form-control mr5 w200 pull-left ${parseInt(props.valueDiscount) > props.real_price ? "is-invalid" : ""}`}
                                        placeholder="Harga diskon"
                                        value={props.valueDiscount} 
                                        displayType={'input'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        onValueChange={(values) => props.onChangeDiscount(values.value)}
                                        />
                                    {
                                        props.ppn
                                        ? <label style={{ color: "#84C225" }}>Harga + PPN 10 % = <FormatUang value={props.valueDiscountPpn}/></label>
                                        : null
                                    }
                                    {/* <input 
                                        type="number"
                                        className="form-control mr5 w200 pull-left" 
                                        placeholder="Harga diskon" 
                                        defaultValue={props.valueDiscount === 0 ? '' : props.valueDiscount}
                                        onChange={(e) => props.onChangeDiscount(e)}
                                    /> */}
                                </div>
                                {
                                    props.is_vip
                                    ? <div className="col-md-6 pl0"  >
                                        <label>Harga diskon VIP</label><br/>
                                        <span className="mr5 mt5 pull-left">Rp</span>
                                            <NumberFormat
                                                type="text"
                                                className={`form-control mr5 w200 pull-left ${parseInt(props.valueDiscount_vip) > props.real_price_vip ? "is-invalid" : ""}`}
                                                placeholder="Harga diskon"
                                                value={props.valueDiscount_vip} 
                                                displayType={'input'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                onValueChange={(values) => props.onChangeDiscountVip(values.value)}
                                                />
                                            {
                                                props.ppn
                                                ? <label style={{ color: "#84C225" }}>Harga + PPN 10 % = <FormatUang value={props.valueDiscountPpn_vip}/></label>
                                                : null
                                            }
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                        {
                            props.prices_temp.length > 0
                            ? <div className="form-group-div">
                                <label>Tentukan harga barang grosir setelah diskon</label><br />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="w100">Qty</td>
                                            <td>Harga</td>
                                            {
                                                props.is_vip
                                                ? <td >Harga VIP</td>
                                                : null
                                            }
                                        </tr>
                                        {
                                            props.prices_temp.map(price => {
                                                let pembelian = price.max > 0 ? `${price.min} - ${price.max}` : `> ${price.min}`;
                                                return (
                                                    <tr key={price.id}>
                                                        <td>{pembelian}</td>
                                                        <td>
                                                            <span className="mr5 mt5 pull-left">Rp</span> 
                                                            <NumberFormat
                                                                type="text"
                                                                className={`form-control mr5 w135 pull-left ${parseInt(price.price_before_ppn) > price.real_price_before_ppn ? "is-invalid" : ""}`}
                                                                placeholder="Harga diskon"
                                                                value={price.price_before_ppn} 
                                                                displayType={'input'}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                onValueChange={(values) => props.onChangeDiscountGrosir(price,values.value)}
                                                                />
                                                            {
                                                                props.ppn
                                                                ? <Ppn>Harga + PPN 10% = <FormatUang value={price.price_before_ppn === "" ? 0 : parseInt(price.price_before_ppn) + (parseInt(price.price_before_ppn) * 10/100)}/></Ppn>
                                                                : null
                                                            }
                                                        </td>
                                                        {
                                                            props.is_vip
                                                            ? <td >
                                                            <span className="mr5 mt5 pull-left"><img src={logo_vip} alt="LOGO VIP"/> Rp</span> 
                                                                <NumberFormat
                                                                    type="text"
                                                                    className={`form-control mr5 w135 pull-left ${parseInt(price.price_before_ppn_vip) > price.real_price_before_ppn_vip ? "is-invalid" : ""}`}
                                                                    placeholder="Harga diskon"
                                                                    value={price.price_before_ppn_vip} 
                                                                    displayType={'input'}
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    onValueChange={(values) => props.onChangeDiscountGrosirVip(price,values.value)}
                                                                    />
                                                                {
                                                                    props.ppn
                                                                    ? <Ppn>Harga + PPN 10% = <FormatUang value={price.price_before_ppn_vip === "" ? 0 : parseInt(price.price_before_ppn_vip) + (parseInt(price.price_before_ppn_vip) * 10/100)}/></Ppn>
                                                                    : null
                                                                }
                                                                
                                                            </td>
                                                            : null
                                                        }
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                        <div className="notification-box sm">
                            <div className="info">
                                <i className="fas fa-info-circle"></i>
                                <p>
                                    Harga diskon tidak boleh lebih besar dari harga barang.<br/>
                                    Harga diskon {props.ppn ? 'setelah dikenakan PPN' : null } akan menjadi harga baru yang ditampilkan pada barang.
                                </p>
                            </div>
                        </div>
                            
                    </div>
                    <div className="modal-footer">
                        {
                            props.loadingButton
                            ? <div style={{width: "20%"}}><Loading/></div>
                            : <React.Fragment>
                                {
                                    parseInt(props.price) !== parseInt(props.real_price) || parseInt(props.price_vip) !== parseInt(props.real_price_vip) || props.prices_temp.find(p => p.price !== p.real_price) || props.prices_temp.find(p => p.price_vip !== p.real_price_vip)
                                    ? props.loadingHapusDiskon
                                        ? <div style={{width: "20%"}}>
                                            <Loading/>
                                        </div>
                                        : <button className="btn btn-sm btn-outline-danger" onClick={props.onClickRemoveDiskon}>Hapus diskon</button>
                                    : null
                                }
                                <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                <a href="/#" className="btn btn-solid primary btn-sm" onClick={props.saveDiskon}>Simpan</a>
                            </React.Fragment>
                        }
                        
                    </div>
                </div>
            </form>
        </div>
    </div>
)

const mapStateToProps = state => {
    return {
        product_detail: state.products.item,
        seller : state.products.seller,
        loading : state.loading.loadingProduct,
        shipment : state.products.productShipment,
        userData : state.user.user
    }
}


export default NetworkDetector(connect(mapStateToProps, {
    toggleModalLogin
})(ViewMyProduct_v2))