import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserAfterVerify } from '../../actions/userAction';
import { CSSTransition } from 'react-transition-group';
import { toggleModalLogin } from "../../actions/registerActions";

import {
    NewHeader,
    LoadingPage,
} from '../../components';
import AuthService from '../../actions/API/AuthService'
import Logo from '../../assert/icon/logo.png'
import VerifSuccess from '../../assert/icon/email-verification-success.png'
import NetworkDetector from '../PUBLIC/NetworkDetector'

class VerifyEmail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingPage: true,
            verifiedStatus: false,
            messageVerified: ""
        }
        this.Auth = new AuthService();
    }

    componentDidMount(){
        this.props.toggleModalLogin(false);
        this.props.getUserAfterVerify(this.props.userData.token)
        .then(() => {
            this.setState({ verifiedStatus: true });
            // this.props.history.push('/')
        })
        .catch(err => {
            this.setState({ verifiedStatus: false, messageVerified: err.response });
            // this.props.history.push('/')
        });
        this.setState({ loadingPage: false });
    }

    render() {
        let PageTrue = (
            <div className="container p30 text-center" style={{ marginTop: "100px" }}>
                    <img src={Logo} alt="Agrinis" /><br />
                    <img src={VerifSuccess} alt="Verifikasi Email" />
                    <br /><br />
                    <h2>Verifikasi Berhasil</h2>
                    <p className="fs15">Selamat datang di Agrinis.<br />Nikmati kemudahan berbelanja kebutuhan peternakan dengan aman.</p>
                    <a href="/#" onClick={(e) => { e.preventDefault(); this.props.history.push('/') }} className="btn btn-solid primary">Belanja Sekarang</a>
                </div>)
                return (
                    <div>
                        <NewHeader
                            {...this.props}
                            stateLogin={this.props.userId}
                            toggleModalLogin={() => this.props.toggleModalLogin(true)}
                            clearFilterPage={this.clearFilterPage}
                        />
                        <CSSTransition
                            in={this.state.loadingPage}
                            timeout={300}
                            classNames="mypage"
                            unmountOnExit>
                            <LoadingPage />
                        </CSSTransition>
                        <CSSTransition
                            in={!this.state.loadingPage}
                            timeout={300}
                            classNames="mypage"
                            unmountOnExit>
                            {PageTrue}
                        </CSSTransition>
                    </div>
            )
        // let PageFalse = (<div><h1>False Page</h1></div>)
        // if (this.state.verifiedStatus){
        //     return (
        //         <div>
        //             <NewHeader
        //                 {...this.props}
        //                 stateLogin={this.props.userId}
        //                 toggleModalLogin={() => this.props.toggleModalLogin(true)}
        //                 clearFilterPage={this.clearFilterPage}
        //             />
        //             <CSSTransition
        //                 in={this.state.loadingPage}
        //                 timeout={300}
        //                 classNames="mypage"
        //                 unmountOnExit>
        //                 <LoadingPage />
        //             </CSSTransition>
        //             <CSSTransition
        //                 in={!this.state.loadingPage}
        //                 timeout={300}
        //                 classNames="mypage"
        //                 unmountOnExit>
        //                 {PageTrue}
        //             </CSSTransition>
        //         </div>
        // )
        // }else{
        //     console.log(this.state.messageVerified);
        //     return (
        //         <div>
        //             <NewHeader
        //                 {...this.props}
        //                 stateLogin={this.props.userId}
        //                 toggleModalLogin={() => this.props.toggleModalLogin(true)}
        //                 clearFilterPage={this.clearFilterPage}
        //             />
        //             <CSSTransition
        //                 in={this.state.loadingPage}
        //                 timeout={300}
        //                 classNames="mypage"
        //                 unmountOnExit>
        //                 <LoadingPage />
        //             </CSSTransition>
        //             <CSSTransition
        //                 in={!this.state.loadingPage}
        //                 timeout={300}
        //                 classNames="mypage"
        //                 unmountOnExit>
        //                 {PageFalse}
        //             </CSSTransition>
        //         </div>
        //     )
        // }
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.user.user,
        userId: state.user.user.user,
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    getUserAfterVerify,
    toggleModalLogin
})(VerifyEmail))
