import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserAfterVerify } from '../../../../actions/userAction';
import { CSSTransition } from 'react-transition-group';
import { toggleModalLogin } from "../../../../actions/registerActions";

import {
    NewHeader,
    LoadingPage,
} from '../../../../components';
import AuthService from '../../../../actions/API/AuthService';
import VIP from '../../../../assert/files/illustration/toko_vip.svg';
import { authGet } from '../../../../helper';
import { 
    getMyShop
} from "../../../../actions/shopActions";
import NetworkDetector from '../../../PUBLIC/NetworkDetector'
class RequestResult extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingPage: true,
            verifiedStatus: 0,
            messageVerified: "",
            is_success: 0,
        }
        this.Auth = new AuthService();
    }

    componentDidMount(){
        let params = this.props.match.params;
        this.props.getMyShop(this.props.userData.token);
        authGet('vip/result/'+params.id,this.props.userData.token)
        .then(res => {
            if (parseInt(res.data.message) === 0) { 
                window.location.href = "/myshop"
            } else { 
                this.setState({
                    verifiedStatus: parseInt(res.data.message),
                    loadingPage: false
                })
            }
        }) 
        // this.props.toggleModalLogin(false);
        // this.props.getUserAfterVerify(this.props.userData.token)
        // .then(() => {
        //     this.setState({ verifiedStatus: true });
        //     // this.props.history.push('/')
        // })
        // .catch(err => {
        //     this.setState({ verifiedStatus: false, messageVerified: err.response });
        //     // this.props.history.push('/')
        // });
        // this.setState({ loadingPage: false });
    }

    render() {
        let PageTrue = (
            <div className="container p30 text-center" style={{ marginTop: "200px" }}>
                    {/* <img src={Logo} alt="Agrinis" /><br /> */}
                    <img src={VIP} alt="Verifikasi Email" />
                    <br /><br />
                    <h3 style={{ color:"#0C7F8F" }}>DAFTAR TOKO VIP BERHASIL</h3><br />
                    <p className="fs15">Selamat, kini toko anda sudah menjadi Toko VIP.<br />Nikmati kemudahan fitur-fitur khusus untuk Toko VIP.</p>
                    <br /><br />
                    <a href="/#" onClick={(e) => { e.preventDefault(); this.props.history.push('/myshop') }} className="btn btn-solid primary">Kembali ke toko</a>
                </div>
                )
        let PageFalse = (
            <div className="container p30 text-center" style={{ marginTop: "200px" }}>
                    {/* <img src={Logo} alt="Agrinis" /><br /> */}
                    <img src={VIP} alt="Verifikasi Email" />
                    <br /><br />
                    <h3 style={{ color:"#ED6362" }}>DAFTAR TOKO VIP GAGAL</h3><br />
                    <p className="fs15">Mohon maaf, toko Anda belum memenuhi syarat untuk menjadi Toko VIP. <br />Silahkan lakukan pendaftaran kembali.</p>
                    <br /><br />
                    <a href="/#" onClick={(e) => { e.preventDefault(); this.props.history.push('/myshop') }} className="btn btn-solid primary">Kembali ke toko</a>
                </div>
                )

                return (
                    <div>
                        <NewHeader
                            {...this.props}
                            stateLogin={this.props.userId}
                            toggleModalLogin={() => this.props.toggleModalLogin(true)}
                            clearFilterPage={this.clearFilterPage}
                        />
                        <CSSTransition
                            in={this.state.loadingPage}
                            timeout={300}
                            classNames="mypage"
                            unmountOnExit>
                            <LoadingPage />
                        </CSSTransition>
                        <CSSTransition
                            in={!this.state.loadingPage}
                            timeout={300}
                            classNames="mypage"
                            unmountOnExit>
                            {this.state.verifiedStatus === 2 ? PageTrue : PageFalse}
                        </CSSTransition>
                    </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.user.user,
        userId: state.user.user.user,
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    getUserAfterVerify,
    toggleModalLogin,
    getMyShop
})(RequestResult))
