import { 
    USER_DATA, 
    AFTER_VERIFY_EMAIL, 
    SET_SHOP_DATA ,
    UPDATE_LIKES_PRODUCT,
    SUBSCRIB_SHOP,
    SET_ADDRESS,
    SEND_EMAIL,
    CLOSE_NOTIF_EMAIL,
    SET_IMAGE_PROFILE,
    SET_TOKEN,
    SET_SALDO
    // UN_SUBSCRIB_SHOP
} from "../actions/types/userType";

const initialState = {
    user : {}
}

export default function(state = initialState, action){
    switch (action.type) {
        case USER_DATA:    
            return {
                ...state,
                user: action.payload
            }
        case AFTER_VERIFY_EMAIL:    
            return {
                ...state,
                user: {
                    ...state.user,
                    email_verify : action.payload.email_verify,
                    message : action.payload.message
                }
            }
        case SET_SHOP_DATA:    
            return {
                ...state,
                user: {
                    ...state.user,
                    shop : action.payload
                }
            }
        case SET_SALDO:    
            return {
                ...state,
                user: {
                    ...state.user,
                    saldo : action.payload
                }
            }
        case UPDATE_LIKES_PRODUCT:    
            return {
                ...state,
                user: {
                    ...state.user,
                    product_favorite : action.payload
                }
            }
        case SUBSCRIB_SHOP:    
            return {
                ...state,
                user: {
                    ...state.user,
                    subscribes : action.payload
                }
            }
        case SET_ADDRESS:    
            return {
                ...state,
                user: {
                    ...state.user,
                    current_address : action.payload
                }
            }
        case SEND_EMAIL:    
            return {
                ...state,
                user: {
                    ...state.user,
                    sendEmail : 1
                }
            }
        case CLOSE_NOTIF_EMAIL:    
            return {
                ...state,
                user: {
                    ...state.user,
                    closeNotif : 1
                }
            }
        case SET_IMAGE_PROFILE:    
            return {
                ...state,
                user: {
                    ...state.user,
                    image_url : action.payload
                }
            }
        case SET_TOKEN:    
            return {
                ...state,
                user: {
                    ...state.user,
                    token : action.payload
                }
            }
        default:
            return state;
    }
}