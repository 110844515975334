import axios from 'axios';
// import {headers} from '../headers';
import { API_URL } from '../config';
import {
    SET_DATA_PRODUCT,
    // SET_DATA_SHOP,
    SET_DATA_BANK,
    CHANGE_QUANTITY,
    SET_HISTORY,
    // SET_SHOP_SHIPMENT,
    CLEAR
//    CREATE_INVOICE
} from './types/transactionType';


export const setDataCheckout = data => dispatch => {
    dispatch({
        type : SET_DATA_PRODUCT,
        payload : data
    });
}


export const clearDataTransaction = () => dispatch =>{
    return Promise.resolve().then(() => {
        dispatch({
            type : CLEAR
        });
    })
}

export const setHistoryBrowser = data => (dispatch, getState) => {
    dispatch({
        type : SET_HISTORY,
        payload : data
    });

    return Promise.resolve();
    
}

export const fetchAllBank = (token) => dispatch => {
    return axios.get(`${API_URL}/bank`, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
    .then(res => {
        dispatch({
            type: SET_DATA_BANK,
            payload : res.data.data
        })
    })
}

export const changeQuantity = (data) => {
    return{
        type: CHANGE_QUANTITY,
        payload: data
    }
}


export const createInvoice = (data, token) => {

    return axios.post(`${API_URL}/invoice`, data , {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}


export const getInvoices = (flag, token) => {
    return axios.post(`${API_URL}/get_invoice`, flag ,{
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}

export const getMoreInvoices = (flag, url, token) => {
    return axios.post(url, flag ,{
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}


export const getDetailInvoice = (id, token) => {
    return axios.get(`${API_URL}/invoice/${id}`, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}

export const getSellerInvoice = (id, token) => {
    return axios.get(`${API_URL}/seller_invoice/${id}`, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}


/**
 * FOR SHOP
 */
export const updateInvoiceBiayaPengiriman = (data, token) => {
    return axios.put(`${API_URL}/invoice_update_biaya_pengiriman`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}
export const updateInvoicePengiriman = (data, token) => {
    return axios.put(`${API_URL}/invoice_update_pengiriman`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}
export const updateInvoiceDitolak = (data, token) => {
    return axios.put(`${API_URL}/invoice_update_ditolak`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}



/**
 * FOR USER
 */
export const updateInvoicePayment = (data, token) => {
    return axios.put(`${API_URL}/invoice_update_pembayaran`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}

export const updateInvoiceReceived = (data, token) => {
    return axios.put(`${API_URL}/invoice_update_diterima`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}

export const setUlasan = (data, token) => {
    return axios.post(`${API_URL}/invoice/set_ulasan`, data, {
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    })
}

export const cancelInvoice = (data, token) => {
    return axios.put(`${API_URL}/invoice_update_dibatal`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
        }
    })
}

export const uploadFotoUlasan = (data, token) => {
    return axios.post(`${API_URL}/invoice/ulasan_poto`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
        }
    })
}
