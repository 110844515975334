import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ReactLoading from 'react-loading';
import NumberFormat from 'react-number-format';
import validator from 'validator';
import * as moment from "moment";
import { CSSTransition } from 'react-transition-group';
import Swal from 'sweetalert2';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { privateChannel } from '../../../socket';
import {
    API_URL
} from '../../../config';

import IconSaldo from '../../../assert/icon/saldo.png';
import "react-datepicker/dist/react-datepicker.css";
import id from "date-fns/locale/id";
import 'moment/locale/id';

import {
    setUserSaldo
} from '../../../actions/userAction';

import {
    toggleModalLogin
} from '../../../actions/registerActions';


import {
    NewHeader,
} from "../../../components";
import { 
    authGet,
    FormatUang,
    AlertError,
    AlertWarning,
    authPost,
    authDelete,
    Toast,
    authPostPaginate,
    RedConfirmWarning
} from '../../../helper';
import WalletLoading from '../../../components/Loading/WalletLoading';
import i18n from '../../../i18n';
import NetworkDetector from '../../PUBLIC/NetworkDetector';

moment.locale('id');
registerLocale("id", id);
const Minimal = styled.span`
    font-style: italic;
    font-weight: 300;
    color: #009db2;
    float: right;
`;
class Wallet extends Component {

    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            loadData: false,
            loadingDataModal : false,
            loading_pdf: false,
            active_tab: 1,
            saldo: 0,
            datas:[],
            pagination: {},
            shop_banks: [],
            nominal : "",
            shop_bank_id: "",
            new_shop_bank: {
                master_bank_id : "",
                nama_rekening: "",
                nomor_rekening: ""
            },
            new_biaya_admin: false,
            master_banks: [],
            loadingTarikTunai: false,
            loadingMoreData: false,
            start_periode: '',
            end_periode: '',
            sort_id: 1,
            withdraw_detail: {}
        }
    }

    componentWillMount(){
        privateChannel(this.props.userData)
        .notification(data => {
            if(data.type === "penarikan-dana"){
                this.setState({
                    start_periode: '',
                    end_periode: '',
                    sort_id: 1,
                    active_tab: 1,
                    loadData:true
                }, () => {
                    this.getData();
                })
            }
        })
    }

    componentDidMount(){
        this.getData();
    }

    getData() {   
        authPost({},`wallet`, this.props.userData.token)
        .then(res => {
            this.setState({
                datas: res.data.data,
                saldo: res.data.saldo,
                pagination: res.data.meta.pagination,
                master_banks: res.data.master_banks,
                loadingPage: false,
                loadData:false
            }, () => {
                this.props.setUserSaldo(res.data.saldo);
            })
        })
        .catch(err => {
            this.setState({loadingPage: false}, () => {
                AlertError.fire({text: err.response.data.message});
            })
        })
    }

    setTab(e, tab){
        e.preventDefault();
        this.setState({
            active_tab: tab,
            loadData: true,
            start_periode: '',
            end_periode: '',
            sort_id: 1
        }, () => {
            let type = "all";
            if(tab === 2){
                type = "pemasukan";
            }else if(tab === 3){
                type = "pengeluaran";
            }

            let data = {
                type,
                start_periode: this.state.start_periode !== '' ? moment(this.state.start_periode).format("YYYY-MM-D") : '',
                end_periode: this.state.end_periode !== '' ? moment(this.state.end_periode).format("YYYY-MM-D") : '',
            }

            authPost(data ,`wallet`, this.props.userData.token)
            .then(res => {
                this.setState({
                    datas: res.data.data,
                    saldo: res.data.saldo,
                    pagination: res.data.meta.pagination,
                    loadData: false
                }, () => {
                    this.props.setUserSaldo(res.data.saldo);
                })
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message});
            })
        });
    }

    getDataBank = () => {
        Swal.fire({
            title: i18n.t("Wallet:alert.title"),
            input: "password"
        })
        .then(result => {
            if(result.value){
                authPost({password : result.value}, `user/valid`, this.props.userData.token)
                .then(response => {
                    if(response.data.valid){
                        window.$("#modalTarikSaldo").modal("show");
                        this.setState({loadingDataModal: true})
                        authGet(`shop_profile/get_bank`, this.props.userData.token)
                        .then(res => {
                            this.setState({
                                loadingDataModal: false,
                                shop_banks: res.data
                            })
                        })
                        .catch(err => {
                            this.setState({loadingDataModal: false})
                            AlertError.fire({text: err.response.data.message});
                        })
                    }
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
            
        })
        
    }

    onChangeNominal(value){
        this.setState({nominal: value});
    }

    onChangeShpoBankId(id){
        this.setState({
            shop_bank_id: id
        })
    }

    onChangeNewShopBank = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        if (e.target.name === "master_bank_id") { 
            this.setState({
                new_biaya_admin: e.target[e.target.selectedIndex].getAttribute('data-biaya-admin')
            })
        }
        this.setState({
            new_shop_bank : {
                ...this.state.new_shop_bank,
                [e.target.name] : e.target.name === "nomor_rekening" ? validateValue : e.target.value
            }
        })
    }

    validateWithdraw(data){
        if(validator.isEmpty(data.nominal)){
            AlertWarning.fire({text: i18n.t("Wallet:validation.nominal")});
            return false;
        }
        else if(data.shop_bank_id === ""){
            AlertWarning.fire({text: i18n.t("Wallet:validation.tujuan")});
            return false;
        }

        else if(!data.shop_bank_id){

            if(data.new_shop_bank.master_bank_id === ""){
                AlertWarning.fire({text: i18n.t("Wallet:validation.bank")});
                return false;
            }

            else if(validator.isEmpty(data.new_shop_bank.nama_rekening )){
                AlertWarning.fire({text: i18n.t("Wallet:validation.nama")});
                return false;
            }

            else if(data.new_shop_bank.nomor_rekening === ""){
                AlertWarning.fire({text: i18n.t("Wallet:validation.nomor")});
                return false;
            }
        }
        return true;
    }

    getFilterData = (e) => {
        e.preventDefault();

        let type = "all";

        if(this.state.active_tab === 2){
            type = "pemasukan"
        }
        else if(this.state.active_tab === 3){
            type = "pengeluaran";
        }

        let data = {
            type,
            start_periode: this.state.start_periode !== '' ? moment(this.state.start_periode).format("YYYY-MM-D") : '',
            end_periode: this.state.end_periode !== '' ? moment(this.state.end_periode).format("YYYY-MM-D") : '',
            sort_id: this.state.sort_id
        };

        this.setState({loadData: true})
        authPost(data, `wallet`, this.props.userData.token)
        .then(res => {
            this.setState({
                datas: res.data.data,
                saldo: res.data.saldo,
                pagination: res.data.meta.pagination,
                loadData: false,
            })
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }

    storeWithDraw = (e) => {
        e.preventDefault();
        let data = {
            nominal : this.state.nominal,
            shop_bank_id: this.state.shop_bank_id,
            new_shop_bank: this.state.new_shop_bank
        }


        if(this.validateWithdraw(data)){
            
            this.setState({loadingTarikTunai: true});
            authPost(data, `wallet/penarikan`, this.props.userData.token)
            .then(res => {
                this.setState({
                    nominal: "",
                    shop_bank_id: "",
                    new_shop_bank: {
                        master_bank_id: "",
                        nama_rekening: "",
                        nomor_rekening: ""
                    },
                    loadingTarikTunai: false
                }, () => {
                    this.tabAll.click();
                    window.$("#modalTarikSaldo").modal("hide");
                })
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message});
                this.setState({loadingTarikTunai: false});
            })
        }
    }

    onCancelWithdraw = (e) => {
        e.preventDefault();
        RedConfirmWarning.fire({
            text: i18n.t("Wallet:confirm_cancel")
        })
        .then(result => {
            if(result.value){
                authDelete(`withdraw/cancel`, this.props.userData.token)
                .then(res => {
                    Toast.fire({
                        type: "success",
                        title: i18n.t("Wallet:success_cancel")
                    })
                    this.tabAll.click();
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message})
                })
            }
        })
    }

    loadMoreData = () => {
        let { next } = this.state.pagination.links;
        let type = "all";

        if(this.state.active_tab === 2){
            type = "pemasukan"
        }
        else if(this.state.active_tab === 3){
            type = "pengeluaran";
        }

        let data = {
            type,
            start_periode: this.state.start_periode !== '' ? moment(this.state.start_periode).format("YYYY-MM-D") : '',
            end_periode: this.state.end_periode !== '' ? moment(this.state.end_periode).format("YYYY-MM-D") : '',
            sort_id: this.state.sort_id
        };

        this.setState({loadingMoreData: true});
        authPostPaginate(data, next, this.props.userData.token)
        .then(res => {
            this.setState({
                datas : [
                    ...this.state.datas,
                    ...res.data.data,
                ],
                pagination : res.data.meta.pagination,
                loadingMoreData: false
            })
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
            this.setState({loadingMoreData: false});
        })
    }

    onClickMutasi = (e) => {
        e.preventDefault();
        this.setState({loading_pdf: true})
        let data = {
            start_periode: this.state.start_periode !== '' ? moment(this.state.start_periode).format("YYYY-MM-DD") : '',
            end_periode: this.state.end_periode !== '' ? moment(this.state.end_periode).format("YYYY-MM-DD") : '',
        };
        fetch(`${API_URL}/wallet/generate_mutasi`, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer '+this.props.userData.token,
                'Content-Type': 'application/json'
            }),
            'body' : JSON.stringify(data)
        })
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Mutasi.pdf";
                this.setState({loading_pdf: false}, () => {
                    a.click();
                })
            });
        })
        .catch(err => {
            this.setState({loading_pdf: false});
            AlertError.fire({ text: err.response.data.message });
        })
    }

    getWithdrawDetail = (event, id) => { 
        event.preventDefault()
        let data = {
            id: id
        }
        this.setState({
            withdraw_detail: {}
        })
        authPost(data, 'withdraw/detail', this.props.userData.token).then(res => { 
            this.setState({
                withdraw_detail: res.data
            }, () => {
                window.$("#modalInfoPenarikan").modal("show");
            })
            
        })
    }

    hasMoreData = () => !(this.state.datas.length === (this.state.pagination ? this.state.pagination.total : -1 ));

    render() {

        // let sub_saldo = this.state.saldo;
        let row_data = [];

        _.forEach(this.state.datas, (data, i) => {
            row_data.push(
                <tr className={data.className} key={i}>
                    <td className="p10">
                        {
                            data.type === "penarikan"
                            ? <p className="semibold mb0 fs16">{this.props.lng === "en" ? data.label_en : data.label} {data.no_referensi}</p>
                            : <p className="semibold mb0 fs16">{this.props.lng === "en" ? data.label_en : data.label} <Link to={`/transaction/${data.link_ref}/${data.ref_id}`}>#{data.no_referensi}</Link></p>
                        }
                        
                        <span>{moment(data.tanggal).format("DD MMM YYYY HH:mm")}</span>
                    </td>
                    <td align="right" className="p10" colSpan={data.status !== 1 ? "2" : "0"}>
                        <p className="semibold mb0 fs16">{data.op} Rp <FormatUang value={data.value} /></p>
                        <span className={data.message ? "regularitalic" : ""}>
                            {
                                this.props.lng === "en" ? data.message_en : data.message
                                // ? data.message
                                // : this.state.active_tab === 1 
                                //     ?<React.Fragment>Saldo : <FormatUang value={sub_saldo} /></React.Fragment>
                                //     : null
                            }
                        </span>
                        {
                            data.type === "penarikan" ? <a href="/#" className="ml10" onClick={(e) => this.getWithdrawDetail(e, data.ref_id)}>Informasi Penarikan</a> : null
                        }
                    </td>
                    {
                        data.status === 1 ? 
                    <td align="right" className="p10">
                        <button className="btn btn-md btn-solid primary" onClick={this.onCancelWithdraw}>{i18n.t("Wallet:batalkan")}</button>
                    </td>
                    : null
                    }
                </tr>
            )

            // if(data.type === "penarikan"){
            //     if(data.status === 2){
            //         sub_saldo += data.value;
            //     }
            // }else{
            //     sub_saldo -= data.value;
            // }
        });

        let Page = (
            <div className="container-fluid">
                <div className="basic-container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="fas fa-home mr0"></i></Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{i18n.t("Wallet:dompet")}</li>
                        </ol>
                    </nav>
                    <br />
                    <h1 className="page-title">{i18n.t("Wallet:dompet")}</h1>
                    <div className="box-outline p20 w20p pull-left mr20">
                        <img src={IconSaldo} alt="Saldo" className="mr10" />
                        <span className="inline-block semibold fs18">{i18n.t("Wallet:saldo")}</span>
                        <span className="block fs30 primary mb30">Rp <FormatUang value={this.state.saldo} /></span>

                        <a href="/" className="btn btn-outline btn-block primary" data-toggle="modal" onClick={this.getDataBank}>{i18n.t("Wallet:tarik_saldo")}</a>
                    </div>
                    <div className="w75p pull-left">
                        <h1 className="content-title">{i18n.t("Wallet:mutasi_title")}</h1>
                        {this.state.loading_pdf ? <div className="pull-right ml10"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div> : <a href="/#" className="btn btn-solid btn-sm primary pull-right ml10" onClick={this.onClickMutasi}>{i18n.t("Wallet:mutasi")}</a>}
                        <a href="/#" className="btn btn-solid btn-sm primary pull-right ml10" onClick={this.getFilterData}>{i18n.t("Wallet:tampilkan")}</a>
                        <div className="sorting-input w250">
                            <select className="form-control" onChange={(e) => this.setState({sort_id: e.target.value})} value={this.state.sort_id}>
                                <option value={1}>{i18n.t("Wallet:terbaru")}</option>
                                <option value={2}>{i18n.t("Wallet:terlama")}</option>
                            </select>
                            <label><i className="fas fa-sort-amount-down"></i></label>
                        </div>
                        <div className="filter-input-right">
                        <div className="date-range">
                            <div className="pull-left w45p">
                                <DatePicker
                                    selected={this.state.start_periode}
                                    customInput={<input type="text" className="form-control datepicker" />}
                                    onChange={date => this.setState({start_periode : date})}
                                    placeholderText={i18n.t("Wallet:periode_awal")}
                                    showYearDropdown
                                    showMonthDropdown
                                    dateFormat="d MMMM yyyy"
                                    dropdownMode="select"
                                    locale={id}
                                />
                                {/* <input type="text" className="form-control datepicker" /> */}
                            </div>
                            <span className="pull-left text-center w10p"> - </span>
                            <div className="pull-left w45p">    
                                <DatePicker
                                    selected={this.state.end_periode}
                                    customInput={<input type="text" className="form-control datepicker" />}
                                    onChange={date => this.setState({end_periode : date})}
                                    placeholderText={i18n.t("Wallet:periode_akhir")}
                                    showYearDropdown
                                    showMonthDropdown
                                    dateFormat="d MMMM yyyy"
                                    dropdownMode="select"
                                    locale={id}
                                />
                                {/* <input type="text" className="form-control datepicker"/> */}
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <label><i className="fas fa-calendar-alt"></i></label>
                    </div>
                        <div className="content-tab">
                            <ul id="dompet_tab">
                                <li className={this.state.active_tab === 1 ? "active": ""}><a href="/" onClick={(e) => this.setTab(e, 1)} ref={input => this.tabAll = input}>{i18n.t("Wallet:tab.semua")}</a></li>
                                <li className={this.state.active_tab === 2 ? "active": ""}><a href="/" onClick={(e) => this.setTab(e, 2)}>{i18n.t("Wallet:tab.pemasukan")}</a></li>
                                <li className={this.state.active_tab === 3 ? "active": ""}><a href="/" onClick={(e) => this.setTab(e, 3)}>{i18n.t("Wallet:tab.pengeluaran")}</a></li>
                            </ul>
                            <div className="content-tab-detail">
                                {
                                    this.state.loadData
                                    ? <p>{i18n.t("Wallet:memuat")}</p>
                                    : <table className="table table-form">
                                        <tbody>
                                            {row_data}
                                        </tbody>
                                    </table>
                                }
                                {
                                    this.hasMoreData()
                                    ? <div className="text-center">
                                        {
                                            this.state.loadingMoreData
                                            ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                            : <button className="btn btn-solid primary" onClick={this.loadMoreData}>{i18n.t("Wallet:data_lainnya")}</button>
                                        }
                                    </div>
                                    : null
                                }
                                
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        );

        return (
            <div className="mg-top">
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <WalletLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {Page}
                </CSSTransition>
                <div className="modal fade modal-2" id="modalTarikSaldo" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form>
                        {
                                this.state.loadingDataModal
                                ? <div className="modal-content">
                                    <div className="modal-body text-center" style={{height: "250px"}}>
                                        <div style={{position: "relative", top:"35%", left: "40%"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" /></div>
                                    </div>
                                </div>
                                : <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{i18n.t("Wallet:modal_tarik.title")}</h2>
                                    <div className="scroll-y">
                                        <div className="form-group-div">
                                            <label>{i18n.t("Wallet:modal_tarik.nominal")}</label><br />
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Rp</span>
                                                </div>
                                                <NumberFormat
                                                    type="text"
                                                    className="form-control mb5 right"
                                                    value={this.state.nominal} 
                                                    displayType={'input'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    onValueChange={(values) => this.onChangeNominal(values.value)}
                                                    />
                                            </div>
                                            <Minimal>{i18n.t("Wallet:modal_tarik.minimal")}</Minimal>
                                        </div><br />
                                        <div className="form-group-div">
                                            <label>{i18n.t("Wallet:modal_tarik.rekening")}</label><br />
                                            {
                                                this.state.shop_banks.map(shop_bank => (
                                                    <div className="box-outline" key={shop_bank.id}>
                                                        <table cellPadding="10">
                                                            <tbody>
                                                                <tr>
                                                                    <td valign="top" className="w50">
                                                                        <input 
                                                                            type="radio" 
                                                                            name="shop_bank_id" 
                                                                            value={shop_bank.id}
                                                                            checked={parseInt(this.state.shop_bank_id) === parseInt(shop_bank.id)}
                                                                            onChange={() => this.onChangeShpoBankId(shop_bank.id)}
                                                                            />
                                                                    </td>
                                                                    <td className="w100"><img src={shop_bank.bank_image} alt={shop_bank.bank_name} /></td>
                                                                    <td>
                                                                        <span className="grey">{shop_bank.bank_name}</span><br />
                                                                        <p style={{ marginBottom: "0px" }}>{shop_bank.account_number}<br />
                                                                        {shop_bank.account_name}</p>
                                                                        {
                                                                            shop_bank.biaya_admin ? <><span className="yellow">Akan ada biaya admin</span><br /></> : null
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))
                                            }
                                            
                                            <div className="box-outline">
                                                <table cellPadding="10" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top" className="w50">
                                                                <input 
                                                                    type="radio" 
                                                                    name="option_bank" 
                                                                    value="newbank"
                                                                    checked={this.state.shop_bank_id === null}
                                                                    onChange={() => this.onChangeShpoBankId(null)}
                                                                    />
                                                            </td>
                                                            <td colSpan="2">
                                                                <span>{i18n.t("Wallet:modal_tarik.rekening_baru")}</span>
    
                                                                <div className="pt20 td-newbank" style={{display: this.state.shop_bank_id === null ? "" : "none"}}>
                                                                    <div className="form-group-div">
                                                                        <label>{i18n.t("Wallet:modal_tarik.bank")}</label><br />
                                                                        <select 
                                                                            name="master_bank_id"
                                                                            className="form-control"
                                                                            value={this.state.new_shop_bank.master_bank_id}
                                                                            onChange={this.onChangeNewShopBank}
                                                                            >
                                                                            <option>{i18n.t("Wallet:modal_tarik.pilih_bank")}</option>
                                                                            {
                                                                                this.state.master_banks.map(master_bank => (
                                                                                    <option
                                                                                        key={master_bank.id}
                                                                                        value={master_bank.id}
                                                                                        data-biaya-admin={master_bank.biaya_admin}
                                                                                    >{master_bank.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        {
                                                                            this.state.new_biaya_admin === "true" ? <><br /><span className="yellow">Akan ada biaya admin</span><br /></> : null
                                                                        }                                                                        
                                                                    </div>
                                                                    <div className="form-group-div">
                                                                        <label>{i18n.t("Wallet:modal_tarik.nama_rekening")}</label><br />
                                                                        <input 
                                                                            name="nama_rekening"
                                                                            type="text" 
                                                                            className="form-control"
                                                                            value={this.state.new_shop_bank.nama_rekening}
                                                                            onChange={this.onChangeNewShopBank}
                                                                            />
                                                                    </div>
                                                                    <div className="form-group-div">
                                                                        <label>{i18n.t("Wallet:modal_tarik.nomor_rekening")}</label><br />
                                                                        <input 
                                                                            name="nomor_rekening"
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            value={this.state.new_shop_bank.nomor_rekening}
                                                                            onChange={this.onChangeNewShopBank}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="notification-box sm"><div className="info"><i className="fas fa-info-circle"></i><p>{i18n.t("Wallet:modal_tarik.notice")}<br />{i18n.t("Wallet:modal_tarik.notice2")}</p></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingTarikTunai
                                        ? <div className="loading-btn mb20" style={{marginRight: "50px"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("Wallet:modal_tarik.batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.storeWithDraw}>{i18n.t("Wallet:modal_tarik.simpan")}</button>
                                        </React.Fragment>
                                    }
                                    
                                    
                                </div>
                            </div>
                            }
                        
                    </form>
                </div>
            </div>
            <div className="modal fade modal-2" id="modalInfoPenarikan" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div class="modal-body">
                            <a href="/#" class="pull-right" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></a>
                            <h2>INFORMASI PENARIKAN SALDO</h2>
                            <div class="trans-payment-info">
                                <div class="p30 bg-grey">
                                    <div class="text-center"><span class="semibold fs20 block mb30">Informasi Penarikan Saldo</span><span class="block mb20">Total penarikan diterima</span><span class="semibold fs30"> Rp &nbsp;<FormatUang value={this.state.withdraw_detail.nominal_final} /></span></div>
                                    <div class="notification-box sm mt20">
                                        <div class="info">
                                            <p>Bila ada biaya transfer bank, akan dibebankan ke pengguna.</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <table width="100%" cellpadding="3">
                                        <tbody>
                                            <tr>
                                                <td>Nominal Penarikan</td>                                   
                                                <td width="30%" align="right"> Rp &nbsp;<FormatUang value={this.state.withdraw_detail.nominal} /></td>
                                            </tr>
                                            <tr>
                                                <td>Biaya Transfer Bank</td>
                                                <td width="30%" align="right"> Rp &nbsp;<FormatUang value={this.state.withdraw_detail.biaya_admin} /></td>
                                            </tr>
                                            <tr>
                                                <td class="fs15 semibold">Total penarikan diterima</td>
                                                <td class="fs15 semibold" align="right"> Rp &nbsp;<FormatUang value={this.state.withdraw_detail.nominal_final} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    <table width="100%" cellpadding="3">
                                        <tbody>
                                            <tr>
                                                <td width="30%">Bank</td>
                                                <td align="right"><span><img src={this.state.withdraw_detail.image_bank} alt="transaksi" /> {this.state.withdraw_detail.nama_bank}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Nomor Rekening</td>
                                                <td align="right"><span>{this.state.withdraw_detail.nomor_rekening}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Nama Rekening</td>
                                                <td align="right">{this.state.withdraw_detail.nama_rekening}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            </div>
        )
    }
}

const mapStateToPros = state => {
    return {
        userData : state.user.user
    }
}

export default NetworkDetector(withNamespaces("Wallet")(connect(mapStateToPros, {
    setUserSaldo,
    toggleModalLogin
})(Wallet)));