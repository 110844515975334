import React from 'react'
import classNames from 'classnames';

const Notification = (props) => {

    let icon = classNames({
        "fa-info-circle" : props.type === 'info',
        "fa-check-circle" : props.type === "success",
        "fa-exclamation-circle" : props.type === "warning" || props.type === "danger"
    });
    return (
        <div>
            {
                // !props.isComplain ? 
                //     <div className="notification-box">
                //         <div className="info">
                //             <i className="fas fa-info-circle"></i>
                //             <p>{props.message}</p>
                //         </div>
                //     </div>
                //      : 
                    <div className="notification-box">
                        <div className={props.type}>
                            <i className={`fas ${icon}`}></i>
                            <p>{props.message}</p>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Notification