import { createSelector } from 'reselect';


const selectTransaction = state => state.transaction;


export const transactionItems = createSelector(
    [selectTransaction],
    transaction => transaction.items
);

export const transactionShop = createSelector(
    [selectTransaction],
    transaction => transaction.shop
);

export const transactionShopShipment = createSelector(
    [selectTransaction],
    transaction => transaction.shipments
);


export const transactionItemsTotal = createSelector(
    [transactionItems],
    transaction => transaction.reduce(
        (accumalatedQuantity, cartItem) =>
            accumalatedQuantity + (!cartItem.fadeOut ? cartItem.sub_total : 0 ) ,
        0
    )
);

const selectKeranjang = state => state;

export const getTotalBelanja = createSelector(
    [selectKeranjang],
    transaction => transaction.reduce(
        (accumalatedQuantity, cartItem) =>
            accumalatedQuantity + (!cartItem.fadeOut ? cartItem.sub_total : 0 ) ,
        0
    )
);

export const getTotalBerat = createSelector(
    [selectKeranjang],
    transaction => transaction.reduce(
        (accumalatedQuantity, cartItem) =>
            accumalatedQuantity + (!cartItem.fadeOut ? (cartItem.berat * cartItem.quantity) : 0 ) ,
        0
    )
);