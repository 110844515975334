import { FETCH_SHIPMENTS, FETCH_SHOP_SHIPMENT } from "../actions/types/shipmentType";

const initialState = {
    items: [],
    shop: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case FETCH_SHIPMENTS:
            return {
                ...state,
                items: action.payload
            }
        case FETCH_SHOP_SHIPMENT:
            return {
                ...state,
                shop: action.payload
            }
        default:
            return state;
    }
}