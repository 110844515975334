import React from 'react'

const FloatingNotif = props => {
    return (
        <div className="floating-notif">
            <a href="/#" className="pull-right mt10 close" onClick={props.closeNotif}><i className="fas fa-times fs11"></i></a>
            <div className={props.type}>
                <i className="fas fa-exclamation-circle"></i>
                <p>{props.text} 
                    {/* {
                        props.sendEmail
                        ? null
                        : props.loading
                            ? <span>...</span>
                            : <a href="/#" onClick={props.resendEmail}>Klik disini</a>
                    } */}
                </p>
            </div>
        </div>
    )
}

export default FloatingNotif