import React from 'react'
import { Link } from 'react-router-dom'
import EmptyResult from "../Global/EmptyResult"
import imageEmpty from '../../assert/icon/empty-result.png'
import { FormatUang } from '../../helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingInfiniteScroll from '../Loading/LadingInfiniteScroll'
import { withNamespaces } from 'react-i18next'
import i18n from '../../i18n';
// import logo_vip from '../../assert/icon/vip_logo.svg';
const MyProductGrid = props => {
    const renderLabel = (status) => {
        if(status === 1)
        {
            return <span className="label label-productactive">{i18n.t("MyProduct:aktif")}</span>
        }
        else if(status === 2)
        {
            return <span className="label label-productnonactive">{i18n.t("MyProduct:nonaktif")}</span>
        }
        else
        {
            return null
        }
    }
    if(props.loading){
        return (<p>{i18n.t("MyProduct:loading")}</p>)
    }else{
        if(props.totalData === 0){
            let msg = i18n.t("MyProduct:no_barang");
            let btnText = i18n.t("MyProduct:button");

            if(props.status === 2){
                msg = i18n.t("MyProduct:grid.no_barang");
                btnText = 0;
            }

            return <EmptyResult 
            image={imageEmpty}
            message={msg}
            buttonAction={props.emtyDataAction}
            buttonText={btnText}
        />
        }else{
            return (
                <div className="product-container" style={{display : props.grid ? "": "none"}}>
                    <InfiniteScroll
                        dataLength={props.data.length}
                        next={props.fetchMoreData}
                        hasMore={props.hasMoreData}
                        loader={<LoadingInfiniteScroll/>}
                        >
                    {props.data.map(product => {
                        let product_name ="";
                        if (product.name.length > 25) { 
                            product_name = product.name.substr(0, 21)+"...";
                        } else { 
                            product_name = product.name;
                        }
                        let product_description = "";
                        if (product.description) { 
                            if (product.description.length > 29) { 
                                product_description = product.description.substr(0, 25)+"...";
                            } else { 
                                product_description = product.description;
                            }
                        }
                        return (
                            <div className="product-div" key={product.id}>
                                { 
                                    props.activeChecked
                                    ? <div className="bulk-check">
                                            <input 
                                                type="checkbox" 
                                                checked={props.checkedProduct(product.id)} 
                                                onChange={() => props.onChangeProduct(product.id)}
                                            />
                                        </div>
                                    : null
                                }
                                
                                <Link to={`/myshop/product/view/${product.id}`} className="product-link">
                                    <div className="product-img" style={{height : "196px"}}>
                                        <img src={product.image} alt="Agrinis"/>
                                        {
                                            product.discount > 0
                                            ? <div className="discount">{product.discount}%</div>
                                            : null
                                        }
                                        {
                                            product.kondisi === 2
                                            ? <div className="second">{i18n.t("MyProduct:grid.bekas")}</div>
                                            : null
                                        }
                                    </div>
                                    <h4 title={product.name}>{product_name}</h4>
                                </Link>
                                <div className="product-desc">
                                    <p title={product.subtitle}>{product_description}</p>
                                    {  props.status === 3 ? renderLabel(product.status) : null }
                                    <div className="price">
                                        <span className="main-price">
                                        {/* <img src={logo_vip} alt="LOGO VIP"/>  */}
                                        Rp &nbsp;
                                            <FormatUang value={product.price}/>
                                        </span>
                                    </div>
                                    <div className="status">
                                        <span className="inline-block mr5 grey"><i className="far fa-heart" title="Favorit"></i> {product.like}</span>
                                        <span className="inline-block grey"><i className="far fa-eye" title="View"></i> {product.viewer}</span>
                                        <span className="inline-block pull-right">{i18n.t("MyProduct:terjual")} {product.terjual}</span>
                                    </div>
                                </div>
                            </div>
                    )})}
                    </InfiniteScroll>
                    <div className="clearfix"></div>
                </div>
            )
        }
    }
}

export default withNamespaces("MyProduct")(MyProductGrid)
