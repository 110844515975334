import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import { 
    getMyShop, 
    fetchMyProduct, 
    updateStatusProduct, 
    deleteMyProduct 
} from "../../../actions/shopActions";
import { fetchCategoryWithSub } from "../../../actions/categoryActions";
import { postMoreProductsAuth } from "../../../actions/productActions";
import {
    toggleModalLogin
} from "../../../actions/registerActions";
import {
    NewHeader,
    ListCategory,
    Filter,
} from '../../../components';

import Banner from "../../../components/Shop/_Component/Banner";
import MyProductGrid from '../../../components/Shop/MyProductGrid'
import MyProductList from '../../../components/Shop/MyProductList';
import classnames from 'classnames';
import { LoadingMyShopHeader, LodingSideCategory } from '../../../components/Loading/Loading';
import{
    ConfirmWarning,
    AlertWarning,
    get,
    AlertError,
    AlertInfo
} from '../../../helper';
import MyShopLoading from '../../../components/Loading/MyShopLoading';
import i18n from '../../../i18n';
import NetworkDetector from '../../PUBLIC/NetworkDetector'

class Products extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            grid : true,
            activeChecked : false,
            status : 1,
            sub_category : 0,
            category : 0,
            itemChecked : [],
            sub_name : '',
            sort_id : 6,
            max: '',
            min : '',
            kondisi : {
                1 : true,
                2 : true
            },
            shipment : [],
            shipmentChecked : {},
            dataPersist : {},
            diskonCheked : false,
            loadingMoreLoading : false
        }
    }

    componentWillMount(){
        this.props.getMyShop(this.props.userData.token);
        this.props.fetchCategoryWithSub();
        get(`get_shipment_detail`)
        .then(res => {
            let shipmentChecked = {};
            _.each(res.data, d => {
                shipmentChecked = {...shipmentChecked, [d.id] : true};
            })

            this.setState({shipmentChecked, shipment : res.data, loadingPage: false});
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }

    componentDidMount() {
        this.setDataPost({status : 1});
    }

    setDataPost = (data) => {
        this.setState({dataPersist : data}
        ,() => {
            this.props.fetchMyProduct(data, this.props.userData.token)
            .catch(err => {
                AlertError.fire({text: err.response.data.message});
            })
        })
    }

    setTab(status) {
        this.setState({status, activeChecked: false}
        ,() => {
            let postStatus = this.state.status === 3 ? 0 : this.state.status;
            let data = {
                ...this.state.dataPersist,
                status : postStatus
            }
            this.setDataPost(data)
        })
    }

    handleCategory(sub_category, sub_name, category){
        
        this.setState({sub_category, sub_name, category, activeChecked: false}
            ,() => {
                let data = {
                    ...this.state.dataPersist,
                    category,
                    sub_category
                }
                this.setDataPost(data)
            })
        
    }

    handleChecked(id){
        let items = this.state.itemChecked;
        let itemExist = items.find(item => item === id);
        if(itemExist){
            items.splice(items.indexOf(id),1);
            this.setState({itemChecked : items})
        }else{
            this.setState({itemChecked : [...items, id]})
        }
        
    }

    checkedItem(id){
        let items = this.state.itemChecked;
        let exist = items.find(item => item === id);
        let check = exist ? true : false;
        return check;
    }

    handleButtonActive(status){
        let items = this.state.itemChecked;
        if(items.length <= 0){
            AlertWarning.fire({text : i18n.t("Myshop:validation.tandai")});
        }else{
            ConfirmWarning.fire({
                text : i18n.t("Myshop:validation.tandai_confirm")
            }).then(result => {
                if(result.value){
                    updateStatusProduct({
                        status, 
                        products: items
                    }, this.props.userData.token)
                    .then(res => {
                        this.setState({
                            itemChecked : [],
                            activeChecked : false,
                        }, () => {
                            let data = this.state.dataPersist
                            this.setDataPost(data)
                        })
                    })
                }
            })
        }
        
    }

    handleDelete = () => {
        let items = this.state.itemChecked;
        if(items.length <= 0){
            AlertWarning.fire({text : i18n.t("Myshop:validation.tandai")});
        }else{
            ConfirmWarning.fire({
                text : "Apakah anda yakin akan Menghapus data ini?"
            }).then(result => {
                if(result.value){
                    deleteMyProduct({products : items}, this.props.userData.token)
                    .then(res => {
                        this.setState({
                            itemChecked : [],
                            activeChecked : false,
                        }, () => {
                            let data = this.state.dataPersist;
                            this.setDataPost(data);
                            if(res.data.cant_delete){
                                AlertInfo.fire({text: res.data.message});
                            }
                        })
                    })
                    .catch(err => {
                        AlertError.fire({text: err.response.data.message});
                    })
                }
            })
        }
    }

    onChangefilterHarga = (value, name) =>{
        this.setState({[name] : value})
    }

    checkedKondisi = (e) => {
        let kon = parseInt(e.target.value)
        this.setState({
            kondisi : {
                ...this.state.kondisi,
                [kon] : !this.state.kondisi[kon]
            }
        })
    }

    onChangeShipment = (item) => {
        this.setState({
            shipmentChecked : {
                ...this.state.shipmentChecked,
                [item.id] : !this.state.shipmentChecked[item.id]
            }
        })
    }

    filterProduct = () => {
        let {
            dataPersist,
            min,
            max,
            kondisi,
            shipmentChecked,
            diskonCheked,
        } = this.state;

        if(parseInt(min) > parseInt(max)){
            AlertWarning.fire({text : i18n.t("Myshop:validation.minmax")});
            return false;
        }

        let data = {
            ...dataPersist,
            min,
            max,
            kondisi : _.keys(_.pickBy(kondisi)),
            shipment :  _.keys(_.pickBy(shipmentChecked)),
        }

        if(diskonCheked){
            data = {...data, diskon : diskonCheked}
        }

        this.setDataPost(data);
    }

    resetFilterProduct = (e) => {
        e.preventDefault();
        let kondisi = {1: true, 2: true};
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = {...shipmentChecked, [d.id] : true};
        })

        this.setState({
            min : "", 
            max: "", 
            kondisi, 
            shipmentChecked, 
            diskonCheked : false,
            category : 0,
            sub_category : 0,
            sub_name: ''
        },() => {
            this.setDataPost({status : 1});
        })
    }

    sortProductShop = (e) => {
        this.setState({sort_id : e.target.value}
        ,() => {
            let data = {
                ...this.state.dataPersist,
                sort_id : this.state.sort_id
            }

            this.setDataPost(data);
        })
    }

    renderButton(status) {
        if(status === 1){
            return <button 
                    className="btn btn-solid yellow mr5" 
                    onClick={() => this.handleButtonActive(2)}
                    >
                    <i className="fas fa-eye-slash"></i>{i18n.t("Myshop:nonaktifkan")}
                </button>

        }else if(status === 2){
            return <button 
                    className="btn btn-solid primary mr5"
                    onClick={() => this.handleButtonActive(1)}
                    >
                    <i className="fas fa-eye"></i>{i18n.t("Myshop:aktifkan")}
                </button>
        }
    }

    fetchMoreData = () => {
        let { next } = this.props.pagination.links;
        let { dataPersist } = this.state;
        this.setState({loadingMoreLoading : true});
        this.props.postMoreProductsAuth(next, dataPersist, this.props.userData.token)
        .then(() => {
            this.setState({loadingMoreLoading : false});
        })
        // setTimeout(() => {
        // }, 1000);
    }

    onClickProductList = (id) => { 
        window.location.href = `/myshop/product/view/${id}`;
    }

    hasMoreData = () => !(this.props.products.length === (this.props.pagination ? this.props.pagination.total : -1 ));
    
    render() {
    const { categories } = this.props;
    let btnGridclass = classnames({
        "icon-grid-view" : true,
        "active" : this.state.grid
    })
    let btnListclass = classnames({
        "icon-list-view" : true,
        "active" : !this.state.grid
    })
    let tabAktif = classnames({
        "active" : this.state.status === 1
    })
    let tabNonAktif = classnames({
        "active" : this.state.status === 2
    })
    let tabAll = classnames({
        "active" : this.state.status === 3
    })
    
    let Page = (
        <div>
            <div className="container-fluid">
                <div className="header-banner-3 header-store">
                    {
                        !this.props.loadingHeader
                        ? <Banner {...this.props.shop}/>
                        : <LoadingMyShopHeader/>
                    }
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="container p15">
            <div className="pull-right">
                <Link to="/myshop/product/create" className="btn btn-outline primary"><i className="fas fa-plus"></i> {i18n.t("Myshop:tambah_barang")}</Link>
            </div>
            <div className="toko-tab">
                <ul>
                    <li className={tabAktif}>
                        <a href="/#" onClick={(e) => {e.preventDefault();this.setTab(1)}}>{i18n.t("Myshop:aktif")}</a>
                    </li>
                    <li className={tabNonAktif}>
                        <a href="/#" onClick={(e) => {e.preventDefault();this.setTab(2)}}>{i18n.t("Myshop:nonaktif")}</a>
                    </li>
                    <li className={tabAll}>
                        <a href="/#" onClick={(e) => {e.preventDefault();this.setTab(3)}}>{i18n.t("Myshop:semua")}</a>
                    </li>
                </ul>
            </div>
            <div className="left-side">
                <div className="category-menu">
                    <h2>{i18n.t("Myshop:kategori")}</h2>
                    {
                        this.props.loadingCategory
                        ? <LodingSideCategory/>
                        : categories.map(category => (
                            <ListCategory
                                changeCategory={() => this.handleCategory(0, category.name, category.id)}
                                parentActive={this.state.category}
                                key={category.id} 
                                active={this.state.sub_category}
                                subActive={this.state.sub_category}
                                onClick={(id, name) => this.handleCategory(id, name, category.id)}
                                {...category} />
                        ))
                    }
                </div>
                <Filter 
                    maxValue={this.state.max}
                    minValue={this.state.min}
                    kondisi={this.state.kondisi}
                    shipments={this.state.shipment}
                    shipmentChecked={this.state.shipmentChecked}
                    diskonChecked={this.state.diskonCheked}
                    onChangeHarga={this.onChangefilterHarga}
                    checkedKondisi={this.checkedKondisi}
                    onChangeShipment={this.onChangeShipment}
                    onChangeDiskon={() => this.setState({diskonCheked : !this.state.diskonCheked})}
                    filterProduct={this.filterProduct}
                    resetFilterProduct={this.resetFilterProduct}
                    />
            </div>
            <div className="category-detail">
            {
                this.props.products.length > 0
                ? <div>
                    <h3>{this.state.sub_name}</h3>

                    <div className="sorting-input">
                        <select className="form-control" value={this.state.sort_id} onChange={this.sortProductShop}>
                            <option value="2">{i18n.t("Myshop:urutkan.termurah")}</option>
                            <option value="3">{i18n.t("Myshop:urutkan.termahal")}</option>
                            <option value="4">{i18n.t("Myshop:urutkan.terlaris")}</option>
                            <option value="5">{i18n.t("Myshop:urutkan.populer")}</option>
                            <option value="6">{i18n.t("Myshop:urutkan.terbaru")}</option>
                        </select>
                        <label>{i18n.t("Myshop:urutkan.title")}</label>
                    </div>
                    <div className="pull-right"><div className={btnListclass} onClick={() => this.setState({grid : false})}></div></div>
                    <div className="pull-right mr5"><div className={btnGridclass} onClick={() => this.setState({grid : true})}></div></div>

                    <div className="bulk-container">
                        <a 
                            href="/#" 
                            onClick={(e) => { e.preventDefault();this.setState({activeChecked : true})}}
                            style={{display: this.state.activeChecked ? 'none' : ''}}
                            >
                                <i className="fas fa-edit"></i> {i18n.t("Myshop:tandai")}
                        </a>
                        <div className="bulk-button" style={{display: this.state.activeChecked ? '' : 'none'}}>
                            <button 
                                className="btn btn-solid darkgrey mr5"
                                onClick={() => this.setState({activeChecked : false, itemChecked: []})}
                                >
                                    <i className="fas fa-times"></i>{i18n.t("Myshop:batal")}
                            </button>
                            {this.renderButton(this.state.status)}
                            <button className="btn btn-solid red" onClick={this.handleDelete}><i className="fas fa-trash-alt"></i>{i18n.t("Myshop:hapus")}</button>
                        </div>
                    </div>
                    </div>
                : null
            }
            <div className="grid-view" style={{ display: this.state.grid ? '' : 'none' }}>
                <MyProductGrid 
                    data={this.props.products} 
                    grid={this.state.grid}
                    checkedProduct={(id) => this.checkedItem(id)}
                    onChangeProduct={(id) => this.handleChecked(id)}
                    activeChecked={this.state.activeChecked}
                    status={this.state.status}
                    loading={this.props.loadingData}
                    emtyDataAction={() => this.props.history.push('/myshop/product/create')}
                    totalData={this.props.products.length}
                    fetchMoreData={this.fetchMoreData}
                    hasMoreData={this.hasMoreData()}
                />
            </div>
            <div className="list-view" style={{ display: !this.state.grid ? '' : 'none' }}>
                <MyProductList 
                    data={this.props.products} 
                    grid={this.state.grid}
                    checkedProduct={(id) => this.checkedItem(id)}
                    onChangeProduct={(id) => this.handleChecked(id)}
                    activeChecked={this.state.activeChecked}
                    status={this.state.status}
                    loading={this.props.loadingData}
                    emtyDataAction={() => this.props.history.push('/myshop/product/create')}
                    totalData={this.props.products.length}
                    fetchMoreData={this.fetchMoreData}
                    hasMoreData={this.hasMoreData()}
                    loadingMoreLoading={this.state.loadingMoreLoading}
                    onClickProductList={this.onClickProductList}
                />
            </div>
            </div>
        </div>
        </div>
    );

    return (
        

        <div style={{marginTop: "62px"}}>
            <NewHeader 
                {...this.props}
                stateLogin = {this.props.userData.user}
                toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
            <CSSTransition
                in={this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
                    <MyShopLoading/>
            </CSSTransition>
            <CSSTransition
                in={!this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
                    {Page}
            </CSSTransition>
            {/* <Footer/> */}
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shop : state.shop.item,
        categories : state.categories.category_and_sub,
        loadingHeader : state.loading.fetchMyShop,
        products : state.products.items,
        pagination: state.products.pagination,
        loadingData : state.loading.loadingProduct,
        loadingCategory : state.loading.loadingCategoryWithSub,
        userData : state.user.user
    }
}

export default NetworkDetector(withNamespaces("Myshop")(connect(mapStateToProps, {
    getMyShop,
    fetchCategoryWithSub,
    fetchMyProduct,
    postMoreProductsAuth,
    toggleModalLogin
})(Products)));
