import { 
    FETCH_CATEGORIES, 
    FETCH_CATEGORY, 
    FETCH_CATEGORY_HEADER, 
    FETCH_CATEGORY_WITH_SUB,
    FETCH_SUB_CATEGORY,
    ON_CHANGE_CATEGORY_FILTER,
    ON_CHANGE_SUB_CATEGORY_FILTER
} from "../actions/types/categoryTipe";

const initialState = {
    items: [],
    item:{},
    category_and_sub : [],
    categoryActive : null,
    subActive  : null,
    sub_category : {name : ''},
    all_sub_category: [],
    all_category: []
}

export default function(state = initialState, action){
    switch(action.type){
        case FETCH_CATEGORIES:
            return {
                ...state,
                items: action.payload,
                all_category: action.payload
            }
        case FETCH_CATEGORY:
            return {
                ...state,
                item: action.payload
            }
        case FETCH_CATEGORY_HEADER:
            return {
                ...state,
                item: action.payload
            }
        case FETCH_CATEGORY_WITH_SUB:
            return {
                ...state,
                category_and_sub: action.payload,
            }
        case FETCH_SUB_CATEGORY:
            return {
                ...state,
                sub_category: action.payload,
                all_sub_category : action.payload
            }
        case ON_CHANGE_CATEGORY_FILTER:
            return {
                ...state,
                sub_category: filterSubCategory(state.all_sub_category, action.payload)
            }
        case ON_CHANGE_SUB_CATEGORY_FILTER:
            return {
                ...state,
                // items: filterCategory(state.all_category, action.payload, state.all_sub_category)
            }
        default:
            return state;
    }
}

const filterSubCategory = (currentSubCategory, categoryId) => {
    if(parseInt(categoryId) === 0){
        return currentSubCategory;
    }

    return currentSubCategory.filter(subItem => subItem.category_id === parseInt(categoryId));
    
};

// const filterCategory = (currentCategory, id, currentSubCategory) => {
//     const arr_subCategory = currentSubCategory.filter(subItem => subItem.id == id);
//     if(arr_subCategory.length > 0){
//         const category_id = arr_subCategory[0].category_id;
//         const item = currentCategory.filter(item => item.id == category_id);
//         item[0].selected = true;
//         return item;
//     }

//     return currentCategory
// };
