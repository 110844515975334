import React from 'react';
import { FormatUang } from '../../../helper';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { LabelPPNRight } from './PpnComponent';
import logo_vip from '../../../assert/icon/vip_logo.svg';


const BtnEditMode = styled.a`
    min-width: 30px;
    width: 30px;
    padding: 7px;
    text-align: center;
    height: 30px;
`;

const HargaGrosirEdit = (props) => {
    
    return (
        <tr>
            <td align="center">
                {props.min}
            </td>
            <td align="center"> - </td>
            <td align="center">
                {parseInt(props.max) === 0 ? `> ${props.min}` : props.max}
            </td>
            <td align="center"> = </td>
            {
                props.editMode
                ? props.dataEditGrosir.index === props.indexItem
                        ? <td className="pl100 pr20"> 
                        
                            <div className="form-inline">
                                <div className="form-group">
                                    <NumberFormat 
                                        type="text"
                                        value={props.dataEditGrosir.price}
                                        onValueChange={props.onChangeHargaGrosirEdit}
                                        className="form-control right edit-grosir"
                                        placeholder="Harga (Rp)"
                                        displayType={'input'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        style={{
                                            padding: "2px 2px",
                                            height: "auto",
                                            fontSize: "14px"
                                        }}/>
                                    {
                                        props.is_vip
                                        ? <NumberFormat 
                                            type="text"
                                            value={props.dataEditGrosir.price_vip}
                                            onValueChange={props.onChangeHargaGrosirVipEdit}
                                            className="form-control right edit-grosir"
                                            placeholder="Harga (Rp)"
                                            displayType={'input'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            style={{
                                                padding: "2px 2px",
                                                height: "auto",
                                                fontSize: "14px",
                                                marginLeft: "10px"
                                            }}/>
                                        : null
                                    }
                                    
                                    <BtnEditMode href="/" className="btn-secondary" onClick={props.handleCancelEditMode}><i className="fas fa-times"></i></BtnEditMode>
                                    <BtnEditMode href="/" className="primary btn-solid" onClick={props.handleOkEditMode}><i className="fas fa-check"></i></BtnEditMode>
                                </div>

                            </div>
                        </td>
                        : <React.Fragment><td className="pr20">
                            Rp <span className="pull-right"><FormatUang value={props.price}/></span>
                            {
                                props.ppn
                                ? <React.Fragment>
                                    <br/>
                                    <LabelPPNRight 
                                        style={{fontSize: "10px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.price === "" ? 0 : parseInt(props.price) + ((parseInt(props.price)*10)/ 100)}/>
                                    </LabelPPNRight>
                                </React.Fragment>
                                : null
                            }
                            
                        </td>
                        {
                            props.is_vip
                            ? <td className="pr20">
                                <img src={logo_vip} alt="LOGO VIP"/> Rp <span className="pull-right"><FormatUang value={props.price_vip}/></span>
                                {
                                    props.ppn
                                    ? <React.Fragment>
                                        <br/>
                                        <LabelPPNRight 
                                            style={{fontSize: "10px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.price_vip === "" ? 0 : parseInt(props.price_vip) + ((parseInt(props.price_vip)*10)/ 100)}/>
                                        </LabelPPNRight>
                                    </React.Fragment>
                                    : null
                                }
                            </td>
                            : null
                        }
                        
                    </React.Fragment>

                : <React.Fragment>
                    <td className="pr20">
                        Rp <span className="pull-right"><FormatUang value={parseInt(props.price)}/></span>
                        {
                            props.ppn
                            ? <React.Fragment>
                                <br/>
                                <LabelPPNRight 
                                    style={{fontSize: "10px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.price === "" ? 0 : parseInt(props.price) + ((parseInt(props.price)*10)/ 100)}/>
                                </LabelPPNRight>
                            </React.Fragment>
                            : null
                        }
                        
                    </td>
                    {
                        props.is_vip
                        ? <td className="pr20">
                            <img src={logo_vip} alt="LOGO VIP"/> Rp <span className="pull-right"><FormatUang value={parseInt(props.price_vip)}/></span>
                                {
                                    props.ppn
                                    ? <React.Fragment>
                                        <br/>
                                        <LabelPPNRight 
                                            style={{fontSize: "10px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.price_vip === "" ? 0 : parseInt(props.price_vip) + ((parseInt(props.price_vip)*10)/ 100)}/>
                                        </LabelPPNRight>
                                    </React.Fragment>
                                    : null
                                }
                                
                            </td>
                        : null
                    }
                    
                    <td align="center">
                        <div style={{ float: "left" }}>
                        <a href="/" className="mr10" onClick={props.onChangeEditMode}>
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                        {
                            props.indexItem === (props.countItem) -1
                            ? <span className="btn-remove red" onClick={props.handleRemoveHarga}>
                                <i className="fas fa-times"></i>
                            </span>
                            : null
                        }
                        </div>
                    </td>
                </React.Fragment>
            }
            
        </tr>
    )
}


export default HargaGrosirEdit
