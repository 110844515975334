import React from 'react'
import { Link } from 'react-router-dom'
// import _ from 'lodash'
import classnames from 'classnames'
import {FormatUang} from '../../helper'
import ReactLoading from 'react-loading';
import { withNamespaces } from 'react-i18next';
import logo_vip from '../../assert/icon/vip_logo.svg';
const Loading = () => <div className="loading-btn"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"15px"} width={"15px"} /></div>
const Product = product => {
    const { t } = product;
    let like = product.likes.find(p => p.product_id === product.id);
    let iconHeart = classnames({
        'fas' : true,
        'fa-heart' : true,
        'active' : like
    });

    let rating = [];

    for (let i = 0; i < 5; i++) {
        if(i < product.rating){
            rating.push(<i className="fas fa-star starred" key={i}></i>);
        }else{
            rating.push(<i className="fas fa-star" key={i}></i>);
        }
    }

    let product_name ="";
    if (product.product_name.length > 25) { 
        product_name = product.product_name.substr(0, 21)+"...";
    } else { 
        product_name = product.product_name;
    }
    let product_description = "";
    if (product.description) { 
        if (product.description.length > 29) { 
            product_description = product.description.substr(0, 25)+"...";
        } else { 
            product_description = product.description;
        }
    }

    return (
        <div key={product.id} className={`product-div ${product.className}`}>
        <Link to={`/product/${product.id}`} className="product-link" title={t("Product:lihat_produk")}>
            <div className="product-img" style={{height : '196px'}}>
                <img src={product.image} alt="Agrinis"/>
                    {
                        product.flag_discount
                        ? <div className="discount">{product.label_discount}</div>
                        : null
                    }
                    {
                        product.kondisi === 2
                        ? <div className="second">{t("Product:bekas")}</div>
                        : null
                    }
                </div>
                <h4 style={{overflowWrap: "break-word"}} title={product.product_name}>{product_name}</h4>
        </Link>

        <div className="product-desc">
            <p title={product.description}>{product_description}</p>
            <div className="rating">
                {
                    rating.map((item) => item)
                }
                &nbsp;<a href="/#" title={t("Product:lihat_ulasan")}>({product.jml_ulasan})</a>
            </div>
            <div className="price">
                <span className={`main-price ${product.grosir ? "fs14" : ""}`}>
                {
                    product.is_vip
                    ? <img src={logo_vip} alt="LOGO VIP"/>
                    : null
                }
                 {product.label_price}
                    {/* Rp <FormatUang value={product.price}/> */}
                </span>
                {
                    product.flag_discount && !product.grosir
                    ? <span className="discount">
                        <FormatUang value={product.real_price}/>
                    </span>
                    : null
                }
            </div>
            <div className="likes">
                {
                    product.loadingButton ? <Loading /> : <i className={iconHeart} title="Favorite" onClick={() => product.onClickLikeButton(product, like ? like : false)}></i>
                }
            </div>
            <div className="store">
                <Link to={`/${product.seller_domain}`} title={t("Product:lihat_toko")}>
                    <i className="fas fa-store"></i>
                    <span className="store-location">{product.city}</span>
                    <span className="store-name">{product.seller_name}</span>
                </Link>
            </div>
        </div>
    </div>
)}

export default withNamespaces("Product")(Product)
