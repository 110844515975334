import {
    SET_CATEGORY_HEADER,
    SET_SUB_CATEGORY_HEADER
} from "../actions/types/categoryTipe";
import {
    SET_CURSOR_POSITION
} from "../actions/types/userType";

const initialState = {
    category: [],
    categorySelected: [],
    subCategory: [],
    cursor: {
        x: 0,
        y: 0
    }
}

export default function (state = initialState, action) { 
    switch (action.type) {
        case SET_CATEGORY_HEADER:
            return {
                category: action.payload,
                categorySelected: action.payload[0],
                subCategory: action.payload[0].subCategory.data,
            }
        case SET_SUB_CATEGORY_HEADER:
            return {
                category: [...state.category],
                categorySelected: action.payload,
                subCategory: action.payload.subCategory.data,
            }
        case SET_CURSOR_POSITION:
            return {
                ...state,
                cursor : action.payload
            }
        default:
            return state;
    }
}