import styled from 'styled-components';

export const LabelPPN = styled.p`
    display: inline;
    margin-left: 20px;
`;

export const LabelPPNRight = styled.label`
    font-style: italic;
    font-weight: 100;
    margin-right: 10px;
    width: 90%;
`;

export const InfoPPN = styled.i`
    cursor: pointer;
`;