import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    NewHeader,
    EmptyResult,
    FilterShop, 
} from '../../../components';

import AuthService from '../../../actions/API/AuthService'
import  ReactLoading  from 'react-loading';
import LoadingInfiniteScroll from '../../../components/Loading/LadingInfiniteScroll'
import Subscribe_result from '../../../assert/icon/empty-following.png'
import { 
    // authGet, 
    authPost, 
    AlertError,  
    authPostPaginate ,
    get,
    FormatUang,
    AlertLoading
} from './../../../helper';
import {
    subScribeShop,
    unsubScribeShop,
    getMoreSubscribeShop
} from '../../../actions/userAction';
import HubungiPenjual from '../../../components/Global/HubungiPenjual';
import SubscribeLoading from '../../../components/Loading/SubscribeLoading';
import { withNamespaces } from 'react-i18next'
import i18n from '../../../i18n';
import logo_vip from '../../../assert/icon/vip_logo.svg';
import NetworkDetector from '../../PUBLIC/NetworkDetector'

class Subscribes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingPage: true,
            dataPersist: {},
            pagination: {},
            subscribed: [],
            loadingSubscribeShop: true,
            flag: false,
            seller_selected: { image: "", addresses: { data: [{ city: "" }]}},
            wilayah: [],
            provinsi: [],
        }
        this.Auth = new AuthService();
    }

    componentDidMount() {
        this.setState({loadingSubscribeShop: true, sort_id: 1});
        let { dataPersist } = this.state;
        let data = { ...dataPersist, sort_id: 1 }
        authPost(data, "subscribe_shop/sort", this.props.userData.token)
        .then(res =>{
            this.setState({
                subscribed: res.data.data,
                seller_selected: res.data.data.length > 0 ? res.data.data[0] : { image: "", addresses: { data: [{ city: "" }] } },
                pagination: res.data.meta.pagination,
                loadingPage: false,
                loadingSubscribeShop: false,
            }, () => {
                get(`get_province`).then(res2 => {
                    this.setState({provinsi: res2.data}, () => {
                        let arr = this.state.provinsi.map(e => {
                            return parseInt(e.value)
                        });
                        this.setState({wilayah: arr});
                    });
                });
            })
        })
    }

    setDataPost = (data) => {
        let { token } = this.props.userData;
        this.setState({ dataPersist: data,loadingSubscribeShop: true }
            , () => {
                authPost(data, "subscribe_shop/sort", token)
                .then(res => {
                    this.setState({
                        subscribed: res.data.data,
                        pagination: res.data.meta.pagination,
                        loadingSubscribeShop: false,
                        loadingMoreData: false,
                        flag: true
                    })
                })
            })
    }
    sortSubscribe = (e) => {
        this.setState({ sort_id: e.target.value }
            , () => {
                let { dataPersist } = this.state;
                let data = { ...dataPersist, sort_id: this.state.sort_id }

                this.setDataPost(data);
            })
    }
    unSubscribe = (e, shop_id) => {
        e.preventDefault();

        let data = { shop_id }
        AlertLoading.fire({})
        this.props.unsubScribeShop(data, this.props.userData.token, this.props.lng).then(() => {
        //   let datax = this.state.subscribed.map(data => data.id === shop_id ? {...data, isSub: false} :{...data} );
            this.setState({
                subscribed: this.state.subscribed.filter(data => data.id !== shop_id),
                pagination: {...this.state.pagination, total: this.state.pagination.total -1}
            });
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }
    onSubscribe = (e, shop_id) => {
        e.preventDefault();

        let data = { shop_id }
        AlertLoading.fire({})
        this.props.subScribeShop(data, this.props.userData.token, this.props.lng).then(() => {
            let datax = this.state.subscribed.map(data => data.id === shop_id ? { ...data, isSub: true } : { ...data });
            this.setState({
                subscribed: datax
            });
        })
            .catch(err => {
                AlertError.fire({ text: err.response.data.message });
            })
    }

    loadMoreData = () => {
        let { next } = this.state.pagination.links;
        this.setState({ loadingMoreData: true });
        let { dataPersist } = this.state;
        let data = { ...dataPersist, sort_id: this.state.sort_id }
        authPostPaginate(data, next, this.props.userData.token)
            .then(res => {
                this.setState({
                    subscribed: [
                        ...this.state.subscribed,
                        ...res.data.data
                    ],
                    pagination: res.data.meta.pagination,
                    loadingPage: false,
                    loadingSubscribeShop: false,
                    loadingMoreData: false
                })
            })
    }

    onClickHubungiPenjual = (e) => {
        e.preventDefault()
        this.setState({
            seller_selected: this.state.subscribed[e.currentTarget.dataset.id]
        }, () => {
            window.$("#modalHubungiPenjual").modal("show");
        })
    }
    checkedWilayah = (e) => { 
        let wil = parseInt(e.target.value)
        if(this.state.wilayah.includes(wil)) {
            this.setState({
                wilayah: _.without(this.state.wilayah, wil)
            })
        } else { 
            this.setState({
                wilayah: [...this.state.wilayah, wil]
            })
        }
    }

    filterShops = (e) => { 
        e.preventDefault();
        this.setState({loadingSubscribeShop: true})
        let data = {
            keyword: this.props.match.params.keyword,
            sort_id: this.state.sort_id_shop, 
            filter: this.state.wilayah,
        }
        authPost(data, 'subscribe_shop/filter', this.props.userData.token).then(res => {
            this.setState({
                subscribed: res.data.data,
                pagination: res.data.meta.pagination,
                loadingPage: false,
                loadingSubscribeShop: false,
                loadingMoreData: false
            });
        });
    }
    resetFilterShops = (e) => { 
        e.preventDefault();
        let arr = this.state.provinsi.map(e => {
            return parseInt(e.value)
        });
        this.setState({wilayah: arr});
    }

    hasMoreData = () => !(this.state.subscribed.length === (this.state.pagination ? this.state.pagination.total : -1));
    
    render() {
        //const totalProudcts = this.props.pagination ? this.props.pagination.total : 0;
        // const countProudcts = this.props.pagination ? this.props.pagination.count : 0;
        let Page = (
            <div className="container" style={{ marginTop: "62px" }}>
                <div className="left-side">
                        <FilterShop
                            province={this.state.provinsi}
                            wilayah={this.state.wilayah}
                            filterShops={this.filterShops}
                            resetFilterShops={this.resetFilterShops}
                            checkedWilayah={this.checkedWilayah}
                        />
                            
                </div>
                <div className="category-detail">
                <div className="content-tab mb20">
                {
                    this.state.subscribed.length > 0 ?
                    <div className="sorting-input">
                        <select className="form-control" name="sort" id="sort" value={this.state.sort_id} onChange={this.sortSubscribe}>
                            <option value="1">{i18n.t("Subscribes:urutkan_toko.barang")}</option>
                            {/* <option value="2">Aktif Terbaru</option> */}
                            <option value="3">{i18n.t("Subscribes:urutkan_toko.diskon")}</option>
                        </select>
                        <label>{i18n.t("Subscribes:urutkan")}</label>
                    </div> : ''
                }
                    <h3>{i18n.t("Subscribes:title")}</h3>
                    <p className="search-summary">{i18n.t("Subscribes:menampilkan.menampilkan")} {this.state.pagination.total} {i18n.t("Subscribes:menampilkan.toko_langganan")}</p>
                    <div className="clearfix"></div>
                    
                        {
                            this.state.loadingSubscribeShop
                            ? <div>
                                <LoadingInfiniteScroll />
                            </div>
                            : this.state.subscribed.length > 0 
                                ? <div><div className="row equal">
                                    {this.state.subscribed.map((data, i) => {
                                        return (
                                            <div className="col-md-6" key={i}>
                                                <div className="following-shop">
                                                    <div className="following-shop-header">
                                                        <img src={data.photo} alt="" className="shop-icon mr10" />
                                                        <div className="following-info">
                                                            <div>
                                                                <Link to={`/${data.domain}`} className="shop-name">
                                                                    {data.name}
                                                                </Link>
                                                                <p><i className="fas fa-map-marker-alt"></i> {data.city}</p>
                                                            </div>
                                                            <div>
                                                                {
                                                                data.isSub ? 
                                                                <a href="/#" onClick={(e) => this.unSubscribe(e, data.id)} className="btn btn-sm btn-solid grey mb5"><i className="fas fa-bookmark"></i> {i18n.t("Subscribes:toko.langganan_hapus")}</a>
                                                                    :
                                                                <a href="/#" onClick={(e) => this.onSubscribe(e, data.id)} className="btn btn-sm btn-solid grey mb5"><i className="far fa-bookmark"></i> {i18n.t("Subscribes:toko_langganan.langganan")}</a>
                                                                }
                                                                &nbsp;&nbsp;<a href="/#" onClick={this.onClickHubungiPenjual} className="btn btn-sm btn-solid grey mr5 mb5" data-id={i}><i className="far fa-envelope"></i> {i18n.t("Subscribes:toko.hubungi_penjual")}</a>                                                                
                                                            </div>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="following-shop-body">
                                                    {data.product.length <= 0 ? 
                                                            <div className="text-center">
                                                                <span className="regularitalic">{i18n.t("Subscribes:toko.no_barang")}</span>
                                                            </div>    
                                                            :
                                                        <div><p>{i18n.t("Subscribes:toko.perbarui")} {data.latest}</p>
                                                        <div className="row">
                                                            {
                                                                data.product.map(datax => {
                                                                    let rating = [];

                                                                    for (let i = 0; i < 5; i++) {
                                                                        if(i < datax.rating){
                                                                            rating.push(<i className="fas fa-star starred" key={i}></i>);
                                                                        }else{
                                                                            rating.push(<i className="fas fa-star" key={i}></i>);
                                                                        }
                                                                    }
                                                                    return (
                                                                        <div key={datax.id} className={`product-div subscribe`}>
                                                                            <Link to={`/product/${datax.id}`} className="product-link" title="Lihat Produk">
                                                                                <div className="product-img" style={{height : '90px', width: '90px'}}>
                                                                                    <img src={datax.image} alt="Agrinis"/>
                                                                                        {
                                                                                            datax.flag_discount
                                                                                            ? <div className="discount">{datax.label_discount}</div>
                                                                                            : null
                                                                                        }
                                                                                        {
                                                                                            datax.kondisi === 2
                                                                                            ? <div className="second">Bekas</div>
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                                    <h5 style={{overflowWrap: "break-word"}}>{datax.product_name}</h5>
                                                                            </Link>
                                                                            <div className="product-desc">
                                                                                <p>{datax.description}</p>
                                                                                <div className="rating">
                                                                                    {
                                                                                        rating.map((item) => item)
                                                                                    }
                                                                                    &nbsp;<a href="/#" title={`Lihat Ulasan`}>({datax.jml_ulasan})</a>
                                                                                </div>
                                                                                <div className="price">
                                                                                    <span className={`main-price ${datax.grosir ? "fs11" : "fs14"}`}>
                                                                                    {
                                                                                        datax.is_vip
                                                                                        ? <img src={logo_vip} alt="LOGO VIP"/>
                                                                                        : null
                                                                                    }
                                                                                    {datax.label_price}
                                                                                        {/* Rp <FormatUang value={datax.price}/> */}
                                                                                    </span>
                                                                                    {
                                                                                        datax.flag_discount && !datax.grosir
                                                                                        ? <span className="discount">
                                                                                            <FormatUang value={datax.real_price}/>
                                                                                        </span>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                            </div>                                                                    
                                                                        </div>
                                                                    )
                                                                }
                                                            )
                                                            }
                                                            {/* {
                                                                data.product.map((datax, ix) => <div className="col-md-4" key={ix}>
                                                                <Link to={`/product/${datax.id}`} className="block">
                                                                    <img src={datax.image} alt="" className="product-img" />
                                                                </Link>
                                                            </div>
                                                            )
                                                            } */}
                                                        </div></div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })} 
                                    </div>
                                    <div className="text-center">
                                        {
                                            this.hasMoreData() ? 
                                            this.state.loadingMoreData
                                                ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                                : <button className="btn btn-solid primary" onClick={this.loadMoreData}>{i18n.t("Subscribes:toko.lainnya")}</button>
                                                :null
                                        }
                                    </div></div>
                                    :
                                    <EmptyResult
                                        image={Subscribe_result}
                                        message={i18n.t("Subscribes:toko.not_found")}
                                        buttonText={0}
                                    />
                        }
                        </div>
                    </div>
                </div>
        )
        return (
            <div>
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userId}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    clearFilterPage={this.clearFilterPage}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    <SubscribeLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <HubungiPenjual
                    seller={this.state.seller_selected}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        subscribed: state.user.subscribed,
        userData: state.user.user,
        userId: state.user.user.user,
        // pagination: state.products.pagination,
        loadingSubscribeShop: state.loading.loadingSubscribeShop,

    }
}

export default NetworkDetector(withNamespaces("Subscribes")(connect(mapStateToProps, {
    subScribeShop,
    unsubScribeShop,
    getMoreSubscribeShop
})(Subscribes)))