
export const increment = (items, newItem) => {
    
    return items.map(item =>{
        let new_jumlah = parseInt(item.jumlah) + 1;
        let new_harga = parseInt(item.harga);

        let obj_price = findPrice(item, new_jumlah);

        if(obj_price){
            new_harga = obj_price.price;
        }

        return item.id === newItem.id
            ? { 
                ...item, 
                jumlah : new_jumlah,
                harga: new_harga,
                disableDec: false
            }
            : item
    })
};

export function findPrice(cartItem, qtyToAdd){
    let result = cartItem.prices.find(price => qtyToAdd >= price.min && qtyToAdd <= (price.max === 0 ? (qtyToAdd + 1) : price.max));

    if(result) {
        return result;
    }

    return cartItem.prices.find(price => price.id === 0);
}

export const decrement = (items, newItem) => {
    
    return items.map(item =>{
        let new_jumlah = parseInt(item.jumlah) - 1;
        let new_harga = parseInt(item.harga);

        let obj_price = findPrice(item, new_jumlah);

        if(obj_price){
            new_harga = obj_price.price;
        }

        return item.id === newItem.id
            ? { 
                ...item, 
                jumlah : new_jumlah,
                harga : new_harga,
                disableDec: new_jumlah <= item.min_order
            }
            : item
    })
};

export const updateQuantity = (items, newItem) => {
    
    return items.map(item => {
        let new_jumlah = parseInt(newItem.jumlah);
        let new_harga = parseInt(item.harga);

        let obj_price = findPrice(item, new_jumlah);

        if(obj_price){
            new_harga = obj_price.price;
        }

        return item.id === newItem.id
            ? { 
                ...item,
                jumlah : new_jumlah,
                harga: new_harga,
                disableDec: new_jumlah <= item.min_order
            }
            : item
    })
};

export const getTotal = (items) => {
    
    return items.reduce(
        (accumalatedQuantity, item) =>
            accumalatedQuantity + (item.harga * item.jumlah), 0
    )
};

export const getTotalBerat = (items) => {
    
    return items.reduce(
        (accumalatedQuantity, item) =>
            accumalatedQuantity + (item.berat * item.jumlah), 0
    )
};

export const getTotalPpn = (items) => {
    
    return items.reduce(
        (accumalatedQuantity, item) =>
            accumalatedQuantity + (item.ppn ? (item.harga * item.jumlah) : 0), 0
    )
};
