import { 
    TAB_CREATE_SHOP, 
    CREATE_SHOP, 
    FETCH_SHOP, 
    FETCH_DISTRICTS, 
    TOGGLE_MODAL_ADDRESS,
    ADD_ADDRESS_SHOP,
    DELETE_ADDRESS_SHOP,
    FETCH_SHOP_ADDRESS,
    FETCH_SHOP_SHIPMENT
} from "../actions/types/shopType";
// import _ from "lodash";

const initialState = {
    tab: 1,
    create_shop : {
        name_shop: '',
        domain: '',
        file: null
    },
    item: {
        photo : null,
        addresses : {
            data : []
        }
    },
    districts: [],
    toggle: false,
    step3Address: [],
    shop_address : [],
    shop_shipment : []
}

export default function(state = initialState, action){
    switch (action.type) {
        case TAB_CREATE_SHOP:
            return {
                ...state,
                tab: action.payload
            }
        case CREATE_SHOP:
            return {
                ...state,
                create_shop: action.payload
            }
        case FETCH_SHOP:
            return {
                ...state,
                item: action.payload
            }
        case FETCH_DISTRICTS:
            return {
                ...state,
                districts: action.payload.data
            }
        case TOGGLE_MODAL_ADDRESS:
            return {
                ...state,
                toggle: action.payload
            }
        case ADD_ADDRESS_SHOP:
            return {
                ...state,
                step3Address: [...state.step3Address, action.payload]
            }
        case DELETE_ADDRESS_SHOP:
            return {
                ...state,
                step3Address: removeAddress(state.step3Address, action.payload)
            }
        case FETCH_SHOP_ADDRESS:
            return {
                ...state,
                shop_address: action.payload
            }
        case FETCH_SHOP_SHIPMENT:
            return {
                ...state,
                shop_shipment: action.payload
            }
        default:
            return state;
    }
}


const removeAddress =  (addressItems, index) => {
    addressItems.splice(index, 1);
    let newaddres = addressItems;
    return [...newaddres];
}