import React from 'react';
import { connect } from 'react-redux';
import {
    NewHeader, 
    HomeLoading,
} from '../../components';

import {
    toggleModalLogin
} from '../../actions/registerActions';
import { authGet } from '../../helper';



class BlankPage extends React.Component {
    
    componentDidMount()
    {
        authGet(`auth/check`, this.props.userData.token)
        .then(res => {
            if(!res.data.valid){
                setTimeout(() => {
                    window.location.href = "/"
                },2000)
            }
            else{
                window.location.reload();
            }
        })
        .catch(err => {
            setTimeout(() => {
                window.location.href = "/"
            },2000)
        })
    }

    render() {
        return (
            <div>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <HomeLoading/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        userData : state.user.user
    }
}

export default connect(mapStateToProps, {
    toggleModalLogin
})(BlankPage);