import { 
    TAB_CREATE_SHOP, 
    CREATE_SHOP, 
    FETCH_SHOP, 
    FETCH_DISTRICTS, 
    TOGGLE_MODAL_ADDRESS,
    ADD_ADDRESS_SHOP,
    DELETE_ADDRESS_SHOP,
    FETCH_SHOP_ADDRESS,
    FETCH_SHOP_SHIPMENT,
    FETCH_SHOP_PRODUCT,
    // UPDATE_STATUS_PRODUCT,
} from "./types/shopType";
import {
    LOADING_FETCH_SHOP,
    GET_STATUS_PRODUCT,
    LOADING_FETCH_ADDRES_SHOP
} from "./types/loadingType";

import axios from 'axios';
import { API_URL } from "../../src/config";
import { headers } from '../headers';

export const tabCreateShop = (tab) => {
    return {
        type: TAB_CREATE_SHOP,
        payload: tab
    }
}

export const createShop = (data) => {
    return {
        type: CREATE_SHOP,
        payload: data
    }
}

export const fetchShop = (domain) => dispatch => {
    axios.get(`${API_URL}/get_shop_by_domain/${domain}`)
        .then(shop =>{
            dispatch({
                type: FETCH_SHOP,
                payload: shop.data
            })
        });
}

export const getShop = (id) => dispatch => {
    axios.get(`${API_URL}/shop/${id}`)
        .then(shop =>{
            dispatch({
                type: FETCH_SHOP,
                payload: shop.data.data
            })
        });
}

export const getMyShop = (token) => dispatch => {
    dispatch({
        type: LOADING_FETCH_SHOP,
        payload: true
    })
    axios.get(`${API_URL}/my_shop`, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
        .then(res =>{
            dispatch({
                type: FETCH_SHOP,
                payload: res.data.data
            })
            dispatch({
                type: LOADING_FETCH_SHOP,
                payload: false
            })
        })
        .catch(err => window.location.href = '/')
}

export const fetchMyProduct = (data, token) => dispatch => {
    dispatch({
        type: GET_STATUS_PRODUCT,
        payload: true
    })
    return axios.post(`${API_URL}/product_shop`, data, {
            headers : {
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(res =>{
            dispatch({
                type: FETCH_SHOP_PRODUCT,
                payload: res.data.data,
                meta: res.data.meta.pagination
            })
            dispatch({
                type: GET_STATUS_PRODUCT,
                payload: false
            })
        });
}

export const fecthShopAddress = (token) => dispatch => {
    
    axios.get(`${API_URL}/get_shop_address`, {
            headers : {
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(res =>{
            dispatch({
                type: FETCH_SHOP_ADDRESS,
                payload: res.data.data
            })
        }).catch((err, x) => {
            if(err.response.data.error === 1){
                window.location.href = '/'
            }
        })
}

export const updateStatusProduct = (data, token) => {
    return axios.post(`${API_URL}/product/update_status`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}

export const deleteMyProduct = (data, token) => {
    return axios.post(`${API_URL}/product/delete_product`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    })
}


export const fecthShopShipment = (token) => dispatch => {
    
    axios.get(`${API_URL}/get_shop_shipment`, {
            headers : {
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(res =>{
            dispatch({
                type: FETCH_SHOP_SHIPMENT,
                payload: res.data.data
            })
        });
}


export const postShopShipment = (data) => dispatch => {

    axios.post(`${API_URL}/shop_shipment`, data, headers)
        .then(res =>{
            dispatch({
                type: FETCH_SHOP_SHIPMENT,
                payload: res.data.data
            })
        });
}

export const checkMyProduct = (product_id, token) =>{
    
    return axios.get(`${API_URL}/check_is_my_product/${product_id}`, {
        headers : {
            'Authorization' : 'Bearer ' + token
        }
    });
}

export const fetchHeaderShop = (data) => dispatch => {
    dispatch({
        type: FETCH_SHOP,
        payload: data
    })
}

export const fetchDistricts = () => dispatch => {
    const localDistrics = localStorage.getItem('disctrict');
    if(localDistrics){
        if(JSON.parse(localDistrics).data.length > 0){
            dispatch({
                type: FETCH_DISTRICTS,
                payload: JSON.parse(localDistrics)
            })
        }else{
            dispatch({
                type: LOADING_FETCH_ADDRES_SHOP,
                payload: true
            })
            axios.get(`${API_URL}/get_districts`)
                .then(res => {
                    dispatch({
                        type: FETCH_DISTRICTS,
                        payload: res.data
                    })
                    dispatch({
                        type: LOADING_FETCH_ADDRES_SHOP,
                        payload: false
                    })
                    localStorage.setItem('disctrict', JSON.stringify(res.data))
                })
        }
    }else{
        dispatch({
            type: LOADING_FETCH_ADDRES_SHOP,
            payload: true
        })
        axios.get(`${API_URL}/get_districts`)
            .then(res => {
                dispatch({
                    type: FETCH_DISTRICTS,
                    payload: res.data
                })
                dispatch({
                    type: LOADING_FETCH_ADDRES_SHOP,
                    payload: false
                })
                localStorage.setItem('disctrict', JSON.stringify(res.data))
            })
    }
}

export const toggleModalAdress = (toggle) => {
    return {
        type: TOGGLE_MODAL_ADDRESS,
        payload: toggle
    }
}

export const addAddressShop = (address) => {
    return{
        type: ADD_ADDRESS_SHOP,
        payload: address
    }
}

export const deleteAddressShop = (index) => {
    return {
        type: DELETE_ADDRESS_SHOP,
        payload:index
    }
}