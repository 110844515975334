import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withNamespaces } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import {
    NewHeader,
    Product,
    Footer,
    LoadingCategory,
    LoadingProduct,
    // LoadingPage
} from '../../components';

import _ from 'lodash';
import { fetchCategories } from '../../actions/categoryActions';
import { 
    fetchProducts, 
    onLikeProduct, 
    fetchMoreProducts,
    // pusher
} from '../../actions/productActions';
import { toggleModalLogin } from "../../actions/registerActions";
import HomeLoading from '../../components/Loading/HomeLoading';
import { 
    get,
    // getPaginate, 
    Toast, 
    authGet,
    authGetPaginate 
} from "../../helper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NetworkDetector from './NetworkDetector';

// import Pusher from 'pusher-js';
// import Echo from 'laravel-echo';<img src={} alt="Agrinis" />

class Home extends Component{

    constructor(props){
        super(props);
        this.state = {
            loadingPage : true,
            products: [],
            products_pagination: {},
            loadingProducts: false,
            loadingButton: false,
            items: [],
            items_small: [],
        }
    }

    componentDidMount() {
        this.props.fetchCategories()
        .then(() => {
            // this.props.fetchProducts();
            get('get_banner').then(res => {
                this.setState({
                    items: res.data.filter(banner => banner.sub_banner === 0),
                    items_small: _(res.data).orderBy(o => o.updated_at, 'desc').filter(f => f.sub_banner === 1).slice(0, 2).value()
                })
            })
            this.setState({loadingPage : false, loadingProducts: true});
            authGet('product', this.props.userData.token).then(res => {
                this.setState({
                    products: res.data.data,
                    loadingProducts: false,
                })
            })
        })

        // const pusher = new Pusher('6e28d3fc671affa44165', {
        //     cluster: 'ap1',
        //     encrypted: true
        // });

        // const channel = pusher.subscribe('my-channel');
        // channel.bind('my-event', data => {
        //     alert("oke");
        // });

        // const options = {
        //     broadcaster: 'pusher',
        //     key: "6e28d3fc671affa44165",
        //     cluster: "5e44019dda4f4963ece1",
        //     forceTLS: "ap1", 

        //     auth: {
        //       headers: {
        //         Authorization: `Bearer ${this.props.userData.token}`,
        //         Accept: 'application/json',
        //       },
        //     },
        //   };
          
        //   const echo = new Echo(options);

        //   echo.private(`App.User.${this.props.userId}`).notification((data) => {
        //     console.log(data);
        // });
    }

    onClickLikeButton (product, likeActive) {
        let { token } = this.props.userData;
        if(this.props.userId){
            this.setState({ loadingButton: true })
            if(!likeActive){
                /* INSERT LIKE Record */
                
                let data = {
                    product_id : product.id,
                    user_id: this.props.userId,
                    action: "INSERT" 
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.setState({loadingButton: false}, () => { 
                        Toast.fire({
                            text: "Barang berhasil ditambahkan ke daftar favorite",
                            type: "success"
                        });                        
                    })
                });

            }else{
                /* REMOVE FROM LIKE Record */

                let data = {
                    id : likeActive.id,
                    user_id: this.props.userId,
                    action: "DELETE" 
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.setState({loadingButton: false}, () => { 
                        Toast.fire({
                            text: "Barang berhasil dihapus dari daftar favorite",
                            type: "success"
                        });                        
                    })
                });
            }
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    fetchMoreData = () => {
        let { next } = this.state.products_pagination.links;
        // this.props.fetchMoreProducts(next);
        authGetPaginate(next, this.props.userData.token).then(res => {
            this.setState({
                products: [...this.state.products, ...res.data.data],
                products_pagination: res.data.meta.pagination,
            })
        })
        // setTimeout(() => {
        // }, 1000);
    }

    hasMoreData = () => !(this.state.products.length === (this.state.products_pagination ? this.state.products_pagination.total : -1 ));

    onClickCarousel = (e) => {
        let data = this.state.items[e];
        if (data.link) {
            window.open(data.link)
        }
    }
    

    render(){
        let loadCateory = [];
        for (let i = 0; i < 6; i++) {
            loadCateory.push(<div className="col-lg-4 col-md-6" key={i} style={{paddingTop: "20px", paddingBotom: "20px"}}><LoadingCategory/></div>);
            
        }

        let loadProduct = [];
        for (let i = 0; i < 10; i++) {
            loadProduct.push(<div className="product-div home" key={i}><LoadingProduct/></div>);
            
        }
        const { t } = this.props;
        let Page = (
            <div className="container-fluid mg-top">
                    <div className="container">
                        <div className="header-banner">
                            <div className="row">
                                <div className="col-md-7">
                                    {/* <UncontrolledCarousel items={this.state.items} /> */}
                                    {
                                        this.state.items.length > 0 ? 
                                            <Carousel
                                            autoPlay
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop={true}
                                            showArrows={false}
                                            showIndicators={true}
                                            interval={2000}
                                            onClickItem={this.onClickCarousel}
                                            >
                                            {
                                                this.state.items.map(item => <img key={item.key} src={item.src} alt="Promo"/>)
                                            }
                                        </Carousel> : null
                                    }
                                    {/* <img src={`${URL}/images/promo/banner-1.png`} alt="Agrinis"/> */}
                                </div>
                                <div className="col-md-5">
                                    {
                                        this.state.items_small.map(item => <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.key}><img src={item.src} alt="Promo"/></a>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                    

                    <div className="container p20">
                        <h2 className="sub-title">{t('Home:kategori')}</h2>
                        <div className="row">
                            {
                                this.props.loadingCategory
                                ? loadCateory
                                : this.props.categories.map(category => (
                                    <div className="col-lg-4 col-md-6" key={category.id}>
                                        <div className="category-div">
                                            <h3>{category.name}</h3>
                                            <img src={category.url_image} alt="Agrinis"/>
                                            <Link to={`/category/${category.id}`} className="btn btn-solid primary">{t('Home:cari_barang')}</Link>
                                        </div>
                                    </div>
                                ))
                                
                            }
                        </div>
                        <h2 className="sub-title">{t('Home:rak_barang')}</h2>
                        <div className="product-container" style={{overflow: "auto"}}>
                        
                            {
                                this.state.loadingProducts
                                ? loadProduct
                                : 
                                    this.state.products.map(product => 
                                        <Product 
                                            key={product.id} 
                                            className="home" 
                                            onClickLikeButton={(product, likeActive) => this.onClickLikeButton(product, likeActive)}
                                            likes={this.props.likes ? this.props.likes : []}
                                            loadingButton={this.state.loadingButton}
                                            {...product}
                                            />
                                    )
                                
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <Footer/>
                </div>
        )

        return (
            <div>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userId}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <HomeLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories : state.categories.items,
        products: state.products.items,
        pagination: state.products.pagination,
        loadingCategory : state.loading.loadingCategory,
        loadingProduct : state.loading.loadingProduct,
        likes : state.user.user.product_favorite,
        userId : state.user.user.user,
        userData : state.user.user

    }
}

export default NetworkDetector(withNamespaces('Home')(connect(mapStateToProps, {
    fetchCategories, 
    fetchProducts, 
    toggleModalLogin,
    onLikeProduct,
    fetchMoreProducts
})(Home)));