import React from 'react';
import { FormatUang } from '../../../helper';
import { LabelPPNRight } from './PpnComponent';
import logo_vip from '../../../assert/icon/vip_logo.svg';

const HargaGrosir = (props) => {
    return (
        <tr>
            <td align="center">
                {props.min}
            </td>
            <td align="center"> - </td>
            <td align="center">
                {parseInt(props.max) === 0 ? `> ${props.min}` : props.max}
            </td>
            <td align="center"> = </td>
            <td className="pr20">
                Rp <span className="pull-right"><FormatUang value={props.harga}/></span>
                {
                    props.ppn
                    ? <React.Fragment>
                        <br/>
                        <LabelPPNRight 
                            style={{fontSize: "10px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.harga === "" ? 0 : parseInt(props.harga) + ((parseInt(props.harga)*10)/ 100)}/>
                        </LabelPPNRight>
                    </React.Fragment>
                    : null
                }
                
            </td>
            {
                props.is_vip
                ? <td className="pr20">
                    <img src={logo_vip} alt="LOGO VIP"/> Rp <span className="pull-right"><FormatUang value={props.harga_vip}/></span>
                    {
                        props.ppn
                        ? <React.Fragment>
                            <br/>
                            <LabelPPNRight 
                                style={{fontSize: "10px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={props.harga_vip === "" ? 0 : parseInt(props.harga_vip) + ((parseInt(props.harga_vip)*10)/ 100)}/>
                            </LabelPPNRight>
                        </React.Fragment>
                        : null
                    }
                    
                </td>  
                : null
            }
                      
            <td align="center">
                {
                    props.indexItem === (props.countItem) -1
                    ? <span className="btn-remove red" onClick={props.onClick}>
                        <i className="fas fa-times"></i>
                    </span>

                    : null
                }
            </td>
        </tr>
    )
}

export default HargaGrosir
