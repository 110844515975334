import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ReactImageMagnify from 'react-image-magnify';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';

// import { URL } from "../../config";
import { 
    fetchProductDetail, 
    getShopByProductId,
    selectImageMagnify,
    productShipment,
    onLikeProduct,
    onIncrement,
    onDecrement,
    onChangeQuantity
} from "../../actions/productActions";
import { 
    addToCartV2
} from "../../actions/cartActions";
import { 
    subScribeShop,
    unsubScribeShop
} from "../../actions/userAction";

import {
    Footer,
    NewHeader,
    FlashNotif,
} from '../../components';

import { toggleModalLogin } from "../../actions/registerActions";

import { 
    LodingThumnailDetailProduct,
    LodingImageDetailProduct,
    LoadingDescProduct,
    LoadingSellerDetail
} from '../../components/Loading/Loading';

import {
    Breadcrumb,
    Seller,
    ImgThumb,
    ProductDetail,
    TabDetailProduct
} from '../../components/Product/_Component/DetailProductComponent';

import { addItemToCartV3 } from '../../reducers/cart.util';

import {
    AlertInfo,
    AlertError,
    authPost,
    authGet,
    Toast
} from '../../helper';
import HubungiPenjual from '../../components/Global/HubungiPenjual';
import ReactLoading from 'react-loading';
import SingleProductLoading from '../../components/Loading/SingleProductLoading';
import ProductNotFound from './ProductNotFound';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
import NetworkDetector from './NetworkDetector';

// const Loading = () => <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"20px"} width={"20px"} />
class ProductDetailComponent extends Component {
    constructor(props){
        super(props);

        this.state = {
            loadingPage: true,
            active : 1,
            id: '',
            qty: 0,
            price_sale: 0,
            tab: '',
            selectImageID : 0,
            selectImage: '',
            disableDec : true,
            loading : true,
            notif : false,

            product_detail: {
                shop : {
                    addresses: {
                        data: [{
                            city: ""
                        }]
                    }
                },
                prices: [],
                images: [],
                shipment: [],
            },
            loadingButton: false,
            product_notif: {},
            is_product_exist: false,
            spesifikasi: [],

        }

        this.handleTab = this.handleTab.bind(this);
        this.selectImage = this.selectImage.bind(this);
    }
    
    componentDidMount(){
        let {id} = this.props.match.params;
        let outputSpesifikasi = [];
        authGet(`product/show/${id}`, this.props.userData.token)
        .then(res => {
            
            if (res.data.spesifikasi.length > 0) { 
                outputSpesifikasi = this.formatSpesifikasiProduct(res.data.spesifikasi)
            }
            this.setState({
                loadingPage: false,
                product_detail: res.data,
                is_product_exist: true,
            }, () => { 
                this.setState({
                    product_detail: {
                        ...this.state.product_detail,
                        spesifikasi: outputSpesifikasi
                    }
                })
            });
        })
        .catch(err => {
            console.log(err.response.data.message)
            this.setState({is_product_exist: false,loadingPage: false});
            // // this.setState({is_product_exist: false}), () => {
            //     AlertError.fire({text: err.response.data.message})
            //     .then(result => {
            //         window.location.href = "/";
            //     })
            // // });
        })
    }

    formatSpesifikasiProduct(data) { 
        let outputObj = _.reduce(data, function(accumulator, {value, name}) { 
            if (!accumulator[name]) accumulator[name] = value
            else accumulator[name] += ", " + value
            return accumulator
        }, {});
        let output = _.map(outputObj, function(value, name) { 
            return {name, value}
        });
        return output
    }

    handleTab(e){
        e.preventDefault();
        this.setState({tab : e.target.id})
    }

    onClickLikeButton (product_id, likeActive) {
        let { token } = this.props.userData;
        if(this.props.userData.user){
            this.setState({ loadingButton: true });
            if(!likeActive){
                /* INSERT LIKE TABLE */
                
                let data = {
                    product_id : product_id,
                    user_id: this.props.userData.user,
                    action: "INSERT" 
                }

                this.props.onLikeProduct(data, token)
                .then(() => {
                    this.setState({ loadingButton: false, product_detail :{
                            ...this.state.product_detail,
                            jml_like: this.state.product_detail.jml_like + 1,
                        }
                    }, () => { 
                        Toast.fire({
                            text: "Barang berhasil ditambahkan ke daftar favorite",
                            type: "success"
                        });                        
                    })
                })

            }else{
                /* REMOVE FROM LIKE TABLEE */

                let data = {
                    id : likeActive.id,
                    user_id: this.props.userData.user,
                    action: "DELETE" 
                }

                this.props.onLikeProduct(data, token)
                .then(() => {
                    this.setState({ loadingButton: false, product_detail :{
                            ...this.state.product_detail,
                            jml_like: this.state.product_detail.jml_like - 1,
                        }
                    }, () => { 
                        Toast.fire({
                            text: "Barang berhasil dihapus dari daftar favorite",
                            type: "success"
                        });                        
                    })
                })
            }
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    renderLikeButton(product_likes){
        if (this.state.loadingButton) { 
            return <ReactLoading type="spinningBubbles" color="#0C7F8F" height={"15px"} width={"15px"} className="custom-loading"/>
        } else {
        let product_id = parseInt(this.props.match.params.id);
            if(product_likes){
                let like = product_likes.find(item => item.product_id === product_id);
                if(like){
                    return <a href="/#" className="ml10" onClick={(e) => {e.preventDefault();this.onClickLikeButton(product_id, like)}}><i className="fas fa-heart primary"></i> {i18n.t("DetailProduct_temp:favorite.remove")}</a>
                }
            }
            return <a href="/#" className="ml10" onClick={(e) => {e.preventDefault();this.onClickLikeButton(product_id, false)}}><i className="fas fa-heart"></i> {i18n.t("DetailProduct_temp:favorite.add")}</a>
        }
    } 

    setTab(active){
        let {id} = this.props.match.params;
        switch(active){
            case 2:
                this.setState({active})
                this.props.productShipment(id)
                break;
            case 3:
                this.setState({active})
                break;
            case 4:
                this.setState({active})
                break;
            default :
                this.setState({active})
                break;

        }
    }

    selectImage(image) {
        // this.props.selectImageMagnify(image);
        this.setState({
            product_detail: {
                ...this.state.product_detail,
                image_selected: image.id,
                image_url: image.image
            }
        })
    }

    onChange(e){
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        // this.setState({ qty: validateValue });
        // this.props.onChangeQuantity(validateValue);

        this.setState({
            product_detail: {
                ...this.state.product_detail,
                qty: !isNaN(parseInt(validateValue)) ? parseInt(validateValue) : 0,
                disableDec: this.state.product_detail.qty <= (this.state.product_detail.min_order) + 1 ? true : false
            }
        })

    }

    onDecrement(e){
        // this.props.onDecrement();
        this.setState({
            product_detail: {
                ...this.state.product_detail,
                qty : this.state.product_detail.qty - 1,
                disableDec: this.state.product_detail.qty <= (this.state.product_detail.min_order) + 1 ? true : false
            }
        })
    }

    onIncrement(e){
        // this.props.onIncrement();
        this.setState({
            product_detail: {
                ...this.state.product_detail,
                qty : this.state.product_detail.qty + 1,
                disableDec: false
            }
        })
    }

    checkQuantity = (e) => {
        let { qty, min_order } = this.state.product_detail;
        if(qty < min_order){
            this.setState({
                product_detail: {
                    ...this.state.product_detail,
                    qty : min_order,
                    disableDec: this.state.product_detail.qty <= (this.state.product_detail.min_order) + 1 ? true : false
                }
            })
        }
    }

    onOrder(data){
        let qty = data.qty;
        data.quantity = qty;

        /**
         * @PRICE
         */
        // let obj_price = data.prices.find(price => data.qty >= price.min && data.qty <= (price.max === 0 ? (data.qty + 1) : price.max));
        // if(obj_price){

        //     let product_real_price = parseInt(obj_price.price);
        //     let product_price = product_real_price - obj_price.discount;

        //     data.price = product_price;
        //     data.discount_price = obj_price.discount;
        // }

        // data.sub_total = qty * data.price;
        // data.sub_diskon = qty * data.discount_price;
        

        
        if(this.props.cartItems.length > 0 ){
            let exiting = this.props.cartItems.find(cartItem => cartItem.checked && data.shop_id === cartItem.shop_id);
            if(exiting){
                data.checked = true;
                data.disabledChecked = false;
            }else{
                data.checked = false;
                data.disabledChecked = true;
            }
        }else{
            data.checked = true;
            data.disabledChecked = false;
        }
        
        if(this.props.userData.token){
            if(this.props.userData.shop){
                if(data.shop_id === this.props.userData.shop.id){
                    AlertInfo.fire({text: i18n.t("DetailProduct_temp:belanja_sendiri")});
                    return false;
                }
            }

            let data_cart = addItemToCartV3(this.props.cartItems, data);
            let products = data_cart.map(product => {
                return {
                    product_id : product.id,
                    quantity : product.quantity
                }
            });

            authPost({ value: JSON.stringify(data_cart), products }, `cart`, this.props.userData.token)
            .then(res => {
                this.props.addToCartV2(res.data);
                this.setState({
                    product_notif: data_cart.find(d => d.id === data.id),
                    notif: true,
                    product_detail: {
                        ...this.state.product_detail,
                        qty : this.state.product_detail.min_order,
                        disableDec: true
                    },
                }, () => {
                    setTimeout(() => {
                        this.setState({notif : false})
                    }, 3000)
                })
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message});
            })
            
            /*
            this.props.addToCartWithApi(addItemToCart(this.props.cartItems, data), this.props.userData.token)
            .then(() => {
                this.setState({notif : true}
                , () => {
                    setTimeout(() => {
                        this.setState({notif : false})
                    }, 3000)
                })
            })
            this.props.onChangeQuantity(data.min_order);
            */
            
        }else{
            this.props.toggleModalLogin(true);
        }
        
    }

    subScribeShop = (e) => {
        e.preventDefault();
        if(this.props.userData.user){
            this.setState({ loadingButton: true });
            let data = {
                shop_id : this.state.product_detail.shop_id
            }
            this.props.subScribeShop(data, this.props.userData.token, this.props.lng).then(() => {
                this.setState({ loadingButton: false });
            });
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    unsubScribeShop = (e) => {
        e.preventDefault();
        if(this.props.userData.user){
            this.setState({ loadingButton: true });
            
            let data = {
                shop_id : this.state.product_detail.shop_id
            }
            this.props.unsubScribeShop(data, this.props.userData.token, this.props.lng).then(() => {
                this.setState({ loadingButton: false });
            });
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    thisMyshop(data_user, data_product){
        if(data_user.shop && data_product){
            return data_user.shop.id === data_product.shop_id
        }

        return false;
    }

    userSubsricbe(data_user, data_product){
        if(data_user.subscribes && data_product){
            return data_user.subscribes.find(subscribe => subscribe.shop_id === data_product.shop_id)
        }

        return false;
    }

    closeNotif = (e) => {
        e.preventDefault();
        this.setState({notif : false})
    }

    hubungiPenjual = (e) => {
        e.preventDefault();
        if(!this.props.userData.token){
            this.props.toggleModalLogin(true);
            return false;
        }

        window.$("#modalHubungiPenjual").modal("show");
    }
    
    render() {
        const { t } = this.props;
        let product = this.state.product_detail;
        let seller = this.state.product_detail.shop;
        let reference = {
            reference_id: product.id,
            reference_name: product.name,
            reference_image: product.image_url,
            reference_type: 'product'
        }
        
        let Page = (
            <div className="container">
                <div className="detail-product-container">
                    <Breadcrumb 
                        category_id={product.category_id}
                        category_name={product.category}
                        category_detail_name={product.sub_category}
                    />
                    <br/>
                    <div className="product-side">
                        <div className="image-product">
                        {
                            product.kondisi === 2
                            ? <div className="second">{t("DetailProduct_temp:bekas")}</div>
                            : null
                        }
                        <div className="image-thumbnail">
                            {
                                this.state.loadingPage
                                ? <LodingThumnailDetailProduct/>
                                : product.images.map(item => (
                                    <ImgThumb 
                                        key={item.id} 
                                        src={item.image}
                                        onClick={() => this.selectImage(item)}
                                        className={item.id === parseInt(product.image_selected) ? "thumb active" : "thumb"}
                                    />
                                ))
                            }
                            </div>
                            <div className="image-slide" style={{zIndex: 9999}}>
                            {
                                this.state.loadingPage
                                ? <div style={{width: "350px", marginLeft: "25px"}}><LodingImageDetailProduct/></div>
                                : <ReactImageMagnify {...{
                                    smallImage: {
                                        alt: 'Product',
                                        isFluidWidth: true,
                                        src: product.image_url
                                    },
                                    largeImage: {
                                        src: product.image_url,
                                        width: 800,
                                        height: 800,
                                    },
                                    style: {
                                        zIndex: 99
                                    },
                                    // className: 'image-zoom',
                                    enlargedImageClassName: 'image-zoom'
                                }} />
                            }
                                <div className="text-center">
                                    <span className="mr10"><i className="far fa-heart"></i> {product.jml_like} {t("DetailProduct_temp:sukai")}</span> | 
                                    {this.renderLikeButton(this.props.userData.product_favorite)}
                                </div>
                            </div>
                        </div>
                        {
                            this.state.loadingPage
                            ? <div style={{width: "300px", float: "left"}}><LoadingDescProduct/></div>
                            : <ProductDetail
                                disableDecrement={product.disableDec}
                                onDecrement={(e) => this.onDecrement(e)}
                                onChange={(e) => this.onChange(e)}
                                onIncrement={(e) => this.onIncrement(e)}
                                onBlur={this.checkQuantity}
                                onOrder={() => this.onOrder(product)}
                                loadingButton={this.state.loadingButton}
                                {...product}
                                />
                        }
                        
                        <div className="clearfix"></div>
                        
                        <TabDetailProduct 
                            active={this.state.active}
                            setTab={(active) => this.setTab(active)}
                            getShipment={product.shipment}
                            {...product}
                            />
                        {/* <div className="desc-product">
                            <ul className="product-tab">
                                <li id="info" onClick={this.handleTab} className="active">Informasi Barang</li>
                                <li id="shipment" onClick={this.handleTab} >Pengiriman</li>
                                <li id="review" onClick={this.handleTab} >Ulasan</li>
                                <li id="discussion" onClick={this.handleTab} >Diskusi Barang</li>
                            </ul>
                            {this.renderStep(product)}
                        </div> */}
                        
                    </div>
                    {
                        this.state.loadingPage
                        ? <div className="store-side"><div className="store-product"><LoadingSellerDetail/></div></div>
                        : <Seller 
                            {...seller} 
                            subScribeShop={this.subScribeShop}
                            unsubScribeShop={this.unsubScribeShop}
                            isMyShop={this.thisMyshop(this.props.userData, product)}
                            userSubsricbe={this.userSubsricbe(this.props.userData, product)}
                            loadingButton={this.state.loadingButton}
                            hubungiPenjual={this.hubungiPenjual}
                            is_vip={this.state.product_detail.is_vip}
                            />
                    }
                </div>
                
                <HubungiPenjual
                    seller={seller}
                    reference={reference}
                />
                
                
            </div>
        )
        
        return (
            
            <div className="mg-top">
            {
                this.state.notif
                ? <FlashNotif message="Berhasil ditambah ke keranjang" data={this.state.product_notif} closeNotif={this.closeNotif}/>
                : null
            }
            <NewHeader 
                {...this.props}
                stateLogin = {this.props.userData.user}
                toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
            <CSSTransition
                in={this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
                    <SingleProductLoading />
            </CSSTransition>
            <CSSTransition
                in={!this.state.loadingPage}
                timeout={300}
                classNames="mypage"
                unmountOnExit>
                    {this.state.is_product_exist ? Page : <ProductNotFound />}
            </CSSTransition>

            <div className="clearfix"></div>
            {
                this.state.loadingPage
                ? null
                : !this.state.is_product_exist ? null : <Footer />
            }
        </div>
        )
        
    }
}


ProductDetailComponent.propTypes = {
    fetchProductDetail: PropTypes.func.isRequired
}

const mapStateToprops = (state) => {
    return {
        userData : state.user.user,
        product_detail: state.products.item,
        seller : state.products.seller,
        loading : state.loading.loadingProduct,
        shipment : state.products.productShipment,
        cartItems : state.carts.cartItems
    }
}

const mapActionToprops ={
    addToCartV2,
    fetchProductDetail,
    getShopByProductId,
    selectImageMagnify,
    toggleModalLogin,
    productShipment,
    onLikeProduct,
    subScribeShop,
    unsubScribeShop,
    onIncrement,
    onDecrement,
    onChangeQuantity,
}

export default NetworkDetector(withNamespaces("DetailProduct_temp")(connect(mapStateToprops, mapActionToprops)(ProductDetailComponent)));
