import React from 'react'
import { connect } from 'react-redux'
import ImageUploader from 'react-images-upload';
import TextField from '../_Component/TextField';
import SelectForm from '../_Component/SelectForm';
import { handleChangeProduct, onDropPicture } from '../../../actions/productActions'
import { onChangeCategory, onChangeSubCategory } from "../../../actions/categoryActions";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import SpesifikasiProduct from '../_Component/SpesifikasiProduct'
const InfoBarang = (props) => {
    return (
        <div>
            <h1 className="form-title">Tambah Barang</h1>
            <div className="form-subtitle">
				<h2>KATEGORI BARANG</h2>
			</div>
			<div className="row">
				<div className="col-md-12 mb30">
					<div className="form-group-div">
						<label>Kategori <span className="red">*</span></label>
						<div className="row">
                            <SelectForm 
                                name="category_id"
                                label="Pilih Kategori"
                                items={props.categories}
                                onChange={props.onChangeBarangCategory}
                                value={props.data.category_id}
                                />
                            <SelectForm
                                name="detail_category_id"
                                label="Pilih Sub Kategori"
                                items={props.detail_categories}
                                onChange={props.onChangeBarang}
                                value={props.data.detail_category_id}
                                categoryValue={props.data.category_id}
                                other
                                />
						</div>
					</div>
				</div>
			</div>
            <div className="form-subtitle">
                <h2>INFORMASI BARANG</h2>
            </div>
            <div className="row mb30">
                <div className="col-md-12 mb30">
                    <div className="form-group-div">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className={parseInt(props.data.category_id) === window.globalConfig.ovk_id ? "col-md-6" : "col-md-7"}>
                                    <TextField
                                        type="text"
                                        name="nama"
                                        label="Nama Barang"
                                        placeholder="Tulis Nama Barang"
                                        isRequire
                                        // onChange={props.onChangeBarang}
                                        onChange={props.handleNamaBarangChange}
                                        value={props.data.nama}
                                        isDisabled={props.loadingProductInternal ? true : false}
                                    />
                                    {
                                        parseInt(props.data.category_id) === window.globalConfig.ovk_id
                                        ? 
                                        <>
                                    <div className="checkbox">
                                        <label><input type="checkbox" onChange={props.onChangeAssociatedProduct} /> Asosiasikan Produk</label>
                                    </div>
                                    <div className="notification-box sm"><div className="info"><i className="fas fa-info-circle"></i><p>Jika anda mengasosiasikan produk maka produk akan direkomendasikan 
ke penyakit yang berhubungan.</p></div></div></> : null }
                                    </div>
                                    {
                                        parseInt(props.data.category_id) === window.globalConfig.ovk_id && props.isAssociated
                                        ? 
                                            <div className="col-md-6">
                                                <div className="form-group-div">
                                                    <label>Asosiasikan Produk</label>
                                                    {
                                                        props.loadingProductInternal
                                                        ? <p>Loading...</p>
                                                        : <AsyncSelect
                                                                cacheOptions
                                                                loadOptions={props.loadOptionsProductInternal}
                                                                defaultOptions={props.productInternal}
                                                                // onInputChange={props.handleInputChange}
                                                                onChange={props.changeProductInternal}
                                                                value={props.productInternalSelectedAsync}
                                                                noOptionsMessage={(val)=> null}
                                                                placeholder="Pilih produk"
                                                                />
                                                    }
                                                </div>
                                            </div>
                                        : null
                                }
                                </div>
                                </div>
                                <div className="col-md-7">
                                {
                                    parseInt(props.data.category_id) === window.globalConfig.ovk_id && props.productInternalSelected > 0 && props.dataProductInternalSelected.images.length > 0
                                    ? <input type="radio" name="rd_gambar" checked={!props.rd_gambar} onChange={() => props.onChangeRdGambar(undefined)}/>
                                    : null
                                }
                                
                                <label> Gambar Barang <span className="red">*</span></label>
                            
                                <ImageUploader
                                    // className={hiddeButton}
                                    name="photo"
                                    // className = "image-upload"
                                    label="Rasio gambar 1:1 (Persegi). Ukuran gambar minimum 300x300 pixel. "
                                    withPreview={true}
                                    withLabel={true}
                                    withIcon={true}
                                    buttonText='Pilih Gambar'
                                    onChange={props.onDrop}
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    maxFileSize={3145728}
                                />
                            </div>
                            {
                                parseInt(props.data.category_id) === window.globalConfig.ovk_id && props.productInternalSelected > 0 && props.dataProductInternalSelected.images.length > 0
                                ? <div className="col-md-5">
                                    <input type="radio" name="rd_gambar" checked={props.rd_gambar} onChange={() => props.onChangeRdGambar(true)} />
                                    <label>Gambar Default</label><br />
                                    {
                                        props.dataProductInternalSelected.images.map((image, i) => (
                                            <div className="img-product-thumbnail" key={i}>
                                                <img src={image.image_url} alt="ovk" />
                                            </div>
                                        ))
                                    }
                                    
                                    {/* <a href="/#" className="btn btn-outline btn-sm primary" data-toggle="modal" data-target="#modalGunakanGambar">Gunakan Gambar</a> */}
                                </div>
                                : null
                            }
                            
                        </div>
                    </div>
                </div>                                
                <div className="col-md-7">
                    {/* <TextField
                        type="text"
                        name="nama"
                        label="Nama Barang"
                        placeholder="Tulis Nama Barang"
                        isRequire
                        // onChange={props.onChangeBarang}
                        onChange={props.handleNamaBarangChange}
                        value={props.data.nama}
                    /> */}
                    <TextField
                        type="text"
                        name="subtitle"
                        label="Keterangan atau Subtitle"
                        placeholder="Tulis Subtitle"
                        onChange={props.onChangeBarang}
                        value={props.data.subtitle}
                    />
                    {/* <div className="form-group-div">
                        <label>Kategori <span className="red">*</span></label>
                        <div className="row">
                            <SelectForm 
                                name="category_id"
                                label="Pilih Kategori"
                                items={props.categories}
                                onChange={props.onChangeBarangCategory}
                                value={props.data.category_id}
                                />
                            <SelectForm
                                name="detail_category_id"
                                label="Pilih Sub Kategori"
                                items={props.detail_categories}
                                onChange={props.onChangeBarang}
                                value={props.data.detail_category_id}
                                categoryValue={props.data.category_id}
                                other
                                />
                        </div>
                    </div> */}
                    <div className="form-group-div">
						<label>Produsen Barang</label>
                        <select 
                            className="form-control" 
                            onChange={props.onChangeProdusen} 
                            value={props.dataProductInternalSelected.produsen ? props.dataProductInternalSelected.produsen.id : 0}
                            disabled={props.disabledProdusen}
                            >
							<option value={0}>Pilih Produsen</option>
                            {
                                props.produsens.map(produsen => (
                                    <option key={produsen.id} value={produsen.id}>{produsen.nama}</option>
                                ))
                            }
						</select>
					</div>
                    <div className="form-group-div">
                        <label>Kondisi</label><br />
                        <label className="form-check pull-left mr10">
                            <input 
                                type="radio" 
                                name="kondisi" 
                                className="mr5"
                                value={1}
                                checked={parseInt(props.data.kondisi) === 1}
                                onChange={props.onChangeBarang}
                            /> 
                            Baru
                        </label>
                        <label className="form-check pull-left">
                            <input 
                                type="radio" 
                                name="kondisi" 
                                className="mr5" 
                                checked={parseInt(props.data.kondisi) === 2}
                                onChange={props.onChangeBarang}
                                value={2}
                            /> 
                            Bekas
                        </label>
                        <div className="clearfix"></div>
                    </div>
                    <TextField
                        type="text"
                        name="berat"
                        label="Berat (gram) untuk pengiriman"
                        placeholder="Tulis Berat Barang"
                        className="w200"
                        isRequire
                        onChange={props.onChangeBarangNumber}
                        value={props.data.berat}
                    />                    
                    <div className="form-group-div">
                        <label>Deskripsi Barang</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={props.data.deskripsi}
                            config={{ 
                                toolbar: [
                                    "heading", 
                                    "|", "bold", "italic", "link", "bulletedList", "numberedList", 
                                    "|", "indent", "outdent", "|", "insertTable"
                                ]
                            }}
                            onChange={(event, editor) => { 
                                const data = editor.getData(); 
                                props.onChangeDescription(data)
                            }}
                        /> 
                        {/* <textarea 
                            name="deskripsi" 
                            className="form-control" 
                            rows="6" 
                            onChange={props.onChangeBarang}
                            value={props.data.deskripsi}></textarea> */}
                    </div>  
                </div>
                <div className="col-md-12">
                    <div className="form_grosir_price" style={{display: props.spesifikasi? "block" : "none"}}>
                        <label>Spesifikasi Produk</label>
                        <table className="table table-form">
                            <thead>
                                <tr>
                                    <td className="pr20">
                                    <AsyncCreatableSelect
                                        loadOptions={props.loadOptionsMasterSpesifikasi}
                                        defaultOptions={props.master_spesifikasi}
                                        // onInputChange={this.handleChange}
                                        onChange={props.onChangeMasterSpesifikasi}
                                        value={props.master_spesifikasi_selected}
                                        placeholder="Pilih atau tulis spesifikasi"
                                        noOptionsMessage={(val)=> null}
                                        isDisabled={props.loadingSpesifikasi}
                                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    />
                                    </td>
                                    <td className="pr20">
                                        {
                                            props.master_spesifikasi_detail.length > 0 ?
                                                <AsyncCreatableSelect
                                                    cacheOptions
                                                    loadOptions={props.loadOptionsMasterSpesifikasiDetail}
                                                    defaultOptions={props.master_spesifikasi_detail}
                                                    // onInputChange={this.handleChange}
                                                    onChange={props.onChangeMasterSpesifikasiDetailAsync}
                                                    value={props.master_spesifikasi_detail_selected}
                                                    placeholder="Pilih atau tulis keterangan spesifikasi"
                                                    noOptionsMessage={(val)=> null}
                                                    isDisabled={props.loadingDataSpesifikasi || (props.master_spesifikasi_detail.length === 0 && props.master_spesifikasi_selected.is_new)}
                                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                />  
                                            :
                                                <input 
                                                    type="text"
                                                    name="data-spesifikasi"
                                                    className={`form-control`}
                                                    placeholder="Tulis keterangan spesifikasi"
                                                    value={props.master_spesifikasi_detail_selected.label}
                                                    onChange={props.onChangeMasterSpesifikasiDetail}
                                                    disabled={props.loadingDataSpesifikasi || (props.master_spesifikasi_detail.length === 0 && props.master_spesifikasi_selected.is_new) ? "disabled" : ""}
                                                />
                                        }
                                    </td>
                                    <td className="w40">
                                        <button type="button" className="btn btn-solid primary btn-plus" onClick={props.onClickAddSpesifikasi}>
                                            <i className="fas fa-plus no-margin"></i>
                                        </button>
                                    </td>                                    
                                </tr>
                                <tr>
                                    <td className="pr20">
                                    <div className="notification-box sm" style={{ maxWidth: "374px" }}>
                                            <div className="info">
                                                <i className="fas fa-info-circle"></i>
                                                <p>Pilih atau tulis spesifikasi</p>
                                            </div>
                                        </div>   
                                    </td>
                                    <td className="pr20">
                                        <div className="notification-box sm" style={{ maxWidth: "374px" }}>
                                            <div className="info">
                                                <i className="fas fa-info-circle"></i>
                                                <p>Pilih atau tulis keterangan spesifikasi</p>
                                            </div>
                                        </div>   
                                    </td>
                                    <td className="w40">
                                        
                                    </td>                                    
                                </tr>                                
                            </thead>
                            <tbody>
                                    {
                                        props.dataSpesifikasi.map((item, i)=>(
                                            <SpesifikasiProduct 
                                                key={i}
                                                indexItem={i}
                                                countItem={props.dataSpesifikasi.length}
                                                onClick={() => props.onClickRemoveSpesifikasi(item, i)}
                                                {...item}
                                            />
                                        ))
                                    }
                                </tbody>
                        </table>
                    </div>   
                </div>                 
            </div>     
        </div>                 
)}

const maspStateToProps = (state) => {
    return {
        createProduct: state.products.createProduct,
        categoryItem: state.categories.items,
        subCategoryItem: state.categories.sub_category
    }
}

export default connect(maspStateToProps, {
    handleChangeProduct,
    onChangeCategory, 
    onChangeSubCategory,
    onDropPicture
})(InfoBarang);