export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const ADD_SHIPPING = 'ADD_SHIPPING';
export const TOGGLE_CART_HIDDEN = 'TOGGLE_CART_HIDDEN';
export const CHANGE_QUANTITY_PRODUCT = 'CHANGE_QUANTITY_PRODUCT';
export const CHANGE_CHECKED_ITEM = 'CHANGE_CHECKED_ITEM';
export const CHANGE_CHECKED_SHOP = 'CHANGE_CHECKED_SHOP';
export const REMOVE_SHOP_ITEM = 'REMOVE_SHOP_ITEM';
export const ADD_TO_CART_API = 'ADD_TO_CART_API';
export const SYNC_CART = 'SYNC_CART';
export const CLEAR_CART = 'CLEAR_CART';