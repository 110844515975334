import React from 'react';
import ContentLoader from "react-content-loader";

const MessageLoading = () => (
    <div className="container" style={{marginTop: "62px"}}>
                <div className="basic-container">
                <div className="direct-message-container mt20">
                    <div className="direct-message-user">
                    <ContentLoader 
                            height={45}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="250" height="20"/>
                        </ContentLoader>
                        <ContentLoader 
                            height={115}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="94" y="40" rx="4" ry="4" width="275" height="15" /> 
                            <rect x="94" y="65" rx="3" ry="3" width="275" height="15" /> 
                            <circle cx="45" cy="70" r="40" /> 
                        </ContentLoader>
                        <ContentLoader 
                            height={115}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="94" y="40" rx="4" ry="4" width="275" height="15" /> 
                            <rect x="94" y="65" rx="3" ry="3" width="275" height="15" /> 
                            <circle cx="45" cy="70" r="40" /> 
                        </ContentLoader>
                        <ContentLoader 
                            height={115}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="94" y="40" rx="4" ry="4" width="275" height="15" /> 
                            <rect x="94" y="65" rx="3" ry="3" width="275" height="15" /> 
                            <circle cx="45" cy="70" r="40" /> 
                        </ContentLoader>
                        <ContentLoader 
                            height={115}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="94" y="40" rx="4" ry="4" width="275" height="15" /> 
                            <rect x="94" y="65" rx="3" ry="3" width="275" height="15" /> 
                            <circle cx="45" cy="70" r="40" /> 
                        </ContentLoader>
                        {/* <ContentLoader 
                            height={500}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            >
                            <rect x="0" y="0" rx="0" ry="0" width="400" height="75"/>
                            <rect x="0" y="80" rx="0" ry="0" width="400" height="75"/>
                            <rect x="0" y="160" rx="0" ry="0" width="400" height="75"/>
                            <rect x="0" y="240" rx="0" ry="0" width="400" height="75"/>
                            <rect x="0" y="320" rx="0" ry="0" width="400" height="75"/>
                            <rect x="0" y="400" rx="0" ry="0" width="400" height="75"/>
                        </ContentLoader> */}
                    </div>
                        <div className="direct-message-form">
                            <div className="direct-message-conv" style={{ overflowY: "hidden" }}>
                            <ContentLoader height={160} width={400} speed={2}>
                                <rect x="0" y="12" rx="5" ry="5" width="220" height="10" />
                                <rect x="0" y="29" rx="5" ry="5" width="220" height="10" />
                                <rect x="179" y="76" rx="5" ry="5" width="220" height="10" />
                                <rect x="179" y="58" rx="5" ry="5" width="220" height="10" />
                                <rect x="0" y="104" rx="5" ry="5" width="220" height="10" />
                                <rect x="0" y="122" rx="5" ry="5" width="220" height="10" />
                            </ContentLoader>        
                            <ContentLoader height={75} width={400} speed={2}>
                                <rect x="179" y="12" rx="5" ry="5" width="220" height="10" />
                                <rect x="179" y="29" rx="5" ry="5" width="220" height="10" />
                                {/* <rect x="0" y="76" rx="5" ry="5" width="220" height="10" /> */}
                                {/* <rect x="0" y="58" rx="5" ry="5" width="220" height="10" /> */}
                                {/* <rect x="0" y="104" rx="5" ry="5" width="220" height="10" /> */}
                                {/* <rect x="0" y="122" rx="5" ry="5" width="220" height="10" /> */}
                            </ContentLoader>                              
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
)
export default MessageLoading;