import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import{
    Filter,
    NewHeader,
    Product,
    ListCategory,
    EmptyResult,
    LodingSideCategory,
    LoadingInfiniteScroll,
    Footer,
} from "../../components";

import imageEmpty from '../../assert/icon/empty-result.png';

import { toggleModalLogin } from "../../actions/registerActions";
import { onLikeProduct, postMoreProducts } from "../../actions/productActions";
import { fetchCategory, fetchCategoryWithSub } from "../../actions/categoryActions";
import { postProductsWithCategory } from "../../actions/productActions";

import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSSTransition } from 'react-transition-group';

import _ from 'lodash';

import {
    get,
    AlertWarning,
    authPost,
    Toast,
    post,
    authPostPaginate
} from '../../helper'
import { withNamespaces } from 'react-i18next';
import CategoryLoading from '../../components/Loading/CategoryLoading'
import NetworkDetector from './NetworkDetector'

class Category extends Component {

    constructor(props){
        super(props);
        this.state={
            loadingPage: true,
            params: {
                id: null,
                sub_id : 0,
            },
            sort_id : 5,
            max: '',
            min : '',
            kondisi : {
                1 : true,
                2 : true
            },
            spesifikasis: [],
            spesifikasisChecked: [],
            shipment : [],
            shipmentChecked : {},
            dataPersist : {},
            diskonCheked : false,
            products: [],
            products_pagination: {},
            loadingProduct: false,
            loadingButton: false,
            loadingFilter: false,
            spesifikasi_all: []
        }
    }
    
    componentWillMount() {
        let params = this.props.match.params;
        this.setState({params});
        this.props.fetchCategory(params)
        .then(() => {
            this.setState({loadingPage: false});
        })
        this.props.fetchCategoryWithSub();
        this.setDataPost(params);
        get(`get_shipment_detail`)
        .then(res => {
            let shipmentChecked = {};
            _.each(res.data, d => {
                shipmentChecked = {...shipmentChecked, [d.id] : true};
            })

            this.setState({shipmentChecked, shipment : res.data});
        })
    }

    setDataPost = (data) => {
        this.setState({dataPersist : data, loadingProduct: true, loadingFilter: true}
        ,() => {
            // this.props.postProductsWithCategory(data)
            if (data.sub_id) { 
                let dataa = {
                    detail_category_id: data.sub_id,
                    spesifikasi_all: this.state.spesifikasi_all
                }
                post(dataa, `load_spesifikasi`)
                .then(ress => { 
                    this.setState({
                        spesifikasis: ress.data,
                        loadingFilter: false
                    })
                })
            }
            authPost(data, 'product_with_category', this.props.userData.token).then(res => {
                this.setState({
                    products: res.data.data,
                    products_pagination: res.data.meta.pagination,
                    loadingProduct: false,
                    loadingFilter: false
                });
            })
        })
    }

    filterProduct = () => {
        let {
            dataPersist,
            min,
            max,
            kondisi,
            shipmentChecked,
            diskonCheked,
            spesifikasisChecked
        } = this.state;

        if(parseInt(min) > parseInt(max)){
            AlertWarning.fire({text : "Nilai min tidak boleh lebih besar dari max"});
            return false;
        }

        let data = {
            ...dataPersist,
            min,
            max,
            kondisi : _.keys(_.pickBy(kondisi)),
            shipment :  _.keys(_.pickBy(shipmentChecked)),
            spesifikasi: spesifikasisChecked
        }

        if(diskonCheked){
            data = {...data, diskon : diskonCheked}
        }

        this.setDataPost(data);
    }

    onChange = (data) => {
        // this.props.history.replace(`/category/${data}`);
        this.setState({params: {id: data}});
    }

    handleCategory(id, sub_id){
        let params = {id, sub_id};
        this.setState({params} ,() => {
            let data = {
                ...this.state.dataPersist,
                ...params,
                sort_id: this.state.sort_id
            }
            this.props.fetchCategory(params)
            this.setDataPost(data)
        });
    }

    onClickLikeButton (product, likeActive) {
        let { token } = this.props.userData;
        if(this.props.userId){
            this.setState({ loadingButton: true });
            if(!likeActive){
                /* INSERT LIKE TABLE */
                
                let data = {
                    product_id : product.id,
                    user_id: this.props.userId,
                    action: "INSERT" 
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.setState({ loadingButton: false }, () => { 
                        Toast.fire({
                            text: "Barang berhasil ditambahkan ke daftar favorite",
                            type: "success"
                        });                        
                    });
                });

            }else{
                /* REMOVE FROM LIKE TABLEE */

                let data = {
                    id : likeActive.id,
                    user_id: this.props.userId,
                    action: "DELETE" 
                }

                this.props.onLikeProduct(data, token).then(() => {
                    this.setState({ loadingButton: false }, () => { 
                        Toast.fire({
                            text: "Barang berhasil dihapus dari daftar favorite",
                            type: "success"
                        });                        
                    });
                });
            }
        }else{
            this.props.toggleModalLogin(true);
        }
    }

    onChangefilterHarga = (value, name) =>{
        this.setState({[name] : value})
    }

    checkedKondisi = (e) => {
        let kon = parseInt(e.target.value)
        this.setState({
            kondisi : {
                ...this.state.kondisi,
                [kon] : !this.state.kondisi[kon]
            }
        })
    }
    
    onChangeSpesifikasi = (id) => { 
        let array = this.state.spesifikasisChecked;
        let check = _.find(array, function (o) { return o === id })
        if (check) { 
            _.pull(array, id)
        } else { 
            array.push(id)
        }
        this.setState({
            spesifikasisChecked: array
        })
    }

    isSpesifikasiChecked = (id) => { 
        let check = _.find(this.state.spesifikasisChecked   , function (o) { return o === id })
        if (check) { 
            return true;
        } else { 
            return false;
        }
    }

    onChangeShipment = (item) => {
        this.setState({
            shipmentChecked : {
                ...this.state.shipmentChecked,
                [item.id] : !this.state.shipmentChecked[item.id]
            }
        })
    }

    resetFilterProduct = (e) => {
        e.preventDefault();
        let kondisi = {1: true, 2: true};
        let shipmentChecked = {};
        _.each(this.state.shipment, d => {
            shipmentChecked = {...shipmentChecked, [d.id] : true};
        })

        this.setState({min : "", max: "", kondisi, shipmentChecked, diskonCheked : false},
        () => {
            let { params } = this.props.match;
            this.props.fetchCategory(params)
            this.setDataPost(params);
        })
    }

    sortProduct = (e) => {
        this.setState({sort_id : e.target.value}
        ,() => {

            let { dataPersist } = this.state;
            let data = {...dataPersist, sort_id : this.state.sort_id}

            this.setDataPost(data)
        });
    }

    loadSpesifikasiLainnya = (e, id) => { 
        e.preventDefault();
        this.setState({
            spesifikasi_all: [...this.state.spesifikasi_all, id],
            loadingFilter: true
        }, () => { 
            let dataa = {
                detail_category_id: this.state.dataPersist.sub_id,
                spesifikasi_all: this.state.spesifikasi_all
            }
            post(dataa, `load_spesifikasi`)
            .then(ress => { 
                this.setState({
                    spesifikasis: ress.data,
                    loadingFilter: false
                })
            })
        })
        
    }

    fetchMoreData = () => {
        let { next } = this.state.products_pagination.links;
        let { dataPersist } = this.state;
        // this.props.postMoreProducts(next, dataPersist);
            authPostPaginate(dataPersist, next, this.props.userData.token).then(res => {
                this.setState({
                    products: [...this.state.products, ...res.data.data],
                    products_pagination: res.data.meta.pagination,
                })
            })
    }

    hasMoreData = () => !(this.state.products.length === (this.state.products_pagination ? this.state.products_pagination.total : -1 ));
    
    
    render() {
        const { t } = this.props;
        return (
            <div className="mg-top">
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userId}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <CategoryLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                <div>
                <div className="container-fluid">
                    <div className="header-banner-2" style={{minHeight: "250px"}}>
                        <div className="row">
                            <div className="col-md-9 col-9">
                                {
                                    this.props.loadingCategory
                                    ? <h1>...</h1>
                                    : <h1>{this.props.category.name} <br/> {this.props.category.detail_category_name}</h1>
                                }
                            </div>
                            <div className="col-md-3 col-3">
                                <div className="text-right">
                                    {
                                        this.props.loadingCategory
                                        ? <div style={{width: "150px",verticalAlign : "middle", float: "right", marginTop: "50px"}}>
                                            <ReactLoading type="spinningBubbles" color="#ffffff" height={"50px"} width={"50px"}/>
                                        </div>
                                        : <img src={this.props.category.url_image} alt="category page"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="left-side">
                        <div className="category-menu">
                        <h2>{t("Category:kategori")}</h2>
                            {
                                this.props.loadingSideCategory
                                ? <LodingSideCategory/>
                                : this.props.listcategory.map(category => (
                                    <ListCategory 
                                        changeCategory={() => this.handleCategory(category.id)}
                                        parentActive={parseInt(this.props.match.params.id)}
                                        key={category.id} 
                                        active={this.state.params.sub_id}
                                        subActive={parseInt(this.state.params.sub_id)}
                                        onClick={(id) => this.handleCategory(category.id, id)}
                                        {...category} />
                                ))
                            }
                        </div>
                        {
                            this.state.loadingFilter ? <div style={{position:"relative", top: '200px'}}><LoadingInfiniteScroll/></div> 
                            : 
                            <Filter 
                                maxValue={this.state.max}
                                minValue={this.state.min}
                                kondisi={this.state.kondisi}
                                shipments={this.state.shipment}
                                spesifikasis={this.state.spesifikasis}
                                shipmentChecked={this.state.shipmentChecked}
                                diskonChecked={this.state.diskonCheked}
                                onChangeHarga={this.onChangefilterHarga}
                                checkedKondisi={this.checkedKondisi}
                                onChangeShipment={this.onChangeShipment}
                                isSpesifikasiChecked={this.isSpesifikasiChecked}
                                onChangeSpesifikasi={this.onChangeSpesifikasi}
                                onChangeDiskon={() => this.setState({diskonCheked : !this.state.diskonCheked})}
                                filterProduct={this.filterProduct}
                                resetFilterProduct={this.resetFilterProduct}
                                loadSpesifikasiLainnya={this.loadSpesifikasiLainnya}
                                />
                        }
                    </div>
                    <div className="category-detail">
                        <div className="sorting-input">
                            <select className="form-control" value={this.state.sort_id} onChange={this.sortProduct}>
                                <option value="2">{t("Category:termurah")}</option>
                                <option value="3">{t("Category:termahal")}</option>
                                <option value="4">{t("Category:terlaris")}</option>
                                <option value="5">{t("Category:populer")}</option>
                                <option value="6">{t("Category:terbaru")}</option>
                            </select>
                            <label>{t("Category:urutkan")}</label>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/category/${this.props.category.id}`}>{this.props.category.name}</Link></li>
                                {
                                    this.props.category.detail_category_name
                                    ? <li className="breadcrumb-item active" aria-current="page">{this.props.category.detail_category_name}</li>
                                    : null
                                }
                            </ol>
                        </nav>
                        <div className="clearfix"></div>
                        <h3>{this.props.category.detail_category_name}</h3>
                        <div className="product-container">
                            {
                                this.state.loadingProduct
                                ? <div style={{position:"relative", top: '200px'}}><LoadingInfiniteScroll/></div>
                                : <InfiniteScroll
                                    dataLength={this.state.products.length}
                                    next={this.fetchMoreData}
                                    hasMore={this.hasMoreData()}
                                    loader={<LoadingInfiniteScroll/>}
                                    >
                                    {
                                        this.state.products.length > 0
                                        ? this.state.products.map(product => 
                                        <Product 
                                            key={product.id}
                                            onClickLikeButton={(product, likeActive) => this.onClickLikeButton(product, likeActive)}
                                            likes={this.props.likes ? this.props.likes : []}
                                            loadingButton={this.state.loadingButton}
                                            {...product}
                                            />
                                        )

                                        : <EmptyResult
                                            image={imageEmpty}
                                            message={t("Category:tidak_ada_barang")}
                                            buttonText={0}
                                            />
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>

                
                <div className="clearfix"></div>
                {this.hasMoreData() ? null : <Footer/>}
            </div>
            </CSSTransition>
            </div>
        )
    }
    
}
const mapStateToProps = (state) => {
    return {
        category: state.categories.item,
        sub_category : state.categories.sub_category,
        listcategory : state.categories.category_and_sub,
        products : state.products.items,
        pagination: state.products.pagination,
        loadingSideCategory : state.loading.loadingCategoryWithSub,
        loadingCategory : state.loading.loadingCategory,
        loadingProduct : state.loading.loadingProduct,
        likes : state.user.user.product_favorite,
        userId : state.user.user.user,
        userData : state.user.user,
    }
    
}

export default NetworkDetector(withNamespaces("Category")(connect(mapStateToProps , { 
    fetchCategory, 
    toggleModalLogin, 
    fetchCategoryWithSub, 
    postProductsWithCategory,
    onLikeProduct,
    postMoreProducts
})(Category)));
