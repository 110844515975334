import React from 'react'
import ReactLoading from 'react-loading';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import id from "date-fns/locale/id";
import i18n from '../../i18n';
registerLocale("id", id);
/**
 * Filter
 */
const Filter = props => (
        <div>
            <div className="statistic-filter">
                <div className="row">
                    <div className="col-md-6">
                        {
                            props.menu_active === 1 || props.menu_active === 5 ?
                                <React.Fragment>
                                    <label className="mr20">{i18n.t("Statistic:filter.lihat")}</label>
                                    <div className="btn-option-container">
                                        <a href="/#" className={props.menu_active === 1 ? "btn btn-solid btn-option active" : "btn btn-solid btn-option"} onClick={props.onChangeMenuPendapatan} data-id={1}>{i18n.t("Statistic:filter.semua")}</a>&nbsp;
                                            <a href="/#" className={props.menu_active === 5 ? "btn btn-solid btn-option active" : "btn btn-solid btn-option"} onClick={props.onChangeMenuPendapatan} data-id={5}>{i18n.t("Statistic:filter.per_barang")}</a>
                                    </div>
                                </React.Fragment>
                                :
                                props.menu_active === 4 ?
                                    <div>
                                        <label className="mr20">{i18n.t("Statistic:filter.lihat_barang")}</label>
                                        <div className="btn-option-container">
                                            <a href="/#" className={`btn btn-solid btn-option ${props.sort_by_active === 1 ? 'active' : ''}`} data-id="1" onClick={props.onChangeSortBy}>{i18n.t("Statistic:filter.terlaris")}</a>
                                            <a href="/#" className={`btn btn-solid btn-option ${props.sort_by_active === 2 ? 'active' : ''}`} data-id="2" onClick={props.onChangeSortBy}>{i18n.t("Statistic:filter.dilihat")}</a>
                                            <a href="/#" className={`btn btn-solid btn-option ${props.sort_by_active === 3 ? 'active' : ''}`} data-id="3" onClick={props.onChangeSortBy}>{i18n.t("Statistic:filter.favorit")}</a>
                                            <a href="/#" className={`btn btn-solid btn-option ${props.sort_by_active === 4 ? 'active' : ''}`} data-id="4" onClick={props.onChangeSortBy}>{i18n.t("Statistic:filter.rating")}</a>
                                        </div>
                                    </div>
                                    : ''
                        }
                    </div>
                    <div className="col-md-6 text-right">
                        <a href="/#" style={{ display: props.menu_active === 4 ? '' : 'none' }} onClick={props.onChangeGridView} id="list"><div className={props.grid ? "icon-list-view" : "icon-list-view active"}></div></a>&nbsp;
                        <a href="/#" className="mr20" style={{ display: props.menu_active === 4 ? '' : 'none' }} onClick={props.onChangeGridView} id="grid"><div className={props.grid ? "icon-grid-view active" : "icon-grid-view"}></div></a>
                        <label className="mr20">{i18n.t("Statistic:filter.periode")}</label>
                        <div className="date-range pull-right">
                            <div className="pull-left">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <DatePicker
                                        selected={props.from}
                                        customInput={<input type="text" className="form-control" />}
                                        onChange={date => props.onChangeFrom(date)}
                                        placeholderText={i18n.t("Statistic:filter.dari")}
                                        showYearDropdown
                                        showMonthDropdown
                                        dateFormat="d MMMM yyyy"
                                        dropdownMode="select"
                                        locale={id}
                                    />
                                </div>
                            </div>
                            <span className="pull-left mr10 ml10">-</span>
                            <div className="pull-left">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <DatePicker
                                        selected={props.to}
                                        customInput={<input type="text" className="form-control" />}
                                        onChange={date => props.onChangeTo(date)}
                                        placeholderText={i18n.t("Statistic:filter.sampai")}
                                        showYearDropdown
                                        showMonthDropdown
                                        dateFormat="d MMMM yyyy"
                                        dropdownMode="select"
                                        locale={id}
                                    />
                                </div>
                            </div>
                            <span className="pull-left mr10 ml10"></span>
                            <a href="/#" className="btn btn-solid primary" onClick={props.onClickTampilkan} style={{ display: props.menu_active === 5 ? 'none' : '' }}>{i18n.t("Statistic:filter.tampilkan")}</a>
                        </div>
                    </div>
                </div>
            </div>
            {
                props.menu_active === 5 ?
                    <React.Fragment>
                        <div className="statistic-filter-addon">
                            <div className="row">
                                <div className="col-md-4">
                                    <label>{i18n.t("Statistic:filter.kategori")}</label>
                                    <select className="form-control" onChange={props.onChangeKategori} value={props.category_selected}>
                                        <option value="0">{i18n.t("Statistic:filter.kategori")}</option>
                                        {
                                            props.categories.map(data => (
                                                <option value={data.id} key={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label>{i18n.t("Statistic:filter.barang")}</label>
                                    <select className="form-control" disabled={props.products.length > 0 ? '' : 'disabled'} onChange={props.onChangeProduct} value={props.product_selected}>
                                        <option value="0" selected>{props.products_msg ? props.products_msg : i18n.t("Statistic:filter.barang")}</option>
                                        {
                                            props.products.map(data => (
                                                <option value={data.id} key={data.id}>{data.product_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    {
                                        props.LoadingShowBy && props.menu_active === 5 ?
                                            <ReactLoading type="spinningBubbles" color="#0C7F8F" width={20} height={20} style={{ margin: '29px', width: 20, height: 20, color: '#0C7F8F' }} />
                                            :
                                            <button className="btn btn-solid primary" onClick={props.onClickTampilkan} style={{ margin: '29px', display: props.menu_active === 5 ? '' : 'none' }} disabled={props.category_selected !== 0 && props.product_selected !== 0 ? '' : 'disabled'}>{i18n.t("Statistic:filter.tampilkan")}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : ''
            }
        </div>
)

export default Filter;