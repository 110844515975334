import React from 'react';
import styled from 'styled-components';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

const BtnEditMode = styled.a`
    min-width: 20px;
    width: 20px;
    padding: 5px;
    float: left;
    text-align: center;
    height: 30px;
`;

const SpesifikasiProductEdit = (props) => {
    return (
    <tr>
        {
            !props.editModeSpek ?
            <React.Fragment>
                <td align="center">
                    {props.master_spesifikasi.label}
                </td>
                <td align="center">
                    {props.label}
                </td>    
                <td align="center">
                    <div style={{ float: "left" }}>
                        <a href="/" className="mr10" onClick={props.onChangeEditModeSpek}>
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                        <span className="btn-remove red" onClick={props.onClickRemoveSpesifikasi}>
                                <i className="fas fa-times"></i>
                        </span>
                    </div>
                </td>
            </React.Fragment>
            :
            props.indexEditSpek === props.indexItem ? 
            <React.Fragment>
                <td>
                <AsyncCreatableSelect
                    cacheOptions
                    loadOptions={props.loadOptionsMasterSpesifikasi}
                    defaultOptions={props.all_master_spesifikasi}
                    // onInputChange={this.handleChange}
                    onChange={props.onChangeMasterSpesifikasiEdit}
                    value={props.master_spesifikasi_selected_edit}
                    placeholder="Tulis Nama Spesifikasi"
                    noOptionsMessage={(val)=> null}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                />  
                </td>
                <td>
                {
                    props.all_master_spesifikasi_detail.length > 0 ?
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={props.loadOptionsMasterSpesifikasiDetail}
                            defaultOptions={props.all_master_spesifikasi_detail}
                            // onInputChange={this.handleChange}
                            onChange={props.onChangeMasterSpesifikasiDetailEditAsync}
                            value={props.master_spesifikasi_detail_selected_edit}
                            placeholder="Tulis Data Spesifikasi"
                            noOptionsMessage={(val)=> null}
                            isDisabled={props.loadingDataSpesifikasi}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        />  
                    :
                        <input 
                            type="text"
                            name="data-spesifikasi"
                            className={`form-control`}
                            placeholder="Tulis Data Spesifikasi"
                            value={props.master_spesifikasi_detail_selected_edit.label}
                            onChange={props.onChangeMasterSpesifikasiDetailEdit}
                            disabled={props.loadingDataSpesifikasi ? "disabled" : ""}
                        />
                }
                </td>    
                <td align="center">
                    <div style={{ float: "left" }}>
                        <BtnEditMode href="/" className="btn-secondary" onClick={props.handleCancelEditMode}><i className="fas fa-times"></i></BtnEditMode>
                        <BtnEditMode href="/" className="primary btn-solid" onClick={props.handleOkEditSpekMode}><i className="fas fa-check"></i></BtnEditMode>
                    </div>                    
                </td>
            </React.Fragment>
            :
            <React.Fragment>
                <td align="center">
                    {props.master_spesifikasi.label}
                </td>
                <td align="center">
                    {props.label}
                </td>    
                <td align="center">
                    <div style={{ float: "left" }}>
                        <a href="/" className="mr10" onClick={props.onChangeEditModeSpek}>
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                        <span className="btn-remove red" onClick={props.onClickRemoveSpesifikasi}>
                                <i className="fas fa-times"></i>
                        </span>
                    </div>
                </td>
            </React.Fragment>            
        }
    </tr>
    )
}


export default SpesifikasiProductEdit
