import React from 'react';
import imageError from '../../assert/files/illustration/404.png'
// import Header from '../Global/Header'

const Page404 = () => (
    <React.Fragment>
    {/* <Header/> */}
    <div className="container-fluid">
        <div className="container p30 text-center">
            <img src={imageError} alt="404 server not found"/>
            <h2>Oops!</h2>
            <p className="fs15">Maaf, halaman yang Anda tuju tidak ditemukan.</p>
	    </div>
    </div>
    </React.Fragment>
)

export default Page404;