import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

import {
    NewHeader,
    HargaGrosirEdit
} from '../../../../components';
import {LodingThumnailDetailProduct} from '../../../../components/Loading/Loading';

import {
    toggleModalLogin
} from '../../../../actions/registerActions'

import ImageUploader from 'react-images-upload';

import TextField from '../../../../components/Product/_Component/TextField';
import {
    InfoPPN,
    LabelPPN,
    LabelPPNRight
} from '../../../../components/Product/_Component/PpnComponent';
// import SelectForm from '../../../../components/Product/_Component/SelectForm';
import NumberFormat from 'react-number-format';

import ReactLoading from 'react-loading';

import {
    Images
} from '../../../../components/Product/Images';

import {
    authGet,
    authPostFile,
    AlertError,
    ConfirmWarning,
    AlertWarning,
    authPost,
    Toast,
    ConfirmInfo,
    authPut,
    FormatUang
} from '../../../../helper';

import _ from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; 
import CreateProductLoading from '../../../../components/Loading/CreateProductLoading';
import SpesifikasiProductEdit from '../../../../components/Product/_Component/SpesifikasiProductEdit';
import NetworkDetector from '../../../PUBLIC/NetworkDetector';
import Fuse from "fuse.js";

const fuseOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 50,
    maxPatternLength: 12,
    minMatchCharLength: 2,
    keys: ["label"]
  };

const Loading = () => <div className="loading-btn" style={{marginLeft: "300px"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

class EditProduct extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            images : [],
            loadingUpload : false,
            nama: '',
            subtitle : '',
            current_detail_category_item : [],
            category_id : 0,
            berat : 0,
            shop_address : [],
            min_order : 1,
            address : [],
            address_checked : {},
            shipment_checked : {},
            shop_shipment : [],
            loading : false,
            ppn: false,
            grosir: false,
            prices: [],
            price_temps: [],
            priceEdit: {},
            disabledMinOrder: false,
            hargaGrosir: {
                min: '',
                max: '',
                price: '',
                price_vip: '',
                disabled: false,
            },
            editMode: false,
            dataEditGrosir: {},
            is_vip: false,
            master_spesifikasi: [],
            master_spesifikasi_detail: [],
            master_spesifikasi_selected: {
                value: null,
                label: null,
                is_new: false,
                is_new2: false,
            },
            master_spesifikasi_detail_selected: {
                value: null,
                label: null,
                is_new: false,
                master_spesifikasi: {}
            },
            master_spesifikasi_selected_edit: {
                value: null,
                label: null,
                is_new: false,
                is_new2: false,
            },
            master_spesifikasi_detail_selected_edit: {
                value: null,
                label: null,
                is_new: false,
                master_spesifikasi: {}
            },            
            dataSpesifikasi: [],
            loadingDataSpesifikasi: false,
            editModeSpek: false,
            indexEditSpek: 0,
            spesifikasi: false
        }
    }

    componentWillMount(){
        const { token } = this.props.userData;
        const { id } = this.props.match.params;

        authGet(`product/${id}/edit`, token)
        .then(res => {
            let objAddress = {};
            res.data.shop_address.map(item => {
                const exist = res.data.address.find(a => a.id === item.id);
                if(exist){
                    objAddress = {...objAddress, [item.id] : true};
                    return 1;
                }else{
                    objAddress = {...objAddress, [item.id] : false};
                    return 1;
                }
            })

            let objShipment = {};
            res.data.shop_shipment.map(item => {
                const exist = res.data.shipment.find(a => a.id === item.detail_id);
                if(exist){
                    objShipment = {...objShipment, [item.detail_id] : true}
                    return 1;
                }else{
                    objShipment = {...objShipment, [item.detail_id] : false}
                    return 1;
                }
            })

            this.setState({
                ...res.data,
                price_temps: res.data.prices,
                current_detail_category_item : res.data.detail_category_item.filter(item => item.category_id === res.data.category_id),
                address_checked : objAddress,
                shipment_checked : objShipment,
                master_spesifikasi: res.data.master_spesifikasi.map(d => { return {value: d.id, label: d.name} }),
                loadingPage: false
            });
        });
    }

    onDrop = (picture) => {
        let { id } = this.props.match.params;
        this.setState({loadingUpload : true}
        ,() => {
            let data = new FormData();
            data.append('file', picture[picture.length - 1]);
            data.append('product_id', id);

            authPostFile(data, `product_add_image`, this.props.userData.token)
            .then(res => {
                this.setState({
                    images : [...this.state.images, res.data],
                    loadingUpload : false
                }, () => {
                    Toast.fire({
                        type : "success",
                        text : "Gambar berhasil di upload"
                    })
                });
            })
            .catch(err => {
                this.setState({loadingUpload : false});
                AlertError.fire({text : err.response.data.message});
            })
        })
    }

    removeImage(e, data){
        e.preventDefault();
        ConfirmWarning.fire({
            text : "Apakah anda yakin ingin menghapus gambar ini?"
        }).then(result => {
            if(result.value){
                let product_id = this.props.match.params.id;
                let product_image_id = data.id;
                authPost({product_id, product_image_id}, `product_delete_image`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        images : this.state.images.filter(image => image.id !== res.data.id)
                    }, () => {
                        Toast.fire({
                            type : "success",
                            text : "Gambar berhasil di hapus"
                        })
                    })
                })
                .catch(err => {
                    AlertError.fire({text : err.response.data.message});
                })
            }
        })
    }

/** SPESIFIKASI START */
    // loadOptionsMasterSpesifikasi = (inputValue, callback) => {
    //     let data = this.state.master_spesifikasi.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
    //     callback(data);
    // }

    // loadOptionsMasterSpesifikasiDetail = (inputValue, callback) => {
    //     let data = this.state.master_spesifikasi_detail.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
    //     callback(data);
    // }
    loadOptionsMasterSpesifikasi = (inputValue, callback) => {
        const fuse = new Fuse(this.state.master_spesifikasi, fuseOptions);
        const datax = inputValue ? fuse.search(inputValue) : this.state.master_spesifikasi;
        let dataa = [];
        datax.map(item => dataa.push(item.item))
        callback(dataa);
        // let data = this.state.master_spesifikasi.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    loadOptionsMasterSpesifikasiDetail = (inputValue, callback) => {
        const fuse = new Fuse(this.state.master_spesifikasi_detail, fuseOptions);
        const datax = inputValue ? fuse.search(inputValue) : this.state.master_spesifikasi_detail;
        let dataa = [];
        datax.map(item => dataa.push(item.item))
        callback(dataa);
        // let data = this.state.master_spesifikasi_detail.filter(result => result.label.toLowerCase().includes(inputValue.toLowerCase()));
    }    
    /** Add New Product Specification - Start*/
    onChangeMasterSpesifikasi = (newValue) => {
        const { token } = this.props.userData;
        let data = { 
            'value' : newValue.value,
            'label' : newValue.label,
            'is_new' : newValue.__isNew__ ? true : false,
            'is_new2': newValue.__isNew__ ? true : false,
        }
        this.setState({
            master_spesifikasi_selected: data,
            master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
            loadingDataSpesifikasi: true,
            loadingSpesifikasi: true
        }, () => {
            if (!data.is_new) { 
                authGet(`product/spesifikasi/get_detail/${data.value}`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi_detail: res.data.data.map(d => { return {value: d.id, label: d.value} }),
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
            } else {
                let dataPost = {
                    'label': data.label,
                    'detail_category_id': this.state.sub_category_id
                }
                authPost(dataPost, `product/spesifikasi/save_new`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi_detail: [],
                        master_spesifikasi_selected: res.data.selected,
                        master_spesifikasi: res.data.data.map(d => { return {value: d.id, label: d.name} }),
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    }, () => { 
                        AlertError.fire({text : err.response.data.message });
                    });
                })                
            }
        })
    }
    
    onChangeMasterSpesifikasiDetailAsync = (newValue) => {
        if (!this.state.master_spesifikasi_selected.value) { 
            AlertError.fire({text : "Nama Spesifikasi wajib diisi"});
            return false;
        }
        let data = { 
            'value' : newValue.value,
            'label' : newValue.label,
            'is_new' : newValue.__isNew__ ? true : false,
            'master_spesifikasi' : this.state.master_spesifikasi_selected
        }        
        this.setState({
            master_spesifikasi_detail_selected: data,
        });
    }
    
    onChangeMasterSpesifikasiDetail = (e) => {
        if (!this.state.master_spesifikasi_selected.value) { 
            AlertError.fire({text : "Nama Spesifikasi wajib diisi"});
            return false;
        }
        let data = { 
            'value' : e.target.value,
            'label' : e.target.value,
            'is_new' : true,
            'master_spesifikasi' : this.state.master_spesifikasi_selected
        }        
        this.setState({
            master_spesifikasi_detail_selected: data,
        });
    }
    
    onClickAddSpesifikasi = () => { 
        /**
         * Pengecekan untuk mengantisipasi adanya data yang sama. 
         */
        if (!this.state.master_spesifikasi_detail_selected.value || !this.state.master_spesifikasi_selected.value) { 
            AlertError.fire({text : "Spesifikasi wajib diisi"});
            return false;
        }
        
        if (this.state.dataSpesifikasi.length > 0) {
            // let exists_master_spek = this.state.infoBarang.spesifikasi.filter(item => item.master_spesifikasi.value === this.state.master_spesifikasi_detail_selected.master_spesifikasi.value).length;
            // let exists_master_spek_detail = this.state.dataSpesifikasi.filter(item => _.lowerCase(item.value) === _.lowerCase(this.state.master_spesifikasi_detail_selected.value) && _.lowerCase(item.master_spesifikasi.value) === _.lowerCase(this.state.master_spesifikasi_detail_selected.master_spesifikasi.value)).length;
            let exists_master_spek_detail = this.state.dataSpesifikasi.filter(item => item.value.toString().toLowerCase() === this.state.master_spesifikasi_detail_selected.value.toString().toLowerCase() && item.master_spesifikasi.value.toString().toLowerCase() === this.state.master_spesifikasi_detail_selected.master_spesifikasi.value.toString().toLowerCase()).length;
            if (exists_master_spek_detail > 0) { 
                this.setState({
                    master_spesifikasi_selected: { value: null, label: null, is_new: false, is_new2: false},
                    master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
                }, () => { 
                    AlertError.fire({text : "Tidak dapat memilih data spesifikasi yang sama."})
                })            
            } else { 
                this.setState({
                    dataSpesifikasi: [...this.state.dataSpesifikasi, this.state.master_spesifikasi_detail_selected]
                }, () => {
                    this.setState({
                        master_spesifikasi_selected: { value: null, label: null, is_new: false, is_new2: false},
                        master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
                        master_spesifikasi_detail: [],
                    })
                })
            }
        } else { 
            this.setState({
                dataSpesifikasi: [...this.state.dataSpesifikasi, this.state.master_spesifikasi_detail_selected]
            }, () => {
                this.setState({
                    master_spesifikasi_selected: { value: null, label: null, is_new: false, is_new2: false},
                    master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
                    master_spesifikasi_detail: [],
                })
            })
        }
    }
    /** Add New Product Specification - End */
    onClickRemoveSpesifikasi = (item, index) => { 
        this.setState({
            dataSpesifikasi: this.state.dataSpesifikasi.filter((h, i) => i !== index),
        })
    }
    /** Edit New Product Specification - Start */
    
    onChangeEditModeSpek(item, i) {
        const { token } = this.props.userData;         
        authGet(`product/spesifikasi/get_detail/${item.master_spesifikasi.value}`, token)
        .then(res => {
            this.setState({
                master_spesifikasi_detail: res.data.data.map(d => { return {value: d.id, label: d.value} }),
                editModeSpek: true,
                indexEditSpek: i,
                master_spesifikasi_selected_edit: item.master_spesifikasi,
                master_spesifikasi_detail_selected_edit: item
            });
        })
    }

    handleCancelEditSpekMode() { 
        this.setState({
            editModeSpek: false,
            master_spesifikasi_selected: { value: null, label: null, is_new: false},
            master_spesifikasi_detail_selected: { value: "", label: "", is_new: false, master_spesifikasi: {}},
            indexEditSpek: 0,
        })
    }

    onChangeMasterSpesifikasiEdit = (newValue) => {
        const { token } = this.props.userData;
        let data = { 
            'value' : newValue.value,
            'label' : newValue.label,
            'is_new' : newValue.__isNew__ ? true : false,
            'is_new2' : newValue.__isNew__ ? true : false
        }        
        this.setState({
            master_spesifikasi_selected_edit: data,
            master_spesifikasi_detail_selected_edit: { value: null, label: null, is_new: false, master_spesifikasi: {}},
            loadingDataSpesifikasi: true,
            loadingSpesifikasi: true
        }, () => {
            if (!data.is_new) { 
                authGet(`product/spesifikasi/get_detail/${data.value}`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi_detail: res.data.data.map(d => { return {value: d.id, label: d.value} }),
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
            } else {
                let dataPost = {
                    'label': data.label,
                    'detail_category_id': this.state.sub_category_id
                }
                authPost(dataPost, `product/spesifikasi/save_new`, token)
                .then(res => {
                    this.setState({
                        master_spesifikasi_detail: [],
                        master_spesifikasi_selected_edit: res.data.selected,
                        master_spesifikasi: res.data.data.map(d => { return {value: d.id, label: d.name} }),
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loadingSpesifikasi: false,
                        loadingDataSpesifikasi: false
                    }, () => { 
                        AlertError.fire({text : err.response.data.message });
                    });
                })                
            }
        })
    }

    onChangeMasterSpesifikasiDetailEditAsync = (newValue) => {
        if (!this.state.master_spesifikasi_selected_edit.value) { 
            AlertError.fire({text : "Nama Spesifikasi wajib diisi"});
            return false;
        }
        let data = { 
            'value' : newValue.value,
            'label' : newValue.label,
            'is_new' : newValue.__isNew__ ? true : false,
            'master_spesifikasi' : this.state.master_spesifikasi_selected_edit
        }        
        this.setState({
            master_spesifikasi_detail_selected_edit: data,
        });
    }

    onChangeMasterSpesifikasiDetailEdit = (e) => {
        if (!this.state.master_spesifikasi_selected_edit.value) { 
            AlertError.fire({text : "Nama Spesifikasi wajib diisi"});
            return false;
        }
        let data = { 
            'value' : e.target.value,
            'label' : e.target.value,
            'is_new' : true,
            'master_spesifikasi' : this.state.master_spesifikasi_selected_edit
        }        
        this.setState({
            master_spesifikasi_detail_selected_edit: data,
        });
    }
    handleOkEditSpekMode = (i) => {
        /**
         * Pengecekan untuk mengantisipasi adanya data yang sama. 
         */
        if (!this.state.master_spesifikasi_detail_selected_edit.value || !this.state.master_spesifikasi_selected_edit.value) { 
            AlertError.fire({text : "Spesifikasi wajib diisi"});
            return false;
        }

        if (this.state.dataSpesifikasi.length > 0) {
            // let exists_master_spek = this.state.infoBarang.spesifikasi.filter(item => item.master_spesifikasi.value === this.state.master_spesifikasi_detail_selected.master_spesifikasi.value).length;
            let exists_master_spek_detail = this.state.dataSpesifikasi.filter(item => item.value === this.state.master_spesifikasi_detail_selected_edit.value).length;
            if (exists_master_spek_detail > 0) { 
                AlertError.fire({text : "Tidak dapat memilih data spesifikasi yang sama."});        
                return false;
            }
        }

        this.setState({
            dataSpesifikasi: this.state.dataSpesifikasi.map((data, index) => i === index ? {...data, ...this.state.master_spesifikasi_detail_selected_edit} : {...data}),
            master_spesifikasi_edit_selected: { value: null, label: null, is_new: false},
            master_spesifikasi_detail_edit_selected: { value: null, label: null, is_new: false, master_spesifikasi: {}},
            master_spesifikasi_detail: [],
            editModeSpek: false,
            indexEditSpek: 0,
        });
    }
    /** Edit New Product Specification - Start */
/** SPESIFIKASI END */

    handleChangeProduct(target){
        this.setState({[target.name] : target.value})
    }
    
    handleChangeDescription(value) {
        this.setState({
            "description": value
        })
    }

    clickImageActive(e, data){
        e.preventDefault();

        authPost(data, `product_set_default_image`, this.props.userData.token)
        .then(res => {
            this.setState({images : res.data}
            ,() => {
                Toast.fire({
                    type : "success",
                    text : "Data berhasil di ubah"
                })
            })
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
        })
    }

    onChangeCategory = (e) => {
        this.setState({
            category_id : e.target.value,
            sub_category_id : 0,
            current_detail_category_item :this.state.detail_category_item.filter(item => item.category_id === parseInt(e.target.value))
        })
    }


    onChangeSubCategory = (e) => {
        this.setState({
            sub_category_id : e.target.value,
        })
    }

    handleChangeHargaSatuan(value){
        this.setState({real_price : value})
    }

    onChangeAlamat(item){
        this.setState({
            address_checked : {
                ...this.state.address_checked,
                [item.id] : !this.state.address_checked[item.id]
            },
        })
    }

    onChangeShipment(item){
        this.setState({
            shipment_checked : {
                ...this.state.shipment_checked,
                [item.detail_id] : !this.state.shipment_checked[item.detail_id]
            },
        })
    }

    updateProduct = () => {

        let {
            nama, 
            category_id, 
            sub_category_id, 
            berat, 
            real_price,
            real_price_vip,
            min_order, 
            address_checked,
            shipment_checked,
            subtitle,
            description,
            kondisi,
            ppn,
            grosir,
            prices,
            dataSpesifikasi
        } = this.state;

        let data = {
            nama,
            category_id,
            sub_category_id,
            berat,
            real_price,
            real_price_vip,
            min_order,
            address_checked,
            shipment_checked,
            subtitle,
            description,
            kondisi,
            ppn,
            grosir,
            prices,
            dataSpesifikasi
        }

        if(this.validateUpdateProduct(this.state)){
            ConfirmInfo.fire({
                text : "Apakah anda yakin akan mengubah data ini?"
            }).then(result => {
                if(result.value){
                    this.setState({loading: true});
                    
                    let arr_address = [];
                    _.each(address_checked, (item, key) => {
                        if(item){
                            arr_address.push(key);
                        }
                    })

                    let arr_shipment = [];
                    _.each(shipment_checked, (item, key) => {
                        if(item){
                            arr_shipment.push(key);
                        }
                    })

                    data.address_checked = arr_address;
                    data.shipment_checked = arr_shipment;

                    authPut(data, `product/update/${this.props.match.params.id}`, this.props.userData.token)
                    .then(res => {
                        Toast.fire({
                            type : "success",
                            title : "Data berhasil di simpan"
                        }).then(result => {
                            this.setState({loading: false});
                            this.props.history.push(`/myshop/product/view/${res.data.id}`);
                        })
                    })
                    .catch(err => {
                        this.setState({loading: false});
                        AlertError.fire({text : err.response.data.message});
                    })
                }
            })
        }
    }

    countOfObjectTrueValue(object){
        return Object.values(object).reduce((a, val) => a + val, 0)
    }

    validateUpdateProduct(data){
        const miliAlamat = this.countOfObjectTrueValue(data.address_checked);
        const miliPengiriman = this.countOfObjectTrueValue(data.shipment_checked);
        if(data.nama === ""){
            AlertWarning.fire({text : "nama tidak boleh kosong"});
            return false
        }
        else if(parseInt(data.category_id) === 0 || parseInt(data.sub_category_id) === 0){
            AlertWarning.fire({text : "Silahkan pilih kategori product"});
            return false
        }
        else if(data.berat === ""){
            AlertWarning.fire({text : "Berat tidak boleh kosong"});
            return false
        }
        // else if(parseInt(data.berat) > 30000){
        //     AlertWarning.fire({text : "Berat tidak boleh lebih dari 30kg"});
        //     return false
        // }            
        else if(parseInt(data.real_price) === 0 || data.real_price === ""){
            AlertWarning.fire({text : "Harga tidak boleh kosong"});
            return false
        }
        else if(parseInt(data.min_order) <= 0 || data.min_order === ""){
            AlertWarning.fire({text : "Minimal pembelian tidak boleh kosong"});
            return false
        }
        else if(miliAlamat === 0){
            AlertWarning.fire({text : "Pilih salah satu alamat"});
            return false
        }
        else if(miliPengiriman === 0){
            AlertWarning.fire({text : "Pilih salah satu Pengiriman"});
            return false
        }
        else if(this.state.editMode){
            AlertWarning.fire({text : "Perubahan harga belum selesai"});
            return false
        }
        else{
            return true
        }
    }

    handleChangeGrosir = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            hargaGrosir : {
                ...this.state.hargaGrosir,
                [e.target.name] : validateValue
            }
        })
    }

    onChangeHargaGrosir(value) {
        this.setState({
            hargaGrosir : {
                ...this.state.hargaGrosir,
                price : value
            }
        })
    }

    onChangeHargaGrosirVip(value) {
        this.setState({
            hargaGrosir : {
                ...this.state.hargaGrosir,
                price_vip : value
            }
        })
    }

    validateHargaGrosir(data){
        if(data.min === ""){
            AlertWarning.fire({text: "Jumlah minimal tidak boleh kosong!"});
            return false;
        }
        else if(data.harga === ""){
            AlertWarning.fire({text: "Harga tidak boleh kosong!"});
            return false;
        }
        else if(data.min > data.max){
            if(data.max !== ""){
                AlertWarning.fire({text: "Jumlah minimal tidak boleh lebih besar atau sama dengan jumlah maksimal"});
                return false;
            }
        }
        else if(data.min < data.min_order){
            AlertWarning.fire({text: "Jumlah minimal tidak boleh lebih kecil atau sama dengan minimal pembelian"});
            return false;
        }

        return true;
    }

    handleAddHargaGrosir = () => {
        let hargaGrosirs = this.state.prices;

        let data = {
            min : this.state.hargaGrosir.min !== "" ? parseInt(this.state.hargaGrosir.min) : "",
            max : this.state.hargaGrosir.max !== "" ? parseInt(this.state.hargaGrosir.max) : "",
            price : this.state.hargaGrosir.price !== "" ? parseInt(this.state.hargaGrosir.price) : "",
            price_vip : this.state.hargaGrosir.price_vip !== "" ? parseInt(this.state.hargaGrosir.price_vip) : "",
            min_order: this.state.min_order !== "" ? parseInt(this.state.min_order) : ""
        }

        if(this.validateHargaGrosir(data)) {
            if(hargaGrosirs[hargaGrosirs.length -1]){
                if(data.min <= hargaGrosirs[hargaGrosirs.length -1].max){
                    AlertWarning.fire({text: "Jumlah minimal tidak boleh lebih kecil atau sama dengan jumlah maksimal yang ada di daftar harga"});
                    return false;
                }
            }

            this.setState({
                prices : [
                    ...this.state.prices,
                    {
                        ...data,
                        max: data.max === "" ? `Lebih dari ${data.min}` : data.max,
                        price_discount: data.price,
                        price_discount_vip: data.price_vip
                    }
                ],
                hargaGrosir : {
                    min: data.max === "" ? "" : parseInt(data.max) + 1,
                    max: '',
                    price: '',
                    price_vip: '',
                    disabled: data.max === "" ? true : false,
                }
                
            }, () => {
                this.refMaxGrosir.focus();
                this.setState({})
            })
        }
    }

    handleRemoveHarga(item , index) {
        let { prices } = this.state;

        this.setState({
            prices: prices.filter((p, i) => i !== index),
            hargaGrosir: {
                ...this.state.hargaGrosir,
                min: item.min,
                max: '',
                price: '',
                disabled: item.max === "" ? true : false,
            }
        })
    }

    handleOffHargaGrosir = (e) => {
        e.preventDefault();
        if(this.state.prices.length > 0) {
            ConfirmWarning.fire({
                text: "Apakah anda yakin ingin membatalkannya? "
            }).then(result => {
                if(result.value) {
                    this.setState({
                        prices: [],
                        grosir: false,
                        hargaGrosir: {
                            min: '',
                            max: '',
                            price: '',
                            disabled: false
                        }
                    })
                }
            });
        }
        else {
            this.setState({grosir: false});
        }
        
    }

    onChangeEditMode(item, i) {
        this.setState({
            editMode: true,
            dataEditGrosir: {
                index: i,
                ...item
            }
        })
    }

    handleOkEditMode(item, i) {

        let dataGrosir = this.state.dataEditGrosir;

        if(parseInt(dataGrosir.price) < dataGrosir.price_discount && dataGrosir.price_discount !== this.state.price_temps[i].price) {
            AlertWarning.fire({text : "Perubahan harga lebih kecil dari harga setelah diskon"});
            return false;
        }

        if(parseInt(dataGrosir.price_vip) < dataGrosir.price_discount_vip && dataGrosir.price_discount_vip !== (this.state.price_temps[i] ? this.state.price_temps[i].price_vip : 0)) {
            AlertWarning.fire({text : "Perubahan harga lebih kecil dari harga setelah diskon"});
            return false;
        }

        this.setState({
            prices: this.state.prices.map((data, index) => i === index 
                                                    ? {
                                                        ...data, 
                                                        price_discount: parseInt(data.price) === parseInt(data.price_discount) ? dataGrosir.price : data.price_discount,
                                                        price: dataGrosir.price,
                                                        price_discount_vip: parseInt(data.price_vip) === parseInt(data.price_discount_vip) ? dataGrosir.price_vip : data.price_discount_vip,
                                                        price_vip: dataGrosir.price_vip
                                                    } 
                                                    : {...data}),
            editMode: false
        })
    }


    render() {

        let Page = (
            <div className="container p15" style={{marginTop: "62px"}}>
                    <div className="form-product">
                        <h1 className="form-title">Ubah Barang</h1>
                        {/* <div className="form-subtitle">
                            <h2>KATEGORI BARANG</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb30">
                                <div className="form-group-div">
                                    <label>Kategori <span className="red">*</span></label>
                                    <div className="row">
                                        <SelectForm 
                                            name="category"
                                            label="Pilih Kategori"
                                            items={this.state.category_item}
                                            onChange={this.onChangeCategory}
                                            value={this.state.category_id}
                                            />
                                        <SelectForm
                                            name="sub_category"
                                            label="Pilih Sub Kategori"
                                            items={this.state.current_detail_category_item}
                                            onChange={this.onChangeSubCategory}
                                            value={this.state.sub_category_id}
                                            other
                                            />
                                    </div>
                                </div>
                                
                            </div>
                        </div> */}
                        {/* DATA INFO BARANG START*/}

                        <div className="form-subtitle">
                            <h2>INFORMASI BARANG</h2>
                        </div>
                        <div className="row mb30">
                            <div className="col-md-12 mb30">

                                {/* UPLOAD IMAGE */}
                                <div className="form-group-div">
                                    <label>Gambar Barang <span className="red">*</span></label>
                                    {/* <div className="notification-box sm">
                                        <div className="primary">
                                            <i className="fas fa-exclamation-circle"></i>
                                            <p>Klik gambar untuk memilih gambar utama</p>
                                        </div>
                                    </div> */}
                                    <div>
                                        {
                                            this.state.images.map(image => (
                                                <Images
                                                    key={image.id}
                                                    removeImage={(e) => this.removeImage(e, image)}
                                                    clickImageActive={(e) => this.clickImageActive(e, image)}
                                                    {...image}
                                                />
                                            ))
                                        }
                                        {
                                            this.state.loadingUpload
                                            ? <div className="image-grid-shop">
                                                <LodingThumnailDetailProduct/>
                                            </div>
                                            : null
                                        }
                                        
                                    </div>
                                    <div className="col-md-8">
                                    <ImageUploader
                                        name="photo"
                                        label="Rasio gambar 1:1 (Persegi). Ukuran gambar minimum 300x300 pixel. "
                                        // withPreview={true}
                                        withLabel={true}
                                        withIcon={true}
                                        buttonText='Tambah Gambar'
                                        onChange={this.onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                        maxFileSize={3145728}
                                        singleImage
                                    />
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <TextField
                                            type="text"
                                            name="nama"
                                            label="Nama Barang"
                                            placeholder="Tulis Nama Barang"
                                            isRequire
                                            onChange={(e) => this.handleChangeProduct(e.target)}
                                            value={this.state.nama}
                                        />
                                    <TextField
                                        type="text"
                                        name="subtitle"
                                        label="Keterangan atau Subtitle"
                                        placeholder="Tulis Subtitle"
                                        onChange={(e) => this.setState({subtitle: e.target.value})}
                                        value={this.state.subtitle ? this.state.subtitle : ""}
                                    />

                                    {/* <div className="form-group-div">
                                        <label>Kategori <span className="red">*</span></label>
                                        <div className="row">
                                            <SelectForm 
                                                name="category"
                                                label="Pilih Kategori"
                                                items={this.state.category_item}
                                                onChange={this.onChangeCategory}
                                                value={this.state.category_id}
                                                />
                                            <SelectForm
                                                name="sub_category"
                                                label="Pilih Sub Kategori"
                                                items={this.state.current_detail_category_item}
                                                onChange={this.onChangeSubCategory}
                                                value={this.state.sub_category_id}
                                                other
                                                />
                                        </div>
                                    </div> */}

                                    <div className="form-group-div">
                                        <label>Kondisi</label><br />
                                        <label className="form-check pull-left mr10">
                                            <input 
                                                type="radio" 
                                                name="kondisi" 
                                                className="mr5"
                                                value={1}
                                                checked={parseInt(this.state.kondisi) === 1}
                                                onChange={(e) => this.handleChangeProduct(e.target)}
                                            /> 
                                            Baru
                                        </label>
                                        <label className="form-check pull-left">
                                            <input 
                                                type="radio" 
                                                name="kondisi" 
                                                className="mr5" 
                                                checked={parseInt(this.state.kondisi) === 2}
                                                onChange={(e) => this.handleChangeProduct(e.target)}
                                                value={2}
                                            /> 
                                            Bekas
                                        </label>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>

                                <TextField
                                    type="text"
                                    name="berat"
                                    label="Berat (gram) untuk pengiriman"
                                    placeholder="Tulis Berat Barang"
                                    className="w200"
                                    isRequire
                                    onChange={(e) => this.handleChangeProduct(e.target)}
                                    value={this.state.berat}
                                />
                                
                                <div className="form-group-div">
                                    <label>Deskripsi Barang</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.description}
                                    config={{ toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "insertTable"] }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        this.handleChangeDescription(data)
                                    }}
                                /> 
                                    {/* <textarea 
                                        name="description" 
                                        className="form-control" 
                                        rows="6" 
                                        onChange={(e) => this.handleChangeProduct(e.target)}
                                        value={this.state.description}></textarea> */}
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="form_grosir_price" style={{display: this.state.spesifikasi? "block" : "none"}}>
                                    <label>Spesifikasi Produk</label>
                                    <table className="table table-form-spesifikasi">
                            <thead>
                                <tr>
                                    <td className="pr20">
                                    <AsyncCreatableSelect
                                        cacheOptions
                                        loadOptions={this.loadOptionsMasterSpesifikasi}
                                        defaultOptions={this.state.master_spesifikasi}
                                        // onInputChange={this.handleChange}
                                        onChange={this.onChangeMasterSpesifikasi}
                                        value={this.state.master_spesifikasi_selected}
                                        placeholder="Pilih atau tulis spesifikasi"
                                        noOptionsMessage={(val)=> null}
                                        isDisabled={this.state.editModeSpek}
                                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    />  
                                    </td>
                                    <td className="pr20">
                                        {
                                            this.state.master_spesifikasi_detail.length > 0 ?
                                                <AsyncCreatableSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptionsMasterSpesifikasiDetail}
                                                    defaultOptions={this.state.master_spesifikasi_detail}
                                                    // onInputChange={this.handleChange}
                                                    onChange={this.onChangeMasterSpesifikasiDetailAsync}
                                                    value={this.state.master_spesifikasi_detail_selected}
                                                    placeholder="Pilih atau tulis keterangan spesifikasi"
                                                    noOptionsMessage={(val)=> null}
                                                    isDisabled={this.state.loadingDataSpesifikasi || this.state.editModeSpek}
                                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                    // formatCreateLabel={userInput => `Buat baru "${userInput}"`}
                                                />  
                                            :
                                                <input 
                                                    type="text"
                                                    name="data-spesifikasi"
                                                    className={`form-control`}
                                                    placeholder="Tulis keterangan spesifikasi"
                                                    value={this.state.master_spesifikasi_detail_selected.label}
                                                    onChange={this.onChangeMasterSpesifikasiDetail}
                                                    disabled={this.state.loadingDataSpesifikasi || this.state.editModeSpek ? "disabled" : ""}
                                                />
                                        }
                                    </td>
                                    <td className="w40">
                                        <button type="button" className="btn btn-solid primary btn-plus" onClick={this.onClickAddSpesifikasi} disabled={this.state.editModeSpek ? "disabled" : ""}>
                                            <i className="fas fa-plus no-margin"></i>
                                        </button>
                                    </td>                                    
                                </tr>
                                <tr>
                                    <td className="pr20">
                                    <div className="notification-box sm" style={{ maxWidth: "374px" }}>
                                            <div className="info">
                                                <i className="fas fa-info-circle"></i>
                                                <p>Pilih atau tulis spesifikasi</p>
                                            </div>
                                        </div>   
                                    </td>
                                    <td className="pr20">
                                        <div className="notification-box sm" style={{ maxWidth: "374px" }}>
                                            <div className="info">
                                                <i className="fas fa-info-circle"></i>
                                                <p>Pilih atau tulis keterangan spesifikasi</p>
                                            </div>
                                        </div>   
                                    </td>
                                    <td className="w40">
                                        
                                    </td>                                    
                                </tr>                                    
                            </thead>
                                        <tbody>
                                                {
                                                    this.state.dataSpesifikasi.map((item, i)=>(
                                                        <SpesifikasiProductEdit 
                                                            key={i}
                                                            indexItem={i}
                                                            countItem={this.state.dataSpesifikasi.length}
                                                            editModeSpek={this.state.editModeSpek}
                                                            indexEditSpek={this.state.indexEditSpek}
                                                            onChangeEditModeSpek={(e) => {e.preventDefault();this.onChangeEditModeSpek(item, i)}}
                                                            onClickRemoveSpesifikasi={() => this.onClickRemoveSpesifikasi(item, i)}
                                                            handleCancelEditMode={(e) => {e.preventDefault();this.handleCancelEditSpekMode()}}
                                                            onChangeMasterSpesifikasiEdit={this.onChangeMasterSpesifikasiEdit}
                                                            onChangeMasterSpesifikasiDetailEdit={this.onChangeMasterSpesifikasiDetailEdit}
                                                            onChangeMasterSpesifikasiDetailEditAsync={this.onChangeMasterSpesifikasiDetailEditAsync}
                                                            handleOkEditSpekMode={(e) => {e.preventDefault();this.handleOkEditSpekMode(i);}}
                                                            all_master_spesifikasi={this.state.master_spesifikasi}
                                                            all_master_spesifikasi_detail={this.state.master_spesifikasi_detail}
                                                            onChangeMasterSpesifikasi={this.onChangeMasterSpesifikasi}
                                                            onChangeMasterSpesifikasiDetail={this.onChangeMasterSpesifikasiDetail}
                                                            onChangeMasterSpesifikasiDetailAsync={this.onChangeMasterSpesifikasiDetailAsync}
                                                            loadOptionsMasterSpesifikasi={this.loadOptionsMasterSpesifikasi}
                                                            loadOptionsMasterSpesifikasiDetail={this.loadOptionsMasterSpesifikasiDetail}
                                                            loadingDataSpesifikasi={this.state.loadingDataSpesifikasi}
                                                            master_spesifikasi_selected_edit={this.state.master_spesifikasi_selected_edit}
                                                            master_spesifikasi_detail_selected_edit={this.state.master_spesifikasi_detail_selected_edit}
                                                            item={item}
                                                            {...item}
                                                        />
                                                    ))
                                                }
                                            </tbody>
                                    </table>
                                </div>   
                            </div>                                 
                        </div>

                        {/* DATA INFO BARANG END*/}
                        
                        
                        {/* DATA INFO HARGA START*/}

                        <div className="form-subtitle">
                            <h2>INFORMASI HARGA</h2>
                        </div>
                        <div className="row mb30">
                            <div className="col-md-5"> 
                            {/* <div className="col-md-10"> 
                                KALAU GA ADA HARGA VIP, COL-MD-5 NYA GANTI JADI COL-MD-10*/}
                                <label>Harga Satuan <span className="red">*</span></label>
                                <div className="form_price">
                                    <div className="form-group-div" id="input_price">
                                        <NumberFormat
                                            name="real_price"
                                            type="text"
                                            className="form-control mb5 right"
                                            placeholder="Harga Satuan (Rp)"
                                            value={this.state.real_price} 
                                            displayType={'input'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            onValueChange={(values) => this.handleChangeHargaSatuan(values.value)}
                                            />
                                            
                                            <div style={{display: "flex", marginBottom: "20px", marginTop: "10px"}}>
                                                <div className="w100p">
                                                    <input 
                                                        type="checkbox" 
                                                        checked={this.state.ppn} 
                                                        onChange={() => this.setState({ppn: !this.state.ppn})}/>
                                                    <LabelPPN>Dikenakan PPN 10%</LabelPPN>
                                                </div>
                                                {
                                                    this.state.ppn
                                                    ? <div className="w100p" style={{textAlign: "right", display: "flex"}}>
                                                        <LabelPPNRight style={{fontSize: "12px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={this.state.real_price === "" ? 0 : parseInt(this.state.real_price) + ((parseInt(this.state.real_price)*10)/ 100)}/></LabelPPNRight>
                                                        <div>
                                                            <InfoPPN 
                                                                className="fas fa-info-circle c-info"
                                                                onMouseEnter={() => window.$(".label-info-ppn").css({opacity: 1, transition: 'opacity 0.5s ease-in'})}
                                                                onMouseLeave={() => window.$(".label-info-ppn").css({opacity: 0})}
                                                                ></InfoPPN>
                                                            <div 
                                                                className="alert alert-info label-info-ppn" 
                                                                style={{
                                                                    position: "absolute", 
                                                                    textAlign: "left", 
                                                                    width: "50%",
                                                                    opacity: 0
                                                                }}>
                                                                Harga ini adalah harga yang tertera pada daftar barang dan detail barang
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                
                                            </div>
                                            
                                            {
                                                this.state.grosir
                                                ? <a 
                                                    href="/#" 
                                                    className="btn btn-outline-danger btn-sm" 
                                                    onClick={this.handleOffHargaGrosir}
                                                    >
                                                        <i className="fas fa-times"></i>Batalkan harga grosir
                                                    </a>
                                                : <span 
                                                    className="text-link" 
                                                    onClick={() => this.setState({grosir: true, hargaGrosir: {...this.state.hargaGrosir, min: parseInt(this.state.min_order) + 1}})}>
                                                        <i className="fas fa-plus fs12"></i> Tambah Harga Grosir
                                                </span>
                                            }
                                            
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.is_vip
                                ? <div className="col-md-5">
                                    <label>Harga VIP Satuan <span className="red">*</span></label>
                                    <div className="form_price">
                                        <div className="form-group-div" id="input_price">
                                            <NumberFormat
                                                name="real_price"
                                                type="text"
                                                className="form-control mb5 right"
                                                placeholder="Harga Satuan (Rp)"
                                                value={this.state.real_price_vip} 
                                                displayType={'input'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                onValueChange={(values) => this.setState({real_price_vip:values.value})}
                                                />
                                                
                                                <div style={{display: "flex", marginBottom: "20px", marginTop: "6px", float: "right"}}>
                                                    {
                                                        this.state.ppn
                                                        ? <div className="w100p" style={{textAlign: "right", display: "flex"}}>
                                                            <LabelPPNRight style={{fontSize: "12px", color: "#84C225"}}>Harga + PPN 10% = <FormatUang value={this.state.real_price_vip === "" ? 0 : parseInt(this.state.real_price_vip) + ((parseInt(this.state.real_price_vip)*10)/ 100)}/></LabelPPNRight>
                                                            <div>
                                                                <InfoPPN 
                                                                    className="fas fa-info-circle c-info"
                                                                    onMouseEnter={() => window.$(".label-info-ppn").css({opacity: 1, transition: 'opacity 0.5s ease-in'})}
                                                                    onMouseLeave={() => window.$(".label-info-ppn").css({opacity: 0})}
                                                                    ></InfoPPN>
                                                                <div 
                                                                    className="alert alert-info label-info-ppn" 
                                                                    style={{
                                                                        position: "absolute", 
                                                                        textAlign: "left", 
                                                                        width: "50%",
                                                                        opacity: 0
                                                                    }}>
                                                                    Harga ini adalah harga yang tertera pada daftar barang dan detail barang
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                    
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            
                            <div className="col-md-2">
                                <div className="form-group-div">
                                    <label>Minimal Pembelian <span className="red">*</span></label>
                                    {/* minimal pembelian default 1 */}
                                    <input 
                                        name="min_order" 
                                        type="number" 
                                        className="form-control mb5" 
                                        value={this.state.min_order}
                                        onChange={(e) => this.handleChangeProduct(e.target)}
                                        disabled={this.state.grosir}
                                        />
                                </div>
                            </div>

                            <div className="col-md-12">
                                        {/* HARGA GROSIR START */}
                                        <div className="form_grosir_price" style={{display: this.state.grosir ? "" : "none"}}>
                                        <label>Harga Grosir</label>
                                        <table className="table table-form">
                                            <thead>
                                                <tr>
                                                    <td className="w150">
                                                        <input 
                                                            name="min" 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Jumlah Min" 
                                                            onChange={this.handleChangeGrosir} 
                                                            value={this.state.hargaGrosir.min}
                                                            disabled={this.state.hargaGrosir.disabled || this.state.prices.length > 0}/>
                                                    </td>
                                                    <td align="center" className="w25"> - </td>
                                                    <td className="w150">
                                                        <input 
                                                            name="max" 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Jumlah Max"
                                                            ref={(input) => this.refMaxGrosir = input}
                                                            onChange={this.handleChangeGrosir} 
                                                            value={this.state.hargaGrosir.max}
                                                            disabled={this.state.hargaGrosir.disabled}/>
                                                    </td>
                                                    <td align="center" className="w25"> = </td>
                                                    <td className="pr10">
                                                    <NumberFormat
                                                        type="text"
                                                        className="form-control right"
                                                        placeholder="Harga (Rp)"
                                                        value={this.state.hargaGrosir.price} 
                                                        displayType={'input'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        onValueChange={(values) => this.onChangeHargaGrosir(values.value)}
                                                        disabled={this.state.hargaGrosir.disabled}
                                                        />
                                                    </td>
                                                    {
                                                        this.state.is_vip
                                                        ? <td className="pr10">
                                                            <NumberFormat
                                                                type="text"
                                                                className="form-control right"
                                                                placeholder="Harga VIP (Rp)"
                                                                value={this.state.hargaGrosir.price_vip} 
                                                                displayType={'input'}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                onValueChange={(values) => this.onChangeHargaGrosirVip(values.value)}
                                                                disabled={this.state.hargaGrosir.disabled}
                                                                />
                                                            </td>
                                                        : null
                                                    }
                                                    
                                                    <td className="w40">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-solid primary btn-plus" 
                                                            onClick={this.handleAddHargaGrosir} 
                                                            disabled={this.state.hargaGrosir.disabled}>
                                                                <i className="fas fa-plus no-margin"></i>
                                                            </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="notification-box sm">
                                                            <div className="info">
                                                                <i className="fas fa-info-circle"></i>
                                                                <p>Kosongkan jumlah maksimum untuk jumlah tak terbatas</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.prices.map((item, i)=>(
                                                        <HargaGrosirEdit 
                                                            key={i}
                                                            indexItem={i}
                                                            ppn={this.state.ppn}
                                                            countItem={this.state.prices.length}
                                                            editMode={this.state.editMode}
                                                            dataEditGrosir={this.state.dataEditGrosir}
                                                            onChangeEditMode={(e) => {e.preventDefault();this.onChangeEditMode(item, i)}}
                                                            handleRemoveHarga={() => this.handleRemoveHarga(item, i)}
                                                            handleCancelEditMode={(e) => {e.preventDefault();this.setState({editMode: false})}}
                                                            onChangeHargaGrosirEdit={(value) => this.setState({dataEditGrosir: {...this.state.dataEditGrosir, price: value.value}})}
                                                            onChangeHargaGrosirVipEdit={(value) => this.setState({dataEditGrosir: {...this.state.dataEditGrosir, price_vip: value.value}})}
                                                            handleOkEditMode={(e) => {e.preventDefault();this.handleOkEditMode(item, i);}}
                                                            is_vip={this.state.is_vip}
                                                            {...item}
                                                        />
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    {/*
                                        HARGA GROSIR END 
                                    */}
                            </div>

                        </div>
                        {/* b
                            DATA INFO HARGA END

                        */}



                        {/* 
                            DATA INFO ALAMAT START

                        */}

                        <div className="form-subtitle">
                            <h2>INFORMASI ALAMAT ASAL PENGIRIMAN</h2>
                        </div>
                        <div className="mb30">
                            <div className="form-group-div">
                                <div className="row">
                                    <div className="col-md-8">
                                        <label>Barang Tersedia di Alamat Pengiriman <span className="red">*</span></label>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label>Tersedia Pick Up</label>
                                    </div> */}
                                </div>
                                {
                                    this.state.shop_address.map((item) => (
                                        <div className="row" key={item.id}>
                                        <div className="col-md-8">
                                            <div className="box-outline mb0">
                                                <table cellPadding="10">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input 
                                                                    name="alamat" 
                                                                    type="checkbox" 
                                                                    value={item.id} 
                                                                    onChange={() => this.onChangeAlamat(item)}
                                                                    checked={this.state.address_checked[item.id]}
                                                                    />
                                                            </td>
                                                            <td>
                                                                <p><b>{item.name}</b></p>
                                                                <p>{item.district}, {item.city}, {item.zip_portal}</p>
                                                                <p className="mb0">{item.address}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                        </div>

                        {/*
                            DATA INFO ALAMAT END

                        */}


                        <div className="form-subtitle">
                            <h2>INFORMASI JASA PENGIRIMAN</h2>
                        </div>
                        <div className="row mb30">
                            <div className="col-md-8">
                                <div className="form-group-div">
                                    <label>Pilih Jasa Pengiriman <span className="red">*</span></label>
                                    {
                                        this.state.shop_shipment.map(shipment => (
                                            <div className="box-outline mb0" key={shipment.id}>
                                                <table cellPadding="10">
                                                    <tbody>
                                                        <tr>
                                                            <td className="w100" rowSpan="2"><img src={shipment.image} alt="shipment"/></td>
                                                            <td>
                                                                <div className="p10">
                                                                    <div className="w25 pull-left">
                                                                        <input
                                                                            value={shipment.detail_id}
                                                                            type="checkbox"
                                                                            onChange={() => this.onChangeShipment(shipment)}
                                                                            checked={this.state.shipment_checked[shipment.detail_id]}
                                                                        />
                                                                    </div>
                                                                    {shipment.name}
                                                                </div>
                                                                {/* {
                                                                    shipment.detail.map(item => (
                                                                        <div className="p10" key={item.id}>
                                                                            <div className="w25 pull-left">
                                                                                <input
                                                                                    value={item.id}
                                                                                    type="checkbox"
                                                                                    onChange={() => this.onChangeShipment(item)}
                                                                                    checked={this.state.shipment_checked[item.id]}
                                                                                />
                                                                            </div>
                                                                            {item.id === 1 ? `${shipment.name} (${item.name})` : `${shipment.name} - ${item.name}`}
                                                                        </div>
                                                                    ))
                                                                } */}

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row form-footer">
                            <div className="col-md-7">
                                <p className="grey">Pastikan barang yang Anda tambahkan sudah sesuai dengan syarat dan ketentuan Agrinis. Barang yang melanggar syarat dan ketentuan akan dinon-aktifkan.</p>
                            </div>
                            <div className="col-md-5 text-right pt10">
                                    {
                                        this.state.loading
                                        ? <Loading/>
                                        : <div>
                                            <button className="btn btn-solid darkgrey mr5" onClick={() => this.props.history.goBack() }>Batal</button>
                                            <button type="submit" className="btn btn-solid primary" onClick={this.updateProduct}>Simpan</button>
                                        </div>
                                    }
                            </div>
                        </div>


                    </div>
                </div>
        );

        return (
            <div>
                <NewHeader
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    {...this.props}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <CreateProductLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData : state.user.user
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    toggleModalLogin
})(EditProduct))
