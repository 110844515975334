import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
const HeaderNotification = props => {
    const { t } = props;
    return (
        <div className="notification-container" style={{display: "none"}}>
        <div className="triangle-notification"></div>
        <div className="notification-header">
            <label>{t("Header:pemberitahuan.title")}</label>
        </div>
        {
            props.data.length > 0 ? props.data.map(d => (
                <div className="notification-list" key={d.id}>
                    <a href="/#" onClick={(e) => {e.preventDefault();props.readNotification(d)}}>
                        {
                            d.image_url
                            ? <div className="notification-img">
                                    <img src={d.image_url} alt="Pesan" />
                                </div>
                            : null
                        }
                        <div className="notification-msg">
                            <label>{d.title}</label>
                            {
                                ( d.type === "pembelian" || 
                                d.type === "penjualan" || 
                                d.type === "complain" )
                                ? <p>{d.message}</p>
                                : <p>{d.concat_ref} {d.ref}</p>
                            }
                            
                        </div>
                        <div className="clearfix"></div>
                    </a>
                </div>
            )) : <div className="text-center pt5"><p className="grey">{t("Header:pemberitahuan.kosong")} </p></div>
        }
        
        
        <div className="text-center pt5">
                <Link to="/user/notification">{t("Header:pemberitahuan.detail")}</Link>
        </div>
        
        
    </div>
    )
}


export default withNamespaces()(HeaderNotification);