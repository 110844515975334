import React from 'react'
import { connect } from 'react-redux'
import Logo from '../../logo.png'
import { Link } from 'react-router-dom'
import Login from '../../components/Login/Login'
import NewMenu from './NewMenu'
import slug from 'slug'
import Cart from '../Cart/Cart'
import FloatingNotif from './FloatingNotif'
import { 
    toggleCartHidden, 
    clearCart,
    addToCartV2
} from '../../actions/cartActions'
import { 
    getUserData,
    closeNotifEmailVerify
} from '../../actions/userAction'
import { clearDataTransaction } from '../../actions/transactionAction'
import { handleChangeSearch, seacrhProduct, savePrevKeyword } from '../../actions/productActions'
import AuthService from "../../actions/API/AuthService"
import Swal from 'sweetalert2'
import {
    AlertInfo, 
    post, 
    authGet, 
    AlertError,
    callNotification,
    get,
    authPut,
    AlertLoading
} from '../../helper'
import { 
    setCategoryWithSub,
    setSubCategory 
} from '../../actions/categoryActions';
import { 
    setNotification,
    setNotificationData
} from '../../actions/notificationAction';
import {
    SuccessNotif
}from '../';
import ReactLoading from 'react-loading';
import { withI18n } from "react-i18next";
import i18n from '../../i18n';

class NewHeader extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            keyword : '',
            loadingEmail : false,
            searchAutocomplete: 'none',
            searchResult: [],
            areaSearch: false,
            isOnDiv: false,
            loadingSearch: false,
            openKategori: false,
            kategori: [],
            subKategori: [],
            kategoriActive: 1,
            kategoriSelected: '',
            view_toko: '',
            callNotif: false,
            seacrhProduct: [],
            searchShop: [],
            language: 'id',
        }
    }

    componentWillMount(){
        if(this.props.userData.token){
            
            authGet(`auth/check`, this.props.userData.token)
            .then(res => {
                if(!res.data.valid)
                {
                    this.props.getUserData({});
                    this.props.clearCart();
                    this.props.clearDataTransaction()
                    .then(() => {
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 1000);
                    })
                    
                }
                else {
                    this.syncHeader(res.data);
                }
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message})
            })
        }
    }

    syncHeader(data){
        this.props.getUserData(data.data);
        this.props.addToCartV2(data.cart);
        this.props.setNotificationData(data.notif);
    }

    componentWillUnmount(){
        if(this.props.match.path === "/search/:keyword"){
            this.props.handleChangeSearch('');
        }
        // this.props.toggleModalLogin(false);
    }

    componentDidMount() {
        this.timer = null;
        get('category_with_sub')
        .then(res => {
            this.props.setCategoryWithSub(res.data.data)
            // this.setState({
            //     kategori: res.data.data,
            //     kategoriSelected: res.data.data[0],
            //     subKategori: res.data.data[0].subCategory.data
            // })
        })
        
    }
    
    onSearch = (e) => {
        e.preventDefault();
        this.setState({
            seacrhProduct: [],
            searchShop: [],
        },() => {
            if(this.props.keyword !== ""){
                this.handleSearch(this.props.keyword);
            }
        })
    }

    handleSearch = (word) => {
        this.props.savePrevKeyword(word);
        let keyword = slug(word, {
            replacement: '-',      // replace spaces with replacement
            symbols: true,         // replace unicode symbols or not
            remove: null,          // (optional) regex to remove characters
            lower: true,           // result in lower case
            charmap: slug.charmap, // replace special characters
            multicharmap: slug.multicharmap // replace multi-characters
        })

        if (this.props.match.path === "/search/:keyword") {
            this.props.history.replace(`/search/${keyword}`);
            this.props.clearFilterPage();
        } else {
            this.props.history.replace(`/search/${keyword}`);
            this.props.handleChangeSearch(word);
        }
    }

    handleClickShop = (e) => {
        e.preventDefault();
        if(this.props.userData.email_verify === null){
            AlertInfo.fire({
                text : "Kamu Harus Verifikasi Email Terebih Dahulu"
            });
        }else if(this.props.userData.shop){
            this.props.history.push('/myshop');
        }else{
            this.props.history.push('/myshop/create');
        }
    }

    handleChangeLanguage = (e, lang) => {
        e.preventDefault();
        AlertLoading.fire({})
        i18n.changeLanguage(lang).then(() => {
            Swal.close()
        });
    }

    handleLogoutV2 = (e) => {
        e.preventDefault();
        authGet(`logout`, this.props.userData.token)
        .then(res => {
            this.props.setNotification("Logout berhasil");
            callNotification();
            window.$(".cart-container").css({"display": "none"})
            this.props.getUserData({});
            this.props.clearCart();
            this.props.clearDataTransaction();
        })
        .then(() => {
            setTimeout(() => {
                window.location.href = "/";
            }, 1000)
        })
        .catch(err => {
            AlertError.fire({
                text: err.response.data.message
            })
            .then(result => {
                window.location.href = "/";
            })
        })
    }

    handleLogout = async (e) => {
        e.preventDefault();
        await this.props.getUserData({})
        .then(() =>{
            this.props.clearCart()
            .then(() => {
                this.props.clearDataTransaction()
                .then(() => {
                    new AuthService().logout()
                    .then(() => {
                        // window.location.href = '/';
                        this.props.history.replace('/');
                    })
                })
            })
        })
        // Toast.fire({
        //     type : "success",
        //     title : "Berhasil Logout!"
        // });
        // this.props.history.push('/');
        // window.location.href = this.props.match.url ? this.props.match.url : '/';
    }

    setTimer = (val) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (val.length >= 1) {
                this.handleAutoComplete(val);
            } else {
                this.setState({
                    searchResult: {},
                    loadingSearch: false,
                    seacrhProduct: [],
                    searchShop: []
                })
            }

        }, 500);
    }

    handleAutoComplete = (keyword) => {
        let data = {
            "keyword": keyword
        }
        post(data, "get_search_result")
            .then(res => {
                let toko = res.data.data.filter(s => s.type==="Shops").length;
                this.setState({
                    // searchResult: res.data.data,
                    seacrhProduct: res.data.data.filter(d => d.type === "Products"),
                    searchShop:res.data.data.filter(d => d.type === "Shops"),
                    loadingSearch: false,
                    view_toko: toko > 0 ? "TOKO" : ""
                })
            });
    }

    onChangeSearch = (e) => {
        this.setState({
            keyword: e.target.value,
            loadingSearch: true 
        }, () => {
            this.setTimer(this.state.keyword);
            this.props.handleChangeSearch(this.state.keyword);
        });
    }

    onFocusSearch = (e) => {
        this.setState({
            // searchAutocomplete: "",
            // areaSearch: true,
            openKategori: false,
        })
        // this.setTimer(e.target.value);
    }

    onClickSearchResult = (data) => {
        this.setState({ 
            keyword: data.title, 
            searchAutocomplete: 'none', 
            isOnDiv: false,
            searchShop: [],
            seacrhProduct: []
        });
        if (data.type === "Products") {
            this.props.handleChangeSearch(data.title);
            this.handleSearch(data.title);
        }else{
            // window.location.href = data.url;
            this.props.handleChangeSearch("");
            this.props.history.push(data.url);
        }
    }

    onBlurSearch = (e) => {
        let {
            x,
            y,
            width,
            height
        } = this.refs.search_result.getBoundingClientRect();

        let cursorX = this.props.cursor.x;
        let cursorY = this.props.cursor.y;

        if(((cursorX < x || cursorX > (x + width)) || cursorY < y || cursorY > (y + height + 62)) ){
            this.setState({
                seacrhProduct: [],
                searchShop: [],
                loadingSearch: false
            })
        }
    }

    onMouveLeaveSearchResult = () => {
        setTimeout(() => {
            this.setState({ searchAutocomplete: 'none', isOnDiv: false });
            this.refs.search_box.blur();
        }, 500);
    }

    onClickKategori = (e) => {
        e.preventDefault()
        this.setState({
            searchAutocomplete: "none",
            openKategori: !this.state.openKategori
        })
    }
    highlightedSearchResult = (result, i) => {
        let textHighlighter;
        let searchKeywordIdx = result.toLowerCase().indexOf(this.state.keyword.toLowerCase());
        if (searchKeywordIdx > -1) {
            textHighlighter = [
                result.substring(0, searchKeywordIdx),
                <b key={i}>
                    {result.substring(searchKeywordIdx, searchKeywordIdx + this.state.keyword.length)}
                </b>,
                result.substring(searchKeywordIdx + this.state.keyword.length)
            ];
            return textHighlighter;
        }
        else{
            return result;
        }
    }
    onMouseOverKategoriItem = (id) => {
        this.setState({
            kategoriActive: id
        }, () => {
            let t = this.props.category.find(kat => kat.id === this.state.kategoriActive);
            this.props.setSubCategory(t);
            // this.setState({
            //     kategoriSelected: t,
            //     subKategori: t.subCategory.data
            // });
            // get(`single_category_with_sub/${id}`)
            //     .then(resx => {
            //         this.setState({
            //             kategoriSelected: resx.data.data[0],
            //             subKategori: resx.data.data[0].subCategory.data
            //         })
            //     })

        })
    }

    onClickCategoryItem = (id) => {
        if (this.props.match.path === "/category/:id/:sub_id?") {
            window.location.href = `/category/${id}`;
        } else {
            this.props.history.replace(`/category/${id}`);
        }
    }

    onClickSubCategoryItem = (category_id, id) => {
        if (this.props.match.path === "/category/:id/:sub_id?") {
            window.location.href = `/category/${category_id}/${id}`;
        }else{
            this.props.history.replace(`/category/${category_id}/${id}`);
        }
    }

    readNotification = (data) => {
        authPut(data, `user/read_notification/${data.id}`, this.props.userData.token)
        .then(res => {
            // this.syncHeader(res.data);
            // if(this.props.match.path.split("/")[1] === "transaction"){
            window.location.href = data.link_ref;
            // }else{
            //     this.props.history.push(data.link_ref);
            // }
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }


    render(){
        const { t } = this.props;
        return (
            <div className = "container-fluid container-menu" >
            <SuccessNotif message={this.props.notifMessage}/>
            <nav className = "navbar fixed-top navbar-expand navbar-light bg-light" >
                <Link to="/" className="navbar-brand">
                    <img src={Logo} alt="agrinis"/>
                </Link>
            
                    <div className="collapse navbar-collapse" id="mainNavbar">
                        <div className="menu-category-container">
                            <a href="/#" 
                                className="menu-category-text" 
                                onClick={this.onClickKategori}
                                >
                                    {t('Header:kategori')}
                                <i className="fas fa-angle-down ml10"></i>
                            </a>
                            <div className="menu-category" style={{display: this.state.openKategori ? "" : "none"}}>
                                <div className="menu-category-left">
                                    <ul>
                                        {this.props.category.map((data, i) => {
                                            return (
                                                <li 
                                                    key={i} 
                                                    onMouseOver={() => this.onMouseOverKategoriItem(data.id)} 
                                                    onClick={() => this.onClickCategoryItem(data.id)} 
                                                    className={this.props.categorySelected === data.id ? 'active' : ''}>
                                                    {data.name}
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                                <div className="menu-category-right">
                                    <div className="">
                                        <span className="menu-category-title">{this.props.categorySelected.name}</span>
                                        <ul>
                                        {
                                            this.props.subCategory.map((datax, ix) => {
                                                return ( 
                                                    <li 
                                                        key={ix} 
                                                        onClick={() => this.onClickSubCategoryItem(this.props.categorySelected.id, datax.id)}>
                                                            {datax.name}
                                                        </li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <form className="form-inline mr-auto">
                        <div>
                            {
                                this.state.loadingSearch
                                ? <div style={{
                                    position: "absolute",
                                    right: "25px",
                                    top: "20%"
                                }}>
                                    <ReactLoading type="spinningBubbles" color="#0C7F8F" width={20} height={20}/>
                                </div>
                                : null
                            }
                            
                        <input 
                        className="form-control" 
                        type="search" 
                        placeholder={t("Header:cari")}
                        aria-label="Search"
                        onChange={this.onChangeSearch}
                        onFocus={this.onFocusSearch}
                        onBlur={this.onBlurSearch}
                        value={this.props.keyword}
                        ref="search_box"
                        />
                        </div>
                        <button className="btn-search" onClick={this.onSearch}  ref={input => this.searchButton = input}><i className="fas fa-search"></i></button>
                        {
                            // this.state.loadingSearch 
                            // ? <div className="search-result">
                            //     <div
                            //         style={{
                            //             display: 'flex',
                            //             alignItems: 'center',
                            //             justifyContent: 'center',
                            //             height: '100%'
                            //         }}
                            //     ><LoadingInfiniteScroll /></div>
                            // </div>
                            // : 
                            <div className="search-result" 
                                    style={{
                                        display: this.state.seacrhProduct.length > 0 || this.state.searchShop.length > 0 ? "" : "none"
                                    }} 
                                    ref="search_result"
                                    // onMouseEnter={() => this.setState({isOnDiv: true})}
                                    // onMouseLeave={this.onMouveLeaveSearchResult}
                                    // onClick={() => this.state.isOnDiv ? this.refs.search_box.focus() : ""}
                                    >
                                <ul>
                                    {
                                        this.state.seacrhProduct.map((data, i) => (
                                            <li
                                                key={i}
                                                onClick={() => this.onClickSearchResult(data)}
                                                data-url={data.url}
                                                className="result" >
                                                {this.highlightedSearchResult(data.title, i)}
                                            </li>
                                            ))
                                    }
                                    </ul>
                                <span className="title-search-result">{this.state.view_toko}</span>
                                <ul className="shop-result">
                                    {
                                    
                                        this.state.searchShop.map((data, i) => (
                                            <li
                                                key={i}
                                                onClick={() => this.onClickSearchResult(data)}
                                                data-url={data.url}
                                                className="result"
                                            >
                                                <img src={data.image} className="shop-icon" alt={data.title} />
                                                <div>
                                                    <span className="block medium">{this.highlightedSearchResult(data.title, i)}</span>
                                                    <span className="grey">{data.lokasi}</span>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                {/* <span className="title-search-result">POPULER</span>
                                <div className="populer-result">
                                    <span className="label label-populer">Pakan Starter</span>
                                    <span className="label label-populer">Vitamin Ayam</span>
                                    <span className="label label-populer">Obat Ayam</span>
                                </div> */}
                            </div>
                        }
                    </form>
                </div>
                <NewMenu 
                    isLogin={this.props.stateLogin}
                    toggleModalLogin={this.props.toggleModalLogin}
                    toggleCartHidden={(e) => {e.preventDefault();this.props.toggleCartHidden()}}
                    countOrder={this.props.countOrder}
                    logout={this.handleLogoutV2}
                    hasShop={this.handleClickShop}
                    userName={this.props.userData.name ? this.props.userData.name.substring(0,1): ''}
                    userImage={this.props.userData.image_url}
                    notifData={this.props.notifData}
                    readNotification={this.readNotification}
                    handleChangeLanguage={this.handleChangeLanguage}
                    language={this.state.language}
                    {...this.props.userData}
                    />

                <Login {...this.props}/>
            </nav>
                <Cart {...this.props}/>
                {
                    this.props.userData.token
                    ? this.props.userData.email_verify
                        ? null
                        : this.props.userData.closeNotif
                            ? null
                            : <FloatingNotif 
                                    type="warning" 
                                    text="Anda belum melakukan konfirmasi email"
                                    sendEmail={this.props.userData.sendEmail}
                                    resendEmail={this.resendEmail}
                                    loading={this.state.loadingEmail}
                                    closeNotif={(e) => {e.preventDefault();this.props.closeNotifEmailVerify()}}
                                    />
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData : state.user.user,
        countOrder: state.carts.cartItems.length,
        keyword : state.products.keyword,
        category: state.headerCategory.category,
        categorySelected: state.headerCategory.categorySelected,
        subCategory: state.headerCategory.subCategory,
        notifMessage: state.notification.message,
        notifData: state.notification.data,
        cursor: state.headerCategory.cursor
    }
}
export default withI18n()(connect(mapStateToProps, { 
    toggleCartHidden, 
    getUserData,
    handleChangeSearch, 
    seacrhProduct,
    savePrevKeyword,
    clearCart,
    clearDataTransaction,
    closeNotifEmailVerify,
    setCategoryWithSub,
    setSubCategory,
    setNotification,
    addToCartV2,
    setNotificationData
})(NewHeader));
