import { FETCH_DISCUSSION_PRODUCT, NEW_DISCUSSION_COMMENT } from "../actions/types/discussionType";

const initialState = {
    items: [],
    item: {}
}

export default function(state = initialState, action){
    switch (action.type) {
        case FETCH_DISCUSSION_PRODUCT:
            return {
                ...state,
                items: action.payload
            }
        case NEW_DISCUSSION_COMMENT:
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
    }
}