import React from 'react'
import ContentLoader from 'react-content-loader'

function CreateShopLoading() {
    return (
        <div className="center-div">
        <ContentLoader 
            height={300}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="150"/>
            <rect x="0" y="180" rx="0" ry="0" width="400" height="25"/>
            <rect x="0" y="225" rx="0" ry="0" width="400" height="25"/>
        </ContentLoader>            
        <ContentLoader 
            height={200}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="25"/>
            <rect x="0" y="35" rx="0" ry="0" width="400" height="25"/>
            <rect x="0" y="70" rx="0" ry="0" width="400" height="25"/>
        </ContentLoader>
        <ContentLoader 
            height={100}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="50"/>
        </ContentLoader>               
        </div>
    )
}

export default CreateShopLoading
