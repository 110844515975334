import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
    NewHeader,
    Footer,
    LoadingInfiniteScroll
} from '../../../components';

import { 
    authGet,
    authPut, 
    AlertError,
    authGetPaginate,
    AlertWarning
} from '../../../helper';

import { 
    getUserData
} from '../../../actions/userAction';
import {
    toggleModalLogin
} from '../../../actions/registerActions';
import { 
    setNotificationData
} from '../../../actions/notificationAction';
import NotificationLoading from '../../../components/Loading/NotificationLoading';
import NetworkDetector from '../../PUBLIC/NetworkDetector';

class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            datas: [],
            unread : 0,
            pagination: {},
            loadData: false,
            loadingMoreData: false,
            checkedMode: false,
            checkedItems: [],
        }
    }

    componentDidMount(){
        this.getData(1);
    }

    getData = (sort_id) => {
        this.setState({loadData: true});
        authGet(`notification/get/${sort_id}`, this.props.userData.token)
        .then(res => {
            this.setState({
                datas: res.data.data,
                unread: res.data.unread,
                loadingPage: false,
                pagination: res.data.meta.pagination,
                loadData: false
            })
        })
    }

    readNotification = (data) => {

        if(!data.read_at){
            authPut(data, `user/read_notification/${data.id}`, this.props.userData.token)
            .then(res => {
                this.props.setNotificationData(res.data.notif);
                this.props.getUserData(res.data.data);
            })
            .then(() => {
                this.props.history.push(data.link_ref);
            })
            .catch(err => {
                AlertError.fire({text: err.response.data.message})
            })
        }else{
            this.props.history.push(data.link_ref);
        }
    }

    onSortData = (e) => {
        this.getData(e.target.value);
    }

    addOrRemoveChecked(item){
        let exists = this.state.checkedItems.find(checkedItem => checkedItem === item.id);
        if(exists){
            this.setState({
                checkedItems: this.state.checkedItems.filter(checkedItem => checkedItem !== item.id)
            })
        }else{
            this.setState({
                checkedItems: [
                    ...this.state.checkedItems,
                    item.id
                ]
                
            })
        }
    }

    readNotifications = (e) => {
        e.preventDefault();
        if(this.state.checkedItems.length === 0){
            AlertWarning.fire({text: "Pilih salah satu item"});
            return false;
        }

        authPut({data: this.state.checkedItems}, `notification/read`, this.props.userData.token)
        .then(res => {
            let new_data = this.state.datas.map(data => {
                let exists =  this.state.checkedItems.find(checkedItem => checkedItem === data.id);
                if(exists){
                    return {...data, read_at: 1};
                }
                return {...data};
            })

            this.setState({
                datas: new_data,
                checkedMode: false,
                unread: this.state.unread - this.state.checkedItems.length,
            }, () => {
                this.setState({
                    checkedItems: []
                })
                this.props.setNotificationData(res.data.notif);
                this.props.getUserData(res.data.data);
            })

        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message})
        })
    }

    loadMoreData = () => {
        let { next } = this.state.pagination.links;
        this.setState({loadingMoreData: true});
        authGetPaginate(next, this.props.userData.token)
        .then(res => {
            this.setState({
                datas : [
                    ...this.state.datas,
                    ...res.data.data,
                ],
                pagination : res.data.meta.pagination,
                loadingMoreData: false
            })
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
            this.setState({loadingMoreData: false});
        })
    }

    hasMoreData = () => !(this.state.datas.length === (this.state.pagination ? this.state.pagination.total : -1 ));

    render() {
        let Page = (
        <div>
            <div className="container" style={{marginTop: "62px"}}>
                <div className="basic-container">
                    <h3>Notifikasi</h3>
                    {
                        this.state.datas.length === 0
                        ? <p className="search-summary">Anda belum memiliki notifikasi</p>
                        : this.state.unread > 0
                            ? <p className="search-summary">Anda memiliki {this.state.unread} notifikasi belum dilihat</p>
                            : null
                    }
                    
                    <div className="clearfix"></div>
                    <div className="sorting-input">
                        <select className="form-control" onChange={this.onSortData}>
                            <option value={1}>Terbaru</option>
                            <option value={2}>Terlama</option>
                        </select>
                        <label>Urutkan</label>
                    </div>

                    <div className="notification-detail-container mt20">
                        <div className="mb15">
                            {
                                this.state.datas.length > 0
                                ? <React.Fragment>
                                    <a href="/#" onClick={(e) => {e.preventDefault();this.setState({checkedMode: !this.state.checkedMode})}}><i className="fas fa-edit"></i> Tandai</a>
                                </React.Fragment>
                                : null
                            }
                            
                        </div>
                        
                        {
                            this.state.loadData
                            ? <div style={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                height: window.outerHeight
                            }}>
                                <LoadingInfiniteScroll/>
                            </div>
                            : this.state.datas.map(data => (
                                <div className={`notification-list ${data.read_at ? "" : "unread"}`} key={data.id}>
                                    <span className="pull-right">{data.created_at}</span>
                                        {
                                        <div className="notification-img">
                                            {
                                                !data.read_at
                                                ? <input 
                                                    type="checkbox" 
                                                    className="mr10" 
                                                    style={{display: this.state.checkedMode ? "" : "none"}}
                                                    checked={this.state.checkedItems.find(item => item === data.id)}
                                                    onChange={() => this.addOrRemoveChecked(data)}
                                                    />
                                                : null
                                            }
                                            <img src={data.image_url} alt="Pesan" />
                                        </div>
                                        }
                                    <a href="/#" onClick={e => {e.preventDefault();this.readNotification(data)}}>
                                        <div className="notification-msg">
                                            <label>{data.title}</label>
                                            <p>{data.concat_ref} 
                                            {
                                                data.type === "pembelian" || data.type === "penjualan"
                                                ? <span className="primary">#{data.ref}</span>
                                                : <React.Fragment>{data.ref}</React.Fragment>
                                            }
                                            <br />{data.value}</p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a>
                                </div>
                            ))
                        }
                        

                        <div className="mt20">
                            <a onClick={this.readNotifications} href="/#" className="btn btn-sm btn-solid primary" style={{display: this.state.checkedMode ? "" : "none"}}>Tandai Sudah Dibaca</a>
                        </div>
                        {
                            this.state.loadData
                            ? null
                            : this.hasMoreData()
                                ? <div className="text-center">
                                    <button className="btn btn-solid primary" onClick={this.loadMoreData}>Notifikasi Lainnya</button>
                                </div>
                                : null
                        }
                        
                    </div>
                </div>
            </div>
            {
                this.state.datas.length > 8 && !this.state.loadData
                ? <Footer/>
                : null
            }
            
            </div>
        );
        return (
            <div>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <NotificationLoading/>
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {Page}
                </CSSTransition>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.user.user
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    getUserData,
    setNotificationData,
    toggleModalLogin
})(Notification));