import React from 'react';
import ContentLoader from "react-content-loader"

const MyShopLoading = () => (
<div>
    <div className="container-fluid">
                {/* 
                    BANNER
                
                */}        
    <ContentLoader 
            height={40}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%"/>
        </ContentLoader>
    </div>
    <div className="container p15">
    <div className="toko-tab">
                <ul>
                <ContentLoader 
                height={12}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="25" height="10"/>
                <rect x="27" y="0" rx="0" ry="0" width="25" height="10"/>
                <rect x="54" y="0" rx="0" ry="0" width="25" height="10"/>
                <rect x="340" y="0" rx="0" ry="0" width="50" height="10"/>
                </ContentLoader>
                </ul>
            </div>
        <div className="left-side">
            <div className="category-menu">
                {/* 
                    KATEGORI & FILTER
                
                */}
            <ContentLoader 
                        height={30}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="500" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>            
            <ContentLoader 
                        height={40}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="10" rx="10" ry="10" width="250" height="15"/>
            </ContentLoader>
            {/*
                FILTER
            */}
            <ContentLoader 
                        height={30}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="500" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={30}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="150" height="100%"/>
                        <rect x="190" y="0" rx="10" ry="10" width="150" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={30}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="150" height="100%"/>
                        <rect x="190" y="0" rx="10" ry="10" width="150" height="100%"/>
            </ContentLoader>           
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="450" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                        height={15}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="0" rx="10" ry="10" width="250" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                    height={20}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="10" y="0" rx="10" ry="10" width="500" height="100%"/>
            </ContentLoader>    
            <ContentLoader 
                        height={60}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="10" y="10" rx="10" ry="10" width="600" height="100%"/>
            </ContentLoader>                                            
            </div>

        </div>
        <div className="category-detail">
        <ContentLoader 
            height={15}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="3" ry="3" width="50" height="5"/>
            <rect x="290" y="2" rx="3" ry="3" width="30" height="5"/>
            <rect x="325" y="0" rx="3" ry="3" width="75" height="10"/>
        </ContentLoader>

            <div className="product-container">
            <div className="product-div" style={{marginBottom: 0, width: "25%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "25%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "25%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                <div className="product-div" style={{marginBottom: 0, width: "25%"}}>
                    <ContentLoader 
                        height={600}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    </div>


    <div className="clearfix"></div>
</div>
)

export default MyShopLoading;