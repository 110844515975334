import axios from 'axios';
import { API_URL } from "../../src/config";
// import { headers } from '../headers';
import { Toast } from '../helper';
import { 
    USER_DATA, 
    AFTER_VERIFY_EMAIL, 
    SET_SHOP_DATA ,
    SUBSCRIB_SHOP,
    SET_ADDRESS,
    SEND_EMAIL,
    CLOSE_NOTIF_EMAIL,
    SET_IMAGE_PROFILE,
    SET_TOKEN,
    SET_SALDO,
    SUBSCRIB_DATA,
    SET_CURSOR_POSITION
} from './types/userType'

import {
    ADD_TO_CART_API
} from './types/cartType'

import {
    SET_NOTIFICATION_DATA
} from './types/notificationType'

import {
    LOADING_FETCH_SUBSCRIBE_SHOP
} from './types/loadingType'


export const getUserData = (data) => dispatch => {
    return Promise.resolve().then(() => {
        dispatch({
            type: USER_DATA,
            payload : data
        })
    })
    // dispatch({
    //     type: USER_DATA,
    //     payload : data
    // })

}

export const checkAndSetUserData = token => dispatch => {
    return axios.get(`${API_URL}/auth/check`, {
        headers : {
            'Authorization' : 'Bearer ' + token, 
        }
    })
    .then(res => {
        if(res.data.valid){
            dispatch({
                type: USER_DATA,
                payload : res.data.data
            })
            dispatch({
                type: ADD_TO_CART_API,
                payload : res.data.cart
            })
            dispatch({
                type: SET_NOTIFICATION_DATA,
                payload : res.data.notif
            })
        }
    })
}

export const setUserShopData = (data) => dispatch => {
    dispatch({
        type: SET_SHOP_DATA,
        payload : data
    })
}

export const setUserSaldo = (data) => dispatch => {
    dispatch({
        type: SET_SALDO,
        payload : data
    })
}

export const resendEmailVerify = (token) => dispatch => {
    return axios.get(`${API_URL}/email/resend`, {
        headers : {
            'Authorization' : 'Bearer ' + token, 
        }
    })
    .then(res => {
        dispatch({
            type : SEND_EMAIL,
        })
    })
}


export const getUserAfterVerify = (token) => dispatch => {
    return axios.get(`${API_URL}/user`, {
        headers : {
            'Authorization' : 'Bearer ' + token, 
        }
    })
    .then(res => {
        dispatch({
            type: AFTER_VERIFY_EMAIL,
            payload : res.data
        });
    })
}

export const subScribeShop = (data, token, lang="id") => dispatch => {
    return axios.post(`${API_URL}/user_subscribe`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token, 
        }
    })
    .then(res => {
        dispatch({
            type: SUBSCRIB_SHOP,
            payload: res.data
        });
        Toast.fire({
            text: lang === "id" ? "Toko berhasil ditambahkan ke daftar langganan" : "Store successfully added to the subscription list",
            type: "success"
        });
    })
}

export const getMoreSubscribeShop = (url, token) => dispatch => {
    dispatch({
        type: LOADING_FETCH_SUBSCRIBE_SHOP,
        payload: true
    });
    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    })
        .then(res => {
            dispatch({
                type: SUBSCRIB_DATA,
                payload: res.data.data
            });
            dispatch({
                type: LOADING_FETCH_SUBSCRIBE_SHOP,
                payload: false
            });
        })
        .catch(err => {
            alert(err.message);
        })
}

export const unsubScribeShop = (data, token, lang="id") => dispatch => {
    return axios.post(`${API_URL}/user_unsubscribe`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token, 
        }
    })
    .then(res => {
        dispatch({
            type: SUBSCRIB_SHOP,
            payload: res.data
        });
        Toast.fire({
            text: lang === "id" ? "Toko berhasil dihapus dari daftar langganan" : "The store was successfully removed from the subscription list",
            type: "success"
        });
    })
}

export const create_shippingAddress = (data, token) => {
    return axios.post(`${API_URL}/shipping_address`, data, {
        headers : {
            'Authorization' : 'Bearer ' + token, 
        }
    });
}



export const setShippingAddress = (data) => dispatch => {
    dispatch({
        type: SET_ADDRESS,
        payload : data
    });
}


export const setImageProfile = (data) => dispatch => {
    dispatch({
        type: SET_IMAGE_PROFILE,
        payload : data
    });
}

export const setNewToken = (data) => dispatch => {
    dispatch({
        type: SET_TOKEN,
        payload : data
    });
}


export const closeNotifEmailVerify = () => dispatch => {
    dispatch({
        type: CLOSE_NOTIF_EMAIL
    });
}

export const setCursorPosition = (data) => dispatch => {
    dispatch({
        type: SET_CURSOR_POSITION,
        payload: data
    });
}

