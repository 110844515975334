import React from 'react';
import ContentLoader from "react-content-loader"

const SingleProductLoading = () => (
<div className="container">
    <div className="detail-product-container">
        {
            /**bread crumb ( navigasi ) */
        }
    <ContentLoader 
            height={15}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="20" height="5"/>
            <rect x="21" y="0" rx="0" ry="0" width="5" height="5"/>
            <rect x="27" y="0" rx="0" ry="0" width="20" height="5"/>
        </ContentLoader>
        <br/>
        <div className="product-side">
            <div className="image-product">
            <div className="image-thumbnail">
            {
            /* Image thumb */
            }
            <ContentLoader 
                    height={500}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="0" y="0" rx="3" ry="3" width="500" height="500"/>
                </ContentLoader>
                </div>
                <div className="image-slide" style={{zIndex: 9999}}>
                {
                    /* Image */
                }                    
                <ContentLoader 
                    height={400}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="15" y="0" rx="3" ry="3" width="400" height="400"/>
                </ContentLoader>
                {
                    /* Like */
                }   
                <ContentLoader 
                    height={50}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="90" y="10" rx="3" ry="3" width="100" height="20"/>
                    <rect x="200" y="10" rx="3" ry="3" width="5" height="20"/>
                    <rect x="215" y="10" rx="3" ry="3" width="100" height="20"/>
                </ContentLoader>
                </div>
            </div>
            <div className="detail-product">
                {
                /* Nama Produk */
                }                   
            <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="3" ry="3" width="500" height="100%"/>
            </ContentLoader>
            <ContentLoader 
                height={18}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="8" rx="3" ry="3" width="250" height="10"/>
            </ContentLoader>
            <ContentLoader 
                height={30}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="20" rx="3" ry="3" width="13" height="10"/>
                <rect x="15" y="20" rx="3" ry="3" width="13" height="10"/>
                <rect x="30" y="20" rx="3" ry="3" width="13" height="10"/>
                <rect x="45" y="20" rx="3" ry="3" width="13" height="10"/>
                <rect x="60" y="20" rx="3" ry="3" width="13" height="10"/>
                <rect x="85" y="20" rx="3" ry="3" width="56" height="10"/>
            </ContentLoader>
            <div className="clearfix"></div>
            <div className="price">
                <ContentLoader 
                height={90}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="30" rx="3" ry="3" width="250" height="40"/>
            </ContentLoader>
            </div>
            <br/>
            <ContentLoader 
                height={46}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="3" ry="3" width="70" height="38"/>
                <rect x="71" y="0" rx="3" ry="3" width="110" height="38"/>
                <rect x="182" y="0" rx="3" ry="3" width="70" height="38"/>
            </ContentLoader>
            <br/>
            <ContentLoader 
                height={75}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="20" rx="3" ry="3" width="250" height="50"/>
            </ContentLoader>
        </div>
            <div className="clearfix"></div>
            <div className="desc-product">
            {
                /**Tab */
            }
            <ContentLoader 
                height={30}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="20" rx="3" ry="3" width="45" height="10"/>
                <rect x="55" y="20" rx="3" ry="3" width="45" height="10"/>
                <rect x="105" y="20" rx="3" ry="3" width="45" height="10"/>
                <rect x="160" y="20" rx="3" ry="3" width="45" height="10"/>
            </ContentLoader>
            {
                /**Desciption */
            }
            <ContentLoader 
                height={50}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
            >
                <rect x="0" y="10" rx="3" ry="3" width="350" height="40" /> 
            </ContentLoader>
            </div>
            </div>
            <div className="store-side">
        <div className="store-product">
            {
                /**SEller */
            }
        <ContentLoader 
            height={265}
            width={341}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="72" y="82" rx="3" ry="3" width="161" height="10" /> 
            <circle cx="150" cy="41" r="30" /> 
            <rect x="100" y="96" rx="3" ry="3" width="113" height="8" /> 
            <rect x="77" y="113" rx="0" ry="0" width="156" height="37" /> 
            <rect x="76" y="157" rx="0" ry="0" width="156" height="37" /> 
            <rect x="76" y="202" rx="0" ry="0" width="156" height="37" />
        </ContentLoader>
            </div>
            {/* <p className="store-lastonline">Terakhir aktif 1 jam yang lalu</p> */}

        </div>
    </div>
        </div>
    )

export default SingleProductLoading;