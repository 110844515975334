import React, { Component } from 'react'
import { connect } from 'react-redux';

import { CSSTransition } from 'react-transition-group';

// import Header from '../../../../components/Transaction/Header'
import StepTransaction from '../../../../components/Transaction/StepTransaction'
import ListProductTransaction from '../../../../components/Transaction/ListProductTransaction'
import Notification from '../../../../components/Global/Notification'
// import _ from 'lodash';
import {
    // SuccessNotif
    NewHeader
} from '../../../../components';

import{
    FormatUang,
    ConfirmInfo,
    AlertWarning,
    AlertError,
    Toast,
    authPost,
    AlertInfo,
    authGet
} from '../../../../helper';

import {
    increment,
    decrement,
    getTotal,
    getTotalBerat,
    updateQuantity,
    getTotalPpn
} from '../../../../reducers/transaction.util'

import {
    getSellerInvoice,
    updateInvoiceBiayaPengiriman,
    updateInvoicePengiriman,
    updateInvoiceDitolak
} from '../../../../actions/transactionAction';

import {
    toggleModalLogin
} from '../../../../actions/registerActions';

import ReactLoading from 'react-loading';

import NumberFormat from 'react-number-format';
import { privateChannel } from '../../../../socket';
import HubungiPembeli from './../../../../components/Transaction/HubungiPembeli';
import ModalImage from "react-modal-image";
import * as moment from "moment";
import 'moment/locale/id';
import TransactionDetailLoading from '../../../../components/Loading/TransactionDetailLoading';
import NetworkDetector from '../../../PUBLIC/NetworkDetector'
moment.locale('id');
const Loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
const Complain = props => {
    let complain = props.complain;
    let diajukan_id = [1,6,12,16,19];
    let barang_accepted_id = [3,8];
    let kirim_barang_id = [9,13];
    let latest_status = complain.status.slice(-1)[0];
    return (
        <div>
            <div className="form-subtitle">
                <h2>Komplain & Retur</h2>
            </div>

            <div className="mb30">
                <table width="100%" className="mb10">
                    <tbody>
                        <tr>
                            <td valign="top" width="200"><label className="darkgrey">Status Komplain</label></td>
                            <td valign="top">
                                <ul className="status-step">
                                    {complain.status.map((data, i) => (
                                        <li key={i}>
                                            <div className={data.id === 22 || data.id === 23 ? "round-icon red" : "round-icon"}><i className={data.id === 22 || data.id === 23 ? "fas fa-times" : "fas fa-check"}></i></div>
                                            <div className="status-text">{data.title}</div>
                                            <div 
                                                className="status-date">
                                                    {moment(data.created_at).format("DD MMM YYYY HH:mm")} &nbsp;
                                                    {
                                                        data.shipment 
                                                        ? data.courier_answer 
                                                            ? <a href="/#" data-toggle="modal" data-target="#modalInfoKurir">Apa Kata Kurir?</a> 
                                                            : <a href="/#" data-toggle="modal" data-target="#modalInfoPengembalian" data-id={i} onClick={props.OnClickComplainShipment}>Info {data.shipment.link_title}</a> 
                                                        : ''
                                                    }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            <td valign="top" align="right">
                                {/* <a href="/#" data-toggle="modal" data-target="#modalHubungiPembeli" className="btn btn-outline primary"><i className="far fa-envelope"></i> Hubungi Pembeli</a> */}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="notification-box sm">
                    <div className="info">
                        <i className="fas fa-info-circle"></i>
                        <p>{latest_status.text_seller}</p>
                    </div>
                </div>
                {
                    complain.ditolak_reason_agrinis ? 
                    <div>
                        <table width="100%" className="mb10" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td valign="top" width="200"><label className="darkgrey">Catatan dari Agrinis</label></td>
                                    <td valign="top"><p className="mb0">{complain.ditolak_reason_agrinis}</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> : ''
                }
                {
                    barang_accepted_id.indexOf(latest_status.id) !== -1 ?
                        props.loadingButton ? <Loading /> :
                            <a href="/#" className="btn btn-block btn-solid primary p10 mb10" onClick={props.ComplainNextStep}>Barang Pengembalian Telah Diterima</a>
                        :
                        ''
                }
                {
                    kirim_barang_id.indexOf(latest_status.id) !== -1 ?
                        props.loadingButton ? <Loading /> :
                            <a href="/#" className="btn btn-block btn-solid primary p10 mb10" data-toggle="modal" data-target="#modalPengembalianBarang">Kirim Barang</a>
                        :
                        ''
                }
                {
                    latest_status.id === 20 ? 
                            <a href="/#" className="btn btn-block btn-solid primary p10 mb10" data-toggle="modal" data-target="#modalFormInfoKurir">Apa kata kurir?</a>
                    :
                    ''
                }
                <hr />
                <table width="100%" className="mb10" cellPadding="3">
                    <tbody>
                        <tr>
                            <td valign="top" width="200"><label className="darkgrey">Penerimaan Barang</label></td>
                            <td valign="top">
                                <span>{complain.status_barang}</span>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><label className="darkgrey">Permasalahan Barang</label></td>
                            <td valign="top">
                                <span>{complain.problem}</span>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><label className="darkgrey">Alasan Komplain</label></td>
                            <td valign="top">{complain.reason}</td>
                        </tr>
                        {
                            complain.status_barang === 'Belum diterima' ? '' :
                                <tr>
                                    <td valign="top"><label className="darkgrey">Foto Bukti Komplain</label></td>
                                    <td valign="top">
                                    {complain.images.map((data, i) => (
                                        <React.Fragment key={i}>
                                            {
                                                !data.image_thumb ?
                                                    <div className="complain-img"><img src={data.image} alt="" /></div> :
                                                    <ModalImage
                                                        className="complain-imgg"
                                                        small={data.image_thumb}
                                                        medium={data.image}
                                                        large={data.image}
                                                        alt="Foto Bukti Komplain"
                                                        showRotate={true}
                                                        hideDownload={true}
                                                    />
                                            }
                                        </React.Fragment>
                                    ))}
                                    </td>
                                </tr>
                        }
                        <tr>
                            <td valign="top"><label className="darkgrey">Solusi yang diinginkan</label></td>
                            <td valign="top">
                                <span>{complain.solution}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {
                    diajukan_id.indexOf(latest_status.id) !== -1 && (!complain.ditolak_at && complain.status_tolak_internal === 0)? 
                    props.loadingButton ? <Loading /> : 
                    <div className="row">
                        <div className="col-md-6 pr0">
                            <a href="/#" className="pull-right btn btn-solid yellow p10 btn-block" data-toggle="modal" data-target="#modalTolakKomplain">Tolak Komplain</a>
                        </div>
                        <div className="col-md-6 pl0">
                                <a href="/#" className="pull-right btn btn-solid primary p10 btn-block" onClick={props.ComplainNextStep}>Terima Komplain</a>
                        </div>
                    </div>
                    :
                    ''
                }
                <div className="clearfix"></div>
            </div>
        </div>
    )
}
class TransactionSellerDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            detail : [],
            alamat : {
                phone: ''
            },
            shipment : {},
            user : {},
            prosess : [],
            total : 0,
            total_berat: 0,
            grand_total: 0,
            loadingButton : false,
            edit_mode: false,
            products_temp: [],
            third_party: {},
            catatan_pengiriman: '',
            show_limit: false,
            show_limit_loading: false,
            barangSelected: {
                ulasan: {}
            },
            barangSelectedIndex: 0,
            ulasanText: '',
            catatan_pembeli: null,
            loadingTotalHarga: false,
            complain_shipment: '',
            complain_resi: '',
            complain_shipment_selected: {},
            tolak_reason: '',
            info_kurir: '',
            input_ditolak_reason: '',
            shop: {},
            shop_alamat: {},
            list_produsen: [],
            isProdusenExist: false,
            loadingPermintaanBarang: false,
            loadingModalTolakPesanan: false,
            tempo_piutang: null,
        }
    }

    componentWillMount(){
        privateChannel(this.props.userData)
        .notification(data => {
            if(data.type === "penjualan" || data.type === "complain" || data.type === "ulasan-pembeli"){
                this.getDataInvoice();
            }
        })
    }

    componentDidMount(){
        this.getDataInvoice()
    }

    getDataInvoice(){
        getSellerInvoice(this.props.match.params.id, this.props.userData.token)
        .then(res => {
            if(res.data.data.alamat){
    
                this.setState({ 
                    ...res.data.data, 
                    alamat: JSON.parse(res.data.data.alamat), 
                    loadingPage: false, 
                    activeBank: res.data.data.bank.id, 
                    bank: res.data.data.bank,
                    grand_total: res.data.data.total + res.data.data.biaya_pengiriman,
                    shop_alamat: res.data.data.shop.addresses.data[0],
                })
            }
            else{
                this.setState({
                    loadingPage: false
                }, () => {
                    AlertError.fire({text: "Transaksi tidak ditemukan"})
                    .then(() => {
                        this.props.history.push("/transaction");
                    })
                })
            }
        })
        .catch(err => {
            // alert(err.response)
            if(err.response){
                this.setState({
                    loadingPage: false
                },() => {
                    AlertError.fire({text : err.response.data})
                    .then(() => {
                        this.props.history.push("/transaction");
                    })
                })
            }
        })
    }

    incrementQty(product){
        let detail = increment(this.state.detail, product);
        // let total = getTotal(detail);
        this.setState({
            detail, 
            // total
        });
    }

    onchange(e, product){
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        if(validateValue === ''){
            validateValue = 0
        }

        let detail = updateQuantity(this.state.detail, {...product, jumlah : validateValue});
        // let total = getTotal(detail);
        this.setState({
            detail, 
            // total
        });
    }

    renderStar(starred) {

        let arr_star = [];
        for (let i = 0; i < 5; i++) {
            if (i < starred) {
                arr_star.push(<i className="fas fa-star starred" key={i}></i>)
            } else {
                arr_star.push(<i className="fas fa-star" key={i}></i>)
            }
        }

        return arr_star;
    }


    decrementQty(product){
        let detail = decrement(this.state.detail, product);
        // let total = getTotal(detail);
        this.setState({
            detail, 
            // total
        });
    }

    onEditMode = (e) => {
        e.preventDefault();
        this.setState({
            edit_mode: true,
            products_temp : this.state.detail,
        })
    }

    offEditMode = (e) => {
        e.preventDefault();
        this.setState({
            edit_mode: false,
            detail : this.state.products_temp,
        })
    }

    updateListProduct = (e) => {
        e.preventDefault();

        let total = getTotal(this.state.detail);
        let total_berat = getTotalBerat(this.state.detail);
        let ppn = getTotalPpn(this.state.detail) - (10 * getTotalPpn(this.state.detail) / 11);

        if (total_berat > 30000 && this.state.shipment.third_party === 1) { 
            AlertError.fire({text: "Berat melebihi batas maksimum 30 kg untuk jasa pengiriman yang telah dipilih."});
            return false;
        }
        
        this.setState({
            edit_mode: false,
            total,
            total_berat,
            ppn
        }, () => {
            if(this.state.shipment.third_party){
                let data = {
                    origin: this.state.alamat.third_party.city_id,
                    destination: this.state.third_party.city_id,
                    weight: this.state.total_berat,
                    courier: this.state.shipment.name
                }

                this.setState({loadingTotalHarga: true})
                authPost(data, `shipment/detail_cost`, this.props.userData.token)
                .then(res => {
                    this.setState({
                        biaya_pengiriman: res.data.value,
                        loadingTotalHarga: false
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response ? err.response.data.message : err.message});
                })
            }
        })
    }

    updateInvoice = (e) => {
        e.preventDefault()
        if(this.state.biaya_pengiriman === ""){
            AlertWarning.fire({text : "Biaya Pengiriman belum terisi"});
            return false;
        }

        ConfirmInfo.fire({
            text : "Apakah anda yakin data sudah benar?"
        }).then(({value}) => {
            if(value) {
                let data = {
                    invoice_id : this.state.id,
                    detail : this.state.detail,
                    biaya_pengiriman : this.state.biaya_pengiriman,
                    tempo_piutang: this.state.tempo_piutang,
                    total : this.state.total
                }

                this.setState({loadingButton : true});
    
                updateInvoiceBiayaPengiriman(data, this.props.userData.token)
                .then(res => {
                    // window.location.href = `/transaction/seller/${res.data.id}`;
                    this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat)}
                    , () => {
                        Toast.fire({
                            type : 'success',
                            title : 'Data berhasil Disimpan'
                        });
                        this.setState({loadingButton : false});
                    });
                })
                .catch(err => {
                    this.setState({loadingButton : false});
                    AlertError.fire({text : err.response.data.message})
                    .then(result => {
                        getSellerInvoice(this.props.match.params.id, this.props.userData.token)
                        .then(res => {
                            this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat)})
                        })
                    })
                })
            }
        })
            
        
    }

    updateInvoiceSent = (e) => {
        e.preventDefault();
        if (this.state.catatan_pengiriman === '') { 
            let text = this.state.shipment.third_party ? 'Nomor Resi wajib diisi!' : 'Catatan Pengiriman wajib diisi!';
            AlertWarning.fire({ text: text });
            return false;
        }else{
            ConfirmInfo.fire({
                text : "Apakah anda yakin ingin melanjutkan?"
            }).then(({value}) => {
                if(value) {
                    let data = {
                        invoice_id : this.state.id,
                        catatan_pengiriman: this.state.catatan_pengiriman,
                    }
            
                    this.setState({loadingButton : true});

                    updateInvoicePengiriman(data, this.props.userData.token)
                    .then(res => {
                        // window.location.href = `/transaction/seller/${res.data.id}`;
                        this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat)}
                        ,() => {
                            Toast.fire({
                                type : "success",
                                title : 'Data berhasil Disimpan'
                            })
                            this.setState({loadingButton : false});
                        });
                    })
                    .catch(err => {
                        this.setState({loadingButton : false});
                        AlertError.fire({text : err.response.data.message ? err.response.data.message : err.message});
                    })
                }
            })
    }
    }

    tolakPesanan = (e) => {
        e.preventDefault();
        ConfirmInfo.fire({
            text : "Apakah anda yakin ingin menolak pesanan ini?"
        }).then(({value}) => {
            if(value) {
                this.setState({
                    loadingModalTolakPesanan: true
                });
                let data = {
                    invoice_id : this.state.id,
                    ditolak_reason : this.state.input_ditolak_reason
                }
        
                updateInvoiceDitolak(data, this.props.userData.token)
                .then(res => {
                    this.setState({...res.data.data, alamat : JSON.parse(res.data.data.alamat), input_ditolak_reason: "", loadingModalTolakPesanan: false}
                    ,() => {
                        Toast.fire({
                            type : "success",
                            title : 'Data berhasil Dirubah'
                        })
                        window.$('#modalTolakPesanan').modal('hide');
                    });
                })
                .catch(err => {
                    AlertError.fire({text : err.response.data.message});
                })
            }
        })
    }

    onChangeCatatanPengiriman = (e) => { 
        this.setState({
            catatan_pengiriman: e.currentTarget.value
        });
    }

    onClickLihatLainnya = (e) => { 
        e.preventDefault();
        this.setState({show_limit_loading: true});
        authGet('invoice/seller_detail/'+this.state.id, this.props.userData.token)
        .then(res => {
            this.setState({
                detail: res.data,
                show_limit: false,
                show_limit_loading: false,
            });
        })
        .catch(err => {
            this.setState({show_limit_loading: false});
            AlertError.fire({ text: err.response.data.message });
        })
    }

    onClickBeriUlasan = (e) => {
        e.preventDefault();
        this.setState({
            barangSelected: this.state.detail[e.currentTarget.dataset.id],
            barangSelectedIndex: e.currentTarget.dataset.id,
            ulasanText: '',
        }, () => {
            window.$('#modalBalasUlasan').modal('show');
        });
    }
    onClickSimpanBalasan = (e) => {
        e.preventDefault();
        this.setState({loadingButton: true})
        let data = {
            product_ulasan_id: this.state.barangSelected.ulasan.id,
            text: this.state.ulasanText,
            invoice_id: this.state.id,
        }
        authPost(data, 'invoice/set_reply_ulasan', this.props.userData.token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat), 
                detail: this.state.show_limit_product === false ? res.data.data.detail_full : res.data.data.detail,
                loadingButton: false,
            }, () => {
                    Toast.fire({
                        type: "success",
                        title: 'Data berhasil disimpan'
                    });
                    window.$('#modalBalasUlasan').modal('hide');
            });
        })
        .catch(err => {
            this.setState({loadingButton: false})
            window.$('#modalBalasUlasan').modal('hide');
            AlertError.fire({text : err.response.data.message});
        })
    }

    ComplainNextStep = (e) => {
        e.preventDefault();
        this.setState({loadingButton : true});
        let data = {
            invoice_id: this.state.id,
            user_type: 'seller',
        }
        authPost(data, 'complain/next_step', this.props.userData.token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat),
                activeBank: res.data.data.bank.id, 
                bank: res.data.data.bank,
                grand_total: res.data.data.total + res.data.data.biaya_pengiriman,
                loadingButton: false,
            }, () => {
                    Toast.fire({
                        type: "success",
                        title: 'Data berhasil disimpan'
                    });
            });
            // window.$('#modalPilihPembayaran').modal('hide');
            // window.location.reload()
        })
    }

    validateNextShipment(data){
        if(data.complain_shipment === '' || data.complain_resi === ''){
            AlertInfo.fire({text: "Form tidak boleh kosong"});
            return false;
        }

        return true;
    }

    ComplainNextStepShipment = (e) => {
        e.preventDefault();
        this.setState({loadingButton: true});
        let data = {
            invoice_id: this.state.id,
            shipment: this.state.complain_shipment,
            resi: this.state.complain_resi,
            user_type: 'seller',
        }

        if(this.validateNextShipment(data)){
            authPost(data, 'complain/next_step_shipment', this.props.userData.token).then(res => {
                this.setState({
                    ...res.data.data,
                    alamat: JSON.parse(res.data.data.alamat),
                    activeBank: res.data.data.bank.id, 
                    bank: res.data.data.bank,
                    grand_total: res.data.data.total + res.data.data.biaya_pengiriman,
                    loadingButton: false,
                }, () => {
                        Toast.fire({
                            type: "success",
                            title: "Data berhasil disimpan!"
                        })
                        window.$('#modalPengembalianBarang').modal('hide');
                });
                // window.$('#modalPilihPembayaran').modal('hide');
                // window.location.reload()
            })
            .catch(err => {
                this.setState({loadingButton: false})
                window.$('#modalPengembalianBarang').modal('hide');
                AlertError.fire({text : err.response.data.message});
            })
        }
    }

    onChangePengembalianBarangModal = (e) => {
        this.setState({
            [e.currentTarget.name]: e.currentTarget.value,
        });
    }

    OnClickComplainShipment = (e) => {
        e.preventDefault();
        this.setState({
            complain_shipment_selected: this.state.complain.status[e.currentTarget.dataset.id].shipment
        });
        window.$('#modalInfoPengembalian').modal('show');
    }
    onChangeAlasanTolak = (e) => {
        this.setState({
            tolak_reason: e.currentTarget.value,
        });
    }
    onClickTolakComplain = (e) => {
        e.preventDefault();
        this.setState({loadingButton: true})
        let data = {
            complain_id: this.state.complain.id,
            tolak_reason: this.state.tolak_reason,
            user_type: 'seller',
        }
        authPost(data, 'complain/store_tolak', this.props.userData.token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat),
                activeBank: res.data.data.bank.id,
                bank: res.data.data.bank,
                grand_total: res.data.data.total + res.data.data.biaya_pengiriman,
                loadingButton: false,
            }, () => {
                Toast.fire({
                    type: "success",
                    title: "Data berhasil disimpan!"
                })
                window.$('#modalTolakKomplain').modal('hide');
            });
        })
    }
    onChangeInfoKurir = (e) => {
        this.setState({
            info_kurir: e.currentTarget.value,
        });
    }
    onChangeDitolakReason = (e) => {
        this.setState({
            input_ditolak_reason: e.currentTarget.value,
        });
    }    
    onClickComplainKurir = (e) => {
        e.preventDefault();
        this.setState({loadingButton: true});
        let data = {
            complain_id: this.state.complain.id,
            info_kurir: this.state.info_kurir,
            user_type: 'seller',
        }
        authPost(data, 'complain/next_step_kurir', this.props.userData.token).then(res => {
            this.setState({
                ...res.data.data,
                alamat: JSON.parse(res.data.data.alamat),
                activeBank: res.data.data.bank.id,
                bank: res.data.data.bank,
                grand_total: res.data.data.total + res.data.data.biaya_pengiriman,
                loadingButton: false,
            }, () => {
                Toast.fire({
                    type: "success",
                    title: "Data berhasil disimpan!"
                })
                window.$('#modalFormInfoKurir').modal('hide');
            });
            // window.$('#modalPilihPembayaran').modal('hide');
            // window.location.reload()
        })
    }

    onClickGeneratePDF = (e) => {
        e.preventDefault();
        fetch(this.state.link_invoice, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.userData.token
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = this.state.no_transaksi + ".pdf";
                    a.click();
                });
            });
    }

    onClickPermintaanBarang = (e) => {
        e.preventDefault();
        if (this.state.list_produsen.length === 0) { 
            this.setState({loadingPermintaanBarang: true})
            authGet("get_produsen_by_invoice/"+this.state.id, this.props.userData.token)
            .then(res => {
                this.setState({
                    list_produsen: res.data, 
                    loadingPermintaanBarang: false,
                }, () => {
                    window.$('#modalPermintaanBarang').modal('show');
                })
            })
        } else { 
            window.$('#modalPermintaanBarang').modal('show');
        }
        
    }
    onClickUnduhPermintaanBarang = (e, link, nama) => {
        e.preventDefault();
        fetch(link, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.userData.token
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = nama+"_"+this.state.no_transaksi + ".pdf";
                    a.click();
                });
            });
    }

    loading = () => <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

    render() {

        let rating = [];
        if(this.state.rating){

            for (let i = 0; i < 5; i++) {
                if(i < this.state.rating.rating){
                    rating.push(<i className="fas fa-star starred" key={i}></i>);
                }else{
                    rating.push(<i className="fas fa-star" key={i}></i>);
                }
            }
        }

        let user = this.state.user;
        let reference = {
            reference_id: this.state.id,
            reference_name: this.state.no_transaksi,
            reference_image: user.image,
            reference_total: this.state.grand_total,
            reference_type: 'invoice'
        }
        let message = '';
        if (this.state.complain) {
            if (this.state.complain.is_complain_finished) {
                if (this.state.status_id === 5) {
                    if (this.state.complain.dibatal_at || (this.state.complain.ditolak_at && this.state.complain.status_tolak_internal !== 0)) {
                        message = "Komplain telah diakhiri. ";
                    } else {
                        message = "Komplain selesai. ";
                    }
                } else {
                    if (this.state.complain.dibatal_at || (this.state.complain.ditolak_at && this.state.complain.status_tolak_internal !== 0)) {
                        message = "Komplain diakhiri. " + this.state.status;
                    } else {
                        message = "Komplain selesai. " + this.state.status;
                    }
                }
            } else {
                message = "Pembeli melakukan komplain & retur.";
            }
        } else {
            message = this.state.status;
        }
        let Page = (
            <div className="container">
                {/* <SuccessNotif message={this.props.notifMessage}/> */}
                    <div className="form-transaction">
                        {/* <Header url_back="/transaction"/> */}
                        <h1 className="form-title">Detail Pesanan</h1>
                        <form>
                            <div className="form-subtitle no-border">
                                <span className="grey mr10 inline-block">Tanggal</span>
                                <span className="fs17">{this.state.tanggal}</span>
                                <div className="pull-right">
                                    <span className="grey mr10 inline-block">No. Transaksi</span>
                                    <span className="fs17">{this.state.no_transaksi}</span>
                                </div>
                            </div>
                            {
                                this.state.status_id > 1 && this.state.status_id !== 7 && this.state.status_id !== 8 ?
                                    <React.Fragment>
                                        <div className="mb30">
                                            <div className="pull-right">
                                                {
                                                    this.state.shop.id !== 1 || !this.state.isProdusenExist ? null : this.state.loadingPermintaanBarang ? <span style={{ marginRight: "10px" }}>Loading...</span> : <a className="btn btn-outline primary" href="/#" onClick={this.onClickPermintaanBarang} style={{ marginRight: "10px" }}><i className="fas fa-file-pdf"></i> Permintaan Barang</a>
                                                }
                                                {/* <a href="/#" className="btn btn-outline primary mr5" data-toggle="modal" data-target="#modalHubungiPenjual"><i className="fas fa-file-pdf"></i> Daftar Pesanan</a> */}
                                                <a className="btn btn-outline primary" href="/#" onClick={this.onClickGeneratePDF}><i className="fas fa-file-pdf"></i> Faktur</a>
                                            </div>
                                        </div>
                                        <br />
                                    </React.Fragment> : null
                            }
                            <div className="mb30">
                                <StepTransaction prosess={this.state.prosess}/>
                            </div>
                            <Notification type={this.state.status_class} message={message} isComplain={this.state.complain ? this.state.complain.is_complain_finished ? true : false : false}/> 
                            {/* <Notification type="info" message="Menunggu pembayaran dari pembeli" /> 
                            
                            <Notification type="info" message="Proses pembayaran sudah selesai. Segera proses pesanan ini dan konfirmasi jika barang sudah dikirim." /> 
                            <Notification type="info" message="Menunggu pembeli konfirmasi pesanan diterima" /> 
                            <Notification type="success" message="Pesanan sudah selesai" />  */}
                            {
                                this.state.complain ?
                                    <Complain
                                        {...this.state}
                                        OnClickComplainShipment={this.OnClickComplainShipment}
                                        ComplainNextStep={this.ComplainNextStep}
                                    /> : null
                            }
                            {
                            !this.state.complain ?
                                this.state.status_id === 6 ? 
                                    <div>
                                        <div className="form-subtitle">
                                            <h2>Ulasan Pembeli</h2>
                                        </div>

                                        <div className="mb30 transaction-list">
                                            <table width="100%">
                                                <tbody>
                                                    {this.state.detail.map((barang, i) => <tr key={i}>
                                                        <td valign="top" className="product-img-2">
                                                            <img src={barang.image} alt="barang" />
                                                        </td>
                                                        <td className="product-desc">
                                                            {
                                                                barang.ulasan ?
                                                                    <div>
                                                                        {
                                                                            Object.keys(barang.ulasan.reply).length === 0 ? <a href="/#" className="pull-right btn btn-outline primary" data-id={i} onClick={this.onClickBeriUlasan}>Balas Ulasan</a> : ''
                                                                        }
                                                                        <p className="fs15 semibold mb0">{barang.name}</p>
                                                                        <div className="rating fs15 pull-left">
                                                                            {this.renderStar(barang.ulasan.rating)}
                                                                        </div>
                                                                        {/* <div className="pull-left pt5 ml20"><span className="grey mb20">19 Feb {(new Date().getFullYear())} 14:15</span></div> */}
                                                                        <div className="pull-left pt5 ml20"><span className="grey mb20">{moment(barang.ulasan.created_at).format("DD MMM YYYY HH:mm")}</span></div>
                                                                        <div className="clearfix"></div>
                                                                        <p>{barang.ulasan.review}</p>
                                                                        {
                                                                            barang.ulasan.image ?
                                                                                barang.ulasan.image.map((gambar, i) => (
                                                                                    <React.Fragment key={i}>
                                                                                        {!gambar.image_thumb ?
                                                                                            <div className="review-img" key={i}>
                                                                                                <img src={gambar.image_url} alt="" />
                                                                                            </div>
                                                                                            :
                                                                                            <ModalImage
                                                                                                className="review-imgg"
                                                                                                small={gambar.image_thumb}
                                                                                                medium={gambar.image_url}
                                                                                                large={gambar.image_url}
                                                                                                alt="Foto Ulasan"
                                                                                                showRotate={true}
                                                                                                hideDownload={true}
                                                                                            />}
                                                                                    </React.Fragment>
                                                                                ))
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            Object.keys(barang.ulasan.reply).length === 0 ? '' :
                                                                            <><br /><div className="review-reply" style={{marginTop: barang.ulasan.image.length > 0 ? "12%" : "0%"}}>
                                                                                    <p className="review-from">Oleh <span>{this.state.shop.name}</span> <span className="label label-seller">Penjual</span> &nbsp;&nbsp;.&nbsp;&nbsp;{barang.ulasan.reply.created_at}</p>
                                                                                    <p className="review-text">{barang.ulasan.reply.review}</p>
                                                                                </div></>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>

                                                                        <p className="fs15 semibold mb0">{barang.name}</p>
                                                                    </div>

                                                            }
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            {
                                            this.state.show_limit ? 
                                                this.state.show_limit_loading ? 
                                                <Loading /> 
                                                : <div className="text-center"><a href="/#" onClick={this.onClickLihatLainnya}>Lihat ulasan lainnya</a></div>
                                            : null
                                        }
                                        </div>
                                    </div> 
                                    : ''
                                :
                                (this.state.complain.solution_id === 1 || this.state.complain.solution_id === 4) && !this.state.complain.dibatal_at && !this.state.complain.ditolak_at ? '' :
                                    this.state.status_id === 6 ? 
                                <div>
                                <div className="form-subtitle">
                                    <h2>Ulasan Pembeli</h2>
                                </div>

                                <div className="mb30 transaction-list">
                                    <table width="100%">
                                    <tbody>
                                        {this.state.detail.map((barang, i) => <tr key={i}>
                                                        <td valign="top" className="product-img-2">
                                                            <img src={barang.image} alt="barang"/>
                                                        </td>
                                                        <td className="product-desc">
                                                            {
                                                                barang.ulasan ? 
                                                                <div>
                                                                    {
                                                                        Object.keys(barang.ulasan.reply).length === 0 ? <a href="/#" className="pull-right btn btn-outline primary" data-id={i} onClick={this.onClickBeriUlasan}>Balas Ulasan</a> : ''
                                                                    }
                                                                    <p className="fs15 semibold mb0">{barang.name}</p>
                                                                    <div className="rating fs15 pull-left">
                                                                        {this.renderStar(barang.ulasan.rating)}
                                                                    </div>
                                                                    {/* <div className="pull-left pt5 ml20"><span className="grey mb20">19 Feb {(new Date().getFullYear())} 14:15</span></div> */}
                                                                    <div className="pull-left pt5 ml20"><span className="grey mb20">{moment(barang.ulasan.created_at).format("DD MMM YYYY HH:mm")}</span></div>
                                                                    <div className="clearfix"></div>
                                                                    <p>{barang.ulasan.review}</p>
                                                                    {
                                                                            barang.ulasan.image ?
                                                                            <div className="review-image">
                                                                                {
                                                                                barang.ulasan.image.map((gambar, i) => (
                                                                                    <React.Fragment key={i}>
                                                                                        {!gambar.image_thumb ?
                                                                                            <div className="review-img" key={i}>
                                                                                                <img src={gambar.image_url} alt="" />
                                                                                            </div>
                                                                                            :
                                                                                            <ModalImage
                                                                                                className="review-imgg"
                                                                                                small={gambar.image_thumb}
                                                                                                medium={gambar.image_url}
                                                                                                large={gambar.image_url}
                                                                                                alt="Foto Ulasan"
                                                                                                showRotate={true}
                                                                                                hideDownload={true}
                                                                                            />}
                                                                                    </React.Fragment>
                                                                                ))
                                                                                }
                                                                                </div>
                                                                                :
                                                                                null
                                                                    }
                                                                    {
                                                                        Object.keys(barang.ulasan.reply).length === 0 ? '' : 
                                                                        <div className="review-reply">
                                                                            <p className="review-from">Oleh <span>{this.state.shop.name}</span> <span className="label label-seller">Penjual</span> &nbsp;&nbsp;.&nbsp;&nbsp;{barang.ulasan.reply.created_at}</p>
                                                                            <p className="review-text">{barang.ulasan.reply.review}</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div>
                                                                    
                                                                    <p className="fs15 semibold mb0">{barang.name}</p>
                                                                </div>

                                                            }
                                                        </td>
                                                    </tr>
                                        )} 
                                            </tbody>
                                    </table>
                                    {
                                            this.state.show_limit ? 
                                                this.state.show_limit_loading ? 
                                                <Loading /> 
                                                : <div className="text-center"><a href="/#" onClick={this.onClickLihatLainnya}>Lihat ulasan lainnya</a></div>
                                            : null
                                        }
                                </div>
                                </div>
                                : null
                            }
                            
                            {
                                this.state.ditolak_reason ? 
                                    <table width="100%" className="mb10" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" width="200"><label className="darkgrey">Alasan Penjual</label></td>
                                            <td valign="top"><p className="mb0">{this.state.ditolak_reason}</p></td>
                                        </tr>
                                    </tbody>
                                </table> : null
                            }
                            <div className="box-outline p10 mb30">
                                <span className="inline-block mr20 grey">Dipesan oleh</span>
                                {/* <div className="profile-user">u</div> */}
                                <img src={this.state.user.image} className="profile-picture mr5" alt="seller"/>
                                <span>{this.state.user.name}</span>
                                {
                                    this.state.status_id >= 4 && this.state.status_id !== 9 ? <a href="/#" className="btn btn-outline primary pull-right" data-toggle="modal" data-target="#modalHubungiPembeli"><i className="far fa-envelope"></i> Hubungi Pembeli</a> : null
                                }
                                <div className="clearfix"></div>
                            </div>

                            <div className="form-subtitle">
                                {
                                    this.state.status_id === 1 && !this.state.edit_mode
                                    ? <a 
                                        href="/#" 
                                        onClick={this.onEditMode}
                                        className="pull-right"><i className="fas fa-pencil-alt"></i> 
                                        Edit Pesanan
                                    </a>
                                    : null
                                }
                                <h2>Daftar Pesanan</h2>
                            </div>

                            <div className="mb30">
                                {
                                    this.state.detail.map(barang => 
                                    <ListProductTransaction 
                                        key={barang.id} 
                                        {...barang} 
                                        allowEditQty={this.state.edit_mode}
                                        quantity={barang.jumlah}
                                        berat={(barang.berat / 1000)}
                                        price={barang.harga}
                                        sub_total={barang.harga * barang.jumlah}
                                        addQty={() => this.incrementQty(barang)}
                                        minQty={() => this.decrementQty(barang)}
                                        onchange={(e) => this.onchange(e, barang)}
                                        /> 
                                    )
                                }
                                {
                                    this.state.edit_mode
                                    ? <React.Fragment>
                                        <div className="pull-right mt15">
                                            <a href="/#" className="btn btn-solid darkgrey" onClick={this.offEditMode}>Batal</a> <a href="/#" className="btn btn-solid primary" onClick={this.updateListProduct}>Simpan</a>
                                        </div>
                                        <div className="clearfix"></div>
                                    </React.Fragment>
                                    : null
                                }
                            {
                                this.state.show_limit 
                                ? this.state.show_limit_loading 
                                    ? <Loading /> 
                                    : <div className="text-center"><a href="/#" onClick={this.onClickLihatLainnya}>Lihat barang lainnya</a></div>
                                : null
                            }
                            </div>

                            {
                                this.state.catatan_pembeli
                                ? <React.Fragment>
                                    <div className="form-subtitle">
                                        <h2>Catatan Dari Pembeli</h2>
                                    </div>
                                    <div className="mb30">
                                        <p>{this.state.catatan_pembeli}</p>
                                    </div>
                                </React.Fragment>
                                : null
                            }
                            

                            <div className="form-subtitle">
                                <h2>Pengiriman</h2>
                            </div>
                            <div className="mb30">
                                <table width="100%" cellPadding="10">
                                    <tbody>
                                        <tr>
                                            <td valign="top"><span className="grey">Alamat Pengiriman</span></td>
                                            <td>
                                                <p className="bold mb0">{this.state.alamat.penerima}</p>
                                                <p className="mb0">
                                                <NumberFormat
                                                    value={this.state.alamat.phone} 
                                                    displayType={'text'}
                                                    format={"#### #### #### #"}
                                                    />
                                                    {/* {this.state.alamat.phone} */}
                                                </p>
                                                <p className="mb0">{this.state.alamat.alamat_lengkap} {this.state.alamat.kode_pos}<br />{this.state.alamat.kec} {this.state.alamat.kab}</p>		
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="center"><span className="grey">Jasa Pengiriman</span></td>
                                            <td>
                                                {/* <div className="w75 pull-left">
                                                    <img src={this.state.shipment.image} alt="seller"/>
                                                </div>
                                                <span>{this.state.shipment.name}</span> */}
                                                    {
                                                        this.state.status_id > 2 || this.state.shipment.third_party
                                                        ? <table width="100%" className="mb20">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="300">
                                                                        <div className="w75 pull-left">
                                                                            <img src={this.state.shipment.image} alt="seller" />
                                                                        </div>
                                                                        <span>{this.state.shipment.name}</span>
                                                                    </td>
                                                                    {
                                                                        this.state.shipment.ets
                                                                        ? <td valign="top">{this.state.shipment.ets} hari</td>
                                                                        : null
                                                                    }
                                                                    
                                                                    <td valign="top" align="right" className="semibold">Rp <FormatUang value={this.state.biaya_pengiriman}/></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <table width="100%" className="mb20"> 
                                                        <tbody>
                                                            <tr>
                                                                <td width="300"></td>
                                                                <td align="right">Biaya Pengiriman</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="300">
                                                                    <div className="w75 pull-left">
                                                                        <img src={this.state.shipment.image} alt="seller" />
                                                                    </div>
                                                                    <span>{this.state.shipment.name}</span>
                                                                </td>
                                                                <td valign="top" align="right" className="semibold">
                                                                    {
                                                                        this.state.status_id === 1
                                                                        ? <><NumberFormat
                                                                            type="text"
                                                                            className="form-control input-biaya-pengiriman"
                                                                            placeholder="Biaya pengiriman (Rp)"
                                                                            value={this.state.biaya_pengiriman} 
                                                                            defaultValue="0"
                                                                            displayType={'input'}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            onValueChange={(values) => this.setState({biaya_pengiriman : values.value})}
                                                                            />
                                                                            <div className="notification-box sm mt20" align="left">
                                                                                <div className="info">
                                                                                    <i className="fas fa-info-circle"></i>
                                                                                    <p>Isi biaya pengiriman dengan angka 0 bila metode pengiriman yang dipilih adalah Self Pickup.</p>
                                                                                </div>
                                                                            </div>  
                                                                            </>
                                                                        : <span>Rp <FormatUang value={this.state.biaya_pengiriman} /></span>
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    }
                                                    
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td valign="top"><span className="grey">Biaya Pengiriman</span></td>
                                            <td>
                                                {
                                                    this.state.status_id === 1
                                                    ? <NumberFormat
                                                        type="text"
                                                        className="form-control w50p"
                                                        placeholder="Biaya pengiriman (Rp)"
                                                        value={this.state.biaya_pengiriman > 0 ? this.state.biaya_pengiriman : ""} 
                                                        displayType={'input'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        onValueChange={(values) => this.setState({biaya_pengiriman : values.value})}
                                                        />
                                                    // <input 
                                                    //     type="number" 
                                                    //     className="form-control w50p" 
                                                    //     placeholder="Biaya pengiriman (Rp)" 
                                                    //     value={this.state.biaya_pengiriman > 0 ? this.state.biaya_pengiriman : ""} 
                                                    //     onChange={(e) => this.setState({biaya_pengiriman : e.target.value})}
                                                    //     />
                                                    : <span>Rp <FormatUang value={this.state.biaya_pengiriman} /></span>
                                                }
                                                
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>

                            <div className="footer-transaction-list">
                                
                                <div className="total-transaction">
                                    <div style={{fontFamily:"'Sofia Pro Regular', sans-serif"}}>
                                        <table style={{width: "100%"}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:"70%"}}>Total Belanja</td>
                                                    <td style={{textAlign: "right"}}>Rp</td>
                                                    <td style={{textAlign: "right", width:"10%"}}><FormatUang value={this.state.total - this.state.ppn}/></td>
                                                </tr>
                                                <tr>
                                                    <td>Total PPN</td>
                                                    <td style={{textAlign: "right"}}>Rp</td>
                                                    <td style={{textAlign: "right"}}><FormatUang value={this.state.ppn}/></td>
                                                </tr>
                                                <tr>
                                                    <td>Biaya Pengiriman</td>
                                                    <td style={{textAlign: "right"}}>Rp</td>
                                                    <td style={{textAlign: "right"}}><FormatUang value={this.state.biaya_pengiriman}/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr/>
                                    <span>TOTAL</span>
                                    <span className="pull-right fs19">
                                        {
                                            this.state.loadingTotalHarga
                                            ? this.loading()
                                            : <React.Fragment>
                                                Rp <FormatUang value={this.state.total + (this.state.biaya_pengiriman ? parseInt(this.state.biaya_pengiriman) : 0)} />
                                            </React.Fragment>
                                        }
                                        
                                    </span>
                                </div>
                                {
                                    this.state.member_vip
                                    ? <div className="total-transaction">
                                        <div style={{fontFamily:"'Sofia Pro Regular', sans-serif"}}>
                                            <table width="100%" cellPadding="10">
                                                <tbody>
                                                    <tr>
                                                        <td valign="top" width="20%"><span className="grey">Tempo Piutang</span></td>
                                                        <td style={{ display: this.state.status_id === 1 ? "" : "none" }}>
                                                            <div className="form-group-div">
                                                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                                                    <input 
                                                                        className="form-check-input" 
                                                                        type="radio" 
                                                                        name="gridRadios" 
                                                                        id="gridRadios1" 
                                                                        checked={parseInt(this.state.tempo_piutang) === 30}
                                                                        onChange={()=> this.setState({tempo_piutang: 30})}
                                                                        />
                                                                    <label className="form-check-label" htmlFor="gridRadios1" style={{ color:"#333333" }}>
                                                                        30 Hari
                                                                    </label>
                                                                </div>
                                                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                                                    <input 
                                                                        className="form-check-input" 
                                                                        type="radio" 
                                                                        name="gridRadios" 
                                                                        id="gridRadios2" 
                                                                        checked={parseInt(this.state.tempo_piutang) === 60}
                                                                        onChange={()=> this.setState({tempo_piutang: 60})}
                                                                        />
                                                                    <label className="form-check-label" htmlFor="gridRadios2" style={{ color:"#333333" }}>
                                                                        60 Hari
                                                                    </label>
                                                                </div>
                                                                {/* <div className="form-group-div">
                                                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                                                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
                                                                    <label className="form-check-label" htmlFor="gridRadios2" style={{ color:"#333333" }}>
                                                                        Atur sendiri
                                                                    </label>
                                                                </div>  
                                                                    <div className="input-group input-group-sm mb-2" style={{ width: "70%", marginLeft: "18px" }}>
                                                                        <input type="text" className="form-control form-control-sm" id="inlineFormInputGroup" placeholder="Jumlah hari" />
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">Hari</div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>  
                                                        </td>
                                                        <td style={{ display: this.state.status_id !== 1 ? "" : "none" }}>
                                                            {this.state.tempo_piutang} hari 
                                                            {/* <a href="/#"><i className="fas fa-pencil-alt" style={{ color: "#0C7F8F" }}></i></a> */}
                                                        </td>
                                                    </tr> 
                                                </tbody>
                                            </table>
                                            <div className="notification-box sm mt20">
                                                <div className="info">
                                                    <i className="fas fa-info-circle"></i>
                                                    <p>Tempo piutang adalah waktu jatuh tempo saat pembeli spesial membayar dengan piutang.</p>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                    : null
                                }
                                                                
                            </div>
                            
                            <div className="mb30 text-center">
                                {
                                    this.state.status_id === 1
                                    ? <div>
                                        {
                                            this.state.loadingButton
                                            ? this.loading()
                                            : <button className="btn btn-block btn-solid primary p10" onClick={this.updateInvoice} disabled={this.state.edit_mode ? "disabled" : ""}>Konfirmasi Pesanan</button>
                                        }
                                        <br /><br /><br /><br />
                                        {
                                            this.state.edit_mode ? <span>Tolak Pesanan</span> : <a href="/#" className="red" data-toggle="modal" data-target="#modalTolakPesanan">Tolak Pesanan</a>
                                        }
                                        <br /><br />
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="mb30">
                                {
                                    this.state.status_id === 4
                                    ? this.state.loadingButton
                                        ? this.loading()
                                        : <div><div className="form-group-div form-warning">
                                            <label>{this.state.shipment.third_party ? 'Nomor Resi' : 'Catatan Pengiriman'}</label><br />
                                            <textarea onChange={this.onChangeCatatanPengiriman} value={this.state.catatan_pengiriman} className="form-control" placeholder={this.state.shipment.third_party ? 'Tulis nomor resi' : 'Tulis catatan pengiriman'}></textarea>	
                                         </div>
                                         <button className="btn btn-block btn-solid primary p10" onClick={this.updateInvoiceSent}>Kirim Pesanan</button></div>
                                    : null
                                }
                            </div>
                        </form>
                    </div>
                    {reference.reference_id !== undefined ? <HubungiPembeli user={user} reference={reference} /> : ''}
                </div>
        );
        return (
            <div className="mg-top" style={{marginTop: "80px"}}>
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <TransactionDetailLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <div className="modal fade modal-2" id="modalBalasUlasan" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>BALAS ULASAN</h2>
                                    <div className="form-group-div">
                                        <div className="transaction-list">
                                            <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td valign="top" className="product-img-2 ulasan-img">
                                                        <img src={this.state.barangSelected.image} alt="img"/>
                                                    </td>
                                                    <td className="product-desc">
                                                        <p className="fs15 semibold mb0">{this.state.barangSelected.name}</p>
                                                        <div className="rating fs15 pull-left">
                                                            {this.renderStar(this.state.barangSelected.ulasan.rating)}   
                                                        </div>
                                                        <div className="pull-left pt5 ml20"><span className="grey mb20">{this.state.barangSelected.ulasan.created_at}</span></div>
                                                        <div className="clearfix"></div>
                                                        <p>{this.state.barangSelected.ulasan.review}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="form-group-div">
                                        <label>Beri Komentar</label>
                                        <textarea className="form-control" placeholder="Tulis Komentar" value={this.state.ulasanText} onChange={(e) => this.setState({ ulasanText: e.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                {
                                        this.state.loadingButton ?  <Loading /> 
                                        : 
                                        <>
                                    <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                    <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onClickSimpanBalasan}>Simpan</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalPengembalianBarang" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>PENGIRIMAN BARANG</h2>

                                    <div className="form-group-div">
                                        <label>Metode Pengiriman <span className="red">*</span></label>
                                        <input type="text" className="form-control" placeholder="Contoh: JNE, J&T, Tiki, Pengiriman sendiri" name="complain_shipment" onChange={this.onChangePengembalianBarangModal} value={this.state.complain_shipment} />
                                    </div>
                                    <div className="form-group-div">
                                        <label>Nomor Resi</label>
                                        <input type="text" className="form-control" placeholder="Tulis nomor resi" name="complain_resi" onChange={this.onChangePengembalianBarangModal} value={this.state.complain_resi} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingButton ?  <Loading /> 
                                        : 
                                        <>
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.ComplainNextStepShipment}>Simpan</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalInfoPengembalian" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>Info {this.state.complain_shipment_selected.link_title}</h2>

                                <table width="100%" cellPadding="3">
                                    <tbody>
                                        <tr>
                                            <td valign="top" width="150"><label className="grey">Tanggal Pengiriman</label></td>
                                            <td valign="top"><p className="mb0">{this.state.complain_shipment_selected.created_at}</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Metode Pengiriman</label></td>
                                            <td valign="top"><p className="mb0">{this.state.complain_shipment_selected.shipment}</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Nomor Resi</label></td>
                                            <td valign="top"><p className="mb0">{this.state.complain_shipment_selected.no_resi}</p></td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><label className="grey">Alamat</label></td>
                                            {
                                                this.state.complain_shipment_selected.link_title === "Pengembalian" ?
                                                    <td valign="top"><p className="mb0">{this.state.shop.name}<br /><span className="grey">{this.state.shop.telephone}</span><br /><span className="grey">{this.state.shop_alamat.address}</span><br /><span className="grey">{this.state.shop_alamat.district}, {this.state.shop_alamat.city} {this.state.shop_alamat.zip_portal}</span></p></td>
                                                    :
                                                    <td valign="top">
                                                        <p className="bold mb0">{this.state.alamat.label}</p>
                                                        <p className="grey mb0">{this.state.alamat.penerima}</p>
                                                        <p className="grey mb0">
                                                            <NumberFormat
                                                                value={this.state.alamat.phone}
                                                                displayType={'text'}
                                                                format="#### #### #### #"
                                                            />
                                                            {/* {this.state.alamat.phone} */}
                                                        </p>
                                                        <p className="grey mb0">{this.state.alamat.alamat_lengkap} {this.state.alamat.kode_pos}<br />{this.state.alamat.kec} {this.state.alamat.kab}</p>
                                                    </td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalTolakKomplain" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>TOLAK KOMPLAIN</h2>

                                    <div className="form-group-div">
                                        <label>Alasan Menolak Komplain <span className="red">*</span></label>
                                        <textarea className="form-control" placeholder="Tulis alasan anda menolak komplain" required onChange={this.onChangeAlasanTolak} value={this.state.tolak_reason}></textarea>
                                    </div>

                                    <div className="notification-box sm">
                                        <div className="info">
                                            <i className="fas fa-info-circle"></i>
                                            <p>Tim Agrinis akan menghubungi anda untuk konfirmasi penolakan.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                {
                                        this.state.loadingButton ?  <Loading /> 
                                        : 
                                        <>
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onClickTolakComplain}>Simpan</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalInfoKurir" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>INFORMASI STATUS PENGIRIMAN</h2>
                                <label className="grey">Apa kata kurir?</label>
                                <p>{this.state.complain ? this.state.complain.status.slice(-1)[0].courier_answer ? this.state.complain.status.slice(-1)[0].courier_answer : null : null}</p>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalFormInfoKurir" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>INFORMASI STATUS PENGIRIMAN</h2>

                                    <div className="form-group-div">
                                        <label>Apa kata kurir? <span className="red">*</span></label>
                                        <textarea className="form-control" placeholder="Tulis informasi status pengiriman" onChange={this.onChangeInfoKurir} value={this.state.info_kurir}></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingButton ?  <Loading /> 
                                        : 
                                        <>
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.onClickComplainKurir}>Simpan</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade modal-2" id="modalTolakPesanan" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>ALASAN PENOLAKAN PENJUAL</h2>

                                    <div className="form-group-div">
                                        <label>Alasan Penolakan Penjual <span className="red">*</span></label>
                                        <textarea className="form-control" placeholder="Tulis alasan penolakan penjual" onChange={this.onChangeDitolakReason} value={this.state.input_ditolak_reason}></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingModalTolakPesanan ?  <Loading /> 
                                        : 
                                        <>
                                            <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</a>
                                            <a href="/#" className="btn btn-solid primary btn-sm" onClick={this.tolakPesanan}>Simpan</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>                
                <div className="modal fade modal-2" id="modalPermintaanBarang" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>Cetak PDF Permintaan Barang</h2>

                                <table width="100%" cellPadding="3">
                                    <tbody>
                                        {
                                            this.state.list_produsen.map((data, i) => (
                                                <tr key={i}>
                                                    <td valign="top"><label className="grey">{ data.deskripsi }</label></td>
                                                    <td valign="top"><a href="/#" className="btn btn-solid primary btn-sm btn-block" onClick={(e) => this.onClickUnduhPermintaanBarang(e, data.link, data.nama)}>Unduh</a></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.user.user,
        notifMessage: state.notification.message
    }
}

export default NetworkDetector(connect(mapStateToProps, {
    toggleModalLogin
})(TransactionSellerDetail));