import { 
	GET_STATUS_CATEGORY, 
	GET_STATUS_PRODUCT, 
	GET_STATUS_SIDE_CATEGORY, 
	GET_STATUS_SHIPMENT, 
	ERROR_PRODUCT,
	LOADING_FETCH_SHOP,
	LOADING_FETCH_ADDRES_SHOP,
	LOADING_FETCH_SUBSCRIBE_SHOP
} from "../actions/types/loadingType";

export default function (state = {loadingProduct : true}, action) {
	switch(action.type){
		case GET_STATUS_CATEGORY:
			return { ...state,loadingCategory:action.payload }
		case GET_STATUS_PRODUCT:
			return { ...state,loadingProduct:action.payload }
		case ERROR_PRODUCT:
			return { ...state,errorProduct:action.payload }
		case GET_STATUS_SIDE_CATEGORY:
			return { ...state,loadingCategoryWithSub:action.payload }
		case GET_STATUS_SHIPMENT:
			return { ...state,loadingShipment:action.payload }
		case LOADING_FETCH_SHOP:
			return { ...state,fetchMyShop:action.payload }
		case LOADING_FETCH_ADDRES_SHOP:
			return { ...state,loadingAddressShop:action.payload }
		case LOADING_FETCH_SUBSCRIBE_SHOP:
			return { ...state, loadingSubscribeShop: action.payload }
		default:
			return state;
	}
}