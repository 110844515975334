import React from "react";
import _ from 'lodash';
import { Link } from 'react-router-dom'
import i18n from '../../../i18n';
import VipBadge from '../../../assert/icon/vip-badge.svg'
const Banner = (shop) => {
    let cities = "";
    _.forEach(shop.addresses.data, (d, i) => {
        let koma = i === (shop.addresses.data.length - 1) ? "" : ", ";
        cities += `${d.city}${koma}`;
    })
    return (
    <div className="header-content">
        <div className="header-logo">
            {
                shop.photo
                ? <>
                    {
                        shop.is_vip
                        ? <img src={VipBadge} alt="" className="inner-image"/>
                        : null
                    }
                    <img src={shop.photo} alt="Banner Header"/></>
                : null
            }    
        </div>
        <div className="header-storename">
            <h1>{shop.name}</h1>
            <p className="mb5"><i className="fas fa-map-marker-alt"></i>&nbsp;{cities}</p>
        </div>
        <div className="pull-right dropdown">
            <a href="/" className="dropdown-toggle btn btn-solid grey mt20" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-cog"></i>{i18n.t("ShopBanner:pengaturan")}
            </a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/myshop/profile">{i18n.t("ShopBanner:profil")}</Link>
                <Link className="dropdown-item" to="/myshop/statistic">{i18n.t("ShopBanner:statistik")}</Link>
                {
                    shop.is_vip
                    ? <Link className="dropdown-item" to="/myshop/vip/list">{i18n.t("ShopBanner:pembeli_spesial")}</Link>
                    : <Link className="dropdown-item" to="/myshop/vip/registrasi">{i18n.t("ShopBanner:vip")}</Link>
                }
                
                {/* <Link className="dropdown-item" to="/">Diskon Toko</Link> */}
            </div>
        </div>
    </div>
)
}

export default Banner;