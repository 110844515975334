import React from 'react';
// import image from '../../assert/files/loading/cart.gif';
import ReactLoading from 'react-loading';


const LoadingPage = () => (
    // <div className="pageloading-container">
        <div className="pageloading2">
            {/* <img src={image} alt="loading"/> */}
            <div className="loading-btn mb20">
                <ReactLoading type="bubbles" color="#0C7F8F"/>
            </div>
            <span>Sedang memuat, mohon tunggu.</span>
        </div>
    // </div>
)

export default LoadingPage;