import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from "react-i18next";

i18n
  .use(Backend)
  .use(reactI18nextModule)
  .init({
    lng: 'id',
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'id',
    debug: false,
    ns: [
        'Header',
        'Footer',
        'Home',
        'Category',
        'DetailProduct_temp',
        'DetailProductComponent',
        'SingleProductReview',
        'SingleProductDiscussionV2',
        'Filter',
        'Profile',
        'ShippingAddress',
        'TambahAlamat',
        'Message',
        'Search',
        'Favourite',
        'Subscribes',
        'FilterShop',
        'Product',
        'Wallet',
        'Myshop',
        'ShopBanner',
        'ProfileShop',
        'Statistic'
    ],
    defaultNS: 'Header',
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n;