import React from 'react';
import { FormatUang } from './../../helper';
import _ from 'lodash';
import {
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import i18n from '../../i18n';

const Wilayah = props => {
    const COLORS = ['#4BC0C0', '#bd9fff', '#FF6384', '#36A2EB', '#FF9F40'];
    let data1 = props.data.length > 5 ? _.slice(props.data, 0, 5) : props.data;
    let data2 = props.data.length > 5 ? _.slice(props.data, 5, props.data.length) : props.data;
    return (
        <div className="statistic-body" id="statistic-3">
            <div className="row mb15">
                <div className="col-md-5">
                    <span className="title-graphic">{i18n.t("Statistic:wilayah.title")}</span>
                    <p>{i18n.t("Statistic:wilayah.desc")}</p>
                    <div style={{ width: '100%' }}>
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie cx="50%" cy="50%" data={props.data_pie} outerRadius={150} fill="#8884d8" labelLine={false}>
                                    {
                                        props.data_pie.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                        ))
                                    }
                                </Pie>
                                <Tooltip formatter={(value) => value + ' %'} />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-md-7">
                    <br /><br /><br /><br />
                    <div className="stat-table-container noborder">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{i18n.t("Statistic:wilayah.tabel.wilayah")}</th>
                                    <th className="center">{i18n.t("Statistic:wilayah.tabel.persentase")}</th>
                                    <th className="center">{i18n.t("Statistic:wilayah.tabel.jumlah")}</th>
                                    <th className="center">{i18n.t("Statistic:wilayah.tabel.pendapatan")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    data1.map((data, i) => (
                                        <tr>
                                            <td><div className="stat-legend-box" style={{ background: COLORS[i] }}></div></td>
                                            {
                                                i === 0 ?
                                                    <React.Fragment>
                                                        <td><span className="fs25">{data.label}</span></td>
                                                        <td className="right"><span className="fs25">{data.count_percent}</span>%</td>
                                                        <td className="right"><span className="fs25">{data.total_unit}</span> unit</td>
                                                        <td className="right pl20"><span className="pull-left">Rp</span> <span className="fs25"><FormatUang value={data.total_pendapatan} /></span></td>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <td>{data.label}</td>
                                                        <td className="right">{data.count_percent}%</td>
                                                        <td className="right">{data.total_unit} unit</td>
                                                        <td className="right pl20"><span className="pull-left">Rp</span> <FormatUang value={data.total_pendapatan} /></td>
                                                    </React.Fragment>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                props.data.length > 5 ?
                    <React.Fragment>
                        <a href="/#" style={{ display: props.show_table ? 'none' : '' }} data-id="open" onClick={props.onClickShowTable}>{i18n.t("Statistic:wilayah.show_table")}</a>
                        <a href="/#" style={{ display: !props.show_table ? 'none' : '' }} data-id="close" onClick={props.onClickShowTable}>{i18n.t("Statistic:wilayah.hide_table")}</a>
                    </React.Fragment>
                    :
                    ''
            }

            <div className="row mb15 mt15" style={{ display: !props.show_table ? 'none' : '' }}>
                <div className="col-md-7">
                    <div className="stat-table-container">
                        <span className="title-graphic">{i18n.t("Statistic:wilayah.tabel.persebaran")}</span>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{i18n.t("Statistic:wilayah.tabel.wilayah")}</th>
                                    <th>{i18n.t("Statistic:wilayah.tabel.persentase")}</th>
                                    <th>{i18n.t("Statistic:wilayah.tabel.jumlah")}</th>
                                    <th>{i18n.t("Statistic:wilayah.tabel.pendapatan")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data2.map(data => (
                                        <tr>
                                            <td>{data.label}</td>
                                            <td>{data.count_percent}%</td>
                                            <td>{data.total_unit} unit</td>
                                            <td><span className="pull-left">Rp</span> <FormatUang value={data.total_pendapatan} /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Wilayah