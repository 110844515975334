import React from 'react'
import * as moment from "moment";
import 'moment/locale/id';
moment.locale('id');

const StepTransaction = (props) => (
    <div className="text-center">
        <ul className="transaction-step">
            {
                props.prosess.map(proses => (
                    <li className={proses.active ? `active ${proses.cancel ? "cancel" : ""}` : ""} key={proses.id}>
                        <div className="round-icon">
                            <img src={proses.image} alt="Transaksi"/>
                        </div>
                        <div className="step-desc">
                            <span className="block semibold">{proses.name}</span>
                            <span className="grey fs11">{proses.active ? moment(proses.active).format("DD MMM YYYY HH:mm") : null}</span>
                        </div>
                    </li>
                ))
            }
        </ul>
    </div>
)

export default StepTransaction