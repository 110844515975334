import React from 'react';

const SpesifikasiProduct = (props) => {
    return (
        <tr>
            <td align="center">
                {props.master_spesifikasi.label}
            </td>
            <td align="center">
                {props.label}
            </td>    
            <td align="center">
                {
                    <span className="btn-remove red" onClick={props.onClick}>
                        <i className="fas fa-times"></i>
                    </span>
                }
            </td>
        </tr>
    )
}

export default SpesifikasiProduct
