import React from 'react'

import ReactLoading from 'react-loading'

const LoadingInfiniteScroll = () => 
(
    <div className="loading-btn mb20">
        <ReactLoading 
            type="spinningBubbles" 
            color="#0C7F8F" 
            height={"50px"} 
            width={"50px"} />
    </div>
)

export default LoadingInfiniteScroll