import React from 'react';

export const Images = props => (
    <div className="image-grid-transaction">
        {
            <div className="pull-right more-menu">
                <a href="/#" className="dropdown-toggle no-toggle" onClick={props.removeImage}><i className="fas fa-times fs16 red"></i></a>
            </div>
        }
        
        <img src={props.image_thumb} alt="product"/>
    </div>
)