import React from 'react';
import ContentLoader from "react-content-loader"

export const LoadingCategory = () => (
  <ContentLoader 
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="10" y="4" rx="0" ry="0" width="100%" />
  </ContentLoader>
)

export const LoadingProduct = () => (
  <ContentLoader 
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    height={600}
  >
    <rect x="10" y="4" rx="0" ry="0" width="100%" />
  </ContentLoader>
)

export const LodingSideCategory = () => (
  <ContentLoader 
    height={400}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
    <rect x="25" y="30" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="60" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="90" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="120" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="150" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="180" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="210" rx="3" ry="3" width="200" height="10" />
    <rect x="25" y="240" rx="3" ry="3" width="200" height="10" />
    <rect x="0" y="270" rx="3" ry="3" width="250" height="10" />
    <rect x="0" y="300" rx="3" ry="3" width="250" height="10" />
    <rect x="0" y="330" rx="3" ry="3" width="250" height="10" />
    <rect x="0" y="360" rx="3" ry="3" width="250" height="10" />
  </ContentLoader>
)

export const LodingThumnailDetailProduct = () => (
  <ContentLoader 
    height={100}
    width={100}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="100" height="100" />
  </ContentLoader>
)

export const LodingImageDetailProduct = () => (
  <ContentLoader 
    height={100}
    width={100}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="100" height="100" />
  </ContentLoader>
)

export const LoadingDescProduct = () => (
  <ContentLoader 
    height={300}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <circle cx="20" cy="20" r="20" /> 
    <rect x="50" y="10" rx="5" ry="5" width="300" height="20" /> 
    <circle cx="20" cy="80" r="20" /> 
    <rect x="50" y="70" rx="5" ry="5" width="220" height="20" /> 
    <circle cx="20" cy="140" r="20" /> 
    <rect x="50" y="130" rx="5" ry="5" width="220" height="20" /> 
    <rect x="0" y="180" rx="5" ry="5" width="270" height="20" /> 
    <rect x="0" y="230" rx="5" ry="5" width="270" height="20" /> 
    <rect x="0" y="280" rx="5" ry="5" width="270" height="20" /> 
  </ContentLoader>
)

export const LoadingSellerDetail = () => (
  <ContentLoader 
    height={400}
    width={400}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="20" y="80" rx="3" ry="3" width="280" height="6" /> 
    <rect x="20" y="100" rx="3" ry="3" width="280" height="6" /> 
    <rect x="20" y="120" rx="3" ry="3" width="280" height="6" /> 
    <rect x="60" y="150" rx="3" ry="3" width="200" height="30" /> 
    <rect x="60" y="200" rx="3" ry="3" width="200" height="30" /> 
    <rect x="60" y="250" rx="3" ry="3" width="200" height="30" /> 
    <circle cx="160" cy="30" r="30" />
  </ContentLoader>
)

export const LoadingMyShopHeader = () => (
  <div style={{width:"250px", float: "left"}}>
      <ContentLoader 
          height={200}
          width={500}
          speed={2}
          primaryColor="#ffffff"
          secondaryColor="#ecebeb"
      >
          <circle cx="80" cy="80" r="80" /> 
          <rect x="178" y="23" rx="4" ry="4" width="311" height="25" /> 
          <rect x="185" y="72" rx="4" ry="4" width="198" height="23" /> 
          <rect x="515" y="-242" rx="5" ry="5" width="400" height="400" /> 
          <rect x="183" y="114" rx="4" ry="4" width="213" height="23" />
      </ContentLoader>
  </div>
)