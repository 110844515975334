import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Rating from 'react-rating';
import ReactLoading from 'react-loading';
import * as moment from "moment";
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import "react-datepicker/dist/react-datepicker.css";

import {
    NewHeader,
} from '../../../components';
import {
    authGet,
    authPost,
    authPostPaginate,
    AlertWarning,
    AlertInfo
} from './../../../helper';
import {
    getMyShop,
} from "../../../actions/shopActions";
import {
    setNotification
} from '../../../actions/notificationAction';

import {
    toggleModalLogin
} from '../../../actions/registerActions';
import { LoadingMyShopHeader } from '../../../components/Loading/Loading';
import Banner from "../../../components/Shop/_Component/Banner";
import { fetchCategories } from '../../../actions/categoryActions';

import {
    Filter,
    Pendapatan,
    PendapatanPerBarang,
    Transaksi,
    Wilayah,
    BarangPopuler
} from '../../../components/Statistic'

import StatisticLoading from '../../../components/Loading/StatisticLoading';
import i18n from '../../../i18n';
import NetworkDetector from '../../PUBLIC/NetworkDetector';
const LoadingInfiniteScroll = () => 
(
    <div className="loading-statistic">
        <ReactLoading 
            type="spinningBubbles" 
            color="#0C7F8F" 
            height={"100px"} 
            width={"50px"} />
    </div>
)
class Statistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loadingPage: true,
        loadingMenu: false,
        LoadingShowBy: false,
        loadingMoreData: false,
        from: new Date(moment().startOf('year').format('YYYY-MM-DD')),
        to: new Date(moment().endOf('year').format('YYYY-MM-DD')),
        data: [],
        data_pie: [],
        sort_by_active: 1,
        show_table: false,
        menu_active: 1,
        show_active: 'bulanan',
        temp_show_active: 'bulanan',
        rating: 0.0,
        langganan: 0,
        pagination: {},
        grid: true,
        categories: [],
        products: [],
        category_selected: 0,
        product_selected: 0,
        products_msg: ''
    }
}
    componentDidMount() {
        this.props.fetchCategories()
        .then(() => {
            this.setState({ categories: this.props.categories });
        })
        this.props.getMyShop(this.props.userData.token);
        this.loadSidebarData();
        this.loadPendapatan();
    }

    loadSidebarData = () => {
        authGet('statistic/sidebardata', this.props.userData.token)
            .then(res => {
                this.setState({
                    rating: res.data.rating,
                    langganan: res.data.langganan
                });
            })
            .catch(err => {
                this.setState({ loadingMenu: false,  LoadingShowBy: false, show_active: this.state.temp_show_active }, () => {
                    AlertInfo.fire({ text: err.response.data.message });

                });
            })
    }

    loadTransaksi() {
        let data = {
            from: this.state.from,
            to: this.state.to,
            sort_by: this.state.show_active,
            lang: this.props.lng
        }
        authPost(data, 'statistic/transaksi', this.props.userData.token)
            .then(res => {    
                this.setState({
                    data: res.data,
                    LoadingShowBy: false,
                    loadingMenu: false,
                });
            })
            .catch(err => {
                this.setState({ loadingMenu: false,  LoadingShowBy: false, show_active: this.state.temp_show_active }, () => {
                    AlertInfo.fire({ text: err.response.data.message });

                });
            })
    }

    loadWilayah() {
        let data = {
            from: this.state.from,
            to: this.state.to,
            sort_by: this.state.show_active,
            grid: this.state.grid ? "grid" : "list",
            lang: this.props.lng
        }
        authPost(data, 'statistic/get_wilayah', this.props.userData.token)
            .then(res => {
                let test = _(res.data)
                    .groupBy('province')
                    .map((platform, id) => ({
                        label: id,
                        count: platform.length,
                        count_percent: parseInt(_.sumBy(platform, 'detail_count') / _.sumBy(res.data, 'detail_count') * 100),
                        total_unit: _.sumBy(platform, 'detail_count'),
                        total_pendapatan: _.sumBy(platform, 'detail_harga')
                    }))
                    .value()   
                let test2 = _(res.data)
                    .groupBy('province')
                    .map((platform, id) => ({
                        name: id,
                        value: parseInt(_.sumBy(platform, 'detail_count') / _.sumBy(res.data, 'detail_count') * 100),
                        count_percent: parseInt(_.sumBy(platform, 'detail_count') / _.sumBy(res.data, 'detail_count') * 100),
                    }))
                    .value()
                this.setState({
                    data_pie: test2.length > 5 ? _.slice(_.orderBy(test2, ['value'], ['desc']), 0, 5) : _.orderBy(test2, ['value'], ['desc']),
                    data: _.orderBy(test, ['total_unit'], ['desc']),
                    LoadingShowBy: false,
                    loadingMenu: false,
                });
            })
            .catch(err => {
                this.setState({ loadingMenu: false,  LoadingShowBy: false, show_active: this.state.temp_show_active }, () => {
                    AlertInfo.fire({ text: err.response.data.message });

                });
            })
    }

    loadPendapatan() {
        let data = {
            from: this.state.from,
            to: this.state.to,
            sort_by: this.state.show_active,
            lang: this.props.lng
        }
        authPost(data, 'statistic/get_pendapatan', this.props.userData.token)
            .then(res => {
                this.setState({
                    data: res.data,
                    LoadingShowBy: false,
                    loadingMenu: false,
                    loadingPage: false,
                });
            })
            .catch(err => {
                // console.log(err.response);
                
                this.setState({ loadingMenu: false,  LoadingShowBy: false, show_active: this.state.temp_show_active }, () => {
                    console.log(err.response);
                    
                    AlertInfo.fire({ text: err.response.data.message });

                });
            })
    }

    loadPendapatanPerBarang() {
        if (this.state.product_selected !== 0) {
            let data = {
                from: this.state.from,
                to: this.state.to,
                sort_by: this.state.show_active,
                product_id: this.state.product_selected,
                lang: this.props.lng
            }
        authPost(data, 'statistic/get_pendapatan_per_barang', this.props.userData.token)
            .then(res => {
                this.setState({
                    data: res.data,
                    LoadingShowBy: false,
                    loadingMenu: false,
                    loadingPage: false,
                });
            })
            .catch(err => {
                this.setState({ loadingMenu: false,  LoadingShowBy: false, show_active: this.state.temp_show_active }, () => {
                    AlertInfo.fire({ text: err.response.data.message });

                });
            })
        } else {
            this.setState({
                data: [],
                LoadingShowBy: false,
                loadingMenu: false,
                loadingPage: false,
            });
        }
    }

    loadBarangPopuler() {
        let data = {
            from: this.state.from,
            to: this.state.to,
            sort_type: this.state.sort_by_active,
            lang: this.props.lng
        }
        this.setState({loadingMenu: true})
        authPost(data, 'statistic/get_barang_populer', this.props.userData.token)
            .then(res => {
                // console.log(res.data.data);
                
                let result = [];
                switch (this.state.sort_by_active) {
                    case 1:
                        result =  _.orderBy(res.data.data, ['terjual'], ['desc']);
                        break;
                    case 2:
                        result =  _.orderBy(res.data.data, ['viewer'], ['desc']);
                        break;
                    case 3:
                        result = _.orderBy(res.data.data, ['like'], ['desc']);
                        break;
                    case 4:
                        result = _.orderBy(res.data.data, ['rating'], ['desc']);
                        break;
                    default:
                        result = res.data.data
                        break;
                }
                this.setState({
                    data: result,
                    pagination: res.data.meta.pagination,
                    loadingMenu: false,
                });
            })
            .catch(err => {
                this.setState({ loadingMenu: false,  LoadingShowBy: false, show_active: this.state.temp_show_active }, () => {
                    AlertInfo.fire({ text: err.response.data.message });

                });
            })
    }

    onChangeKategori = (e) => {
        this.setState({
            category_selected: parseInt(e.target.value), product_selected: 0,
            products: []
        }, () => {
            let data = {
                category_id: this.state.category_selected
            }
            authPost(data, 'product/product_by_category', this.props.userData.token)
            .then(res => {
                if (res.data.data.length > 0) { 
                    this.setState({products_msg: '', products: res.data.data});
                } else {
                    this.setState({
                        products_msg: i18n.t("Statistic:tidak_ada_barang"),
                        products: []
                    });
                }
                
            })
        })
    }

    onChangeProduct = (e) => {
        this.setState({
            product_selected: parseInt(e.target.value)
        })
    }

    onChangeMenuPendapatan = (e) => {
        e.preventDefault();
        this.setState({
            menu_active: parseInt(e.currentTarget.dataset.id),
            data: [],
            data_pie: [],
            from: new Date(moment().startOf('year').format('YYYY-MM-DD')),
            to: new Date(moment().endOf('year').format('YYYY-MM-DD')),
            show_active: "bulanan",
            loadingMenu: true,
            products: [],
            product_selected: 0,
            show_table: false,
        }, () => {
            if (this.state.menu_active === 1) {
                this.loadPendapatan();
            } else if (this.state.menu_active === 5) {
                this.loadPendapatanPerBarang();
            }
            this.renderMenu(this.state.menu_active);
        });
    }

    onChangeMenu = (e) => {
        e.preventDefault();
        this.setState({
            menu_active: parseInt(e.currentTarget.dataset.id),
            data: [],
            data_pie: [],
            from: new Date(moment().startOf('year').format('YYYY-MM-DD')),
            to: new Date(moment().endOf('year').format('YYYY-MM-DD')),
            show_active: "bulanan",
            loadingMenu: true,
            show_table: false,
        }, () => {
            if (this.state.menu_active === 1) {
                this.loadPendapatan();
            } else if (this.state.menu_active === 2) {
                this.loadTransaksi();
            } else if (this.state.menu_active === 3) {
                this.loadWilayah();
            } else if (this.state.menu_active === 4) {
                this.loadBarangPopuler();
            } else if (this.state.menu_active === 5) {
                this.loadPendapatanPerBarang();
            }
            this.renderMenu(this.state.menu_active);
        });
    }

    onChangeFrom = (tgl) => {
        if (this.state.to < tgl) {
            AlertWarning.fire({
                text: i18n.t("Statistic:validation.tanggal")
            })
            return false;
        }
        this.setState({
            from: tgl
        });
    }

    onChangeTo = (tgl) => {
        if (this.state.from > tgl) {
            AlertWarning.fire({
                text: i18n.t("Statistic:validation.tanggal2")
            })
            return false;
        }
        this.setState({
            to: tgl
        });
    }

    onChangeShow = (e) => {
        this.setState({
            temp_show_active: this.state.show_active,
            show_active: e.currentTarget.value,
            LoadingShowBy: true,
            loadingMenu: true,
        }, () => {
            switch (this.state.menu_active) {
                case 1:
                    this.loadPendapatan();
                    break;
                case 2:
                    this.loadTransaksi();
                    break;
                case 3:
                    this.loadWilayah();
                    break;
                case 4:
                    this.loadBarangPopuler();
                    break;
                case 5:
                    this.loadPendapatanPerBarang();
                    break;
                default:
                    break;
            }
        });
    }

    onClickTampilkan = (e) => {
        e.preventDefault();
        // let from = moment(this.state.from);
        // let to = moment(this.state.to);
        // let show_active = "bulanan";
        // if (to.diff(from, 'days') <= 30) {
        //     show_active = "harian";
        // } else if (to.diff(from, 'months') >= 3 && to.diff(from, 'months') <= 11) {
        //     show_active = "mingguan";
        // } else if (to.diff(from, 'months') >= 12) {
        //     show_active = "bulanan";
        // }
        this.setState({ 
            LoadingShowBy: true,
            temp_show_active: this.state.show_active,
            loadingMenu: true,
            // show_active: show_active
        })
        switch (this.state.menu_active) {
            case 1:
                this.loadPendapatan();
                break;
            case 2:
                this.loadTransaksi();
                break;
            case 3:
                this.loadWilayah();
                break;
            case 4:
                this.loadBarangPopuler();
                break;
            case 5:
                this.loadPendapatanPerBarang();
                break;
            default:
            break;
        }
    }

    onChangeGridView = (e) => {
        e.preventDefault()
        this.setState({
            grid: e.currentTarget.id === "grid" ? true : false,
        })
    }

    onChangeSortBy = (e) => {
        e.preventDefault();
        this.setState({ sort_by_active: parseInt(e.currentTarget.dataset.id) }, () => {
            this.loadBarangPopuler();
        })
    }

    onClickShowTable = (e) => {
        e.preventDefault();
        this.setState({
            show_table: (e.currentTarget.dataset.id === "open" ? true : false)
        });
    }

    fetchMoreData = () => {
        if (this.state.pagination.links.next) { 
            let { next } = this.state.pagination.links;
            let data = {
                from: this.state.from,
                to: this.state.to,
                sort_type: this.state.sort_by_active
            }
            this.setState({ loadingMoreData: true });
            authPostPaginate(data, next, this.props.userData.token)
            .then(res=> {
                this.setState({
                    data: [...this.state.data, ...res.data.data],
                    pagination: res.data.meta.pagination,
                    loadingMoreData: false,
                }, () => {
                    
                });
            })
        }
    }
    
    renderMenu(param) {
        switch(param) {
            case 1:
                return <Pendapatan 
                    {...this.state}
                    onClickShowTable={this.onClickShowTable}
                    onChangeShow={this.onChangeShow}
                />
            case 2:
                return <Transaksi
                    {...this.state}
                    onClickShowTable={this.onClickShowTable}
                    onChangeShow={this.onChangeShow}
                />
            case 3:
                return <Wilayah 
                    {...this.state}
                    onClickShowTable={this.onClickShowTable}
                    onChangeShow={this.onChangeShow}
                />
            case 4:
                return <BarangPopuler
                    {...this.state}
                    onClickShowTable={this.onClickShowTable}
                    hasMoreData={this.hasMoreData}
                    fetchMoreData={this.fetchMoreData}
                /> 
            case 5:
                return <PendapatanPerBarang
                    {...this.state}
                    onClickShowTable={this.onClickShowTable}
                    onChangeShow={this.onChangeShow}
                />    
            default:
            break;                                                           
        }
    }
    hasMoreData = () => !(this.state.data.length === (this.state.pagination ? this.state.pagination.total : -1));
    render() {
        let Page = (
        <div>
            <div className="container-fluid">
	            <div className="header-banner-3 header-store">
                    {
                        !this.props.loadingHeader
                        ? <Banner {...this.props.shop}/>
                        : <LoadingMyShopHeader/>
                    }
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="statistic-container">
                    <div className="statistic-left">
                        <div className="stat-rating">
                            <label>{i18n.t("Statistic:rating")}</label>
                            <span className="stat-rating-value">{this.state.rating.toString().replace(/[.]/, ",")}</span>
                            <div className="clearfix mb20"></div>
                            <div className="text-right">
                                <div className="rating-big">
                                <Rating
                                    emptySymbol="fas fa-star"
                                    fullSymbol="fas fa-star starred"
                                    initialRating={this.state.rating}
                                    readonly
                                />
                                </div>
                            </div>
                        </div>
                        <div className="stat-subscribe">
                            <label><i className="fas fa-bookmark"></i></label>
                            <span className="stat-subscribe-value">{this.state.langganan}</span>
                            <div className="text-right">
                                <span className="fs17">{i18n.t("Statistic:langganan")}</span>
                            </div>
                        </div>

                        <div className="statistic-submenu">
                            <div className={this.state.menu_active === 1 || this.state.menu_active === 5 ? "statistic-submenu-option active" : "statistic-submenu-option"} data-id={1} onClick={this.onChangeMenu}>{i18n.t("Statistic:tab.pendapatan")}</div>
                            <div className={this.state.menu_active === 2 ? "statistic-submenu-option active" : "statistic-submenu-option"} data-id={2} onClick={this.onChangeMenu}>{i18n.t("Statistic:tab.transaksi")}</div>
                            <div className={this.state.menu_active === 3 ? "statistic-submenu-option active" : "statistic-submenu-option"} data-id={3} onClick={this.onChangeMenu}>{i18n.t("Statistic:tab.wilayah")}</div>
                            <div className={this.state.menu_active === 4 ? "statistic-submenu-option active" : "statistic-submenu-option"} data-id={4} onClick={this.onChangeMenu}>{i18n.t("Statistic:tab.populer")}</div>
                        </div>
                    </div>

                    <div className="statistic-main">
                    <Filter 
                    {...this.state}
                    menu_active={this.state.menu_active}
                    onChangeSortBy={this.onChangeSortBy} 
                    onChangeFrom={this.onChangeFrom}
                    onChangeTo={this.onChangeTo}
                    onClickTampilkan={this.onClickTampilkan}
                    onChangeKategori={this.onChangeKategori}
                    onChangeProduct={this.onChangeProduct}
                    onChangeMenuPendapatan={this.onChangeMenuPendapatan}
                    onChangeGridView={this.onChangeGridView}

                    />
                    { 
                        this.state.loadingMenu ? <LoadingInfiniteScroll /> : this.renderMenu(this.state.menu_active)
                    }
                    </div>
                </div>
            </div>
        </div>
                        
        )
        return(
            <div className="mg-top">
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    <StatisticLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        userData: state.user.user,
        shop: state.shop.item,
        notifMessage: state.notification.message,
        categories: state.categories.items,
    }
}
export default NetworkDetector(withNamespaces("Statistic")(connect(mapStateToProps, {
    getMyShop,
    setNotification,
    toggleModalLogin,
    fetchCategories
})(Statistic)));