import React from 'react';

export const Images = props => (
    <div className={`image-grid-shop ${props.status === 1 ? 'active' : ''}`}>
        {
            props.status === 1
            ? null
            : <div className="pull-right more-menu">
                <a href="/#" className="dropdown-toggle no-toggle" role="button" data-toggle="dropdown"><i className="fas fa-ellipsis-h fs16 grey"></i></a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-sm">
                    <a 
                        className="dropdown-item red" 
                        href="/"
                        onClick={props.removeImage}>Hapus</a>
                    <a 
                    className="dropdown-item" 
                    href="/"
                    onClick={props.clickImageActive}>Set Gambar Utama</a>
                </div>
            </div>
        }
        
        <img src={props.image_thumb} alt="product"/>
        {
            props.status === 1
            ? <span className="label">Gambar Utama</span>
            : null
        }
    </div>
)