import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import categoryReducer from './categoryReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import shopReducer from './shopReducer';
import shipmentReducer from "./shipmentReducer";
import registerReducer from "./registerReducer";
import discussionReducer from "./discussionReducer";
import userReducer from "./userReducer";
import loadingReducer from "./loadingReducer";
import transactionReducer from "./transactionReducer";
import headerCategoryReducer from "./headerCategoryReducer";
import notificationReducer from "./notificationReducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['carts' ,'user', 'transaction']
  };

  const rootReducer = combineReducers({
    headerCategory: headerCategoryReducer,
    notification: notificationReducer,
    categories: categoryReducer,
    products: productReducer,
    carts: cartReducer,
    shop: shopReducer,
    shipment: shipmentReducer,
    register: registerReducer,
    discussion: discussionReducer,
    loading: loadingReducer,
    user : userReducer,
    transaction : transactionReducer
});

export default persistReducer(persistConfig, rootReducer);