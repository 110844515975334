import React from 'react'

const EmptyResult = props => {
    return (
        <div className="empty-result">
            <img src={props.image} alt="empty result"/>
            {
                props.title 
                ? <center><p className="bold"><b>{props.title}</b></p></center>
                : null
            }
            <p>{props.message}</p>
            {
                props.buttonText === 0
                ? null
                : <button 
                    className="btn btn-solid primary" 
                    onClick={props.buttonAction}>
                    {props.buttonText}
                </button>
            }
        </div>
    )
}

export default EmptyResult
