
import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store';
// import i18n (needs to be bundled ;)) 
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <I18nextProvider i18n={ i18n }>
                <App/>
            </I18nextProvider>
        </PersistGate>
    </Provider>
, document.getElementById('root'));

// serviceWorker.unregister();

