import React from 'react';
import ContentLoader from "react-content-loader"

const ViewMyProductLoading = () => (
<div className="container mg-top">
    <div className="detail-product-container">

        <div className="product-side">
            <div className="image-product">
            <div className="image-thumbnail">
            {
            /* Image thumb */
            }
            <ContentLoader 
                    height={500}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="0" y="0" rx="3" ry="3" width="500" height="500"/>
                </ContentLoader>
                </div>
                <div className="image-slide" style={{zIndex: 9999}}>
                {
                    /* Image */
                }                    
                <ContentLoader 
                    height={400}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="15" y="0" rx="3" ry="3" width="400" height="400"/>
                </ContentLoader>
                {
                    /* Like */
                }   
                <ContentLoader 
                    height={50}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="90" y="10" rx="3" ry="3" width="100" height="20"/>
                    <rect x="200" y="10" rx="3" ry="3" width="5" height="20"/>
                    <rect x="215" y="10" rx="3" ry="3" width="100" height="20"/>
                </ContentLoader>
                </div>
            </div>
            <div className="detail-product">
                {
                /* Nama Produk */
                }                   
            <ContentLoader 
                height={225}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="3" ry="3" width="500" height="20"/>
                <rect x="0" y="40" rx="3" ry="3" width="500" height="20"/>
                <rect x="0" y="80" rx="3" ry="3" width="500" height="20"/>
                <rect x="0" y="150" rx="3" ry="3" width="500" height="20"/>
                <rect x="0" y="195" rx="3" ry="3" width="500" height="20"/>
            </ContentLoader>
            
        </div>
            <div className="clearfix"></div>
            <div className="desc-product">
            {
                /**Tab */
            }
            <ContentLoader 
                height={30}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="20" rx="3" ry="3" width="45" height="10"/>
                <rect x="55" y="20" rx="3" ry="3" width="45" height="10"/>
                <rect x="105" y="20" rx="3" ry="3" width="45" height="10"/>
                <rect x="160" y="20" rx="3" ry="3" width="45" height="10"/>
            </ContentLoader>
            {
                /**Desciption */
            }
            <ContentLoader 
                height={50}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
            >
                <rect x="0" y="5" rx="0" ry="0" width="500" height="100%"/>
            </ContentLoader>
            </div>
            </div>
            <div className="store-side">
        <div className="store-product">
            {
                /**SEller */
            }
        <ContentLoader 
            height={265}
            width={341}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
    <rect x="20" y="15" rx="0" ry="0" width="179" height="37" /> 
    <rect x="20" y="61" rx="0" ry="0" width="179" height="37" /> 
    <rect x="20" y="105" rx="0" ry="0" width="179" height="37" /> 
    <rect x="20" y="150" rx="0" ry="0" width="179" height="37" />
        </ContentLoader>
            </div>
            {/* <p className="store-lastonline">Terakhir aktif 1 jam yang lalu</p> */}

        </div>
    </div>
        </div>
    )

export default ViewMyProductLoading;