import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
    NewHeader,
    Footer
} from '../../../components';
import AuthService from '../../../actions/API/AuthService'
import {
    authGet, 
    authPost,
    FormatUang,
    Toast,
    AlertError,
} from './../../../helper';
import { withNamespaces } from 'react-i18next';
import i18n from '../../../i18n';
import {MessageChannel} from '../../../socket';
import No_Message from '../../../assert/icon/default-message.png'
import { fetchCategories } from '../../../actions/categoryActions';
import ReactLoading from 'react-loading';
import MessageLoading from '../../../components/Loading/MessageLoading';
import NetworkDetector from '../../PUBLIC/NetworkDetector';
const LoadingInfiniteScroll = () =>
    (
        <div className="loading-statistic">
            <ReactLoading
                type="spinningBubbles"
                color="#0C7F8F"
                height={"100px"}
                width={"50px"} />
        </div>
    )
const LoadingInfiniteScroll2 = () =>
    (
        <div className="loading-kirim-btn">
            <ReactLoading
                type="spinningBubbles"
                color="#0C7F8F"
                height={"20px"}
                width={"20px"} />
        </div>
    )
class Message extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingPage: true,
            rooms: [],
            messages: [],
            messagess: [],
            openConv: false,
            message_id_active: 0,
            user_id_active: 0,
            shop_user_id_active: 0,
            room_active: {},
            tipe_active: "",
            message_text: '',
            latest_msg: '',
            latest_msg_time: '',
            categories: [],
            products: [],
            category_selected: 0,
            product_selected: {},
            keyword_link: "",
            loadingMessage: false,
            loadingButton: false,
        }
        this.Auth = new AuthService();
        this.end_msg = React.createRef();
    }

    componentDidMount() {
        this.setState({loadingMessage: true})
        let { token } = this.props.userData;
        this.props.fetchCategories()
            .then(() => {
                this.setState({ categories: this.props.categories });
        })
        authGet('messages/get_rooms', token).then(res => {
            if (res.data.data.length > 0){            
            this.setState({
                rooms: res.data.data,
                latest_msg: res.data.data[0].latest_message.msg,
                message_id_active: res.data.data[0].id,
                user_id_active: res.data.data[0].user_id,
                shop_user_id_active: res.data.data[0].shop_user_id,
                shop_id_active: res.data.data[0].shop_id,
                tipe_active: res.data.data[0].tipe,
                room_active: res.data.data[0],
            }, () => {
                this.onRoomClick(res.data.data[0])
                this.setState({ loadingPage: false });
            });
        }
        else {
                this.setState({
                    rooms: [],
                    message_id_active: 0,
                    user_id_active: 0,
                    shop_user_id_active: 0,
                    tipe_active: 0,
                    loadingPage: false,
                    loadingMessage: false,
                });
        }
        })
    }

    refreshRooms() {
        let { token } = this.props.userData;
        authGet('messages/get_rooms', token).then(res => {
                this.setState({
                    rooms: res.data.data,
                });
        })
    }

    componentDidUpdate() {
        // this.scrollToBottom();
    }

    onRoomClick(data){
        this.setState({
            user_id_active: data.user_id,
            shop_user_id_active: data.shop_user_id,
            shop_id_active: data.shop_id,
            message_id_active: data.id,
            tipe_active: data.tipe,
            room_active: data,
            latest_msg: '',
            latest_msg_time: '',
            product_selected: {},
            category_selected: 0,
            products: [],
            loadingMessage: true,
        }, () => {
                this.refreshRooms();
                authPost({ shop_id: this.state.shop_id_active }, 'product/search_product', this.props.userData.token).then(res => {
                    this.setState({ products: res.data.data });
                    this.getMessage(this.state.message_id_active);
                    MessageChannel(this.props.userData, this.state.message_id_active).listen('MessageSendEvent', (e) => {
                        this.getMessage(e.message.id)      
                    })
                })
            });
    }

    getMessage(id){
        let { token } = this.props.userData;
        let data = {
            message_id: id
        }
        authPost(data, 'messages/get_message', token).then(res => {
            
            this.setState({
                messages: res.data.data,
                openConv: true,
                latest_msg: res.data.data.slice(-1)[0].message,
                latest_msg_time: res.data.data.slice(-1)[0].date,
                
            }, () => {
                this.groupingMessage();
            });
        })
    }

    groupingMessage() {
        // this gives an object with dates as keys
        const groups = this.state.messages.reduce((group, data) => {
            const date = data.date_title;
            if (!group[date]) {
                group[date] = [];
            }
            group[date].push(data);
            return group;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
            return {
                date,
                msg: groups[date]
            };
        });
        this.setState({
            messagess: groupArrays,
            loadingMessage: false
        }, () => {
                this.end_msg.current.scrollIntoView({ block: 'end' }); //{ block: 'end', behavior: 'smooth' }
        });
    }
    
    onMessageTextAreaChange = (e) => {
        this.setState({
            message_text: e.target.value,
        });
    }

    onKirimClick = (e) => {
        e.preventDefault();
        if (!this.state.message_text) { 
            AlertError.fire({text: i18n.t("Message:validation.pesan_kosong")});
            return false;
        }
        let { token } = this.props.userData;
        var textarea_msg = this.refs.textarea_msg;
        let data = {
            message: this.state.message_text,
            message_id: this.state.message_id_active,
            from: (this.state.tipe_active === "user" ? this.state.user_id_active : this.state.shop_user_id_active),
            to: (this.state.tipe_active === "user" ? this.state.shop_user_id_active : this.state.user_id_active),
            tipe: this.state.tipe_active,
            product_selected: this.state.product_selected.id
        }
        this.setState({loadingButton: true})
        authPost(data, 'messages/store', token).then(res => {
            this.setState({
                messages: res.data.data,
                message_text: "",
                product_selected: {},
                openConv: true,
                loadingButton: false
            }, () => {
                this.refreshRooms();
                this.groupingMessage();
                textarea_msg.value="";
                    Toast.fire({
                        type: "success",
                        title: i18n.t("Message:success_kirim")
                    })
            });
        })
    }

    onClickTambahLink = (e) => {
        e.preventDefault();

    }

    onChangeCategory = (e) => {
        this.setState({
            category_selected: parseInt(e.target.value),
            keyword_link: "",
            products: []
        }, () => {
            let data = {
                keyword: this.state.keyword_link,
                category_id: this.state.category_selected,
                shop_id: this.state.shop_id_active
            };
            let { token } = this.props.userData;
            authPost(data, 'product/search_product', token)
                .then(res => {
                    this.setState({ products: res.data.data })
            })
        })
    }

    onChangeKeywordLink = (e) => { 
        this.setState({
            keyword_link: e.target.value
        }, () => {
            if (this.state.keyword_link.length > 2) { 
                let data = {
                    keyword: this.state.keyword_link,
                    category_id: this.state.category_selected,
                    shop_id: this.state.shop_id_active
                };
                let { token } = this.props.userData;
                authPost(data, 'product/search_product', token)
                .then(res => {
                    this.setState({products: res.data.data})
                })
            }
        });
    }
    
    onClickProduct(product) {
        this.setState({
            product_selected: product
        });
    }

    onClickLinkBatal = (e) => {
        e.preventDefault();
        this.setState({
            product_selected: {}
        });
    }

    render() {
        let Page = (
            <div>
            <div className="container" style={{marginTop: "62px"}}>
                <div className="basic-container">
                    <div className="direct-message-container mt20">
                        <div className="direct-message-user">
                            <span className="direct-message-title">{i18n.t("Message:pesan")}</span>
                            {
                                this.state.rooms.length > 0 ? 
                                this.state.rooms.map((data, i) => {
                                    return (
                                        <div className={data.id === this.state.message_id_active ? 'dm-user active' : 'dm-user'} key={i} onClick={() => this.onRoomClick(data)}>
                                            <span className="pull-right" style={{ fontSize: "11px" }}>{data.id === this.state.message_id_active ? this.state.latest_msg_time : data.latest_message.date}</span>
                                            <img src={data.image} alt="Toko" className="shop-icon" />
                                            <div className="pull-left">
                                                <span className="shop-name">{data.name}</span>
                                                <p style={{wordBreak: "break-all", width: "210px"}}>{data.id === this.state.message_id_active ? this.state.latest_msg.replace(/[<]br[^>]*[>]/gi," ") : data.latest_message.msg.replace(/[<]br[^>]*[>]/gi," ")}</p>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    )
                                })
                                :
                                <p className="regularitalic mt20">{i18n.t("Message:no_msg")}</p>
                            }
                        </div>
                        {
                        this.state.messagess <=0 ? 
                        <div className="direct-message-form-default">
                            {
                                this.state.loadingMessage ? <LoadingInfiniteScroll /> : 
                                <React.Fragment>
                                <img src={No_Message} alt="Pesan" />
                                <p><b>{i18n.t("Message:welcome")}</b><br />{i18n.t("Message:no_msg2")}</p>
                                </React.Fragment>
                            }
                        </div>
                        :
                        <div className="direct-message-form">
                            <div className="direct-message-conv">
                                {this.state.loadingMessage ? <LoadingInfiniteScroll /> : this.state.messagess.map((datax, ix) => {
                                        return (
                                            <div key={ix}>
                                                <div className="dm-title">{datax.date}</div>
                                                    {datax.msg.map((data, i) => <div className={data.pos} key={i}>
                                                                                        <div className="dm-message">
                                                                                            <span className="dm-time" style={{ marginBottom: "5px" }}>{data.date}</span>
                                                                                            <div className="dm-text" style={{wordBreak: "break-all"}}>
                                                                                            {
                                                                                                data.reference_type === "invoice" ? 
                                                                                                    <div className="box-outline">
                                                                                                        <span className="pull-right fs15">Rp <FormatUang value={data.reference_data.total}/></span>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={this.state.tipe_active === 'user' ? `/transaction/detail/${data.reference_data.invoice_id}` : `/transaction/seller/${data.reference_data.invoice_id}`} className="fs15" style={{overflowWrap: "break-word"}}>#{data.reference_data.no_transaksi}</a>
                                                                                                    </div>
                                                                                                    :
                                                                                                    ''
                                                                                            }
                                                                                            {
                                                                                                data.reference_type === "product" ?
                                                                                                    <div className="product-card-message mb10">
                                                                                                        <div className="product-thumb">
                                                                                                            <img src={data.reference_data.image_thumb} alt="" />
                                                                                                        </div>
                                                                                                        <div className="product-info">
                                                                                                            {
                                                                                                                data.reference_data.is_deleted ? 
                                                                                                              <p className="semibold mt20" style={{overflowWrap: "break-word"}} title="Produk ini telah dihapus">{data.reference_data.product_name}</p> :
                                                                                                              <a target="_blank" rel="noopener noreferrer" href={`/product/${data.reference_data.id}`} className="semibold mt20" style={{overflowWrap: "break-word"}}>{data.reference_data.product_name}</a>
                                                                                                            }
                                                                                                            {
                                                                                                            data.reference_data.price !== data.reference_data.real_price ? 
                                                                                                                        <>
                                                                                                                        <p style={{overflowWrap: "break-word",fontSize: "12px", color: "#777777", marginBottom: "7px"}}>{data.reference_data.subtitle}</p>
                                                                                                                        <div className="harga">
                                                                                                                            <div>
                                                                                                                                <div className="discount-box">
                                                                                                                                    {data.reference_data.discount}%
                                                                                                                                </div>
                                                                                                                                <div className="main-price">
                                                                                                                                    Rp <FormatUang value={data.reference_data.price}/>
                                                                                                                                </div>
                                                                                                                                <div className="discount-harga">
                                                                                                                                    Rp <FormatUang value={data.reference_data.real_price}/>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <p style={{overflowWrap: "break-word",fontSize: "12px", color: "#777777", marginBottom: "0px"}}>{data.reference_data.subtitle}</p>
                                                                                                                        <div className="harga">
                                                                                                                            <div className="main-price" style={{ marginLeft: "0px" }}>
                                                                                                                                Rp <FormatUang value={data.reference_data.price}/>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                            }
                                                                                                            </div>
                                                                                                        <div className="clearfix"></div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    ''
                                                                                            }
                                                                                                <p style={{marginBottom: "0px"}} dangerouslySetInnerHTML={{__html: data.message}}/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="clearfix"></div>
                                                                                      </div>
                                                    )}
                                            </div>
                                        )
                                    })
                                }
                                <div ref={this.end_msg}></div>
                            </div>
                            {this.state.loadingMessage ? null : 
                                this.state.rooms.length > 0 ? 
                                <div className="direct-message-write">
                                {
                                    Object.keys(this.state.product_selected).length === 0 ? '' :
                                    <div className="product-card mb10">
                                        <a href="/#" className="pull-right mr10" onClick={(e) => {e.preventDefault(); this.setState({product_selected: {}});}}><i className="fas fa-times"></i></a>
                                        <div className="product-thumb">
                                            <img src={this.state.product_selected.image} alt="" />
                                        </div>
                                        <div className="product-info">
                                            <p className="semibold mt20" style={{overflowWrap: "break-word"}}>{this.state.product_selected.product_name}</p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                }
                                    <textarea ref="textarea_msg" className="form-control mb10" placeholder={i18n.t("Message:tulis_pesan")} onChange={this.onMessageTextAreaChange}></textarea>
                                    { this.state.loadingButton ? <LoadingInfiniteScroll2 /> : <a href="/#" className="btn btn-solid primary pull-right" onClick={this.onKirimClick}>{i18n.t("Message:kirim")}</a>}
                                    {
                                        this.state.tipe_active === "shop" ? '' : this.state.loadingButton ? <span><i className="fas fa-link"></i> {i18n.t("Message:link_barang.btn")}</span> : <a href="/#" data-toggle="modal" data-target="#modalTambahLink" data-backdrop="static" data-keyboard="false" onClick={this.onClickTambahLink}><i className="fas fa-link"></i> {i18n.t("Message:link_barang.btn")}</a>
                                    }
                                </div>
                                :
                                ''
                                
                            }
                        </div>
                        }
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            <Footer />
            </div>
        )
        return (
            <div>
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userId}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    clearFilterPage={this.clearFilterPage}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={500}
                    classNames="mypage"
                    unmountOnExit>
                    <MessageLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <div className="modal fade modal-2" id="modalTambahLink" tabIndex="-1" role="dialog" aria-hidden="true" >
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{i18n.t("Message:link_barang.title")}</h2>
                                    <div className="mb10">
                                        <img src={this.state.room_active.image} alt="" className="shop-icon mr10" />
                                        <div className="pull-left">
                                            <a href="detail_toko.php" className="shop-name">{this.state.room_active.name}</a>
                                            <p><i className="fas fa-map-marker-alt"></i> {this.state.room_active.shop_address}</p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 pr5">
                                            <div className="form-group-div">
                                                <select className="form-control" placeholder="Kategori" value={this.state.category_selected} onChange={this.onChangeCategory}>
                                                    <option value="0">{i18n.t("Message:link_barang.kategori")}</option>
                                                    {
                                                        this.state.categories.map(data => <option value={data.id} key={data.id}>{data.name}</option>)
                                                    }
                                                </select>
                                            </div>	
                                        </div>
                                        <div className="col-md-7 pl5">
                                            <div className="form-group-div">
                                                <input type="text" className="form-control" placeholder={i18n.t("Message:link_barang.cari_barang")} value={this.state.keyword_link} onChange={this.onChangeKeywordLink}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="choose-product-container">
                                    {
                                        this.state.products.map(data => (
                                            <div className={this.state.product_selected.id === data.id ? "choose-product active" : "choose-product"} key={data.id} onClick={() => this.onClickProduct(data)}>
                                                <img src={data.image} alt={data.product_name} width={60} height={60}/>
                                                <div className="pull-left pt10">
                                                    <span className="block">{data.product_name}</span>
                                                    <span className="block bold primary">Rp <FormatUang value={data.price}/></span>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm" onClick={this.onClickLinkBatal}>{i18n.t("Message:link_barang.batal")}</button>
                                    <button className="btn btn-solid primary btn-sm" data-dismiss="modal">{i18n.t("Message:link_barang.pilih")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.user.user,
        userId: state.user.user.user,
        categories: state.categories.items,
    }
}
export default NetworkDetector(withNamespaces("Message")(connect(mapStateToProps, {
    fetchCategories
})(Message)))