import React from 'react'
import ContentLoader from 'react-content-loader'
function SubscribeLoading() {
    return (
        <div className="container" style={{ marginTop: "62px" }}>
        <ContentLoader 
            height={20}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            >
            <rect x="0" y="5" rx="0" ry="0" width="75" height="15"/>
            <rect x="290" y="12" rx="0" ry="0" width="30" height="5"/>
            <rect x="325" y="10" rx="0" ry="0" width="75" height="10"/>
        </ContentLoader>
        <div className="product-container">
            <div>
                    <ContentLoader 
                        height={100}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="10" rx="0" ry="0" width="190" height="75" />
                        <rect x="200" y="10" rx="0" ry="0" width="190" height="75" />
                    </ContentLoader>
                    <ContentLoader 
                        height={100}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                        >
                        <rect x="0" y="0" rx="0" ry="0" width="190" height="75" />
                        <rect x="200" y="0" rx="0" ry="0" width="190" height="75" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    )
}

export default SubscribeLoading
