import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import ReactLoading from 'react-loading'
import ReactTooltip from "react-tooltip";
import {
    NewHeader,
    LoadingPage
} from '../../../../components';
import {
    FormatUang, authPost, AlertError, AlertSuccess, ConfirmWarning, authPostPaginate, AlertLoading
} from './../../../../helper';
import {
    getMyShop,
} from "../../../../actions/shopActions";
import {
    setNotification
} from '../../../../actions/notificationAction';
import "react-datepicker/dist/react-datepicker.css";
import {
    toggleModalLogin
} from '../../../../actions/registerActions';
import { fetchCategories } from '../../../../actions/categoryActions';
import EmptyResult from '../../../../components/Global/EmptyResult';
import imageEmptyHutang from '../../../../assert/icon/empty-detail-hutang.svg';
import imageEmptyHutangLunas from '../../../../assert/icon/empty-detail-lunas-hutang.svg';
import NetworkDetector from '../../../PUBLIC/NetworkDetector'

class DetailUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingPage: true,
            loadData: false,
            loadFilterData: false,
            data: [],
            pagination: [],
            filter_data: [],
            checked_filter_data: [],
            sorting_data: [],
            total_piutang: 0,
            flag: 1,
            sort_id: 1,
            filter_id: 1,
            blacklist_at: null,
            nonaktif_at: null,
            can_delete: false,
            tempo_piutang_form: 0,
            img_empty: [imageEmptyHutang, imageEmptyHutangLunas],
            text_empty: ["Pembeli spesial ini tidak memiliki piutang.", "Pembeli spesial ini tidak memiliki piutang yang sudah lunas."],
            title_empty: ["Pembeli tidak memiliki piutang", "Pembeli tidak memiliki piutang lunas"],
        }
    }
    componentDidMount() {
        // this.props.getMyShop(this.props.userData.token);
        this.retrieveData({
            id: this.props.match.params.id,
            flag: 1,
            sort_id: 1,
            filter_id: 1
        });
    }

    retrieveData(data) {
        authPost(data, `vip/user/detail`, this.props.userData.token)
        .then(res => {
            this.setState({
                ...res.data,
                data: res.data.list_hutang.data,
                pagination: res.data.list_hutang.meta.pagination,
                sort_id: res.data.sort_id,
                filter_id: res.data.filter_id,
                tempo_piutang_form: res.data.tempo_piutang,
                loadingPage: false,
                loadData: false,
                loadFilterData: false
            })
        })
        .catch(err => {
            AlertError.fire({text : "Pembeli Special tidak ditemukan"})
            .then(() => {
                this.props.history.push("/myshop/vip/list");
            })
        });
    }

    handleChangeTab(e, flag) {
        e.preventDefault();

        this.setState({
            flag,
            loadData: true
        }, () => {
            let data = {
                id: this.props.match.params.id,
                flag: this.state.flag,
                sort_id: this.state.sort_id,
                filter_id: this.state.filter_id
            };

            this.retrieveData(data);
        })
    }
    onChangeSortData(e) {
        this.setState({
            sort_id: parseInt(e.currentTarget.value),
            loadFilterData: true,
        }, () => {
            let data = {
                sort_id: this.state.sort_id,
                filter_id: this.state.filter_id,
                flag: this.state.flag,
                id: this.props.match.params.id
            }
    
            this.retrieveData(data);
        })
    }

    onClickfilterData = (e) => {
        e.preventDefault();
        this.setState({
            loadFilterData: true
        } , () => {
            let data = {
                sort_id: this.state.sort_id,
                filter_id: this.state.filter_id,
                flag: this.state.flag,
                id: this.props.match.params.id
            }
            this.retrieveData(data);
        })
    }   

    onClickBlacklist = (e) => { 
        e.preventDefault();
        let data = {
            id: this.props.match.params.id
        }
        ConfirmWarning.fire({
            text : "Apakah anda yakin ingin memblokir pembeli spesial ini?"
        }).then(result => {
            if(result.value) {            
                AlertLoading.fire();
                authPost(data, 'vip/blacklist_user', this.props.userData.token).then(res => {
                    AlertSuccess.fire({title: "Berhasil", text: res.data.message}).then(() => {
                        window.location.href = '/myshop/vip/list';
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                });
            }
        })
    }

    onClickAktifkan = (e, from) => {
        e.preventDefault();
        let data = { 
            id: this.props.match.params.id,
            from: from
        }
        ConfirmWarning.fire({
            text : (from === "nonaktif" ? "Apakah anda yakin ingin mengaktifkan kembali pembeli spesial ini?" : "Apakah anda yakin ingin menghapus blacklist dan mengaktifkan kembali pembeli spesial ini?")
        }).then(result => {
            if(result.value) {        
                AlertLoading.fire();  
                authPost(data, 'vip/aktifkan_user', this.props.userData.token)
                .then(res => {
                    AlertSuccess.fire({title: "Berhasil", text: res.data.message}).then(() => {
                        window.location.href = '/myshop/vip/list';
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                });
            }
        });
    }

    onClickMasukkanNonAktif = (e) => {
        e.preventDefault();
        let data = { 
            id: this.props.match.params.id,
        }
        ConfirmWarning.fire({
            text : "Apakah anda yakin ingin menghapus blacklist dan memasukkan pembeli spesial ini ke nonaktif?"
        }).then(result => {
            if(result.value) {            
                AlertLoading.fire();  
                authPost(data, 'vip/remove_blacklist_user', this.props.userData.token)
                .then(res => {
                    AlertSuccess.fire({title: "Berhasil", text: res.data.message}).then(() => {
                        window.location.href = '/myshop/vip/list';
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                });
            }
        });
    }

    onClickNonaktif = (e) => {
        e.preventDefault();
        let data = { 
            id: this.props.match.params.id
        }
        ConfirmWarning.fire({
            text : "Apakah anda yakin ingin menonaktifkan pembeli spesial ini?"
        }).then(result => {
            if(result.value) {        
                AlertLoading.fire();   
                authPost(data, 'vip/nonaktif_user', this.props.userData.token)
                .then(res => {
                    AlertSuccess.fire({title: "Berhasil", text: res.data.message}).then(() => {
                        window.location.href = '/myshop/vip/list';
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                });
            }
        });
    }

    deleteUserVip = (e) => {
        e.preventDefault();
        ConfirmWarning.fire({
            text: 'Anda yakin menghapus data ini?'
        }).then(result => {
            if(result.value) {
                AlertLoading.fire();
                authPost({id: this.props.match.params.id}, `vip/remove_member_vip`, this.props.userData.token)
                .then(res => {
                    AlertSuccess.fire({text: 'Pembeli special berhasil di hapus', title: "Berhasil"}).then(() => {
                        window.location.href = '/myshop/vip/list';
                    })
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        });
    }

    onClickUbahTempo = (e) => {
        e.preventDefault();
        let data = {
            sort_id: this.state.sort_id,
            filter_id: this.state.filter_id,
            flag: this.state.flag,
            tempo: this.state.tempo_piutang_form,
            id: this.props.match.params.id
        }
        AlertLoading.fire();
        authPost(data, `vip/ubah_tempo`, this.props.userData.token)
        .then(res => {
            AlertSuccess.fire({title: "Berhasil", text: res.data.message}).then(() => {
                this.retrieveData(data)
            });
        })
        .catch(err => {
            AlertError.fire({text: err.response.data.message});
        })
    }

    loadMoreData = () => {
        let { next } = this.state.pagination.links;
        let data = {
            sort_id: this.state.sort_id,
            filter_id: this.state.filter_id,
            flag: this.state.flag,
            id: this.props.match.params.id
        }
        this.setState({loadingMoreData: true});
        authPostPaginate(data, next, this.props.userData.token)
        .then(res => {
            this.setState({
                data: [...this.state.data, ...res.data.list_hutang.data],
                pagination: res.data.list_hutang.meta.pagination,
                sort_id: res.data.sort_id,
                filter_id: res.data.filter_id,
                loadingMoreData: false,
            })
        })
    }
    hasMoreData = () => !(this.state.data.length === (this.state.pagination ? this.state.pagination.total : -1 ));

    render() {
        let Page = (
            <div className="container-fluid">
                    <div className="basic-container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/myshop/vip/list"> Pembeli VIP</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{this.state.name}</li>
                            </ol>
                        </nav>
                        <br />
                        <h1 className="page-title">Detail Pembeli Spesial</h1>

                        <div className="pull-left mr20 text-center">
                            <div className="user-profile-picture">
                                <img src={this.state.image_url} alt="Profil" className={this.state.blacklist_at ? "profile-picture lg bw" : "profile-picture lg"} />
                                {
                                    this.state.blacklist_at || this.state.nonaktif_at ? 
                                        <span className={this.state.blacklist_at ? "badge badge-pill badge-dark badge-user-profile" : "badge badge-pill badge-warning badge-user-profile"}>{this.state.blacklist_at ? "Blacklist" : "Nonaktif"}</span>
                                    : null
                                }
                            </div>
                        </div>

                        <div className="pull-left">
                            <span className="block fs25 semibold">{this.state.name}</span><br />
                            <span className="block fs13" style={{ marginBottom: "3px" }}><i className="fas fa-envelope"></i> {this.state.email ? this.state.email : "-"}</span>
                            <span className="block fs13" style={{ marginBottom: "3px" }}><i className="fas fa-phone"></i> {this.state.phone ? this.state.phone : "-"}</span>
                            <span className="block fs13"><i className="fas fa-map-marker-alt"></i> {this.state.address ? `${this.state.address.alamat_lengkap}`: "-"}</span>
                            <br />
                            
                        </div>
                        <div className="pull-right">
                            <a href="/" className="btn btn-lg btn-outline primary nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-cog"></i> Pengaturan</a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <a href="/#" className="dropdown-item" data-toggle="modal" data-target="#modalUbahTempo" data-backdrop="static" data-keyboard="false"><i className="fas fa-pencil-alt"></i> Ubah Tempo Piutang</a>
                                {
                                    this.state.can_delete
                                    ? <a href="/#" className="dropdown-item" onClick={this.deleteUserVip}><i className="fas fa-trash-alt"></i> Hapus Pembeli Spesial</a>
                                    : null
                                }
                                
                                {
                                    this.state.nonaktif_at ? //Jika dinonaktifkan
                                        this.state.blacklist_at ?  //Jika dinonaktifkan dan diblacklist
                                            <><a className="dropdown-item yellow" href="/#" onClick={(e) => this.onClickMasukkanNonAktif(e)} data-tip data-for="aktifkan_2"><i className="far fa-eye-slash"></i> &nbsp;Masukkan ke Nonaktif</a><ReactTooltip id="aktifkan_2" type="warning" effect="solid">Pembeli dapat mengakses dan membeli barang di toko namun tidak mendapatkan harga spesial</ReactTooltip></>
                                        :   <><a className="dropdown-item green" href="/#" onClick={(e) => this.onClickAktifkan(e, "nonaktif")} data-tip data-for="aktifkan"><i className="far fa-eye"></i> &nbsp;Aktifkan Kembali</a><ReactTooltip id="aktifkan" type="success" effect="solid">Pembeli akan mendapatkan harga spesial kembali</ReactTooltip></> //Hanya dinonaktifkan
                                    : this.state.blacklist_at ? null //Jika tidak dinonaktifkan tapi di blacklist
                                        : <><a href="/#" className="dropdown-item yellow" onClick={(e) => this.onClickNonaktif(e)} data-tip data-for="nonaktif"><i className="fas fa-eye-slash "></i> Non Aktifkan</a><ReactTooltip id="nonaktif" type="warning" effect="solid">Pembeli tidak akan mendapat harga spesial</ReactTooltip></> //Jika tidak dinonaktifkan dan tidak di blacklist
                                }
                                {
                                    this.state.blacklist_at ? 
                                        <><a className="dropdown-item green" href="/#" onClick={(e) => this.onClickAktifkan(e, "blacklist")} data-tip data-for="aktifkan"><i className="far fa-eye"></i> &nbsp;Aktifkan Kembali</a><ReactTooltip id="aktifkan" type="success" effect="solid">Pembeli akan mendapatkan harga spesial kembali</ReactTooltip></>
                                    :   <><a href="/#" className="dropdown-item red" onClick={(e) => this.onClickBlacklist(e)} data-tip data-for="blacklist"><i className="fas fa-ban "></i> Blacklist</a><ReactTooltip id="blacklist" type="error" effect="solid">Pembeli tidak akan bisa mengakses dan membeli barang di toko</ReactTooltip></>
                                }
                            </div>
                            <br /><br />
                            <span className="block fs13 pull-right">Tempo Piutang</span><br />
                            <span className="block fs25 semibold pull-right">{this.state.tempo_piutang} Hari</span>
                        </div>
                        <div className="clearfix"></div>
                        <br />
                    </div>
                    <div className="vip-container">
                        <div className="vip-main" style={{ padding: "0px" }}>
                        { this.state.loadData
                                ? <div className="text-center" style={{height: "400px"}}>
                                    <div className="loading-btn mb20" style={{height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <ReactLoading type="spinningBubbles" color="#0C7F8F"/>
                                    </div>
                                </div>
                                :                         
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="vip-top">
                                        <div className="detail-vip-tab">
                                            <div className="pull-right">
                                                <div className="sorting-input">
                                                <select className="form-control" value={this.state.sort_id} onChange={(e) => this.onChangeSortData(e)}>
                                                            {
                                                                this.state.sorting_data.map(d => (
                                                                    <option value={d.id} key={d.id}>{d.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                <label>Urutkan</label>
                                                </div>
                                            </div>   
                                            <ul>
                                                <li className={this.state.flag === 1 ? "active" : ""}>
                                                    <a href="/#" onClick={(e) => this.handleChangeTab(e, 1)}>Daftar Piutang</a>
                                                </li>
                                                <li className={this.state.flag === 2 ? "active" : ""}>
                                                    <a href="/#" onClick={(e) => this.handleChangeTab(e, 2)}>Piutang Lunas</a>
                                                </li>
                                            </ul>                         
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-2">
                                <label className="mr20" style={{ fontSize: "24px",lineHeight: "33px", float:"left", marginLeft:"30px" }}>Filter</label>
                                <br />
                                <br />
                                <div className="filter-menu" style={{ marginTop:"10px",marginLeft: "30px" }}>
                                    <div className="mb20">
                                        <h3>Jatuh Tempo Piutang</h3>
                                        <table width="100%" cellPadding="3">
                                            <tbody>
                                                {
                                                    this.state.filter_data.map(f => (
                                                        <tr key={f.id}>
                                                            <td>
                                                                <input 
                                                                    type="radio" 
                                                                    checked={this.state.filter_id === f.id} 
                                                                    onChange={() => this.setState({filter_id: f.id})}
                                                                    />
                                                            </td>
                                                            <td>{f.label}</td>
                                                        </tr>
                                                    ))
                                                }                                          
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mb20 text-center">
                                    <button className="btn btn-block btn-solid primary" onClick={this.onClickfilterData} disabled={this.state.flag !== 1}>Terapkan</button>
                                        <br/>
                                        <a href="/#">Reset</a>
                                    </div>                                        
                                </div>
                            </div>  
                            <div className="col-md-10">
                            {
                                this.state.loadFilterData ? 
                                    <div className="text-center" style={{height: "400px"}}>
                                        <div className="loading-btn mb20" style={{height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <ReactLoading type="spinningBubbles" color="#0C7F8F"/>
                                        </div>
                                    </div>
                                :
                                this.state.data <= 0 ? <EmptyResult image={this.state.img_empty[this.state.flag-1]} message={this.state.text_empty[this.state.flag-1]} title={this.state.title_empty[this.state.flag-1]} buttonText={0}/>
                                :
                                <>
                                <div className="pull-right">
                                {
                                    this.state.flag === 1 ? 
                                        <label style={{ marginRight: "10px", fontSize: "18px",lineHeight: "25px", fontWeight: "600" }}>Total Piutang: <span style={{ color: "#0C7F8F" }}> Rp &nbsp;<FormatUang value={this.state.total_piutang} /></span></label>
                                    :
                                        <label style={{ marginRight: "10px", fontSize: "18px",lineHeight: "25px", fontWeight: "600" }}>Total Transaksi: <span style={{ color: "#0C7F8F" }}> Rp &nbsp;<FormatUang value={this.state.total_piutang} /></span></label>
                                }
                                </div>
                                <table className="table table-form" style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}>
                                    <tbody>
                                        {
                                            this.state.data.map(data => (
                                                <tr className="" key={data.id}>
                                                    <td className="p10">
                                                        <p className="semibold mb0 fs16">{data.label} <a href={data.link_shop_ref}>{data.ref}</a></p><span>{data.tanggal}</span>
                                                    </td>
                                                    <td align="right" className="p10">
                                                        <p className="semibold mb0 fs16"> Rp &nbsp;<FormatUang value={data.nominal} /></p>
                                                        <span className={`badge badge-pill ${data.status_class}`} style={{ marginTop:"4px", fontWeight: "100" }}>
                                                            <i className="fas fa-calendar-alt"></i> &nbsp;{data.jatuh_tempo}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </tbody>
                                </table>
                                </>
                            }
                            {
                                this.hasMoreData()
                                ? <div className="text-center">
                                    {
                                        this.state.loadingMoreData
                                        ? <div className="loading-btn mb20"><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
                                        : <button className="btn btn-solid primary" onClick={this.loadMoreData}>Data Lainnya</button>
                                    }
                                </div>
                                : null
                            }                                   
                            </div>                          
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
        return(
            <div className="mg-top">
                <NewHeader
                    {...this.props}
                    stateLogin={this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    <LoadingPage />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                    {Page}
                </CSSTransition>
                <div className="modal fade modal-2" id="modalUbahTempo" tabIndex="-1" role="dialog" aria-hidden="true" >
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>UBAH TEMPO PIUTANG</h2>
                                    <div className="row">
                                        <div className="col-md-12 pl5">
                                            <div className="form-group-div" style={{ marginLeft: "20px" }}>
                                                <label>Tempo Piutang*</label>
                                                <br />
                                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="radio" 
                                                        checked={this.state.tempo_piutang_form === 30}
                                                        onChange={() => this.setState({tempo_piutang_form: 30})}
                                                        />
                                                    <label className="form-check-label" htmlFor="gridRadios1" style={{ color:"#333333" }}>
                                                        30 Hari
                                                    </label>
                                                </div>
                                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="radio"
                                                        checked={this.state.tempo_piutang_form === 60}
                                                        onChange={() => this.setState({tempo_piutang_form: 60})}
                                                        />
                                                    <label className="form-check-label" htmlFor="gridRadios2" style={{ color:"#333333" }}>
                                                        60 Hari
                                                    </label>
                                                </div>
                                                {/* <div className="form-group-div">
                                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
                                                    <label className="form-check-label" htmlFor="gridRadios2" style={{ color:"#333333" }}>
                                                        Atur sendiri
                                                    </label>
                                                </div>  
                                                    <div className="input-group input-group-sm mb-2" style={{ width: "70%", marginLeft: "18px" }}>
                                                        <input type="text" className="form-control form-control-sm" id="inlineFormInputGroup" placeholder="Jumlah hari" />
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">Hari</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>  
                                            <div className="notification-box sm mt20" style={{ marginLeft: "20px" }}>
                                                <div className="info">
                                                    <i className="fas fa-info-circle"></i>
                                                    <p>Tempo piutang adalah waktu jatuh tempo saat pembeli spesial membayar dengan piutang.</p>
                                                </div>
                                            </div>                                                                                   
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">Batal</button>
                                    <button className="btn btn-solid primary btn-sm" data-dismiss="modal" onClick={this.onClickUbahTempo}>Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        userData: state.user.user,
        shop: state.shop.item,
        notifMessage: state.notification.message,
        categories: state.categories.items,
    }
}
export default NetworkDetector(connect(mapStateToProps, {
    getMyShop,
    setNotification,
    toggleModalLogin,
    fetchCategories
})(DetailUser));
