import React from 'react';
import ContentLoader from "react-content-loader";

const TransactionDetailLoading = () => (
<div className="container">
    <div className="form-transaction">
    <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="150" height="10"/>
            </ContentLoader>
            <div className="form-subtitle no-border">
            <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="150" height="10"/>
                <rect x="250" y="0" rx="0" ry="0" width="150" height="10"/>
            </ContentLoader>
            <ContentLoader 
                height={15}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="250" y="0" rx="0" ry="0" width="150" height="15"/>
            </ContentLoader>
            </div>

            <div className="mb30">
            {/* <ContentLoader 
            height={75}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <circle cx="70" cy="30" r="20" /> 
            <circle cx="130" cy="30" r="20" />
            <circle cx="190" cy="30" r="20" />
            <circle cx="250" cy="30" r="20" />
            <circle cx="310" cy="30" r="20" />
            </ContentLoader> */}
              <ContentLoader 
                height={160}
                width={972}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
            >
                <rect x="205" y="33" rx="4" ry="4" width="85" height="4" /> 
                <circle cx="168" cy="37" r="36" /> 
                <circle cx="327" cy="39" r="36" /> 
                <circle cx="485" cy="39" r="36" /> 
                <rect x="363" y="34" rx="4" ry="4" width="85" height="4" /> 
                <rect x="520" y="32" rx="4" ry="4" width="85" height="4" /> 
                <circle cx="640" cy="37" r="36" /> 
                <rect x="673" y="29" rx="4" ry="4" width="85" height="4" /> 
                <circle cx="792" cy="38" r="36" />
            </ContentLoader>
            <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="15"/>
            </ContentLoader>
            </div>
            
            <div className="form-subtitle">
            <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
            </ContentLoader>
            </div>
            <div className="mb30">
                <div>
                <ContentLoader 
                height={75}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                <rect x="0" y="15" rx="0" ry="0" width="400" height="10"/>
                <rect x="0" y="30" rx="0" ry="0" width="400" height="10"/>
                <rect x="0" y="45" rx="0" ry="0" width="400" height="10"/>
            </ContentLoader>
                </div>
            </div>
            <div className="form-subtitle">
            <ContentLoader 
                height={20}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
            </ContentLoader>
            </div>
            <div className="mb30">
                <div>
                <ContentLoader 
                height={90}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="10"/>
                <rect x="0" y="15" rx="0" ry="0" width="400" height="10"/>
                <rect x="0" y="30" rx="0" ry="0" width="400" height="10"/>
                <rect x="0" y="45" rx="0" ry="0" width="400" height="10"/>
            </ContentLoader>
                </div>
            </div>
</div>
</div>
)
export default TransactionDetailLoading;