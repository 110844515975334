import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CSSTransition } from 'react-transition-group';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import validator from 'validator';

import NumberFormat from 'react-number-format';

import {
    NewHeader,
    TambahAlamat,
    ShippingAddress,
} from '../../../components';


import { 
    setShippingAddress,
    resendEmailVerify,
    setImageProfile,
    setNewToken
} from '../../../actions/userAction';
import AuthService from '../../../actions/API/AuthService';

import {
    toggleModalLogin
} from '../../../actions/registerActions';

import ReactLoading from 'react-loading';

import {
    authGet,
    authPost,
    AlertWarning,
    authPostFile,
    Toast,
    AlertError,
    // ConfirmWarning,
    ConfirmInfo,
    RedConfirmWarning,
} from '../../../helper';
import id from "date-fns/locale/id";
import ProfileLoading from '../../../components/Loading/ProfileLoading';
import { withNamespaces } from 'react-i18next';
import i18n from '../../../i18n';
import NetworkDetector from '../../PUBLIC/NetworkDetector';

registerLocale("id", id);
const Loading = () => <div className="loading-btn mb20" style={{marginRight: "50px"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>

class Profile extends Component {

    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            input_name: "",
            input_username: "",
            input_email: "",
            input_tanggal_lahir: "",
            input_no_telpon: "",
            input_jenis_kelamin : null,
            loadingUpdateDataDiri : false,
            loadingEmailVerify : false,
            loadingUploadImage: false,
            loadingUpdatePassword: false,
            password: {
                oldPassword: "",
                newPassword : "",
                confirmPassword: "",
                validNewPassword: true,
                validConfirmPassword: true,
                msgNewPassword: "",
                msgConfirmPassword: ""
            },
            current_address: [],
            alamat: [],
            is_edit_address: false,
            bank_accounts: [],
            master_banks: [],
            modal_create_bank: {
                input_bank_id: '',
                input_account_name: '',
                input_account_number: ''
            },
            modal_edit_bank : {
            }
        }

        this.Auth = new AuthService();
    }

    componentDidMount(){
        authGet(`profile`, this.props.userData.token)
        .then(res => {
            this.setState({
                ...res.data,
                input_name : res.data.name,
                input_username : res.data.username,
                input_email : res.data.email,
                input_no_telpon : res.data.no_telpon === "-" ? "" : res.data.no_telpon,
                input_jenis_kelamin : res.data.jenis_kelamin_id,
                input_tanggal_lahir : res.data.tanggal_lahir === "-" ? "" : moment(res.data.tanggal_lahir).toDate(),
                tanggal_lahir : res.data.tanggal_lahir === "-" ? "" : moment(res.data.tanggal_lahir).format("DD MMM Y"),
                alamat: res.data.pengiriman
            },
            () => {
                this.props.setShippingAddress(res.data.pengiriman.data)
            });
        })
        .then(() => {
            this.setState({loadingPage: false})
        })
        .catch(err => {
            this.setState({loadingPage: false})
        })
    }

    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value});
    }

    onChangeNoTelp(value){
        this.setState({input_no_telpon: value});
    }

    onChangePassword = (e) => {
        this.setState({password : 
            {
                ...this.state.password,
                [e.target.name] : e.target.value
            }
        })
    }

    onBlurPassword = (e) => {
        let { oldPassword, newPassword, confirmPassword } = this.state.password;
        if(oldPassword !== "" && oldPassword === newPassword && newPassword !== ""){
            this.isValidNewPassword(false)
        }else{
            this.isValidNewPassword(true)
        }

        if(e.target.name === "confirmPassword"){
            if(newPassword !== "" && newPassword !== confirmPassword && confirmPassword !== ""){
                this.isValidConfirmPassword(false);
            }else{
                this.isValidConfirmPassword(true);
            }
        }
    }

    isValidNewPassword(valid){
        this.setState({
            password: {
                ...this.state.password,
                msgNewPassword : valid ? "" : i18n.t("Profile:validation.valid_new_password.text"),
                validNewPassword: valid,
            }
        })
    }

    isValidConfirmPassword(valid){
        this.setState({
            password: {
                ...this.state.password,
                msgConfirmPassword : valid ? "" : i18n.t("Profile:validation.valid_confirm_password.text"),
                validConfirmPassword: valid,
            }
        })
    }

    onChangeModalBank = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            modal_create_bank : {
                ...this.state.modal_create_bank,
                [e.target.name] : e.target.name === 'input_account_number' ? validateValue : e.target.value
            }
        })
    }

    validateProfile(data){
        if(validator.isEmpty(data.name)){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.nama")});
            return false;
        }
        else if(!data.username || data.username === ""){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.username")});
            return false;
        }
        else if(!data.email || data.email === ""){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.email")});
            return false;
        }
        else if(!validator.isEmail(data.email)){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.email_valid")});
            return false;
        }
        else if(data.tanggal_lahir === "" || data.tanggal_lahir === null){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.tgl_lahir")});
            return false;
        }
        else if(!data.jenis_kelamin){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.jenis_kelamin")});
            return false;
        } 
        else if (!data.no_telpon){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.no_telp")});
            return false;
        }
        else if(!validator.isNumeric(data.no_telpon)){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_profile.no_telp_valid")});
            return false;
        }

        return true;
    }

    validatePassword(data){
        if(validator.isEmpty(data.old_password)){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_password.old")});
            return false;
        }
        else if(validator.isEmpty(data.new_password)){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_password.new")});
            return false;
        }
        else if(data.new_password.length < 8){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_password.new_char")});
            return false;
        }
        else if(validator.isEmpty(data.confirm_password)){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_password.confirm")});
            return false;
        }
        else if(!data.valid_new_password || !data.valid_confirm_password){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_password.tidak_sama")});
            return false;
        }

        return true;
    }

    updateProfile = () => {
        let data = {
            name : this.state.input_name,
            email : this.state.input_email,
            username : this.state.input_username,
            tanggal_lahir : this.state.input_tanggal_lahir,
            jenis_kelamin : this.state.input_jenis_kelamin,
            no_telpon : this.state.input_no_telpon
        }
        ConfirmInfo.fire({
            text : i18n.t("Profile:confirm")
        }).then(result => {
            if(result.value) {
                if(this.validateProfile(data)){
                    this.setState({loadingUpdateDataDiri : true});
                    authPost({...data, tanggal_lahir: moment(data.tanggal_lahir).format("Y-MM-DD")}, `profile`, this.props.userData.token)
                    .then(res => {
                        Toast.fire({
                            type: "success",
                            title : i18n.t("Profile:success_ubah")
                        })
                        this.setState({
                            ...res.data,
                            tanggal_lahir : moment(res.data.tanggal_lahir).format("DD MMM Y"),
                            loadingUpdateDataDiri: false
                        })
                        window.$("#modalUbahProfil").modal("hide");
                    })
                    .catch(err => {
                        AlertError.fire({text : err.response.data.message})
                        .then(() => {
                            
                            this.setState({loadingUpdateDataDiri: false});
                            // window.$("#modalUbahProfil").modal("hide");
                        })
                    })
                }
            }
        })
    }

    updatePassword = () => {

        const password = this.state.password

        let data = {
            old_password : password.oldPassword,
            new_password : password.newPassword,
            confirm_password : password.confirmPassword,
            valid_new_password : password.validNewPassword,
            valid_confirm_password : password.validConfirmPassword
        }

        if(this.validatePassword(data)){
            ConfirmInfo.fire({
                text : i18n.t("Profile:confirm")
            }).then(result => {
                if(result.value){
                    this.setState({loadingUpdatePassword : true});
                    authPost(data, `profile/password_change`, this.props.userData.token)
                    .then(res => {
                        this.setState({
                            loadingUpdatePassword : false,
                            password : {
                                ...this.state.password,
                                oldPassword : "",
                                newPassword : "",
                                confirmPassword : ""
                            }
                        });
                        this.Auth.setToken(res.data);
                        this.props.setNewToken(res.data);
                        Toast.fire({
                            type: "success",
                            title: i18n.t("Profile:success_ubah")
                        })
                        window.$("#modalUbahPassword").modal("hide");
                    })
                    .catch(err => {
                        AlertError.fire({text : err.response.data})
                        .then(() => {
                            this.setState({loadingUpdatePassword: false});
                        })
                    })
                }
            })
        }
    }


    cangePhotoProfile = (e) => {
        let formData = new FormData();

        formData.append('photo', e.target.files[0]);
        this.setState({loadingUploadImage: true});
        authPostFile(formData, `profile/upload`, this.props.userData.token)
        .then(res => {
            Toast.fire({
                type: "success",
                title: i18n.t("Profile:success_ubah")
            });

            this.setState({
                image: res.data.image,
                hasImage: res.data.hasImage,
                loadingUploadImage: false
            }, ()=> {
                this.props.setImageProfile(res.data.image);
            })
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message}).then(() => {
                this.setState({loadingUploadImage: false})
            })
        })
    }

    removeProfileImage = (e) => {
        e.preventDefault();

        RedConfirmWarning.fire({
            text: i18n.t("Profile:confirm_foto")
        }).then(result => {
            if(result.value){
                this.setState({loadingUploadImage: true});
                authGet(`profile/delete_image`, this.props.userData.token)
                .then(res => {
                    Toast.fire({
                        type: "success",
                        title: i18n.t("Profile:success_hapus")
                    });
        
                    this.setState({
                        image: res.data.image,
                        hasImage: res.data.hasImage,
                        loadingUploadImage: false
                    }, ()=> {
                        this.props.setImageProfile(0);
                    })
                })
                .catch(err => {
                    AlertError.fire({text : err.response.data.message}).then(() => {
                        this.setState({loadingUploadImage: false})
                    })
                })
            }
        })
    }

    onResendEmailVerify = (e) => {
        e.preventDefault();

        this.setState({loadingEmailVerify : true});
        this.props.resendEmailVerify(this.props.userData.token)
        .then(() => {
            Toast.fire({
                type: "success",
                title: i18n.t("Profile:resend_success")
            })
            this.setState({loadingEmailVerify : false})
        })
        .catch(err => {
            AlertError.fire({text : err.response.data.message});
            this.setState({loadingEmailVerify : false})
        })
    }
    onClickEditAlamat = (e) => {
        if (e.currentTarget.dataset.id === undefined) {
            this.setState({
                current_address: []
            });
        }else{
            this.setState({
                current_address: this.props.address[e.currentTarget.dataset.id],
                is_edit_address: true,
            });
        }
    }
    onClickHapusAlamat = (e) => {
        e.preventDefault();
        let data = {
            id: e.currentTarget.dataset.id
        };
        RedConfirmWarning.fire({
            text: i18n.t("Profile:confirm_delete")
        }).then(result => {
            if (result.value) {
                authPost(data, `shipping_address/delete`, this.props.userData.token)
                    .then(res => {
                        Toast.fire({
                            type: "success",
                            title: i18n.t("Profile:success_hapus")
                        });
                        this.props.setShippingAddress(res.data.data);
                    })
                    .catch(err => {
                        AlertError.fire({ text: err.response.data.message });
                    })
            }
        })
    }
    onClickTambahAlamat = () => {
        this.setState({
            is_edit_address: false,
        });
    }

    validateModalBank(data){
        if(data.bank_id === 0){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_bank.bank")});
            return false;
        }
        else if(data.nama_rekening === ""){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_bank.nama")});
            return false;
        }
        else if(data.nomor_rekening === ""){
            AlertWarning.fire({text: i18n.t("Profile:validation.validate_bank.nomor")});
            return false;
        }

        return true;
    }

    onClickAddBank = (e) => {
        e.preventDefault();

        let data = {
            bank_id : this.state.modal_create_bank.input_bank_id,
            nama_rekening: this.state.modal_create_bank.input_account_name,
            nomor_rekening: this.state.modal_create_bank.input_account_number
        }

        if(this.validateModalBank(data)){
            this.setState({loadingAddTokoBank: true});
            authPost(data, `shop_profile/add_bank`, this.props.userData.token)
            .then(res => {
                this.setState({
                    loadingAddTokoBank: false,
                    modal_create_bank : {
                        input_bank_id: '',
                        input_account_name: '',
                        input_account_number: ''
                    },
                    bank_accounts: res.data
                });
                Toast.fire({
                    type: "success",
                    title: i18n.t("Profile:success_tambah")
                });
                window.$('#modalTambahRekening').modal('hide');
            })
            .catch(err => {
                this.setState({loadingAddTokoBank: false});
                AlertError.fire({text: err.response.data.message});
            })
        }
    }

    setModalEditBank = (bank_account) => {
        this.setState({
            modal_edit_bank : {
                id: bank_account.id,
                input_bank_id: bank_account.bank_id,
                input_account_name: bank_account.account_name,
                input_account_number: bank_account.account_number,
                edit : true
            }
        })
    }

    onChangeModalBankEdit = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            modal_edit_bank : {
                ...this.state.modal_edit_bank,
                [e.target.name] : e.target.name === 'input_account_number' ? validateValue : e.target.value
            }
        })
    }

    onClickUpdateBank = (e) => {
        e.preventDefault();

        let data = {
            id: this.state.modal_edit_bank.id,
            master_bank_id : this.state.modal_edit_bank.input_bank_id,
            nama_rekening: this.state.modal_edit_bank.input_account_name,
            nomor_rekening: this.state.modal_edit_bank.input_account_number
        }

        if(this.validateModalBank(data)){
            this.setState({loadingUpdateTokoBank: true});
            authPost(data, `shop_profile/update_bank`, this.props.userData.token)
            .then(res => {
                this.setState({
                    loadingUpdateTokoBank: false,
                    bank_accounts: res.data
                });
                Toast.fire({
                    type: "success",
                    title: i18n.t("Profile:success_ubah")
                });
                window.$('#modalUbahRekening').modal('hide');
            })
            .catch(err => {
                this.setState({loadingUpdateTokoBank: false});
                AlertError.fire({text: err.response.data.message});
            })
        }
    }

    removeBank = (e, bank_account) => {
        e.preventDefault();

        RedConfirmWarning.fire({
            text: i18n.t("Profile:confirm_delete")
        }).then(result => {
            if(result.value){
                authPost(bank_account, `shop_profile/remove_bank`, this.props.userData.token)
                .then(res => {
                    this.setState({bank_accounts: res.data})
                    Toast.fire({
                        type: "success",
                        title: i18n.t("Profile:success_hapus")
                    });
                })
                .catch(err => {
                    AlertError.fire({text: err.response.data.message});
                })
            }
        })
    }

    render() {
        const { t } = this.props;
        let Page = (
            <div className="container">
                    <div className="basic-container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/"><i className="fas fa-home mr0"></i></a></li>
                                <li className="breadcrumb-item active" aria-current="page">{t("Profile:profil")}</li>
                            </ol>
                        </nav>
                        <br />
                        <h1 className="page-title">{t("Profile:profil")}</h1>

                        <div className="pull-left mr20 text-center">
                            <div className="user-profile-picture">
                                <img src={this.state.image} alt="Profil" className="profile-picture lg" />
                                {
                                    this.state.loadingUploadImage
                                    ? <div style={{position: "absolute", top: "35%", left: "35%"}}>
                                        <ReactLoading type="spin" color="#0C7F8F" width={30}/>
                                    </div>
                                    : null
                                }
                                <div className="action-profile-picture">
                                    <input id="myInput" type="file" ref={(ref) => this.uploadPhoto = ref} style={{ display: 'none' }} onChange={this.cangePhotoProfile} onClick={(event)=> { event.target.value = null }} accept=".png, .jpg, .jpeg"/>
                                    <a href="/#" className="mr10 fs15" onClick={(e) => {e.preventDefault(); this.uploadPhoto.click()}}><i className="fas fa-camera"></i></a>
                                    {
                                        this.state.hasImage
                                        ? <a href="/#" className="red fs15" onClick={this.removeProfileImage}><i className="fas fa-trash-alt"></i></a>
                                        : null
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        <div className="pull-left">
                            <span className="block fs25 semibold">{this.state.name}</span>
                            <span className="block fs13">{this.state.username}</span>
                            <br />
                            <a href="/" className="btn btn-sm btn-outline primary" data-toggle="modal" data-target="#modalUbahPassword"><i className="fas fa-lock"></i>{t("Profile:ubah_password_btn")}</a>
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        {
                            !this.state.verify_email
                            ? <div className="notification-box">
                                
                                <div className="warning">
                                    <i className="fas fa-info-circle"></i>
                                    <p>
                                    {t("Profile:konfirm_email.title")}<br />
                                        {
                                            this.props.userData.sendEmail
                                            ? <a href="/#" onClick={(e) => e.preventDefault()}>{t("Profile:konfirm_email.send")}</a>
                                            : this.state.loadingEmailVerify
                                                ? <span>{t("Profile:konfirm_email.memuat")}</span>
                                                : <a href="/#" onClick={this.onResendEmailVerify}>{t("Profile:konfirm_email.kirim_ulang")}</a>
                                        }
                                        
                                    </p>
                                </div>
                            </div>
                            : null
                        }
                        

                        <div className="fs13">
                            <a href="/" className="pull-right mr10 mt10" data-toggle="modal" data-target="#modalUbahProfil" onClick={this.toggle}><i className="fas fa-pencil-alt mr5"></i>{t("Profile:ubah")}</a>
                            <span className="profile-title">{t("Profile:data_diri.title")}</span>
                            <table width="100%" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td className="grey w150">{t("Profile:data_diri.nama")}</td>
                                        <td><span>{this.state.name}</span></td>
                                    </tr>
                                    <tr>
                                        <td className="grey">{t("Profile:data_diri.username")}</td>
                                        <td><span>{this.state.username}</span></td>
                                    </tr>
                                    <tr>
                                        <td className="grey">{t("Profile:data_diri.tgl_lahir")}</td>
                                        <td><span>{this.state.tanggal_lahir}</span></td>
                                    </tr>
                                    <tr>
                                        <td className="grey">{t("Profile:data_diri.jenis_kelamin")}</td>
                                        <td><span>{this.state.jenis_kelamin}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <span className="profile-title">{t("Profile:kontak.title")}</span>
                            <table width="100%" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td className="grey w150">{t("Profile:kontak.email")}</td>
                                        <td><span>{this.state.email}</span></td>
                                    </tr>
                                    <tr>
                                        <td className="grey">{t("Profile:kontak.no_telp")}</td>
                                        <td><span>
                                            <NumberFormat
                                                value={this.state.no_telpon} 
                                                displayType={'text'}
                                                format="## ### #### #### #"
                                                />
                                            {/* {this.state.no_telpon} */}
                                        </span></td>
                                    </tr>
                                    
                                </tbody>				
                            </table>

                            <span className="profile-title">{t("Profile:alamat_pengiriman")}</span>
                            <div>
                                {/* {
                                    this.props.address
                                    ? <a href="/" className="pull-right" data-toggle="modal" data-target="#modalTambahAlamat"><i className="fas fa-pencil-alt"></i> Ubah</a>
                                    : null
                                } */}
                                {/* <span className="block bold">Surojo Farm</span>
                                <span className="block grey">081258756541</span>
                                <span className="block grey">Rukan French Walk G-3 Kelapa Gading Square, Jl. Boulevard Barat Raya Kelapa Gading 14240 Kelapa Gading, Jakarta Utara, Jakarta</span> */}
                            </div>
                            <ShippingAddress 
                                // data={this.props.address}
                                data={this.props.address}
                                onClickEditAlamat={this.onClickEditAlamat}
                                onClickTambahAlamat={this.onClickTambahAlamat}
                                onClickHapusAlamat={this.onClickHapusAlamat}
                                no_br
                                />
                            <span className="profile-title">{t("Profile:rekening.title")}</span>
                            <a href="/" className="btn btn-outline primary mt15 mb20" data-toggle="modal" data-target="#modalTambahRekening"><i className="fas fa-plus"></i> {t("Profile:rekening.tambah")}</a>
                            <table className="table table-form w500" cellPadding="5">
                                <tbody>
                                    {
                                        this.state.bank_accounts.map(bank_account => (
                                            <tr key={bank_account.id}>
                                                <td valign="middle" width="100">
                                                    <img src={bank_account.bank_image} alt={bank_account.bank_name} />
                                                </td>
                                                <td>
                                                    <span className="grey">{bank_account.bank_name}</span><br />
                                                    <p>{bank_account.account_number}<br />
                                                    {bank_account.account_name}</p>
                                                </td>
                                                <td valign="top" align="right">
                                                    <a href="/" className="mr10" data-toggle="modal" data-target="#modalUbahRekening" onClick={() => this.setModalEditBank(bank_account)}><i className="fas fa-pencil-alt"></i></a>
                                                    <a href="/" className="red" onClick={(e) => this.removeBank(e, bank_account)}><i className="fas fa-trash-alt"></i></a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        )

        return (
            <div className="mg-top">
                <NewHeader 
                    {...this.props}
                    stateLogin = {this.props.userData.user}
                    toggleModalLogin={() => this.props.toggleModalLogin(true)}
                    />
                <CSSTransition
                    in={this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        <ProfileLoading />
                </CSSTransition>
                <CSSTransition
                    in={!this.state.loadingPage}
                    timeout={300}
                    classNames="mypage"
                    unmountOnExit>
                        {Page}
                </CSSTransition>

                <div className="modal fade modal-2" id="modalUbahProfil" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{t("Profile:ubah_profil.title")}</h2>
                                    <span className="modal-subtitle">{t("Profile:ubah_profil.data_diri")}</span>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_profil.nama")}</label><br />
                                        <input 
                                            name="input_name"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.input_name}
                                            onChange={this.handleChange}
                                            />
                                    </div>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_profil.username")}</label><br />
                                        <input 
                                            name="input_username"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.input_username}
                                            onChange={this.handleChange}
                                            disabled={this.state.input_username ? true : false} />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group-div">
                                                <label>{t("Profile:ubah_profil.tgl_lahir")}</label><br />
                                                <DatePicker
                                                    selected={this.state.input_tanggal_lahir}
                                                    customInput={<input type="text" className="form-control" />}
                                                    onChange={date => this.setState({input_tanggal_lahir : date})}
                                                    placeholderText={t("Profile:ubah_profil.tgl_lahir_placeholder")}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dateFormat="d MMMM yyyy"
                                                    dropdownMode="select"
                                                    locale={id}
                                                />
                                                {/*  */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group-div">
                                                <label>{t("Profile:ubah_profil.jenis_kelamin.title")}</label><br />
                                                <label className="form-check pull-left mr10">
                                                    <input 
                                                        type="radio" 
                                                        name="opt1" 
                                                        className="mr5"
                                                        checked={this.state.input_jenis_kelamin === 1}
                                                        onChange={() => this.setState({input_jenis_kelamin : 1})}
                                                        /> {t("Profile:ubah_profil.jenis_kelamin.laki")}
                                                </label>
                                                <label className="form-check pull-left">
                                                    <input 
                                                        type="radio" 
                                                        name="opt1" 
                                                        className="mr5" 
                                                        checked={this.state.input_jenis_kelamin === 2}
                                                        onChange={() => this.setState({input_jenis_kelamin : 2})}
                                                        /> {t("Profile:ubah_profil.jenis_kelamin.perempuan")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <span className="modal-subtitle">{t("Profile:ubah_profil.kontak.title")}</span>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_profil.kontak.email")}</label><br />
                                        <input
                                            name="input_email"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.input_email}
                                            onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_profil.kontak.no_telp")}</label><br />
                                        <NumberFormat
                                            type="text"
                                            className="form-control"
                                            placeholder="62 8xx xxxx xxxx"
                                            value={this.state.input_no_telpon} 
                                            displayType={'input'}
                                            onValueChange={(values) => this.onChangeNoTelp(values.value)}
                                            format="## ### #### #### #"
                                            />
                                        {/* <input
                                            name="input_no_telpon"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.input_no_telpon}
                                            onChange={this.handleChange}
                                            /> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingUpdateDataDiri
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{t("Profile:ubah_profil.batal")}</button>
                                            <button type="button" className="btn btn-solid primary btn-sm" onClick={this.updateProfile}>{t("Profile:ubah_profil.simpan")}</button>
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <TambahAlamat
                    address={this.props.address}
                    current_address={this.state.current_address}
                    is_edit_address={this.state.is_edit_address}
                />
                

                <div className="modal fade modal-2" id="modalUbahPassword" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{t("Profile:ubah_password.title")}</h2>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_password.old")}</label><br />
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            name="oldPassword"
                                            value={this.state.password.oldPassword}
                                            onChange={this.onChangePassword}
                                            onBlur={this.onBlurPassword}
                                            />
                                    </div>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_password.new")}</label><br />
                                        <input 
                                            type="password" 
                                            className={`form-control ${this.state.password.validNewPassword ? "" : "red"}`} 
                                            name="newPassword"
                                            value={this.state.password.newPassword}
                                            onChange={this.onChangePassword}
                                            onBlur={this.onBlurPassword}
                                            />
                                        <div className="red">{this.state.password.msgNewPassword}</div>
                                    </div>
                                    <div className="form-group-div">
                                        <label>{t("Profile:ubah_password.confirm")}</label><br />
                                        <input 
                                            type="password" 
                                            className={`form-control ${this.state.password.validConfirmPassword ? "" : "red"}`}
                                            name="confirmPassword"
                                            value={this.state.password.confirmPassword}
                                            onChange={this.onChangePassword}
                                            onBlur={this.onBlurPassword}
                                            />
                                        <div className="red">{this.state.password.msgConfirmPassword}</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingUpdatePassword
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{t("Profile:ubah_password.batal")}</button>
                                            <button 
                                                type="button" 
                                                className="btn btn-solid primary btn-sm" 
                                                onClick={this.updatePassword}>{t("Profile:ubah_password.simpan")}</button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                
                <div className="modal fade modal-2" id="modalTambahRekening" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <form>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                    <h2>{t("Profile:rekening_modal.title_tambah")}</h2>
                                    <div className="form-group-div">
                                        <label>{t("Profile:rekening_modal.bank")}</label><br />
                                        <select 
                                            name="input_bank_id"
                                            className="form-control" 
                                            onChange={this.onChangeModalBank}
                                            value={this.state.modal_create_bank.input_bank_id}
                                            >
                                            <option value={0}>{t("Profile:rekening_modal.default_bank")}</option>
                                            {
                                                this.state.master_banks.map(master_bank => (
                                                    <option key={master_bank.id} value={master_bank.id}>{master_bank.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group-div">
                                        <label>{t("Profile:rekening_modal.nama")}</label><br />
                                        <input 
                                            name="input_account_name"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.modal_create_bank.input_account_name}
                                            onChange={this.onChangeModalBank}
                                            />
                                    </div>
                                    <div className="form-group-div">
                                        <label>{t("Profile:rekening_modal.nomor")}</label><br />
                                        <input
                                            name="input_account_number"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.modal_create_bank.input_account_number}
                                            onChange={this.onChangeModalBank}
                                            />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        this.state.loadingAddTokoBank
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{t("Profile:rekening_modal.batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.onClickAddBank}>{t("Profile:rekening_modal.simpan")}</button>
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    this.state.modal_edit_bank.edit
                    ? <div className="modal fade modal-2" id="modalUbahRekening" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <form>
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <a href="/" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                        <h2>{t("Profile:rekening_modal.title_ubah")}</h2>
                                        <div className="form-group-div">
                                            <label>{t("Profile:rekening_modal.bank")}</label><br />
                                            <select 
                                                name="input_bank_id"
                                                className="form-control"
                                                value={this.state.modal_edit_bank.input_bank_id}
                                                onChange={this.onChangeModalBankEdit}
                                                >
                                                {
                                                    this.state.master_banks.map(master_bank => (
                                                        <option key={master_bank.id} value={master_bank.id}>{master_bank.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group-div">
                                            <label>{t("Profile:rekening_modal.nama")}</label><br />
                                            <input 
                                                name="input_account_name"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_edit_bank.input_account_name}
                                                onChange={this.onChangeModalBankEdit}
                                                />
                                        </div>
                                        <div className="form-group-div">
                                            <label>{t("Profile:rekening_modal.nomor")}</label><br />
                                            <input 
                                                name="input_account_number"
                                                type="text" 
                                                className="form-control" 
                                                value={this.state.modal_edit_bank.input_account_number}
                                                onChange={this.onChangeModalBankEdit}
                                                />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                    {
                                        this.state.loadingUpdateTokoBank
                                        ? <Loading/>
                                        : <React.Fragment>
                                            <button data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{t("Profile:rekening_modal.batal")}</button>
                                            <button className="btn btn-solid primary btn-sm" onClick={this.onClickUpdateBank}>{t("Profile:rekening_modal.simpan")}</button>
                                        </React.Fragment>
                                    }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }
}
const mapStateToProps = state => {
    let userData = state.user.user;
    return {
        userData,
        districts : state.shop.districts,
        address : userData.current_address,
    }
}

export default NetworkDetector(withNamespaces("Profile")(connect(mapStateToProps, {
    setShippingAddress,
    resendEmailVerify,
    setImageProfile,
    setNewToken,
    toggleModalLogin
})(Profile)));