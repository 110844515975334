import React, { Component } from 'react'
import Logo from '../../assert/files/logo/logo-white.png';
import Shipment from '../../assert/files/footer/shipment.png';
import Easy from '../../assert/files/footer/easy.png';
import Safe from '../../assert/files/footer/safe.png';
import { withNamespaces } from "react-i18next";
export class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
        <div className="container-fluid footer-container" style={this.props.style}>
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-8 footer-left">
                        <div className="row">
                            <div className="col-md-4 col-12">
                                <img src={Safe} alt="agrinis"/>
                                <h6>{t("Footer:jaminan.title")}</h6>
                                <p>{t("Footer:jaminan.desc")}</p>
                            </div>
                            <div className="col-md-4 col-12">
                                <img src={Easy} alt="agrinis"/>
                                <h6>{t("Footer:pembayaran.title")}</h6>
                                <p>{t("Footer:pembayaran.desc")}</p>
                            </div>
                            <div className="col-md-4 col-12">
                                <img src={Shipment} alt="agrinis"/>
                                <h6>{t("Footer:jasa.title")}</h6>
                                <p>{t("Footer:jasa.desc")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-4 footer-right">
                        <div className="copy"><img src={Logo} alt="Agrinis"/>{(new Date().getFullYear())} &copy; Agrinis</div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default withNamespaces('Footer')(Footer);
