import React from 'react'
import { withNamespaces } from 'react-i18next'
import i18n from '../../i18n';

const FilterShop = props => {
    return (
        <div className="filter-menu">
        <h2>{i18n.t("FilterShop:filter")}</h2>

        <div className="mb20">
            <h3>{i18n.t("FilterShop:wilayah")}</h3>
            <table width="100%" cellPadding="3">
                <tbody>
                    {
                        props.province.map((data, i) => (
                            <tr key={i}>
                                <td>
                                <input 
                                    name="baru"
                                    type="checkbox" 
                                    value={data.value}
                                    checked={props.wilayah.includes(parseInt(data.value))}
                                    onChange={props.checkedWilayah}/>
                                </td>
                                <td>{data.label}</td>
                            </tr>
                        ))
                    }                  
                </tbody>
            </table>
        </div>

        <div className="mb20 text-center">
            <button className="btn btn-block btn-solid primary" onClick={props.filterShops}>{i18n.t("FilterShop:terapkan")}</button>
            <br/>
            <a href="/#" onClick={props.resetFilterShops}>{i18n.t("FilterShop:reset")}</a>
        </div>

    </div>
    )
}

export default withNamespaces("FilterShop")(FilterShop)
