import React from 'react';
import { connect } from 'react-redux';
// import _ from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import ReactLoading from 'react-loading';
import {
    setShippingAddress,
    create_shippingAddress
}  from '../../actions/userAction';

import {
    AlertWarning,
    AlertError,
    get,
    Toast
} from '../../helper';
import Geocode from "react-geocode";
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
import { API_GMAPS_KEY } from '../../config';
// import {
//     MapComponent
// } from '../../components';
// import { LoadScript } from '@react-google-maps/api';
// const libraries = ['places'];

Geocode.setApiKey(API_GMAPS_KEY);
Geocode.enableDebug();
Geocode.setLanguage("id");
Geocode.setRegion("id");

class TambahAlamat extends React.Component {
    constructor(props){
        super(props);
        let id             = this.props.current_address.id ? this.props.current_address.id : '';
        let label          = props.address.label ? props.address.label : '';
        let penerima       = props.address.penerima ? props.address.penerima : '';
        let district       = props.address.district ? props.address.district : '';
        let phone          = props.address.phone ? props.address.phone : '';
        let alamat_lengkap = props.address.alamat_lengkap ? props.address.alamat_lengkap : '';
        let kode_pos       = props.address.kode_pos ? props.address.kode_pos : '';
        let lat            = props.address.lat ? parseFloat(props.address.lat) : parseFloat('0.0');
        let lng            = props.address.lng ? parseFloat(props.address.lng) : parseFloat('0.0');
        let instruksi_tambahan = props.address.instruksi_tambahan ? props.address.instruksi_tambahan : '';
        let is_edit_address = props.is_edit_address;
        this.state = {
            id: id,
            district : district,
            label : label,
            penerima : penerima,
            phone : phone,
            alamat_lengkap : alamat_lengkap,
            instruksi_tambahan: instruksi_tambahan,
            kode_pos: kode_pos,
            loading : false,
            g_adrress: '',
            g_position: {
                lat: lat,
                lng: lng
            },
            is_edit_address: is_edit_address,
        }
    }

    componentWillMount() {
        this.getCurrentLocation();
    }

    componentDidUpdate(prevProps)
    {
        if ((this.props.current_address !== prevProps.current_address) || (this.props.is_edit_address !== prevProps.is_edit_address)){
            this.setCurrentAddress()
        }
    }

    setCurrentAddress()
    {
            let id             = this.props.is_edit_address ? this.props.current_address.id ? this.props.current_address.id : '' : '';
            let label          = this.props.is_edit_address ? this.props.current_address.label ? this.props.current_address.label : '' : '';
            let penerima       = this.props.is_edit_address ? this.props.current_address.penerima ? this.props.current_address.penerima : '' : '';
            let district       = this.props.is_edit_address ? this.props.current_address.district ? this.props.current_address.district : '' : '';
            let phone          = this.props.is_edit_address ? this.props.current_address.phone ? this.props.current_address.phone : '' : '';
            let alamat_lengkap = this.props.is_edit_address ? this.props.current_address.alamat_lengkap ? this.props.current_address.alamat_lengkap : '' : '';
            let kode_pos       = this.props.is_edit_address ? this.props.current_address.kode_pos ? this.props.current_address.kode_pos : '' : '';
            let lat            = this.props.is_edit_address ? this.props.current_address.lat ? parseFloat(this.props.current_address.lat) : parseFloat('0.0') : parseFloat('0.0');
            let lng            = this.props.is_edit_address ? this.props.current_address.lng ? parseFloat(this.props.current_address.lng) : parseFloat('0.0') : parseFloat('0.0');
        let instruksi_tambahan = this.props.is_edit_address ? this.props.current_address.instruksi_tambahan ? this.props.current_address.instruksi_tambahan : '' : '';
            let is_edit_address = this.props.is_edit_address;
            this.setState({
                id: id,
                district : district,
                label : label,
                penerima : penerima,
                phone : phone,
                alamat_lengkap : alamat_lengkap,
                kode_pos: kode_pos,
                g_position: {
                    lat: lat,
                    lng: lng
                },
                instruksi_tambahan: instruksi_tambahan,
                is_edit_address: is_edit_address,
            }, () => {
                    this.getCurrentLocation();
            })
    }

    getCurrentLocation() {
        if (this.state.g_position.lat === 0.0 || this.state.g_position.lng === 0.0) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.setState({
                        g_position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }, () => {
                        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                            response => {
                                const g_adrress = response.results[0].formatted_address;
    
                                this.setState({
                                    g_adrress: (g_adrress) ? g_adrress : ''
                                })
                            },
                            error => {
                                // console.error(error);
                            }
                        );
                    });
                },
                (error) => {console.log(error.message)},
                { enableHighAccuracy: true, timeout: 10000, maximumAge: 1000 },
            );
        }else{
            Geocode.fromLatLng(this.state.g_position.lat, this.state.g_position.lng).then(
                response => {
                    const g_adrress = response.results[0].formatted_address;
                    this.setState({
                        g_adrress: (g_adrress) ? g_adrress : ''
                    })
                },
                error => {
                    // console.error(error);
                }
            );
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleChangeNumber = (e) => {
        let validateValue = e.target.value.replace(/[^\d]+/g,'');
        this.setState({
            [e.target.name] : validateValue
        })
    }

    handleChangeDistrict = (newValue) => {
        const district = newValue;
        
        this.setState({ district });
        return district;
    };

    loadOptions = (inputValue, callback) => {
        if(inputValue.length > 2){
            get(`indonesia/search/${inputValue}`)
            .then(res => {
                callback(res.data);
            })
        }else{
            callback([]);
        }
    }

    validateAlamat(){
        let { state } = this;

        if(state.penerima === '')
        {
            AlertWarning.fire({text : i18n.t("TambahAlamat:validation.penerima")});
            return false;
        }
        else if(state.phone === '')
        {
            AlertWarning.fire({text : i18n.t("TambahAlamat:validation.no_telp")});
            return false;
        }
        else if(state.alamat_lengkap === '')
        {
            AlertWarning.fire({text : i18n.t("TambahAlamat:validation.alamat")});
            return false;
        }
        else if(state.kode_pos === '')
        {
            AlertWarning.fire({text : i18n.t("TambahAlamat:validation.kode_pos")});
            return false;
        }
        else if(state.district === '')
        {
            AlertWarning.fire({text : i18n.t("TambahAlamat:validation.district")});
            return false;
        }

        return true;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // window.$("#modalTambahAlamat").modal('hide');

        if(this.validateAlamat()){
            this.setState({loading : true});
            let data = { ...this.state, 
                district_id : this.state.district.value,
                lat: this.state.g_position.lat, 
                lng: this.state.g_position.lng,
            };
            create_shippingAddress(data, this.props.userData.token)
            .then(res => {
                this.setState({
                    loading : false,
                    id: '',
                    district: '',
                    label: '',
                    penerima: '',
                    phone: '',
                    alamat_lengkap: '',
                    kode_pos: '',
                    instruksi_tambahan: ''
                }, () => { 
                    this.props.setShippingAddress(res.data.data);
                    window.$("#modalTambahAlamat").modal('hide');
                    Toast.fire({
                        type: "success",
                        title: this.state.is_edit_address ? i18n.t("TambahAlamat:validation.success_ubah") : i18n.t("TambahAlamat:validation.success_tambah")
                    });
                })
            }).catch(err => {
                this.setState({loading : false}, () => AlertError.fire({text: err.response.data.message}))
            })
        }
    }

    onChangeGMaps = (e) => {
        this.setState({ place: e.description }
            , () => {
                Geocode.fromAddress(e.description).then(
                    response => {
                        const coordinates = response.results[0].geometry.location;
                        const g_adrress = response.results[0].formatted_address;
                        this.setState({
                            g_position: {
                                lat: coordinates.lat,
                                lng: coordinates.lng
                            },
                            g_adrress: (g_adrress) ? g_adrress : '',
                        });
                    },
                    error => {
                        console.error("From Address: " + error);
                    }
                );
            }
        )
    }
    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const g_adrress = response.results[0].formatted_address;
                this.setState({
                    g_position: {
                        lat: newLat,
                        lng: newLng
                    },
                    g_adrress: (g_adrress) ? g_adrress : '',
                });
            },
            error => {
                console.error(error);
            }
        );
    };

    loading = () => <div className="loading-btn mb20" style={{width: "100px"}}><ReactLoading type="spinningBubbles" color="#0C7F8F" height={"25px"} width={"25px"} /></div>
    
    render(){

        return (
            <div className="modal fade modal-2" id="modalTambahAlamat" tabIndex="-1" role="dialog" aria-hidden="true" ref="coba">
                <div className="modal-dialog" role="document">
                    <form onSubmit={this.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href="/#" className="pull-right" data-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></a>
                                <h2>{this.state.is_edit_address ? i18n.t("TambahAlamat:validation.title_ubah") : i18n.t("TambahAlamat:title_tambah") }</h2>
                                <div className="form-group-div form-warning">
                                    <label>{i18n.t("TambahAlamat:label")}</label><br />
                                    <input
                                        name="label"
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.label}
                                    />
                                </div>
                                <div className="form-group-div form-warning">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>{i18n.t("TambahAlamat:penerima")}</label><br />
                                            <input
                                                name="penerima"
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={this.state.penerima}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>{i18n.t("TambahAlamat:no_telp")}</label><br />
                                            <input
                                                name="phone"
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChangeNumber}
                                                value={this.state.phone}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group-div">
                                    <label>{i18n.t("TambahAlamat:alamat")}</label><br />
                                    <textarea
                                        name="alamat_lengkap"
                                        className="form-control"
                                        rows="5"
                                        onChange={this.handleChange}
                                        value={this.state.alamat_lengkap}
                                    ></textarea>
                                </div>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions={false}
                                    // onInputChange={this.handleChange}
                                    onChange={this.handleChangeDistrict}
                                    value={this.state.district}
                                    placeholder={i18n.t("TambahAlamat:district")}
                                    noOptionsMessage={(val)=> null}
                                    />
                                {/* <div className="form-group-div">
                                    <label>Kabupaten / Kota dan Kecamatan</label><br />
                                    <select className="form-control">
                                        <option>Pilih</option>
                                    </select>
                                </div> */}
                                <div className="form-group-div w50p">
                                    <label>{i18n.t("TambahAlamat:kode_pos")}</label><br />
                                    <input 
                                        name="kode_pos" 
                                        type="text" 
                                        className="form-control" 
                                        onChange={this.handleChange}
                                        value={this.state.kode_pos}
                                        />
                                </div>
                                {/* <div className="form-group-div">
                                    <label>Pilih Koordinat Lokasi</label><br />
                                    <LoadScript
                                        id="script-loader"
                                        googleMapsApiKey={API_GMAPS_KEY}
                                        language="id"
                                        region="id"
                                        libraries={libraries}
                                    >
                                        <MapComponent
                                            onChangeGMaps={this.onChangeGMaps}
                                            position={this.state.g_position}
                                            address={this.state.g_adrress}
                                            api_key={API_GMAPS_KEY}
                                            onMarkerDragEnd={this.onMarkerDragEnd}
                                        />
                                    </LoadScript>
                                </div> */}
                                <div className="form-group-div">
                                    <label>{i18n.t("TambahAlamat:keterangan_lokasi")}</label><br />
                                    <textarea
                                        name="instruksi_tambahan"
                                        className="form-control"
                                        rows="5"
                                        onChange={this.handleChange}
                                        value={this.state.instruksi_tambahan}
                                    ></textarea>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <a href="/#" data-dismiss="modal" className="btn btn-solid darkgrey btn-sm">{i18n.t("TambahAlamat:batal")}</a>
                                { 
                                    this.state.loading
                                    ? this.loading()
                                    : <button className="btn btn-solid primary btn-sm">{i18n.t("TambahAlamat:simpan")}</button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userData : state.user.user
    }
}

export default withNamespaces("TambahAlamat")(connect(mapStateToProps, { 
    setShippingAddress 
})(TambahAlamat));