import React from 'react'
import Logo from '../../logo.png'
import { Link } from 'react-router-dom'

const Header = props => (
    <div className="text-center">
        <Link to={props.url_back} className="pull-left"><i className="fas fa-arrow-left mr5"></i> Kembali</Link>
        <Link to="/">
            <img src={Logo} alt="Agrinis"/>
        </Link>
    </div>
)
    
export default Header