import React from 'react'
import ContentLoader from "react-content-loader";

export default function CreateProductLoading() {
    return (
        <div className="container p15" style={{marginTop: "62px"}}>
            <div className="form-product">
            <ContentLoader 
                height={35}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                >
                <rect x="0" y="0" rx="0" ry="0" width="100" height="25"/>
            </ContentLoader>
                <div className="form-subtitle">
                <ContentLoader 
                    height={20}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="15"/>
                </ContentLoader>
                </div>
                <div>
                <ContentLoader 
                    height={75}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="65"/>
                </ContentLoader>
                </div>
                <div className="form-subtitle">
                <ContentLoader 
                    height={20}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="15"/>
                </ContentLoader>
                </div>
                <div>
                <ContentLoader 
                    height={170}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="65"/>
                    <rect x="0" y="80" rx="0" ry="0" width="200" height="20"/>
                    <rect x="0" y="110" rx="0" ry="0" width="200" height="20"/>
                    <rect x="0" y="140" rx="0" ry="0" width="200" height="20"/>
                    </ContentLoader>
                </div>
            </div>
        </div>
    )
}
