import React from 'react'
import {
    // URL
} from '../../config'

import {
    FormatUang
} from '../../helper'
const FlashNotif = props => {
    return (
        <div className="flash-notification-container">
            <a href="/#" className="pull-right close-flash-notification" onClick={props.closeNotif}><i className="fas fa-times"></i></a>
            <i className="fas fa-check-circle"></i>
            {/* <i className="fas fa-exclamation-circle"></i> */}
            <p className="notification-msg">{props.message}</p>
            <div className="clearfix"></div>

            {/* Jika Tambahkan ke Keranjang */}
            {
                props.data
                ? <div className="product-card">
                <div className="product-thumb">
                    <img src={props.data.image_thumb} alt="Notif"/>
                </div>
                <div className="product-info w150">
                    <p className="semibold mb5" style={{ overflowWrap: "break-word" }}>{props.data.name}</p>
                    <p className="regularitalic pull-right mb0">Rp <FormatUang value={props.data.price}/></p>
                    <p className="mb0">Jumlah: {props.data.quantity}</p>
                </div>
                <div className="clearfix"></div>
            </div>
                : null
            }
            
        </div>
    )
}

export default FlashNotif