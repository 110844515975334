import React from 'react'

const SuccessNotif = props => {
    return (
        <div id="notif_success" className="flash-notification-container" style={{display: "none"}}>
            <a href="/#" className="pull-right close-flash-notification" onClick={(e) => e.preventDefault()}>
                <i className="fas fa-times"></i>
            </a>
            <i className="fas fa-check-circle"></i>
            <p className="notification-msg">{props.message}</p>
        </div>
    )
}

export default SuccessNotif